<template>
<footer class="page-footer">
    <div class="container">
        <div class="row">
            <!-- <div class="col m6 s12">
                <h4 class="site-name left-align"><strong>HOFHRecipes.com</strong></h4>
                <p class="site-details left-align">We have collected a range of delicious and simple recipes made especially for people with homozygous familial hypercholesterolemia, an uncommon condition that leads to high levels of cholesterol in your blood.</p>
            </div> -->
            <div class="col m12 s12">
                <p><span class="white-text left-align">Sponsored by:</span></p>
                <!-- <img class="footer-logo" src="@/assets/img/amryt-logo.svg"> -->
                <img class="footer-logo" src="@/assets/img/chiesi-logo-white.svg">
            </div>
        </div>
        <div class="row small">
            <div class="col m12 s12">
                <ul>
                    <li class="links center-align"><strong><a class="modal-trigger" href="#privacyModal">Privacy Policy</a></strong></li>
                    <li class="links center-align"><strong><a class="modal-trigger" href="#termsModal">Terms of use</a></strong></li>
                    <li class="links center-align"><strong><a class="modal-trigger" href="mailto:info@hofhrecipes.com">Contact us</a></strong></li>
                    <li class="links center-align" :value="updateExists" :timeout="0" color="primary">
                    <a @click="refreshApp">
                        <span style="color:#21a3ca">Update</span>
                    </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="footer-copyright">
        <div class="container">
            <small class="center-align">© {{ new Date().getFullYear() }} HOFHRecipes.com.</small>
            <small class="center-align"> 
                    <span v-show="$route.name!=='profile'">C-HoFH/ALL/0003. Date of preparation: February 2024</span>
                    <!-- <span v-show="$route.name=='profile'">87011-4. Date of preparation: August 2020.</span> -->
            </small>
        </div>
    </div>
    <!-- Modal Structure -->
    <div id="termsModal" class="modal modal-fixed-footer">
        <div class="modal-content">

            <h4>Terms of use</h4>

            <p>These terms and conditions apply to your use of the website http://hofhrecipes.com (the “Website”). </p>

            <p>The Website is operated by Amryt Pharma plc on behalf of the Amryt group (“Amryt”, “us” or “we”). </p>

            <p>Please read these terms and conditions of use of the Website carefully as by using the Website you agree to be bound by them. We reserve the right to vary the conditions of use at any time and will post any variations here. You are advised to review the conditions of use on a regular basis as you will be deemed to have accepted variations if you continue to use the Website after they have been posted.</p>

            <p><strong>YOUR RESPONSIBILITY</strong> </p>

            <p>You assume all responsibility for your use of, or access to, this Website, including your access to any Amryt or other literature obtained through the Site, and waive all claims or causes of action against Amryt or their respective officers, directors, employees, agents and affiliates in connection therewith. If you do not agree to abide by these conditions of use you should not use the Website in any way.</p>

            <p><strong>THIRD-PARTY RESOURCES</strong> </p>

            <p>This Website may contain links to websites operated by other parties. The linked sites are not under the control of Amryt, and we are not responsible for the content available on any other Internet sites linked to this Website. Such links do not imply our endorsement of material on any other site, and Amryt disclaim all liability with regard to your access to such linked websites. Amryt may provide links to other Internet sites as a convenience to users, and access to any other Internet sites linked to this Website is at your own risk. Information published on the Website is supplied by Amryt and, where indicated, by certain third parties. Amryt take every care and precaution to ensure that information published on the Website is accurate when posted and regularly updated, however Amryt makes no warranties or representations as to its accuracy and Amryt may change the information on the Website at any time without notice. Amryt and its affiliates assume no liability or responsibility, to the extent permitted by laws, for any errors or omissions in the content of the Website. You must not rely on the information on the Website and you acknowledge that you must take appropriate steps to verify this information before acting on it.</p>

            <p><strong>DISCLAIMER</strong></p>

            <p><strong>AMRYT PUBLISH THE WEBSITE “AS IS” WITHOUT ANY WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE WEBSITE, THE ACCURACY OF THE INFORMATION OR THE PRODUCTS OR SERVICES REFERRED TO ON THE WEBSITE (IN SO FAR AS SUCH WARRANTIES MAY BE EXCLUDED UNDER ANY RELEVANT LAW) AND TO THE EXTENT PERMITTED BY LAW, AMRYT SHALL NOT BE LIABLE FOR ANY LOSSES OR DAMAGE WHETHER DIRECT OR INDIRECT (INCLUDING, WITHOUT LIMITATION DIRECT OR INDIRECT LOSS OF PROFITS), CONSEQUENTIAL, SPECIAL OR OTHERWISE INCIDENTAL THAT MAY RESULT FROM USE OF THE WEBSITE HOWSOEVER ARISING. WE FURTHER DISCLAIM ANY WARRANTY THAT THE INFORMATION ON THIS SITE WILL BE FREE OF INTERRUPTION, OR THAT THE SITE IS FREE OF VIRUSES, WORMS, TROJAN HORSES OR OTHER CODE THAT MANIFEST CONTAMINATING OR DESTRUCTIVE PROPERTIES</strong></p>

            <p><strong>INDEMNITY</strong></p>

            <p>You agree to indemnify, defend and hold harmless Amryt, and their respective officers, directors, employees, agents and affiliates from and against all losses, expenses, damages and costs, including reasonable attorneys’ fees, arising out of or relating to any violation of these Terms or any activity related to use of the Website (including but not limited to infringement of third parties’ worldwide intellectual property rights or negligent or wrongful conduct) by you or any other person accessing the Website on your behalf.</p>

            <p><strong>COPYRIGHT</strong></p>

            <p>Amryt owns the copyright and trademarks in the content published on the Website except where otherwise indicated by a third party’s proprietary notice. Images, trademarks and brands are also protected by other intellectual property laws and may not be reproduced or appropriated in any manner without written permission of their respective owners. Unless specifically prohibited by a notice published on any page, you may make a print copy of such parts of the Website as you may reasonably require for your own personal use provided that any copy has attached to it any relevant proprietary notices and/or disclaimers. All other use is prohibited.</p>

            <p>We are not responsible for the content of any other website from which you have accessed the Website or to which you may hyperlink from the Website and cannot be held liable for any loss or damage you incur as a result thereof.</p>

            <p><strong>THIS WEBSITE DOES NOT PROVIDE MEDICAL OR PROFESSIONAL SERVICES ADVICE</strong></p>

            <p>The content on the Website is intended to be a general information resource in regard to the subject matter covered. Amryt does not directly or indirectly practice medicine, render medical advice, or dispense medical services via the Website, and nothing contained on the Website is intended to be instruction for medical diagnosis or treatment. Any information provided should not be considered complete, nor should it be relied on to suggest a course of treatment for a particular individual. Information received from the Website should not be relied upon for personal, medical, legal, technical, or financial decisions. It should not be used in place of a visit, call, consultation or the advice of your physician or other qualified healthcare provider. Should you have any healthcare related questions, please consult with your physician or other qualified healthcare provider promptly.</p>

            <p><strong>UPDATES</strong></p>

            <p>Amryt may make improvements and/or changes to the Website at any time. Although we attempt to periodically update information on this Website, the information, materials and services provided on or through this Website may occasionally be inaccurate, incomplete or out of date. Amryt do not have a duty to update information contained on the Website, and we will not be liable for any failure to update such information. We make no representation as to the completeness, accuracy or currentness of any information on the Website, and we undertake no obligation to update or revise the information contained on the Website, whether as a result of new information, future events or circumstances or otherwise. It is your responsibility to verify any information contained on the Website before relying upon it.</p>

            <p><strong>GOVERNING LAW</strong></p>

            <p>These conditions of use are governed by the laws of England and Wales and you agree that the English courts shall have exclusive jurisdiction in any dispute.</p>

            <p>To the extent that any part of these conditions of use is found to be invalid, unlawful or unenforceable by any court of competent jurisdiction such part shall to that extent be severed from the remaining terms all of which shall remain in full force and effect as permitted by law.</p>

            <p>C-LD/EU/0054</p>

        </div>
        <div class="modal-footer">
            <a href="#!" class="modal-close waves-effect waves-green btn-flat">Close</a>
        </div>
    </div>

    <!-- Modal Structure -->
    <div id="privacyModal" class="modal modal-fixed-footer">
        <div class="modal-content">

            <h4>Privacy policy</h4>

            <p>This Privacy Statement applies to your use of the website hofhrecipes.com ( <strong>“Website”</strong>). This Privacy Statement (the <strong>“Privacy Statement”</strong>) sets forth the commitment of Amryt to comply with applicable international, federal, state, and local laws and regulations protecting Personal Data that we process. Any person accessing, browsing or otherwise using the Website, either manually or via an automated device or program, shall be considered a <strong>“User.”</strong></p>

            <p>All Users are bound to the terms of this Notice and to any specific terms of use associated with the Website. Your use of the Website means you consent to the collection, use and disclosure of your Personal Data as described in this Privacy Statement.</p>

            <p><strong>“Personal Data”</strong> means any information relating to an identified or identifiable natural person; an identifiable person is one who can be identified, directly or indirectly, in particular by reference to an identification number or to one or more factors specific to his physical, physiological, mental, economic, cultural or social identity. We recognise the importance of and are committed to respecting and protecting your privacy. This Privacy Statement applies to our collection and use of personal information through our website and through our offline business-related interactions with you, which, in the course of its ordinary business activities, Amryt may collect or receive.</p>

            <p>The collection, storage, adaptation or alteration, transfer, use, deletion, and other processing of Personal Data will be governed nationally, internationally, and regionally by data protection laws and regulations, such as the EU General Data Protection Regulation (GDPR).</p>

            <p><strong>PURPOSE FOR WHICH WE HOLD YOUR INFORMATION</strong></p>

            <p>Amryt will not collect any Personal Data about you, except where it is knowlingly and voluntarily provided by you.</p>

            <p>We collect your Personal Data for the following purposes:</p>
            <p>
            <ul>
                <li class="disc">When you register for a User account on the Website: for the purpose of administering and authenticating your account</li>
                <li>When you input any information (including health information) via the Health Tracker on the Website: for the purposes of enabling you to track your health stats and manage your user profile.</li>
            </ul>
            </p>

            <p>You may update, amend and delete this information as you wish at any time.</p>

            <p>We also collect some basic information when you visit the Website such as your internet service provider’s domain name, which pages you accessed on the site, and when. We use this information only to analyse the use of the Website to help guide improvements.</p>

            <p><strong>DISCLOSURE OF YOUR INFORMATION TO THIRD PARTIES</strong></p>

            <p>We may disclose your information to the following third parties to provide the services associated with the Website:</p>

            <p>
            <ul>
                <li>Companies within the Amryt Group</li>
                <li>Third party service providers who support the functionality of the Website</li>
            </ul>
            </p>

            <p>The Personal Data Amryt processes may be stored on servers in the United States. If you are located outside of the United States, please be aware that Personal Data we collect may be processed and stored in the United States (the data protection and privacy laws in the United States may offer a lower level of protections than in your country/region).</p>

            <p>By using the Website and submitting your Personal Data, you agree to the transfer, storage, and/or processing of your Personal Data in the United States.</p>

            <p>We may also disclose your information if we believe in good faith that we are required to disclose it in order to comply with any applicable law, a summons, a search warrant, a court or regulatory order or other statutory or legal requirement.</p>

            <p>We will not disclose your Personal Data to any other third party, except as described above.</p>

            <p><strong>LEGAL BASIS FOR PROCESSING</strong></p>

            <p>When you register for a User account or interact with our Website, such processing is necessary for the administration of your account, and the performance of the Website.</p>

            <p>When you input Personal Data, we process such data on the basis of your prior consent. Where you input information into the Health Tracker (including health information), you provide your explict consent to the storage of this information for the purposes of maintaining your personal profile and enhancing your user experience. This information will not be used by Amryt for any other purposes or shared by Amryt with any third parties other than the third parties described above.</p>

            <p>When we leverage and/or collect cookies, device IDs, location data, data from the environment, and other tracking technologies, we process such data on the basis of your consent.</p>

            <p><strong>RETENTION AND DELETION POLICY</strong></p>

            <p>We retain your Personal Data for as long as you maintain a User account.</p>

            <p>You may delete your User account yourself at any time through the Website. When you delete your User account, all information input by you associated with your User profile will be permanently erased. We will no longer store your Personal Data (except to the extent we are required to retain any Personal Data by law).</p>

            <p>To find out what Personal Data we hold relating to you or if you are having difficulty updating, amending or deleting information from your profile, please contact us by email at info@hofhrecipes.com.</p>

            <p><strong>COOKIES</strong></p>

            <p>When you visit the Website, it uses cookies which are small pieces of information that allow them to maintain your connection to the website. This Website may use cookies for detecting what kind of device you have in order to present content in the best way, for a language switch and/or for other purposes. These cookies do not collect or store any personally identifiable information. <a href="https://www.lipodystrophy.eu/en/privacy-policy#refusecookies">You can refuse the use of cookies</a>.</p>

            <p><strong>HOW TO REFUSE THE USE OF COOKIES</strong></p>

            <p>You may refuse the use of cookies by selecting the appropriate settings in your browser. However, if you do this you may lose some useful functionality such as personalisation and ‘keep me signed in’ and ‘remember me’ features.</p>

            <p><a href="http://files.investis.com/info/disabling-cookies.html">How to disable cookies in your browser </a></p>

            <p><strong>GOOGLE ANALYTICS</strong></p>

            <p>This Website uses Google Analytics, a web analytics service provided by Google, Inc. (‘Google’). Google Analytics uses cookies (text files placed on your computer) to help the website operators analyse how users use the website. The information generated by the cookie about your use of the website (including your IP address) will be transmitted to and stored by Google on servers in the United States. Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity for website operators and providing other services relating to website activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google’s behalf. Google will not associate your IP address with any other data held by Google. By using this website, you consent to the processing of data about you by Google in the manner and for the purposes set out above.</p>

            <p><strong>SECURITY</strong></p>

            <p>We implement appropriate technical and organizational safeguards to protect against unauthorized or unlawful processing of Personal Data and against accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to Personal Data.</p>

            <p>However, the transmission of information via the internet is not completely secure. We cannot guarantee the security of your data transmitted to our online services; any transmission is at your own risk. Once we have received your information, we will take reasonable steps to use procedures and security features to try to prevent unauthorised access, modification or disclosure.</p>

            <p>If you communicate with us using a non-secure web platform, you assume the risks that such communications between us are intercepted, not received, delayed, corrupted or are received by persons other than the intended recipient. We will, however, take all reasonable steps (including appropriate technical and organisational measures) to protect your data.</p>

            <p>In the event your personal data is accessed, lost, or stolen by an unauthorised third party, we will exercise commercially reasonable efforts to notify you to the extent required by law and disclose to you the personal data that was accessed/disclosed using the contact information provided to us or by other reasonable means.</p>

            <p><strong>YOUR RIGHTS</strong></p>

            <p>You have, in connection with your personal data, under certain circumstances, the right to:</p>
            <p>
            <ul>
                <li> Request access to your personal information (commonly known as a "data subject access request"). This enables you to receive a copy of the personal information we hold about you and to check that we are lawfully processing it.</li>
                <li> Request correction of the personal information that we hold about you. This enables you to have any incomplete or inaccurate information we hold about you corrected.</li>
                <li> Request erasure of your personal information, in certain circumstances. This enables you to ask us to delete or remove personal information where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal information where you have exercised your right to object to processing (see below).</li>
                <li> Object to processing of your personal information, in certain circumstances, where we are relying on a legitimate interest (or those of a third party) on grounds relating your particular situation.</li>
                <li> Request the restriction of processing of your personal information in certain circumstances. This enables you to ask us to suspend the processing of personal information about you, for example if you want us to establish its accuracy or the reason for processing it.</li>
                <li> Request the transfer of your personal information to another party, in certain circumstances.</li>
                <li> Withdraw consent at any time, where we are relying on consent to process your personal data. If you withdraw your consent to the continued use of your Photographs, we will delete these immediately. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. We will advise you if this is the case at the time you withdraw your consent.</li>
            </ul>
            </p>

            <p>If you wish to exercise any of the rights set out above, please contact <a href="mailto:info@hofhrecipes.com">info@hofhrecipes.com</a></p>

            <p><strong>RIGHT TO LODGE A COMPLAINT BEFORE THE DATA PROTECTION AUTHORITY</strong></p>

            <p>We encourage you to contact us directly and allow us to work with you to address your concerns. Nevertheless, you have the right to lodge a complaint with a competent data protection supervisory authority, in particular in the EU Member State where you reside, work or the place of the alleged infringement. You have the right to do so if you consider that the processing of Personal Data relating to you infringes applicable data protection laws.</p>

            <p><strong>DPO DETAILS</strong></p>

            <p>We have appointed a data protection officer (DPO) who is responsible for overseeing questions in relation to this privacy notice. If you have any questions about this privacy notice, including any requests to exercise your legal rights, please contact the DPO using the details set out below.</p>

            <p>Email address: dpo@amrytpharma.com</p>

            <p>Postal address: the Data Protection Officer, Amryt Pharma, 45 Mespil Road, Dublin 4, Ireland</p>

            <p><strong>THIRD-PARTY SERVICES</strong></p>

            <p>This Privacy Statement does not address, and Amryt is not responsible for the terms of use, information or privacy practices of any third parties, including any third party operating any website or service to which our Amryt website links. The inclusion of a link on the Amryt website does not imply our or our affiliates’ endorsement of the linked website or service.</p>

            <p><strong>CHANGES</strong></p>

            <p>Any changes to this statement will be posted on this website so you are always aware of what information we collect, how we use it, and under what circumstances, if any, we disclose it.</p>

            <p>C-LD/EU/0054</p>

        </div>
        <div class="modal-footer">
            <a href="#!" class="modal-close waves-effect waves-green btn-flat">Close</a>
        </div>
    </div>
</footer>
</template>

<script>
import update from '../mixins/update'
export default {
    name: 'Footer',
    data() {
        return {
            user: null
        }
    },
    mixins: [update],
    mounted() {

        /* eslint-disable */
        const modal = document.querySelectorAll('.modal')
        M.Modal.init(modal)

    },
}
</script>

<style>
.page-footer {
    background: rgb(0, 161, 201);
    background: -moz-linear-gradient(319deg, rgba(0, 161, 201, 1) 0%, rgba(2, 178, 209, 1) 100%);
    background: -webkit-linear-gradient(319deg, rgba(0, 161, 201, 1) 0%, rgba(2, 178, 209, 1) 100%);
    background: linear-gradient(319deg, rgba(0, 161, 201, 1) 0%, rgba(2, 178, 209, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00a1c9", endColorstr="#02b2d1", GradientType=1);
    margin-top: 160px;
}

.page-footer h1 {
    font-family: 'Baloo Thambi', cursive;
    font-size: 2em;
    padding: 0;
    margin: 15px 0;
}

.page-footer .links {
    font-family: 'Baloo Thambi', cursive;
    font-size: 1.2em;
    padding: 4px 20px;
    margin: 0 20px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    background: #fff;
    border-radius: 100px;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}

.page-footer .links:hover {
    background: #fcb911;
}

.page-footer a {
    color: #21a3ca;
    font-weight: 300;
}

.page-footer .site-details {
    padding-right: 30px;
}

.page-footer h4 {
    color: #fff;
    font-size: 1.3em;
    margin-top: 18px;
}

.page-footer .links span {
    margin: 0 10px;
}

.page-footer .row {
    margin-bottom: 20px;
}

.page-footer .footer-logo {
    width: 190px;
}

.footer-copyright small {
    margin-left: .5rem;
    font-size: 85%;
}

.modal-content h4 {
    text-align: left;
}

.modal-content p {
    color: #5a5a5a;
    text-align: left;
}

.modal-content ul:not(.browser-default) {
    padding-left: 1em;
    list-style-type: disc;
}

.modal-content ul:not(.browser-default)>li {
    list-style-type: disc;
}

.modal-content strong {
    font-weight: 900;
}

.modal-footer {
    padding: 0 24px !important;
}

.modal-footer a {
    font-family: 'Baloo Thambi', cursive;
    color: #1a727e;
    font-size: 1.5em;
}

.modal.modal-fixed-footer {
    padding: 0;
    height: 70%;
}

@media screen and (max-device-width: 420px) {
    .page-footer {
        margin-bottom: 70px;
    }

    footer .site-name {
        display: none;
    }

    .page-footer .links {
        padding: 6px 16px;
        margin: 10px 8px 16px;
    }

    .site-details {
        text-align: center !important;
        padding-right: 0 !important;
    }
}
</style>
