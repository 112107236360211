<template>
<footer class="page-footer">
    <div class="container">
        <div class="row">
            <!-- <div class="col m6 s12">
                <h4 class="site-name left-align"><strong>HOFHRecipes.com</strong></h4>
                <p class="site-details left-align">We have collected a range of delicious and simple recipes made especially for people with homozygous familial hypercholesterolemia, an uncommon condition that leads to high levels of cholesterol in your blood.</p>
            </div> -->
            <div class="col m12 s12">
                <p><span class="white-text left-align">برعاية:</span></p>
                <!-- <img class="footer-logo" src="@/assets/img/amryt-logo.svg"> -->
                <img class="footer-logo" src="@/assets/img/chiesi-logo-white.svg">
            </div>
        </div>
        <div class="row small">
            <div class="col m12 s12">
                <ul>
                    <li class="links center-align"><strong><a class="modal-trigger" href="#privacyModal">سياسة الخصوصية</a></strong></li>
                    <li class="links center-align"><strong><a class="modal-trigger" href="#termsModal">ارشادات الاستخدام</a></strong></li>
                    <li class="links center-align"><strong><a class="modal-trigger" href="mailto:info@hofhrecipes.com">اتصل بنا</a></strong></li>
                    <li class="links center-align" :value="updateExists" :timeout="0" color="primary">
                    <a @click="refreshApp">
                        <span style="color:#21a3ca">تحديث</span>
                    </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="footer-copyright">
        <div class="container">
            <small class="center-align"> 
                    <span>Dec-21-VV-MED-00971 </span>
            </small>
            <small class="center-align">© {{ new Date().getFullYear() }} HOFHRecipes.com</small>
        </div>
    </div>
    <!-- Modal Structure -->
    <div id="termsModal" class="modal modal-fixed-footer">
        <div class="modal-content">

            <p>   <b>الاستخدام</b></p>
        
        <p> <b>تنطبق هذه الشروط والأحكام على استخدامك للموقع http://hofhrecipes.com ("الموقع").</b></p>

        <p> <b>يتم تشغيل الموقع من قبل Amryt Pharma plc نيابة عن مجموعة Amryt ("Amryt" أو "نحن" أو "نحن").</b> </p>

        <p>يرجى قراءة شروط وأحكام استخدام الموقع بعناية حيث إن استخدامك للموقع يعني موافقتك على الالتزام بها. نحتفظ بالحق في تغيير شروط الاستخدام في أي وقت وسنقوم بنشر أي تغييرات هنا. يُنصح بمراجعة شروط الاستخدام على أساس منتظم حيث سيتم اعتبارك موافقًا على التغييرات إذا واصلت استخدام موقع الويب بعد نشرها.</p>

        <p><b>مسؤوليتك</b></p>

        <p>أنت تتحمل كامل المسؤولية عن استخدامك لهذا الموقع أو الوصول إليه ، بما في ذلك وصولك إلى أي أمريت أو أي مواد أخرى تم الحصول عليها من خلال الموقع ، وتتنازل عن جميع المطالبات أو أسباب الدعوى ضد أمريت أو مسؤوليها أو مديريها أو موظفيها أو وكلائها. والشركات التابعة لها فيما يتعلق بذلك. إذا كنت لا توافق على الالتزام بشروط الاستخدام هذه ، فلا يجب عليك استخدام الموقع بأي شكل من الأشكال.</p>

        <p><b>موارد الطرف الثالث</b></p>

        <p>قد يحتوي هذا الموقع على روابط لمواقع إلكترونية تديرها أطراف أخرى. لا تخضع المواقع المرتبطة لسيطرة شركة أمريت ، ولسنا مسؤولين عن المحتوى المتاح على أي مواقع إنترنت أخرى مرتبطة بهذا الموقع. لا تعني هذه الروابط موافقتنا على المواد الموجودة على أي موقع آخر ، وتخلي شركة Amryt مسؤوليتها فيما يتعلق بوصولك إلى هذه المواقع المرتبطة. قد توفر أمريت روابط لمواقع إنترنت أخرى للتسهيل على المستخدمين ، ويكون الوصول إلى أي مواقع إنترنت أخرى مرتبطة بهذا الموقع على مسؤوليتك الخاصة. يتم توفير المعلومات المنشورة على الموقع من قبل Amryt وكذلك ، عند الإشارة ، من قبل أطراف ثالثة معينة. تتخذ Amryt كل العناية والاحتياطات للتأكد من أن المعلومات المنشورة على الموقع دقيقة عند نشرها وتحديثها بانتظام ، ومع ذلك ، لا تقدم Amryt أي ضمانات أو تعهدات فيما يتعلق بدقتها ، ويجوز لـ Amryt تغيير المعلومات الموجودة على الموقع في أي وقت دون إشعار. لا تتحمل شركة Amryt والشركات التابعة لها أي التزام أو مسؤولية ، إلى الحد الذي تسمح به القوانين ، عن أي أخطاء أو سهو في محتوى الموقع. يجب ألا تعتمد على المعلومات الموجودة على الموقع وتقر بأنه يجب عليك اتخاذ الخطوات المناسبة للتحقق من هذه المعلومات قبل التصرف بناءً عليها.</p>

        <p><b>تنصل</b></p>

        <p>تنشر AMRYT موقع الويب "كما هي" دون أي ضمان من أي نوع ، صريحًا أو ضمنيًا ، فيما يتعلق بتشغيل موقع الويب ، أو دقة المعلومات أو المنتجات أو الخدمات المُحالة إلى الموقع الإلكتروني (بقدر هذه الضمانات تُستثنى بموجب أي قانون ذي صلة) وإلى الحد الذي يسمح به القانون ، لن تكون AMRYT مسؤولة عن أي خسائر أو أضرار مباشرة أو غير مباشرة (بما في ذلك ، على سبيل المثال لا الحصر ، الخسائر المباشرة أو غير المباشرة في الممتلكات الأخرى) ، ناتج عن استخدام الموقع على أي حال. كما أننا نخلي مسؤوليتنا عن أي ضمان بأن المعلومات الموجودة على هذا الموقع ستكون خالية من الانقطاع ، أو أن الموقع خالٍ من الفيروسات أو الديدان أو خيول تروجان أو أي كود آخر يُظهر تلوثًا أو خصائص مدمرة</p>

        <p><b>تعويض</b></p>

        <p>على تعويض شركة Amryt ومسؤوليها ومديريها وموظفيها ووكلائها والشركات التابعة لها والدفاع عنها وحمايتها من الضرر من وضد جميع الخسائر والنفقات والأضرار والتكاليف ، بما في ذلك أتعاب المحاماة المعقولة ، الناشئة عن أو المتعلقة بأي انتهاك لـ هذه الشروط أو أي نشاط متعلق باستخدام الموقع (بما في ذلك على سبيل المثال لا الحصر انتهاك حقوق الملكية الفكرية لأطراف ثالثة في جميع أنحاء العالم أو السلوك غير المشروع أو الإهمال) من قبلك أو من قبل أي شخص آخر يصل إلى الموقع نيابة عنك</p>

        <p><b>حقوق النشر</b></p>

        <p>تمتلك Amryt حقوق الطبع والنشر والعلامات التجارية في المحتوى المنشور على الموقع الإلكتروني ما لم يُذكر خلاف ذلك من خلال إشعار الملكية الخاص بطرف ثالث. الصور والعلامات التجارية والعلامات التجارية محمية أيضًا بموجب قوانين الملكية الفكرية الأخرى ولا يجوز إعادة إنتاجها أو الاستيلاء عليها بأي شكل من الأشكال دون إذن كتابي من أصحابها المعنيين. ما لم يكن محظورًا على وجه التحديد بموجب إشعار منشور على أي صفحة ، يجوز لك عمل نسخة مطبوعة من هذه الأجزاء من الموقع كما قد تطلبها بشكل معقول لاستخدامك الشخصي بشرط أن تكون أي نسخة قد أرفقت بها أي إشعارات ملكية و / أو إخلاء مسؤولية ذات صلة. يحظر أي استخدام آخر.</p>

        <p>نحن لسنا مسؤولين عن محتوى أي موقع إلكتروني آخر دخلت منه إلى موقع الويب أو يمكنك الارتباط به تشعبيًا من الموقع ولا يمكن أن نتحمل المسؤولية عن أي خسارة أو ضرر تتكبده نتيجة لذلك.</p>

        <p><b>لا يقدم موقع الويب الخاص اي نصائح حول الخدمات الطبية أو المهنية</b></p>

        <p>يُقصد بالمحتوى الموجود على موقع الويب أن يكون مصدر معلومات عام فيما يتعلق بالموضوع الذي يتم تناوله. لا تمارس أمريت الطب بشكل مباشر أو غير مباشر ، ولا تقدم المشورة الطبية ، أو توزع الخدمات الطبية عبر الموقع الإلكتروني ، ولا يوجد أي شيء وارد على الموقع الإلكتروني مخصص للتشخيص أو العلاج الطبي. لا ينبغي اعتبار أي معلومات مقدمة كاملة ، ولا ينبغي الاعتماد عليها لاقتراح مسار العلاج لفرد معين. لا ينبغي الاعتماد على المعلومات الواردة من الموقع في قرارات شخصية أو طبية أو قانونية أو فنية أو مالية. لا ينبغي استخدامه بدلاً من زيارة أو مكالمة أو استشارة أو نصيحة طبيبك أو غيره من مقدمي الرعاية الصحية المؤهلين. إذا كان لديك أي أسئلة متعلقة بالرعاية الصحية ، فيرجى استشارة طبيبك أو غيره من مقدمي الرعاية الصحية المؤهلين على الفور.</p>
        
        <p><b>التحديثات</b></p>

        <p>يجوز لشركة أمريت إجراء تحسينات و / أو تغييرات على الموقع في أي وقت. على الرغم من أننا نحاول تحديث المعلومات بشكل دوري على هذا الموقع ، إلا أن المعلومات والمواد والخدمات المقدمة على هذا الموقع أو من خلاله قد تكون أحيانًا غير دقيقة أو غير كاملة أو قديمة. Amryt ليست ملزمة بتحديث المعلومات الواردة على الموقع ، ولن نكون مسؤولين عن أي فشل في تحديث هذه المعلومات. لا نقدم أي تعهدات بشأن اكتمال أو دقة أو حداثة أي معلومات على الموقع ، ولا نتعهد بتحديث أو مراجعة المعلومات الواردة على الموقع ، سواء كان ذلك نتيجة لمعلومات جديدة أو أحداث أو ظروف مستقبلية أو غير ذلك. تقع على عاتقك مسؤولية التحقق من أي معلومات واردة على الموقع قبل الاعتماد عليها.</p>

        <p><b>القانون الذي يحكم</b></p>

        <p>تخضع شروط الاستخدام هذه لقوانين إنجلترا وويلز وأنت توافق على أن يكون للمحاكم الإنجليزية الاختصاص الحصري في أي نزاع.</p>

        <p>إلى الحد الذي يتبين فيه أن أي جزء من شروط الاستخدام هذه غير صالح أو غير قانوني أو غير قابل للتنفيذ من قبل أي محكمة ذات اختصاص قضائي مختص ، يجب فصل هذا الجزء إلى هذا الحد عن الشروط المتبقية التي تظل جميعها سارية المفعول بالكامل كما هو مسموح به. بموجب القانون.</p>
        
        </div>
        <div class="modal-footer">
            <a href="#!" class="modal-close waves-effect waves-green btn-flat">أغلق</a>
        </div>
    </div>

    <!-- Modal Structure -->
    <div id="privacyModal" class="modal modal-fixed-footer">
        <div class="modal-content">
            <p>يوضح بيان الخصوصية ("بيان الخصوصية") التزام شركة بالامتثال للقوانين واللوائح الدولية والفيدرالية والمحلية المعمول بها والتي تحمي البيانات الشخصية التي نعالجها. يعتبر أي شخص يصل إلى موقع الويب أو يتصفحه أو يستخدمه بطريقة أخرى ، إما يدويًا أو عبر جهاز أو برنامج آلي."</p>

            <p>يلتزم جميع المستخدمين بشروط هذا الإشعار وأي شروط استخدام محددة مرتبطة بالموقع. استخدامك للموقع يعني موافقتك على جمع واستخدام والكشف عن بياناتك الشخصية كما هو موضح في بيان الخصوصية هذا.</p>

            <p>"البيانات الشخصية" تعني أي معلومات تتعلق بشخص طبيعي محدد أو يمكن التعرف عليه ؛ الشخص الذي يمكن التعرف عليه هو الشخص الذي يمكن تحديد هويته ، بشكل مباشر أو غير مباشر ، على وجه الخصوص بالرجوع إلى رقم التعريف أو إلى عامل أو أكثر خاص بهويته الجسدية أو الفسيولوجية أو العقلية أو الاقتصادية أو الثقافية أو الاجتماعية. نحن ندرك أهمية خصوصيتك ونلتزم باحترامها وحمايتها. ينطبق بيان الخصوصية هذا على جمعنا للمعلومات الشخصية واستخدامها من خلال موقعنا الإلكتروني ومن خلال تفاعلاتنا المتعلقة بالأعمال التجارية معك ، والتي قد تجمعها شركة أو تتلقاها في سياق أنشطتها التجارية العادية.</p>
        
            <p>سيخضع جمع البيانات الشخصية وتخزينها وتكييفها أو تغييرها ونقلها واستخدامها وحذفها ومعالجتها الأخرى على المستوى الوطني والدولي والإقليمي لقوانين ولوائح حماية البيانات ، مثل لائحة حماية البيانات العامة للاتحاد الأوروبي (GDPR)</p>
        
            <p><strong>الغرض الذي نحتفظ بمعلوماتك من أجله</strong></p>

            <p> لن تقوم أمريت بجمع أي بيانات شخصية عنك ، إلا إذا تم توفيرها عن علم وطواعية من قبلك.</p>
            <p> نجمع بياناتك الشخصية للأغراض التالية:</p>
            <p> عندما تقوم بالتسجيل للحصول على حساب مستخدم على الموقع: لغرض إدارة حسابك والمصادقة عليه</p>
            <p> عند إدخال أي معلومات (بما في ذلك المعلومات الصحية) عبر على الموقع الإلكتروني: لأغراض تمكينك من تتبع إحصائياتك صحتك وإدارة ملف تعريف المستخدم الخاص بك.</p>
            <p> يمكنك تحديث وتعديل وحذف هذه المعلومات كما يحلو لك في أي وقت.</p>
            <p> نقوم أيضًا بجمع بعض المعلومات الأساسية عند زيارة موقع الويب مثل اسم نطاق مزود خدمة الإنترنت ، والصفحات التي تدخل إليها على الموقع ، ومتى. نحن نستخدم هذه المعلومات فقط لتحليل استخدام الموقع للمساعدة في توجيه التحسينات.</p>

            <p><strong>الكشف عن معلوماتك لأطراف ثالثة</strong></p>
            <p> قد نكشف عن معلوماتك للأطراف الثالثة التالية لتقديم الخدمات المرتبطة بالموقع:</p>
            <p><ul><li>مقدمو خدمات الطرف الثالث الذين يدعمون وظائف الموقع</li></ul></p>
            <p>قد يتم تخزين عمليات البيانات الشخصية على خوادم امريت في الولايات المتحدة. إذا كنت متواجدًا خارج الولايات المتحدة ، فيرجى العلم بأن البيانات الشخصية التي نجمعها قد تتم معالجتها وتخزينها في الولايات المتحدة (قد تقدم قوانين حماية البيانات والخصوصية في الولايات المتحدة مستوى أقل من الحماية مقارنة ببلدك / منطقة).</p>
            <p>باستخدام موقع الويب وإرسال بياناتك الشخصية ، فإنك توافق على نقل و / أو تخزين و / أو معالجة بياناتك الشخصية في الولايات المتحدة.</p>
            <p>قد نكشف أيضًا عن معلوماتك إذا اعتقدنا بحسن نية أننا مطالبون بالكشف عنها من أجل الامتثال لأي قانون معمول به ، أو أمر استدعاء ، أو أمر تفتيش ، أو أمر محكمة أو تنظيمي أو أي متطلبات قانونية أو قانونية أخرى.</p>
            <p>لن نفصح عن بياناتك الشخصية لأي طرف ثالث ، باستثناء ما هو موضح أعلاه.</p>

            <p><strong>الأساس القانوني للمعالجة</strong></p>
            <p>عندما تقوم بالتسجيل للحصول على حساب مستخدم أو تتفاعل مع موقعنا ، فإن هذه المعالجة ضرورية لإدارة حسابك وأداء الموقع.</p>
            <p>عندما تقوم بإدخال بيانات شخصية ، فإننا نعالج هذه البيانات على أساس موافقتك المسبقة. عندما تقوم بإدخال معلومات في التطبيق (بما في ذلك المعلومات الصحية) ، فإنك تقدم موافقتك الصريحة على تخزين هذه المعلومات لأغراض الحفاظ على ملفك الشخصي وتعزيز تجربة المستخدم الخاصة بك. لن يتم استخدام هذه المعلومات من قبل امريت لأي أغراض أخرى أو مشاركتها بواسطة امريت مع أي أطراف ثالثة بخلاف الأطراف الثالثة المذكورة أعلاه.</p>
            <p>عندما نستفيد و / أو نجمع ملفات تعريف الارتباط ومعرفات الجهاز وبيانات الموقع والبيانات من البيئة وتقنيات التتبع الأخرى ، فإننا نعالج هذه البيانات على أساس موافقتك.</p>

            <p><strong>سياسة الحجز والحذف</strong></p>
            <p>نحتفظ ببياناتك الشخصية طالما أنك تحتفظ بحساب مستخدم.</p>
            <p>يمكنك حذف حساب المستخدم الخاص بك بنفسك في أي وقت من خلال الموقع. عندما تحذف حساب المستخدم الخاص بك ، سيتم مسح جميع المعلومات التي أدخلتها والمرتبطة بملف تعريف المستخدم الخاص بك بشكل دائم. لن نخزن بياناتك الشخصية بعد الآن (باستثناء الحد المطلوب منا للاحتفاظ بأي بيانات شخصية بموجب القانون).</p>
            <p>لمعرفة البيانات الشخصية التي نحتفظ بها والمتعلقة بك أو إذا كنت تواجه صعوبة في تحديث أو تعديل أو حذف المعلومات من ملفك الشخصي</p>

            <p><strong>روابط التعريف</strong></p>
            <p>عندما تزور موقع الويب ، فإنه يستخدم ملفات تعريف الارتباط وهي عبارة عن أجزاء صغيرة من المعلومات تسمح لهم بالحفاظ على اتصالك بالموقع. قد يستخدم هذا الموقع ملفات تعريف الارتباط لاكتشاف نوع الجهاز لديك من أجل تقديم المحتوى بأفضل طريقة ، من أجل تبديل اللغة و / أو لأغراض أخرى. لا تجمع ملفات تعريف الارتباط هذه أو تخزن أي معلومات تعريف شخصية. يمكنك رفض استخدام ملفات تعريف الارتباط.</p>

            <p><strong>كيفية رفض استخدام ملفات تعريف الارتباط</strong></p>
            <p>يمكنك رفض استخدام ملفات تعريف الارتباط عن طريق تحديد الإعدادات المناسبة في متصفحك. ومع ذلك ، إذا قمت بذلك ، فقد تفقد بعض الوظائف المفيدة مثل التخصيص و "احتفظ بتسجيل الدخول" وميزات "تذكرني".</p>

            <p><strong>كيفية تعطيل ملفات تعريف الارتباط في متصفحك</strong></p>
            <p><strong>تحليلات جوجل</strong></p>

            <p>يستخدم موقع الويب هذا تحليلات جوجل  ، وهي خدمة تحليلات الويب التي تقدمها شركة جوجل ملفات تعريف الارتباط (ملفات نصية موضوعة على جهاز الكمبيوتر الخاص بك) لمساعدة مشغلي الموقع على تحليل كيفية استخدام المستخدمين للموقع. سيتم نقل المعلومات التي تم إنشاؤها بواسطة ملف تعريف الارتباط حول استخدامك للموقع (بما في ذلك عنوان اي بي الخاص بك) إلى جوجل وتخزينها على خوادم في الولايات المتحدة. ستستخدم جوجل هذه المعلومات لغرض تقييم استخدامك للموقع ، وتجميع التقارير عن نشاط موقع الويب لمشغلي مواقع الويب ، وتقديم خدمات أخرى تتعلق بنشاط الموقع واستخدام الإنترنت. يجوز لجوجل أيضًا نقل نيابة عن امريت باستخدام هذه المعلومات إلى جهات خارجية عند الاقتضاء بموجب القانون ، أو حيث تقوم هذه الأطراف الثالثة بمعالجة المعلومات</p>

            <p><strong>الأمان</strong></p>
            <p>نقوم بتنفيذ الضمانات التقنية والتنظيمية المناسبة للحماية من المعالجة غير المصرح بها أو غير القانونية للبيانات الشخصية وضد التدمير العرضي أو غير القانوني أو الفقد أو التغيير أو الكشف غير المصرح به أو الوصول إلى البيانات الشخصية.</p>
            <p>ومع ذلك ، فإن نقل المعلومات عبر الإنترنت ليس آمنًا تمامًا. لا يمكننا ضمان أمان بياناتك المنقولة إلى خدماتنا عبر الإنترنت ؛ أي انتقال يكون على مسؤوليتك الخاصة. بمجرد تلقينا معلوماتك ، سنتخذ خطوات معقولة لاستخدام الإجراءات والميزات الأمنية لمحاولة منع الوصول غير المصرح به أو التعديل أو الكشف.</p>
            <p>إذا تواصلت معنا باستخدام منصة ويب غير آمنة ، فأنت تتحمل مخاطر اعتراض هذه الاتصالات بيننا أو عدم تلقيها أو تأخيرها أو تلفها أو تلقيها من قبل أشخاص بخلاف المستلم المقصود. ومع ذلك ، سنتخذ جميع الخطوات المعقولة (بما في ذلك التدابير الفنية والتنظيمية المناسبة) لحماية بياناتك.</p>
            <p>في حالة الوصول إلى بياناتك الشخصية أو فقدها أو سرقتها من قبل طرف ثالث غير مصرح له ، سنبذل جهودًا معقولة تجاريًا لإعلامك بالقدر الذي يقتضيه القانون والإفصاح لك عن البيانات الشخصية التي تم الوصول إليها / الكشف عنها باستخدام معلومات الاتصال المقدمة إلينا أو بوسائل أخرى معقولة.</p>

            <p><strong>حقوقك</strong></p>
            <p>فيما يتعلق ببياناتك الشخصية ، في ظل ظروف معينة ، لديك الحق في :</p>
            <p>اطلب الوصول إلى معلوماتك الشخصية (المعروف باسم "طلب الوصول إلى موضوع البيانات"). يمكّنك هذا من تلقي نسخة من المعلومات الشخصية التي نحتفظ بها عنك والتحقق من أننا نعالجها بشكل قانوني.</p>
            <p>طلب تصحيح المعلومات الشخصية التي نحتفظ بها عنك. يمكّنك هذا من تصحيح أي معلومات غير كاملة أو غير دقيقة نحتفظ بها عنك.</p>
            <p>طلب محو معلوماتك الشخصية ، في ظروف معينة. يمكّنك هذا من مطالبتنا بحذف المعلومات الشخصية أو إزالتها في حالة عدم وجود سبب وجيه لمواصلة معالجتها. لديك أيضًا الحق في مطالبتنا بحذف أو إزالة معلوماتك الشخصية حيثما كنت قد مارست حقك في الاعتراض على المعالجة (انظر أدناه).</p>
            <p>الاعتراض على معالجة معلوماتك الشخصية ، في ظروف معينة ، حيث نعتمد على مصلحة مشروعة (أو مصالح طرف ثالث) على أسس تتعلق بوضعك الخاص.</p>
            <p>اطلب تقييد معالجة معلوماتك الشخصية في ظروف معينة. يمكّنك هذا من مطالبتنا بتعليق معالجة معلوماتك الشخصية ، على سبيل المثال إذا كنت تريد منا إثبات دقتها أو سبب معالجتها.</p>
            <p>اطلب نقل معلوماتك الشخصية إلى طرف آخر ، في ظروف معينة</p>
            <p>سحب الموافقة في أي وقت ، حيث نعتمد على الموافقة لمعالجة بياناتك الشخصية. إذا سحبت موافقتك على استمرار استخدام صورك ، فسنحذفها على الفور. ومع ذلك ، لن يؤثر ذلك على قانونية أي معالجة يتم إجراؤها قبل سحب موافقتك. سنخبرك إذا كان هذا هو الحال في الوقت الذي تسحب فيه موافقتك</p>
            <p>إذا كنت ترغب في ممارسة أي من الحقوق الموضحة أعلاه ، فيرجى الاتصال ب </p>

            <p><a href="mailto:info@hofhr.com">info@hofhr.com</a></p>

            <p><strong>الحق في تقديم شكوى قبل هيئة حماية البيانات</strong></p>
            <p>نشجعك على الاتصال بنا مباشرة والسماح لنا بالعمل معك لمعالجة مخاوفك. ومع ذلك ، يحق لك تقديم شكوى إلى سلطة إشرافية مختصة بحماية البيانات ، لا سيما في الدولة العضو في الاتحاد الأوروبي حيث تقيم أو تعمل أو في مكان الانتهاك المزعوم. لديك الحق في القيام بذلك إذا كنت ترى أن معالجة البيانات الشخصية المتعلقة بك تنتهك قوانين حماية البيانات المعمول بها</p>
            
            <p><strong>تفاصيل DPO  مسئول حماية البيانات</strong></p>
            <p>لقد قمنا بتعيين مسؤول حماية البيانات المسؤول عن الإشراف على الأسئلة المتعلقة بإشعار الخصوصية هذا. إذا كانت لديك أي أسئلة حول إشعار الخصوصية هذا ، بما في ذلك أي طلبات لممارسة حقوقك القانونية ، فيرجى الاتصال بـعنوان البريد الإلكتروني باستخدام التفاصيل الموضحة أدناه.</p>
            <p>dpo@amrytpharma.com :</p>
            <p>العنوان البريدي: مسؤول حماية البيانات ، أمريت فارما ، 45 طريق مسبيل ، دبلن 4 ، أيرلندا</p>

            <p><strong>خدمات الطرف الثالث</strong></p>
            <p>لا يتناول بيان الخصوصية هذا ، ولا تتحمل امريت مسؤولية شروط الاستخدام أو المعلومات أو ممارسات الخصوصية الخاصة بأي طرف ثالث ، بما في ذلك أي طرف ثالث يقوم بتشغيل أي موقع ويب أو خدمة يرتبط بها موقع امريت. لا يعني تضمين رابط على الموقع الإلكتروني تأييدنا أو تأييد الشركات التابعة لنا للموقع أو الخدمة المرتبطة </p>

            <p><strong>التغييرات</strong></p>
            <p>سيتم نشر أي تغييرات على هذا البيان على هذا الموقع حتى تكون دائمًا على دراية بالمعلومات التي نجمعها ، وكيف نستخدمها ، وتحت أي ظروف ، إن وجدت ، نفصح عنهاسيتم نشر أي تغييرات على هذا البيان على هذا الموقع حتى تكون دائمًا على دراية بالمعلومات التي نجمعها ، وكيف نستخدمها ، وتحت أي ظروف ، إن وجدت ، نفصح عنها</p>
        </div>
        <div class="modal-footer">
            <a href="#!" class="modal-close waves-effect waves-green btn-flat">أغلق</a>
        </div>
    </div>
</footer>
</template>

<script>
import update from '../mixins/update'
export default {
    name: 'Footer',
    data() {
        return {
            user: null
        }
    },
    mixins: [update],
    mounted() {

        /* eslint-disable */
        const modal = document.querySelectorAll('.modal')
        M.Modal.init(modal)

    },
}
</script>

<style>
.page-footer {
    background: rgb(0, 161, 201);
    background: -moz-linear-gradient(319deg, rgba(0, 161, 201, 1) 0%, rgba(2, 178, 209, 1) 100%);
    background: -webkit-linear-gradient(319deg, rgba(0, 161, 201, 1) 0%, rgba(2, 178, 209, 1) 100%);
    background: linear-gradient(319deg, rgba(0, 161, 201, 1) 0%, rgba(2, 178, 209, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00a1c9", endColorstr="#02b2d1", GradientType=1);
    margin-top: 160px;
}

.page-footer h1 {
    font-family: 'Baloo Thambi', cursive;
    font-size: 2em;
    padding: 0;
    margin: 15px 0;
}

.page-footer .links {
    font-family: 'Baloo Thambi', cursive;
    font-size: 1.2em;
    padding: 4px 20px;
    margin: 0 20px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    background: #fff;
    border-radius: 100px;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}

.page-footer .links:hover {
    background: #fcb911;
}

.page-footer a {
    color: #21a3ca;
    font-weight: 300;
}

.page-footer .site-details {
    padding-right: 30px;
}

.page-footer h4 {
    color: #fff;
    font-size: 1.3em;
    margin-top: 18px;
}

.page-footer .links span {
    margin: 0 10px;
}

.page-footer .row {
    margin-bottom: 20px;
}

.page-footer .footer-logo {
    width: 190px;
}

.footer-copyright small {
    margin-left: .5rem;
    font-size: 85%;
}

.modal-content h4 {
    text-align: left;
}

.modal-content p {
    color: #5a5a5a;
    text-align: right;
}

.modal-content ul:not(.browser-default) {
    padding-left: 1em;
    list-style-type: disc;
}

.modal-content ul:not(.browser-default)>li {
    list-style-type: disc;
}

.modal-content strong {
    font-weight: 900;
}

.modal-footer {
    padding: 0 24px !important;
}

.modal-footer a {
    font-family: 'Baloo Thambi', cursive;
    color: #1a727e;
    font-size: 1.5em;
}

.modal.modal-fixed-footer {
    padding: 0;
    height: 70%;
}

@media screen and (max-device-width: 420px) {
    .page-footer {
        margin-bottom: 70px;
    }

    footer .site-name {
        display: none;
    }

    .page-footer .links {
        padding: 6px 16px;
        margin: 10px 8px 16px;
    }

    .site-details {
        text-align: center !important;
        padding-right: 0 !important;
    }
}
</style>
