export default [
    {
      title: "Pommes, compote de pommes",
      serves: "1 petite",
      fat: 0,
      category: "fruit"
    },
    {
      title: "Bananes",
      serves: "1 petite",
      fat: 0,
      category: "fruit"
    },
    {
      title: "Petits fruits",
      serves: "1 tasse (128g)",
      fat: 0,
      category: "fruit"
    },
    {
      title: "Raisins",
      serves: "1 tasse (128g)",
      fat: 0,
      category: "fruit"
    },
    {
      title: "Kiwi",
      serves: "1 moyenne",
      fat: 0,
      category: "fruit"
    },
    {
      title: "Oranges, mandarines",
      serves: "1 petite",
      fat: 0,
      category: "fruit"
    },
    {
      title: "Pêches, nectarines",
      serves: "1 petite",
      fat: 0,
      category: "fruit"
    },
    {
      title: "Cocktail aux fruits et autres fruits en conserve (sauf les pamplemousses)",
      serves: "1/2 tasse (64g)",
      fat: 0,
      category: "fruit"
    },
    {
      title: "Raisins secs, autres fruits secs",
      serves: "1 once (28g)",
      fat: 0,
      category: "fruit"
    },
    {
      title: "Croustilles de banane",
      serves: "1 once (28g)",
      fat: 9,
      category: "fruit"
    },
    {
      title: "Brocoli",
      serves: "1 tasse (128g)",
      fat: 0,
      category: "vegetables"
    },
    {
      title: "Carottes",
      serves: "1 tasse (128g)",
      fat: 0,
      category: "vegetables"
    },
    {
      title: "Chou-fleur",
      serves: "1 tasse (128g)",
      fat: 0,
      category: "vegetables"
    },
    {
      title: "Haricots verts",
      serves: "1 tasse (128g)",
      fat: 0,
      category: "vegetables"
    },
    {
      title: "Salades vertes, épinards",
      serves: "1 tasse (128g)",
      fat: 0,
      category: "vegetables"
    },
    {
      title: "Tomates, produits de tomates en conserve",
      serves: "1 grosse",
      fat: 0,
      category: "vegetables"
    },
    {
      title: "Oignons",
      serves: "1 petite",
      fat: 0,
      category: "vegetables"
    },
    {
      title: "Pommes de terre, ignames",
      serves: "1 moyenne ",
      fat: 0,
      category: "vegetables"
    },
    {
      title: "Courgette",
      serves: "1 moyenne",
      fat: 0,
      category: "vegetables"
    },
    {
      title: "Légumes congelés sans matières grasses ajoutées",
      serves: "1 tasse (128g)",
      fat: 0,
      category: "vegetables"
    },
    {
      title: "Haricots en conserve, haricots frits sans gras, fèves au four végétariennes",
      serves: "1/2 tasse (64g)",
      fat: 1,
      category: "vegetables"
    },
    {
      title: "Blancs d’œuf, substitut d’œuf",
      serves: "1 1/4 tasse (425g)",
      fat: 0,
      category: "meat"
    },
    {
      title: "Dinde hachée à 99 % sans gras",
      serves: "4 onces",
      fat: 1,
      category: "meat"
    },
    {
      title: "Poitrine de dinde, charcuterie de dinde",
      serves: "1 once (28g)",
      fat: 1,
      category: "meat"
    },
    {
      title: "Saucisses à hot dog faibles en gras, comme Ball Park®",
      serves: "1 saucisse",
      fat: 1,
      category: "meat"
    },
    {
      title: "Thon dans l’eau",
      serves: "2 onces (56g)",
      fat: 1.5,
      category: "meat"
    },
    {
      title: "Poisson blanc maigre, comme la sole ou le tilapia",
      serves: "4 onces (114g)",
      fat: 2,
      category: "meat"
    },
    {
      title: "Crevettes, pétoncles, autres mollusques et crustacés",
      serves: "3 onces (85g)",
      fat: 0.5,
      category: "meat"
    },
    {
      title: "Lait écrémé",
      serves: "8 onces (236ml)",
      fat: 0,
      category: "dairy"
    },
    {
      title: "Fromage cottage ou ricotta sans gras",
      serves: "1/2 tasse (170g)",
      fat: 0,
      category: "dairy"
    },
    {
      title: "Yogourt sans gras ou yogourt grec",
      serves: "8 onces (236ml)",
      fat: 0,
      category: "dairy"
    },
    {
      title: "Fromage sans gras, fromage à la crème, crème sure",
      serves: "1 tranche, 1 once (30mg)",
      fat: 0,
      category: "dairy"
    },
    {
      title: "Moitié-moitié à base de produits laitiers sans gras",
      serves: "1 once (30mg)",
      fat: 0,
      category: "dairy"
    },
    {
      title: "Pain en tranches",
      serves: "1 tranche",
      fat: 0.5,
      category: "bread"
    },
    {
      title: "Petit pain mollet",
      serves: "1 petite",
      fat: 1.5,
      category: "bread"
    },
    {
      title: "Bagel",
      serves: "1 petite, 2 onces (56g)",
      fat: 1,
      category: "bread"
    },
    {
      title: "Pain pita",
      serves: "1 petite",
      fat: 0,
      category: "bread"
    },
    {
      title: "Muffin anglais",
      serves: "2 onces (56g)",
      fat: 1.5,
      category: "bread"
    },
    {
      title: "Tortilla de maïs",
      serves: "1 petite",
      fat: 1,
      category: "bread"
    },
    {
      title: "Tortilla à la farine sans gras",
      serves: "1 petite",
      fat: 0,
      category: "bread"
    },
    {
      title: "Céréales avec moins de 1,5 g de gras par portion, comme les CheeriosTM, les Wheaties® ou les Rice ChexTM ",
      serves: "3/4 tasse (63g)",
      fat: 1.5,
      category: "bread"
    },
    {
      title: "Céréales chaudes, comme Cream of WheatTM",
      serves: "1 once (30ml)",
      fat: 0,
      category: "bread"
    },
    {
      title: "Pâtes nature, riz",
      serves: "1/2 tasse (64g)",
      fat: 0,
      category: "bread"
    },
    {
      title: "Craquelins sans gras",
      serves: "5 crackers",
      fat: 0,
      category: "bread"
    },
    {
      title: "Matzo",
      serves: "1 once (30g)",
      fat: 1,
      category: "bread"
    },
    {
      title: "Galettes de riz nature",
      serves: "1 portion rég",
      fat: 0.5,
      category: "bread"
    },
    {
      title: "Bretzels (grignotine sèche, salée)",
      serves: "1 once (30g)",
      fat: 1,
      category: "bread"
    },
    {
      title: "Enduit à cuisson antiadhésif",
      serves: "1/4 seconde",
      fat: 0,
      category: "baking"
    },
    {
      title: "Enduit à cuisson sans gras à saveur de beurre",
      serves: "1/4 seconde",
      fat: 0,
      category: "baking"
    },
    {
      title: "Gelée, confiture, tartinade aux fruits",
      serves: "2 c. à table",
      fat: 0,
      category: "baking"
    },
    {
      title: "Vinaigrette sans gras",
      serves: "2 c. à table",
      fat: 0,
      category: "baking"
    },
    {
      title: "Citron frais, lime, vinaigres",
      serves: "1 portion",
      fat: 0,
      category: "baking"
    },
    {
      title: "Mayonnaise sans gras",
      serves: "2 c. à table",
      fat: 0,
      category: "baking"
    },
    {
      title: "Condiments, comme le ketchup, la moutarde, la sauce barbecue, la sauce teriyaki, la sauce soya, la salsa, la sauce piquante, la sauce Tabasco®, la relish, les cornichons",
      serves: "indiquée sur la bouteille",
      fat: 0,
      category: "baking"
    },
    {
      title: "Burger aux légumes végétalien Boca® original",
      serves: "1 burger",
      fat: 1,
      category: "other"
    },
    {
      title: "Burrito aux haricots, au riz et au fromage CedarLane® faible en gras (congelé, 1 g de gras par burrito)",
      serves: "1 burrito",
      fat: 1,
      category: "other"
    },
    {
      title: "Beurre d’arachides faible en gras, comme le PB2® et le beurre d’arachides PB2® au chocolat (en poudre) (1 à 1,5 g de gras pour 2 c. à table)",
      serves: "2 c. à table",
      fat: 1.5,
      category: "other"
    },
    {
      title: "Houmous sans gras Cedar®",
      serves: "2 c. à table",
      fat: 0,
      category: "other"
    },
    {
      title: "Soupes (jusqu’à 1,5 g de gras par tasse)",
      serves: "indiquée sur la conserve",
      fat: 1.5,
      category: "other"
    },
    {
      title: "Sauce tomate sans gras, sauce à spaghetti sans gras ou sauce à pizza sans gras",
      serves: "1/4 tasse (85g)",
      fat: 0,
      category: "other"
    }
   ]