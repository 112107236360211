<template>
  <div class="container lifestyle">
    <div class="row small">
      <img class="img-03" src="@/assets/img/img-planner.png" />
      <div class="col s12 m8">
        <h1 class="hero-heading">Smart food substitutions</h1>
        <p
          class="hero-p"
        >Take a look at this list to see which high-fat foods you might be able to swap for a lower-fat or fat-free alternative.</p>
      </div>
    </div>

    <div class="row card-panel">
      <div class="col s12 m2">
        <h4 class="center-align">Dairy</h4>
        <img class="responsive-img sub-img" src="@/assets/img/subs-dairy.png" alt />
      </div>
      <div class="col s12 m10">
        <table class="sub-table">
          <thead>
            <tr>
              <th>
                <h4>if you like…</h4>
              </th>
              <th>
                <h4>try…</h4>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <strong>Whole milk</strong>
              </td>
              <td>Skimmed milk or 1% milk</td>
            </tr>
            <tr>
              <td>
                <strong>Cheese</strong>
              </td>
              <td>Fat-free or low-fat cheese, low-fat mozzarella cheese sticks, grated Parmesan cheese, fat-free cream cheese, fat-free cottage cheese, fat-free ricotta cheese</td>
            </tr>
            <tr>
              <td>
                <strong>Sour cream</strong>
              </td>
              <td>Fat-free sour cream, fat-free plain yogurt</td>
            </tr>
            <tr>
              <td>
                <strong>Cream, half and half, non-dairy creamer</strong>
              </td>
              <td>Fat-free half and half, fat-free non-dairy creamer</td>
            </tr>
            <tr>
              <td>
                <strong>Ice cream</strong>
              </td>
              <td>Sorbet, sherbet, fat-free ice cream and frozen yogurt, fruit ice, popsicles or ice lollies</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row card-panel">
      <div class="col s12 m2">
        <h4 class="center-align">Meat, poultry & fish</h4>
        <img class="responsive-img sub-img" src="@/assets/img/subs-meat.png" alt />
      </div>
      <div class="col s12 m10">
        <table class="sub-table">
          <thead>
            <tr>
              <th>
                <h4>if you like…</h4>
              </th>
              <th>
                <h4>try…</h4>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <strong>Eggs</strong>
              </td>
              <td>Egg substitutes and egg whites</td>
            </tr>
            <tr>
              <td>
                <strong>Cold cuts like bologna, salami</strong>
              </td>
              <td>Extra lean (at least 95% fat-free) turkey, chicken, ham</td>
            </tr>
            <tr>
              <td>
                <strong>Bacon and sausage</strong>
              </td>
              <td>Canadian ham, extra lean (at least 95% fat- free) ham, low-fat turkey bacon, bacon bits</td>
            </tr>
            <tr>
              <td>
                <strong>Fatty cuts of beef like chuck, brisket and rib</strong>
              </td>
              <td>Leaner cuts like round and loin trimmed of visible fat (portion size=deck of cards)</td>
            </tr>
            <tr>
              <td>
                <strong>Pork</strong>
              </td>
              <td>Pork tenderloin trimmed of visible fat</td>
            </tr>
            <tr>
              <td>
                <strong>Ground or minced meats</strong>
              </td>
              <td>Extra lean (at least 95% fat-free) meat</td>
            </tr>
            <tr>
              <td>
                <strong>Dark meat poultry with skin</strong>
              </td>
              <td>Skinless chicken or turkey breast</td>
            </tr>
            <tr>
              <td>
                <strong>Fatty fish like salmon, sardines, trout, canned tuna in oil</strong>
              </td>
              <td>Lean fish like tilapia, flounder, haddock, sole, halibut, all shellfish, canned tuna in water or fried fish</td>
            </tr>
            <tr>
              <td>
                <strong>Fried chicken</strong>
              </td>
              <td>Bread your own skinless chicken breast or fish and oven bake until crispy</td>
            </tr>
            <tr>
              <td>
                <strong>Hot dogs</strong>
              </td>
              <td>All-white meat turkey hot dogs or extra-lean (at least 95% fat-free) hot dogs</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row card-panel">
      <div class="col s12 m2">
        <h4 class="center-align">baked goods</h4>
        <img class="responsive-img sub-img" src="@/assets/img/subs-baking.png" alt />
      </div>
      <div class="col s12 m10">
        <table class="sub-table">
          <thead>
            <tr>
              <th>
                <h4>if you like…</h4>
              </th>
              <th>
                <h4>try…</h4>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <strong>Cake</strong>
              </td>
              <td>Angel food cake</td>
            </tr>
            <tr>
              <td>
                <strong>Cookies</strong>
              </td>
              <td>Fat-free fig cookies, low-fat animal crackers, low-fat vanilla wafers, fat-free devil’s food cookie cakes, sweet flavored rice cakes</td>
            </tr>
            <tr>
              <td>
                <strong>Muffins, donuts, biscuits, cornbread, croissants</strong>
              </td>
              <td>English muffins, bagels, low-fat muffins, low-fat biscuit/cornbread mix</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row card-panel">
      <div class="col s12 m2">
        <h4 class="center-align">fats, oils, &amp; salad dressing</h4>
        <img class="responsive-img sub-img" src="@/assets/img/subs-dressing.png" alt />
      </div>
      <div class="col s12 m10">
        <table class="sub-table">
          <thead>
            <tr>
              <th>
                <h4>if you like…</h4>
              </th>
              <th>
                <h4>try…</h4>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <strong>Butter and margarine on bread</strong>
              </td>
              <td>Fat-free butter spray, light margarine sparingly, jam, light cheese wedges</td>
            </tr>
            <tr>
              <td>
                <strong>Oil for cooking</strong>
              </td>
              <td>Nonstick cooking spray</td>
            </tr>
            <tr>
              <td>
                <strong>Salad dressing</strong>
              </td>
              <td>Fat-free salad dressing, vinegar, lemon, lime, light salad dressing used sparingly</td>
            </tr>
            <tr>
              <td>
                <strong>Mayonnaise</strong>
              </td>
              <td>Fat-free and low-fat mayonnaise, fat-free plain yogurt, mustard, light mayonnaise used sparingly</td>
            </tr>
            <tr>
              <td>
                <strong>Peanut butter</strong>
              </td>
              <td>Low-fat peanut butter</td>
            </tr>
            <tr>
              <td>
                <strong>Oil or butter in baking</strong>
              </td>
              <td>Apple sauce, date, prune puree, yogurt</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row card-panel">
      <div class="col s12 m2">
        <h4 class="center-align">snacks &amp; sweets</h4>
        <img class="responsive-img sub-img" src="@/assets/img/subs-sweets.png" alt />
      </div>
      <div class="col s12 m10">
        <table class="sub-table">
          <thead>
            <tr>
              <th>
                <h4>if you like…</h4>
              </th>
              <th>
                <h4>try…</h4>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <strong>French fries</strong>
              </td>
              <td>Bake your own seasoned potato wedges, 98% fat-free French fries</td>
            </tr>
            <tr>
              <td>
                <strong>Potato and corn chips</strong>
              </td>
              <td>Pretzels, low-fat baked chips, savory flavored rice cakes</td>
            </tr>
            <tr>
              <td>
                <strong>Party crackers</strong>
              </td>
              <td>Fat-free saltines, low-fat crackers, rice crackers</td>
            </tr>
            <tr>
              <td>
                <strong>Nuts</strong>
              </td>
              <td>Popcorn (air popped or light microwave)</td>
            </tr>
            <tr>
              <td>
                <strong>Peanut butter</strong>
              </td>
              <td>Low-fat peanut butter spread</td>
            </tr>
            <tr>
              <td>
                <strong>Chocolate</strong>
              </td>
              <td>Fat-free chocolate syrup, chocolate fat-free ice cream or pudding, fat-free hot cocoa</td>
            </tr>
            <tr>
              <td>
                <strong>Candy bars</strong>
              </td>
              <td>Licorice/liquorice, spice drops, lollipops, marshmallows, gummy candies</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row card-panel">
      <div class="col s12 m2">
        <h4 class="center-align">Others</h4>
        <img class="responsive-img sub-img" src="@/assets/img/subs-other.png" alt />
      </div>
      <div class="col s12 m10">
        <table class="sub-table">
          <thead>
            <tr>
              <th>
                <h4>if you like…</h4>
              </th>
              <th>
                <h4>try…</h4>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <strong>Cream soups</strong>
              </td>
              <td>Low-fat and broth based soups</td>
            </tr>
            <tr>
              <td>
                <strong>Gravy</strong>
              </td>
              <td>Fat-free and low-fat gravy</td>
            </tr>
            <tr>
              <td>
                <strong>Cream sauce on pasta</strong>
              </td>
              <td>Tomato sauce on pasta</td>
            </tr>
            <tr>
              <td>
                <strong>Pizza</strong>
              </td>
              <td>Pizza made with no cheese, less cheese or low-fat cheese</td>
            </tr>
            <tr>
              <td>
                <strong>Ramen noodles</strong>
              </td>
              <td>Noodles, spaghetti, soba noodles</td>
            </tr>
            <tr>
              <td>
                <strong>Granola</strong>
              </td>
              <td>Low-fat granola, other dry cereals</td>
            </tr>
            <tr>
              <td>
                <strong>Avocado</strong>
              </td>
              <td>Cucumber and tomato slices</td>
            </tr>
            <tr>
              <td>
                <strong>Olives</strong>
              </td>
              <td>Cherry tomatoes</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SmartFoodSubstitutions",
  data() {
    return {};
  },
  mounted() {
    document.body.classList.add("sub-page");
  },
  methods: {},
  computed: {},
};
</script>

<style>
.sub-table {
  table-layout: fixed;
  width: 100%;
}

.sub-table td:nth-child(1),
.sub-table th:nth-child(1) {
  width: 30%;
}

.sub-table td:nth-child(2),
.sub-table th:nth-child(2) {
  width: 70%;
}

.lifestyle th {
  padding-top: 0;
}

.lifestyle th,
.lifestyle td {
  float: left;
}

@media screen and (max-device-width: 420px) {
  .sub-img {
    max-width: 50% !important;
    margin: 10px 0;
  }
}
</style>