<template>
<div class="autocomplete" :class="{ 'auto-arabic' : $route.meta.isAR}">
    <input type="text" :placeholder="[[ placeholder ]]" ref="input" v-model="query" @keydown.up="up" @keydown.down="down" @keydown.enter="selectItem">
    <i class="material-icons search-icon">search</i>

    <div class="options" ref="optionsList">
        <ul>
            <li v-for="(match, index) in matches" :key="index" :class="{ 'selected': (selected == index)}" @click="itemClicked(index)" v-text="match.title"></li>
        </ul>
    </div>
    <div class="placeholder" v-if="selectedItem == null" v-text="title"></div>
    <!-- <button class="close" @click="selectedItem = null" v-if="selectedItem">x</button> -->
    <div class="popover" v-show="visible">
    </div>
</div>
</template>

<script>
export default {
    props: {
        items: {
            /* eslint-disable */
            default: [],
            type: Array
        },
        filterby: {
            type: String
        },
        title: {
            default: 'Select One...',
            type: String
        },
        placeholder: {
            default: 'Search for a recipe or ingredient',
            type: String
        },
        shouldReset: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            itemHeight: 39,
            selectedItem: null,
            selected: 0,
            query: '',
            visible: false
        };
    },
    methods: {

        itemClicked(index) {
            this.selected = index;
            this.selectItem();

        },
        selectItem() {
            if (!this.matches.length) {
                return;
            }

            this.selectedItem = this.matches[this.selected];
            this.visible = false;

            if (this.shouldReset) {
                this.query = '';
                this.selected = 0;
            }

            this.$emit('selected', JSON.parse(JSON.stringify(this.selectedItem)));
        },
        up() {
            if (this.selected == 0) {
                return;
            }

            this.selected -= 1;
            this.scrollToItem();
        },
        down() {
            if (this.selected >= this.matches.length - 1) {
                return;
            }

            this.selected += 1;
            this.scrollToItem();
        },
        scrollToItem() {
            this.$refs.optionsList.scrollTop = this.selected * this.itemHeight;
        }
    },
    computed: {
        matches() {
            this.$emit('change', this.query);

            if (this.query == '') {
                return [];
            }

            // Here is where the JSON object categories are checked, in order to filter by ingredients AND title the "or" was added. Works great and doesn't duplicate!
            return this.items.filter((item) => item["ingredients"].toLowerCase().includes(this.query.toLowerCase()) || item["title"].toLowerCase().includes(this.query.toLowerCase()))
        }
    }
}
</script>

<style>
.autocomplete {
    width: 65%;
    position: relative;
    z-index: 100;
}

#dim {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #000;
    top: 0;
    left: 0;
    opacity: 0.6;
    z-index: 100;
}

.autocomplete input[type=text]:not(.browser-default) {
    border: 1px solid #ddd !important;
    border-radius: 70px;
    box-shadow: 0 10px 20px rgba(200, 200, 200, 0.19), 0 6px 6px rgba(200, 200, 200, 0.23);
    height: 67px;
    padding: 0px 30px;
    box-sizing: border-box;
    color: #a7b5b7;
    background: #fff;
}

.search-icon {
    position: absolute;
    right: 30px;
    position: absolute;
    top: 47%;
    transform: translateY(-47%);
    color: #a7b5b7;
}

input[type=text]:not(.browser-default):focus:not([readonly]) {
    box-shadow: 0 2px 5px rgba(200, 200, 200, 0.30), 0 2px 2px rgba(200, 200, 200, 0.30) !important;
    /* box-shadow: none !important; */
}

input::placeholder {
    color: #a7b5b7;
}

.close {
    position: absolute;
    right: 2px;
    top: 4px;
    background: none;
    border: none;
    font-size: 30px;
    color: lightgrey;
    cursor: pointer;
}

.options {
    max-height: 400px;
    overflow-y: scroll;
    position: absolute;
    top: 74px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 100;
    width: 93%;
    border-radius: 7px;
    box-shadow: 0 2px 5px rgba(200, 200, 200, 0.30), 0 2px 2px rgba(200, 200, 200, 0.30) !important;
}

.options ul li:first-child {
    /* border: none; */
}

.options ul li {
    border-bottom: 1px solid lightgray;
    padding: 19px;
    cursor: pointer;
    background: #fff;
}

.options ul li.selected {
    background: #e4fcff;
}

.options ul li:not(.selected):hover {
    background: #03bad4;
    color: #fff;
}

.options ul {
    list-style-type: none;
    text-align: left;
    padding-left: 0;
    width: 100%;
    margin: 0;
}

.options ul li.selected {
    /* background: #58bd4c; */
    /* color: #fff; */
    font-weight: 600;
}


/* ARABIC */

.auto-arabic .search-icon{
    right: unset;
    left: 30px
}
</style>
