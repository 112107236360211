<template>
    <div class="login container card-panel">
        <h1 class="center">Forgot Password?</h1>
        <p>Enter your email below and we'll send you the reset link.</p>

        <form @submit.prevent="forgot">

            <div class="input-field">
                <label for="email">Email:</label>
                <input type="email" name="email" v-model="email">
            </div>
            <p class="red-text center" v-if="feedback">{{feedback}}</p>
            <div class="field center">
                <button class="btn btn-large mt30">Send</button>
            </div>
        </form>
  </div>
</template>

<script>
import firebase from 'firebase'
export default {
    name: 'login',
    data(){
        return {
            email: null,
            password: null,
            feedback: null
        }
    },
    methods : {
        forgot(){
            /* eslint-disable */
            // console.log(this.email, this.password)

            if(this.email){

                firebase.auth().sendPasswordResetEmail(this.email).then(cred => {
                
                    /* eslint-disable */
                    var toastHTML = '<span>Reset email sent!</span>';
                    M.toast({html: toastHTML});
                    this.$router.push({name: 'home'})

                }).catch(function(error) {
                    this.feedback = "Something went wrong try again later"
                });
            }else{
                this.feedback = "Please enter your account email address"
            }
        }
    }
}
</script>

<style>
    .login{ 
        max-width: 400px !important;
        margin: 50px auto !important;
    }

    .signup-left .btn{
        background: #fff !important;
    }

    .signup-left .field .btn, .login .field .btn{
        background: #01b1d0 !important;
    }

    .login .googleLogin{
        color: #2c3e50;
        width: 90%;
        padding: 7px 30px;
        height: auto;
    }

    .login button svg{
        max-width: 20px;
        margin-top: 8px;
        float: left;
    }

    .login h1{
        font-size:  2em;
        margin: 1em 0 1.5em;
    }

    .login .field label{
        float: left;
    }

    .forgot-password span{
        width: 100%;
        text-align: right;
        display: block;
        font-size: .85em;
        color: #01b1d0;
        font-weight: 700;
    }

    .mt{
        margin-top: 2em;
    }
</style>