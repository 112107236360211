<template>
  <div id="app">
    <Navbar v-if="$route.meta.isEN" />
    <NavbarItaliano v-if="$route.meta.isIT" />
    <NavbarArabic v-if="$route.meta.isAR" />
    <NavbarCanadian v-if="$route.meta.isCA" />
    <NavbarFrench v-if="$route.meta.isFR" />
      <router-view></router-view>
    <Footer v-if="$route.meta.isEN" />
    <FooterArabic v-if="$route.meta.isAR" />
    <FooterCanadian v-if="$route.meta.isCA" />
    <FooterFrench v-if="$route.meta.isFR" />
  </div>
</template>

<script>
import Navbar from "./components/Navbar";
import NavbarItaliano from "./components/NavbarItaliano";
import NavbarArabic from "./components/NavbarArabic";
import NavbarCanadian from "./components/NavbarCanadian";
import NavbarFrench from "./components/NavbarFrench";
import Footer from "./components/Footer";
import FooterArabic from "./components/FooterArabic";
import FooterCanadian from "./components/FooterCanadian";
import FooterFrench from "./components/FooterFrench";
import 'materialize-css/dist/css/materialize.min.css'
import 'materialize-css/dist/js/materialize.min.js'
import "@/assets/css/global.css";

export default {
  name: "App",
  components: {
    Navbar,
    NavbarItaliano,
    NavbarArabic,
    NavbarCanadian,
    NavbarFrench,
    Footer,
    FooterArabic,
    FooterCanadian,
    FooterFrench
  },
  created() {
  },
  mounted() {

    let meta = this.$route.meta
    var htmlRoot = document.getElementsByTagName( 'html' )[0];

    // IF IN ARABIC PAGE SET HTML DIRECTION TO BE RIGHT TO LEFT
    if(meta.isAR){
      htmlRoot.setAttribute("dir","rtl");
      htmlRoot.setAttribute("lang","ar");
    } else{
      htmlRoot.setAttribute("dir","ltr");
      htmlRoot.setAttribute("lang","en");
    }
  }
};
</script>

<style scoped>
/* @import './assets/js/materialize.min.js'; */
/* @import './assets/css/materialize.min.css'; */

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
