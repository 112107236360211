<template>
    <div class="container categories">
      <div class="row">
        <img class="img-02" src="@/assets/img/favourites-img.png">
        <h1 class="hero-heading">All recipes</h1>
        <div class="col s6 m3" v-for="(data, index) in orderedRecipes" :key="index">
          <div class="card hoverable small">
            
            <router-link :to="'/recipe/' + data.slug">
              <div class="card-image">
                <div v-if="data.hasImage">
                  <img :src="require(`@/assets/img/recipes/${data.img}.jpg`)">
                </div>
                <div v-else>
                  <img src="@/assets/img/default-recipe.jpg">
                </div>
                <span class="difficulty" :class="data.difficulty">{{data.difficulty}}</span>
                <div class="blob">
                  <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                    <path d="M55.4,-66.5C68,-55.5,71.7,-34.4,75.3,-13.4C78.8,7.6,82.3,28.6,74.6,44.3C67,60,48.2,70.6,28.9,76.2C9.6,81.8,-10.3,82.5,-26.7,75.6C-43.1,68.7,-56,54.2,-67.2,37.5C-78.4,20.8,-87.8,1.8,-86.8,-17.2C-85.7,-36.3,-74.1,-55.4,-57.9,-65.8C-41.7,-76.2,-20.8,-78,0.3,-78.3C21.4,-78.7,42.8,-77.6,55.4,-66.5Z" transform="translate(100 100)" />
                  </svg>
                  <div class="fatBlobText">
                    <p>Fat:</p>
                    <p>{{data.fat}}g</p>
                  </div>
                </div>
              </div>
              <div class="card-content">
                <h5 class="card-header">{{data.title}}</h5>
                <p v-if="data.serves"> <strong>Serves:</strong> {{data.serves}}</p> <p><strong>Calories:</strong> {{data.calories}}kcals</p>
              </div>
            </router-link>
          
          </div>
        </div>
        <!-- <div class="left-align nofavs card" v-if="nofavs">
          <h4>You have not selected any favourties</h4>
          <p>To add recipes to your categories list, open up and recipes and click the "Add to categories"button</p>
        </div> -->
      </div>
    </div>
</template>

<script>
    import getRecipes from '@/assets/data/recipes-v03';
    import _ from 'lodash';  
    export default{
      //custom option named recipes
        data(){
          return{
              recipes: [],
          }
        },
        mounted(){
          document.body.classList.add("sub-page");
        },    
        created(){
          this.recipes = getRecipes;
        },
        computed: {
          orderedRecipes: function () {
          return _.orderBy(this.recipes, 'title')
          }
        }
    }
</script>

<style>


</style>
