<template>
<div class="container lifestyle">

    <div class="row small">
        <img class="img-03" src="@/assets/img/img-carbs.png">
        <div class="col s12 m8">
            <h1 class="hero-heading">Combining carb control with your low-fat eating plan</h1>
            <p class="hero-p"><strong>If you already follow a carbohydrate-controlled or low-carbohydrate eating
                    plan, this handout will give you some tips for combining your current eating
                    style with your low-fat eating plan.</strong></p>

            <p class="hero-p">Keep in mind your Max Daily Fat, which was provided to you during your nutrition
                consultation. Remember that all fats count! </p>
        </div>
    </div>

    <div class="row small">
        <div class="col s12">
            <h3>1. Start with meals that feature low-fat foods with little or no carbohydrates, such as</h3>
        </div>
        <div class="col s12 m6">
            <div class="card-panel">
                <h4>Lean proteins</h4>
                <p class="hero-p">
                <ul>
                    <li> Chicken and turkey breast without the skin</li>
                    <li> Lean white fish</li>
                    <li> All shellfish, including shrimp and scallops</li>
                    <li> Ham and pork loin, trimmed of all visible fat</li>
                    <li> Extra lean (at least 95% fat-free) ground beef,
                        pork, chicken or turkey</li>
                    <li> Lean cuts of beef like round and loin trimmed
                        of all visible fat</li>
                    <li> Egg whites and egg substitute</li>
                    <li> Tofu</li>
                    <li> Low-fat jerky</li>
                    <li> Peanut Butter Powder</li>
                    <li> Low-fat meatless alternatives</li>
                </ul>
                </p>
            </div>
        </div>
        <div class="col s12 m6">
            <div class="card-panel">
                <h4>Fat-free and low-fat dairy products</h4>
                <p class="hero-p">
                <ul>
                    <li> Low-fat mozzarella string cheese</li>
                    <li> Fat-free cottage cheese or ricotta cheese</li>
                    <li> Fat-free plain or no sugar added yogurt or Greek yogurt</li>
                    <li> Fat-free or low-fat versions of cheddar, swiss,
                        and other natural cheeses</li>
                    <li> Skim or low-fat milk or milk alternative</li>
                </ul>
                </p>
            </div>
            <img class="responsive-img carb-dairy-img" src="@/assets/img/carbs-dairy.png" alt="">
        </div>
    </div>

    <div class="row small">
        <div class="col s12">
            <h3>2. Add in some healthy carbohydrates and lower-carbohydrates choices like</h3>
        </div>
        <div class="col s12 m6">
            <div class="card-panel">
                <h4>Non-Starchy Vegetables</h4>

                <p class="hero-p">
                <ul>
                    <li>Asparagus, broccoli, green beans, tomatoes, cauliflower, peppers, cucumber, and spinach</li>
                </ul>
                </p>
            </div>
        </div>
        <div class="col s12 m6">
            <div class="card-panel">
                <h4>Beans/Legumes</h4>

                <p class="hero-p">
                    <i>Go slow here if you are sensitive to the gas-forming effects of beans.</i>
                <ul>
                    <li>Pinto beans, garbanzo beans and black-eyed peas</li>
                </ul>
                </p>
            </div>
        </div>
    </div>

    <div class="row small">
        <div class="col s12">
            <h3>3. Make smart lower-carb substitutions</h3>
            <p class="hero-p">As you add other healthy carbohydrates into your meals, choose foods that are lower in carbohydrates relative to higher-carbohydrate choices.</p>
        </div>
        <div class="col s12 m12">

            <div class="card-panel">
                <p class="hero-p two-col">
                <ul>
                    <li> Puree cauliflower or butternut/acorn squash instead
                        of mashing potatoes</li>
                    <li> Try spaghetti squash or zucchini noodles instead of
                        regular pasta</li>
                    <li> For foods that are served on rice, chop/grate your own cauliflower
                        to make “cauliflower rice.”</li>
                    <li> Roll your sandwich into a small pita or small tortilla
                        instead of between 2 slices of bread.</li>
                    <li> Try light bread instead
                        of regular sandwich bread.</li>
                    <li> Note the serving size for breakfast cereals and measure
                        with measuring cups.</li>
                    <li> Roast or grill non-starchy vegetables instead of
                        a side of corn or peas.</li>
                    <li> Regular potatoes, sweet potatoes, pasta and rice are
                        naturally low in fat, but they do have more carbs –
                        watch portions and keep them as a side dish.</li>
                    <li> Increase quantity of non-starchy vegetables in casseroles
                        and cut down on starch.</li>
                    <li> Be mindful that some fat-free and sugar-free products
                        are made with sugar alcohols (i.e. sorbitol and mannitol).
                        Sugar alcohols may cause a laxative effect when eaten
                        in excess.</li>
                </ul>
                </p>
            </div>
        </div>
    </div>

    <div class="row small">
        <div class="col s6 m3"><img class="responsive-img carb-img" src="@/assets/img/carbs-img-2.png" alt=""></div>
        <div class="col s6 m3"><img class="responsive-img carb-img hide-on-small-only" src="@/assets/img/carbs-img-1.png" alt=""></div>
        <div class="col s6 m3"><img class="responsive-img carb-img" src="@/assets/img/carbs-img-3.png" alt=""></div>
        <div class="col s6 m3"><img class="responsive-img carb-img hide-on-small-only" src="@/assets/img/carbs-img-4.png" alt=""></div>
    </div>

    <div class="row small">
        <div class="col s12">
            <h3>4. Try some of these low-carb and low-fat snacks</h3>
        </div>
        <div class="col s12 m12">
            <div class="card-panel">
                <p class="hero-p two-col">
                <ul>
                    <li> Celery with light peanut butter spread on top</li>
                    <li> Fresh tomato slices with fat-free cottage cheese</li>
                    <li> A melon wedge wrapped in a thin slice of lean ham</li>
                    <li> Low-fat egg salad made with egg whites and fat-free mayo</li>
                    <li> Pepper strips dipped in fat-free dressing</li>
                    <li> Low-fat string cheese or Light cheese with an apple</li>
                    <li> Fat-free cream cheese with a low-fat cracker</li>
                    <li> Cold, sliced turkey or chicken wrapped in a leaf of Romaine lettuce</li>
                    <li> Low-fat vegetable soup</li>
                    <li> Sugar-free gelatin or pudding</li>
                </ul>
                </p>
            </div>
        </div>
    </div>

    <div class="row small">
        <div class="col s12">
            <h3>5. Check labels for both Total Fat and Total Carbohydrate</h3>
        </div>
        <div class="col s12">
            <div class="card-panel">
                <p class="hero-p two-col">
                <ul>
                    <li>Keep in mind that if something is low in carbohydrates,
                        it is not always low-fat, and vice versa. For example,
                        pork rinds are low in carbohydrates, but high in fat. Jelly
                        beans are low in fat, but high in carbohydrates. If you take
                        the bun off the burger to lower the carbohydrates, the
                        meat in the burger is still going to have fat in it. Use
                        your CalorieKing book to track both fat and
                        carbohydrates, if desired.</li>
                    <li>We hope this handout has given you some ideas for
                        keeping your carbohydrate intake in balance, while
                        lowering your fat intake. Please let us know if we can
                        help you further, with additional informational resources,
                        recipes, or to schedule a follow-up consultation.</li>
                </ul>
                </p>
            </div>
        </div>
    </div>

</div>
</template>

<script>
export default {
    name: 'CarbControl',
    data() {
        return {}
    },
    mounted() {
        document.body.classList.add("sub-page");
    },
    methods: {},
    computed: {}
}
</script>

<style>
.carb-dairy-img {
    margin-top: 2rem;
    max-width: 80%;
}

.carb-img {
    max-height: 80px;
}
</style>
