<template>
<footer class="page-footer">
    <div class="container">
        <div class="row">
            <!-- <div class="col m6 s12">
                <h4 class="site-name left-align"><strong>HOFHRecipes.com</strong></h4>
                <p class="site-details left-align">We have collected a range of delicious and simple recipes made especially for people with homozygous familial hypercholesterolemia, an uncommon condition that leads to high levels of cholesterol in your blood.</p>
            </div> -->
            <div class="col m12 s12">
                <p><span class="white-text left-align">Commanditée par:</span></p>
                <!-- <img class="footer-logo" src="@/assets/img/amryt-logo.svg"> -->
                <img class="footer-logo" src="@/assets/img/chiesi-logo-white.svg">
            </div>
        </div>
        <div class="row small">
            <div class="col m12 s12">
                <ul>
                    <li class="links center-align"><strong><a class="modal-trigger" href="#privacyModalFrench">Politique de confidentialité</a></strong></li>
                    <li class="links center-align"><strong><a class="modal-trigger" href="#termsModalFrench">Conditions d’utilisation</a></strong></li>
                    <li class="links center-align"><strong><a class="modal-trigger" href="mailto:info@hofhrecipes.com">Communiquez avec nous</a></strong></li>
                    <li class="links center-align" :value="updateExists" :timeout="0" color="primary">
                    <a @click="refreshApp">
                        <span style="color:#21a3ca">Mise à jour</span>
                    </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="footer-copyright">
        <div class="container">
            <small class="center-align">© {{ new Date().getFullYear() }} HOFHRecipes.com.</small>
            <small class="center-align"> 
                    <span v-show="$route.name!=='profile'">C-HoFH/ALL/0003. Date de publication: Février 2024</span>
                    <span v-show="$route.name=='profile'">87011-4. Date de publication: August 2020.</span>
            </small>
        </div>
    </div>
    <!-- Modal Structure -->
    <div id="termsModalFrench" class="modal modal-fixed-footer">
        <div class="modal-content">

            <h4>Conditions d’utilisation</h4>

            <p>Ces termes et conditions s’appliquent à votre utilisation du site Web http://hofhrecipes.com (le « site Web »). </p>

            <p>Le site Web est exploité par Amryt Pharma plc au nom du groupe Amryt (« Amryt », « nous » ou « nous »). </p>

            <p>Veuillez lire attentivement ces termes et conditions d’utilisation du site Web car en utilisant le site Web, vous acceptez d’être lié par eux. Nous nous réservons le droit de modifier les conditions d’utilisation à tout moment et publierons toute variation ici. Nous vous conseillons de revoir régulièrement les conditions d’utilisation, car vous serez réputé avoir accepté des modifications si vous continuez à utiliser le site Web après leur publication.</p>

            <p><strong>VOTRE RESPONSABILITÉ</strong> </p>

            <p>Vous assumez l’entière responsabilité de votre utilisation ou de votre accès à ce site Web, y compris votre accès à tout Amryt ou à toute autre documentation obtenue par l’intermédiaire du site, et renoncez à toute réclamation ou cause d’action contre Amryt ou leurs dirigeants, administrateurs, employés, agents et sociétés affiliées respectifs à cet égard. Si vous n’acceptez pas de respecter ces conditions d’utilisation, vous ne devez pas utiliser le site Web de quelque manière que ce soit.</p>

            <p><strong>RESSOURCES TIERCES</strong> </p>

            <p>Ce site Web peut contenir des liens vers des sites Web exploités par d’autres parties. Les sites liés ne sont pas sous le contrôle d’Amryt, et nous ne sommes pas responsables du contenu disponible sur tout autre site Internet lié à ce site Web. De tels liens n’impliquent pas notre approbation du matériel sur tout autre site, et Amryt décline toute responsabilité quant à votre accès à ces sites Web liés. Amryt peut fournir des liens vers d’autres sites Internet pour la commodité des utilisateurs, et l’accès à tout autre site Internet lié à ce site Web est à vos propres risques. Les informations publiées sur le site Web sont fournies par Amryt et, le cas échéant, par certains tiers. Amryt prend tous les soins et précautions pour s’assurer que les informations publiées sur le site Web sont exactes lorsqu’elles sont publiées et régulièrement mises à jour, mais Amryt ne donne aucune garantie et ne fait aucune déclaration quant à son exactitude et Amryt peut modifier les informations sur le site Web à tout moment sans préavis. Amryt et ses sociétés affiliées n’assument aucune responsabilité, dans la mesure permise par la loi, pour toute erreur ou omission dans le contenu du site Web. Vous ne devez pas vous fier aux informations sur le site Web et vous reconnaissez que vous devez prendre les mesures appropriées pour vérifier ces informations avant d’agir sur elle.</p>

            <p><strong>CLAUSE DE NON-RESPONSABILITÉ</strong></p>

            <p><strong>AMRYT PUBLIE LE SITE WEB « TEL QUEL » SANS AUCUNE GARANTIE D’AUCUNE SORTE, EXPRESSE OU IMPLICITE, QUANT AU FONCTIONNEMENT DU SITE WEB, À L’EXACTITUDE DES INFORMATIONS OU DES PRODUITS OU SERVICES MENTIONNÉS SUR LE SITE WEB (DANS LA MESURE OÙ CES GARANTIES PEUVENT ÊTRE EXCLUES EN VERTU DE TOUTE LOI PERTINENTE) ET DANS LA MESURE PERMISE PAR LA LOI,  AMRYT NE SERA PAS RESPONSABLE DES PERTES OU DOMMAGES DIRECTS OU INDIRECTS (Y COMPRIS, SANS S’Y LIMITER, LA PERTE DIRECTE OU INDIRECTE DE PROFITS), CONSÉCUTIFS, SPÉCIAUX OU AUTREMENT ACCESSOIRES POUVANT RÉSULTER DE L’UTILISATION DU SITE WEB, QUELLE QU’EN SOIT LA CAUSE. NOUS DÉCLINONS EN OUTRE TOUTE GARANTIE QUE LES INFORMATIONS SUR CE SITE SERONT EXEMPTES D’INTERRUPTION, OU QUE LE SITE EST EXEMPT DE VIRUS, DE VERS, DE CHEVAUX DE TROIE OU D’AUTRES CODES QUI MANIFESTENT DES PROPRIÉTÉS CONTAMINANTES OU DESTRUCTRICES</strong></p>

            <p><strong>INDEMNITÉ</strong></p>

            <p>Vous acceptez d’indemniser, de défendre et de dégager amryt de toute responsabilité, ainsi que leurs dirigeants, administrateurs, employés, agents et sociétés affiliées respectifs, contre toutes les pertes, dépenses, dommages et coûts, y compris les honoraires d’avocat raisonnables, découlant de ou liés à toute violation des présentes Conditions ou à toute activité liée à l’utilisation du Site Web (y compris, mais sans s’y limiter, la violation des droits de propriété intellectuelle mondiaux de tiers ou la conduite négligente ou illicite) par vous ou toute autre personne  accéder au site Web en votre nom.</p>

            <p><strong>DROIT D’AUTEUR</strong></p>

            <p>Amryt détient les droits d’auteur et les marques de commerce sur le contenu publié sur le site Web, sauf indication contraire de l’avis de propriété d’un tiers. Les images, les marques de commerce et les marques sont également protégées par d’autres lois sur la propriété intellectuelle et ne peuvent être reproduites ou appropriées de quelque manière que ce soit sans l’autorisation écrite de leurs propriétaires respectifs. À moins d’être expressément interdit par un avis publié sur une page, vous pouvez faire une copie imprimée des parties du site Web dont vous pourriez raisonnablement avoir besoin pour votre usage personnel, à condition que toute copie y ait joint des avis de propriété et / ou des avertissements pertinents. Toute autre utilisation est interdite.</p>

            <p>Nous ne sommes pas responsables du contenu de tout autre site Web à partir duquel vous avez accédé au site Web ou vers lequel vous pouvez accéder à un hyperlien à partir du site Web et ne pouvons être tenus responsables de toute perte ou dommage que vous encourez à la suite de celui-ci.</p>

            <p><strong>CE SITE WEB NE FOURNIT PAS DE CONSEILS MÉDICAUX OU DE SERVICES PROFESSIONNELS</strong></p>

            <p>Le contenu du site Web est destiné à être une source d’information générale en ce qui concerne le sujet couvert. Amryt n’exerce pas directement ou indirectement la médecine, ne donne pas de conseils médicaux ou ne dispense pas de services médicaux via le site Web, et rien de ce qui est contenu sur le site Web n’est destiné à être une instruction pour le diagnostic ou le traitement médical. Toute information fournie ne doit pas être considérée comme complète, et on ne doit pas s’y fier pour suggérer un traitement pour une personne en particulier. Les informations reçues du site Web ne doivent pas être utilisées pour des décisions personnelles, médicales, juridiques, techniques ou financières. Il ne doit pas être utilisé à la place d’une visite, d’un appel, d’une consultation ou des conseils de votre médecin ou d’un autre fournisseur de soins de santé qualifié. Si vous avez des questions sur les soins de santé, veuillez consulter rapidement votre médecin ou un autre fournisseur de soins de santé qualifié.</p>

            <p><strong>MISES À JOUR</strong></p>

            <p>Amryt peut apporter des améliorations et/ou des modifications au site Web à tout moment. Bien que nous tentions de mettre à jour périodiquement les informations sur ce site Web, les informations, le matériel et les services fournis sur ou via ce site Web peuvent parfois être inexacts, incomplets ou obsolètes. Amryt n’a pas l’obligation de mettre à jour les informations contenues sur le site Web, et nous ne serons pas responsables de tout manquement à la mise à jour de ces informations. Nous ne faisons aucune déclaration quant à l’exhaustivité, l’exactitude ou l’actualité de toute information sur le site Web, et nous n’assumons aucune obligation de mettre à jour ou de réviser les informations contenues sur le site Web, que ce soit à la suite de nouvelles informations, d’événements ou de circonstances futurs ou autrement. Il est de votre responsabilité de vérifier toute information contenue sur le site Web avant de vous y fier.</p>

            <p><strong>LOI APPLICABLE</strong></p>

            <p>Ces conditions d’utilisation sont régies par les lois de l’Angleterre et du Pays de Galles et vous acceptez que les tribunaux anglais aient la compétence exclusive dans tout litige.</p>

            <p>Dans la mesure où une partie de ces conditions d’utilisation est jugée invalide, illégale ou inapplicable par un tribunal compétent, cette partie sera dans cette mesure dissociée des conditions restantes qui resteront toutes en vigueur et de plein effet dans la mesure permise par la loi.</p>

            <p>C-LD/EU/0054</p>

        </div>
        <div class="modal-footer">
            <a href="#!" class="modal-close waves-effect waves-green btn-flat">Fermer</a>
        </div>
    </div>

    <!-- Modal Structure -->
    <div id="privacyModalFrench" class="modal modal-fixed-footer">
        <div class="modal-content">

            <h4>Politique de confidentialité</h4>

            <p>Cette déclaration de confidentialité s’applique à votre utilisation du site Web hofhrecipes.com ( <strong>« Site Web »</strong>). La présente Déclaration de confidentialité (la <strong>« Déclaration de confidentialité »</strong>) énonce l’engagement d’Amryt à se conformer aux lois et réglementations internationales, fédérales, étatiques et locales applicables protégeant les données personnelles que nous traitons. Toute personne accédant, naviguant ou utilisant autrement le site Web, manuellement ou via un appareil ou un programme automatisé, sera considérée comme un <strong>« utilisateur ».</strong></p>

            <p>Tous les utilisateurs sont liés aux termes du présent avis et à toutes les conditions d’utilisation spécifiques associées au site Web. Votre utilisation du site Web signifie que vous consentez à la collecte, à l’utilisation et à la divulgation de vos données personnelles comme décrit dans la présente déclaration de confidentialité.</p>

            <p><strong>« Données personnelles » </strong> désigne toute information relative à une personne physique identifiée ou identifiable; une personne identifiable est une personne qui peut être identifiée, directement ou indirectement, notamment par référence à un numéro d’identification ou à un ou plusieurs facteurs spécifiques à son identité physique, physiologique, mentale, économique, culturelle ou sociale. Nous reconnaissons l’importance de et nous nous engageons à respecter et à protéger votre vie privée. La présente Déclaration de confidentialité s’applique à la collecte et à l’utilisation de renseignements personnels par l’entremise de notre site Web et de nos interactions commerciales hors ligne avec vous, qu’Amryt peut recueillir ou recevoir dans le cadre de ses activités commerciales ordinaires.</p>

            <p>La collecte, le stockage, l’adaptation ou l’altération, le transfert, l’utilisation, la suppression et tout autre traitement des données personnelles seront régis au niveau national, international et régional par des lois et réglementations sur la protection des données, telles que le règlement général sur la protection des données (RGPD) de l’UE.</p>

            <p><strong>BUTS POUR LESQUELS NOUS DÉTENONS VOS INFORMATIONS</strong></p>

            <p>Amryt ne collectera aucune donnée personnelle vous concernant, sauf si vous les fournissez de manière avisée et volontaire.</p>

            <p>Nous collectons vos données personnelles aux fins suivantes:</p>
            <p>
            <ul>
                <li class="disc">Lorsque vous créez un compte d’utilisateur sur le site Web : dans le but d’administrer et d’authentifier votre compte</li>
                <li>Lorsque vous entrez des informations (y compris des informations de santé) via le Health Tracker sur le site Web: dans le but de vous permettre de suivre vos statistiques de santé et de gérer votre profil d’utilisateur.</li>
            </ul>
            </p>

            <p>Vous pouvez mettre à jour, modifier et supprimer ces informations comme vous le souhaitez à tout moment.</p>

            <p>Nous recueillons également certaines informations de base lorsque vous visitez le site Web, telles que le nom de domaine de votre fournisseur de services Internet, les pages auxquelles vous avez accédé sur le site et quand. Nous utilisons ces informations uniquement pour analyser l’utilisation du site Web afin d’aider à guider les améliorations.</p>

            <p><strong>DIVULGATION DE VOS INFORMATIONS À DES TIERS</strong></p>

            <p>Nous pouvons divulguer vos informations aux tiers suivants pour fournir les services associés au site Web:</p>

            <p>
            <ul>
                <li>Sociétés du groupe Amryt</li>
                <li>Fournisseurs de services tiers qui prennent en charge la fonctionnalité du site Web</li>
            </ul>
            </p>

            <p>Les données personnelles qu’Amryt traite peuvent être stockées sur des serveurs aux États-Unis. Si vous êtes situé en dehors des États-Unis, veuillez noter que les données personnelles que nous collectons peuvent être traitées et stockées aux États-Unis (les lois sur la protection des données et la confidentialité aux États-Unis peuvent offrir un niveau de protection inférieur à celui de votre pays / région).</p>

            <p>En utilisant le site Web et en soumettant vos données personnelles, vous acceptez le transfert, le stockage et / ou le traitement de vos données personnelles aux États-Unis.</p>

            <p>Nous pouvons également divulguer vos informations si nous croyons de bonne foi que nous sommes tenus de les divulguer afin de nous conformer à toute loi applicable, à une assignation, à un mandat de perquisition, à une ordonnance d’un tribunal ou réglementaire ou à toute autre exigence légale ou légale.</p>

            <p>Nous ne divulguerons pas vos données personnelles à d’autres tiers, sauf comme décrit ci-dessus.</p>

            <p><strong>BASE JURIDIQUE POUR LE TRAITEMENT</strong></p>

            <p>Lorsque vous vous inscrivez à un compte d’utilisateur ou interagissez avec notre site Web, un tel traitement est nécessaire à l’administration de votre compte et à la performance du site Web.</p>

            <p>Lorsque vous entrez des données personnelles, nous traitons ces données sur la base de votre consentement préalable. Lorsque vous entrez des informations dans le health tracker (y compris les informations de santé), vous donnez votre consentement explicite au stockage de ces informations dans le but de maintenir votre profil personnel et d’améliorer votre expérience utilisateur. Ces renseignements ne seront pas utilisés par Amryt à d’autres fins ou partagés par Amryt avec des tiers autres que les tiers décrits ci-dessus.</p>

            <p>Lorsque nous exploitons et/ou collectons des cookies, des identifiants d’appareil, des données de localisation, des données de l’environnement et d’autres technologies de suivi, nous traitons ces données sur la base de votre consentement.</p>

            <p><strong>POLITIQUE DE RÉTENTION ET DE SUPPRESSION</strong></p>

            <p>Nous conservons vos données personnelles aussi longtemps que vous maintenez un compte d’utilisateur.</p>

            <p>Vous pouvez supprimer vous-même votre compte d’utilisateur à tout moment via le site Web. Lorsque vous supprimez votre compte d’utilisateur, toutes les informations que vous entrez associées à votre profil d’utilisateur seront définitivement effacées. Nous ne stockerons plus vos données personnelles (sauf dans la mesure où nous sommes tenus de conserver des données personnelles par la loi).</p>

            <p>Pour savoir quelles données personnelles nous détenons à votre sujet ou si vous avez des difficultés à mettre à jour, modifier ou supprimer des informations de votre profil, veuillez nous contacter par e-mail à info@hofhrecipes.com.</p>

            <p><strong>TÉMOINS</strong></p>

            <p>Lorsque vous visitez le site Web, il utilise des cookies qui sont de petits éléments d’information qui leur permettent de maintenir votre connexion au site Web. Ce site Web peut utiliser des cookies pour détecter le type d’appareil dont vous disposez afin de présenter le contenu de la meilleure façon, pour un changement de langue et / ou à d’autres fins. Ces cookies ne collectent ni ne stockent aucune information personnellement identifiable. <a href="https://www.lipodystrophy.eu/en/privacy-policy#refusecookies">Vous pouvez refuser l’utilisation de cookies.</a>.</p>

            <p><strong>COMMENT REFUSER L’UTILISATION DE COOKIES</strong></p>

            <p>Vous pouvez refuser l’utilisation de cookies en sélectionnant les paramètres appropriés dans votre navigateur. Cependant, si vous faites cela, vous risquez de perdre certaines fonctionnalités utiles telles que la personnalisation et les fonctionnalités « gardez-moi connecté » et « souvenez-vous de moi ».</p>

            <p><a href="http://files.investis.com/info/disabling-cookies.html">Comment désactiver les cookies dans votre navigateur </a></p>

            <p><strong>GOOGLE ANALYTICS</strong></p>

            <p>Ce site Web utilise Google Analytics, un service d’analyse Web fourni par Google, Inc. (« Google »). Google Analytics utilise des cookies (fichiers texte placés sur votre ordinateur) pour aider les opérateurs du site Web à analyser la façon dont les utilisateurs utilisent le site Web. Les informations générées par le cookie concernant votre utilisation du site Web (y compris votre adresse IP) seront transmises et stockées par Google sur des serveurs aux États-Unis. Google utilisera ces informations dans le but d’évaluer votre utilisation du site Web, de compiler des rapports sur l’activité du site Web pour les opérateurs de sites Web et de fournir d’autres services liés à l’activité du site Web et à l’utilisation d’Internet. Google peut également transférer ces informations à des tiers lorsque la loi l’exige ou lorsque ces tiers traitent les informations au nom de Google. Google n’associera pas votre adresse IP à d’autres données détenues par Google. En utilisant ce site Web, vous consentez au traitement des données vous concernant par Google de la manière et aux fins énoncées ci-dessus.</p>

            <p><strong>SÉCURITÉ</strong></p>

            <p>Nous mettons en œuvre des mesures de protection techniques et organisationnelles appropriées pour nous protéger contre le traitement non autorisé ou illégal des données personnelles et contre la destruction accidentelle ou illégale, la perte, l’altération, la divulgation non autorisée ou l’accès aux données personnelles.</p>

            <p>Cependant, la transmission d’informations via Internet n’est pas complètement sécurisée. Nous ne pouvons pas garantir la sécurité de vos données transmises à nos services en ligne; toute transmission se fait à vos propres risques. Une fois que nous aurons reçu vos informations, nous prendrons des mesures raisonnables pour utiliser des procédures et des fonctionnalités de sécurité afin d’essayer d’empêcher l’accès, la modification ou la divulgation non autorisés.</p>

            <p>Si vous communiquez avec nous à l’aide d’une plateforme Web non sécurisée, vous assumez les risques que de telles communications entre nous soient interceptées, non reçues, retardées, corrompues ou reçues par des personnes autres que le destinataire prévu. Nous prendrons toutefois toutes les mesures raisonnables (y compris les mesures techniques et organisationnelles appropriées) pour protéger vos données.</p>

            <p>Dans le cas où vos données personnelles sont consultées, perdues ou volées par un tiers non autorisé, nous déploierons des efforts commercialement raisonnables pour vous informer dans la mesure requise par la loi et vous divulguer les données personnelles qui ont été consultées / divulguées en utilisant les coordonnées qui nous ont été fournies ou par d’autres moyens raisonnables.</p>

            <p><strong>VOS DROITS</strong></p>

            <p>Vous avez, dans le cadre de vos données personnelles, dans certaines circonstances, le droit de:</p>
            <p>
            <ul>
                <li> Demander l’accès à vos informations personnelles (communément appelées « demande d’accès à la personne concernée »). Cela vous permet de recevoir une copie des informations personnelles que nous détenons à votre sujet et de vérifier que nous les traitons légalement.</li>
                <li> Demander la correction des renseignements personnels que nous détenons à votre sujet. Cela vous permet de faire corriger toutes les informations incomplètes ou inexactes que nous détenons à votre sujet.</li>
                <li> Demandez l’effacement de vos renseignements personnels, dans certaines circonstances. Cela vous permet de nous demander de supprimer ou de supprimer des informations personnelles lorsqu’il n’y a aucune raison valable pour que nous continuions à les traiter. Vous avez également le droit de nous demander de supprimer ou de supprimer vos informations personnelles lorsque vous avez exercé votre droit de vous opposer au traitement (voir ci-dessous).</li>
                <li> S’opposer au traitement de vos informations personnelles, dans certaines circonstances, lorsque nous nous appuyons sur un intérêt légitime (ou ceux d’un tiers) pour des motifs liés à votre situation particulière.</li>
                <li> Demandez la restriction du traitement de vos informations personnelles dans certaines circonstances. Cela vous permet de nous demander de suspendre le traitement des informations personnelles vous concernant, par exemple si vous souhaitez que nous établissions leur exactitude ou la raison de leur traitement.</li>
                <li> Demandez le transfert de vos renseignements personnels à une autre partie, dans certaines circonstances.</li>
                <li> Retirez votre consentement à tout moment, lorsque nous nous appuyons sur le consentement pour traiter vos données personnelles. Si vous retirez votre consentement à l’utilisation continue de vos Photos, nous les supprimerons immédiatement. Cependant, cela n’affectera pas la légalité de tout traitement effectué avant que vous ne retiriez votre consentement. Nous vous informerons si c’est le cas au moment où vous retirez votre consentement.</li>
            </ul>
            </p>

            <p>Si vous souhaitez exercer l’un des droits énoncés ci-dessus, veuillez contacter <a href="mailto:info@hofhrecipes.com">info@hofhrecipes.com</a></p>

            <p><strong>DROIT DE DÉPOSER UNE PLAINTE AUPRÈS DE L’AUTORITÉ DE PROTECTION DES DONNÉES</strong></p>

            <p>Nous vous encourageons à nous contacter directement et nous permettons de travailler avec vous pour répondre à vos préoccupations. Néanmoins, vous avez le droit de déposer une plainte auprès d’une autorité de surveillance compétente en matière de protection des données, en particulier dans l’État membre de l’UE où vous résidez, travaillez ou le lieu de l’infraction présumée. Vous avez le droit de le faire si vous considérez que le traitement des données personnelles vous concernant enfreint les lois applicables en matière de protection des données.</p>

            <p><strong>DÉTAILS DU DPD</strong></p>

            <p>Nous avons nommé un délégué à la protection des données (DPD) qui est chargé de superviser les questions relatives au présent avis de confidentialité. Si vous avez des questions concernant cet avis de confidentialité, y compris toute demande d’exercice de vos droits légaux, veuillez contacter le DPD en utilisant les détails indiqués ci-dessous.</p>

            <p>Adresse courriel: dpo@amrytpharma.com</p>

            <p>Adresse postale: le responsable de la protection des données, Amryt Pharma, 45 Mespil Road, Dublin 4, Irlande</p>

            <p><strong>SERVICES DE TIERS</strong></p>

            <p>La présente déclaration de confidentialité ne traite pas, et Amryt n’est pas responsable des conditions d’utilisation, de l’information ou des pratiques de confidentialité de tiers, y compris tout tiers exploitant un site Web ou un service auquel notre site Web Amryt renvoie. L’inclusion d’un lien sur le site Web d’Amryt n’implique pas notre approbation ou l’approbation par nos sociétés affiliées du site Web ou du service lié.</p>

            <p><strong>CHANGEMENTS</strong></p>

            <p>Toute modification apportée à cette déclaration sera affichée sur ce site Web afin que vous soyez toujours au courant des informations que nous recueillons, de la façon dont nous les utilisons et dans quelles circonstances, le cas échéant, nous les divulguons.</p>

            <p>C-LD/EU/0054</p>

        </div>
        <div class="modal-footer">
            <a href="#!" class="modal-close waves-effect waves-green btn-flat">Fermer</a>
        </div>
    </div>
</footer>
</template>

<script>
import update from '../mixins/update'
export default {
    name: 'Footer',
    data() {
        return {
            user: null
        }
    },
    mixins: [update],
    mounted() {

        /* eslint-disable */
        const modal = document.querySelectorAll('.modal')
        M.Modal.init(modal)

    },
}
</script>

<style>
.page-footer {
    background: rgb(0, 161, 201);
    background: -moz-linear-gradient(319deg, rgba(0, 161, 201, 1) 0%, rgba(2, 178, 209, 1) 100%);
    background: -webkit-linear-gradient(319deg, rgba(0, 161, 201, 1) 0%, rgba(2, 178, 209, 1) 100%);
    background: linear-gradient(319deg, rgba(0, 161, 201, 1) 0%, rgba(2, 178, 209, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00a1c9", endColorstr="#02b2d1", GradientType=1);
    margin-top: 160px;
}

.page-footer h1 {
    font-family: 'Baloo Thambi', cursive;
    font-size: 2em;
    padding: 0;
    margin: 15px 0;
}

.page-footer .links {
    font-family: 'Baloo Thambi', cursive;
    font-size: 1.2em;
    padding: 4px 20px;
    margin: 10px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    background: #fff;
    border-radius: 100px;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}

.page-footer .links:hover {
    background: #fcb911;
}

.page-footer a {
    color: #21a3ca;
    font-weight: 300;
}

.page-footer .site-details {
    padding-right: 30px;
}

.page-footer h4 {
    color: #fff;
    font-size: 1.3em;
    margin-top: 18px;
}

.page-footer .links span {
    margin: 0 10px;
}

.page-footer .row {
    margin-bottom: 20px;
}

.page-footer .footer-logo {
    width: 190px;
}

.footer-copyright small {
    margin-left: .5rem;
    font-size: 85%;
}

.modal-content h4 {
    text-align: left;
}

.modal-content p {
    color: #5a5a5a;
    text-align: left;
}

.modal-content ul:not(.browser-default) {
    padding-left: 1em;
    list-style-type: disc;
}

.modal-content ul:not(.browser-default)>li {
    list-style-type: disc;
}

.modal-content strong {
    font-weight: 900;
}

.modal-footer {
    padding: 0 24px !important;
}

.modal-footer a {
    font-family: 'Baloo Thambi', cursive;
    color: #1a727e;
    font-size: 1.5em;
}

.modal.modal-fixed-footer {
    padding: 0;
    height: 70%;
}

@media screen and (max-device-width: 420px) {
    .page-footer {
        margin-bottom: 70px;
    }

    footer .site-name {
        display: none;
    }

    .page-footer .links {
        padding: 6px 16px;
        margin: 10px 8px 16px;
    }

    .site-details {
        text-align: center !important;
        padding-right: 0 !important;
    }
}
</style>
