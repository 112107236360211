<template>
<div class="recipe container">

    <div class="row">
        <div class="col s12 m6">
            <div class="">
                <ul>
                    <li v-if="currentRecipe" class="left-align">
                        <h1 class="hero-heading">{{currentRecipe.title}}</h1>
                        <ul>
                            <!-- <li>Prep time: {{currentRecipe.time}}</li>
                                <li>Serves: {{currentRecipe.serves}}</li> -->
                            <div class="description">
                                <span v-if="currentRecipe.time"><strong>Prep time:</strong> {{currentRecipe.time}}mins &#x000B7; </span>
                                <span v-if="currentRecipe.difficulty"> <strong>Difficulty:</strong> {{currentRecipe.difficulty}} &#x000B7; </span>
                                <span v-if="currentRecipe.serves"><strong> Serves:</strong> {{currentRecipe.serves}} </span>
                                <p>{{currentRecipe.description}}</p>
                                <p v-if="currentRecipe.notes"><strong>Note: {{currentRecipe.notes}}</strong></p>
                                <p v-if="currentRecipe.heartuk"><strong>This recipe is sponsored from <a href="https://www.heartuk.org.uk/healthy-living/" target="_blank">heartuk.org.uk</a></strong></p>
                            </div>
                            <div class="tag">
                                <p>
                                    <span><strong>Tags: </strong></span>
                                    <span class="chip" v-for="(category, index) in tagList" :key="index">
                                        <router-link :to="`/categories/${category}`">
                                            {{category}}
                                            <span v-if="index != Object.keys(tagList).length - 1"></span>
                                        </router-link>
                                    </span>
                                </p>
                            </div>
                            <div class="recipe-btns">
                                <button v-if="user" class="recipe-btn" id="addToFavs" @click="addToFavs">add to favorites <i class='material-icons right'>favorite</i></button>
                                <button v-if="user" class="recipe-btn" @click="printRecipe();">Print recipe <i class='material-icons right'>print</i></button>
                            </div>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col s12 m6">
            <!-- <img class="responsive-img" :src="getImgUrl()"> -->
            <div class="recipe-img-container" v-if="currentRecipe.hasImage">
                <img class="responsive-img recipe-img" :src="require(`@/assets/img/recipes/${currentRecipe.slug}.jpg`)">
                <a v-if="currentRecipe.heartuk" href="https://www.heartuk.org.uk/healthy-living/" target="_blank"><img class="responsive-img heartukLogo" src="@/assets/img/heart-uk-logo-white.svg"></a>
            </div>
            <div v-else>
                <img class="responsive-img" src="@/assets/img/img-01.png">
            </div>
            <!-- <span>Image src will be {{currentRecipe.slug}}.jpg</span> -->
        </div>
    </div>
    <div id="printThis">
        <h3 class="printHeader">{{currentRecipe.title}} <span>By hofhrecipes.com</span></h3>

        <div class="row">
            <h4>Nutritional information per serving <span class="yellow-text">(1/{{currentRecipe.serves}} of recipe) </span></h4>
            <span v-if="currentRecipe.fat != null">
                <div class="nValue">
                    <div class="blob valign-wrapper fat">
                        <div class="blob-details">
                            <p><strong>Fat:</strong></p>
                            <p>{{currentRecipe.fat}}g</p>
                        </div>
                    </div>
                </div>
            </span>
            <span v-if="currentRecipe.saturated != null">
                <div class="nValue">
                    <div class="blob valign-wrapper saturated">
                        <div class="blob-details">
                            <p><strong>Saturated:</strong></p>
                            <p>{{currentRecipe.saturated}}g</p>
                        </div>
                    </div>
                </div>
            </span>
            <span v-if="currentRecipe.carbohydrate != null">
                <div class="nValue">
                    <div class="blob valign-wrapper carbohydrate">
                        <div class="blob-details">
                            <p><strong>Carbohydrate:</strong></p>
                            <p>{{currentRecipe.carbohydrate}}g</p>
                        </div>
                    </div>
                </div>
            </span>
            <span v-if="currentRecipe.protein != null">
                <div class="nValue">
                    <div class="blob valign-wrapper protein">
                        <div class="blob-details">
                            <p><strong>Protein:</strong></p>
                            <p>{{currentRecipe.protein}}g</p>
                        </div>
                    </div>
                </div>
            </span>
            <span v-if="currentRecipe.calories != null">
                <div class="nValue">
                    <div class="blob valign-wrapper sodium">
                        <div class="blob-details">
                            <p><strong>Calories:</strong></p>
                            <p>{{currentRecipe.calories}}kcals</p>
                        </div>
                    </div>
                </div>
            </span>
        </div>
        <div class="row">
            <div class="col s12 m6">
                <div class="left-align checklist">
                    <h4>Ingredients</h4>
                    <ul>
                        <li v-for="(ingredient, index) in ingredientsList" :key="index"><input type="checkbox" />
                            <form action="#">
                                <p>
                                    <label>
                                        <input type="checkbox" :value="ingredient" v-model="selectedIngredients" />
                                        <span>{{ingredient}}</span>
                                    </label>
                                </p>
                            </form>
                        </li>
                    </ul>

                    <button v-if="user" class="recipe-btn" id="addToShopping" @click="addToShopping">add to shopping list <i class='material-icons right'>shopping_basket</i></button>
                </div>
            </div>
            <div class="col s12 m6">
                <div class="left-align methods">
                    <h4>Method</h4>
                    <ol>
                        <li v-for="(method, index) in methodsList" :key="index">{{method}}</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import recipes from '@/assets/data/recipes-v03'
import firebase from 'firebase'
import db from '@/firebase/init'
import request from 'request';
// import axios from 'axios'

export default {
    name: 'recipe',
    data() {
        return {
            user: firebase.auth().currentUser,
            favRecipes: null,
            currentRecipe: null,
            activeRecipe: null,
            recipes: [],
            ingredientsList: null,
            tagList: null,
            methodsList: null,
            recentlyViewed: [],
            selectedIngredients: []
        }
    },
    metaInfo() {
        return {
            title: this.currentRecipe.title,
            meta: [{
                name: 'Description',
                content: this.currentRecipe.title + ' - ' + this.currentRecipe.description
            }, {
                name: 'keywords',
                content: this.currentRecipe.title + ', HOFH, HOFH recipes, HOFH meal plan, homozygous familial hypercholesterolemia, low fat recipes, low fat meals, ldl, fat free meals'
            }],
        }
    },
    mounted() {
        this.recipes = recipes;
        this.user = firebase.auth().currentUser
        document.body.classList.add("sub-page");

        if (localStorage.recentlyViewed) {
            this.recentlyViewed = JSON.parse(localStorage.recentlyViewed);
        }

        /* eslint-disable */
        if (this.recentlyViewed.includes(this.currentRecipe.slug)) {
            this.recentlyViewed.splice(this.recentlyViewed.indexOf(this.currentRecipe.slug), 1);
        }

        if (this.recentlyViewed.length == 8) {
            console.log(this.recentlyViewed.length)
            this.recentlyViewed = this.recentlyViewed.slice(1);
            this.recentlyViewed.push(this.currentRecipe.slug);
        } else {
            this.recentlyViewed.push(this.currentRecipe.slug);
        }

    },
    watch: {
        recentlyViewed(newRecipe) {
            localStorage.recentlyViewed = JSON.stringify(newRecipe);
        }
    },
    created() {
        let activeRecipe = this.$route.params.id;
        // this.currentRecipe = recipes[0]
        /* eslint-disable */
        // console.log(this.$route.params.id)

        // this.currentRecipe = recipes.filter(obj => {
        //     return obj.slug === this.$route.params.id;
        // })

        this.currentRecipe = recipes.find(function (obj) {
            return obj.slug === activeRecipe;
        });

        this.tagList = this.currentRecipe.category.split("|");
        this.ingredientsList = this.currentRecipe.ingredients.split("|");
        this.methodsList = this.currentRecipe.methods.split("|");
        /* eslint-disable */
        // console.log(this.tagList)

        if (this.user) {
            db.collection('users').where('user_id', '==', this.user.uid)
                .onSnapshot((snapshot) => {
                    snapshot.docChanges().forEach(change => {
                        if (change.type == 'added') {
                            this.favRecipes = change.doc.data().favRecipes

                            // console.log(this.favRecipes)
                            // console.log(this.currentRecipe.slug)

                            if (this.favRecipes) {
                                for (var i = 0; i < this.favRecipes.length; i++) {
                                    var obj = this.favRecipes[i];
                                    // console.log(obj + this.currentRecipe.slug)
                                    if (obj == this.currentRecipe.slug) {
                                        document.getElementById("addToFavs").style.backgroundColor = "#f16a54";
                                        document.getElementById("addToFavs").style.color = "#ffffff";
                                        document.getElementById("addToFavs").innerHTML = "Remove from favorites <i class='material-icons right'>delete</i>";
                                        document.getElementById("addToFavs").classList.add("active")
                                    }
                                }
                            }
                        }
                    })
                })
        }
    },
    methods: {
        printRecipe() {
            try {
                document.execCommand('print', false, null)
            } catch {
                window.print();
                return false;
            }

            // this.$htmlToPaper('printThis');
        },
        // getImgUrl(){
        //     let randomNum = Math.floor(Math.random() * (1 - 230 + 1)) + 1;
        //     let imgSrc = "https://source.unsplash.com/700x500/?food?sig=" + randomNum;
        //     return imgSrc
        // },

        addToFavs() {
            console.log(this.currentRecipe.slug)

            /* eslint-disable */
            //update users weight record
            //   var currUser = db.collection("users").where('user_id', '==', this.user.uid);

            if (document.getElementById("addToFavs").classList.contains("active")) {
                db.collection('users').where('user_id', '==', this.user.uid).get()
                    .then(snapshot => {
                        snapshot.forEach((doc) => {
                            db.collection('users').doc(doc.id).update({
                                favRecipes: firebase.firestore.FieldValue.arrayRemove(this.currentRecipe.slug)
                            })
                        });
                    }).then(() => {
                        document.getElementById("addToFavs").style.cssText = "background-color: #e2f3f5; color: #1a727e";
                        document.getElementById("addToFavs").innerHTML = "<i class='material-icons right'>favorite</i> Add to favorites";
                        document.getElementById("addToFavs").classList.remove("active")
                        var toastHTML = '<span> Removed from favorites</span>';
                        M.toast({
                            html: toastHTML
                        });

                        const url = 'https://hooks.slack.com/services/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';
                        const text = this.currentRecipe.slug + ' removed from favourites by ' + firebase.auth().currentUser.email;

                        request.post({
                                headers: {
                                    'Content-type': 'application/json'
                                },
                                url,
                                form: JSON.stringify({
                                    text
                                }),
                            },
                            (error, res, body) => console.log(error, body, res.statusCode)
                        );
                    }).catch(err => {
                        /* eslint-disable */
                        alert(err);
                    })
            } else {
                db.collection('users').where('user_id', '==', this.user.uid).get()
                    .then(snapshot => {
                        snapshot.forEach((doc) => {
                            db.collection('users').doc(doc.id).update({
                                favRecipes: firebase.firestore.FieldValue.arrayUnion(this.currentRecipe.slug)
                            })
                        });
                    }).then(() => {
                        document.getElementById("addToFavs").style.cssText = "background-color: #f16a54; color: #fff";
                        document.getElementById("addToFavs").innerHTML = "<i class='material-icons right'>delete</i> Remove from favorites";
                        document.getElementById("addToFavs").classList.add("active")
                        var toastHTML = '<span> Added to favorites</span>';
                        M.toast({
                            html: toastHTML
                        });

                        const url = 'https://hooks.slack.com/services/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';
                        const text = this.currentRecipe.slug + ' added to favourites by ' + firebase.auth().currentUser.email;

                        request.post({
                                headers: {
                                    'Content-type': 'application/json'
                                },
                                url,
                                form: JSON.stringify({
                                    text
                                }),
                            },
                            (error, res, body) => console.log(error, body, res.statusCode)
                        );
                    }).catch(err => {
                        /* eslint-disable */
                        alert(err);
                    })
            }
        },

        addToShopping() {

            // for(var i = 0; i < this.selectedIngredients.length; i++) {
            //     var obj = this.selectedIngredients[i];
            //     console.log(obj)
            // }

            db.collection('users').where('user_id', '==', this.user.uid).get()
                .then(snapshot => {
                    for (var i = 0; i < this.selectedIngredients.length; i++) {
                        var obj = this.selectedIngredients[i];
                        snapshot.forEach((doc) => {
                            db.collection('users').doc(doc.id).update({
                                shoppingList: firebase.firestore.FieldValue.arrayUnion(obj)
                            })
                        });
                    }
                }).then(() => {
                    document.getElementById("addToShopping").style.cssText = "background-color: #e2f3f5; color: #eca500";
                    document.getElementById("addToShopping").innerHTML = "<i class='material-icons right'>check</i> Done!";
                    document.getElementById("addToShopping").classList.remove("active")
                    var toastHTML = '<span> Added to shopping list</span>';
                    M.toast({
                        html: toastHTML
                    });

                    const url = 'https://hooks.slack.com/services/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';
                    const text = firebase.auth().currentUser.email + ' added stuff to their shopping list';

                    request.post({
                            headers: {
                                'Content-type': 'application/json'
                            },
                            url,
                            form: JSON.stringify({
                                text
                            }),
                        },
                        (error, res, body) => console.log(error, body, res.statusCode)
                    );
                }).catch(err => {
                    /* eslint-disable */
                    alert(err);
                })
        }
    }
}
</script>

<style>
.recipe {
    margin-top: 60px !important;
}

.recipe .hero-heading {
    font-size: 2em !important;
}

.description {
    margin: 25px 0;
    color: #1a727e;
}

.recipe-btn {
    background-color: #e2f3f5;
    border: 0;
    padding: 7px 20px;
    border-radius: 30px;
    /* box-shadow: 2px 2px 5px rgba(0,0,0,0.1); */
    color: #1a727e;
    margin: 15px 15px 0 0;
    display: inline-block !important;
    padding: 9px 20px;
    font-size: 1rem;
    /*-webkit-transition: all 300ms ease-in-out;
        -moz-transition: all 300ms ease-in-out;
        -o-transition: all 300ms ease-in-out;
        transition: all 300ms ease-in-out; */
    cursor: pointer;
}

.recipe-btn:hover {
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.recipe-btn i {
    font-size: 1.2em
}

.recipe h4 {
    font-size: 1.8rem;
    padding-bottom: 10px;
    border-bottom: 2px solid #eca500;
}

.yellow-text {
    color: #feb814 !important;
}

.mtb {
    margin: 2em 0 !important;
}

.nValue {
    width: 20%;
    display: inline-block;
}

.recipe .blob {
    background-image: url(~@/assets/img/blob-1.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 120px;
    text-align: center;
}

.recipe .blob .blob-details {
    width: 100%;
}

.recipe .blob p {
    font-family: 'Baloo Thambi', cursive;
    display: block;
    width: 100%;
    font-size: 1.2rem;
    margin: 0;
    color: #fff;
}

.recipe .blob strong {
    font-family: 'Baloo Thambi', cursive;
    display: block;
    width: 100%;
    font-size: 1.2rem !important;
    color: #875f00;
    font-weight: 500;
}

.recipe .blob.saturated {
    background-image: url(~@/assets/img/blob-2.svg);
}

.recipe .blob.cholesterol {
    background-image: url(~@/assets/img/blob-3.svg);
}

.recipe .blob.carbohydrate {
    background-image: url(~@/assets/img/blob-4.svg);
}

.recipe .blob.protein {
    background-image: url(~@/assets/img/blob-5.svg);
}

.recipe .blob.sodium {
    background-image: url(~@/assets/img/blob-6.svg);
}

.recipe-img-container {
    position: relative;
    /* max-height: 320px;
    overflow: hidden;
    border: 2px solid #feb813;
    height: 320px; */
}

.recipe-img {
    /* max-width: 100%;
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto; */

    border: 3px solid #feb814;
    max-height: 300px;
        height: auto;
    object-fit: cover;
}

.heartukLogo {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px !important;
    max-width: 140px !important;
    background: #fdb912;
}

.tag .chip {
    background-color: #feb813;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}

.tag .chip:hover {
    background-color: #e2a20a;
}

.tag .chip a {
    color: #875f00;
}

.tag strong {
    color: #1a727e;
    margin-right: 10px;
}

.checklist,
.methods {
    padding: 0 1em;
}

.checklist label {
    color: #1a727e;
}

[type="checkbox"]+span:not(.lever) {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.methods ol {
    padding-inline-start: 15px;
}

.methods li {
    margin-bottom: 1em;
    color: #1a727e;
}

.printHeader {
    display: none;
}

@media screen and (max-device-width: 1200px) {}

@media screen and (max-device-width: 768px) {

    .recipe .blob {
        background-size: 100% !important;
    }

    .recipe .blob strong {
        font-size: 1rem !important;
    }

    .recipe .blob p {
        font-size: 1.2rem;
    }

    .checklist,
    .methods {
        padding: 0;
    }

    .recipe-btn {
        padding: 9px 12px;
    }
}

@media screen and (max-device-width: 420px) {
    .nValue {
        width: 33%;
    }

    .recipe-btn {
        margin: 0px 15px 0px 0;
    }

    .recipe-img-container {
        position: relative;
        margin: 20px 0;
    }

    .recipe-img {
        border: 3px solid #feb814;
    }

}

/* PRINT STYLES */

@media print {
    body * {
        visibility: hidden;
    }

    body.sub-page {
        background-image: none;
    }

    #printThis,
    #printThis * {
        visibility: visible;
    }

    #printThis {
        position: absolute;
        left: 0;
        top: 0;
    }

    .row {
        margin-bottom: 20px;
    }

    .printHeader {
        color: #25b7d3;
        display: block;
    }

    .printHeader span {
        font-size: 1rem;
    }

    .recipe .blob {
        height: 80px;
    }

    p,
    .methods li {
        margin: 7px 0;
    }

    .recipe .blob p {
        font-size: 1.3rem;
    }

    .recipe h4 {
        font-size: 1.3rem;
    }

    .recipe-btn,
    .recipe-btn i {
        visibility: hidden !important;
    }

    ul:not(.browser-default)>li {
        list-style-type: disc;
    }

    [type="checkbox"]+span:not(.lever):before,
    [type="checkbox"]:not(.filled-in)+span:not(.lever):after {
        display: none;
        padding-left: 0;
    }

    [type="checkbox"]:not(:checked),
    [type="checkbox"]:checked {
        display: none;
    }

    [type="checkbox"]:not(.filled-in)+span:not(.lever):after {
        display: none;
    }
}
</style>
