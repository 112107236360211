<template>
    <div class="container favourites">
      <div class="row">
        <img class="img-02" src="@/assets/img/favourites-img.png">
        <h1 class="hero-heading">Your favorite recipes</h1>
        <div class="col s6 m3" v-for="(data, index) in displayRecipes" :key="index">

          <div class="card hoverable small">
            
            <a class="btn-floating halfway-fab waves-effect waves-light red deleteFavourite" @click="deleteFavourite(data.slug)">&minus;</a>
            <router-link :to="'/recipe/' + data.slug">
              <div class="card-image">
                <div v-if="data.hasImage">
                  <img :src="require(`@/assets/img/recipes/${data.img}.jpg`)">
                </div>
                <div v-else>
                  <img src="@/assets/img/default-recipe.jpg">
                </div>
                <span class="difficulty" :class="data.difficulty">{{data.difficulty}}</span>
                <div class="blob">
                  <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                    <path d="M55.4,-66.5C68,-55.5,71.7,-34.4,75.3,-13.4C78.8,7.6,82.3,28.6,74.6,44.3C67,60,48.2,70.6,28.9,76.2C9.6,81.8,-10.3,82.5,-26.7,75.6C-43.1,68.7,-56,54.2,-67.2,37.5C-78.4,20.8,-87.8,1.8,-86.8,-17.2C-85.7,-36.3,-74.1,-55.4,-57.9,-65.8C-41.7,-76.2,-20.8,-78,0.3,-78.3C21.4,-78.7,42.8,-77.6,55.4,-66.5Z" transform="translate(100 100)" />
                  </svg>
                  <div class="fatBlobText">
                    <p>Fat:</p>
                    <p>{{data.fat}}g</p>
                  </div>
                </div>
              </div>
              <div class="card-content">
                <h5 class="card-header">{{data.title}}</h5>
                <p v-if="data.serves"> <strong>Serves:</strong> {{data.serves}}</p> <p><strong>Calories:</strong> {{data.calories}}kcals</p>
              </div>
            </router-link>
          
          </div>
        </div>
        <div class="left-align nofavs card" v-if="nofavs">
          <h4>You have not selected any favorties</h4>
          <p>To add recipes to your favorites list, open up a recipe and click the "Add to favorites"button</p>
        </div>
      </div>
    </div>
</template>

<script>
    import recipes from '@/assets/data/recipes-v03'
    import firebase from 'firebase'
    import db from '@/firebase/init'
    export default{
      //custom option named recipes
        data(){
            return{
                recipes: recipes,
                user: firebase.auth().currentUser,
                favRecipes: [],
                displayRecipes: [],
                nofavs: true
            }
        },
        mounted(){
            this.user = firebase.auth().currentUser
            document.body.classList.add("sub-page");
            
            /* eslint-disable */
            console.log(this.displayRecipes);
            // retrieve weight list
            db.collection('users').where('user_id', '==', this.user.uid)
            .onSnapshot((snapshot) => {
              snapshot.docChanges().forEach(change => {
              if(change.type == 'added'){
                this.favRecipes = change.doc.data().favRecipes

                for(var i = 0; i < recipes.length; i++) {
                  
                  var obj = recipes[i];

                  if(this.favRecipes){
                    for(var j= 0; j < this.favRecipes.length; j++){
                      if(obj.slug == this.favRecipes[j]){
                        this.displayRecipes.push(recipes[i])
                        this.nofavs = false
                        // console.log("YES");
                      }
                    }
                  }

                }

              }
            })
          })
        },    
        created(){
            /* eslint-disable */
            // console.log(this.displayRecipes[0]);

        },
        methods: {
          getImgUrl(){
          let randomNum = Math.floor(Math.random() * (1 - 230 + 1)) + 1;
          let imgSrc = "https://source.unsplash.com/700x500/?food?sig=" + randomNum;
          return imgSrc
        },
        deleteFavourite(slug){

          /* eslint-disable */
          //update users weight record
          //   var currUser = db.collection("users").where('user_id', '==', this.user.uid);
          db.collection('users').where('user_id', '==', this.user.uid).get()
          .then(snapshot => {
              snapshot.forEach((doc) => {
                  db.collection('users').doc(doc.id).update({
                      favRecipes: firebase.firestore.FieldValue.arrayRemove(slug)
                  })
              });
          }).then(() => {
            for(var i = 0; i < this.displayRecipes.length; i++) {
              var obj = this.displayRecipes[i];
              // console.log(obj)
              if(obj.slug == slug){
                  this.displayRecipes.splice(i, 1)
                  console.log("Deleted: " + slug)
                  console.log(this.displayRecipes)
              }
            }
          }).then(() => {
            var toastHTML = '<span>Recipe deleted</span>';
            M.toast({html: toastHTML});
          }).catch( err => {
              /* eslint-disable */
              alert(err);
          })
        }
      }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style>

  .img-02 {
    width: 260px;
    position: absolute;
    top: 43px;
    right: 10%;
    z-index: -1;
}

.favourites{
  margin-top: 100px !important;
}
.favourites h1{
    text-align: left !important;
    margin: 0 0 .5em !important;
    padding: 0 !important;
}

.favourites p, .favourites h2{text-align: left;}
.favourites .recipe div{
  max-width: 600px;
  background: #eee;
  margin: 20px;
  padding: 20px;
  float: left;
}
.favourites h3 {
  margin: 40px 0 0;
}
.favourites ul {
  text-align: left;
}
.favourites ol{
  text-align: left;
}
.favourites li {
  margin: 0 10px;
}
.favourites a {
  color: #42b983;
}
.favourites .deleteFavourite{
    position: absolute;
    color: #fff;
    /* padding: 0; */
    line-height: 35px;
    font-size: 21px;
    font-weight: 900;
    right: 4px;
    /* bottom: 10px; */
    background-color: #f16a54;
    top: 12px;
    width: 35px;
    height: 35px;
}

.favourites .deleteFavourite:hover{
  cursor: pointer;
  background-color: #00a1c9 !important;
}

.favourites .deleteFavourite.btn-floating i{
  line-height: 35px;
  font-size: 1.4em;
}

.nofavs{
  margin-top: 3em !important;
  padding: 20px;
}
.nofavs h4{
  color: #f16a54;
  font-size: 2em;
}

@media screen and (max-device-width: 420px)  { 
  .favourites .card .card-image .blob {
      width: 50px;
      height: 50px;
      top: 4px;
      left: 2px;
  }
}
</style>
