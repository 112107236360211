<template>
<div class="container profile">
    <div class="row">
        <img class="img-03" src="@/assets/img/img-profile.png">
        <div class="col s12 m8">
            <h1 class="hero-heading">Health tracker</h1>
            <p class="hero-p">It can be tough to maintain a healthy lifestyle while also managing your HoFH. We have created some simple trackers to help record your daily weight, LDL-C levels and fat intake as often as you like.</p>
            <p class="hero-p">In addition to the digital trackers below, why not download our <a href="/pdf/11184_HOFH_Recipes_FatTracker.pdf" target="_blank"><strong>printable daily fat tracker <i class="material-icons tiny blue-text">file_download</i></strong></a> which you can use to keep further detail of fat intake throughout the day</p>
        </div>
    </div>

    <div class="row">
        <div class="col s12">
            <ul class="tabs">
                <li class="tab col s4"><a class="active weight-tab" href="#weight-tab">
                        <h4>Weight tracker</h4>
                    </a></li>
                <li class="tab col s4"><a class="ldl-tab" href="#ldl-tab">
                        <h4>LDL-C tracker</h4>
                    </a></li>
                <li class="tab col s4"><a class="fat-tab" href="#fat-tab">
                        <h4>Fat intake tracker</h4>
                    </a></li>
            </ul>
        </div>
        <div id="weight-tab">

            <div class="row">
                <div class="col m6 s12">
                    <form class="card-panel weight-card" @submit.prevent="addWeight">
                        <div>
                            <h3>Weight tracker</h3>
                            <div class="switch">
                                <label>
                                    kgs
                                    <input type="checkbox" id="weightCheckbox" v-model="weightCheckbox">
                                    <span class="lever"></span>
                                    lbs
                                </label>
                            </div>
                        </div>
                        <div class="input-field">
                            <label for="weightEntry">Enter your weight in <span v-if="!weightCheckbox">kilograms</span><span v-else>pounds</span>:</label>
                            <input type="text" name="weightEntry" v-model="weightEntry">
                        </div>
                        <div class="">
                            <!-- <label for="date">Enter the date:</label> -->
                            <!-- <input type="text" class="datepicker" v-model="weightDateEntry"> -->
                            <date-picker v-model="weightDateEntry" name="datepicker" format="DD/MM/YYYY" placeholder="Select date"></date-picker>
                        </div>
                        <p class="red-text center" v-if="weightFeedback">{{weightFeedback}}</p>
                        <div class="center">
                            <button class="btn">Add weight +</button>
                        </div>
                    </form>
                </div>
                <div class="col m6 s12 weightsList">
                    <!-- <canvas class="card-panel" id="planet-chart"></canvas> -->
                    <div v-if="!weightCheckbox" class="card-panel">
                        <line-chart id="weightChartKgs" :data="weightChartDataKgs" :colors="['#feb813']" suffix="kgs" ytitle="Kilograms"></line-chart>
                    </div>
                    <div v-else class="card-panel">
                        <line-chart :data="weightChartDataLbs" :colors="['#feb813']" suffix="lbs" ytitle="Pounds"></line-chart>
                    </div>

                </div>
                <div v-if="this.pastWeights">
                    <div class="col m12 s12 weightsList">
                        <ul>
                            <li v-for="(gweight, index) in pastWeights.slice().reverse()" :key="index">
                                <!-- <p><strong>{{weight.num}}kg, entered {{weight.timestamp}}</strong></p> -->
                                <p><strong><span v-if="!weightCheckbox">{{gweight.num}}kg</span><span v-else>{{ kgToPounds(gweight.num) }}lbs</span></strong> &#x000B7; {{ formatToDay(gweight.timestamp) }}<a class="btn-floating waves-effect waves-light deleteWeight" @click="deleteWeight(gweight.num, gweight.timestamp)"><i class="material-icons">delete</i></a></p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>

        <div id="ldl-tab">
            <div class="row">
                <div class="col m6 s12">
                    <form class="card-panel ldl-card" @submit.prevent="addLDL">
                        <div>
                            <h3>LDL-C tracker</h3>
                            <div class="switch">
                                <label>
                                    mg/dl
                                    <input type="checkbox" id="ldlCheckbox" v-model="ldlCheckbox">
                                    <span class="lever"></span>
                                    mmol/l
                                </label>
                            </div>
                        </div>
                        <div class="input-field">
                            <label for="ldlEntry">Enter your current LDL-C in <span v-if="!ldlCheckbox">mg/dL</span><span v-else>mmol/l</span>:</label>
                            <input type="text" name="ldlEntry" v-model="ldlEntry">
                        </div>
                        <div class="">
                            <!-- <label for="date">Enter the date:</label> -->
                            <!-- <input type="text" class="datepicker" v-model="weightDateEntry"> -->
                            <date-picker v-model="ldlDateEntry" name="datepicker" format="DD/MM/YYYY" placeholder="Select date"></date-picker>
                        </div>
                        <p class="red-text center" v-if="ldlFeedback">{{ldlFeedback}}</p>
                        <div class="center">
                            <button class="btn">Add LDL-C level +</button>
                        </div>
                    </form>
                </div>
                <div class="col m6 s12 ldlList">

                    <!-- <canvas class="card-panel" id="planet-chart"></canvas> -->
                    <div v-if="!ldlCheckbox" class="card-panel">
                        <line-chart :data="ldlChartDataMg" :colors="['#22a666']" suffix="mg/dL" ytitle="mg/dL"></line-chart>
                    </div>
                    <div v-else class="card-panel">
                        <line-chart :data="ldlChartDataMmol" :colors="['#22a666']" suffix="mmol/l" ytitle="mmol/l"></line-chart>
                    </div>

                </div>

                <div v-if="this.pastLDL">
                    <div class="col m12 s12 ldlList">
                        <ul>
                            <li v-for="(gLDL, index) in pastLDL.slice().reverse()" :key="index">
                                <!-- <p><strong>{{weight.num}}kg, entered {{weight.timestamp}}</strong></p> -->
                                <p><strong><span v-if="!ldlCheckbox">{{gLDL.num}}mg/dL</span><span v-else>{{mgdlToMmol(gLDL.num)}}mmol/l</span></strong> &#x000B7; {{ formatToDay(gLDL.timestamp) }}<a class="btn-floating waves-effect waves-light deleteWeight" @click="deleteLDL(gLDL.num, gLDL.timestamp)"><i class="material-icons">delete</i></a></p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div id="fat-tab">
            <div class="row">
                <div class="col m6 s12">
                    <form class="card-panel fat-card" @submit.prevent="addFat">
                        <div>
                            <h3>Fat tracker</h3>
                            <!-- <div class="switch">
                                    <label>
                                        Download printable tracker <i class="material-icons tiny blue-text">file_download</i>
                                    </label>
                                </div> -->
                        </div>
                        <div class="input-field">
                            <label for="fatEntry">Enter your daily Fat intake in grams:</label>
                            <input type="text" name="fatEntry" v-model="fatEntry">
                        </div>
                        <div class="">
                            <!-- <label for="date">Enter the date:</label> -->
                            <!-- <input type="text" class="datepicker" v-model="weightDateEntry"> -->
                            <date-picker v-model="fatDateEntry" name="datepicker" format="DD/MM/YYYY" placeholder="Select date"></date-picker>
                        </div>
                        <p class="red-text center" v-if="fatFeedback">{{fatFeedback}}</p>
                        <div class="center">
                            <button class="btn">Add Fat level +</button>
                        </div>
                        <br>
                        <small>87011-4. Date of preparation: Aug 2018.</small>
                    </form>
                </div>
                <div class="col m6 s12 fatList">

                    <!-- <canvas class="card-panel" id="planet-chart"></canvas> -->
                    <div class="card-panel">
                        <line-chart :data="fatChartData" :colors="['#239ae5']" suffix="g" ytitle="g"></line-chart>
                    </div>

                </div>

                <div v-if="this.pastFats">
                    <div class="col m12 s12 fatList">
                        <ul>
                            <li v-for="(gFat, index) in pastFats.slice().reverse()" :key="index">
                                <!-- <p><strong>{{weight.num}}kg, entered {{weight.timestamp}}</strong></p> -->
                                <p><strong><span>{{gFat.num}}g</span></strong> &#x000B7; {{ formatToDay(gFat.timestamp) }}<a class="btn-floating waves-effect waves-light deleteWeight" @click="deleteFat(gFat.num, gFat.timestamp)"><i class="material-icons">delete</i></a></p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import firebase from 'firebase'
import db from '@/firebase/init'
import moment from 'moment'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import request from 'request';

// import { Bar } from 'vue-chartjs'
// import planetChartData from '@/assets/js/chart-data.js';
export default {
    name: 'Profile',
    // extends: Bar,
    components: {
        DatePicker
    },
    data() {
        return {
            profile: null,
            user: firebase.auth().currentUser,
            weightEntry: null,
            weightDateEntry: null,
            weightFeedback: null,
            pastWeights: [],
            weightChartDataKgs: [],
            weightChartDataLbs: [],
            weightCheckbox: false,

            ldlEntry: null,
            ldlDateEntry: null,
            ldlFeedback: null,
            pastLDL: [],
            ldlChartDataMg: [],
            ldlChartDataMmol: [],
            ldlCheckbox: false,

            fatEntry: null,
            fatDateEntry: null,
            fatFeedback: null,
            pastFats: [],
            fatChartData: [],
            // planetChartData: planetChartData
        }
    },
    mounted() {
        /* eslint-disable */
        const collapsible = document.querySelector('.collapsible')
        M.Collapsible.init(collapsible)

        const datepicker = document.querySelector('.datepicker')
        M.Datepicker.init(datepicker)

        const tabs = document.querySelector('.tabs')
        M.Tabs.init(tabs)

        this.user = firebase.auth().currentUser
        document.body.classList.add("sub-page");

        // this.createChart('planet-chart', this.planetChartData);

    },
    created() {

        // Chartkick.options = {
        //     colors: ["#feb813"], 
        //     suffix: "kgs",
        //     ytitle: "Kilograms"
        // }
        /* eslint-disable */
        // retrieve weight list
        // var weightChartKgs = Chartkick.charts["weightChartKgs"];

        db.collection('users').where('user_id', '==', this.user.uid)
            .onSnapshot((snapshot) => {
                snapshot.docChanges().forEach(change => {

                    this.pastWeights = change.doc.data().weightsList
                    this.pastLDL = change.doc.data().ldlList
                    this.pastFats = change.doc.data().fatList
                    // console.log("Changes: "+snapshot.docChanges());

                    /* eslint-disable */
                    // console.log(this.pastWeights);

                    this.weightChartDataKgs = []
                    this.weightChartDataLbs = []
                    this.ldlChartDataMg = []
                    this.ldlChartDataMmol = []
                    this.fatChartData = []

                    if (this.pastWeights) {
                        for (var i = 0; i < this.pastWeights.length; i++) {

                            var obj = this.pastWeights[i];

                            var indexChartFormatKgs = [this.formatToDay(obj.timestamp), obj.num]
                            var indexChartFormatLbs = [this.formatToDay(obj.timestamp), this.kgToPounds(obj.num)]

                            this.weightChartDataKgs.push(indexChartFormatKgs)
                            this.weightChartDataLbs.push(indexChartFormatLbs)

                        }
                    }

                    if (this.pastLDL) {
                        for (var i = 0; i < this.pastLDL.length; i++) {

                            var obj = this.pastLDL[i];

                            var indexChartFormatMg = [this.formatToDay(obj.timestamp), obj.num]
                            var indexChartFormatMmol = [this.formatToDay(obj.timestamp), this.mgdlToMmol(obj.num)]

                            this.ldlChartDataMg.push(indexChartFormatMg)
                            this.ldlChartDataMmol.push(indexChartFormatMmol)

                        }
                    }

                    if (this.pastFats) {
                        for (var i = 0; i < this.pastFats.length; i++) {

                            var obj = this.pastFats[i];

                            var indexChartFormat = [this.formatToDay(obj.timestamp), obj.num]

                            this.fatChartData.push(indexChartFormat)
                        }
                    }

                    /* eslint-disable */
                    // console.log("KG DATA "+this.weightChartDataKgs);
                    // console.log("LBS DATA "+this.weightChartDataLbs);

                    // this.refreshCharts()
                    // console.log(this.pastWeights)

                })
            })

        // console.log(firebase.auth().currentUser.emailVerified)

        for (var i = 0; i < this.weightChartDataKgs.length; i++) {
            /* eslint-disable */
            console.log(i);
        }

        // console.log(this.weightChartDataKgs);

    },
    methods: {
        addWeight() {
            /* eslint-disable */
            //update users weight record

            if (this.weightEntry) {

                var weightVar = this.weightEntry;
                var weightDateVar = Date.parse(this.weightDateEntry);

                if (this.weightCheckbox) {
                    weightVar = this.poundsToKg(this.weightEntry)
                }

                // console.log(weightVar + ", " + weightDateVar);

                db.collection('users').where('user_id', '==', this.user.uid).get()
                    .then(snapshot => {
                        snapshot.forEach((doc) => {
                            db.collection('users').doc(doc.id).update({
                                weightsList: firebase.firestore.FieldValue.arrayUnion({
                                    "num": weightVar,
                                    "timestamp": weightDateVar
                                })
                            })
                        });
                    }).then(() => {

                        this.weightFeedback = ""

                        // weightChartKgs.redraw();
                        // console.log(weightChartKgs)
                        // this.refreshCharts()

                        var toastHTML = '<span>Weight Added</span>';
                        M.toast({
                            html: toastHTML
                        });

                        const url = 'https://hooks.slack.com/services/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';
                        const text = this.user.email + ' updated their weight stats';

                        request.post({
                                headers: {
                                    'Content-type': 'application/json'
                                },
                                url,
                                form: JSON.stringify({
                                    text
                                }),
                            },
                            (error, res, body) => console.log(error, body, res.statusCode)
                        );

                    })
            } else {
                this.weightFeedback = "Enter a weight before submitting"
            }
        },
        deleteWeight(num, time) {
            // var currentIndex =  this.pastWeights.length - e - 1;
            // var currentIndex = e;
            // console.log(num + " " + time)
            // var pw = this.pastWeights
            // var pa = {"num": 100, "timestamp": time}

            // this.removeA(pw, pa)

            // this.pastWeights.pop(pa)

            // console.log(this.pastWeights)

            db.collection('users').where('user_id', '==', this.user.uid).get()
                .then(snapshot => {
                    snapshot.forEach((doc) => {
                        db.collection('users').doc(doc.id).update({
                            weightsList: firebase.firestore.FieldValue.arrayRemove({
                                "num": num,
                                "timestamp": time
                            })
                        })
                    });
                }).then(() => {

                    // this.refreshCharts()

                    var toastHTML = '<span>Weight Removed</span>';
                    M.toast({
                        html: toastHTML
                    });

                })

        },
        addLDL() {
            /* eslint-disable */
            //update users weight record
            //   var currUser = db.collection("users").where('user_id', '==', this.user.uid);

            if (this.ldlEntry) {

                var ldlVar = this.ldlEntry;
                var ldlDateVar = Date.parse(this.ldlDateEntry);

                if (this.ldlCheckbox) {
                    ldlVar = this.mmolToMgdl(this.ldlEntry)
                }

                console.log(ldlVar);

                db.collection('users').where('user_id', '==', this.user.uid).get()
                    .then(snapshot => {
                        snapshot.forEach((doc) => {
                            db.collection('users').doc(doc.id).update({
                                ldlList: firebase.firestore.FieldValue.arrayUnion({
                                    "num": ldlVar,
                                    "timestamp": ldlDateVar
                                })
                            })
                        });
                    }).then(() => {
                        // this.pastWeights.push({num: this.weight, timestamp: Date.now()})

                        this.ldlFeedback = ""

                        var toastHTML = '<span>LDL-C Added</span>';
                        M.toast({
                            html: toastHTML
                        });

                        const url = 'https://hooks.slack.com/services/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';
                        const text = this.user.email + ' updated their ldl stats';

                        request.post({
                                headers: {
                                    'Content-type': 'application/json'
                                },
                                url,
                                form: JSON.stringify({
                                    text
                                }),
                            },
                            (error, res, body) => console.log(error, body, res.statusCode)
                        );

                    })
            } else {
                this.ldlFeedback = "Enter your LDL-C level before submitting"
            }
        },
        deleteLDL(num, time) {
            // var currentIndex =  this.pastWeights.length - e - 1;
            // var currentIndex = e;
            console.log(num + " " + time)

            db.collection('users').where('user_id', '==', this.user.uid).get()
                .then(snapshot => {
                    snapshot.forEach((doc) => {
                        db.collection('users').doc(doc.id).update({
                            ldlList: firebase.firestore.FieldValue.arrayRemove({
                                "num": num,
                                "timestamp": time
                            })
                            // ["weightsList." + currentIndex] : firebase.firestore.FieldValue.delete()
                        })
                    });
                }).then(() => {
                    // this.pastWeights.push({num: this.weight, timestamp: Date.now()})

                    var toastHTML = '<span>LDL-C Removed</span>';
                    M.toast({
                        html: toastHTML
                    });

                })

        },
        addFat() {
            /* eslint-disable */
            //update users fat record

            if (this.fatEntry) {

                var fatVar = this.fatEntry;
                var fatDateVar = Date.parse(this.fatDateEntry);

                if (this.fatCheckbox) {
                    fatVar = this.poundsToKg(this.fatEntry)
                }

                db.collection('users').where('user_id', '==', this.user.uid).get()
                    .then(snapshot => {
                        snapshot.forEach((doc) => {
                            db.collection('users').doc(doc.id).update({
                                fatList: firebase.firestore.FieldValue.arrayUnion({
                                    "num": fatVar,
                                    "timestamp": fatDateVar
                                })
                            })
                        });
                    }).then(() => {

                        this.fatFeedback = ""

                        var toastHTML = '<span>Fat Added</span>';
                        M.toast({
                            html: toastHTML
                        });

                        const url = 'https://hooks.slack.com/services/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';
                        const text = this.user.email + ' updated their fat stats';

                        request.post({
                                headers: {
                                    'Content-type': 'application/json'
                                },
                                url,
                                form: JSON.stringify({
                                    text
                                }),
                            },
                            (error, res, body) => console.log(error, body, res.statusCode)
                        );

                    })
            } else {
                this.fatFeedback = "Enter a fat before submitting"
            }
        },
        deleteFat(num, time) {

            db.collection('users').where('user_id', '==', this.user.uid).get()
                .then(snapshot => {
                    snapshot.forEach((doc) => {
                        db.collection('users').doc(doc.id).update({
                            fatList: firebase.firestore.FieldValue.arrayRemove({
                                "num": num,
                                "timestamp": time
                            })
                        })
                    });
                }).then(() => {

                    // this.refreshCharts()

                    var toastHTML = '<span>Fat Removed</span>';
                    M.toast({
                        html: toastHTML
                    });

                })

        },
        formatToTime(e) {
            return moment(e).format('lll');
            // return e
        },
        formatToDay(e) {
            return moment(e).format('ll');
            // return e
        },
        // createChart(chartId, chartData) {
        //     const ctx = document.getElementById(chartId);
        //     const myChart = new Chart(ctx, {
        //     type: chartData.type,
        //     data: chartData.data,
        //     options: chartData.options,
        //     });
        // },
        kgToPounds(num) {
            var poundsTemp = num * 2.205;
            return poundsTemp.toFixed(1);
        },
        poundsToKg(num) {
            var kgsTemp = num / 2.205;
            return kgsTemp.toFixed(1);
        },
        mgdlToMmol(num) {
            var mmolTemp = num / 18;
            return mmolTemp.toFixed(2);
        },
        mmolToMgdl(num) {
            var mgTemp = num * 18;
            return mgTemp.toFixed(2);
        },
        refreshCharts() {
            Chartkick.eachChart(function (chart) {
                chart.refreshData()
                console.log(chart)
            })
        },
        removeA(arr) {
            var what, a = arguments,
                L = a.length,
                ax;
            while (L > 1 && arr.length) {
                what = a[--L];
                while ((ax = arr.indexOf(what)) !== -1) {
                    arr.splice(ax, 1);
                }
            }
            return arr;
        }
    }
}
</script>

<style>
input:-internal-autofill-selected {
    background-color: #fff !important;
}

.sub-page .hero-p {
    margin-bottom: 25px;
}

.profile {
    margin-top: 100px !important
}

.profile h1 {
    text-align: left !important;
    margin: 0 0 .5em !important;
    padding: 0 !important;
}

.img-03 {
    width: 300px;
    position: absolute;
    top: 13px;
    right: 10%;
    z-index: -1;
}

.weight-heading {
    font-family: 'Baloo Thambi', cursive;
    color: #fbab18;
    display: inline-block;
    padding-right: 80px;
}

.weight-card h3 {
    font-size: 1.4em;
    color: #fbab18;
    text-align: left;
    float: left;
    margin: 0;
}

.weight-card .switch {
    float: right;
}

.weight-card .switch label .lever:after,
.weight-card .switch label input[type=checkbox]:checked+.lever:after {
    background-color: #feb811;
}

.weight-card .switch label .lever,
.switch label input[type=checkbox]:checked+.lever {
    background-color: #fee9b8 !important;
}

/* .weight-card .switch label input[type=checkbox]:checked+.lever:after{

} */

.ldl-card h3 {
    font-size: 1.4em;
    color: #22a666;
    text-align: left;
    float: left;
    margin: 0;
}

.ldl-card .switch {
    float: right;
}

.ldl-card .switch label .lever:after,
.ldl-card .switch label input[type=checkbox]:checked+.lever:after {
    background-color: #22a666;
}

.ldl-card .switch label .lever,
.ldl-card .switch label input[type=checkbox]:checked+.lever {
    background-color: #9bd8bb !important;
}

/* .ldl-card .switch label input[type=checkbox]:checked+.lever:after{

} */
.fat-card h3 {
    font-size: 1.4em;
    color: #239ae5;
    text-align: left;
    float: left;
    margin: 0;
}

.fat-card .switch {
    float: right;
}

.fat-card .switch label .lever:after,
.fat-card .switch label input[type=checkbox]:checked+.lever:after {
    background-color: #22a666;
}

.fat-card .switch label .lever,
.fat-card .switch label input[type=checkbox]:checked+.lever {
    background-color: #9bd8bb !important;
}

.weight-card .input-field,
.ldl-card .input-field,
.fat-card .input-field {
    margin-top: 3em;
}

.weight-card label,
.ldl-card label,
.fat-card label {
    font-size: 1rem;
}

.weight-card .btn,
.ldl-card .btn,
.fat-card .btn {
    background-color: #feb813 !important;
    border-radius: 30px;
    height: auto;
    line-height: inherit;
    font-family: 'Baloo Thambi', cursive;
    padding: 10px 50px;
}

.ldl-card .btn {
    background-color: #22a666 !important;
}

.fat-card .btn {
    background-color: #239ae5 !important;
}

.weight-card .input-field>label,
.ldl-card .input-field>label,
.fat-card .input-field>label {
    pointer-events: none;
}

.weightsList .yellow-text {
    color: #feb813 !important;
}

.weightsList .card-panel {
    -webkit-transition: all 500ms ease-in-out;
    -moz-transition: all 500ms ease-in-out;
    -o-transition: all 500ms ease-in-out;
    transition: all 500ms ease-in-out;
}

.ldlList .green-text {
    color: #22a666 !important;
}

.fatList .green-text {
    color: #239ae5 !important;
}

.weightsList h2,
.ldlList h2,
.fatList h2 {
    font-size: 1.4em !important;
    /* text-align: center !important; */
    margin-top: 1em !important;
}

input[type="text"]:not(.browser-default):focus:not([readonly]) {
    box-shadow: none !important;
}

.weightsList .btn-floating,
.ldlList .btn-floating,
.fatList .btn-floating {
    width: 30px;
    height: 30px;
    line-height: 30px;
    float: right;
    background-color: #f16a54 !important
}

.weightsList .card-panel,
.ldlList .card-panel,
.fatList .card-panel {
    padding: 20px 10px;
}

.weightsList ul,
.ldlList ul,
.fatList ul {
    margin: 0 20px;
}

.weightsList li,
.ldlList li,
.fatList li {
    border-bottom: 1px solid #feb813;
    text-align: left;
    padding: 10px 20px;
}

.ldlList li {
    border-bottom: 1px solid #22a666;
}

.fatList li {
    border-bottom: 1px solid #239ae5;
}

.weightsList .btn-floating i,
.ldlList .btn-floating i,
.fatList .btn-floating i {
    font-size: 1rem;
    line-height: 30px;
}

.mx-datepicker {
    width: 100%;
    margin: 10px 0 30px;
}

.mx-datepicker-body {
    padding: 16px;
}

.mx-input-wrapper input:not([type]),
.mx-input-wrapper input[type=text]:not(.browser-default) {
    font-size: 1rem !important;
}

.mx-calendar-content .cell {
    text-align: center;
}

.profile .collapsible li {
    text-align: unset;
    border: none;
    padding: 0;
}

.profile .collapsible .left-align {
    float: left;
}

.profile .collapsible .right-align {
    float: right;
}

.profile .collapsible span {
    text-align: left;
    width: 100%;
}

.profile .collapsible-body {
    padding: 1em;
}

.tabs .tab a,
.tabs .tab h4 {
    margin: 0;
    font-size: 1.2em;
    padding: 6px 0;
}

.weight-tab h4 {
    color: #fcb812;
}

.ldl-tab h4 {
    color: #22a666;
}

.fat-tab h4 {
    color: #039be5;
}

.tabs {
    margin-bottom: 2em;
    background-color: rgba(38, 186, 213, 0.07);
}

.tabs a,
.tabs .tab a.active:after {
    -webkit-transition: all 500ms ease-in-out;
    -moz-transition: all 500ms ease-in-out;
    -o-transition: all 500ms ease-in-out;
    transition: all 500ms ease-in-out;
}

.tabs .indicator {
    display: none;
}

.tabs .tab a:focus,
.tabs .tab a:focus.active {
    background-color: #26bad6;
}

.tabs .tab a.active {
    position: relative;
}

.tabs,
.tab,
.tab a {
    overflow: visible !important;
    overflow-wrap: break-word;
    white-space: normal;
}

.tabs .tab a.active:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -10px;
    width: 0;
    height: 0;
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
}

.tabs .tab a.weight-tab.active {
    background-color: #fcb812;
}

.tabs .tab a.weight-tab.active:after {
    border-top: solid 10px #fcb812;
}

.tabs .tab a.ldl-tab.active {
    background-color: #22a666;
}

.tabs .tab a.ldl-tab.active:after {
    border-top: solid 10px #22a666;
}

.tabs .tab a.fat-tab.active {
    background-color: #26bad6;
}

.tabs .tab a.fat-tab.active:after {
    border-top: solid 10px #26bad6;
}

.tabs .tab a:hover {
    cursor: pointer;
}

.tab a.active h4 {
    color: #fff;
}

@media screen and (max-device-width: 768px) {

    .weightsList ul,
    .ldlList ul {
        margin: 0;
    }

    .weightsList li,
    .ldlList li {
        padding: 10px 0px;
    }
}

@media screen and (max-device-width: 420px) {
    .tab a h4 {
        font-size: 1em !important;
        padding: 0 10px !important;
    }
}
</style>
