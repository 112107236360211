import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Favourites from '../views/Favourites.vue'
import Categories from '../views/Categories.vue'
import AllRecipes from '../views/All-recipes.vue'
import Signup from '../views/Signup.vue'
import Login from '../views/Login.vue'
import Forgot from '../views/Forgot.vue'
import Verify from '../views/Verify.vue'
import MealPlan from '../views/MealPlan.vue'
import ShoppingList from '../views/ShoppingList.vue'
import Profile from '../views/Profile.vue'
import CarbControl from '../views/lifestyle-tips/CarbControl.vue'
import EatingOnABudget from '../views/lifestyle-tips/EatingOnABudget.vue'
import MaintainingWeight from '../views/lifestyle-tips/MaintainingWeight.vue'
import SmartFoodSubstitutions from '../views/lifestyle-tips/SmartFoodSubstitutions.vue'
import Recipe from '../views/Recipe.vue'

import HomeItaliano from '../views/italiano/index.vue'
import RecipeItaliano from '../views/italiano/recipe.vue'

import HomeArabic from '../views/arabic/index.vue'
import RecipeArabic from '../views/arabic/recipe.vue'
import AllRecipeArabic from '../views/arabic/All-recipes.vue'


import HomeCanadian from '../views/canadian/index.vue'
import RecipeCanadian from '../views/canadian/recipe.vue'
import AllRecipeCanadian from '../views/canadian/All-recipes.vue'
import CategoriesCanadian from '../views/canadian/Categories.vue'

import HomeFrench from '../views/french/index.vue'
import RecipeFrench from '../views/french/recipe.vue'
import AllRecipeFrench from '../views/french/All-recipes.vue'
import CategoriesFrench from '../views/french/Categories.vue'

import firebase from 'firebase'
// import VuePageTransition from 'vue-page-transition'
import Chartkick from 'vue-chartkick'
import Chart from 'chart.js'
import VueHtmlToPaper from 'vue-html-to-paper';
import VueMeta from 'vue-meta'

Vue.use(VueRouter);
// Vue.use(VuePageTransition);
Vue.use(Chartkick.use(Chart));
Vue.use(VueHtmlToPaper);
/* eslint-disable */
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { isEN: true }
  },
  {
    path: '/favourites',
    name: 'favourites',
    component: Favourites,
    meta: {
      requiresAuth : true,
      isEN: true
    }
  },
  {
    path: '/allRecipes',
    name: 'allRecipes',
    component: AllRecipes,
    meta: { isEN: true }
  },
  {
    path: '/categories/:id',
    name: 'categories',
    component: Categories,
    meta: { isEN: true }
  },
  {
    path: '/signup',
    name: 'signup',
    component: Signup,
    meta: { isEN: true }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { isEN: true }
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: Forgot,
    meta: { isEN: true }
  },
  {
    path: '/verify',
    name: 'verify',
    component: Verify,
    meta: { isEN: true }
  },
  {
    path: '/recipe/:id',
    name: 'recipe',
    component: Recipe,
    meta: { isEN: true }
  },
  {
    path: '/mealPlan',
    name: 'mealPlan',
    component: MealPlan,
    meta: {
      requiresAuth : true,
      isEN: true
    }
  },
  {
    path: '/ShoppingList',
    name: 'ShoppingList',
    component: ShoppingList,
    meta: {
      requiresAuth : true,
      isEN: true
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: {
      requiresAuth : true,
      isEN: true
    }
  },
  {
    path: '/lifestyle-tips/CarbControl/',
    name: 'CarbControl',
    component: CarbControl,
    meta: {
      requiresAuth : false,
      isEN: true
    }
  },
  {
    path: '/lifestyle-tips/EatingOnABudget/',
    name: 'EatingOnABudget',
    component: EatingOnABudget,
    meta: {
      requiresAuth : false,
      isEN: true
    }
  },
  {
    path: '/lifestyle-tips/MaintainingWeight/',
    name: 'MaintainingWeight',
    component: MaintainingWeight,
    meta: {
      requiresAuth : false,
      isEN: true
    }
  },
  {
    path: '/lifestyle-tips/SmartFoodSubstitutions/',
    name: 'SmartFoodSubstitutions',
    component: SmartFoodSubstitutions,
    meta: {
      requiresAuth : false,
      isEN: true
    }
  },
  {
    path: '/italiano/',
    name: 'HomeItaliano',
    component: HomeItaliano,
    meta: { isIT: true }
  },
  {
    path: '/italiano/recipe/:id',
    name: 'RecipeItaliano',
    component: RecipeItaliano,
    meta: { isIT: true }
  },
  {
    path: '/arabic/',
    name: 'HomeArabic',
    component: HomeArabic,
    meta: { isAR: true }
  },
  {
    path: '/arabic/recipe/:id',
    name: 'RecipeArabic',
    component: RecipeArabic,
    meta: { isAR: true }
  },
  {
    path: '/arabic/all-recipes',
    name: 'AllRecipeArabic',
    component: AllRecipeArabic,
    meta: { isAR: true }
  },
  {
    path: '/canadian/',
    name: 'HomeCanadian',
    component: HomeCanadian,
    meta: { isCA: true }
  },
  {
    path: '/canadian/recipe/:id',
    name: 'RecipeCanadian',
    component: RecipeCanadian,
    meta: { isCA: true }
  },
  {
    path: '/canadian/all-recipes',
    name: 'AllRecipeCanadian',
    component: AllRecipeCanadian,
    meta: { isCA: true }
  },
  {
    path: '/canadian/categories/:id',
    name: 'CategoriesCanadian',
    component: CategoriesCanadian,
    meta: { isCA: true }
  },
  {
    path: '/french/',
    name: 'HomeFrench',
    component: HomeFrench,
    meta: { isFR: true }
  },
  {
    path: '/french/recipe/:id',
    name: 'RecipeFrench',
    component: RecipeFrench,
    meta: { isFR: true }
  },
  {
    path: '/french/all-recipes',
    name: 'AllRecipeFrench',
    component: AllRecipeFrench,
    meta: { isFR: true }
  },
  {
    path: '/french/categories/:id',
    name: 'CategoriesFrench',
    component: CategoriesFrench,
    meta: { isFR: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  // check is route requires the user to be signed in
  // window.scrollTo(0, 0)
  if(to.matched.some(rec => rec.meta.requiresAuth)){
    // check auth
    let user = firebase.auth().currentUser

    if(user){
      next()
    } else{
      next({name: 'login'})
    }
  } else {
    next()
  }
})

export default router
