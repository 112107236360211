<template>
    <div class="verify container">
        <div class="card-panel">
            <div class="row">
                <div class="col m6 s12">
                    <div class="verify-left">
                        <h1 class="">Verify your email</h1>
                    
                        <!-- <p><strong>You will need to verify your email to complete your registration</strong> </p> -->
                        <p><strong> An email has been sent to xxx@gmail.com containing a link to verify your account.</strong></p>
                        <p>If you have not recieved the email after a few minutes, resend the email and check your spam folder. </p>
                        <p><a class="waves-effect waves-teal btn mt-3">Resend email</a></p>



                        <!-- <div class="row mt-4">
                            <div class="col m6 s12"><p><button class="btn btn-large">Verify email</button></p></div>
                            <div class="col m6 s12"><p><a class="waves-effect waves-teal btn-flat">Resend email</a></p></div>
                        </div> -->

                        <!-- <small>Already have an account? Log in <span v-if="!user"><router-link to="/login">here</router-link></span></small> -->
                    </div>
                </div>
                <div class="col m6 s12 no-margin">
                    <div class="verify-img">
                        <img class="responsive-img" src="@/assets/img/verify-img.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase'
export default {
    name: 'login',
    data(){
        return {
            email: null,
            password: null,
            feedback: null
        }
    },
    methods : {
        login(){
            /* eslint-disable */
            // console.log(this.email, this.password)

            if(this.email && this.password){

                firebase.auth().signInWithEmailAndPassword(this.email, this.password)
                .then(cred => {
                    console.log(cred.user)
                    this.$router.push({name: 'home'})
                }).catch(err => {
                    this.feedback = err.message
                })

                this.feedback = null;
            }else{
                this.feedback = "Please fill in both fields"
            }
        },
        socialLogin(){
            const provider = new firebase.auth.GoogleAuthProvider();

            firebase.auth().signInWithPopup(provider)
            .then((result) => {
                this.$router.push({name: 'home'})
            }).catch(err => {
                this.feedback = err.message
            })
        }
    }
}
</script>

<style>

    html, body {
        height: 100%;
        padding: 0;
        margin: 0;

    }

    .mt-3{margin-top: 3em;}
    .verify{
        margin-top: 50px;
    }

    .verify-left{
        padding: 1em 2em;
    }

    .verify h1{
        font-size:  2em;
        margin: 2em 0 1.5em;
    }

     .verify button svg{
        max-width: 20px;
        margin-top: 8px;
        float: left;
    }

    .no-margin{
        margin: 0;
        padding: 0 !important;
    }

    .verify-img{
        position: relative;
        height: 510px;
        background: rgb(0,161,201);
        background: -moz-linear-gradient(319deg, rgb(251, 171, 22) 0%, rgb(255, 203, 7) 100%);
        background: -webkit-linear-gradient(319deg, rgb(251, 171, 22) 0%, rgb(255, 203, 7) 100%);
        background: linear-gradient(319deg, rgb(251, 171, 22) 0%, rgb(255, 203, 7) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00a1c9",endColorstr="#02b2d1",GradientType=1);
    }

    .verify-img img{
        max-width: 50%;
        position: absolute;
        top:0; right:0; bottom:0; left:0;
        margin: auto;
    }

    .verify .card-panel{
        padding: 0
    }

    .btn-flat{
        height: 54px;
        line-height: 54px;
    }
</style>