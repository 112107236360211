export default [
    {
      title: "Mele, salsa di mele",
      serves: "1 piccola",
      fat: 0,
      category: "fruit"
    },
    {
      title: "Banane",
      serves: "1 piccola",
      fat: 0,
      category: "fruit"
    },
    {
      title: "Frutti di bosco",
      serves: "130g",
      fat: 0,
      category: "fruit"
    },
    {
      title: "Uva",
      serves: "1 tazza 130g",
      fat: 0,
      category: "fruit"
    },
    {
      title: "Kiwi",
      serves: "1 medio",
      fat: 0,
      category: "fruit"
    },
    {
      title: "Arance, mandarini",
      serves: "1 piccolo",
      fat: 0,
      category: "fruit"
    },
    {
      title: "Pesche, pesche noci",
      serves: "1 piccolo",
      fat: 0,
      category: "fruit"
    },
    {
      title: "Macedonia di frutta e altra frutta in scatola (eccetto il pompelmo)",
      serves: "65g",
      fat: 0,
      category: "fruit"
    },
    {
      title: "Uvetta, altra frutta secca",
      serves: "30g",
      fat: 0,
      category: "fruit"
    },
    {
      title: "Chips di banana",
      serves: "30g",
      fat: 9,
      category: "fruit"
    },
    {
      title: "Broccoli",
      serves: "130g",
      fat: 0,
      category: "vegetables"
    },
    {
      title: "Carote",
      serves: "130g",
      fat: 0,
      category: "vegetables"
    },
    {
      title: "Cavolfiore",
      serves: "130g",
      fat: 0,
      category: "vegetables"
    },
    {
      title: "Fagiolini",
      serves: "130g",
      fat: 0,
      category: "vegetables"
    },
    {
      title: "Insalate, spinaci",
      serves: "130g",
      fat: 0,
      category: "vegetables"
    },
    {
      title: "Pomodori, pomodori pelati in scatola",
      serves: "1 Grande",
      fat: 0,
      category: "vegetables"
    },
    {
      title: "Cipolle",
      serves: "1 piccolo",
      fat: 0,
      category: "vegetables"
    },
    {
      title: "Patate, patate Dolci",
      serves: "1 media",
      fat: 0,
      category: "vegetables"
    },
    {
      title: "Zucchine",
      serves: "1 media",
      fat: 0,
      category: "vegetables"
    },
    {
      title: "Verdure surgelate senza grassi aggiunti",
      serves: "130g",
      fat: 0,
      category: "vegetables"
    },
    {
      title: "Fagioli in scatola, fagioli messicani senza grassi, fagioli al forno vegetariani",
      serves: "70g",
      fat: 1,
      category: "vegetables"
    },
    {
      title: "Albume d'uovo",
      serves: "425g",
      fat: 0,
      category: "meat"
    },
    {
      title: "Tacchino macinato senza grassi al 99%",
      serves: "120g",
      fat: 1,
      category: "meat"
    },
    {
      title: "Petto di tacchino, fette di tacchino",
      serves: "30g",
      fat: 1,
      category: "meat"
    },
    {
      title: "Hot dog a basso contenuto di grassi",
      serves: "50g",
      fat: 1,
      category: "meat"
    },
    {
      title: "Tonno al naturale",
      serves: "56g",
      fat: 1.5,
      category: "meat"
    },
    {
      title: "Pesce bianco magro come sogliola",
      serves: "115g",
      fat: 2,
      category: "meat"
    },
    {
      title: "Gamberetti, capesante, altri crostacei",
      serves: "85g",
      fat: 0.5,
      category: "meat"
    },
    {
      title: "Latte scremato",
      serves: "240ml",
      fat: 0,
      category: "dairy"
    },
    {
      title: "Ricotta o altro formaggio cremoso senza grassi",
      serves: "170g",
      fat: 0,
      category: "dairy"
    },
    {
      title: "Yogurt senza grassi o yogurt greco",
      serves: "240ml",
      fat: 0,
      category: "dairy"
    },
    {
      title: "Formaggio senza grassi, crema di formaggio, panna acida",
      serves: "1 fetta, 30mg",
      fat: 0,
      category: "dairy"
    },
    {
      title: "Latticini metà e metà senza grassi",
      serves: "30mg",
      fat: 0,
      category: "dairy"
    },
    {
      title: "Pane a fette",
      serves: "1 fetta",
      fat: 0.5,
      category: "bread"
    },
    {
      title: "Rotolo per la cena",
      serves: "1 piccolo",
      fat: 1.5,
      category: "bread"
    },
    {
      title: "Bagel",
      serves: "1 piccolo, 60g",
      fat: 1,
      category: "bread"
    },
    {
      title: "Pane pita",
      serves: "1 piccolo",
      fat: 0,
      category: "bread"
    },
    {
      title: "Muffin inglese",
      serves: "60g",
      fat: 1.5,
      category: "bread"
    },
    {
      title: "Tortillas di mais",
      serves: "1 piccolo",
      fat: 1,
      category: "bread"
    },
    {
      title: "Tortillas di farina senza grassi",
      serves: "1 piccolo",
      fat: 0,
      category: "bread"
    },
    {
      title: "Cereali con meno di 1,5 g di grassi per porzione",
      serves: "60g",
      fat: 1.5,
      category: "bread"
    },
    {
      title: "Pasta, riso",
      serves: "1/2 tazza 65g",
      fat: 0,
      category: "bread"
    },
    {
      title: "Cracker salati senza grassi",
      serves: "5 cracker",
      fat: 0,
      category: "bread"
    },
    {
      title: "Matzo (pane non lievitato)",
      serves: "30g",
      fat: 1,
      category: "bread"
    },
    {
      title: "Gallette di riso non aromatizzate",
      serves: "1 reg",
      fat: 0.5,
      category: "bread"
    },
    {
      title: "Pretzel",
      serves: "30g",
      fat: 1,
      category: "bread"
    },
    {
      title: "Spray da cucina antiaderente",
      serves: "1/4 di secondo",
      fat: 0,
      category: "baking"
    },
    {
      title: "Spray aromatizzato al burro senza grassi",
      serves: "1/4 di secondo",
      fat: 0,
      category: "baking"
    },
    {
      title: "Gelatina, marmellata, crema di frutta",
      serves: "2 cucchiai",
      fat: 0,
      category: "baking"
    },
    {
      title: "Condimento per insalata senza grassi",
      serves: "2 cucchiai",
      fat: 0,
      category: "baking"
    },
    {
      title: "Limone fresco, lime, aceto",
      serves: "1 porzione",
      fat: 0,
      category: "baking"
    },
    {
      title: "Maionese senza grassi",
      serves: "2 cucchiai",
      fat: 0,
      category: "baking"
    },
    {
      title: "Condimenti come ketchup, senape, salsa barbecue, salsa teriyaki, salsa di soia, salsa piccante, Tabasco®, sottaceti",
      serves: "per porzione in bottiglia",
      fat: 0,
      category: "baking"
    },
    {
      title: "Burger vegetariano vegano (1 g di grassi per tortino)",
      serves: "1 tortino",
      fat: 1,
      category: "other"
    },
    {
      title: " Burrito a basso contenuto di grassi , riso e formaggio (congelato, 1 g di grassi per burrito)",
      serves: "1 burrito",
      fat: 1,
      category: "other"
    },
    {
      title: "Burro di arachidi magro,  burro di arachidi al cioccolato (in polvere) (1-1,5 g di grassi / 2 cucchiai)",
      serves: "2 cucchiai",
      fat: 1.5,
      category: "other"
    },
    {
      title: "Hummus senza grassi",
      serves: "2 cucchiai",
      fat: 0,
      category: "other"
    },
    {
      title: "Zuppe (fino a 1,5 g di grassi per tazza)",
      serves: "per porzione in lattina",
      fat: 1.5,
      category: "other"
    },
    {
      title: "Salsa di pomodoro, senza grassi",
      serves: "85g",
      fat: 0,
      category: "other"
    }
   ]