import 'babel-polyfill'
import Es6Promise from 'es6-promise'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import firebase from 'firebase'

// import 'materialize-css'

/* eslint-disable */

import 'es6-promise/auto'

import './registerServiceWorker'
Es6Promise.polyfill()

// require('es6-promise').polyfill()

Vue.config.productionTip = false

let app = null;

// Wait for firebase auth to emit before loading app!

firebase.auth().onAuthStateChanged(() => {

  //emit app id not already created

  if(!app){
    app = new Vue({
      router,
      render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
    }).$mount('#app')
  }

})
