export default [
    {
      title: "Pain aux bananes de tous les jours",
      slug: "pain-aux-bananes-de-tous-les-jours",
      img: "everyday-banana-bread",
      hasImage: true,
      description: "Délicieux et moelleux.",
      ingredients:
        "3 bananes mûres, en purée |2 blancs d’œufs|30 ml (2 c. à table) d’huile de colza|67 g à 100 g (1/3 à 1/2 tasse) de sucre|56 ml (1/4 tasse) de lait écrémé |2,5 ml (1/2 c. à thé) de sel|2,5 ml (1/2 c. à thé) de bicarbonate de soude|5 ml (1 c. à thé) de poudre à pâte|210 g (1 ½ tasse) de farine.",
      methods:
        'Préchauffer le four à 176°C (350°F).|Dans un grand bol, mélanger les cinq premiers ingrédients et bien remuer.|Incorporer délicatement les ingrédients secs et remuer jusqu’à ce que le mélange soit humide.|Verser dans un moule à pain de 13 cm par 23 cm (5 po par 9 po), vaporisé avec un enduit à cuisson antiadhésif.|Cuire au four pendant 50 à 55 minutes, jusqu’à ce qu’un couteau inséré au centre du pain en ressorte propre.|Réfrigérer les restes.',
      time: 65,
      serves: 10,
      fat: 3,
      saturated: 0,
      calories: 156,
      cholesterol: 0,
      carbohydrate: 29,
      protein: 3,
      sodium: 243,
      difficulty: "facile",
      category: "dessert|collation",
    },
    {
      title: "Sole frite au four",
      slug: "sole-frite-au-four",
      img: "oven-fried-sole",
      hasImage: true,
      ingredients:
        "454 g (1 lb) de sole ou de flet |125 g (1 tasse) de chapelure sèche |55 g (1/4 tasse) de vinaigrette française ou Mille-Îles sans gras ",
      methods:
        "Préchauffer le four à 232°C (450°F).| Tremper le poisson dans la vinaigrette, puis dans la chapelure. | Vaporiser une plaque à biscuits avec du Pam et y disposer le poisson.| Cuire pendant 10 à 12 minutes ou jusqu’à ce que le poisson se défasse facilement en morceaux avec une fourchette lorsque vous vérifiez sa cuisson. | Retourner à la mi-cuisson. | Servir avec du citron ou avec une quantité supplémentaire de vinaigrette.",
      serves: 4,
      time: 20,
      fat: 3.6,
      calories: 207,
      cholesterol: 52,
      carbohydrate: 24,
      saturated: 0.8,
      sodium: 670,
      fiber: 2,
      difficulty: "facile",
      category: "dîner|rapides et faciles",
    },
    {
      title: "Bœuf Stroganoff",
      slug: "bœuf-stroganoff",
      img: "beef-stroganoff",
      hasImage: true,
      description:
        "Vous pouvez réduire la quantité de matières grasses dans ce mets classique en utilisant de la crème sure sans gras ou du yogourt grec sans gras et un enduit à cuisson antiadhésif sans pour autant en atténuer la saveur!",
      ingredients:
        "454 g (1 livre) de bifteck de surlonge de bœuf désossé d’environ 1,3 cm (½ po) d’épaisseur|10 ml (2 c. à thé) de paprika|1 contenant de 236 ml (8 oz) de crème sure sans gras ou de yogourt grec sans gras|30 ml (2 c. à table) de farine tout usage|15 ml (1 c. à table) de pâte de tomate|125 ml (1/2 tasse) de bouillon de bœuf faible en sodium et sans gras|0,60 ml (1/8 c. à thé) de poivre noir|226 g (8 oz) de nouilles aux œufs larges, sans jaune|Enduit à cuisson antiadhésif|1 contenant de 226 g (8 oz) de champignons, tranchés|88 g (1/2 tasse) d’oignon haché|5 ml (1 c. à thé) d’ail émincé|Sel au goût",
      methods:
        "Retirer le gras du bœuf. Trancher en fines lamelles dans le sens contraire des fibres de la viande, puis les saupoudrer de paprika. Dans un petit bol, mélanger la crème sure ou le yogourt grec, la farine et la pâte de tomates. Incorporer le bouillon et ajouter le poivre. Mettre de côté.|Faire cuire les nouilles selon les indications sur l’emballage. Ne pas utiliser d’huile. Bien égoutter. |Pendant ce temps, vaporiser un grand poêlon avec un enduit à cuisson antiadhésif. Préchauffer à feu moyen. Ajouter l’oignon, l’ail et cuire jusqu’à ce que les oignons soient tendres, environ 3 minutes. Incorporer les lanières de viande et cuire pendant 3 minutes de plus. Ajouter les champignons, couvrir et laisser mijoter pendant 5 minutes. |Ajouter le mélange de crème sure ou de yogourt grec dans le poêlon. Cuire et remuer à feu moyen jusqu’à ce que le mélange épaississe et bouillonne. Servir le mélange de viande sur les nouilles.",
      serves: 5,
      fat: 7.4,
      saturated: 1.9,
      calories: 370,
      cholesterol: 63,
      carbohydrate: 47,
      protein: 28,
      sodium: 164,
      difficulty: "moyen",
      category: "dîner|mets préférés de la famille",
    },
    {
      title: "Longe de porc à l'asiatique préparée au barbecue",
      slug: "longe-de-porc",
      img: "asian-barbequed-pork-loin",
      hasImage: true,
      description:
        "Un filet de porc juteux, servi avec une sauce barbecue légèrement sucrée.",
      ingredients:
        "1,4 kg (3 lb) de rôti de longe de porc entier, désossé (paré du gras)|2,5 ml (1/2 c. à thé) de sel à l’ail|1,25 ml (1/4 c. à thé) de poivre noir|enduit à cuisson antiadhésif|44 g (1/4 tasse) d’oignon haché|175 g (1/2 tasse) de ketchup|57 g (1/3 tasse) de cassonade|15 ml (1 c. à table) de sauce hoisin|7,5 ml (1½ c. à thé) de moutarde|5 ml (1 c. à thé) de sauce soya|2,5 ml (1/2 c. à thé) de poudre d’ail|1,25 ml (1/4 c. à thé) de gingembre moulu|1,25 ml (1/4 c. à thé) de poudre chinoise cinq épices",
      methods:
        "Régler le four à 176°C (350°F).|Saupoudrer le rôti de sel à l’ail et de poivre. Mettre dans une rôtissoire et cuire au four, à découvert, pendant 40 minutes.|Vaporiser une casserole antiadhésive d’enduit à cuisson et faire revenir les oignons. Incorporer le reste des ingrédients et porter à ébullition. Laisser mijoter à découvert jusqu’à ce que la sauce soit réduite à ¾ tasse, en remuant souvent (20 à 25 minutes).|Verser la sauce sur le porc. Cuire durant 10 à 20 minutes de plus, jusqu’à ce qu’un thermomètre à viande indique 62°C (145°F).|Retirer du four, couvrir de papier d’aluminium et laisser reposer le rôti pendant 10 à 15 minutes avant de le trancher afin de conserver les jus de cuisson.",
      time: 100,
      serves: 12,
      fat: 3.2,
      saturated: 1,
      calories: 146,
      cholesterol: 52,
      carbohydrate: 10,
      protein: 32,
      sodium: 291,
      difficulty: "difficile",
      category: "dîner|hearty meal|pork",
    },
    {
      title: "Muffins aux bananes et au son",
      slug: "muffins-aux-bananes-et-au-son",
      img: "banana-bran-muffins",
      hasImage: true,
      description:
        "Cette recette utilise des bananes « tachetées » (plus mûres). À déguster au déjeuner ou à n'importe quel moment de la journée.",
      ingredients:
        "2 bananes mûres|250 g (2½ tasses) de céréales aux flocons de son|225 ml de lait écrémé|30 ml (2 c. à table) de compote de pommes|2 blancs d’œufs|5 ml (1 c. à thé) d’extrait de vanille|140 g (1 tasse) de farine|75 g à 113 g (1/3 à 1/2 tasse) de sucre|15 ml (1 c. à table) de poudre à pâte|5 ml (1 c. à thé) de cannelle|44 g (¼ tasse) de raisins secs, au goût",
      methods:
        "Préchauffer le four à 190°C (375°F).|Dans un grand bol, réduire les bananes en purée à l'aide d'une fourchette, incorporer le reste des ingrédients dans l'ordre indiqué et bien mélanger.|Vaporiser un moule pour 12 muffins avec un enduit à cuisson antiadhésif.|Répartir le mélange entre les 12 muffins.|Cuire au four pendant 20 à 30 minutes ou jusqu'à ce qu'un couteau inséré au centre d’un muffin en ressorte propre.|Laisser refroidir et déguster. Réfrigérer les restes.",
      time: 40,
      serves: 12,
      fat: 1,
      saturated: 0,
      calories: 141,
      cholesterol: 0,
      carbohydrate: 30,
      protein: 3,
      sodium: 203,
      fiber: 3,
      difficulty: "facile",
      category: "collations légères|petit déjeuner|mets préférés de la famille",
    },
    {
      title: "Aubergines au parmesan",
      slug: "aubergines-au-parmesan",
      img: "eggplant-parmesan",
      hasImage: true,
      description:
        "Un goût authentique sans excès de gras. La grande différence est que l’aubergine est panée puis cuite au four, et non frite.",
      ingredients:
        "1 grosse aubergine, pelée et tranchée|1 courgette, hachée|1 courge d’été, hachée|8 oz (1/2 lb) de champignons, tranchés|1 pot de 737 g (26 oz) de sauce tomate (choisir la teneur en matières grasses la plus faible)|113 g (4 oz) de mozzarella sans gras ou faible en gras, râpé*|60 ml (4 c. à table) de parmesan |2 blancs d’œufs|50 g (1 tasse) de chapelure",
      methods:
        "Préchauffer le four à 190°C (375°F).|Battre les blancs d’œufs dans un bol. Mettre la chapelure dans un autre bol. Tremper les tranches d'aubergines dans les blancs d’œufs battus et les transférer ensuite dans l’autre bol pour les recouvrir de chapelure.|Mettre les aubergines dans un plat de cuisson de 23 cm par 33 cm (9 po par 13 po), vaporisé avec un enduit à cuisson antiadhésif.|Cuire au four pendant 30 minutes. Retirer du four.|Verser la sauce sur les aubergines, ajouter les légumes et le fromage et poursuivre la cuisson pendant 20 à 25 minutes, jusqu’à ce que le tout bouillonne.",
      note:
        "* Analysis based on using non fat mozzarella. If low-fat cheese is used, fat will increase to 7g fat/serving.",
      time: 65,
      serves: 4,
      fat: 3,
      saturated: 2,
      calories: 279,
      cholesterol: 10,
      carbohydrate: 42,
      protein: 21,
      sodium: 479,
      difficulty: "moyen",
      category: "dîner|végétarien",
    },
    {
      title: "Gâterie croquante aux petites baies",
      slug: "gaterie-croquante-aux-petites-baies",
      img: "berry-crunch-treat",
      hasImage: true,
      description: "Parfait pour le déjeuner, le dîner ou comme collation!",
      ingredients:
        "100 g (1/2 tasse) de petites baies fraîches ou congelées|30 ml (2 c. à table) de confiture de fruits Simply (saveur au choix)|18 g (1/8 tasse) de granola|125 g (1/2 tasse) de yogourt grec nature sans gras",
      methods: "Mélanger le tout et déguster!",
      time: 5,
      serves: 1,
      fat: 0.7,
      saturated: 0.18,
      calories: 205,
      cholesterol: 6,
      carbohydrate: 37,
      protein: 13,
      sodium: 63,
      fiber: 5,
      difficulty: "facile",
      category: "rapides et faciles|petit déjeuner|collation",
    },
    {
      title: "Galettes de haricots à œil noir, préparées à la mode du Sud",
      slug: "galettes-de-haricots-a-oeil-noir",
      img: "black-eyed-pea-patties",
      hasImage: true,
      description:
        "Une alternative intéressante aux hamburgers végétariens traditionnels. Servir dans une pochette pita avec des tomates, des concombres et de la crème sure sans gras, du yogourt grec sans gras ou de la salsa.",
      ingredients:
        "93 g (2/3 tasse) d’avoine|375 g (2 ½ tasses) de haricots à œil noir, cuits|3 échalotes / oignons verts, tranchés|2 blancs d’œufs|5 ml (1 c. à thé) de cumin|7,5 ml (1 ½ c. à thé) de poudre de chili |0,62 ml (1/8 c. à thé) de poivre de Cayenne|1,25 ml (1/4 c. à thé) de sel|15 ml (1 c. à table) d’huile végétale",
      methods:
        "Réduire les flocons d'avoine en poudre à l'aide d'un robot culinaire. |Retirer 45 ml (3 c. à table) de poudre d’avoine et mettre de côté. |Ajouter tous les autres ingrédients, sauf l’huile, dans le robot culinaire et mélanger jusqu’à l’obtention d’une pâte épaisse (ajouter un peu d’eau, au besoin).| Retirer du robot et former 6 galettes. Rouler les galettes dans la poudre d’avoine réservée et faire revenir avec de l’huile dans un poêlon. Brunir de chaque côté et cuire complètement.",
      note:
        "Black eyed peas are available in convenient precooked frozen or fresh form, as well as canned. If you use canned beans, rinse and drain well.",
      serves: 6,
      fat: 4,
      saturated: 1,
      calories: 166,
      cholesterol: 0,
      carbohydrate: 27,
      protein: 7,
      sodium: 146,
      fiber: 6,
      difficulty: "facile",
      category: "végétarien|déjeuner|dîner",
    },
    {
      title: "Côtelettes de porc noircies servies avec du riz au cari et des légumes",
      slug: "cotelettes-de-porc-noircies",
      img: "blackened-pork-chops",
      hasImage: true,
      description:
        "Un mets qui a du piquant! Pour une version plus douce, utiliser l'assaisonnement créole. Dans les deux cas, vous obtiendrez un mets copieux et délicieux pour le souper ou un repas spécial pour le dîner.",
      ingredients:
        '454 g (1 lb) de côtelettes de porc désossées, coupées au centre, parées – environ 113 g (4 oz), pour chaque côtelette|200 g (1 tasse) de riz, non cuit|250 ml (1 tasse) de bouillon de poulet sans gras|225 ml (1 tasse) d’eau|4 échalotes / oignons verts; couper les tiges en morceaux de 1,3 cm (1/2 po), couper en dés la partie verte supérieure|1 sac de 340 g (12 oz) de pois et de carottes congelés|10 ml (2 c. à thé) d’huile de sésame|15 ml (1 c. à table) de poudre de cari|5 ml (1 c. à thé) de poudre d’ail|Assaisonnement noircissant (ou assaisonnement créole pour une côtelette moins épicée)|Enduit à cuisson',
      methods:
        "Ajouter l’huile de sésame, la poudre de cari et la poudre d’ail dans une casserole de 2,5 litres.|Chauffer à feu moyen-vif jusqu'à ce que l'huile commence à grésiller, puis ajouter les tiges d'oignon. Faire revenir pendant une minute, puis ajouter le riz et remuer jusqu’à ce que le tout soit bien enrobé.|Ajouter le bouillon de poulet et l’eau et réduire à feu doux pour laisser mijoter.|Couvrir, laisser cuire le riz jusqu'à ce que l'eau soit complètement absorbée.|Cuire les pois et les carottes congelés au four à micro-ondes, selon les indications de l'emballage, puis égoutter.|Ajouter les légumes et les pointes d'échalotes et d'oignons verts au riz, couvrir et laisser reposer le temps de préparer les côtelettes de porc.|Ajouter l'assaisonnement noircissant sur un côté des côtelettes de porc. Vaporiser une poêle à frire avec un enduit à cuisson antiadhésif, puis régler la température à feu moyen.|Ajouter les côtelettes de porc dans la poêle, côté assaisonné vers le bas, puis assaisonner l’autre côté. Cuire chaque côté de 3 à 4 minutes ou jusqu’à ce que ce la viande soit cuite.|Déposer en boule le mélange de riz et de légumes dans une assiette, garnir avec une côtelette de porc et déguster!",
      serves: 4,
      fat: 7.2,
      saturated: 2,
      calories: 386,
      cholesterol: 59,
      carbohydrate: 46,
      protein: 35,
      sodium: 457,
      fiber: 4,
      difficulty: "moyen",
      category: "dîner|hearty meal|pork",
    },
    {
      title: "Poulet (ou porc) à la dijonnaise",
      slug: "poulet-a-la-dijonnaise",
      img: "chicken-or-pork-dijon",
      hasImage: true,
      description:
        "La sauce peut être utilisée à l'avance comme marinade ou pour napper des poitrines de poulet désossées sans peau ou des filets de porc.",
      ingredients:
        "454 g (1 lb) de poitrines de poulet désossées, sans peau|175 g (1/2 tasse) de moutarde de Dijon|175 g (1/2 tasse) de sirop d’érable|10 ml (2 c. à thé) de vinaigre de vin rouge|mélange de romarin et d’ail, au goût|brins de romarin frais (facultatif)",
      methods:
        "Préchauffer le four à 190°C (375°F).|Dans un petit bol, mélanger la moutarde, le sirop et le vinaigre.|Verser sur les poitrines de poulet disposées dans un plat de cuisson antiadhésif recouvert de papier aluminium. Réserver un peu de sauce pour badigeonner.|Saupoudrer le mélange de romarin et d’ail (au goût) et mettre quelques brins de romarin frais entre les poitrines de poulet.|Cuire au four de 30 à 35 minutes.",
      time: 45,
      serves: 4,
      fat: 4,
      saturated: 1,
      calories: 172,
      cholesterol: 75,
      carbohydrate: 6,
      protein: 28,
      sodium: 381,
      difficulty: "facile",
      category: "dîner|rapides et faciles",
    },
    {
      title: "Poitrines de poulet avec une savoureuse sauce aux pêches",
      slug: "poitrines-de-poulet-avec-une-savoureuse-sauce-aux-pêches",
      img: "chicken-breasts-with-savory-peach-glaze",
      hasImage: true,
      description:
        "Cette recette savoureuse et facile à préparer est idéale pour rehausser de banales poitrines de poulet! La délicieuse sauce peut également être utilisée pour napper un filet de porc.",
      ingredients:
        "350 g (1 tasse) de confiture de pêches |15 ml (1 c. à table) de sauce soya|15 ml (1 c. à table) de moutarde de Dijon|1 petite gousse d’ail, émincée|4 oignons verts, hachés|1/3 de poivron rouge doux, coupé en dés|0,60 ml (1/8 c. à thé) de flocons de piment rouge (facultatif)|6 demi-poitrines de poulet désossées, sans peau",
      methods:
        "Préchauffer le four à 176°C (350°F).|Mélanger tous les ingrédients, sauf le poulet, dans un bol de grosseur moyenne.|Placer les poitrines de poulet dans un plat de cuisson et les recouvrir du mélange.|Cuire au four pendant 30 minutes ou jusqu’à ce que la température interne du poulet atteigne 73°C (165°F)",
      time: 40,
      serves: 6,
      fat: 4.5,
      saturated: 1,
      calories: 299,
      cholesterol: 124,
      carbohydrate: 21,
      protein: 39,
      sodium: 304,
      difficulty: "facile",
      category: "dîner|rapides et faciles",
    },
    {
      title: "Salade César au poulet",
      slug: "salade-cesar-au-poulet",
      img: "chicken-caesar-salad",
      hasImage: true,
      description:
        "Une version saine d'un plat favori à forte teneur en matières grasses. Ajouter des tomates cerises d'un rouge vif pour une délicieuse variante de la recette.",
      ingredients:
        "600 g (8 tasses) de laitue romaine prélavée et déchiquetée|454 g (1 lb) de poitrines de poulet désossées, sans peau, précuites et tranchées|115 g (1 tasse) de croûtons sans gras|28 g (1 oz) de fromage faible en gras, râpé (ou parmesan sans gras)|175 g (1/2 tasse) de vinaigrette César crémeuse sans gras|2,5 ml (1/2 c. à thé) de poivre noir fraîchement moulu|1 citron frais",
      methods:
        "Mettre la laitue et les croûtons dans un grand bol, ajouter la vinaigrette et remuer pour bien enrober le tout.|Presser le citron pour incorporer son jus au mélange de laitue, puis ajouter le fromage et mélanger à nouveau.|Répartir le mélange dans 4 assiettes de service.|Répartir le poulet cuit, coupé en fines tranches ou en dés, également sur chaque portion.",
      time: 10,
      serves: 4,
      fat: 4,
      saturated: 2,
      calories: 214,
      cholesterol: 92,
      carbohydrate: 22,
      protein: 19,
      sodium: 484,
      difficulty: "facile",
      category: "déjeuner|rapides et faciles|dîner",
    },
    {
      title: "Poulet piquant",
      slug: "poulet-piquant",
      img: "chicken-picante",
      hasImage: true,
      description:
        "Cette marinade facile à préparer vous permettra de transformer les recettes de poulet les plus banales.",
      ingredients:
        "4 poitrines de poulet désossées, sans peau|175 ml (3/4 tasse) de salsa|30 ml (2 c. à table) de moutarde de Dijon (ou plus si vous aimez les mets épicés!)|jus de 2 limes fraîches",
      methods:
        "Préchauffer le four à 176°C (350°F).|Mettre le poulet dans un plat de cuisson préalablement vaporisé avec un enduit à cuisson antiadhésif ou dans une casserole recouverte de papier d'aluminium antiadhésif.|Dans un petit bol, mélanger la salsa, la moutarde et le jus de lime et verser sur le poulet.|Mettre au réfrigérateur pendant au moins 1 heure.|Couvrir et cuire au four durant 35 à 40 minutes.|Servir avec du riz brun (il y aura beaucoup de sauce pour aromatiser le riz).",
      time: 110,
      serves: 4,
      fat: 4,
      saturated: 1,
      calories: 172,
      cholesterol: 85,
      carbohydrate: 3,
      protein: 31,
      sodium: 420,
      difficulty: "facile",
      category: "déjeuner|dîner|mets préférés de la famille",
    },
    {
      title: "Barres de biscuits aux pépites de chocolat",
      slug: "barres-de-biscuits-aux-pepites-de-chocolat",
      img: "chocolate-chip-cookie-bars",
      hasImage: true,
      description:
        "Ces barres ressemblent à des brownies épais et légèrement dorés que toute la famille peut apprécier en guise de gâterie au retour de l'école.",
      ingredients:
        "56 g (1/4 tasse) de beurre non salé|147 g (1/2 tasse) de compote de pommes non sucrée, à la température ambiante|340 g (2 tasses) de cassonade tassée|385 g (2 ¾ tasses) de farine tout usage|15 ml (1 c. à table) de poudre à pâte|2,5 ml (1/2 c. à thé) de sel|3 œufs|5 ml (1 c. à thé) d’extrait de vanille|38 g (1/4 tasse) et 30 g (2 c. à table) de mini-pépites de chocolat mi-sucré|Enduit de cuisson antiadhésif et un peu plus de farine pour préparer le moule",
      methods:
        "Faire fondre le beurre, puis ajouter la compote de pommes et mettre de côté pour laisser refroidir.|Vaporiser un moule à cuisson de 23 cm par 33 cm (9 po par 13 po) avec un enduit à cuisson antiadhésif et le saupoudrer de farine.|Dans un autre bol, mélanger la farine, la poudre à pâte et le sel.|Une fois que le mélange de beurre est froid, ajouter les œufs, un à la fois, en remuant le mélange après chacun.|Ajouter la vanille.|Ajouter les ingrédients secs et bien mélanger pour inclure tout le mélange de farine.|Incorporer les pépites de chocolat miniatures.|Cuire au four durant 30 à 40 minutes, à 162°C (325°F).|Une fois le mélange refroidi, couper en 24 barres.",
      time: 60,
      serves: 24,
      fat: 3.5,
      saturated: 1.9,
      calories: 164,
      cholesterol: 28,
      carbohydrate: 32,
      protein: 2,
      sodium: 124,
      difficulty: "facile",
      category: "dessert|mets préférés de la famille|collations légères",
    },
    {
      title: "Frittata aux légumes hachés",
      slug: "frittata-aux-legumes-haches",
      img: "chopped-vegetable-frittata",
      hasImage: true,
      description: "Idéal pour un déjeuner tardif et tranquille, un brunch ou même pour le souper.",
      ingredients:
        "226 g (8 oz) de substitut d’œuf en boîte / 8 blancs d’œufs, légèrement battus|2 échalotes / oignons verts, émincés|1 tomate, hachée|1/2 poivron rouge, haché|1 poivron vert, haché|175g à 350g (1 à 2 tasses) de champignons, tranchés|175 g (1 tasse) de pommes de terre rouges, cuites et tranchées|1/2 concombre, pelé et tranché|1 boite de 113 g (4 oz) de piments verts |2,5 ml (1/2 c. à thé) de poudre d’ail|1,25 ml (1/4 c. à thé) de poivre blanc|sel au goût, si désiré",
      methods:
        "Vaporiser un plat à sauter avec un enduit à cuisson antiadhésif.|Faire revenir tous les légumes jusqu'à ce qu'ils soient tendres et croquants.|Verser le substitut d’œufs / les blancs d’œufs sur les légumes, couvrir et cuire à feu doux ou moyen jusqu’à ce que l’œuf soit entièrement cuit et gonflé.",
      time: 20,
      serves: 2,
      fat: 4,
      saturated: 0,
      calories: 224,
      cholesterol: 0,
      carbohydrate: 30,
      protein: 18,
      sodium: 408,
      difficulty: "facile",
      category: "petit déjeuner|collations légères|rapides et faciles",
    },
    {
      title: "Gruau à la cannelle cuit au four",
      slug: "gruau-a-la-cannelle-cuit-au-four",
      img: "cinnamon-baked-oatmeal",
      hasImage: true,
      description:
        "Cette recette est idéale pour un déjeuner convivial durant la fin de semaine. Réfrigérer les restes et les réchauffer au four à micro-ondes pour un déjeuner rapide pendant la semaine. Congeler des portions individuelles pour les décongeler et les utiliser plus tard.",
      ingredients:
        "420 g (3 tasses) de flocons d'avoine secs (à préparation rapide ou régulière)|30 ml à 60 ml (2 à 4 c. à table) de cassonade|10 ml (2 c. à thé) de cannelle moulue|1,25 ml (1/4 c. à thé ) de sel|338 ml (1 ½ tasse) de lait écrémé|1 œuf entier|125 g (1/2 tasse) de yogourt nature sans gras|5 ml (1 c. à thé) d’extrait de vanille|350 g (2 tasses) de petites baies mélangées, fraîches ou congelées",
      methods:
        "Préchauffer le four à 176°C (350°F).| Vaporiser un plat de cuisson en verre de 20 cm par 20 cm (8 po par 8 po) avec un enduit à cuisson antiadhésif.| Dans un bol de grosseur moyenne, ajouter et bien mélanger tous les ingrédients, sauf les fruits.| Verser les 2/3 de la pâte dans le plat de cuisson. Ensuite, étendre les fruits sur la pâte et recouvrir le tout avec le dernier tiers de la pâte.| Cuire au four pendant 30 minutes.| Laisser refroidir, couper en 6 morceaux (ou en 9 morceaux pour de plus petites portions) et servir.",
      time: 40,
      serves: 6,
      fat: 3.9,
      saturated: 0.8,
      calories: 224,
      cholesterol: 33,
      carbohydrate: 42,
      protein: 11,
      sodium: 153,
      difficulty: "facile",
      category: "petit déjeuner|mets préférés de la famille|collations légères|rapides et faciles",
    },
    {
      title: "Poitrine de dinde aux champignons, xérès et basilic",
      slug: "poitrine-de-dinde-aux-champignons",
      img: "company-turkey-breast",
      hasImage: true,
      description:
        "Essayez cette garniture à base de pain pour obtenir une poitrine ou un filet de dinde juteux et savoureux.",
      ingredients:
        "454 à 907 g (1 à 2 lb) de poitrine de dinde désossée, sans la peau|263 g (1 ½ tasse) de champignons, émincés|3 échalotes / oignons verts, émincés|1 tranche de pain de blé entier (émiettée)|75 ml (1/3 tasse) de xérès|5 ml (1 c. à thé) de basilic séché",
      methods:
        "Préchauffer le four à 162°C (325°F).|Vaporiser un enduit de cuisson antiadhésif sur une sauteuse de grosseur moyenne, faire revenir les champignons, les oignons, le xérès et le basilic pendant 3 à 4 minutes.|Incorporer la chapelure et retirer du feu.|Mettre la poitrine de dinde sur une grille dans un plat de 23 cm par 33 cm (9 po par 13 po)|Répartir le mélange de farce sur la dinde.|Recouvrir de papier d'aluminium et cuire au four pendant 1 heure ou 2 selon le poids; moins longtemps pour les morceaux individuels de filets de dinde).|Laisser à découvert pendant les 30 dernières minutes de cuisson.|Laisser reposer pendant 10 minutes avant de trancher la dinde.",
      time: 150,
      serves: 4,
      fat: 2,
      saturated: 0.5,
      calories: 162,
      cholesterol: 86,
      carbohydrate: 6,
      protein: 30,
      sodium: 118,
      difficulty: "moyen",
      category: "dîner|mets préférés de la famille",
    },
    {
      title: "Salade de chou Confetti",
      slug: "salade-de-chou-confetti",
      img: "confetti-coleslaw",
      hasImage: true,
      ingredients:
        "1 sac de 453 g (16 oz) de chou vert, de carottes et de chou rouge râpés|75 g (1/2 tasse) de pois congelés, décongelés|75 g (1/2 tasse) de raisins secs dorés ou noirs|30 ml (2 c. à table) de mayonnaise légère (3,5 g de gras par c. à table)|30 ml (2 c. à table) de mayonnaise sans gras ou de yogourt grec sans gras ou de crème sure sans gras |30 ml (2 c. à table) de vinaigre de vin rouge|7,5 ml (1/2 c. à table) de graines de céleri|1,25 ml (1/4 c. à thé) de poudre d’ail|5 sachets d’édulcorant artificiel ou 45 ml (3 c. à table) de sucre|le jus d'un citron fraîchement pressé|sel et poivre, au goût",
      methods:
        "Mettre le mélange de choux et carottes dans un grand bol.|Ajouter le reste des ingrédients et remuer pour bien mélanger.|Réfrigérer pour favoriser le mélange des saveurs, puis servir.",
      note: "Pour réduire davantage les matières grasses, utilisez de la mayonnaise sans matières grasses.",
      time: 15,
      serves: 5,
      fat: 1.4,
      saturated: 0,
      calories: 80,
      cholesterol: 1,
      carbohydrate: 14,
      protein: 2,
      sodium: 79,
      difficulty: "facile",
      category: "déjeuner|mets préférés de la famille|rapides et faciles",
    },
    {
      title: "Salade de maïs et de haricots noirs",
      slug: "salade-de-mais-et-de-haricots-noirs",
      img: "corn-and-black-bean-salad",
      hasImage: true,
      description:
        "Cette salade est facile à préparer avec des ingrédients que vous avez peut-être déjà dans votre cuisine.",
      ingredients:
        "Une boite de 453 g (16 oz) de haricots noirs à faible teneur en sodium, rincés et égouttés|175 g (1 tasse) de tomates italiennes fraîches, coupées en morceaux|75 g (1/2 tasse) de maïs congelé, décongelé|75 g (1/2 tasse) de poivron vert, coupé en morceaux|30 ml (2 c. à table) d’oignon rouge, émincé|88 g (1/4 tasse) de salsa (épicée au goût)|le jus d’une lime",
      methods:
        "Mélanger tous les ingrédients. Réfrigérer et servir comme plat principal ou comme salade d’accompagnement.",
      time: 10,
      serves: 2,
      fat: 0,
      saturated: 0,
      calories: 270,
      cholesterol: 0,
      carbohydrate: 52,
      protein: 16,
      sodium: 382,
      difficulty: "facile",
      category: "déjeuner|mets préférés de la famille|rapides et faciles|végétarien|fat free",
    },
    {
      title: "Sauce campagnarde",
      slug: "sauce-campagnarde",
      img: "country-gravy",
      hasImage: true,
      description:
        "Cette recette a le même goût que la version originale et ne contient aucune matière grasse. Rapide et facile à préparer. Essayez-la sur des petits pains faibles en gras, de la purée de pommes de terre ou du poulet cuit au four.",
      ingredients:
        "450 ml (2 tasses) de lait écrémé|35 g (1/4 tasse) de farine tout usage|5 ml (1 c. à thé) de sel assaisonné|2,5 ml (1/2 c. à thé) de poivre (ou au goût)",
      methods:
        "Verser tous les ingrédients dans un mélangeur ou dans un contenant avec un couvercle et remuer jusqu’à ce que le tout soit bien mélangé|Chauffer une poêle à frire à feu moyen.Une fois la poêle chaude, ajouter le mélange de sauce et remuer lentement jusqu’à ce que celui-ci épaississe et atteigne la consistance désirée.",
      time: 15,
      serves: 4,
      fat: 0,
      saturated: 0,
      calories: 45,
      cholesterol: 3,
      carbohydrate: 7,
      protein: 4,
      sodium: 63,
      difficulty: "facile",
      category: "mets préférés de la famille|rapides et faciles|fat free",
    },
    {
      title: "Pain aux canneberges et à la citrouille",
      slug: "pain-aux-canneberges-et-a-la-citrouille",
      img: "cranberry-pumpkin-bread",
      hasImage: true,
      ingredients:
        "430 g (3 ½ tasses) de farine|250 g (1 ¼ tasse) de sucre|11,25 ml (2 ¼ c. à thé) de bicarbonate de soude|6,25 ml (1 ¼ c. à thé) de poudre à pâte|5 ml (1 c. à thé) de cannelle|1,25 ml (¼ c. à thé) de piment de la Jamaïque|1,25 ml (¼ c. à thé) de clous de girofle, moulus|1,25 ml (1/4 c. à thé) de gingembre|1 boîte de 453 g (16 oz) de sauce aux canneberges entières|1 boîte de 453 g (16 oz) de citrouille|60 ml (1/4 tasse) d’huile de canola|147 g (1/2 tasse) de compote de pommes non sucrée|2 blancs d’œufs (gros œufs)|15 ml (1 c. à table) de pacanes, hachées|Enduit à cuisson antiadhésif",
      methods:
        "Préchauffer le four à 176°C (350°F).|Mettre tous les ingrédients dans un grand bol. Bien mélanger.|Vaporiser un enduit à cuisson antiadhésif sur deux moules à pain de 23 cm par 13 cm par 8 cm (9 po par 5 po par 3 po). |Verser le mélange dans les moules. Cuire au four durant 1 heure ou jusqu'à ce qu’un cure-dent en bois inséré au centre du pain en ressorte propre.",
      time: 80,
      serves: 16,
      fat: 2.0,
      saturated: 0.2,
      calories: 133,
      cholesterol: 0,
      carbohydrate: 26,
      protein: 2,
      sodium: 115,
      fiber: 1,
      difficulty: "moyen",
      category: "mets préférés de la famille|dessert",
    },
    {
      title: "Soupe à la crème de pommes de terre",
      slug: "soupe-a-la-creme-de-pommes-de-terre",
      img: "cream-of-potato-soup",
      hasImage: true,
      ingredients:
        "175 g (1 tasse) de pommes de terre, tranchées|338 ml (1 ½ tasse) de bouillon de poulet faible en sodium, sans gras|88 g (½ tasse) d’oignon haché|5 ml (1 c. à thé) d’huile d’olive|30 g (2 c. à table) de farine tout usage blanche|1,25 ml (¼ c. à thé ) de sel|Une pincée de poivre blanc (facultatif)|225 ml (1 tasse) de lait écrémé",
      methods:
        "Dans une casserole, mélanger les pommes de terre, le bouillon de poulet, l’oignon et l’aneth. Porter le mélange à ébullition. Baisser le feu, couvrir et laisser mijoter pendant 10 minutes. |Mettre la moitié du mélange de légumes dans un mélangeur ou un robot culinaire. Mélanger pendant 30 à 60 secondes jusqu’à l’obtention d’une texture onctueuse.| Verser dans un bol. Pour obtenir une texture très onctueuse, mettre le reste de la préparation de légumes dans un mélangeur. Pour obtenir une texture plus consistante, ne pas mélanger la seconde moitié. Mettre de côté.|Dans la même casserole, à feu moyen, chauffer doucement l’huile d’olive.| Incorporer la farine, le sel et le poivre (si désiré) et fouetter le mélange. |Cuire pendant 1 minute. Ajouter le lait. Cuire et remuer avec le fouet jusqu’à ce que le mélange épaississe et bouillonne.| Incorporer le mélange de légumes et de bouillon. Cuire et remuer jusqu’à ce que la soupe soit bien chaude.| Assaisonner au goût en ajoutant du sel et du poivre.",
      time: 30,
      serves: 3,
      fat: 2.4,
      saturated: 1,
      calories: 135,
      cholesterol: 2,
      carbohydrate: 20,
      protein: 7,
      sodium: 268,
      fiber: 2,
      difficulty: "moyen",
      category: "mets préférés de la famille|déjeuner|dîner|végétarien",
    },
    {
      title: "Soupe crémeuse à la courge musquée",
      slug: "soupe-cremeuse-a-la-courge-musquee",
      img: "creamy-butternut-squash-soup",
      hasImage: true,
      description:
        "Cette soupe peut être préparée comme un potage crémeux et sucré ou ajoutez un peu de poudre de cari pour lui donner un goût épicé. Servez avec une généreuse salade et du pain croûté.",
      ingredients:
        "1 oignon, haché|2 carottes, coupées en tranches ou en dés|2 branches de céleri, tranchées|Un contenant de 340 g (12 oz) de courge musquée en morceaux « prête à l’emploi » (3 tasses, en cubes)|1 pomme de terre, coupée en dés|2 gousses d’ail, émincées|1,25 l (5 tasses) de bouillon de poulet sans gras, à teneur réduite en sodium|15 ml à 30 ml (1 à 2 c. à table) de sucre ou de miel (ou d’édulcorant artificiel)|Poivre, au goût|10 ml à 15 ml (2 à 3 c. à thé) de poudre de cari, si désiré",
      methods:
        "Vaporiser un enduit à cuisson antiadhésif sur un grand plat à sauter ou une grande casserole et faire revenir les oignons, les carottes, le céleri et l’ail jusqu’à ce qu’ils soient légèrement croustillants.|Incorporer le reste des ingrédients, sauf la poudre de cari, et porter à ébullition. Baisser le feu et laisser mijoter pendant 30 minutes, jusqu’à ce que les légumes soient très tendres.|MoveTransférer la préparation dans un robot culinaire et mélanger jusqu’à l’obtention d’une texture lisse.|Si tous les convives aiment le cari, ajouter quelques cuillères à thé de poudre de cari. Sinon, ajouter 2,5 ml (½ c. à thé) ou plus dans le bol des convives qui désirent du cari au moment de servir.",
      time: 40,
      serves: 4,
      fat: 0,
      saturated: 0,
      calories: 104,
      cholesterol: 0,
      carbohydrate: 23,
      protein: 3,
      sodium: 600,
      difficulty: "moyen",
      category: "mets préférés de la famille|déjeuner|dîner|végétarien|fat free",
    },
    {
      title: "Rôti de bœuf et légumes à la mijoteuse",
      slug: "roti-de-bœuf-et-legumes-a-la-mijoteuse",
      img: "crock-pot-beef-roast-and-veggies",
      hasImage: true,
      description:
        "Rapide et facile à préparer, ce délicieux plat sera apprécié quelle que soit la saison.",
      ingredients:
        "454 g (1 lb) de ronde de bœuf, maigre seulement|150 g (1 tasse) de haricots verts|125 g (2 ½ tasses) de carottes, hachées|113 ml (½ tasse) de bouillon de bœuf|200 g (1 tasse) de riz blanc|45 ml (3 c. à table) de vinaigre balsamique|30 ml (2 c. à table) de poudre d'assaisonnement mesquite",
      methods:
        "Mélanger tous les ingrédients, sauf le riz, dans une mijoteuse.|Cuire à feu doux pendant 8 à 10 heures, jusqu’à ce que le bœuf se défasse facilement à la fourchette.|Cuire le riz lorsque la cuisson de la mijoteuse est presque terminée, selon les indications de la recette.|Servir les aliments cuits à la mijoteuse sur un lit de riz.",
      serves: 4,
      fat: 8,
      saturated: 5,
      calories: 435,
      cholesterol: 103,
      carbohydrate: 52,
      protein: 39,
      sodium: 660,
      difficulty: "facile",
      category: "mets préférés de la famille|dîner|hearty meal",
    },
    {
      title: "Poulet des Antilles à la mijoteuse",
      slug: "poulet-des-antilles-a-la-mijoteuse",
      img: "crock-pot-caribbean-chicken",
      hasImage: true,
      description: "Un plat tropical facile à préparer, au goût sucré et délicat.",
      ingredients:
        "454 g (1 lb) de poitrines de poulet désossées, sans peau|43 g (1/4 tasse) de cassonade tassée|2,5 ml (1/2 c. à thé) de muscade|75 ml (1/3 tasse) de jus d’orange|88 g (1/2 tasse) de raisins secs|226 g (8 oz) de morceaux d’ananas en conserve avec le jus|2,5 ml (1/2 c. à thé) de cannelle",
      methods:
        "Mettre tous les ingrédients dans la mijoteuse.|Cuire à feu doux de 4 à 6 heures.|Servir sur du riz cuit à la vapeur, si désiré.",
      serves: 4,
      fat: 4,
      saturated: 0,
      calories: 399,
      cholesterol: 80,
      carbohydrate: 68,
      protein: 26,
      sodium: 65,
      difficulty: "facile",
      category: "mets préférés de la famille|dîner|hearty meal",
    },
    {
      title: "Soupe au poulet mexicaine à la mijoteuse",
      slug: "soupe-au-poulet-mexicaine-a-la-mijoteuse",
      img: "crock-pot-mexican-chicken-soup",
      hasImage: true,
      description: "Un plat savoureux et bien épicé.",
      ingredients:
        "30 ml (2 c. à table) de jus de lime|175 g (3 ½ tasses) de carottes, hachées|5 ml (1 c. à thé) de poudre de chili|15 ml (1 c. à table) d’ail émincé|900 ml (4 tasses) de bouillon de poulet sans gras et à teneur réduite en sodium|225 ml (1 tasse) de jus de tomate|5 ml (1 c. à thé) de graines de fenouil|5 ml (1 c. à thé) de cumin moulu|454 g (1 lb) de poitrines de poulet désossées, sans peau|1 gros oignon jaune, haché",
      methods:
        "Mettre tous les ingrédients dans une mijoteuse et cuire pendant 6 à 8 heures à feu doux ou jusqu’à ce que le poulet soit entièrement cuit.|Défaire le poulet et servir.",
      serves: 4,
      fat: 4,
      saturated: 0,
      calories: 229,
      cholesterol: 66,
      carbohydrate: 17,
      protein: 29,
      sodium: 369,
      difficulty: "facile",
      category: "mets préférés de la famille|dîner|hearty meal",
    },
    {
      title: "Poulet au miel et à l'ail avec des légumes à la mijoteuse",
      slug: "poulet-au-miel-et-a-lail",
      img: "crock-pot-honey-garlic-chicken",
      hasImage: true,
      description: "Une nouvelle mouture du poulet traditionnel préparé à la mijoteuse.",
      ingredients:
        "908 g (2 lb) de poitrines de poulet|454 g (16 oz) de petites pommes de terre rouges|454 g (16 oz) de carottes miniatures|30 ml (2 c. à table) de persil haché|175 g (1/2 tasse) de sauce soya à teneur réduite en sodium|350 g (1 tasse) de miel|60 ml (1/4 tasse) de ketchup |2 gousses d’ail, émincées|5 ml (1 c. à thé) de basilic séché|2,5 ml (1/2 c. à thé) d’origan séché|1,25 ml (¼ c. à thé) de flocons de piments rouges broyés|1,25 ml (1/4 c. à thé) de poivre noir moulu|113 ml (1/2 tasse) d’eau",
      methods:
        "Dans un grand bol, mélanger la sauce soya, le miel, le ketchup, l’ail, le basilic, l’origan, les flocons de piments rouges et le poivre.|Mettre le poulet, les pommes de terre et les carottes dans une mijoteuse.|Verser le mélange de sauce sur le poulet, les pommes de terre et les carottes.|Ajouter l’eau dans la mijoteuse.|Couvrir et cuire à feu doux pendant 7 h 30 min ou à feu vif pendant 3 h 30 min.",
      serves: 4,
      fat: 6.6,
      saturated: 1.4,
      calories: 721,
      cholesterol: 166,
      carbohydrate: 110,
      protein: 59,
      sodium: 1194,
      fiber: 6,
      difficulty: "facile",
      category: "mets préférés de la famille|dîner|hearty meal",
    },
    {
      title: "Pouding à la banane",
      slug: "pouding-a-la-banane",
      img: "banana-pudding",
      hasImage: true,
      description:
        "Un dessert facile à préparer pour votre famille ou idéal pour une rencontre entre amis",
      ingredients:
        "2 grosses bananes mûres tranchées|2 sachets de pouding instantané à la vanille, sans sucre et sans gras (boîte de 28 g)|710 ml (3 tasses) de lait écrémé|113 g (4 oz) de garniture fouettée sans gras|113 g (1/2 tasse) de crème sure sans gras ou de yogourt grec sans gras|50 g (1/4 tasse) de sucre granulé (ou d’édulcorant)|30 gaufrettes à la vanille à teneur réduite en gras",
      methods:
        "Verser le contenu des deux sachets de pouding en poudre dans un bol, puis mélanger avec 710 ml de lait froid. Mettre au réfrigérateur et laisser refroidir jusqu’à ce que le mélange ait une consistance plus épaisse.|Dans un autre bol, mélanger la garniture fouettée, la crème sure sans gras ou le yogourt grec sans gras et l’édulcorant.|Dans une cocotte ou un bol transparent, superposer les ingrédients : commencer avec 10 gaufrettes, des bananes tranchées, du pouding, puis répéter. Ensuite, recouvrir de garniture du mélange de crème fouettée.",
      serves: 10,
      fat: 1.5,
      saturated: 0,
      calories: 128,
      cholesterol: 3,
      carbohydrate: 26,
      protein: 4,
      sodium: 168,
      fiber: 1,
      difficulty: "facile",
      category: "collations légères|dessert|mets préférés de la famille|rapides et faciles",
    },
    {
      title: "Patates douces confites faciles à préparer",
      slug: "patates-douces-confites-faciles-a-preparer",
      img: "easy-candied-sweet-potatoes",
      hasImage: true,
      description:
        "Voici un plat saisonnier, facile à préparer, qui peut être dégusté à n'importe quelle période de l'année!",
      ingredients:
        "680 g (1 ½ lb) de patates douces ou d’ignames, pelées et tranchées finement|30 ml à 45 ml (2 à 3 c. à table) de confiture d'orange, d'abricot ou de pomme|125 ml à 175 ml (1/2 à 3/4 tasse) de jus d’orange|Mini guimauves (ingrédient facultatif / non compris dans l’analyse)",
      methods:
        "Préchauffer le four à 176°C (350°F).|Dans un petit bol, mélanger la confiture et le jus d’orange.|Mettre les patates douces tranchées dans une cocotte.|Verser le mélange de jus sur les pommes de terre et recouvrir de papier d’aluminium ou mettre le couvercle.|Cuire au four pendant 45 minutes, en remuant à la mi-cuisson.|Garnir de mini guimauves pendant les 10 dernières minutes de cuisson, si désiré.|Vous pouvez également cuire cette recette au four à micro-ondes, dans un plat conçu pour ce type de four, pendant 20 à 25 minutes, en remuant occasionnellement jusqu’à ce que les pommes de terre soient tendres.",
      time: 55,
      serves: 4,
      fat: 0.2,
      saturated: 0,
      calories: 184,
      cholesterol: 0,
      carbohydrate: 44,
      protein: 3,
      sodium: 98,
      fiber: 5,
      difficulty: "facile",
      category: "mets préférés de la famille|dîner",
    },
    {
      title: "Haricots frits à la mijoteuse",
      slug: "haricots-frits-a-la-mijoteuse",
      img: "easy-crock-pot-refried-beans",
      hasImage: true,
      description: "Cette recette est si facile que vous pouvez la préparer pendant que vous dormez!",
      ingredients:
        "1 oignon, pelé et coupé en deux|300 g (2 tasses) de haricots pinto secs, rincés|2 gousses d’ail, émincées|3,75 ml (3/4 c. à thé) de sel|2,5 ml (1/2 c. à thé) de poivre noir|0,62 ml (1/8 c. à thé) de cumin|1,5 l (6 tasses) d’eau",
      methods:
        "Mélanger tous les ingrédients dans la mijoteuse.|Cuire à feu vif pendant 8 heures ou cuire la nuit pendant que vous dormez.|Retirer les gros morceaux d’oignon et égoutter l’excès de liquide. Conserver un peu de liquide pour le rajouter aux haricots afin qu’ils aient une consistance idéale (vous ne les voulez pas trop secs).|Écraser les haricots à l'aide d'un pilon à purée et le tour est joué!",
      serves: 10,
      fat: 0.5,
      saturated: 0,
      calories: 134,
      cholesterol: 0,
      carbohydrate: 25,
      protein: 8,
      sodium: 184,
      difficulty: "facile",
      category: "mets préférés de la famille|dîner|hearty meal",
    },
    {
      title: "Gâteau au cacao, sans œuf, facile à préparer",
      slug: "gateau-au-cacao-sans-œuf",
      img: "easy-eggless-cocoa-cake",
      hasImage: true,
      ingredients:
        "210 g (1 ½ tasse) de farine |200 g (1 tasse) de sucre| 31 g (1/4 tasse) de cacao| 5 ml (1 c. à thé) de poudre à pâte | 1,25 ml (1/4 c. à thé ) de sel | 225 ml (1 tasse) d’eau | 30 ml (2 c. à table) d’huile |30 ml (2 c. à table) de yogourt nature sans gras | 15 ml (1 c. à table) de vinaigre  | 5 ml (1 c. à thé) d’extrait de vanille",
      methods:
        "Préchauffer le four à 176°C (350°F).|Mélanger les ingrédients secs. Ajouter les ingrédients liquides et remuer jusqu’à l’obtention d’une consistance lisse.| Verser dans un moule de 20 cm par 20 cm (8 par 8 po) vaporisé avec un enduit antiadhésif pour la cuisson au four. |Cuire au four pendant 30 à 35 minutes ou jusqu’à ce qu’un couteau inséré au centre du gâteau en ressorte propre. Laisser refroidir.| Saupoudrer de sucre en poudre et garnir de grains de chocolat.",
      time: 45,
      serves: 9,
      fat: 3.6,
      saturated: 0.6,
      calories: 199,
      cholesterol: 0,
      carbohydrate: 40,
      protein: 3,
      sodium: 122,
      difficulty: "facile",
      category: "mets préférés de la famille|dessert",
    },
    {
      title: "Gâteau au fromage sans gras facile à préparer",
      slug: "gateau-au-fromage-sans-gras",
      img: "easy-fat-free-cheesecake",
      hasImage: true,
      description:
        "Un gâteau crémeux aussi savoureux que la version originale. Vous pouvez réduire le sucre et les glucides en substituant un édulcorant non calorique.",
      ingredients:
        "453 g (16 oz) de fromage à la crème sans gras, à la température ambiante|113 g (1/2 tasse) de sucre granulé ou 113 g (1/2 tasse) d’édulcorant)|190 g (3/4 tasse) de yogourt grec nature sans gras, à la température ambiante|5 ml (1 c. à thé) de vanille|4 blancs d’œufs ou 1/2 tasse de blancs d’œufs liquides|30 ml (2 c. à table) de jus de citron|30 ml (2 c. à table) de farine tout usage|Nappage facultatif : garniture légère pour tarte aux cerises ou petits fruits frais",
      methods:
        "Préchauffer le four à 176°C (350°F).|Vaporiser un moule à fond amovible de 9 po (23 cm) avec un enduit à cuisson antiadhésif.|Dans un grand bol, mélanger le fromage à la crème, le sucre (ou l’édulcorant sans calories) et la vanille et fouetter le tout à l'aide d'un batteur électrique jusqu'à l'obtention d'une texture lisse.|Ajouter le yogourt, les blancs d’œufs, le jus de citron et la farine et continuer à mélanger.|Verser dans le moule à fond amovible préparé pour la cuisson.|Cuire au four pendant 30 à 40 minutes, jusqu'à ce que le mélange soit ferme et qu’il reprenne sa forme initiale au toucher.|Laisser refroidir complètement.|Si désiré, ajouter une garniture supplémentaire.",
      time: 50,
      serves: 8,
      fat: 1,
      saturated: 19,
      calories: 142,
      cholesterol: 6,
      carbohydrate: 19,
      protein: 14,
      sodium: 418,
      fiber: 1,
      difficulty: "facile",
      category: "mets préférés de la famille|dessert",
    },
    {
      title: "Crème glacée facile à préparer",
      slug: "creme-glacee-facile-a-preparer",
      img: "easy-ice-cream",
      hasImage: true,
      description:
        "Une gourmandise faible en gras qui se prépare rapidement. Vous pouvez utiliser d'autres fruits congelés pour obtenir des saveurs différentes.",
      ingredients:
        "1 paquet de 283 g (10 oz) de fraises congelées | 113 g (1/2 tasse) de sucre| 150 ml (2/3 tasse) de crème à café sans gras",
      methods:
        "Mettre les fruits et le sucre dans un robot culinaire ou dans un mélangeur et mélanger jusqu'à ce que les fruits soient grossièrement hachés.| Pendant que le robot culinaire fonctionne, verser lentement la crème à café dans le mélange et laisser le robot fonctionner jusqu'à ce que la crème soit complètement incorporée à la préparation.| Si vous aimez la consistance d'une crème glacée molle, servir immédiatement. Si vous préférez une crème glacée plus ferme, la mettre au congélateur jusqu'à ce qu'elle soit ferme.",
      serves: 4,
      fat: 0,
      saturated: 0,
      calories: 147,
      cholesterol: 0,
      carbohydrate: 36,
      protein: 2,
      sodium: 40,
      fiber: 2,
      difficulty: "facile",
      category: "mets préférés de la famille|dessert|collations légères|fat free",
    },
    {
      title: "Gâteau à la citrouille et aux épices facile à préparer",
      slug: "gateau-a-la-citrouille-et-aux-epices",
      img: "easy-pumpkin-spice-cake",
      hasImage: true,
      ingredients:
        "1 boite de 432 g (15,25 oz) de préparation pour gâteau super moelleux aux épices|1 boîte de 425 g (15 oz) de citrouille |Utilisez n'importe quelle saveur de préparation pour gâteau super moelleux avec de la citrouille en conserve pour un gâteau faible en gras facile et rapide.",
      methods:
        "Préchauffer le four à 176°C (350°F).|Mélanger les deux ingrédients dans un bol jusqu'à ce que le tout soit bien homogène (la pâte sera épaisse).|Étaler dans un plat de cuisson en verre de 23 cm par 33 cm (9 po par 13 po), vaporisé avec un enduit de cuisson antiadhésif.|Cuire au four pendant 20 à 25 minutes ou jusqu’à ce qu’un couteau inséré au centre du gâteau en ressorte propre.|Laisser refroidir et servir avec une garniture fouettée sans gras, du yogourt ou de la crème glacée sans gras ou napper d’une préparation de lait écrémé et de sucre à glacer.",
      time: 40,
      serves: 15,
      fat: 1,
      saturated: 0,
      calories: 118,
      cholesterol: 0,
      carbohydrate: 26,
      protein: 2,
      sodium: 214,
      difficulty: "facile",
      category: "mets préférés de la famille|dessert|collations légères",
    },
    {
      title: "Soupe au poulet du Sud-Ouest facile à préparer",
      slug: "soupe-au-poulet-du-sud-quest",
      img: "easy-southwest-chicken-soup",
      hasImage: true,
      description: 'Emphase sur le mot « facile »... et aussi, délicieux!',
      ingredients:
        "1 boîte de 198 g (7 oz) de salsa verde| 375 g (3 tasses) de poitrines de poulet cuites, émiettées ou coupées en morceaux|1 boîte de 435 g (15 oz) de haricots cannellini ou de haricots blancs, faibles en sodium et égouttés|900 ml de bouillon de poulet faible en sodium et en gras|450 ml d’eau ajoutée au bouillon|7,5 ml (1 ½ c. à thé) de cumin moulu|Garnitures facultatives:|2 échalotes / oignons verts, hachés|Coriandre, hachée|croustilles de tortilla de maïs",
      methods:
        "Verser la salsa verde et le bouillon de poulet dans un grand chaudron.|Ajouter le reste des ingrédients et cuire à feu moyen pendant 30 minutes.|Garnitures facultatives : Couper les tortillas en triangles, vaporiser avec un enduit à cuisson antiadhésif, saupoudrer d’assaisonnement mexicain à faible teneur en sodium, mettre au four à micro-ondes pendant 90 secondes (laisser refroidir pour que les tortillas soient croustillantes)",
      time: 40,
      serves: 4,
      fat: 5.1,
      saturated: 1.1,
      calories: 348,
      cholesterol: 85,
      carbohydrate: 33,
      protein: 41,
      sodium: 1464,
      difficulty: "facile",
      category: "déjeuner|dîner|rapides et faciles",
    },
    {
      title: "Tarte à la citrouille facile à préparer",
      slug: "tarte-a-la-citrouille-facile-a-preparer",
      img: "easy-as-pie-pumpkin-pie",
      hasImage: true,
      description:
        "Savourez cette populaire recette automnale tout au long de l'année. Si vous préférez, ne faites pas de croûte et servez le mélange dans des coupes à crème pâtissière vaporisées avec un enduit à cuisson antiadhésif.",
      ingredients:
        "1 boîte de 850 g (30 oz) de mélange pour tarte à la citrouille |150 ml (2/3 tasse) de lait évaporé, sans gras|90 g (1/2 tasse) de substitut d’œuf|45 ml à 60 ml (3 à 4 c. à table) de miel|5 feuilles de biscuits Graham au miel, à faible teneur en matières grasses. Mettre dans un sac en plastique et frapper avec le dos d’une cuillère pour obtenir environ 50 g (1 tasse) de chapelure",
      methods:
        "Préchauffer le four à 176°C (350°F).|Préparer la croûte en mélangeant le miel et la chapelure de biscuits Graham.|Presser au fond d’un moule à tarte de 24 cm (9 ½ po), vaporisé avec un enduit à cuisson antiadhésif. |Cuire au four pendant 7 à 9 minutes (le miel brunit rapidement... ne pas trop cuire). Laisser refroidir avant d’ajouter la garniture. |Mélanger la citrouille, le lait évaporé et le substitut d’œuf jusqu’à ce que le tout soit bien homogène.|Verser le mélange dans la croûte de tarte refroidie.|Cuire la tarte au four pendant 60 à 70 minutes ou jusqu’à ce qu’un couteau inséré au centre de la tarte en ressorte propre.|Le mélange remplit parfaitement un moule à tarte de 24 cm (9 ½ po)... Toutefois, si vous utilisez un plus petit moule, vous pourriez avoir un surplus de garniture. Versez-la à la cuillère dans une tasse à crème pâtissière vaporisées avec un enduit à cuisson antiadhésif.|EGâtez-vous et goûtez-y avant l'arrivée de vos convives.",
      time: 100,
      serves: 8,
      fat: 1,
      saturated: 0,
      calories: 208,
      cholesterol: 0,
      carbohydrate: 43,
      protein: 5,
      sodium: 264,
      difficulty: "moyen",
      category: "mets préférés de la famille|dessert",
    },
    {
      title: "Caviar d'aubergine",
      slug: "caviar-daubergine",
      img: "eggplant-caviar",
      hasImage: true,
      description:
        "Populaire dans les pays du Moyen-Orient, ce plat traditionnel à base d'aubergines est la garniture idéale pour les pitas de blé entier. Vous pouvez également l'utiliser comme tartinade sur du pain grillé ou des craquelins.",
      ingredients:
        "1 à 2 aubergines, environ 675 g (3 tasses) de pulpe écrasée|44 g (¼ tasse) d’oignon, haché finement|44 g (¼ tasse) d’échalotes / d’oignons verts, finement émincés|44 g (¼ tasse) de poivron vert, haché finement|2 ou 3 gousses d’ail, finement émincées, ou 2,5 ml (½ c. à thé) de poudre d’ail|175 g (1 tasse) de tomates, pelées, coupées en petits dés (ou des tomates en conserve, coupées en dés)|5 ml (1 c. à thé) de sucre|le jus d’un citron|poivre noir, au goût",
      methods:
        "Cuire les aubergines piquées à la fourchette au four à micro-ondes, à puissance élevée, pendant 10 minutes. Laisser refroidir légèrement.|Dans un grand bol, mélanger le reste des ingrédients.|Couper les aubergines en deux et verser la partie intérieure dans le bol. Bien remuer pour mélanger.|Réfrigérer pendant au moins 2 heures avant de servir.",
      serves: 5,
      fat: 0.4,
      saturated: 0,
      calories: 76,
      cholesterol: 0,
      carbohydrate: 16,
      protein: 3,
      sodium: 13,
      difficulty: "moyen",
      category: "déjeuner|collation|végétarien",
    },
    {
      title: "Soupe aux haricots et aux macaronis",
      slug: "soupe-aux-haricots-et-aux-macaronis",
      img: "bean-and-macaroni-soup",
      hasImage: true,
      description:
        "Cette soupe copieuse et épaisse constitue un dîner rassasiant. Doubler la portion pour les plus gros appétits.",
      ingredients:
        "1 boite de 397 g (14 oz) de haricots blancs ou de petits haricots ronds blancs, rincés et égouttés|1 boite de 397 g (14 oz) de tomates en dés|enduit à cuisson antiadhésif|1 oignon, haché|4 carottes, tranchées finement|2,5 ml (1/2 c. à thé) de poudre d’ail|2,5 ml (1/2 c. à thé) d’assaisonnement italien|77 g (2/3 tasse) de macaronis non cuits|710 ml (3 tasses ou plus) d’eau",
      methods:
        "Vaporiser un chaudron antiadhésif de 3,8 l avec un enduit à cuisson antiadhésif.|Ajouter l'oignon et les carottes et faire revenir pendant 5 minutes.|Ajouter les tomates, les épices et l’eau; porter à ébullition.|Ajouter les macaronis et les haricots et faire bouillir pendant 10 minutes jusqu’à ce que les macaronis soient al dente. Remuer régulièrement.|Servir dans un grand bol et déguster!",
      time: 20,
      serves: 4,
      fat: 1,
      saturated: 0,
      calories: 197,
      cholesterol: 0,
      carbohydrate: 39,
      protein: 7.5,
      sodium: 358,
      difficulty: "facile",
      category: "rapides et faciles|végétarien|déjeuner|hearty meal",
    },
    {
      title: "Pochettes élégantes aux artichauts",
      slug: "pochettes-elegantes-aux-artichauts",
      img: "elegant-artichoke-pockets",
      hasImage: true,
      description:
        "Ne vous fiez pas aux apparences - ces hors-d’œuvre sont faciles à préparer! Une recette idéale pour recevoir la famille ou des amis.",
      ingredients:
        "32 feuilles pour wontons|2 boîtes de 368 g (13 oz) de cœurs d’artichaut, rincés et égouttés|1/2 poivron rouge, émincé|25 g (1/4 tasse) de parmesan|76 g (1/3 tasse) de mayonnaise, sans gras|1 boite de 56 g (2 oz) d’olives mûres tranchées, hachées finement|3,75 ml (3/4 c. à thé) de poudre d’ail",
      methods:
        "Préchauffer le four à 176°C (350°F).|Mélanger les cœurs d’artichauts, la mayonnaise, le parmesan et l’ail dans un robot culinaire, jusqu’à l’obtention d’une texture lisse.|Retirer la lame, incorporer le poivron rouge et les olives et mélanger.|Répartir uniformément sur les feuilles de wontons (environ 1 c. à table pour chacune).|Tirer les coins des feuilles de wontons vers le haut et les replier vers le centre pour les fermer, de façon à créer une pochette.|Cuire au four pendant 20 à 25 minutes, jusqu'à ce que les bords soient dorés.|Servir chaud. Les restes peuvent être réchauffés au four ou au four à micro-ondes.",
      time: 35,
      serves: 8,
      fat: 1.9,
      saturated: 0.5,
      calories: 141,
      cholesterol: 5,
      carbohydrate: 26,
      protein: 5,
      sodium: 426,
      difficulty: "moyen",
      category: "appetizers|végétarien",
    },
    {
      title: "Fabuleux soufflé aux blintz (crêpe juive)",
      slug: "fabuleux-souffle-aux-blintz",
      img: "fabulous-blintz-souffle",
      hasImage: true,
      description:
        "Un plat élégant et si facile à préparer. Personne ne saura que vous avez passé moins de 10 minutes dans la cuisine.",
      ingredients:
        "12 blintz au fromage surgelés (2 paquets)|Une boite de 453 g (16 oz) de substitut d’œufs |453 g (16 oz) de crème sure sans gras|56 ml (¼ tasse) de jus d’orange|50 g (¼ tasse) de sucre (ou 6 sachets d’édulcorant)|5 ml (1 c. à thé) d’extrait de vanille|un soupçon de cannelle",
      methods:
        'Préchauffer le four à 190°C (375°F).|Vaporiser un plat de cuisson en verre de 23 cm par 30 cm (9 po par 12 po) avec un enduit à cuisson antiadhésif.|Disposer les blintz congelés au fond du plat de cuisson.|Dans un bol de grosseur moyenne, mélanger le substitut d’œuf, la crème sure (ou du yogourt grec), le jus d’orange, l’extrait de vanille et le sucre, jusqu’à ce que le tout soit bien homogène.|Verser sur les blintz de façon à les recouvrir.|Saupoudrer de cannelle.|Cuire jusqu’à ce que le tout soit ferme (environ 50 à 60 minutes).|Servir avec des baies, de la compote de pommes et/ou de la confiture. Délicieux réchauffé au four à micro-ondes le jour suivant!',
      time: 75,
      serves: 6,
      fat: 3,
      saturated: 2,
      calories: 303,
      cholesterol: 42,
      carbohydrate: 51,
      protein: 18,
      sodium: 460,
      difficulty: "facile",
      category: "dessert",
    },
    {
      title: "Pain de maïs sans gras",
      slug: "pain-de-mais-sans-gras",
      img: "fat-free-corn-bread",
      hasImage: true,
      description:
        "Une populaire recette de la région du Sud. Facile à préparer et l’absence de matières grasses ne vous manquera pas.",
      ingredients:
        "1 tasse de semoule de maïs jaune|70 g (1/2 tasse) de farine tout usage|2,5 ml (1/2 c. à thé) de sel|15 ml (1 c. à table) de poudre à pâte|4 blancs d’œufs ou 125 ml (½ tasse) de substitut d’œuf|250 g (1 tasse) de yogourt nature sans gras|113 ml (1/2 tasse) de lait écrémé|50 g (¼ tasse) de sucre (pour réduire la teneur en glucides, utiliser un édulcorant non calorique ou omettre le sucre pour obtenir un pain moins sucré)",
      methods:
        'Préchauffer le four à 176°C (350°F).|Vaporiser un poêlon en fonte de 20 cm ou 23 cm (8 ou 9 po) ou un moule à tarte avec un enduit à cuisson antiadhésif.|Mélanger tous les ingrédients dans un bol et remuer.|Verser dans le plat préparé.|Cuire au four de 30 à 35 minutes.',
      time: 45,
      serves: 8,
      fat: 0.5,
      saturated: 0,
      calories: 97,
      cholesterol: 3,
      carbohydrate: 19,
      protein: 4,
      sodium: 92,
      fiber: 3,
      difficulty: "facile",
      category: "dessert|collation|rapides et faciles|fat free",
    },
    {
      title: "Pain aux dattes sans gras",
      slug: "pain-aux-dattes-sans-gras",
      img: "fat-free-date-bread",
      hasImage: true,
      description:
        "Personne ne devinera que cette délicieuse gâterie est sans gras. Grâce aux dattes, le pain conserve une texture moelleuse.",
      ingredients:
        "50 g (2 tasses) de dattes dénoyautées|225 ml (1 tasse) d’eau chaude|5 ml (1 c. à thé) de bicarbonate de soude|2 blancs d’œufs|100 g (1/2 tasse) de sucre|5 ml (1 c. à thé) d’extrait de vanille|210 g (1 ½ tasse) de farine",
      methods:
        'Préchauffer le four à 176°C (350°F).|Mettre les dattes dans un petit bol. Ajouter le bicarbonate de soude à l’eau et verser sur les dattes. |Dans un robot culinaire, mélanger les blancs d’œufs, le sucre et la vanille.|Ajouter environ le ¼ du mélange de dattes et d’eau et remuer jusqu’à ce que la préparation ait une consistance plutôt lisse.|Ajouter la farine et mélanger jusqu’à ce que le tout soit homogène.|Ajouter le reste du mélange de dattes et d’eau et remuer brièvement, pendant 5 à 30 secondes. Remuer pour vérifier que le mélange est bien homogène.|Verser dans un moule à pain de 13 cm par 23 cm (5 po par 9 po), vaporisé avec un enduit à cuisson antiadhésif. |Cuire au four pendant 60 à 75 minutes, jusqu’à ce qu’un couteau inséré au centre du pain en ressorte propre.|Laisser refroidir et trancher. Ce pain peut être servi avec du fromage à la crème sans gras ou de la ricotta.',
      time: 90,
      serves: 12,
      fat: 0,
      saturated: 0,
      calories: 148,
      cholesterol: 0,
      carbohydrate: 34,
      protein: 3,
      sodium: 114,
      difficulty: "moyen",
      category: "dessert|collation|fat free",
    },
    {
      title: "Biscuits moelleux à l’avoine et aux raisins secs",
      slug: "biscuits-moelleux-a-lavoine",
      img: "chewy-oatmeal-raisin-cookies",
      hasImage: true,
      description: "Idéal pour les rencontres durant la période des Fêtes!",
      ingredients:
        "140 g (1 tasse) d’avoine à l’ancienne |140 g (1 tasse) de farine|2,5 ml (1/2 c. à thé) de bicarbonate de soude|3,75 ml (3/4 c. à thé) de cannelle moulue |2,5 ml (1/2 c. à thé) de sel|2,5 ml (1/2 c. à thé) de vanille|85 g (1/2 tasse) de cassonade|50 g (1/4 tasse) de sucre blanc|44 g (1/4 tasse) de raisins secs|175 g (½ tasse) de compote de pommes|1 blanc d’œuf|30 ml (2 c. à table) de lait écrémé",
      methods:
        "Préchauffer le four à 190°C (375°F).|Mélanger tous les ingrédients secs avec les raisins secs.|Ajouter la compote de pommes, le blanc d’œuf et le lait écrémé aux ingrédients secs. Mélanger pour bien incorporer tous les ingrédients.|Mettre sur une plaque à biscuits antiadhésive.|Cuire au four de 12 à 15 minutes ou jusqu’à ce que les biscuits soient dorés.|Laisser reposer les biscuits quelques minutes avant de les retirer de la plaque pour les transférer sur une grille afin qu’ils refroidissent.",
      time: 20,
      serves: 12,
      fat: 0.6,
      saturated: 0.1,
      calories: 121,
      cholesterol: 0,
      carbohydrate: 27,
      protein: 2,
      sodium: 158,
      fiber: 1,
      difficulty: "facile",
      category: "dessert|collation|mets préférés de la famille|rapides et faciles",
    },
    {
      title: "Légumes au barbecue ",
      slug: "légumes-au-barbecue ",
      img: "barbequed-vegetables",
      hasImage: true,
      description:
        "Donnez un goût unique aux légumes. Servir avec du riz basmati brun.",
      ingredients:
        "1 oignon, tranché en rondelles|1 zucchini / courgette, tranché|1 courge d'été, tranchée (zucchini)|1 poivron vert, tranché|1 poivron rouge, tranché|2 pommes de terre nouvelles, coupées en petits morceaux|8 gros champignons ou plus|125 ml (1/2 tasse) de sauce barbecue",
      methods:
        'Préchauffer le four à 218°C (425°F).|Précuire légèrement les pommes de terre en les mettant au four à micro-ondes pendant 3 minutes à puissance élevée.|Vaporiser un moule à cuisson de 23 cm par 33 cm (9 po par 13 po) d’enduit à cuisson antiadhésif ou tapisser le fond de papier d’aluminium antiadhésif.|Mettre tous les légumes dans le moule à cuisson et les badigeonner de sauce barbecue.|Cuire au four pendant 15 minutes et terminer la cuisson sous le gril pendant 5 minutes pour noircir légèrement les légumes.',
      serves: 4,
      fat: 1,
      saturated: 0,
      calories: 115,
      cholesterol: 0,
      carbohydrate: 24,
      protein: 4,
      sodium: 210,
      fiber: 4,
      difficulty: "facile",
      category: "rapides et faciles|végétarien|dîner|déjeuner",
    },
    {
      title: "Cobbler aux pêches sans gras",
      slug: "cobbler-aux-peches-sans-gras",
      img: "fat-free-peach-cobbler",
      hasImage: true,
      description: "Un dessert simple qui convient à toutes les occasions.",
      ingredients:
        "113 g (1/2 tasse) de sucre blanc (ou d’édulcorant non calorique pour réduire la teneur en glucides)|70 g (1/2 tasse) de farine|113 ml (1/2 tasse) de lait écrémé|5 ml (1 c. à thé) de poudre à pâte|1,25 ml (1/4 c. à thé) de sel|525 g (3 tasses) de pêches congelées, sans sucre ajouté",
      methods:
        'Préchauffer le four à 176°C (350°F).|Vaporiser un moule carré de 20 cm par 20 cm (8 po par 8 po) avec un enduit à cuisson antiadhésif.|Mélanger la farine, le lait, la poudre à pâte et le sel dans un bol.|Dans un autre bol, mettre les pêches et mélanger avec 113 g (½ tasse) de sucre (ou d’édulcorant ou de stévia pour une version plus faible en glucides). |Verser d’abord le mélange de pâte dans le moule et bien l’étaler. |Ajouter ensuite les fruits mélangés au sucre (ou à l’édulcorant) sur la pâte. |La croûte cuira complètement jusque sur le dessus.|Cuire au four pendant 30 à 35 minutes jusqu’à l’obtention de la couleur doré désirée.|Garniture facultative : Crème glacée à la vanille sans gras (si vous limitez votre consommation de glucides, choisissez la version sans sucre ajouté).',
      time: 45,
      serves: 6,
      fat: 0,
      saturated: 3,
      calories: 118,
      cholesterol: 0,
      carbohydrate: 30,
      protein: 3,
      sodium: 103,
      fiber: 2,
      difficulty: "moyen",
      category: "dessert|fat free",
    },
    {
      title: "Plat au poisson tout simple",
      slug: "plat-au-poisson-tout-simple",
      img: "fish-made-simple",
      hasImage: true,
      description:
        "Appréciez la saveur subtile du poisson avec cette recette toute simple.",
      ingredients:
        "340 à 453 g (12 à 16 oz) de poisson maigre comme la sole, l’aiglefin, la morue, le tilapia, le mahi-mahi ou le flétan|2 blancs d’œufs ou 125 ml (½ tasse) de substitut d’œuf|63 g (1/2 tasse) de chapelure de style italien|1 citron frais",
      methods:
        "Préchauffer le four à 218°C (425°F).|Laver et assécher les filets de poisson.|Mettre l’œuf dans un bol et y tremper le poisson.|Mettre la chapelure dans un autre bol pour enrober le poisson.|Disposer les filets enrobés sur une feuille d'aluminium antiadhésive placée sur une plaque à biscuits (pour en faciliter le nettoyage).|Garnir de citron coupé en tranches fines.|Cuire au four pendant 20 minutes (selon l’épaisseur) ou jusqu'à ce que le poisson se défasse en morceaux.|Servir avec du citron frais.",
      note:
        "If you prefer a buttery taste without a crumb coating, spray filets with butter spray, season as desired, top with thin lemon slices and bake as above. Alternate toppings can include fruit salsa (mango), tomato salsa, fat-free ginger marinade or teriyaki sauce.",
      time: 30,
      serves: 2,
      fat: 3,
      saturated: 0,
      calories: 223,
      cholesterol: 83,
      carbohydrate: 10,
      protein: 37,
      sodium: 395,
      difficulty: "facile",
      category: "dîner|poisson|rapides et faciles",
    },
    {
      title: "Ragoût de poisson",
      slug: "ragout-de-poisson",
      img: "fish-stew",
      hasImage: true,
      description:
        "Ce populaire plat de fruits de mer facile à préparer est idéal comme repas en semaine au retour d’une journée bien occupée.",
      ingredients:
        "454 g (1 lb) de filets de tilapia|56 ml (1/4 tasse) de bouillon de légumes|1 oignon de grosseur moyenne, haché|4 gousses d’ail, émincées|1 boîte de 396 g (14 oz) de tomates, coupées en dés |1 piment chili, haché|15 g (1/4 tasse) de coriandre fraîche, hachée|15 ml (1 c. à table) de câpres, rincées|15 ml (1 c. à table) d’origan séché|2,5 ml (1/2 c. à thé) de sel|169 ml (¾ tasse) d’eau, au besoin|1/8 d’un avocat comme garniture",
      methods:
        "Dans une poêle à frire, chauffer le bouillon de légumes à feu moyen.|Ajouter l’oignon et cuire, en remuant de temps en temps, jusqu’à ce qu’il soit ramolli.|Ajouter l’ail et poursuivre la cuisson pendant environ 1 minute.|Ajouter le poisson, les tomates et leurs jus, puis le piment chili, la coriandre, les câpres, l’origan et le sel. Remuer pour bien mélanger.|Ajouter 113 ml à 169 ml (1/2 à 3/4 tasse) d’eau si le mélange semble sec. |Couvrir et laisser mijoter pendant 20 minutes. |Retirer du feu et servir chaud, avec des morceaux d’avocat comme garniture, si désiré.",
      time: 40,
      serves: 4,
      fat: 3,
      saturated: 1,
      calories: 160,
      cholesterol: 50,
      carbohydrate: 10,
      protein: 23,
      sodium: 125,
      difficulty: "moyen",
      category: "dîner|poisson ",
    },
    {
      title: "Sauté au gingembre à la chinoise",
      slug: "Sauté au gingembre à la chinoise",
      img: "gingery-chinese-stir-fry",
      hasImage: true,
      description:
        "Cette recette peut être réalisée avec n'importe quel légume ou protéine (poulet, crevettes, bœuf maigre ou porc).",
      ingredients:
        "30 ml à 45 ml (2 à 3 c. à table) de gingembre frais, émincé (ou du gingembre préparé émincé)|3 à 4 gousses d’ail, émincées|454 g (1 lb) de poitrines de poulet, désossées, sans peau, coupées en lanières|150 g (1 tasse) de cosses de pois chinois|300 g (2 tasses) de fleurons de brocoli|175 g (1 tasse) de champignons, tranchés|1 ou 2 petits bok choys, tranchés|150 g à 300 g (1 à 2 tasses) de fèves germées fraîches|2 échalotes / oignons verts, hachés|88 g (1/2 tasse) de carottes, tranchées en lamelles minces|88 g (½ tasse) de poivron rouge, tranché|une petite boîte de châtaignes d’eau, rincées|338 ml (1 ½ tasse) de bouillon de poulet (faible en sodium, si désiré)|30 ml (2 c. à table) de fécule de maïs (un peu plus au besoin pour épaissir)|30 ml à 45 ml (2 à 3 c. à table) de sauce soya légère|poivre frais, au goût",
      methods:
        "Dans un grand plat à sauter ou dans un wok vaporisé avec un enduit à cuisson antiadhésif, faire revenir le poulet jusqu'à ce qu'il soit presque complètement cuit. |Ajouter les champignons et les carottes et poursuivre la cuisson. Ensuite, ajouter le reste des légumes et 225 ml (1 tasse) de bouillon de poulet. |Couvrir pendant 5 minutes, et poursuivre la cuisson. |Mélanger 30 ml (2 c. à table) de fécule de maïs avec le reste du bouillon, puis incorporer au mélange de poulet avec la sauce soya. Cuire brièvement pour épaissir la sauce. |Ajouter le poivre moulu frais et servir sur 200 g (1 tasse) de riz brun ou de riz blanc, par portion.",
      serves: 4,
      fat: 3,
      saturated: 1,
      calories: 435,
      cholesterol: 65,
      carbohydrate: 66,
      protein: 36,
      sodium: 579 - 972,
      difficulty: "moyen",
      category: "dîner|mets préférés de la famille ",
    },
    {
      title: "Filets de porc grillés",
      slug: "filets-de-porc-grilles",
      img: "grilled-pork-tenderloin",
      hasImage: true,
      description:
        "Un mets qui plaira autant aux enfants qu’aux adultes. Un plat simple qui est tout aussi savoureux le lendemain. La recette est pour deux filets de porc de 454 g chacun (1 lb); réduisez les ingrédients de moitié si vous n'utilisez qu'un filet.",
      ingredients:
        "63 g (1/4 tasse) de sauce soya (pour réduire le sodium, utiliser la version faible en sodium)|43 g (1/4 tasse) de cassonade|3 gousses d’ail|88 g (1/4 tasse) de moutarde de Dijon au miel|1,25 ml (1/4 c. à thé) de gingembre moulu|5 ml (1 c. à thé) de sauce Worcestershire|2 filets de porc de 454 g (1 lb) chacun",
      methods:
        "Mélanger les 6 premiers ingrédients et battre avec un fouet.|Mettre les filets de porc dans un sac en plastique avec la marinade et réfrigérer toute une nuit.|Griller à feu moyen pendant 15 à 25 minutes, jusqu'à ce que la température interne du porc ait atteint 73°C (165°F), en vérifiant avec un thermomètre à viande. |Retirer du gril et laisser reposer pendant 15 minutes. |Couper chaque filet de viande en tranches de 6 mm (¼ po) (4 portions par livre de viande ou environ 85 g (3 oz) de viande cuite par portion ou un total de 8 portions).|Un délice servi chaud ou froid en sandwich!",
      time: 50,
      serves: 8,
      fat: 2.7,
      saturated: 0.8,
      calories: 150,
      cholesterol: 54,
      carbohydrate: 6,
      protein: 25,
      sodium: 693,
      difficulty: "moyen",
      category: "dîner|mets préférés de la famille|pork",
    },
    {
      title: "« Demi » brownies maison",
      slug: "demi-brownies-maison",
      img: "half-homemade-brownies",
      hasImage: true,
      description: "Toute la gloire... sans le travail.",
      ingredients:
        "1 boîte de préparation pour brownies |1/2 substitut d’œuf|125 g (1/2 tasse) de yogourt nature sans gras ou de yogourt grec|56 ml (1/4 tasse) d’eau (ou selon les indications de l’emballage)",
      methods:
        'Préchauffer le four à 176°C (350°F).|Mélanger tous les ingrédients et les mettre dans un moule de 33 cm par 23 cm (13 po par 9 po) vaporisé avec un enduit à cuisson antiadhésif.|Cuire au four pendant 25 minutes ou jusqu’à ce qu’un couteau inséré au centre des brownies en ressorte propre.|Laisser refroidir et couper en 18 morceaux.',
      time: 35,
      serves: 18,
      fat: 3.5,
      saturated: 1,
      calories: 141,
      cholesterol: 0,
      carbohydrate: 25,
      protein: 3,
      sodium: 97,
      difficulty: "facile",
      category: "dessert|mets préférés de la famille|rapides et faciles",
    },
    {
      title: "Gâteau aux pommes de la moisson",
      slug: "gateau-aux-pommes-de-la-moisson",
      img: "harvest-apple-cake",
      hasImage: true,
      description: "Ce gâteau contient un ingrédient surprise! Ne le dites à personne.",
      ingredients:
        "100 g (1/2 tasse) de sucre|70 g (1/2 tasse) de farine|150 g (1 tasse) de haricots pinto, rincés et égouttés|1 blanc d’œuf|30 ml (2 c. à table) d’huile végétale (utiliser 30 ml de compote de pommes pour réduire la teneur en gras à moins de 1 g par portion)|5 ml (1 c. à thé) d’extrait de vanille|2,5 ml (1/2 c. à thé) de bicarbonate de soude|1,25 ml (1/4 c. à thé) de clou de girofle|5 ml (1 c. à thé) de cannelle|175 g (1 tasse) de pommes, crues, émincées (y compris la pelure)|58 g (1/3 tasse) de raisins secs",
      methods:
        "Préchauffer le four à 190°C (375°F).|Mettre les pommes dans un robot culinaire et mélanger jusqu'à ce qu'elles soient émincées. Retirer les pommes.|Mélanger tous les ingrédients, sauf les pommes et les raisins secs, au robot culinaire jusqu’à l’obtention d’une consistance lisse.|Incorporer les pommes et les raisins secs.|Verser dans un plat de cuisson en verre de 20 cm par 20 cm (8 po par 8 po), vaporisé avec un enduit à cuisson antiadhésif.|Cuire au four pendant 50 minutes ou jusqu’à ce qu’un couteau inséré au centre du gâteau en ressorte propre.",
      time: 60,
      serves: 9,
      fat: 3,
      saturated: 0,
      calories: 141,
      cholesterol: 0,
      carbohydrate: 27,
      protein: 2.5,
      sodium: 115,
      fiber: 2,
      difficulty: "facile",
      category: "dessert|mets préférés de la famille",
    },
    {
      title: "Salade de pommes de terre nouvelles aux fines herbes",
      slug: "salade-de-pommes-de-terre-nouvelles",
      img: "herbed-new-potato-salad",
      hasImage: true,
      description:
        "Colorée et délicieuse. Tellement facile à préparer si vous avez les ingrédients sous la main.",
      ingredients:
        "Environ 907 g (2 lb) de petites pommes de terre rouges ou nouvelles (ou 4 à 5 pommes de terre rouges de grosseur moyenne)|75 g (1/2 tasse) de pois congelés, décongelés|1 poivron rouge, haché|1 ou 2 échalotes / oignons verts, émincés|58 g (1/4 tasse) de mayonnaise « légère » (3,5 g de gras par c. à table)|65 g (1/4 tasse) de yogourt grec sans gras|5 ml à 10 ml (1 à 2 c. à thé) de moutarde de Dijon |30 ml à 45 ml (2 à 3 c. à table) d’aneth frais, émincé ou 10 ml à 15 ml (2 à 3 c. à thé) d’aneth séché|1,25 ml (1/4 c. à thé) de poudre d’ail|sel et poivre moulu frais, au goût",
      methods:
        "Faire bouillir les pommes de terre jusqu'à ce qu'elles soient cuites juste à point. Les laisser refroidir, (ne pas peler) et les couper en quartiers ou en huit morceaux. |Dans un grand bol, mélanger la mayonnaise, le yogourt, la moutarde et les épices. |Ajouter le reste des ingrédients et remuer. Réfrigérer et servir. |Pour réduire davantage la teneur en matières grasses, utilisez une mayonnaise faible en gras ou sans gras ou un yogourt grec sans gras.",
      serves: 4,
      fat: 4,
      saturated: 0,
      calories: 209,
      cholesterol: 2,
      carbohydrate: 41,
      protein: 6,
      sodium: 184,
      fiber: 5,
      difficulty: "facile",
      category: "déjeuner|mets préférés de la famille|végétarien",
    },
    {
      title: "Déjeuner des Fêtes en casserole",
      slug: "dejeuner-des-fetes-en-casserole",
      img: "holiday-breakfast-casserole",
      hasImage: true,
      description:
        "Facile à cuisiner, ce plat nourrit toute la famille et peut être préparé la veille.",
      ingredients:
        "7 tranches de pain blanc|1 paquet de 226 g (8 oz) de jambon, coupé en dés, sans gras à 96 %|1 paquet de 226 g (8 oz) de fromage râpé, sans gras (cheddar ou suisse)*|1 contenant de 453 g (16 oz) de substitut d’œuf liquide|394 ml (1 ¾ tasse) de lait écrémé|1,25 ml (1/4 c. à thé) de poivre noir",
      methods:
        "Déchiqueter le pain en morceaux et le déposer dans un moule de 23 cm par 33 cm (9 po par 13 po) ou dans une cocotte de 3 litres.|Répartir le jambon coupé en dés et le fromage sur le pain. |Battre le substitut d’œuf, le lait et le poivre. Verser le mélange sur les couches de pain, de jambon et de fromage. |Couvrir d'une pellicule de plastique et appuyer légèrement sur les morceaux de pain jusqu'à ce que le lait imbibe le dessus des couches d’aliments. Réfrigérer toute la nuit.|Juste avant la cuisson, retirer la pellicule de plastique et préchauffer le four à 176°C (350°F). Cuire le plat au four pendant 1 heure.",
      note:
        "*The fat-free shredded cheese can be substituted with 2% reduced-fat shredded cheese, which brings the Total Fat to 7.7 g per serving.",
      time: 80,
      serves: 8,
      fat: 1.6,
      saturated: 0,
      calories: 178,
      cholesterol: 19,
      carbohydrate: 49,
      protein: 22,
      sodium: 866,
      difficulty: "moyen",
      category: "petit déjeuner|mets préférés de la famille ",
    },
    {
      title: "Trempette à 7 étages",
      slug: "trempette-a-7-etages",
      img: "seven-layer-dip",
      hasImage: true,
      description:
        "Cette trempette de style mexicain est un hors-d’œuvre idéal à apporter à une fête. Personne ne se doutera qu’il s’agit d’un plat faible en gras. Servir avec des croustilles de maïs cuites au four ou des pointes de pain pita.",
      ingredients:
        "1/4 cup (44g) avocado, chopped|15 ml (1 c. à table) de jus de citron frais|1 petite gousse d’ail, émincée|10 ml (2 c. à thé) d’oignon, émincé|115 g (½ tasse) de yogourt grec nature sans gras|1,25 ml (¼ c. à thé ) de sel|0,625 ml (⅛ c. à thé) de poivre|150 g (1 tasse) (½ boîte de conserve) de haricots frits sans gras|165 g (⅔ tasse) de crème sure sans gras ou de yogourt grec sans gras|115 ml (½ tasse) de sauce piquante douce|29 g (¼ tasse) de fromage léger pour tacos, finement râpé|175 g (1 tasse) de tomates italiennes, coupées en morceaux|15 ml (1 c. à table) de coriandre fraîche, hachée|15 ml (1 c. à table) d'oignons verts, hachés",
      methods:
        "Dans un petit bol, préparer le guacamole en mélangeant les sept premiers ingrédients figurant sur la liste.|Dans une assiette à tarte de 23 cm (9 po), superposer les ingrédients suivants:|Étape 1: haricots frits|Étape 2: crème sure / yogourt grec|Étape 3: guacamole|Étape 4: sauce piquante|Étape 5: fromage|Étape 6: tomates|Étape 7: oignons verts / coriandre",
      time: 15,
      serves: 10,
      fat: 1.5,
      saturated: 0.4,
      calories: 68,
      cholesterol: 2,
      carbohydrate: 10,
      protein: 4,
      sodium: 299,
      difficulty: "facile",
      category: "collation|déjeuner|mets préférés de la famille|rapides et faciles",
    },
    {
      title: "Chausson à la confiture de cidre de pomme",
      slug: "chausson-a-la-confiture-de-cidre-de-pomme",
      img: "apple-cider-jam-turnovers",
      hasImage: true,
      description:
        "Ces délicieuses petites gâteries goûtent la tarte aux pommes, mais sans gras!",
      ingredients:
        "1/2 boîte de feuilles de pâte phyllo (environ 20 feuilles), décongelées|226 g (8 oz) de fromage à la crème sans gras, à la température ambiante|160 g (1/2 tasse) de confiture ou de gelée de pommes |1 blanc d’œuf plus 15 ml (1 c. à table) d’eau|Sucre granulé",
      methods:
        "Préchauffer le four à 350°F (176°C) et recouvrir 2 plaques à pâtisserie de papier parchemin.|Mélanger le fromage à la crème et la confiture dans un petit bol, jusqu’à ce que le tout soit homogène.|Dérouler délicatement les feuilles de pâtes et les recouvrir d'une serviette humide pour éviter qu'elles ne se dessèchent.|Prendre une feuille de pâte et la plier en deux, dans le sens de la longueur.|Déposer 1 c. à table de mélange de fromage à la crème dans le coin inférieur de la feuille de pâte.|Plier le coin de la feuille de pâte en diagonale, jusqu’au bord opposé, afin de former un triangle.|Continuer à plier le triangle sur lui-même comme lorsque l'on plie un drapeau.|Mettre sur une plaque à pâtisserie.|Fouetter l’œuf et l’eau. Badigeonner chaque triangle du mélange aux œufs. Saupoudrer de sucre.|Cuire au four pendant 20 minutes.",
      time: 35,
      serves: 10,
      fat: 0.6,
      saturated: 0.1,
      calories: 118,
      cholesterol: 3,
      carbohydrate: 21,
      protein: 6,
      sodium: 245,
      difficulty: "moyen",
      category: "collation|dessert|mets préférés de la famille",
    },
    {
      title: "Croustade aux pommes",
      slug: "croustade-aux-pommes",
      img: "apple-crisp",
      hasImage: true,
      description:
        "Savourez cette recette classique avec une boule de yogourt glacé à la vanille sans gras.",
      ingredients:
        "875 g (5 tasse) de pommes, pelées et tranchées|93 g (2/3 tasse) de flocons d’avoine|47 g (1/3 tasse) de farine|113 g (2/3 tasse) de cassonade, légèrement tassée|3,75 ml (3/4 c. à thé) de cannelle|3,75 ml (3/4 c. à thé) de muscade|60 ml (4 c. à table) de miel",
      methods:
        "Préchauffer le four 176°C (à 350°F) et vaporiser un moule de 20 cm par 20 cm (8 po par 8 po) d’un enduit à cuisson antiadhésif.|Ajouter les pommes dans le moule préparé.|Mélanger le reste des ingrédients dans un grand bol et bien incorporer.|Saupoudrer le mélange sur les pommes et cuire de 30 à 35 minutes.",
      time: 45,
      serves: 8,
      fat: 1.1,
      saturated: 0.2,
      calories: 182,
      cholesterol: 0,
      carbohydrate: 42,
      protein: 3,
      sodium: 4,
      fiber: 3,
      difficulty: "facile",
      category: "collation|dessert|mets préférés de la famille",
    },
    {
      title: "Tarte aux bleuets",
      slug: "tarte-aux-bleuets",
      img: "blueberry-pie",
      hasImage: true,
      description:
        "La plupart des matières grasses que contiennent les tartes aux fruits proviennent de la croûte. Cette croûte de tarte faible en gras convient parfaitement à cette recette de tarte classique.",
      ingredients:
        "Croûte de tarte (donne 1 croûte; doubler les quantités pour obtenir 2 croûtes)|187 g (1⅓ tasse) de farine blanche |1 contenant (environ 150 g ou 5,3 oz) de yogourt grec nature |2,5 ml (1/2 c. à thé) d’huile d’olive |60 ml (4 c. à table) d’eau froide |600 g (6 tasses) de bleuets frais ou congelés|169 g (3/4 tasse) de sucre|60 ml (4 c. à table) de tapioca à préparation rapide|2,5 ml (1/2 c. à thé) de cannelle|15 ml (1 c. à table) de jus de citron",
      methods:
        "Préchauffer le four à 218°C (425°F).|Mélanger la farine, le yogourt grec et l’huile jusqu’à ce que la texture soit friable. |Ajouter l’eau froide et remuer pour bien mélanger le tout (ne pas trop remuer). |Pétrir la pâte 6 à 8 fois. |Abaisser sur une surface enfarinée jusqu’à ce que la pâte s'adapte au moule à tarte.|Pour la garniture : mélanger les ingrédients dans un grand bol. |Ajouter à la croûte de tarte préparée dans un moule à tarte de 23 cm (9 po). |Recouvrir la garniture d'une deuxième croûte de tarte ou couper la deuxième croûte de tarte en lanières pour former un treillis et l'ajouter sur le dessus.|Cuire au four à 218°C (425°F) pendant 60 minutes (quelques minutes de plus pour les baies congelées).",
      time: 80,
      serves: 8,
      fat: 1.3,
      saturated: 0.2,
      calories: 330,
      cholesterol: 2,
      carbohydrate: 71,
      protein: 9,
      sodium: 20,
      fiber: 6,
      difficulty: "moyen",
      category: "dessert|mets préférés de la famille",
    },
    {
      title: "Bruschetta",
      slug: "bruschetta",
      img: "bruschetta",
      hasImage: true,
      description: "Cette recette de hors-d’œuvre toute simple est délicieuse et facile à préparer.",
      ingredients:
        "4 tomates moyennes, coupées en dés, épépinées |1 gousse d’ail, émincée|5 g (1/4 tasse) de basilic frais, haché|7,5 ml (1/2 c. à table) d’huile d’olive|1,25 ml (1/4 c. à thé ) de sel|1,23 ml (1/4 c. à thé) de poivre|8 à 10 tranches de pain italien frais",
      methods:
        "Dans un bol, mélanger les tomates, l’ail, le basilic, l’huile d’olive, le sel et le poivre. Laisser reposer pendant 10 minutes.|Faire griller les tranches de pain sur une plaque à pâtisserie.|Verser à la cuillère le mélange de tomates sur les tranches de pain et servir.",
      time: 15,
      serves: 8,
      fat: 2.0,
      saturated: 0.4,
      calories: 101,
      cholesterol: 0,
      carbohydrate: 18,
      protein: 3,
      sodium: 261,
      fiber: 2,
      difficulty: "facile",
      category: "appetizer|déjeuner|mets préférés de la famille|rapides et faciles",
    },
    {
      title: "Filets de poulet Buffalo",
      slug: "filets-de-poulet-buffalo",
      img: "buffalo-chicken-tenders",
      hasImage: true,
      description:
        "Cette recette est une version piquante et épicée, mais faible en matières grasses, de ce hors-d’œuvre réputé pour les jours de matchs. Servir avec votre vinaigrette sans gras préférée.",
      ingredients:
        "113 ml (1/2 tasse) de babeurre sans gras, en quantités divisées, ou 90 ml (1/3 tasse) de lait écrémé + 15 ml (1 c. à table) de vinaigre blanc|75 g (1/3 tasse) + 45 ml (3 c. à table) de sauce piquante, en quantités divisées|45 ml (3 c. à table) de vinaigre blanc|907 g (2 lb) de filets de poulet|90 ml (6 c. à table) de farine|90 ml (6 c. à table) de semoule de maïs|2,5 ml (1/2 c. à thé) de poivre de Cayenne|5 ml (1 c. à thé) de paprika|30 ml (2 c. à table) de crème sure, sans gras",
      methods:
        "Dans un grand bol, fouetter 56 ml (¼ tasse) de babeurre, 45 ml (3 c. à table) de sauce piquante et le vinaigre. Ajouter le poulet et mariner au réfrigérateur pendant au moins 30 minutes.|Mélanger la farine, la semoule de maïs, le poivre de Cayenne et le paprika dans un plat peu profond.|Préchauffer le four à 218°C (425°F). Retirer le poulet de la marinade et le remuer dans le mélange de semoule de maïs. |Vaporiser une grande plaque à pâtisserie recouverte de papier aluminium avec un enduit à cuisson antiadhésif et y déposer le poulet. |Cuire le poulet au four pendant 20 minutes, en le retournant délicatement après 10 minutes.|Dans un petit bol, préparer la sauce buffalo supplémentaire en mélangeant 56 ml (1/4 de tasse) de babeurre, 75 ml (1/3 de tasse) de sauce piquante et la crème sure sans gras ou le yogourt grec sans gras.|Une fois que le poulet est cuit, verser la quantité désirée de sauce buffalo sur celui-ci.|Servir avec des bâtonnets de carotte et de céleri.",
      time: 60,
      serves: 6,
      fat: 4.6,
      saturated: 1.0,
      calories: 259,
      cholesterol: 112,
      carbohydrate: 15,
      protein: 37,
      sodium: 612,
      fiber: 1,
      difficulty: "moyen",
      category: "déjeuner|dîner|mets préférés de la famille",
    },
    {
      title: "Ragoût de poulet",
      slug: "ragout-de-poulet",
      img: "chicken-stew",
      hasImage: true,
      description:
        "Ce ragoût réconfortant vous réchauffera lors des soirées froides. Il suffit de doubler les ingrédients de la recette pour nourrir un plus grand nombre de convives.",
      ingredients:
        "7,5 ml (1/2 c. à table) d’huile d’olive|25 g (1/2 tasse) de céleri, haché|25 g (1/2 tasse) de carottes, hachées|75 g (1/2 tasse) d’oignons, hachés |Sel et poivre au goût|1 boite de 396 g (14 oz) de tomates en dés|1 contenant de 414 ml (14 oz) de bouillon de poulet faible en sodium, sans gras |10 g (1/2 tasse) de feuilles de basilic frais, déchiquetées|15 ml (1 c. à table) de pâte de tomate|2,5 ml (1/2 c. à thé) de feuilles de thym|1 grosse pomme de terre, coupée en cubes|454 g (1 lb) de poitrines de poulet désossées, sans peau",
      methods:
        "Chauffer l’huile dans une grande casserole, à feu moyen et ajouter le céleri, les carottes et l’oignon.|Cuire de 5 à 10 minutes; ajouter du sel et du poivre.|Incorporer le reste des ingrédients, en ajoutant le poulet en dernier.|Porter à ébullition, puis laisser mijoter à découvert pendant 25 minutes ou jusqu’à ce que la température interne du poulet atteigne 73°C (165°F).|Retirer le poulet, le couper en petits morceaux; remettre le poulet en morceaux dans le ragoût et laisser mijoter pendant encore 5 minutes.|Servir avec du pain croûté.",
      time: 60,
      serves: 4,
      fat: 4.9,
      saturated: 0.9,
      calories: 233,
      cholesterol: 83,
      carbohydrate: 17,
      protein: 28,
      sodium: 521,
      fiber: 3,
      difficulty: "moyen",
      category: "dîner|mets préférés de la famille|hearty meal",
    },
    {
      title: "Boulettes de viande cocktail",
      slug: "boulettes-de-viande-cocktail",
      img: "cocktail-meatballs",
      hasImage: true,
      description:
        "Cette recette est une version faible en gras d'un hors d'œuvre très apprécié. Sucré, acidulé et délicieux!",
      ingredients:
        "454 g (1 livre) de bœuf haché « extra maigre » (au moins 95 % sans gras)|63 g (1/2 tasse) de chapelure assaisonnée|30 ml (2 c. à table) de lait écrémé|2 blancs d’œufs|45 ml (3 c. à table) d’oignon, émincé|226 g (8 oz) de sauce aux canneberges en gelée|175 ml (3/4 tasse) de sauce chili|7,5 ml (1½ c. à thé) de jus de citron|22,5 ml (1 ½ c. à table) de cassonade",
      methods:
        "Préchauffer le four à 176°C (350°F).|Dans un grand bol, mélanger le bœuf haché, la chapelure, le lait, les blancs d’œufs et l’oignon.|Former de petites boulettes (environ 3,8 cm/1,5 po) et les disposer sur une plaque à biscuits recouverte de papier d’aluminium antiadhésif.|Cuire au four pendant 20 à 25 minutes ou jusqu'à ce que la température interne des boulettes atteigne au moins 71°C (160°F); retourner les boulettes de viande à la mi-cuisson.|Dans une casserole, mélanger le reste des ingrédients et faire mijoter.|Ajouter les boulettes de viande et laisser mijoter pendant 30 minutes.|Gardez-les au chaud dans une mijoteuse et servez-les avec des cure-dents. |Donne 24 boulettes de viande.",
      time: 60,
      serves: 8,
      fat: 3.4,
      saturated: 1.4,
      calories: 190,
      cholesterol: 35,
      carbohydrate: 24,
      protein: 15,
      sodium: 505,
      fiber: 2,
      difficulty: "facile",
      category: "appetizer|mets préférés de la famille",
    },
    {
      title: "Poulet crémeux à l’estragon et à la moutarde",
      slug: "poulet-cremeux-a-lestragon-et-a-la-moutarde",
      img: "creamy-mustard-tarragon-chicken",
      hasImage: true,
      description:
        "Transformez la saveur de votre poulet avec cette sauce crémeuse.",
      ingredients:
        "4 demi-poitrines de poulet cuites, désossées et sans peau|5 g (1 c. à thé) de margarine molle |25 g (1/4 tasse) d’échalotes françaises, hachées finement|56 ml (¼ tasse) de bouillon de poulet faible en sodium, sans gras|76 g (⅓ tasse) de moutarde de Dijon (ou de moutarde à l’oignon)|113 ml (½ tasse) de lait écrémé évaporé|15 ml (1 c. à table) d’estragon frais, haché",
      methods:
        "Faire fondre la margarine dans une grande poêle à frire, à feu moyen-vif.|Ajouter les échalotes françaises et faire revenir pendant 1 à 2 minutes. |Ajouter le bouillon de poulet et la moutarde aux oignons caramélisés et porter à ébullition.|Ajouter peu à peu le lait écrémé évaporé et l'estragon. Remuer jusqu'à ce que le mélange épaississe légèrement.|Ajouter la poitrine de poulet cuite et bien chauffer.",
      serves: 4,
      fat: 5.6,
      saturated: 1.2,
      calories: 269,
      cholesterol: 125,
      carbohydrate: 9,
      protein: 41,
      sodium: 367,
      fiber: 0,
      difficulty: "facile",
      category: "dîner|mets préférés de la famille",
    },
    {
      title: "Poisson Fiesta facile à préparer",
      slug: "poisson-fiesta-facile-a-preparer",
      img: "easy-fiesta-fish",
      hasImage: true,
      description:
        "Une recette de poisson blanc à la fois simple et délicieuse. Utilisez les restes pour préparer des tacos au poisson.",
      ingredients:
        "1 petit poivron rouge, tranché finement|1 petit oignon, tranché finement|Enduit antiadhésif en vaporisateur|907 g (2 livres) de morue ou de poisson blanc maigre|250 ml (1 tasse) de salsa, sans gras|86 g (3/4 tasse) de cheddar à teneur réduite en gras Sargento",
      methods:
        "Préchauffer le four à 176°C (350°F).|Vaporiser une poêle à frire avec un enduit antiadhésif.|Faire revenir les oignons et les poivrons dans la poêle à frire pendant 5 à 10 minutes.|Mettre le poisson dans un grand plat de cuisson de 23 cm par 33 cm (9 po par 13 po), et ajouter le mélange d'oignons et de poivrons sur le dessus.|Répartir la salsa sur le poisson, puis parsemer de fromage.|Cuire au four pendant 30 à 35 minutes ou jusqu'à ce que le poisson se défasse facilement en morceaux. |Garnir de crème sure sans gras ou de yogourt grec sans gras.",
      time: 50,
      serves: 6,
      fat: 4.1,
      saturated: 2.2,
      calories: 201,
      cholesterol: 75,
      carbohydrate: 10,
      protein: 31,
      sodium: 177,
      fiber: 1,
      difficulty: "facile",
      category: "dîner|mets préférés de la famille|poisson",
    },
    {
      title: "Pain de viande facile à préparer",
      slug: "pain-de-viande-facile-a-preparer",
      img: "easy-meatloaf",
      hasImage: true,
      description:
        "Si le bœuf vous manque, essayez cette recette d'un des mets réconfortants les plus populaires aux États-Unis.",
      ingredients:
        "680 g (1 ½ livre) de bœuf haché « extra maigre » (au moins 95 % sans gras)|125 g (1 tasse) de chapelure nature |3 blancs d’œufs|225 ml (1 tasse) de lait écrémé|40 g (¼ tasse) d’oignons, coupés en dés|1,25 ml (1/4 c. à thé) de poivre|10 ml ( 2 c. à thé) de sauce Worcestershire|153 g (2/3 tasse) de ketchup |57 g (1/3 tasse) de cassonade|45 ml (3 c. à table) de moutarde",
      methods:
        "Préchauffer le four à 176°C (350°F).|Dans un grand bol, mélanger le bœuf haché, la chapelure, les blancs d’œufs, le lait, les oignons, le poivre et la sauce Worcestershire.|Dans un autre bol, mélanger le reste des ingrédients pour préparer la sauce.|Cuire le mélange de bœuf au four dans un moule à pain de 13 cm par 23 cm (5 po par 9 po) pendant 40 minutes, puis ajouter la moitié du mélange de sauce sur le dessus du pain.  |Cuire au four pendant 50 minutes de plus ou jusqu’à ce que la température interne de la viande atteigne au moins 71°C (160°F).|Servir le pain de viande avec le reste de la sauce en accompagnement.",
      time: 100,
      serves: 6,
      fat: 6.9,
      saturated: 3.0,
      calories: 317,
      cholesterol: 71,
      carbohydrate: 31,
      protein: 31,
      sodium: 513,
      fiber: 1,
      difficulty: "facile",
      category: "dîner|mets préférés de la famille|hearty meal",
    },
    {
      title: "Rôti de ronde servi avec des légumes",
      slug: "roti-de-ronde-servi-avec-des-legumes",
      img: "eye-round-roast-with-vegetables",
      hasImage: true,
      description:
        "Pour réussir cette recette, il est recommandé d’utiliser un thermomètre à viande afin d’éviter de trop cuire le rôti.",
      ingredients:
        "907 g (2 lb) de rosbif de noix de ronde|1 ou 2 gousses d’ail, émincées|1,25 ml (1/4 c. à thé) de basilic|1,25 ml (1/4 c. à thé) d’origan|0,62 ml (1/8 c. à thé) de thym|2,5 ml (1/2 c. à thé) de poudre de chili|2,5 ml (1/2 c. à thé) de sel|5 ml (1 c. à thé) de poivre|56 ml (1/4 tasse) de bouillon de bœuf faible en sodium, sans gras|2 branches de céleri, coupées en morceaux|3 carottes, coupées en morceaux|2 pommes de terre de grosseur moyenne, coupées en cubes|1 oignon, coupé en quartiers",
      methods:
        "Préchauffer le four à 190°C (375°F).|Dans un petit bol, mélanger l’ail, le basilic, l’origan, le thym, la poudre de chili, le sel et le poivre.|Déposer le rôti dans une rôtissoire peu profonde et le frotter avec le mélange d’épices.|Ajouter le bouillon de bœuf et les légumes autour du rôti.|Cuire environ 1 heure ou jusqu'au point de cuisson désiré. Une viande mi-saignante doit avoir une température interne d’environ 54°C (130°F).|Laisser le rôti reposer pendant 15 minutes avant de le trancher.",
      time: 90,
      serves: 8,
      fat: 3.6,
      saturated: 1.3,
      calories: 195,
      cholesterol: 68,
      carbohydrate: 13,
      protein: 28,
      sodium: 252,
      fiber: 2,
      difficulty: "moyen",
      category: "dîner|mets préférés de la famille|hearty meal",
    },
    {
      title: "Chaudrée de poisson",
      slug: "chaudree-de-poisson",
      img: "fish-chowder",
      hasImage: true,
      description:
        "Cette chaudrée chaude et savoureuse sera appréciée durant la saison froide. Servir avec du pain croûté ou des craquelins aux huîtres faibles en gras en accompagnement.",
      ingredients:
        "907 g (2 lb) (32 oz) de filets de morue ou d’aiglefin|56 g (2 oz) de bacon de dinde extra maigre, coupé en dés (environ 4 tranches) |10 ml (2 c. à thé) d’huile de colza|2 oignons de grosseur moyenne, tranchés|225 g (1 tasse) de céleri, coupé en morceaux|3 grosses pommes de terre, coupées en dés|1 feuille de laurier, émiettée|900 ml (4 tasses) de lait écrémé (essayer le lait écrémé ultra-filtré ou le lait écrémé évaporé pour une texture plus crémeuse!)|15 ml (1 c. à table) de margarine légère|Poivre, au goût",
      methods:
        "Laisser mijoter le poisson dans 450 ml (2 tasses) d’eau pendant 15 minutes. |Égoutter et réserver le bouillon. |Faire revenir le bacon de dinde dans de l’huile de colza dans un grand chaudron jusqu’à ce qu’il soit croustillant. |Ajouter les oignons et faire revenir jusqu’à ce qu’ils soient dorés.|Ajouter le poisson, le céleri, les pommes de terre et la feuille de laurier. |Mesurer la quantité de bouillon de poisson réservé et ajouter une quantité suffisante d’eau pour obtenir 675 ml (3 tasses) de liquide en tout. Ajouter au chaudron et laisser mijoter pendant 40 minutes.|Ajouter le lait et la margarine et laisser mijoter durant 5 minutes de plus. Ajouter du poivre au goût.",
      time: 80,
      serves: 8,
      fat: 3.5,
      saturated: 0.7,
      calories: 230,
      cholesterol: 56,
      carbohydrate: 22,
      protein: 27,
      sodium: 227,
      fiber: 2,
      difficulty: "moyen",
      category: "dîner |mets préférés de la famille|hearty meal|poisson",
    },
    {
      title: "Poitrine de poulet aux fines herbes avec chutney à la mangue",
      slug: "poitrine-de-poulet-aux-fines-herbes-avec-chutney-a-la-mangue",
      img: "herb-encrusted-chicken-breast-with-mango-chutney",
      hasImage: true,
      description:
        "Rehaussez la saveur de votre poulet grillé habituel avec cette savoureuse recette.",
      ingredients:
        "6 poitrines de poulet, environ 170 g (6 oz) chacune|Enduit à cuisson antiadhésif en vaporisateur|Sel et poivre|Moutarde de Dijon|Brins de romarin, de basilic et de persil frais (ou vos fines herbes préférées), émincés|130 g (3/4 tasse) de tomates, coupées en dés|130 g (3/4 tasse) de mangue, coupée en dés|90 g (1/2 tasse) de piments verts doux, coupés en dés|25 g (1/4 tasse) d’oignons verts, tranchés|30 ml (2 c. à table) de jus de lime|1,25 ml (1/4 c. à thé ) de sel|1,25 ml (1/4 c. à thé) de poivre noir moulu|0,62 ml (1/8 c. à thé) de poivre de Cayenne",
      methods:
        "Préchauffer le four à 176°C (350°F).|Assaisonner le poulet avec du sel et du poivre Vaporiser le gril intérieur ou le barbecue avec un enduit à cuisson antiadhésif et griller le poulet pendant environ 1 à 2 minutes, assez longtemps pour que la grille laisse ses marques.|Une fois le poulet grillé, le placer dans un grand plat de cuisson. |Étendre une fine couche de moutarde de Dijon sur le poulet, puis ajouter les fines herbes fraîches de votre choix.|Cuire au four jusqu’à ce que la température interne du poulet atteigne 73°C (165°F).|Mélanger les ingrédients du chutney à la mangue et le servir en garniture sur la poitrine de poulet.",
      serves: 6,
      fat: 4.5,
      saturated: 1.0,
      calories: 215,
      cholesterol: 124,
      carbohydrate: 2,
      protein: 39,
      sodium: 191,
      fiber: 0,
      difficulty: "moyen",
      category: "dîner|hearty meal",
    },
    {
      title: "Vinaigrette à la lime et au miel",
      slug: "vinaigrette-a-la-lime-et-au-miel",
      img: "honey-lime-dressing",
      hasImage: true,
      description:
        "Servez cette vinaigrette avec votre salade préférée. Personne ne se doutera qu'elle ne contient aucune matière grasse.",
      ingredients:
        "226 g (8 oz) de yogourt nature sans gras|226 g (8 oz) de vinaigre de champagne|15 ml (1 c. à table) de miel|7,5 ml (1 ½ c. à thé) de zeste de lime|2,5 ml (1/2 c. à thé) de poivre noir moulu",
      methods: "Fouetter les ingrédients ensemble. Donne 1 chopine (473 ml).",
      time: 5,
      serves: 16,
      fat: 0,
      saturated: 0,
      calories: 18,
      cholesterol: 0,
      carbohydrate: 2,
      protein: 1,
      sodium: 13,
      fiber: 0,
      difficulty: "facile",
      category: "déjeuner|rapides et faciles",
    },
    {
      title: "Pouding Betty aux pommes",
      slug: "pouding-betty-aux-pommes",
      img: "apple-brown-betty",
      hasImage: true,
      description:
        "Un doux parfum de cannelle envahira votre maison pendant la cuisson de cette recette à la mijoteuse facile à préparer!",
      ingredients:
        "6 tasses de pommes à cuire, pelées, évidées et coupées en huit quartiers (environ 5 pommes de grosseur moyenne)|125 g (1 tasse) de chapelure|5 ml (1 c. à thé) de cannelle moulue|5 ml (1 c. à thé) de muscade moulue|0,60 ml (1/8 c. à thé) de sel|128 g (3/4 tasse) de cassonade pâle, tassée|190 g (3/4 tasse) de yogourt grec nature|30 ml (2 c. à table) de beurre léger, fondu|29 g (1/4 tasse) de noix finement hachées|75 g (1/2 tasse) de crème glacée à la vanille sans gras|Ajoutez 75 g (1/2 tasse) de crème glacée sans gras si vous le souhaitez!",
      methods:
        "Vaporiser votre mijoteuse d'un enduit à cuisson antiadhésif. Mettre les pommes au fond de celle-ci.|Dans un bol de grosseur moyenne, mélanger la chapelure, la cannelle, la muscade, le sel, le sucre, le beurre, le yogourt grec et les noix de Grenoble. Répartir sur les pommes. Couvrir; cuire à feu DOUX durant 3 à 4 heures ou à feu VIF durant 2 heures.|Servir chaud avec de la crème glacée sans gras ou de la crème fouettée sans gras",
      time: 130,
      serves: 8,
      fat: 3.1,
      saturated: 0.5,
      calories: 170,
      cholesterol: 1,
      carbohydrate: 33,
      protein: 3,
      sodium: 99,
      fiber: 3,
      difficulty: "moyen",
      category: "dessert|mets préférés de la famille",
    },
    {
      title: "Brochettes de bœuf",
      slug: "brochettes-de-bœuf",
      img: "beef-kebabs",
      hasImage: true,
      description: "Un mets savoureux, idéal pour les repas d'été préparés sur le barbecue!",
      ingredients:
        "453 g (16 oz) de bifteck de surlonge maigre, haché|2 pommes de terre, coupées en cubes|16 tomates cerises|1 oignon rouge, tranché en rondelles|2 poivrons, tranchés|15 ml (1 c. à table) d’assaisonnement|8 brochettes|Enduit à cuisson antiadhésif",
      methods:
        "Éliminer tout gras visible de la tranche de surlonge maigre. Couper en cubes de 28 g (1 oz) (2,5 cm par 2,5 cm).|Laver les pommes de terre et les couper en cubes de 2,5 cm par 2,5 cm.|Trancher l’oignon en rondelles, puis en petits morceaux.|Enfiler 2 cubes de surlonge de 28 g sur chaque brochette en alternant avec 2 tomates cerises, 3 cubes de pommes de terre, 3 tranches de poivron et 3 rondelles d’oignon. Répéter cette étape 8 fois.|Disposer les brochettes préparées sur le gril ou dans un plat de cuisson. Ajouter l'assaisonnement Mrs. Dash.|Pour le gril : Vaporiser le gril d’un enduit à cuisson antiadhésif et faire griller jusqu’à ce que la viande soit à la cuisson désirée et que vous puissiez insérer une fourchette dans les pommes de terre.|Pour la cuisson au four : cuire à 218°C (425°F) pendant 30 minutes ou jusqu’à ce que le tout soit cuit.",
      serves: 4,
      fat: 6.9,
      saturated: 2.6,
      calories: 330,
      cholesterol: 93,
      carbohydrate: 28,
      protein: 38,
      sodium: 87,
      fiber: 4,
      difficulty: "moyen",
      category: "dîner|mets préférés de la famille",
    },
    {
      title: "Bagatelle aux petites baies",
      slug: "bagatelle-aux-petites-baies",
      img: "berry-trifle",
      hasImage: true,
      description: "Un dessert amusant et facile à apporter pour un pique-nique ou une rencontre entre amis.",
      ingredients:
        "283 g (10 oz) de gâteau des anges préparé, coupé en cubes|357 g (1 chopine) de fraises fraîches, tranchées|2 contenants de 170 g (6 oz) de bleuets frais |1 contenant de 340 g (12 oz) de crème fouettée sans gras",
      methods:
        "Superposer les ingrédients en commençant par les fraises tranchées, le gâteau, la crème fouettée Cool Whip et les bleuets, puis répéter les étapes.",
      time: 10,
      serves: 8,
      fat: 0.5,
      saturated: 0,
      calories: 189,
      cholesterol: 0,
      carbohydrate: 41,
      protein: 3,
      sodium: 290,
      fiber: 2,
      difficulty: "facile",
      category: "dessert|mets préférés de la famille",
    },
    {
      title: "Ragoût de viande maigre désossée",
      slug: "ragout-de-viande-maigre-desossee",
      img: "boneless-lean-stew",
      hasImage: true,
      description: "Un plat réconfortant pour les soirées froides.",
      ingredients:
        "15 ml (1 c. à table) d’huile d’olive ou d’huile végétale|567 g (1¼ lb) de viande à ragoût maigre|1 gousse d’ail|1 oignon moyen|1 poivron vert|5 ml (1 c. à thé) de curcuma|5 ml (1 c. à thé) de sauge|5 ml (1 c. à thé) de cumin|300 g (2 tasses) de petits pois (congelés ou en conserve)|454 g (16 oz) de carottes miniatures|4 pommes de terre de grosseur moyenne, épluchées et coupées en quartiers",
      methods:
        "Couvrir légèrement d'huile le fond d’un grand chaudron. Chauffer le rond de la cuisinière à feu doux.|Couper l’oignon et l’ail et les ajouter au chaudron. Cuire jusqu'à ce que les oignons soient tendres.|Ajouter le reste des ingrédients, sauf l’eau. Cuire pendant 5 minutes, en remuant de temps en temps, pour laisser réduire l'alcool.|Ajouter de l’eau (suffisamment pour recouvrir les autres ingrédients). Pour obtenir une sauce plus épaisse, ajouter de la farine, une ou deux cuillères à table à la fois. Couvrir et laisser mijoter pendant environ 30 minutes, à feu doux ou moyen.|Conseil : Ce ragoût est encore meilleur le lendemain ou le surlendemain, alors n'hésitez pas à le congeler.",
      time: 45,
      serves: 6,
      fat: 6,
      saturated: 2,
      calories: 312,
      cholesterol: 62,
      carbohydrate: 39,
      protein: 26,
      sodium: 143,
      fiber: 7,
      difficulty: "moyen",
      category: "dîner|mets préférés de la famille|hearty meal",
    },
    {
      title: "Salade de poulet",
      slug: "salade-de-poulet",
      img: "chicken-salad",
      hasImage: true,
      description: "Une version rapide et facile à préparer d'un populaire plat estival!",
      ingredients:
        "2 grosses boîtes de morceaux de poitrine de poulet blanc en conserve, égouttés et déchiquetés à la fourchette pour briser les morceaux|3 branches de céleri, coupées en morceaux|1/2 oignon de grosseur moyenne, haché|65 g (1/4 tasse) de yogourt grec nature, sans gras|0,62 ml (1/8 c. à thé) de sel|1,25 ml (1/4 c. à thé) de poivre noir moulu",
      methods:
        "Déchiqueter le poulet et le mettre dans un bol de grosseur moyenne.|Ajouter tous les autres ingrédients et mélanger.|Ajouts facultatifs d'aliments sans gras : pommes coupées en morceaux, raisins, relish sucrée ou ananas broyé",
      time: 10,
      serves: 6,
      fat: 0.1,
      saturated: 0,
      calories: 101,
      cholesterol: 0.5,
      carbohydrate: 6,
      protein: 16,
      sodium: 437,
      fiber: 2,
      difficulty: "facile",
      category: "déjeuner|rapides et faciles",
    },
    {
      title: "Pain doré à la mijoteuse pour le matin de Noël",
      slug: "pain-pour-le-matin-de-noel",
      img: "christmas-morning-crock-pot-french-toast",
      hasImage: true,
      description:
        "Un plat sucré à savourer n'importe quel matin. Laissez l'arôme de la cannelle se répandre dans la maison pendant que vous attendez que ce mets savoureux soit prêt à déguster!",
      ingredients:
        "1 œuf entier|3 blancs d’œufs|338 ml (1 ½ tasse) de lait écrémé|30 ml (2 c. à table) de miel|5 ml (1 c. à thé) d’extrait de vanille|2,5 ml (1/2 c. à thé) de cannelle|9 tranches de pain blanc|3 tasses de morceaux de pommes non cuites, finement coupés en dés (la pomme Gala est parfaite pour cette recette; 3 tasses représentent environ 3 pommes de grosseur moyenne)|45 ml (3 c. à table) de miel|5 ml (1 c. à thé) de jus de citron|2,5 ml (1/2 c. à thé) de cannelle",
      methods:
        "Vaporiser lintérieur de la mijoteuse d'un enduit à cuisson antiadhésif.|Mettre l’œuf, les blancs d’œufs, le lait, le miel, l’extrait de vanille et la cannelle dans un bol à mélanger de grosseur moyenne. Bien fouetter.|Couper les pommes en petits dés. |Mettre les morceaux de pommes, le miel, le jus de citron et la cannelle dans un petit bol à mélanger. Mélanger ces ingrédients de façon à bien enrober tous les morceaux de pommes. Mettre le bol de côté, le mélange sera votre « garniture ».|Couper les morceaux de pain en deux pour former un triangle. Disposer une couche de pain (environ 6 triangles) au fond de la mijoteuse. Ajouter le ¼ de la garniture et répéter jusqu’à ce qu’il y ait 3 couches. Ajouter le reste de la garniture sur le dessus.|Verser le mélange d’œufs sur le tout. Couvrir la mijoteuse et cuire à feu vif pendant 2 heures à 2,5 heures ou à feu doux pendant 4 heures.",
      time: 10,
      serves: 9,
      fat: 1.5,
      saturated: 0.4,
      calories: 164,
      cholesterol: 21,
      carbohydrate: 32,
      protein: 6,
      sodium: 167,
      fiber: 2,
      difficulty: "moyen",
      category: "petit déjeuner|mets préférés de la famille|christmas",
    },
    {
      title: "Muffins aux canneberges et à l’orange",
      slug: "muffins-aux-canneberges-et-a-lorange",
      img: "cranberry-orange-muffins",
      hasImage: true,
      ingredients:
        "280 g (2 tasses) de farine tout usage|133 g (2/3 tasse) de sucre|10 ml (2 c. à thé) de poudre à pâte|1,25 ml (1/4 c. à thé ) de sel|100 g (1 tasse) de canneberges fraîches hachées|150 ml (2/3 tasse) de lait 2%|Un contenant de 170 g (6 oz) de yogourt grec sans gras|15 ml (1 c. à table) d’huile de colza|5 ml (1 c. à thé) de zeste d’orange râpé|2,5 ml (1/2 c. à thé) d’extrait de vanille|2 blancs d’œufs|Enduit à cuisson",
      methods:
        "Préchauffer le four à 204 °C (400 °F) et vaporiser le moule à muffins d’enduit à cuisson|À l’aide d’une cuillère, verser la farine dans une tasse à mesurer et égaliser la quantité avec un couteau. |Mélanger la farine, le sucre, la poudre à pâte et le sel dans un grand bol et bien remuer. |Incorporer les canneberges et créer un puits au centre du mélange.|Mélanger le lait, la compote de pommes, le zeste, la vanille et les blancs d’œufs; ajouter à la préparation de farine en remuant jusqu’à ce que le mélange soit humide. |À l'aide d'une cuillère, répartir la pâte dans un moule de 12 muffins vaporisé avec un enduit à cuisson. Cuire au four à 204°C (400°F) pendant 18 minutes ou jusqu'à ce que les muffins reprennent leur forme lorsqu'on les touche légèrement au centre. Retirer immédiatement les muffins du moule, puis les disposer sur une grille métallique.",
      time: 25,
      serves: 12,
      fat: 1.4,
      saturated: 0.2,
      calories: 144,
      cholesterol: 1,
      carbohydrate: 28,
      sodium: 146,
      fiber: 1,
      difficulty: "facile",
      category: "collation|mets préférés de la famille|rapides et faciles",
    },
    {
      title: "Poulet crémeux aux champignons",
      slug: "poulet-cremeux-aux-champignons",
      img: "creamy-mushroom-chicken",
      hasImage: true,
      description:
        "Si vous recherchez un plat savoureux et crémeux, ne cherchez pas plus loin que cette recette étonnamment simple à réaliser.",
      ingredients:
        "680 g (1,5 lb) de poitrines de poulet, sans peau |Une boîte de 283 g (10 onces) de crème de champignons (sans gras, si possible) |250 g (1 tasse) de crème sure sans gras ou de yogourt grec sans gras |45 ml (3 c. à table) de xérès |Une boîte de 226 g (8 oz) de champignons tranchés, égouttés |150 g (1 tasse) de pois congelés, décongelés |1 petit pot de piments rouges, et si désiré, poivre noir, au goût |800 g (4 tasses) de riz brun, cuit",
      methods:
        "Préchauffer le four à 176 °C (350 °F)| Vaporiser un enduit à cuisson antiadhésif sur un plat de cuisson et disposer les poitrines de poulet.|Dans un petit bol, mélanger la crème de champignons, la crème sure ou le yogourt grec, les champignons et le xérès. Verser sur le poulet et couvrir.|Cuire au four pendant 50 à 65 minutes, jusqu’à ce que la sauce bouillonne.|Ajouter les pois décongelés et les piments rouges (si désiré) à la fin de la cuisson.|Laisser reposer 10 minutes.|Servir le poulet et la sauce sur une portion de 200 g (1 tasse) de riz brun, par personne.",
      time: 90,
      serves: 4,
      fat: 7.4,
      saturated: 1.6,
      calories: 558,
      cholesterol: 133,
      carbohydrate: 68,
      sodium: 723,
      fiber: 6,
      difficulty: "facile",
      category: "dîner|hearty meal",
    },
    {
      title: "Pain aux bananes et aux noix à la mijoteuse",
      slug: "pain-aux-bananes-et-aux-noix-a-la-mijoteuse",
      img: "crock-pot-banana-nut-bread",
      hasImage: true,
      description: "Un pain aux bananes et aux noix léger et moelleux qui plaira à toute la famille!",
      ingredients:
        "245 g (1 ¾ tasse) de farine tout usage|10 ml (2 c. à thé) de poudre à pâte|2,5 ml (1/2 c. à thé) de sel|1,25 ml (1/4 c. à thé) de bicarbonate de soude|56 g (1/4 tasse) de margarine légère|125 g (1/2 tasse) de yogourt grec nature|150 g (2/3 tasse) de sucre|1/2 tasse de blancs d’œufs (4 œufs)|30 ml (2 c. à table) de sirop de maïs foncé|3 bananes mûres, bien écrasées|125 g (1/4 tasse) de noix de Grenoble hachées|Cannelle, au goût",
      methods:
        "Graisser et enfariner la mijoteuse. Dans un petit bol, tamiser la farine, la poudre à pâte, le sel et le bicarbonate de soude. Réserver.|Dans un grand bol, battre en crème le beurre et le yogourt grec à l'aide d'un batteur électrique à vitesse moyenne-forte, jusqu'à ce que le mélange soit onctueux. Ajouter lentement le sucre, les œufs, le sirop de maïs et la purée de bananes. Battre jusqu’à l’obtention d’une consistance lisse. Ajouter graduellement la préparation de farine au mélange crémeux. Ajouter les noix de Grenoble et bien mélanger.| Verser dans la mijoteuse. Couvrir, cuire à feu VIF de 1 h 15 min à 1 h 30 min ou jusqu'à ce qu'un cure-dent inséré au centre du pain en ressorte propre.|Laisser refroidir, puis retourner le pain sur un plateau de service. Trancher et saupoudrer de cannelle, au goût.",
      time: 100,
      serves: 8,
      fat: 4.8,
      saturated: 0.7,
      calories: 269,
      cholesterol: 0,
      carbohydrate: 52,
      sodium: 357,
      fiber: 2,
      difficulty: "moyen",
      category: "collation|mets préférés de la famille",
    },
    {
      title: "Chaudrée de maïs à la mijoteuse",
      slug: "chaudree-de-mais-a-la-mijoteuser",
      img: "crock-pot-corn-chowder",
      hasImage: true,
      description:
        "Un repas chaud et copieux, riche en saveurs et facile à préparer! Saupoudrez de cheddar sans gras à la fin de la cuisson si désiré!",
      ingredients:
        "2 sacs de 283 g (10 oz) de grains de maïs congelés|4 tranches de bacon de dinde, coupées en morceaux|2 branches de céleri, coupées en dés|1 oignon jaune, haché|2 pommes de terre Russet, lavées et coupées en dés|4 boîtes de 340 g (12 oz) de lait évaporé, sans gras|2,5 ml (1/2 c. à thé) de sel|7,5 ml (1 ½ c. à thé) de poivre|15 ml (1 c. à table) de beurre, non salé|1 gousse d’ail, émincée",
      methods:
        "Couper le bacon, le céleri, l’oignon et les pommes de terre en petits morceaux.|Mettre tous les ingrédients dans la mijoteuse.|Cuire à feu vif pendant 4 heures ou à feu doux pendant 8 heures.",
      serves: 6,
      fat: 4.4,
      saturated: 1.7,
      calories: 390,
      cholesterol: 32,
      carbohydrate: 64,
      sodium: 642,
      fiber: 4,
      difficulty: "facile",
      category: "dîner|hearty meal",
    },
    {
      title: "Cannoli au chocolat faciles à préparer",
      slug: "cannoli-au-chocolat",
      img: "easy-chocolate-cannoli-treats",
      hasImage: true,
      description:
        "Une recette rapide, délicieuse et chocolatée! Un dessert idéal à apporter lors d'un événement spécial ou à offrir à vos convives lorsque vous recevez.",
      ingredients:
        "375 g (1 ½ tasse) de fromage ricotta, sans gras|50 g (1/4 tasse) de sucre à glacer |2,5 ml (½ c. à thé) d’extrait de vanille|36 gaufrettes au chocolat|18 grosses fraises, coupées en deux|88 g (1/4 tasse) de sirop au chocolat, sans gras",
      methods:
        "Mettre le fromage ricotta, le sucre à glacer et l’extrait de vanille dans un grand bol. Mélanger jusqu’à l’obtention d’une consistance légère et crémeuse. Vous pouvez également utiliser un robot culinaire.|Déposer 1 c. à thé du mélange de fromage au centre de chaque biscuit. Placer une demi-fraise sur le fromage et presser. |Disposer dans une assiette de service et napper les biscuits de sirop au chocolat.|Réfrigérer les biscuits restants.",
      serves: 18,
      fat: 1.8,
      saturated: 0.5,
      calories: 88,
      cholesterol: 5,
      carbohydrate: 15,
      sodium: 133,
      fiber: 1,
      difficulty: "facile",
      category: "dessert|mets préférés de la famille|rapides et faciles",
    },
    {
      title: "Croûte de tarte facile à préparer",
      slug: "croute-de-tarte-facile-a-preparer",
      img: "easy-pie-crust",
      hasImage: true,
      description: "Un fond de tarte savoureux et facile à préparer pour toutes vos tartes préférées!",
      ingredients:
        "187 g (1⅓  tasse) de farine blanche|1 contenant (environ 150 g ou 5,3 oz) de yogourt grec nature|2,5 ml (½ c. à thé) d’huile d’olive|60 ml (4 c. à table) d’eau froide|5 ml (1 c. à thé) de cannelle",
      methods:
        "Mélanger la farine, le yogourt grec et l’huile jusqu’à ce que la texture soit friable.|Ajouter l’eau froide et remuer pour bien mélanger le tout (ne pas trop remuer).|Pétrir la pâte 6 à 8 fois.|Abaisser sur une surface enfarinée jusqu’à ce que la pâte s'adapte au moule à tarte.|Saupoudrer la cannelle sur la pâte abaissée.|Cuire au four à 218°C (425°F) pendant 15 à 20 minutes.",
      time: 30,
      serves: 8,
      fat: 0.6,
      saturated: 0.1,
      calories: 91,
      cholesterol: 1,
      carbohydrate: 17,
      sodium: 8,
      fiber: 1,
      difficulty: "facile",
      category: "dessert|mets préférés de la famille|rapides et faciles",
    },
    {
      title: "Salade de pâtes et d’épinards facile à préparer",
      slug: "salade-de-pates-et-depinards",
      img: "easy-spinach-pasta-salad",
      hasImage: true,
      ingredients:
        "175 g (1 tasse) de tomates séchées au soleil coupées en morceaux, humides, mais sans huile|411 g (14,5 oz) de farfalles|1,4 kg (6 tasses) de jeunes épinards|1 boîte de haricots cannellini|165 ml (2/3 tasse) de vinaigrette italienne, sans gras|2 gousses d’ail, écrasées|50 g (1/2 tasse) de parmesan sans gras|Poivre ou piment rouge broyé (facultatif)",
      methods:
        "Cuire les pâtes selon les indications de l'emballage. Égoutter et rincer à l’eau froide, puis mettre dans un grand bol.|Ajouter le reste des ingrédients et bien mélanger.|Couvrir et mettre au réfrigérateur pendant 2 heures avant de servir.",
      serves: 12,
      fat: 1.1,
      saturated: 0,
      calories: 214,
      cholesterol: 1,
      sodium: 255,
      fiber: 4,
      difficulty: "facile",
      category: "déjeuner|dîner|mets préférés de la famille",
    },
    {
      title: "Petits pains sans gras",
      slug: "petits-pains-sans-gras",
      img: "fat-free-biscuits",
      hasImage: true,
      description:
        "L’utilisation du yogourt sans gras au lieu du beurre et du lait permet d’obtenir malgré tout une pâte qui lève bien et qui a une belle texture.",
      ingredients:
        "280 g (2 tasses) de farine tout usage|15 ml (1 c. à table) de poudre à pâte|2,5 ml (½ c. à thé) de bicarbonate de soude|5 ml (1 c. à thé) de sel|315 g (1 ¼ tasse) de yogourt nature, sans gras",
      methods:
        "Préchauffer le four à 190°C (375°F).|Vaporiser une plaque à biscuits avec un enduit à cuisson antiadhésif.|Mélanger la farine, la poudre à pâte, le bicarbonate de soude et le sel. Bien mélanger.|Ajouter le yogourt sans gras. Remuer le mélange de farine et de yogourt.|Cette recette devrait donner 8 petits pains. Déposer la pâte à la cuillère ou étaler la pâte sur une surface enfarinée et l’abaisser jusqu’à ce que l’épaisseur soit d'environ 1,9 cm (3/4 de po). Découper avec un emporte-pièce rond enfariné.|Cuire au four jusqu’à ce que les petits pains soient dorés, environ 12 minutes.",
      time: 20,
      serves: 8,
      fat: 0,
      saturated: 0,
      calories: 133,
      cholesterol: 1,
      carbohydrate: 27,
      protein: 5,
      sodium: 908,
      fiber: 1,
      difficulty: "facile",
      category: "collation|fat free|mets préférés de la famille|rapides et faciles",
    },
    {
      title: "Biscuits à l’avoine sans gras",
      slug: "biscuits-a-lavoine-sans-gras",
      img: "fat-free-oatmeal-cookies",
      hasImage: true,
      ingredients:
        "140 g (1 tasse) de flocons d'avoine secs |140 g (1 tasse) de farine |2,5 ml (1/2 c. à thé) de sel |2,5 ml (1/2 c. à thé) de bicarbonate de soude |2,5 ml (1/2 c. à thé) d’extrait de vanille |56 g (1/4 tasse) de sucre granulé |85 g (1/2 tasse) de cassonade |40 g (1/4 tasse) de raisins secs |250 g (1 tasse) de compote de pommes |1 blanc d’œuf |30 ml (2 c. à table) de lait de soya",
      methods:
        "Préchauffer le four à 190°C (375°F).|Mélanger tous les ingrédients secs avec les raisins secs.|Ajouter la compote de pommes, le blanc d’œuf et le lait de soya aux ingrédients secs.|Mettre sur une plaque à biscuits antiadhésive.|Cuire au four durant 12 à 15 minutes",
      time: 25,
      serves: 12,
      fat: 0.6,
      saturated: 0.1,
      calories: 122,
      cholesterol: 0,
      carbohydrate: 27,
      protein: 3,
      sodium: 158,
      fiber: 1,
      difficulty: "facile",
      category: "collation|fat free|mets préférés de la famille|rapides et faciles",
    },
    {
      title: "Tarte feuilletée",
      slug: "tarte-feuilletee",
      img: "fluffy-pie",
      hasImage: true,
      ingredients:
        "45 ml à 60 ml (3 à 4 c. à table) de miel|5 feuilles de biscuits Graham au miel, faible en gras. Mettre dans un sac en plastique et écraser avec une cuillère pour obtenir 125 g (1 tasse) de chapelure.|113 ml (1/2 tasse) d’eau bouillante|1 petit sachet de gélatine au citron (ou à la fraise ou à la cerise)|440 g (1 ¾ tasse) de yogourt au citron sans gras (ou à la fraise ou à la cerise)|360 ml (1 ½ tasse) de garniture fouettée sans gras",
      methods:
        "Préchauffer le four à 176°C (350°F).|Préparer la croûte en mélangeant le miel et la chapelure de biscuits Graham. Presser au fond d’un moule à tarte de 24 cm (9 ½ po), vaporisé avec un enduit à cuisson antiadhésif. Cuire au four pendant 7 minutes. (Le miel brunit rapidement... ne pas trop cuire). Laisser refroidir avant d’ajouter la garniture.|Pour préparer la garniture, verser l'eau bouillante dans un grand bol. Répandre la gélatine dans l'eau et fouetter pendant 3 minutes ou jusqu'à ce qu’elle soit complètement dissoute. Mettre le mélange de côté pendant 20 minutes pour le laisser refroidir à la température ambiante.|Lorsque la gélatine est refroidie, incorporer le yogourt avec un fouet. Réfrigérer pendant 10 minutes. Remuer le mélange qui devrait maintenant avoir la consistance d’un pouding. |Lorsque le mélange de gélatine a atteint la consistance voulue, bien remuer. Incorporer délicatement la garniture fouettée. Répartir le mélange à la cuillère sur le fond de tarte. Réfrigérer pendant au moins 3 heures avant de couper la tarte.",
      serves: 8,
      fat: 2.1,
      saturated: 1.5,
      calories: 169,
      cholesterol: 0,
      carbohydrate: 31,
      protein: 3,
      sodium: 152,
      fiber: 0,
      difficulty: "moyen",
      category: "dessert|mets préférés de la famille",
    },
    {
      title: "Pommes de terre rissolées",
      slug: "pommes-de-terre-rissolees",
      img: "hashbrown-potatoes",
      hasImage: true,
      description:
        "Facile à préparer, ce plat est riche en saveurs et peut être adapté à votre goût avec des assaisonnements et divers légumes.",
      ingredients:
        "4 pommes de terre de grosseur moyenne |1/2 oignon de grosseur moyenne |1 courgette (facultatif) |Ciboulette |Sel au goût |Poivre, au goût |Yogourt grec nature sans gras (nous avons utilisé du yogourt grec Fage, 0 %)|Compote de pommes",
      methods:
        "Râper les pommes de terre et l’oignon. Ajouter la courgette râpée, si désiré.|Mettre le mélange dans un linge à vaisselle et tordre pour essorer autant que possible le surplus de liquide. |Assaisonner le mélange tel que désiré en y ajoutant de la ciboulette haché, du sel et du poivre au goût.|Vaporiser une poêle à frire avec un enduit à cuisson. |Chauffer la poêle, puis ajouter le mélange pour en faire une grande crêpe. Appuyer légèrement sur le mélange avec une spatule pour répartir uniformément les ingrédients dans la poêle.|Laisser le mélange brunir, puis retourner délicatement la crêpe et faire dorer l’autre côté. |Si désiré, placer la crêpe sous le gril en la surveillant attentivement jusqu’à ce qu'elle soit aussi croustillante que souhaité.|Ajouter les garnitures de votre choix à la crêpe : compote de pommes, yogourt grec sans gras.|Savourez!",
      serves: 4,
      fat: 0.3,
      saturated: 0,
      calories: 167,
      cholesterol: 0,
      carbohydrate: 38,
      protein: 4,
      sodium: 15,
      fiber: 3,
      difficulty: "facile",
      category: "collations légères|mets préférés de la famille",
    },
    {
      title: "Pâte à pizza maison",
      slug: "pate-a-pizza-maison",
      img: "homemade-pizza-dough",
      hasImage: true,
      ingredients:
        "1 sachet de levure sèche active|225 ml (1 tasse) d’eau tiède|15 ml (1 c. à table) de miel|340 g (2 ½ tasses) de farine à pain (remarque : la farine à pain donne une croûte plus croustillante, tandis que la farine tout usage donne une croûte plus moelleuse)|2,5 ml (1/2 c. à thé) de sel",
      methods:
        "Dissoudre la levure et le miel dans de l'eau tiède. Laisser reposer pendant 10 minutes jusqu'à l’obtention d’un mélange mousseux.|Mélanger la farine et le sel dans un bol et ajouter graduellement le mélange de levure. Former une boule de pâte. Pétrir la pâte pendant 5 minutes, puis la mettre dans un bol légèrement enfariné. Couvrir d'une pellicule de plastique et laisser reposer pendant une heure ou jusqu'à ce que la pâte double de volume. |Préchauffer le four à 232°C (450°F).|Retirer la pâte du bol et la frapper avec le poing. Abaisser la pâte de façon à obtenir un cercle de 30 cm (12 po). Déposer sur une pierre à four ou une plaque de cuisson et ajouter vos garnitures préférées. Cuire pendant 10 à 13 minutes ou jusqu’à ce que la croûte soit brun doré.",
      time: 25,
      serves: 8,
      fat: 0.8,
      saturated: 0,
      calories: 165,
      cholesterol: 0,
      carbohydrate: 34,
      protein: 6,
      sodium: 147,
      fiber: 1,
      difficulty: "facile",
      category: "dîner|mets préférés de la famille|rapides et faciles",
    },
    {
      title: "Vinaigrette à la moutarde de Dijon et au miel",
      slug: "vinaigrette-a-la-moutarde-de-dijon-et-au-miel",
      img: "honey-dijon-mustard-dressing",
      hasImage: true,
      description:
        "Cette vinaigrette toute simple vous permet de créer des variétés originales en utilisant différents types de moutarde et de vinaigre, et en remplaçant le miel par de la confiture ou de la gelée.",
      ingredients:
        "30 ml (2 c. à table) de moutarde de type Dijon|30 ml (2 c. à table) de vinaigre de vin rouge|15 ml (1 c. à table) de miel|Poivre noir fraîchement moulu, au goût",
      methods: "Mélanger les ingrédients dans un bol et fouetter jusqu’à ce que le mélange soit homogène.",
      time: 10,
      serves: 2,
      fat: 0,
      saturated: 0,
      calories: 35,
      cholesterol: 0,
      carbohydrate: 9,
      protein: 0,
      sodium: 347,
      fiber: 0,
      difficulty: "facile",
      category: "rapides et faciles",
    },
    {
      title: "Ragoût Hoppin’ John",
      slug: "ragout-hoppin-john",
      img: "hoppin-john",
      hasImage: true,
      description:
        "Ce mets savoureux est servi au Nouvel An dans le Sud des États-Unis, où il est considéré comme un porte-bonheur s’il est dégusté le premier de l'An.",
      ingredients:
        "450 g (3 tasses) de haricots à œil noir secs, triés|900 ml (4 tasses) de bouillon de poulet, faible en sodium, sans gras|1 oignon jaune de grosseur moyenne, haché|7,5 ml (1 ½ c. à thé) d’ail frais, écrasé|7,5 ml (1 ½ c. à thé) de sauge, séchée|226 g (8 oz) de bacon canadien, coupé en morceaux|1,25 ml (1/4 c. à thé) de poivre noir moulu|1,25 ml (1/4 c. à thé) de poivre de Cayenne, moulu|3 piments jalapeños, hachés (facultatif)|Une boite de 411 g (14,5 oz) de tomates, coupées en petits dés (facultatif)",
      methods:
        "Mettre les haricots secs à œil noir dans un chaudron de 4,5 litres et ajouter 2,4 litres d’eau. Couvrir et laisser tremper pendant 4 heures. |Juste avant la cuisson, égoutter l'eau de trempage et remettre les haricots dans le chaudron.|Ajouter tous les autres ingrédients au chaudron, sauf les piments jalapeños et les tomates, et porter le mélange à ébullition à feu vif. Réduire la température et couvrir pour laisser mijoter pendant 30 minutes. |Si désiré, ajouter les piments jalapeños et les tomates, et poursuivre la cuisson pendant 15 à 20 minutes ou jusqu'à ce que les haricots soient tendres et que le liquide ait épaissi. Remuer de temps en temps et ajouter plus d’eau au besoin. (Le liquide devrait à peine couvrir les haricots).|Servir chaud sur du riz brun ou manger tel quel. Si vous aimez les mets très épicés, ajoutez de la sauce Tabasco et savourez!",
      serves: 9,
      fat: 1.3,
      saturated: 0.5,
      calories: 96,
      cholesterol: 14,
      carbohydrate: 14,
      protein: 7,
      sodium: 370,
      fiber: 3,
      difficulty: "moyen",
      category: "dîner|christmas",
    },
    {
      title: "Crêpes légères et minces",
      slug: "crepes-legeres-et-minces",
      img: "light-and-low-pancakes",
      hasImage: true,
      description:
        "Ces délicieuses crêpes faible en gras se prêtent aussi bien aux garnitures salées que sucrées.",
      ingredients:
        "125 g (½ tasse) de farine brune ou de farine tout usage de blé entier|1 œuf|300 ml (8 oz) de lait écrémé (un peu plus si vous utilisez de la farine de blé entier)|5 ml (1 c. à thé) d’huile végétale et une petite quantité pour la cuisson ou utiliser un mélange d'huile et d'eau en vaporisateur",
      methods:
        "Tamiser la farine dans un bol. Si vous utilisez de la farine de blé entier, ajouter également le son du tamis à la farine dans le bol après l'avoir tamisée.|Battre l'œuf, le lait et l'huile ensemble, puis ajouter lentement ce mélange à la farine. Remuer le mélange jusqu'à la formation d'une pâte lisse.|Laisser reposer pendant environ 20 minutes, puis remuer à nouveau.|Chauffer un peu d’huile dans une poêle à frire antiadhésive ou la vaporiser avec un mélange d'huile et d'eau. Lorsque l’huile est chaude, ajouter 30 ml (2 c. à table) de la préparation pour crêpes et agiter la poêle pour bien la répandre.|Cuire la crêpe pendant 2 minutes jusqu'à ce que le dessous soit légèrement bruni, puis la retourner et cuire l'autre côté pendant environ une minute.|Garder la crêpe au chaud dans le four pendant que vous cuisinez le reste de la préparation. Vous pouvez empiler les crêpes les unes sur les autres au fur et à mesure qu'elles sont cuites. La préparation devrait donner un total de 8 crêpes. Servir avec la garniture de votre choix.",
      time: 30,
      serves: 4,
      fat: 3.1,
      saturated: 0.6,
      calories: 162,
      sodium: 140,
      difficulty: "facile",
      heartuk: true,
      category:
        "petit déjeuner|mets préférés de la famille|collations légères|rapides et faciles|végétarien",
    },
    {
      title: "Gruau crémeux aux baies d'été",
      slug: "gruau-cremeux-aux-baies-dete",
      img: "creamy-porridge-with-summer-berries",
      hasImage: true,
      description:
        "Commencez votre journée avec ce déjeuner à la fois nutritif et délicieux. L'avoine est une excellente source de fibres solubles et les petites baies comptent pour votre objectif de cinq portions de fruits par jour. Tous les fruits, qu’ils soient frais, congelés, en conserve ou séchés, comptent dans ce calcul.",
      ingredients:
        "600 ml (20 oz) d’eau|125 g (1 tasse) de gruau d'avoine|150 ml (5 oz) de lait écrémé|1 poignée de baies fraîches, congelées et décongelées, en conserve ou cuites, comme des framboises, des fraises, des mûres ou des canneberges",
      methods:
        "Mettre l’eau et l’avoine dans une casserole et porter à ébullition. Laisser mijoter pendant 10 à 20 minutes, en remuant de temps en temps.|Ajouter le lait écrémé, remuer et laisser mijoter quelques minutes de plus.|Servir avec les baies de votre choix.",
      time: 5,
      serves: 2,
      fat: 5.4,
      saturated: 0.9,
      sodium: 90,
      calories: 296,
      difficulty: "facile",
      heartuk: true,
      category:
        "petit déjeuner|mets préférés de la famille|collations légères|rapides et faciles|végétarien",
    },
    {
      title: "Soupe aux carottes et à la coriandre",
      slug: "soupe-aux-carottes-et-a-la-coriandre",
      img: "carrot-and-coriander-soup",
      hasImage: true,
      description:
        "Parfait pour un souper super rapide ou un dîner léger pour une personne.",
      ingredients:
        "15 ml (1 c. à table) d'huile de tournesol ou d'huile de colza|1 gousse d’ail, écrasée|1 oignon, haché|2 carottes moyennes, râpées|2 bâtonnets de céleri, coupés en morceaux|2 tomates, coupées en morceaux|600 ml (20 oz) de bouillon de légumes à faible teneur en sel|300 ml (10 oz) de jus d’orange|45 ml (3 c. à table) de coriandre fraîchement hachée|poivre noir fraîchement moulu pour assaisonner",
      methods:
        "Chauffer l’huile, ajouter l’ail, l’oignon, les carottes, le céleri et les tomates et cuire quelques minutes jusqu’à ce que les légumes soient tendres.|Ajouter le bouillon, assaisonner et laisser mijoter pendant 20 à 25 minutes.|Ajouter la coriandre et le jus d’orange.|Réduire en purée dans un mélangeur au mode intermittent, réchauffer, vérifier l'assaisonnement et servir.|Servir avec des morceaux de pain de grains entiers.",
      time: 40,
      serves: 4,
      fat: 3.6,
      saturated: 0.3,
      calories: 109,
      sodium: 110,
      difficulty: "facile",
      heartuk: true,
      category: "déjeuner|collations légères|végétarien",
    },
    {
      title: "Soupe méditerranéenne",
      slug: "soupe-mediterraneenne",
      img: "mediterranean-soup",
      hasImage: true,
      description:
        "Cette recette est une de nos préférées. Elle est idéale pour cuisiner les restes de courgettes et de poivrons. Savourez! Chaque portion contient au moins 2 de vos 5 portions quotidiennes de légumes.",
      ingredients:
        "1 oignon, haché finement |1 gousse d’ail, écrasée |15 ml (1 c. à table) d’huile d’olive |1 poivron (rouge ou jaune), coupé en dés |2 courgettes, coupées en dés |5 ml (1 c. à thé) de paprika |5 ml (1 c. à thé) de romarin frais, haché |5 ml (1 c. à thé) de vinaigre balsamique |Une boite de 400 g (14 oz) de tomates, coupées en morceaux ou en purée |1 litre (4 tasses) de bouillon de légumes préparé avec 2 cubes de bouillon à faible teneur en sel |15 ml (1 c. à table) de purée de tomates|1 brin de persil plat frais (facultatif) |Poivre fraîchement moulu",
      methods:
        "Chauffer l'huile dans une poêle et cuire lentement l'oignon et l'ail pendant 5 minutes sans les laisser brunir. |Réserver 15 ml (1 c. à table) de poivrons coupés en dés. Mettre le reste des poivrons avec les courgettes, le paprika et le romarin dans la poêle et cuire pendant quelques minutes. |Ajouter le vinaigre balsamique et laisser mijoter 2 minutes. Verser ensuite les tomates et le bouillon et porter le mélange à ébullition. |Par la suite, laisser mijoter à feu doux jusqu'à ce que les légumes soient bien cuits et tendres, environ 10 minutes.|Enfin, incorporer la pâte de tomates et bien remuer. Servir la soupe comme un bouillon de légumes de style rustique avec des morceaux de légumes ou utiliser un mélangeur à main pour donner à la soupe une consistance onctueuse.|Assaisonner de poivre fraîchement moulu et garnir avec le poivron coupé en dés réservé et un brin de persil.",
      time: 35,
      serves: 4,
      fat: 4.1,
      saturated: 0.6,
      sodium: 220,
      calories: 122,
      difficulty: "facile",
      heartuk: true,
      category: "déjeuner|collations légères|végétarien|soup",
    },
    {
      title: "Savoureux sandwichs au thon",
      slug: "savoureux-sandwichs-au-thon",
      img: "tasty-tuna-sandwiches",
      hasImage: true,
      description:
        "Cette option plus légère de la recette de « mayo au thon » classique est idéale pour les sandwichs des pauses-repas.",
      ingredients:
        "1 boîte de thon dans de l’eau de source, égoutté |30 ml (2 c. à table) de yogourt grec faible en gras |15 ml (1 c. à table) de coriandre fraîche, hachée |Poivre noir moulu |4 tranches de pain de grains entiers ou 2 petits pains |1/4 concombre, coupé en dés|1/4 poivron rouge, coupé en dés|quelques feuilles de laitue fraîche|Ajouter un peu de mayonnaise faible en gras, si nécessaire.",
      methods:
        "Mélanger le yogourt et la coriandre et assaisonner de poivre noir. Ajouter le thon défait en morceaux, le concombre et le poivron rouge, coupés en dés. |Tartiner les 2 tranches de pain d’un peu de mayonnaise faible en gras (pour que le mélange puisse adhérer au pain), puis répartir le mélange de thon sur les tranches.|Ajouter quelques feuilles de laitue et les deux autres tranches de pain sur le mélange et servir.",
      time: 5,
      serves: 2,
      fat: 4.4,
      saturated: 0.7,
      sodium: 98,
      calories: 282,
      difficulty: "facile",
      heartuk: true,
      category: "déjeuner|collations légères",
    },
    {
      title: "Soupe aux champignons et aux lentilles",
      slug: "soupe-aux-champignons-et-aux-lentilles",
      img: "mushroom-and-lentil-soup",
      hasImage: true,
      description:
        "Vous pouvez utiliser diverses variétés de champignons pour cette recette ou vous en tenir aux champignons les moins coûteux – quel que soit votre choix, vous obtiendrez toujours une soupe savoureuse. Nous avons utilisé un bouillon de légumes de bonne qualité, mais vous pouvez utiliser votre propre bouillon maison ou même remplacer 250 ml de bouillon de légumes par de la bière brune.",
      ingredients:
        "15 ml (1 c. à table) d’huile d’olive |2 petits oignons, hachés grossièrement|2 branches de céleri, hachées grossièrement|1 carotte, hachée grossièrement|400 g (4 tasses) de champignons, tranchés grossièrement|100 g (1 tasse) de lentilles vertes, précuites dans de l’eau bouillante|1 litre (4 tasses) de bouillon de légumes préparé avec 2 cubes de bouillon de légumes à faible teneur en sel|1 feuille de laurier |15 ml (1 c. à table) de sauce Worcestershire|Poivre noir moulu|Servir avec 20 ml (4 c. à thé) de noix de pin grillées (facultatif)  |Servir avec 20 ml (4 c. à thé) de yogourt au soya (facultatif) ",
      methods:
        "Faire suer les oignons dans un poêlon avec l'huile d'olive pendant quelques minutes, sans les faire brunir. |Ajouter le céleri et la carotte, continuer à remuer et à cuire quelques minutes à feu doux. |Réserver quelques tranches de champignons pour la garniture. Ajouter le reste des champignons, les lentilles cuites, le bouillon de légumes, la sauce Worcestershire et la feuille de laurier dans le poêlon et laisser mijoter jusqu’à ce que tous les légumes soient cuits et tendres. |Retirer la feuille de laurier et à l’aide d’un mélangeur à main, réduire la soupe en purée |Assaisonner de poivre noir et servir la soupe garnie des champignons tranchés, de noix de pin et de yogourt.",
      time: 30,
      serves: 4,
      fat: 4.6,
      saturated: 0.7,
      sodium: 280,
      calories: 161,
      difficulty: "facile",
      heartuk: true,
      category: "déjeuner|collations légères|végétarien|soup",
    },
    {
      title: "Soupe repas rouge",
      slug: "soupe-repas-rouge",
      img: "red-super-soup",
      hasImage: true,
      description:
        "Cette soupe est le fruit d’une agréable conversation entre les membres de l’équipe du centre d’assistance téléphonique. Le bouillon à base de tomates, de lentilles, d’oignons et d’ail peut être rehaussé avec d’autres ingrédients que vous pouvez ajouter selon vos goûts. La soupe comprend tous les « légumes rouges » restants de la saison et donne un potage d’un rouge profond remarquable et plutôt savoureux. Servez-la en version plus consistante en laissant des morceaux de légumes dans le bouillon ou réduisez-la en purée pour obtenir une soupe onctueuse en fonction des goûts de vos convives!",
      ingredients:
        "15 ml (1 c. à table) d’huile d’olive |2 petits oignons rouges, hachés grossièrement |2 gousses d’ail, écrasées |1 branche de céleri, hachée grossièrement |1 carotte, hachée grossièrement |250 g (2 tasses) de chou rouge, râpé finement |50 g (1/3 tasse) de poivron rouge, haché grossièrement |100 g (1 tasse) de lentilles rouges, précuites dans de l’eau bouillante |2 boîtes de 794 g (28 oz) de tomates coupées en morceaux (en purée, si vous ne souhaitez pas passer la soupe au mélangeur avant de la servir)|1/2 litre (2 tasses) de bouillon de légumes préparé à partir de 1 ½ cubes de bouillon à faible teneur en sel|15 ml (1 c. à table) de vinaigre balsamique |Poivre noir moulu |Persil plat, pour garnir (facultatif)",
      methods:
        "Faire suer les oignons dans un poêlon avec l'huile d'olive pendant quelques minutes, sans les faire brunir. |Ajouter le céleri et la carotte, continuer à remuer et à cuire quelques minutes à feu doux. |Réserver quelques tranches de champignons pour la garniture. Ajouter le reste des champignons, les lentilles cuites, le bouillon de légumes, la sauce Worcestershire et la feuille de laurier dans le poêlon et laisser mijoter jusqu’à ce que tous les légumes soient cuits et tendres. |Retirer la feuille de laurier et à l’aide d’un mélangeur à main, réduire la soupe en purée |Assaisonner de poivre noir et servir garnie des champignons tranchés réservés, de noix de pin et de yogourt, si désiré",
      time: 30,
      serves: 4,
      fat: 4.7,
      calories: 230,
      saturated: 0.6,
      sodium: 140,
      difficulty: "facile",
      heartuk: true,
      category: "déjeuner|collations légères|végétarien|soup",
    },
    {
      title: "Plat de haricots mungo cassés (mung dhal) et épinards",
      slug: "plat-de-haricots-mungo-casses-et-epinards",
      img: "mung-dhal-with-spinach",
      hasImage: true,
      description:
        "Des ingrédients simples et sains pour une savoureux plat santé à base de haricots cassés (le mot dhal en langue hindi signifie « cassé »). Préparer en une seule fois et congeler les restes.",
      ingredients:
        "150 g (1 tasse) de haricots mungo (cassés ou décortiqués)|100 g (1 tasse) de pommes de terre nouvelles, coupées en deux et bouillies jusqu’à ce qu’elles soient tendres |250 g (2 tasses) d’épinards, lavés et hachés finement |15 ml (1 c. à table) d’huile de colza |5 ml (1 c. à thé) de gingembre frais, haché |1 ou 2 piments verts, hachés |2,5 ml (1/2 c. à thé) de graines de cumin |2,5 ml (1/2 c. à thé) de curcuma |Jus d’une demi-lime  ",
      methods:
        "Rincer les haricots plusieurs fois sous l’eau. |Chauffer l’huile dans une casserole et faire revenir le gingembre, les piments chilis verts et le cumin pendant 1 minute. |Ajouter les haricots et les épinards et cuire pendant 1 ou 2 minutes de plus. |Ajouter le curcuma et 1,5 l d’eau. |Cuire à découvert à feu doux, en remuant de temps en temps, jusqu’à ce que haricots soient tendres. Ajouter un peu d’eau si nécessaire. Incorporer le jus de lime et les pommes de terre cuites et bien réchauffer le tout. Servir avec des pains pita ou du riz.",
      time: 60,
      serves: 6,
      fat: 2.7,  
      calories: 95,
      saturated: 0.2,
      sodium: 160,
      difficulty: "moyen",
      heartuk: true,
      category: "dîner|végétarien",
    },
    {
      title: "Tikkas au poisson",
      slug: "tikkas-au-poisson",
      img: "fish-tikkas",
      hasImage: true,
      description:
        "Pour un souper d'inspiration indienne, marinez le poisson blanc dans une vinaigrette tikka masala faible en gras et servez avec une salade. Pour préparer cette recette, vous pouvez utiliser n’importe quel poisson blanc ferme provenant de sources durables.",
      ingredients:
        "1 kg (2,2 lb) de filets de morue (ou tout autre poisson ferme), coupés en cubes|15 ml (1 c. à table) de jus de citron|125 g (1/2 tasse) de yogourt nature faible en gras|15 ml (1 c. à table) de vinaigre|15 ml (1 c. à table) de garam masala|10 ml (2 c. à thé) de graines de cumin, moulues|5 ml (1 c. à thé) de poudre de chili rouge|10 ml (2 c. à thé) d’ail, haché|huile végétale",
      methods:
        "Laver les lentilles avec de l’eau et rincer plusieurs fois.|Chauffer l’huile dans une casserole et faire revenir le gingembre, les piments chilis verts et le cumin pendant 1 minute. |Ajouter les lentilles et les épinards et cuire 1 à 2 minutes de plus. |Ajouter le curcuma et 1,5 l d’eau. |Cuire à découvert à feu doux, en remuant de temps en temps, jusqu’à ce que les lentilles soient tendres. Il pourrait être nécessaire d’ajouter plus d’eau. Incorporer le jus de lime et les pommes de terre cuites et réchauffer uniformément. Servir avec du pain pita ou du riz.",
      time: 25,
      serves: 6,
      fat: 3.1,
      calories: 165,
      saturated: 0.5,
      sodium: 610,
      difficulty: "moyen",
      heartuk: true,
      category: "dîner|poisson",
    },
    {
      title: "Poulet tandoori avec sauce au yogourt et aux fines herbes",
      slug: "poulet-tandoori-avec-sauce-au-yogourt-et-aux-fines-herbes",
      img: "tandoori-chicken-with-herb-yoghurt-sauce",
      hasImage: true,
      description:
        "Cette délicieuse marinade tandoori ne contient aucun gras ni sucre ajouté et peut être utilisée pour assaisonner un poisson en entier, de l’agneau ou des filets de poisson. Il suffit de modifier le temps de cuisson en fonction du choix.",
      ingredients:
        "4 poitrines de poulet, désossées, sans peau, environ 150 g (1 tasse) chacune|Vin ou eau|Quelques brins de fines herbes, comme du romarin, du thym ou du persil|15 ml (1 c. à table) de racine de gingembre frais, râpée|10 ml (2 c. à thé) de graines de coriandre, grillées|10 ml (2 c. à thé) de feuilles de romarin|5 ml (1 c. à thé) de zeste de citron râpé|2,5 ml (1/2 c. à thé) de cardamome moulue|2,5 ml (1/2 c. à thé) de cumin moulu|1,25 ml (1/4 c. à thé) de grains de poivre noir moulus|1,25 ml (1/4 c. à thé) de sauce chili ou de poudre de chili|325 g (1 tasse) de yogourt nature faible en gras|30 ml (2 c. à table) de jus de citron|125 g (1 tasse) de concombre râpé|15 ml (1 c. à table) d’aneth haché ou de menthe hachée|Poivre noir fraîchement moulu",
      methods:
        "Dans un bol, mélanger tous les ingrédients pour préparer la marinade.|Mettre le poulet dans un plat non métallique. Verser la marinade sur le poulet à la cuillère et bien enduire pour le recouvrir. Couvrir et réfrigérer pendant 2 à 4 heures.|Retirer le surplus de marinade du poulet. Mettre le poulet sur une grille disposée dans une rôtissoire. Verser du vin ou de l'eau pour obtenir 2,5 cm (1 po) de liquide et ajouter les brins de fines herbes pour que la viande reste tendre pendant la cuisson.|Cuire le poulet dans un four conventionnel préchauffé, à 240°C (475°F) pendant 10 minutes. Tourner le poulet et poursuivre la cuisson pendant 10 minutes jusqu’à ce que qu’il soit bien cuit.|Pendant que le poulet cuit, mélanger tous les ingrédients dans un bol pour préparer la sauce au yogourt et aux fines herbes. Couvrir et mettre au réfrigérateur pendant environ 20 minutes afin de laisser les saveurs se développer.|Servir le poulet avec la sauce au yogourt et aux fines herbes accompagné de riz basmati et d’une salade verte.",
      time: 30,
      serves: 4,
      fat: 3,
      calories: 222,
      saturated: 1,
      sodium: 380,
      difficulty: "moyen",
      heartuk: true,
      category: "dîner|mets préférés de la famille",
    },
    {
      title: "Cari de poisson",
      slug: "cari-de-poisson",
      img: "fish-curry",
      hasImage: true,
      description:
        "Pour un souper santé, préparez ce poisson aromatique au cari en deux temps, trois mouvements et servez-le avec du riz ou du pain pita et une salade. Assurez-vous d'utiliser un poisson blanc provenant de sources durables.",
      ingredients:
        "450 g (1 lb) de poisson blanc (morue ou aiglefin) sans arêtes, coupé en morceaux |30 ml (2 c. à table) de jus de citron |15 ml (1 c. à table) d’huile de colza |2 petits oignons, hachés |5 ml (1 c. à thé) d’ail écrasé |5 ml (1 c. à thé) de gingembre écrasé |5 ml (1 c. à thé) de piment chili vert, haché finement |200 ml (7 oz) de tomates en conserve |2,5 ml (1/2 c. à thé) de poudre de curcuma |5 ml (1 c. à thé) de sel |5 ml (1 c. à thé) de garam masala |30 ml (2 c. à table) de coriandre fraîche, hachée, pour garnir",
      methods:
        "Dans un bol, mélanger tous les ingrédients pour préparer la marinade.|Mettre le poulet dans un plat non métallique. Verser la marinade sur le poulet à la cuillère et bien enduire pour le recouvrir. Couvrir et réfrigérer pendant 2 à 4 heures.|Retirer le surplus de marinade du poulet. Mettre le poulet sur une grille disposée dans une rôtissoire. Verser du vin ou de l'eau pour obtenir 2,5 cm (1 po) de liquide et ajouter les brins de fines herbes pour que la viande reste tendre pendant la cuisson.|Cuire le poulet dans un four conventionnel préchauffé, à 240°C (475°F) pendant 10 minutes. Tourner le poulet et poursuivre la cuisson pendant 10 minutes de plus, jusqu’à ce que qu’il soit bien cuit.|Pendant que le poulet cuit, mélanger tous les ingrédients dans un bol pour préparer la sauce au yogourt et aux fines herbes. Couvrir et mettre au réfrigérateur pendant environ 20 minutes afin de laisser les saveurs se développer.|Servir le poulet avec la sauce au yogourt et aux fines herbes accompagné de riz basmati et d’une salade verte.",
      time: 30,
      serves: 4,
      fat: 4.4,
      calories: 147,
      saturated: 0.4,
      sodium: 1400,
      difficulty: "moyen",
      heartuk: true,
      category: "dîner|mets préférés de la famille|poisson|hearty meal",
    },
    {
      title: "Brochettes de baudroie d'Amérique et de champignons à la thaïlandaise",
      slug: "brochettes-de-baudroie-dAmerique",
      img: "thai-style-monkfish-mushroom-kebabs",
      hasImage: true,
      description:
        "La baudroie d'Amérique est un poisson à la chair ferme, au goût délicat, qui se prête parfaitement à cette marinade à la thaïlandaise. Une merveilleuse infusion de saveurs et une recette si facile à préparer que vous pouvez en profiter n’importe quand, même en semaine.",
      ingredients:
        "500 à 750 g (1 lb à 1½ lb) queues de baudroie d'Amérique, sans la peau|1 oignon rouge, coupé en quartiers et en couches séparées|8 champignons|1 courgette, coupée en 8 morceaux|1 poivron jaune, épépiné et coupé en morceaux|4 tomates cerises|Huile végétale, pour badigeonner|Cresson ou persil plat, pour garnir|Zeste râpé et jus de 2 limes|1 gousse d’ail, hachée finement|30 ml (2 c. à table) de racine de gingembre frais, finement émincée|2 piments chilis frais, rouges ou verts ou un de chaque couleur, épépinés et hachés finement|2 tiges de citronnelle, finement hachées|1 tasse de vin rouge|30 ml (2 c. à table) d’huile de sésame|poivre noir, fraîchement moulu",
      methods:
        "Dans un grand bol, mélanger les ingrédients de la marinade. Couper le poisson en gros cubes et l’ajouter à la marinade avec l’oignon, les champignons et la courgette. Couvrir et réfrigérer pendant 1 heure afin de favoriser le mélange des saveurs.|Badigeonner légèrement d'huile la grille d'une poêle à fond cannelé pour empêcher les brochettes de coller. Enfiler 4 brochettes en alternant les morceaux de poisson, les champignons, la courgette et placer une tomate au centre. Badigeonner les brochettes d'un peu d'huile et les faire griller sous un gril chaud préchauffé pendant environ 10 minutes, en les retournant de temps en temps. Garnir de cresson ou de persil plat.",
      time: 28,
      serves: 6,
      fat: 8,
      calories: 249,
      saturated: 1.2,
      sodium: 90,
      difficulty: "facile",
      heartuk: true,
      category: "dîner|mets préférés de la famille|poisson|hearty meal",
      notes:
        "You can use any firm white fish such as halibut, sea bass, swordfish, cod or haddock, if you prefer.",
    },
    {
      title: "Gratin de pâtes au thon",
      slug: "gratin-de-pates-au-thon",
      img: "tuna-pasta-bake",
      hasImage: true,
      description:
        "Un populaire plat familial, ce mets savoureux et rassasiant se prépare en 45 minutes et constitue le plat réconfortant par excellence, sans la culpabilité.",
      ingredients:
        "400 g (3 tasses) de diverses pâtes de grains entiers (nous avons utilisé des fusillis)|2 boites de 160 g (6 oz) de thon dans l’eau, égoutté |100 g (1 tasse) d’épinards |1 boîte de maïs sucré, égoutté |75 g (1/2 tasse) de chapelure de grains entiers |1 pot de 500 ml (16 oz) de sauce tomate  |100 g (3/4 tasse) de mozzarella faible en gras ou avec 10 % de matières grasses, râpé ou coupé en petits morceaux |Poivre noir",
      methods:
        "Réchauffer le four à 200°C (180°C pour un four à convection).|Cuire les pâtes dans un grand chaudron (en suivant les indications sur l’emballage) jusqu’à ce qu’elles soient cuites, mais encore fermes, « al dente ».|Ajouter les épinards au chaudron comportant les pâtes pendant la dernière minute de cuisson et les laisser flétrir.|Égoutter et transférer les pâtes et les épinards dans un grand plat de cuisson.|Ajouter le thon, le maïs sucré et la sauce tomate et assaisonner de poivre noir.|Garnir de mozzarella et, pour terminer, ajouter la chapelure.|Cuire au four pendant au moins 30 minutes ou jusqu’à ce que le mélange bouillonne.|Servir.",
      time: 45,
      serves: 6,
      fat: 5.3,
      calories: 397,
      saturated: 1.9,
      sodium: 150,
      difficulty: "moyen",
      heartuk: true,
      category: "dîner|mets préférés de la famille|poisson|hearty meal",
      notes:
        "Try using other vegetables favorites including peas, mixed vegetables, broccoli florets, asparagus tips, mushrooms or bell peppers.",
    },
    {
      title: "Cari de crevettes et de légumes",
      slug: "cari-de-crevettes-et-de-legumes",
      img: "prawn-and-vegetable-curry",
      hasImage: true,
      description:
        "Qui a dit qu'il est impossible de préparer un repas santé en moins de 30 minutes? Essayez cette délicieuse recette au cari pour un repas rapide et facile à cuisiner en semaine!",
      ingredients:
        "4 gousses d’ail |1 oignon |15 ml (1 c. à table) d’huile d’olive|1 pomme, râpée |37,5 ml (2 ½ c. à table) de poudre de cari |5 ml (1 c. à thé) de garam masala |2,5 ml (1/2 c. à thé) de curcuma |1 boite de 400 g (14 oz) de tomates hachées, en conserve |1/2 piment chili rouge, haché finement |10 ml (2 c. à thé) d’édulcorant |110 g (1 tasse) de pois chiches en conserve, égouttés et rincés|200 g (1 ½ tasse) de crevettes congelées |50 ml (1,7 oz) de lait de soya |Quelques feuilles de coriandre|265 g (2 tasses) de riz basmati",
      methods:
        "Mesurer et verser le riz basmati dans un chaudron, le recouvrir d’eau et cuire selon les indications de l’emballage. |Cuire l’ail et l’oignon dans de l’huile d’olive, à feu moyen, jusqu’à ce qu’ils soient dorés. Ajouter la poudre de cari, le garam masala et le curcuma et cuire une minute de plus. Ajouter la pomme râpée, les tomates coupées en morceaux et le piment chili rouge, remuer pour mélanger, puis ajouter 200 ml d’eau et remuer à nouveau. Porter à faible ébullition et cuire 2 minutes de plus.|Ajouter les pois chiches et les crevettes congelées et cuire jusqu’à ce qu’elles soient tendres et bien chaudes. Une fois que le mélange est chaud, ajouter l’édulcorant et le lait de soya.|Servir le riz et le cari dans un bol et garnir de feuilles de coriandre.",
      time: 25,
      serves: 4,
      fat: 5.8,
      calories: 416,
      saturated: 0.9,
      sodium: 540,
      difficulty: "moyen",
      heartuk: true,
      category: "dîner|mets préférés de la famille|poisson|hearty meal",
    },
    {
      title: "Pouding estivale",
      slug: "pouding-estivale",
      img: "summer-pudding",
      hasImage: true,
      description:
        "Ce traditionnel pouding estival faible en gras vous permettra de profiter de tous les succulents fruits de la saison. Toutefois, n’attendez pas que l’été soit arrivé pour en profiter. Utilisez des fruits congelés lorsque les petits fruits d'été ne sont plus disponibles.",
      ingredients:
        "300 g (2 ½ tasses) de pain blanc rassis, coupé en tranches de grosseur moyenne|875 g (7 tasses) de petits fruits d’été mûrs, comme des groseilles rouges, des groseilles blanches, des groseilles noires (cassis), des framboises, des fraises et des cerises, préparés séparément|75 g (3 oz) de sucre superfin|75 ml (3 oz) d’eau|Fruits et feuilles de fruits supplémentaires, pour garnir",
      methods:
        "Couper les croûtes du pain et les utiliser pour recouvrir le fond et les côtés d'un moule à pouding de 1,2 litre en disposant les morceaux de pain les uns contre les autres et en les coupant pour qu'ils conviennent à l'espace du moule. Garder suffisamment de pain pour mettre sur le dessus.|Mettre les fruits, sauf les framboises et les fraises, dans une casserole avec le sucre et l’eau. Chauffer à feu doux jusqu'à ce que le jus commence à s'écouler des fruits et que le sucre fonde. Retirer du feu et ajouter le reste des fruits. Égoutter les fruits dans une passoire en nylon, et réserver le jus. À l’aide d’une cuillère, placer les fruits dans le moule avec la moitié du jus et couvrir avec le pain réservé pour la garniture.|Presser le dessus avec une soucoupe alourdie enveloppée dans une pellicule de plastique pour ne pas altérer le pouding. Mettre le moule dans un plat peu profond pour recueillir le jus qui pourrait s’écouler, puis réfrigérer toute la nuit. Couvrir et réfrigérer le reste des jus séparément.|Pour servir, retirer la soucoupe et passer un couteau à lame émoussée autour du pouding. Renverser le moule dans un plat avec des rebords, en le secouant doucement pour libérer le pouding. Soulever délicatement le moule pour retirer le pouding. Si le pain a une surface irrégulière, badigeonner avec les jus réservés. Servir les jus restants séparément. Garnir le pouding de fruits et de feuilles. Servir avec du yogourt nature faible en gras ou avec du fromage frais.",
      time: 30,
      serves: 6,
      fat: 1.2,
      calories: 221,
      saturated: 0,
      sodium: 510,
      difficulty: "moyen",
      heartuk: true,
      category: "dessert|mets préférés de la famille",
    },
    {
      title: "Nids en chocolat râpé",
      slug: "nids-en-chocolat",
      img: "chocolate-nests",
      hasImage: true,
      description:
        "Une excellente idée pour remplacer les traditionnels nids en chocolat. Cette recette contient beaucoup moins de gras saturés et constitue une bonne source de fibres. Pourquoi ne pas en profiter! Une recette tellement simple que les enfants se feront un plaisir de vous aider à la préparer (et à la déguster!).",
      ingredients:
        "4 morceaux de blé filamenté, écrasés|30 ml (2 c. à table) de poudre de cacao|60 ml (4 c. à table) de cassonade|120 ml (8 c. à table) de lait écrémé|16 mini-œufs au chocolat",
      methods:
        "Disposer 16 moules pour petits gâteaux sur un plateau.|Dans une petite casserole, ajouter la poudre de cacao, la cassonade et le lait.|Chauffer à feu moyen en fouettant constamment jusqu’à ce que le mélange épaississe et prenne une consistance soyeuse, comme une sauce caramel au chocolat.|Retirer du feu.|Ajouter le blé écrasé et mélanger avec une cuillère en bois jusqu’à ce que tous les brins de blé soient enrobés.|Verser le mélange uniformément dans les moules pour petits gâteaux.|À l'aide d'un carré de papier ingraissable, presser doucement pour que les brins soient bien tassés.|Garnir chaque nid d’un œuf miniature.|Mettre le plateau au congélateur pendant 1 heure pour laisser durcir.",
      time: 20,
      serves: 16,
      fat: 0.9,
      calories: 55,
      saturated: 0.5,
      sodium: 20,
      difficulty: "facile",
      heartuk: true,
      category: "dessert|mets préférés de la famille",
    },
    {
      title: "Souper au poulet des îles",
      slug: "souper-au-poulet-des-iles",
      img: "island-chicken-dinner",
      hasImage: true,
      description: "C’est comme souper à Hawaï.",
      ingredients:
        "454 g (1 lb) de filets de poitrine de poulet |1 oignon, haché |1 poivron vert, 1 poivron rouge et 1 poivron jaune, tranchés|1 petite boîte de châtaignes d'eau, coupées grossièrement |113 ml (1/2 tasse) de sauce teriyaki |1 petite boîte d'ananas, en morceaux | Le tout servi sur 200 g (1 tasse) de riz brun",
      methods:
        "Faire revenir les filets de poulet dans un grand plat à sauter, vaporisé avec un enduit à cuisson antiadhésif, jusqu'à ce que le poulet ne soit plus rose. |Retirer du plat. |Mettre l’oignon et les poivrons dans le plat. Faire revenir jusqu'à ce qu'ils soient tendres et légèrement croquants. |Ajouter le poulet cuit, les châtaignes d’eau, les morceaux d’ananas et la sauce teriyaki. |Cuire à feu moyen jusqu’à ce que le tout soit bien chaud.|Servir sur du riz brun.",
      serves: 4,
      fat: 4,
      calories: 462,
      cholesterol: 73,
      carbohydrate: 22,
      saturated: 1,
      sodium: 680,
      difficulty: "facile",
      category: "dîner|mets préférés de la famille",
    },
    {
      title: "Soupe au poulet à l’italienne",
      slug: "soupe-au-poulet-a-litalienne",
      img: "italian-chicken-soup",
      hasImage: true,
      description:
        "Ma famille adore cette soupe savoureuse, faible en gras, mais copieuse. Elle est encore plus appétissante avec du pain français croustillant.",
      ingredients:
        "1 petit oignon, haché |1 poivron vert, coupé en dés |3 gousses d’ail |15 ml (1 c. à table) de basilic, séché|1,25 ml (1/4 c. à thé) de flocons de piment rouge (facultatif) |1 tasse de carottes crues, coupées en morceaux |1 grosse courgette, coupée en dés |1 boîte de pois chiches, égouttés |1 boîte de tomates, en dés |3 boîtes de bouillon de poulet, faible en sodium, sans gras |1 paquet de raviolis légers aux quatre fromages |190 g (1 ½ tasse) de poitrine de poulet, cuite, coupée en dés |Parmesan faible en gras |enduit à cuisson antiadhésif ",
      methods:
        "Vaporiser une grande casserole d’un enduit à cuisson antiadhésif. |Ajouter l’oignon, le poivron, l’ail, le basilic et le piment rouge et faire revenir, à feu moyen, jusqu’à ce que le tout soit tendre, environ 10 minutes.|Ajouter le bouillon. Couvrir la casserole et laisser mijoter 10 minutes. |Ajouter les carottes et les courgettes.|Couvrir et laisser mijoter jusqu’à ce que les carottes soient presque tendres, environ 5 minutes. |Cuire ensuite à feu vif et porter la soupe à ébullition. Ajouter les raviolis et faire bouillir jusqu’à ce qu’ils soient tendres, environ 5 minutes. |Ajouter le poulet, les pois chiches et les tomates et cuire jusqu’à ce que le tout soit bien chaud, environ 1 minute. |Verser la soupe dans des bols, saupoudrer d’un peu de parmesan et déguster.",
      serves: 6,
      fat: 6,
      calories: 303,
      cholesterol: 51,
      carbohydrate: 39,
      saturated: 3.7,
      sodium: 543,
      difficulty: "facile",
      category: "dîner|mets préférés de la famille|hearty meal",
    },
    {
      title: "Vinaigrette italienne",
      slug: "vinaigrette-italienne",
      img: "italian-dressing",
      hasImage: true,
      description:
        "Les morceaux de poivron rouge donnent de la couleur à cette vinaigrette aromatique.",
      ingredients:
        "56 ml (1/4 tasse) de vinaigre de vin blanc |30 ml (2 c. à table) de vinaigre de vin rouge |1 grosse gousse d’ail, écrasée |30 ml (2 c. à table) d’échalote française, hachée finement |30 ml (2 c. à table) de poivron rouge, haché finement |10 ml (2 c. à thé) de moutarde de style Dijon |5 ml (1 c. à thé) de miel |3,75 ml (3/4 c. à thé) de sel de table |1,25 ml (1/4 c. à thé) d’origan, séché |1,25 ml (1/4 c. à thé) de persil, séché |Une pincée de flocons de piment rouge (facultatif) |Poivre noir fraîchement moulu, au goût",
      methods:
        "Mélanger les ingrédients dans un bol et fouetter jusqu’à ce que le mélange soit homogène. | Goûter et ajuster l’assaisonnement au goût. ",
      time: 10,
      serves: 4,
      fat: 0,
      calories: 19,
      cholesterol: 0,
      carbohydrate: 3,
      saturated: 0,
      sodium: 496,
      fiber: 0,
      difficulty: "facile",
      category: "rapides et faciles",
    },
    {
      title: "Boulettes de viande à l'italienne",
      slug: "boulettes-de-viande-a-litalienne",
      img: "italian-meatballs",
      hasImage: true,
      description:
        "Servez cette recette lors de vos soupers en semaine et elle deviendra rapidement un mets préféré de la famille!",
      ingredients:
        "454 g (1 lb) de bœuf haché « extra maigre » (au moins 95 % sans gras) |63 g (1/2 tasse) de chapelure, nature |56 ml (1/4 tasse) de lait écrémé |2 blancs d’œufs |40 g (1/4 tasse) d’oignon, émincé|1 gousse d’ail, émincée |2,5 ml (1/2 c. à thé) d’origan |0,625 ml (1/8 c. à thé) de poivre |900 g (4 tasses) de votre sauce tomate sans gras préférée",
      methods:
        "Préchauffer le four à 176°C (350°F).|Dans un grand bol, mélanger tous les ingrédients sauf la sauce tomate.|Former de petites boulettes (environ 3,8 cm/ 1,5 po) et les disposer sur une plaque à biscuits recouverte de papier d’aluminium antiadhésif.|Cuire au four pendant 20 à 25 minutes ou jusqu’à ce que la température interne des boulettes atteigne au moins 71°C (160°F); retourner les boulettes de viande à la mi-cuisson.|Mettre la sauce tomate dans une grande casserole et porter doucement à ébullition.|Ajouter les boulettes de viande et laisser mijoter durant 15 à 20 minutes.|Servir sur vos pâtes préférées.|Donne 24 boulettes de viande.",
      time: 50,
      serves: 8,
      fat: 3.3,
      calories: 155,
      cholesterol: 35,
      carbohydrate: 14,
      saturated: 1.4,
      sodium: 630,
      fiber: 2,
      difficulty: "facile",
      category: "dîner|mets préférés de la famille",
    },
    {
      title: "Gratin de courge spaghetti à l'italienne",
      slug: "gratin-de-courge-spaghetti-a-litalienne",
      img: "italian-style-spaghetti-squash-bake",
      hasImage: true,
      description: "Un excellent substitut aux pâtes!",
      ingredients:
        "1 courge spaghetti de grosseur moyenne – environ 500 g (4 tasses) |1 courgette, râpée |453 g (16 oz) de sauce pour pâtes préparée, faible en gras (comme la sauce Barilla au basilic et à l'ail ou un autre type de sauce aux légumes ou aux fines herbes) |Une boite de 226 g (8 oz) de champignons|50 g (1/2 tasse) de mozzarella râpé avec 2 % de matières grasses (faible en gras) |Parmesan râpé, au goût (non compris dans l’analyse) |Sel, poivre et poudre d’ail, au goût |Assaisonnement italien au goût",
      methods:
        "Préchauffer le four à 176°C (350°F).|Couper la courge en deux dans le sens de la longueur et en retirer les graines. |Mettre environ 1,3 cm (½ po) d’eau dans le fond d'un plat de cuisson de 30 cm par 20 cm (12 po par 8 po) et y déposer la courge (côté coupé vers le bas). Cuire au four à micro-ondes de 12 à 15 minutes ou jusqu'à ce qu'on puisse percer la courge avec une fourchette; laisser refroidir quelques minutes. |Détacher les filaments de courge spaghetti avec une fourchette et jeter les pelures. Vaporiser le même plat de cuisson avec un enduit à cuisson sans gras, et répartir uniformément la courge dans le plat. Ajouter les courgettes râpées. |Égoutter les champignons et les répartir uniformément sur les courgettes. |Saupoudrer le mélange de sel (si désiré), de poivre, de poudre d’ail et d’assaisonnement italien, au goût. |Répartir uniformément la sauce à pâtes sur le mélange de courge. |Répartir le fromage mozzarella râpé uniformément sur le dessus, puis saupoudrer de parmesan. Cuire au four pendant environ 25 minutes ou jusqu’à ce que le fromage soit fondu et que le plat bouillonne. |Laisser refroidir quelques minutes avant de servir. Délicieux comme plat principal ou en plat d’accompagnement.",
      time: 50,
      serves: 6,
      fat: 2.2,
      calories: 103,
      cholesterol: 5,
      carbohydrate: 17,
      saturated: 1.0,
      sodium: 630,
      fiber: 3.5,
      difficulty: "facile",
      category: "dîner|mets préférés de la famille|hearty meal",
    },
    {
      title: "Coquilles géantes farcies",
      slug: "coquilles-geantes-farcies",
      img: "jumbo-stuffed-shells",
      hasImage: true,
      description: "Un plat tellement savoureux que vous voudrez le servir lors d’un souper entre amis.",
      ingredients:
        "Une boite de 453 g (16 oz) de coquilles géantes |440 g (2 ½ tasses) de fleurons de brocoli, cuits à la vapeur et émincés – ou utiliser un paquet de brocoli congelé de 283 g (10 oz)| 2 blancs d’œufs |425 g (15 oz) de ricotta, sans gras |60 ml (4 c. à table) de parmesan  |1,25 ml (1/4 c. à thé) de poudre d’ail |2,5 ml (1/2 c. à thé) de muscade|1,25 ml (1/4 c. à thé) de poivre noir|2,5 ml (1/2 c. à thé) d’origan|900 g (4 tasses) de sauce tomate (utiliser une sauce faible en sodium, si désiré)|75 g (1 tasse) ou plus de champignons frais, tranchés",
      methods:
        "Préchauffer le four à 176°C (350°F).|Cuire les coquilles selon les directives indiquées sur l’emballage.| Dans un grand bol, mélanger les fromages, les épices et les blancs d’œufs. Incorporer le brocoli. |Utiliser une cuillère à table pour garnir les coquilles.|Vaporiser un plat de cuisson en verre de 33 cm par 23 cm (13 po par 9 po) avec un enduit à cuisson antiadhésif. |Verser le tiers de la sauce tomate. |Disposer les coquilles dans le plat, verser le reste de la sauce et ajouter les champignons. |Cuire au four pendant 35 à 40 minutes.",
      time: 60,
      serves: 6,
      fat: 2.3,
      calories: 242,
      cholesterol: 5,
      carbohydrate: 36,
      saturated: 1,
      sodium: 198,
      difficulty: "facile",
      category: "dîner|mets préférés de la famille|hearty meal",
    },
    {
      title: "Le pain doré faible en gras de Justin",
      slug: "le-pain-dore-faible-en-gras-de-justin",
      img: "justins-low-fat-french-toast",
      hasImage: true,
      description:
        "Un plat savoureux pour le déjeuner ou le dîner de la fin de semaine!",
      ingredients:
        "1/2 tasse de blancs d’œufs (deux gros œufs) |113 ml (1/2 tasse) de lait écrémé |2 tranches de pain aux raisins faible en gras |Enduit à cuisson sans gras en vaporisateur |30 ml (2 c. à table) de sirop d’érable pur |2,5 ml (1/2 c. à thé) de cannelle |Garniture fouettée, sans gras |3 fraises, équeutées et coupées en deux",
      methods:
        "Fouetter les blancs d'œufs et le lait écrémé avec la cannelle dans un bol assez grand pour y tremper le pain. |Chauffer une poêle à frire antiadhésive à feu moyen. |Vaporiser avec un enduit à cuisson. |Tremper le pain aux raisins dans le mélange à base d’œufs. |Mettre le pain dans la poêle, laisser les œufs adhérer au pain et attendre qu’ils soient légèrement croustillants. |Retourner le pain et cuire de la même façon de l'autre côté. |Mettre le pain dans une assiette de service et garnir de sirop, de garniture fouettée et de fraises tranchées.",
      time: 30,
      serves: 1,
      fat: 3.7,
      calories: 395,
      cholesterol: 6,
      carbohydrate: 72,
      saturated: 1.4,
      sodium: 516,
      fiber: 4,
      difficulty: "facile",
      category: "petit déjeuner|mets préférés de la famille|rapides et faciles",
    },
    {
      title: "Recette de lasagne facile à préparer",
      slug: "recette-de-lasagne-facile-a-preparer",
      img: "lazy-lasagna",
      hasImage: true,
      description:
        "Il n'est pas nécessaire de cuire les pâtes! Ce mets savoureux plaira à quiconque aime les pâtes.",
      ingredients:
        "12 pâtes pour lasagne|200 g (2 tasses) de mozzarella sans gras, râpé|25 g (¼ tasse) de parmesan faible en gras, râpé|340 g (12 oz) de dinde hachée, maigre à 99 %|2 boîtes de 454 g (1 lb) de sauce tomate|1 boîte de 454 g (1 lb) de tomates écrasées, non salées|30 ml (2 c. à table) de pâte de tomate|150 g (2 tasses) de champignons frais, tranchés|1 oignon moyen, haché|10 ml (2 c. à thé) d’ail, émincé|12,5 ml (2 ½ c. à thé) d’assaisonnement italien, séché|425 g (15 oz) de ricotta, sans gras|225 g (1 tasse) de fromage cottage, faible en gras|10 ml (2 c. à thé) de persil, séché",
      methods:
        "Mettre la dinde hachée dans un chaudron. Cuire à feu moyen, en remuant pour que la viande se défasse, jusqu'à ce qu'elle ne soit plus rose. Égoutter le gras.|Ajouter le reste des ingrédients de la sauce à la viande brunie, et porter à ébullition à feu vif. |Réduire la température à feu doux, couvrir et laisser mijoter pendant 25 minutes. Mettre de côté.|Ajouter tous les ingrédients pour la garniture dans un grand bol et bien mélanger. Mettre de côté.|Vaporiser un plat de cuisson de 23 cm par 33 cm (9 po par 13 po) avec un enduit à cuisson antiadhésif |Déposer à la cuillère 200 ml (1 tasse) de sauce dans le fond du plat. Disposer 4 pâtes pour lasagne non cuites dans le fond du plat, plaçant 3 pâtes dans le sens de la longueur et 1 pâte dans le sens de la largeur (vous devrez peut-être briser celle-ci pour la placer dans l'espace prévu).|Ajouter la moitié de la garniture sur les pâtes, soit 75 g (3/4 tasse) de mozzarella et 300 ml (1,5 tasse) de sauce. Répéter les couches en ajoutant des pâtes, la garniture, le mozzarella et la sauce. |Terminer avec une couche finale de pâtes, de sauce, de parmesan et de mozzarella.|Couvrir le plat de cuisson avec du papier d'aluminium et cuire au four à 176°C (350°F) pendant 45 minutes.|Retirer le papier d’aluminium et poursuivre la cuisson pendant 15 minutes. Laisser refroidir pendant environ 5 minutes, couper et servir!",
      time: 90,
      serves: 10,
      fat: 2,
      calories: 277,
      cholesterol: 40,
      carbohydrate: 36,
      saturated: 0.8,
      sodium: 998,
      fiber: 4,
      difficulty: "moyen",
      category: "dîner|mets préférés de la famille|hearty meal",
    },
    {
      title: "Biscuits au citron",
      slug: "biscuits-au-citron",
      img: "lemon-drop-cookies",
      hasImage: true,
      description:
        "Il ne vous faut que cinq ingrédients pour réaliser cette recette de biscuits tendres et moelleux. Ces biscuits plairont aux gens qui ont la dent sucrée.",
      ingredients:
        "1 boîte de préparation de gâteau au citron |30 g (1/2 tasse) de garniture fouettée Cool Whip sans gras |Un soupçon d'extrait de citron |1 œuf, battu |sucre à glacer",
      methods:
        "Mélanger tous les ingrédients, sauf le sucre à glacer.|Comme le mélange sera un peu friable, il est important de bien le mélanger. |Former des boules de 2,5 cm (1 po) et les rouler dans un bol de sucre à glacer.|Vaporiser une plaque à biscuits avec un enduit à cuisson.|Cuire au four à 176°C (350°F) pendant 10 à 12 minutes. |Donne 32 biscuits",
      time: 20,
      serves: 16,
      fat: 1,
      calories: 107,
      cholesterol: 13,
      carbohydrate: 23,
      saturated: 1,
      sodium: 205,
      fiber: 1,
      difficulty: "facile",
      category: "collations légères|mets préférés de la famille|dessert|rapides et faciles",
    },
    {
      title: "Enchiladas de poulet faibles en gras",
      slug: "enchiladas-de-poulet-faibles-en-gras",
      img: "low-fat-chicken-enchiladas",
      hasImage: true,
      description: "Un plat amusant et faible en gras pour le dîner ou le souper!",
      ingredients:
        "115 g (1/2 tasse) de salsa |113 ml (1/2 tasse) de bouillon de poulet en conserve, faible en sodium, sans gras |5 ml (1 c. à thé) de cumin moulu |3 boîtes de 226 g (8 oz) de sauce tomate, « sans sel ajouté » |375 g (3 tasses) de poitrines de poulet, cuites, coupées en cubes |45 ml (3 c. à table) de piments jalapeños, marinés, hachés |1 boîte de 425 g (15 oz) de haricots noirs, sans sel ajouté, rincés et égouttés |12 tortillas de maïs de 15 cm (6 po) |173 g (1 ½ tasse) de mélange de fromages mexicains, légers, râpés |184 g (3/4 tasse) de crème sure sans gras / yogourt grec sans gras |75 g (1/2 tasse) d’échalotes / d’oignons verts, hachés",
      methods:
        "Préchauffer le four à 176°C (350°F).|Mélanger les quatre premiers ingrédients; bien remuer. Répartir 3/4 de tasse du mélange dans le fond d'un plat de cuisson de 33 cm par 23 cm par 5 cm (13 po par 9 po par 2 po).|Dans un bol de grosseur moyenne, mélanger le poulet, les piments jalapeños, les haricots noirs et 1/2 tasse du mélange de sauce tomate. Bien remuer.|Verser le mélange de poulet uniformément au centre des tortillas.|Rouler les tortillas et les disposer avec l'ouverture vers le bas sur le mélange de sauce dans le plat de cuisson.|Garnir avec le reste du mélange de sauce et le fromage.|Couvrir et mettre au four pendant 25 minutes.|Garnir de crème sure ou de yogourt grec et parsemer d’échalotes ou d’oignons verts.",
      time: 40,
      serves: 6,
      fat: 8.8,
      calories: 439,
      cholesterol: 67,
      carbohydrate: 51,
      saturated: 3.0,
      sodium: 554,
      fiber: 11,
      difficulty: "facile",
      category: "dîner|mets préférés de la famille",
    },
    {
      title: "Chili faible en gras",
      slug: "chili-faible-en-gras",
      img: "low-fat-chili",
      hasImage: true,
      ingredients:
        "3 gousses d’ail, émincées|1 petite boîte de 141 g (5 oz) de piments verts, coupés en dés|1 oignon moyen, haché|1 poivron vert, haché|454 g (1 lb) de bœuf haché, maigre à 95 %|2 boîtes de 425 g (15 oz) de haricots rouge foncé|Une boite de 425 g (15 oz) de tomates, coupées en dés|56 g (2 oz) de pâte de tomate|Poudre de chili, sel et poivre, au goût|7,5 ml (1/2 c. à table) de cumin|7,5 ml (1/2 c. à table) d’origan, séché|7,5 ml (1/2 c. à table) de poudre de chili |5 ml (1 c. à thé) d’huile",
      methods:
        "Dans une poêle à frire de 30 cm (12 po), faire revenir l’oignon, l’ail et le poivron vert dans 5 ml (1 c. à thé) d’huile, jusqu’à ce que le tout soit tendre et légèrement doré.| Ajouter la viande et faire dorer. Égoutter tout surplus de gras. |Ajouter le reste des ingrédients et porter à ébullition, puis réduire le feu et laisser mijoter pendant 40 minutes.",
      time: 55,
      serves: 4,
      fat: 7.3,
      calories: 404,
      cholesterol: 70,
      carbohydrate: 52,
      saturated: 2.8,
      sodium: 720,
      fiber: 17,
      difficulty: "facile",
      category: "dîner|mets préférés de la famille|hearty meal",
    },
    {
      title: "Cocotte de haricots verts, faibles en gras",
      slug: "cocotte-de-haricots-verts-faibles-en-gras",
      img: "low-fat-green-bean-casserole",
      hasImage: true,
      description: "Une savoureuse version d'un plat des Fêtes traditionnel toujours très apprécié!",
      ingredients:
        "600 g (4 tasses) de haricots verts cuits, coupés en morceaux de 3,8 cm (1 ½ po)|425 g (15 oz) de Crème de champignons Campbell's Soupe condensée|113 ml (1/2 tasse) de lait écrémé|5 ml (1 c. à thé) d’oignon émincé, séché|Une pincée de poivre noir|5 ml (1 c. à thé) d’huile de colza|1 oignon doux de grosseur moyenne, tranché finement |63 g (1/2 tasse) de chapelure Panko",
      methods:
        "Préchauffer le four à 176°C (350°F).|Mettre les haricots verts dans une cocotte de 1 ½ l à 2 l. |Dans un bol, mélanger la soupe condensée, le lait, l’oignon émincé séché et le poivre noir. |Verser le mélange sur les haricots verts. |Chauffer 5 ml (1 c. à thé) d’huile de colza dans une poêle antiadhésive et cuire les oignons jusqu’à ce qu’ils soient dorés. |Répartir sur le mélange de haricots verts. |Saupoudrer la chapelure Panko sur le dessus. |Cuire au four pendant 30 minutes ou jusqu’à ce que le dessus soit brun doré.",
      time: 50,
      serves: 6,
      fat: 2,
      calories: 109,
      cholesterol: 4,
      carbohydrate: 18,
      saturated: 0.4,
      sodium: 281,
      fiber: 4,
      difficulty: "moyen",
      category: "dîner|mets préférés de la famille|hearty meal",
    },
    {
      title: "Macaroni au fromage, faible en gras",
      slug: "macaroni-au-fromage-faible-en-gras",
      img: "low-fat-mac-and-cheese",
      hasImage: true,
      ingredients:
        "200 g (2 tasses) de macaronis secs|30 ml (2 c. à table) de beurre de colza léger|15 ml (1 c. à table) de farine|280 ml (1 ¼ tasse) de lait écrémé froid|1 paquet de cheddar blanc, La Vache qui rit|29 g (1/4 tasse) de fromage râpé, sans gras|Sel et poivre au goût",
      methods:
        "Préchauffer le four à 176°C (350°F).|Cuire les nouilles selon les indications de l'emballage et les mettre de côté dans un plat allant au four.|Dans une petite casserole, faire fondre le beurre à feu moyen et incorporer la farine. Ajouter le lait et bien remuer. Cuire la sauce à feu doux jusqu'à ce qu'elle soit lisse et bouillonne. Ajouter les 8 morceaux de fromage et remuer jusqu'à ce que le mélange soit homogène. |Verser la sauce au fromage sur les nouilles cuites dans le plat allant au four et bien mélanger. |Garnir de fromage râpé et cuire au four pendant 20 minutes.",
      time: 35,
      serves: 8,
      fat: 3.2,
      calories: 173,
      cholesterol: 8,
      carbohydrate: 25,
      saturated: 1.9,
      sodium: 280,
      fiber: 1,
      difficulty: "facile",
      category: "dîner|mets préférés de la famille|rapides et faciles",
    },
    {
      title: "Shortcake aux fraises, faible en gras",
      slug: "shortcake-aux-fraises-faible-en-gras",
      img: "low-fat-strawberry-shortcake",
      hasImage: true,
      description:
        "Un dessert estival rapide et facile à préparer qui plaira à toute la famille!",
      ingredients:
        "1 paquet de 6 coquilles de gâteau éponge doré (6 par paquet)|400 g (2 tasses) de fraises, tranchées (fraîches ou congelées)|30 ml (2 c. à table) de sucre|2,5 ml (1/2 c. à thé) d’extrait de vanille|1 contenant de crème fouettée Reddi-wip sans gras ",
      methods:
        "Mettre les fraises, le sucre et l'extrait de vanille dans un chaudron.|Chauffer à feu moyen en remuant régulièrement pendant environ 4 minutes (jusqu'à ce que le jus recouvre le fond du chaudron).|Retirer du feu et mettre dans un bol.|Mettre le mélange de fraises au congélateur pendant dix minutes.|Préparer le shortcake aux fraises en déposant  |1/6 du mélange de fraises sur chaque coquille de gâteau éponge. |Garnir chaque coquille de 30 ml (2 c. à table) de crème fouettée Reddi-wip sans gras. |Et dégustez!",
      time: 15,
      serves: 3,
      fat: 3.6,
      calories: 277,
      cholesterol: 41,
      carbohydrate: 56,
      saturated: 0,
      sodium: 285,
      fiber: 2,
      difficulty: "facile",
      category: "dessert|mets préférés de la famille|rapides et faciles",
    },
    {
      title: "Poulet et dumplings faibles en gras",
      slug: "poulet-et-dumplings-faibles-en-gras",
      img: "low-fat-chicken-and-dumplings",
      hasImage: true,
      description: "Une excellente façon de déguster ce mets traditionnel grâce à cette version faible en gras.",
      ingredients:
        "454 g (1 lb) de poitrines de poulet désossées, sans peau, coupées en cubes |3 boîtes de 429 ml (14,5 oz) chacune, de bouillon de poulet à teneur réduite en sodium |675 ml (3 tasses) d’eau |3 grosses carottes, coupées en morceaux |1 oignon moyen, coupé en morceaux |2 branches de céleri, coupées en morceaux |5 ml (1 c. à thé) de persil |2,5 ml (1/2 c. à thé) de sel |1 gousse d’ail, émincée |3 blancs d’œufs |115 g (1/2 tasse) de fromage cottage avec 1 % de matières grasses|30 ml (2 c. à table) d’eau |140 g (1 tasse) de farine tout usage",
      methods:
        "Verser environ 6 mm (¼ po) d'eau dans une grande poêle à frire antiadhésive. Cuire le poulet jusqu’à ce qu’il ne soit plus rose. Ajouter le bouillon, l’eau, les légumes et les assaisonnements. Porter à ébullition. Baisser le feu; laisser mijoter à découvert pendant 30 minutes ou jusqu’à ce que les légumes soient tendres.|Pour préparer les dumplings, battre les blancs d’œufs et le fromage cottage dans un grand bol jusqu’à l’obtention d’une texture homogène. Ajouter l’eau et le sel. Incorporer la farine et bien mélanger.|Porter la soupe à ébullition. Ajouter les dumplings avec une cuillère à table dans la soupe bouillante. Réduire le feu; couvrir et laisser mijoter pendant 15 minutes ou jusqu’à ce qu’un cure-dent inséré dans les dumplings en ressorte propre. Ne pas soulever le couvercle pendant que les dumplings mijotent. |Pour un apport calorique supplémentaire, ajouter des craquelins, comme les croustillants de pita Kashi à saveur originale, aux 7 grains et au sel de mer.",
      time: 60,
      serves: 4,
      fat: 3.8,
      calories: 334,
      cholesterol: 67,
      carbohydrate: 51,
      saturated: 1,
      sodium: 712,
      fiber: 3,
      difficulty: "moyen",
      category: "dîner",
    },
    {
      title: "Biscuits au pain d'épice, faibles en gras",
      slug: "biscuits-au-pain-depice-faibles-en-gras",
      img: "low-fat-gingerbread-cookies",
      hasImage: true,
      description:
        "Un savoureux dessert des Fêtes qui plaira aux gens qui ont la dent sucrée",
      ingredients:
        "45 ml (3 c. à table) de beurre non salé, ramolli |150 g (3/4 tasse) de cassonade bien tassée |147 g (1/2 tasse) de compote de pommes non sucrée |1 œuf |110 g (1/3 tasse) de mélasse foncée |385 g (3 tasses) de farine tout usage, et un peu plus pour saupoudrer |5 ml (1 c. à thé) de bicarbonate de soude |10 ml (2 c. à thé) de gingembre moulu |5 ml (1 c. à thé) de cannelle moulue |2,5 ml (1/2 c. à thé) de piment de la Jamaïque |1,25 ml (1/4 c. à thé) de clou de girofle, moulu |22,5 ml (1 ½ c. à table) de blancs d’œufs |25 g (1/4 tasse) de sucre en poudre|Une pincée de crème de tartre",
      methods:
        "Dans un grand bol à mélanger, battre le beurre, le sucre et la compote de pommes jusqu’à l’obtention d’une consistance lisse. Ajouter l’œuf et la mélasse et bien mélanger. |Dans un autre grand bol, mélanger la farine, le bicarbonate de soude et les épices. Ajouter au mélange de sucre et de mélasse, en remuant bien. |Diviser la pâte en deux boules plates; enrober d’une pellicule de plastique et mettre au réfrigérateur pendant au moins 2 heures et demie. |Préchauffer le four à 176°C (350°F). Saupoudrer une généreuse quantité de farine sur la surface de votre plan de travail avant d'abaisser la pâte. Préparer une seule boule de pâte à la fois et conserver l'autre au réfrigérateur pendant ce temps.|Rouler la pâte à une épaisseur de 3 mm (1/8 po), saupoudrer un peu de farine sur la pâte si elle est un peu collante. |Découper la pâte de pain d'épice à l'aide d'un emporte-pièce de la forme de votre choix. Si vous utilisez un emporte-pièce plus petit, vous pourriez obtenir 48 biscuits. |Disposer les biscuits en les espaçant de 2,5 cm à 5 cm (1 ou 2 po) sur une plaque à pâtisserie recouverte d'un papier sulfurisé. Cuire durant 10 à 12 minutes.|Mélanger les blancs d’œufs et le jus de citron. Incorporer le sucre en poudre et bien remuer. Si la consistance du glaçage est trop liquide, ajouter plus de sucre en poudre. Si elle est trop épaisse, ajouter un soupçon de jus de citron. Transférer dans une poche à douille pour décorer les biscuits une fois refroidis. |Tout dépendant de la grosseur de l’emporte-pièce que vous utilisez, cette recette vous permet de préparer jusqu’à 48 biscuits.",
      serves: 16,
      fat: 2.7,
      calories: 166,
      cholesterol: 17,
      carbohydrate: 33,
      saturated: 1.5,
      sodium: 90,
      fiber: 1,
      difficulty: "moyen",
      category: "dessert|mets préférés de la famille|christmas",
    },
    {
      title: "Galettes de pommes de terre (latkes) faibles en gras",
      slug: "galettes-de-pommes-de-terre-latkes",
      img: "low-fat-potato-latkes",
      hasImage: true,
      description: "Un savoureux plat traditionnel des Fêtes réinventé et faible en gras.",
      ingredients:
        "225 g (1/2 lb) de pommes de terre, comme les Yukon Gold, Russet, (pommes de terre floconneuses ou à teneur moyenne en amidon) |1 oignon moyen|35 g (1/4 tasse) de farine |2 blancs d’œufs |Poivre noir fraîchement moulu",
      methods:
        "Préchauffer le four à 232°C (450˚F).|Éplucher et râper les pommes de terre et l'oignon à l'aide d'une râpe ou dans un robot culinaire.|Mettre une grande plaque à biscuits dans le four préchauffé. |Verser les pommes de terre et l'oignon râpés dans une passoire et les presser pour en extraire le maximum d'humidité. |Transférer le mélange de pommes de terre et d’oignon dans un grand bol.|Ajouter la farine, les blancs d’œufs et le poivre noir et bien mélanger à la fourchette. |Retirer la plaque à biscuits du four. Vaporiser un enduit à cuisson antiadhésif |Former des rondelles aplaties de 5 cm à 6 cm (2 po à 2 ½ po) avec le mélange de pommes de terre et les déposer sur la plaque à biscuits chaude. |Remettre la plaque à biscuits au four et cuire les rondelles pendant 7 à 8 minutes de chaque côté, jusqu’à ce qu’elles soient croustillantes et dorées. |Pour obtenir des galettes plus croustillantes après la cuisson, les faire griller quelques minutes.|Donne 12 à 15 galettes (latkes) de pommes de terre",
      time: 30,
      serves: 8,
      fat: 0.3,
      calories: 183,
      cholesterol: 0,
      carbohydrate: 40,
      saturated: 0,
      sodium: 37,
      fiber: 3,
      difficulty: "moyen",
      category: "appetizers|mets préférés de la famille",
    },
    {
      title: "Jambalaya",
      slug: "jambalaya",
      img: "low-fat-jambalaya",
      hasImage: true,
      description: "Un savoureux plat cajun classique, facile à préparer et faible en matières grasses!",
      ingredients:
        "227 g (1/2 livre) de crevettes de grosseurs moyennes, décortiquées, déveinées et coupées en morceaux |7,5 ml (1 ½ c. à thé) d'assaisonnement créole pour fruits de mer (comme Tony Chachere’s) |Enduit à cuisson antiadhésif en vaporisateur |5 ml (1 c. à thé) d’huile d’olive ou d’huile de colza |150 g (1 tasse) d’oignon haché |90 g (1/2 tasse) de poivron vert, haché |115 g (1/2 tasse) de céleri haché|30 ml (2 c. à table) d’ail, haché |1 boîte de tomates, en dés, sans sel ajouté |3 feuilles de laurier |5 ml (1 c. à thé) de sauce Worcestershire |5 ml (1 c. à thé) de sauce piquante (comme Tabasco)|190 g (3/4 tasse) de riz blanc |475 ml (2 tasses) de bouillon de poulet faible en sodium |113 g (4 oz) de saucisse de dinde fumée, tranchée |Sel, poivre et sauce piquante, au goût ",
      methods:
        "Dans un bol, mélanger les crevettes et l’assaisonnement créole et remuer pour bien répartir les épices. |Vaporiser le fond d’une grande casserole avec un enduit à cuisson antiadhésif. |Ajouter l’huile, l’oignon, le poivre et le céleri et chauffer à feu moyen-vif, en remuant souvent, pendant 3 minutes. |Ajouter l’ail, les tomates, les feuilles de laurier, la sauce Worcestershire et la sauce piquante. |Incorporer le riz et ajouter lentement le bouillon de poulet. |Cuire à feu moyen jusqu’à ce que le riz absorbe le liquide et devienne tendre, en remuant de temps en temps, durant environ 15 minutes. |Lorsque le riz est tendre à point, ajouter les crevettes et la saucisse. |Poursuivre la cuisson jusqu'à ce que la viande soit cuite, en remuant de temps en temps, pendant environ 10 minutes de plus. |Assaisonner au goût, en ajoutant du sel, du poivre et de la sauce piquante.",
      time: 40,
      serves: 6,
      fat: 3.1,
      calories: 179,
      cholesterol: 71,
      carbohydrate: 25,
      saturated: 0.8,
      sodium: 633,
      fiber: 3,
      difficulty: "moyen",
      category: "dîner|mets préférés de la famille|hearty meal",
    },
    {
      title: "Macaroni au fromage",
      slug: "macaroni-au-fromage",
      img: "mac-and-cheese",
      hasImage: true,
      description:
        "Tellement facile à préparer et délicieusement réconfortant. Servir avec une salade et des brownies faibles en gras pour le dessert.",
      ingredients:
        "425 g (15 oz) de macaronis|2 boîtes de soupe au fromage cheddar, faible en gras|226 g (8 oz) de fromage cheddar râpé, faible en gras",
      methods:
        "Cuire les macaronis selon les indications de l’emballage jusqu’à ce qu’ils soient al dente.|Égoutter et mettre dans un plat de cuisson en verre de 23 cm par 33 cm (9 po par 13 po). |Ajouter la soupe au fromage cheddar et mélanger. |Incorporer le fromage râpé. |Couvrir hermétiquement de papier d'aluminium et cuire au four à 176°C (350°F) pendant environ 1 heure ou jusqu'à ce que le mélange bouillonne.",
      time: 70,
      serves: 8,
      fat: 6,
      calories: 273,
      cholesterol: 6,
      carbohydrate: 40,
      saturated: 4,
      sodium: 515,
      fiber: 6,
      difficulty: "facile",
      category: "dîner|mets préférés de la famille|hearty meal",
    },
    {
      title: "Poulet grillé à la moutarde et à l’érable",
      slug: "poulet-grille-a-la-moutarde-et-a-lerable",
      img: "maple-mustard-grilled-chicken",
      hasImage: true,
      description:
        "Voici une marinade facile à préparer pour vos recettes de poulet, avec des ingrédients que vous avez peut-être déjà dans votre garde-manger.",
      ingredients:
        "Quatre poitrines de poulet de 113 g (4 oz) chacune, désossées, sans peau|85 g (1/4 tasse) de sirop d’érable pur à 100 %|62 g (1/4 tasse) de moutarde de Dijon ou 15 ml (1 c. à table) de moutarde de Dijon en grains « à l’ancienne » avec 45 ml (3 c. à table) de moutarde de Dijon.|15 ml à 30 ml (1 à 2 c. à table) de jus d’un demi-citron |15 ml (1 c. à table) de romarin séché",
      methods:
        "Mettre les poitrines de poulet dans un sac de plastique avec fermeture de 4,5 l.|Dans un petit bol, mélanger les ingrédients pour préparer la marinade. Ensuite, verser la marinade sur le poulet mais garder de 30 ml à 45 ml (2 à 3 c. à table) de marinade pour le badigeonner plus tard. |Retourner le poulet pour l’enrober et laisser mariner pendant une heure ou plus au réfrigérateur.|Retirer le poulet de la marinade et jeter celle-ci.|Chauffer le barbecue et griller le poulet pendant dix minutes de chaque côté, jusqu’à ce qu’il soit entièrement cuit. |Vers la fin de la cuisson, badigeonner chaque poitrine de poulet avec la marinade réservée.|Savourez ce poulet avec votre salade de pommes de terre ou de macaronis préférée, préparée avec de la mayonnaise sans gras. |La marinade peut également être utilisée avec un filet de porc ou de dinde.",
      time: 80,
      serves: 4,
      fat: 3.1,
      calories: 173,
      cholesterol: 83,
      carbohydrate: 7,
      saturated: 0.7,
      sodium: 233,
      fiber: 0,
      difficulty: "moyen",
      category: "dîner|hearty meal",
    },
    {
      title: "Saumon grillé à la moutarde et à l’érable",
      slug: "saumon-grille-a-la-moutarde-et-a-lerable",
      img: "maple-mustard-broiled-salmon",
      hasImage: true,
      ingredients:
        "113 g (4,4 oz) de filets de saumon sauvage de l'Alaska (ne pas remplacer par du saumon d'élevage de l'Atlantique, qui contient deux fois plus de matières grasses) |220 g (1 ¼ tasse) de fleurons de brocoli, congelés |62 g (1/4 tasse) de moutarde de Dijon |85 g (1/4 tasse) de vrai sirop d'érable |15 ml (1 c. à table) d’aneth frais ou séché |1 kg (4 tasses) de riz au jasmin entièrement cuit |poivre noir fraîchement moulu, au goût |enduit à cuisson",
      methods:
        "Décongeler les filets de saumon congelés au réfrigérateur.|Utiliser du riz entièrement cuit ou le préparer selon les indications de l'emballage, sans inclure de beurre, de margarine ou d’huile. |Allumer le gril du four ou le barbecue. |Vaporiser un poêlon à fond cannelé avec un enduit à cuisson et y déposer les ﬁlets de saumon. |Assaisonner avec 2 ou 3 tours de moulin à poivre noir. Mélanger la moutarde, le sirop et l'aneth, et à l'aide d'un pinceau, badigeonner le saumon avec la moitié de cette préparation. |Mettre le poisson sous le gril ou sur le barbecue et cuire pendant 5 minutes. |Mettre les fleurons de brocoli et 1/2 tasse d'eau dans un bol pour le micro-ondes et couvrir.|Mettre au micro-ondes et régler la minuterie pour 5 minutes.|Sortir le poisson et le retourner délicatement avec une spatule, ajouter 2 ou 3 tours de poivre et le badigeonner avec le reste du mélange de moutarde. |Cuire le poisson pendant cinq minutes de plus ou jusqu'à ce qu'il se défasse facilement en morceaux et que la température interne atteigne 54°C (130˚F) lorsqu'un thermomètre à lecture instantanée est inséré dans la partie la plus épaisse du ﬁlet. |Disposer chaque ﬁlet de saumon sur 250 g (1 tasse) de riz chaud. Servir avec 250 g (1 tasse) de brocoli cuit à la vapeur.",
      serves: 4,
      fat: 7,
      calories: 350,
      cholesterol: 50,
      carbohydrate: 46,
      saturated: 2.5,
      sodium: 303,
      fiber: 3,
      difficulty: "moyen",
      category: "dîner|hearty meal",
    },
    {
      title: "Filets de mahi mahi Margarita",
      slug: "filets-de-mahi-mahi-margarita",
      img: "margarita-mahi-mahi-fillets",
      hasImage: true,
      description:
        "Ces filets marinent dans du jus de lime et de la tequila, les ingrédients essentiels d'une margarita. Servir avec du riz espagnol et des haricots noirs. Les restes peuvent être utilisés le lendemain pour préparer des tacos au poisson!",
      ingredients:
        "6 filets de mahi mahi de 113 g (4 oz) chacun (ou d’un autre poisson blanc) |3,75 ml (3/4 c. à thé) de sel, divisée |15 g (1/4 tasse) de feuilles de coriandre fraîche |44 g (1/4 tasse) d’oignon haché |15 ml (1 c. à table) de piment serrano, épépiné, finement haché |45 ml (3 c. à table) de jus de lime frais |15 ml (1 c. à table) de tequila |7,5 ml (1/2 c. à table) d’huile de colza |1 gousse d’ail, hachée |Enduit à cuisson |Ciboulette fraîche, hachée (facultatif) |Quartiers de lime (facultatif) ",
      methods:
        "Disposer les filets en une seule couche dans un plat de cuisson de 33 cm par 23 cm (13 po par 9 po) et les saupoudrer avec 1,25 ml (1/4 de c. à thé) de sel. |Mélanger 2,5 ml (1/2 c. à thé) de sel, la coriandre et les 7 ingrédients suivants (de la coriandre, à la gousse d'ail) dans un robot culinaire ou un mélangeur et mélanger jusqu'à l'obtention d'une consistance homogène. |Verser le mélange de coriandre sur les filets et les retourner pour les enrober. |Laisser mariner au réfrigérateur pendant 30 minutes, en les retournant une fois.|Pour le gril : Chauffer le barbecue à une température élevée. Retirer le poisson du plat et le déposer sur une feuille de papier d'aluminium antiadhésif. Verser le reste de la marinade sur le poisson et refermer le papier d’aluminium. Déposer la papillote sur le barbecue et cuire pendant 10 à 15 minutes, jusqu'à ce que le poisson soit bien cuit et qu'il se défasse facilement en morceaux à la fourchette. Garnir de ciboulette hachée et de quartiers de lime, si désiré. |Pour la cuisson au four : Préchauffer le four à 190 °C (375 ˚F). Placer le poisson mariné dans un plat de cuisson de 33 cm par 23 cm (13 po par 9 po) et le mettre au four. Cuire pendant 7 minutes, retourner le poisson et poursuivre la cuisson pendant 7 minutes de plus. Le poisson doit être opaque et se défaire facilement en morceaux à l’aide d’une fourchette. ",
      serves: 6,
      fat: 0.2,
      calories: 118,
      cholesterol: 83,
      carbohydrate: 1.6,
      saturated: 0.3,
      sodium: 393,
      fiber: 0,
      difficulty: "moyen",
      category: "dîner|hearty meal",
    },
    {
      title: "Crevettes marinées cuites sur le gril",
      slug: "crevettes-marinees-cuites-sur-le-gril",
      img: "marinade-for-grilled-shrimp",
      hasImage: true,
      description:
        "Ces délicieuses crevettes seront un succès lors de votre prochain barbecue.",
      ingredients:
        "454 g (1 lb) (ou plus) de très grosses crevettes décortiquées, déveinées (grosseur 26-30) |3 ou 4 gousses d’ail, émincées |75 ml (1/3 tasse) de sauce soya à teneur réduite en sodium |85 g (1/4 tasse) de miel |169 ml (3/4 tasse) d’eau |Un soupçon de jus de citron ou de jus d'orange |Poivre, au goût ",
      methods:
        "Mélanger les ingrédients et verser sur les crevettes décortiquées et déveinées montées en brochettes. Si vous utilisez des brochettes en bois, assurez-vous de les faire tremper au préalable dans de l'eau.|Laisser mariner les crevettes pendant 30 minutes, puis les faire griller. |Savourez! ",
      serves: 4,
      time: 50,
      fat: 1.8,
      calories: 154,
      cholesterol: 160,
      carbohydrate: 11,
      saturated: 0.3,
      sodium: 488,
      fiber: 0,
      difficulty: "facile",
      category: "dîner|mets préférés de la famille",
    },
    {
      title: "Bouchées aux blancs d'œufs de Martha",
      slug: "bouchees-aux-blancs-dœufs-de-martha",
      img: "marthas-egg-white-bites",
      hasImage: true,
      description:
        "À déguster lors d’un brunch ou à préparer à l'avance pour un déjeuner rapide. Elles sont tout aussi délicieuses le lendemain et elles peuvent également être congelées. Avec cette recette, les jaunes d'œufs ne vous manqueront pas car les légumes rehaussent la saveur de ce plat.",
      ingredients:
        "375 g (2 à 2 ½ tasses) de légumes hachés finement (comme des oignons, des champignons, des poivrons, des épinards, des pommes de terre râpées, du brocoli et des courges d’été)|Un contenant de 453 g (2 tasses) de substitut d’œuf ou de blancs d’œufs|29 g (1/4 tasse) de fromage faible en gras, râpé",
      methods:
        "Préchauffer le four à 176°C (350°F).|Faire revenir les légumes dans une poêle antiadhésive vaporisée avec un enduit à cuisson antiadhésif. Cuire jusqu'à ce que les légumes soient tendres. Sel et poivre, au goût. |Vaporiser un moule pour 12 muffins avec un enduit à cuisson antiadhésif. Répartir uniformément le mélange de légumes dans les 12 cavités du moule à muffins et bien remplir chacune. |Verser le substitut d’œuf sur les légumes et répartir uniformément dans les 12 cavités du moule à muffins. |Répandre une petite quantité de fromage pour garnir le mélange de légumes et de substitut d’œuf.| Cuire au four pendant 20 à 25 minutes ou jusqu’à ce que les bouchées soient cuites. Retirer des moules et servir.|Suggestion de présentation (non comprise dans l'analyse nutritionnelle) – Utiliser les bouchées pour faire un sandwich aux œufs avec un muffin anglais ou du pain grillé. Peuvent également être servies avec de la salsa.",
      serves: 6,
      time: 35,
      fat: 0.3,
      calories: 36,
      cholesterol: 1,
      carbohydrate: 3,
      saturated: 0,
      sodium: 110,
      fiber: 1,
      difficulty: "facile",
      category: "petit déjeuner|végétarien",
    },
    {
      title: "Picadillo à la mexicaine",
      slug: "picadillo-a-la-mexicaine",
      img: "mexican-picadillo",
      hasImage: true,
      description:
        "Ce mets mexicain traditionnel peut être préparé de plusieurs façons différentes. N'hésitez pas à utiliser les légumes de votre choix. Cette savoureuse recette est faible en gras!",
      ingredients:
        "454 g (1 lb) de bœuf haché, maigre à 95 % ou plus |450 g (2 tasses) de pommes de terre coupées en dés (les morceaux plus petits et plus minces cuiront plus rapidement)|225 g (1 ½ tasse) de divers légumes surgelés|88 g (1/2 tasse) de tomates, coupées en dés |26 g (1/2 tasse) d’oignon coupé en dés|90 g (1/2 tasse) de poivron vert, coupé en dés|2,5 ml (1/2 c. à thé) de piment serrano, haché (facultatif))|1 gousse d’ail, écrasée|2,5 ml (1/2 c. à thé) d’origan|2,5 ml (1/2 c. à thé) de cumin moulu|5 ml (1 c. à thé) de sel |225 ml (1 tasse) d’eau",
      methods:
        "Faire dorer le bœuf haché dans une grande poêle à frire à feu moyen, en remuant de temps en temps.|Une fois le bœuf doré, ajouter l’oignon, le poivron vert, le piment serrano, les tomates, l’ail, l’origan, le cumin et le sel. Remuer et cuire pendant 5 minutes. |Ajouter les pommes de terre et l’eau. Laisser mijoter pendant 15 minutes ou jusqu’à ce que les pommes de terre soient tendres.",
      serves: 4,
      time: 40,
      fat: 6.1,
      calories: 281,
      cholesterol: 70,
      carbohydrate: 27,
      saturated: 2.7,
      sodium: 874,
      fiber: 5,
      difficulty: "facile",
      category: "dîner",
    },
    {
      title: "Mini-sandwiches au concombre",
      slug: "mini-sandwiches-au-concombre",
      img: "mini-cucumber-sammys",
      hasImage: true,
      description: "Une recette idéale pour un barbecue.",
      ingredients:
        "1 pain français (coupé en 12 tranches)|1 concombre (coupé en 12 rondelles). Laisser la pelure, si désiré, mais laver d'abord le concombre.|226 g (8 oz) de fromage à la crème frais, ramolli, sans gras|70 g (1/4 tasse) de yogourt grec sans gras|1 sachet de vinaigrette Ranch sèche|Ingrédients facultatifs : sel, poivre, au goût. Aneth, en garniture",
      methods:
        "Mélanger le fromage à la crème, le yogourt grec sans gras et le sachet de vinaigrette Ranch jusqu'à l'obtention d'une consistance crémeuse.|Tartiner une tranche de pain français avec environ 22,5 ml à 30 ml (1 ½ à 2 c. à table) de mélange de fromage à la crème et garnir d’une rondelle de concombre.|Réfrigérer dans un contenant couvert durant au moins une heure avant de servir.|Saupoudrer de sel et de poivre, au goût.|Garnir d’aneth si désiré.",
      serves: 12,
      time: 70,
      fat: 1,
      calories: 97,
      cholesterol: 3,
      carbohydrate: 18,
      saturated: 0.3,
      sodium: 362,
      fiber: 1,
      difficulty: "facile",
      category: "dîner|végétarien",
    },
    {
      title: "Mockamole (faux guacamole)",
      slug: "mockamole-faux-guacamole",
      img: "mockamole",
      hasImage: true,
      description:
        "Si votre trempette préférée à l'avocat, riche en gras, vous manque, cette recette sans les matières grasses est une excellente option.",
      ingredients:
        "1 boîte de 425 g (15 oz) de haricots de Lima, avec le liquide|Jus de 3 limes|25 g (1/2 tasse) de coriandre fraîche|3 échalotes / oignons verts, hachés|3,75 ml (3/4 c. à thé) de sel|1 piment serrano, épépiné, haché (facultatif)",
      methods:
        "Mettre tous les ingrédients dans un mélangeur ou dans un robot culinaire et mélanger jusqu'à l'obtention de la consistance désirée, soit avec de gros morceaux ou une texture crémeuse.",
      serves: 8,
      time: 10,
      fat: 0.1,
      calories: 41,
      cholesterol: 0,
      carbohydrate: 8,
      saturated: 0,
      sodium: 373,
      fiber: 2,
      difficulty: "facile",
      category: "collation|végétarien|rapides et faciles",
    },
    {
      title: "Lait frappé du matin",
      slug: "lait-frappe-du-matin",
      img: "morning-shake",
      hasImage: true,
      description:
        "La recette idéale pour les gens pressés. Facile à emporter!",
      ingredients:
        "225 ml (1 tasse) de lait écrémé |1/2 banane, de grosseur moyenne |50 g (1/2 tasse) de bleuets |5 ml (1 c. à thé) d’extrait de vanille |1 sachet d’édulcorant artificiel |150 g (1 tasse) de glaçons",
      methods: "Mettre tous les ingrédients dans un mélangeur et mélanger jusqu'à l'obtention d'une consistance homogène",
      serves: 1,
      time: 5,
      fat: 0.6,
      calories: 193,
      cholesterol: 5,
      carbohydrate: 38,
      saturated: 0.2,
      sodium: 105,
      fiber: 3,
      difficulty: "facile",
      category: "petit déjeuner|rapides et faciles",
    },
    {
      title: "Bâtonnets de mozzarella",
      slug: "batonnets-de-mozzarella",
      img: "mozzarella-cheese-sticks",
      hasImage: true,
      description:
        "Le hors-d’œuvre idéal si vous recevez ou pour apporter à un événement!",
      ingredients:
        "338 g (1 ½ tasse) de sauce pour pâtes Healthy Choice, à l’ail et aux fines herbes |6 morceaux de fromage à effilocher Sargento, léger |12 feuilles pour wontons |1/4 tasse de blancs d’œufs (2 gros œufs) ",
      methods:
        "Couper chaque bâtonnet de mozzarella en deux. |Disposer les bâtonnets de mozzarella coupés sur une feuille pour wontons et les enrouler, repliant les côtés de la feuille pour garder le fromage à l’intérieur pendant la cuisson. |Disposer uniformément les bâtonnets sur une plaque de cuisson. |Badigeonner les bâtonnets de blancs d'œufs afin de leur donner un fini doré pendant la cuisson et les empêcher de s'assécher. |Cuire au four à 176°C (350°F) pendant 10 à 15 minutes ou jusqu’à ce que le fromage soit fondu à votre goût. |Servir chaud et tremper dans la sauce pour pâtes pour un savoureux hors-d’œuvre!",
      serves: 6,
      time: 25,
      fat: 2.7,
      calories: 130,
      cholesterol: 11,
      carbohydrate: 16,
      saturated: 1.5,
      sodium: 449,
      fiber: 2,
      difficulty: "facile",
      category: "collation|appetizers|mets préférés de la famille|rapides et faciles",
    },
    {
      title: "Tarte aux pommes sans croûte",
      slug: "tarte-aux-pommes-sans-croute",
      img: "no-crust-apple-pie",
      hasImage: true,
      ingredients:
        "6 à 7 pommes (5 à 6 tasses), pelées, évidées et tranchées|15 ml (1 c. à table) de sucre|5 ml (1 c. à thé) de cannelle|75 g (1/3 tasse) de margarine légère, fondue|169 g (3/4 tasse) de sucre |140 g (1 tasse) de farine de blé entier (ou de farine blanche)|1/2 tasse ou 4 blancs d’œufs",
      methods:
        "Mettre les pommes tranchées dans une assiette à tarte. |Mélanger le sucre et la cannelle dans un petit bol. |Répartir ce mélange sur les pommes. |Dans un bol de grosseur moyenne, mélanger le reste des ingrédients. |Verser ce mélange onctueux et crémeux sur les pommes aussi uniformément que possible. |Cuire au four à 176°C (350°F) pendant 1 heure ou jusqu’à ce que les pommes soient dorées.",
      serves: 8,
      time: 75,
      fat: 4,
      calories: 208,
      cholesterol: 0,
      carbohydrate: 42,
      saturated: 1,
      sodium: 86,
      fiber: 3,
      difficulty: "facile",
      category: "dessert|mets préférés de la famille",
    },
    {
      title: "Kugel (casserole) de nouilles",
      slug: "kugel-casserole-de-nouilles",
      img: "noodle-kugel",
      hasImage: true,
      description: "Ce plat populaire durant les Fêtes juives peut être servi chaud ou froid.",
      ingredients:
        "453 g (16 oz) de substitut d’œuf|113 g (1/2 tasse) de sucre|453 g (16 oz) de crème sure sans gras / yogourt grec sans gras|453 g (16 oz) de fromage cottage, sans gras|2 boites de 340 g (12 oz) de lait écrémé, évaporé|30 ml (2 c. à table) de vanille|15 ml (3 c. à thé) de cannelle, en quantités divisées|340 g (12 oz) de nouilles aux œufs sans jaune, cuites",
      methods:
        "Préchauffer le four à 176°C (350°F) et vaporiser un plat de cuisson de 23 cm par 33 cm (9 po par 13 po) avec un enduit à cuisson antiadhésif.|Mélanger les six premiers ingrédients dans un grand bol.|Ajouter seulement 7,5 ml (1 ½ c. à thé) de cannelle au mélange.|Ajouter les nouilles et mélanger tous les ingrédients.|Répartir uniformément dans le plat, couvrir et mettre au réfrigérateur toute la nuit.|Le lendemain, saupoudrer le reste (1 ½ c. à thé) de la cannelle sur le dessus du kugel. |Cuire au four pendant environ 1 heure et 40 minutes.",
      serves: 15,
      time: 120,
      fat: 1.7,
      calories: 218,
      cholesterol: 7,
      carbohydrate: 36,
      saturated: 0.3,
      sodium: 247,
      fiber: 1,
      difficulty: "facile",
      category: "dîner|mets préférés de la famille",
    },
    {
      title: "Salade de pommes de terre à l’ancienne",
      slug: "salade-de-pommes-de-terre-a-lancienne",
      img: "old-fashioned-potato-salad",
      hasImage: true,
      description:
        "Vous pouvez remplacer certains ingrédients par d'autres de votre choix pour obtenir une version faible en gras de ce plat favori pour les pique-niques!",
      ingredients:
        "4 pommes de terre Russet de grosseur moyenne|1 contenant de 1,4 litre (48 oz) de bouillon de poulet sans gras, à faible teneur en sodium |60 g (1/4 tasse) de relish sucrée, marinée|40 g (1/4 tasse) d’oignon haché|115 g (1/2 tasse) de céleri haché|2,5 ml (1/2 c. à thé) de sel|2,5 ml (1/2 c. à thé) de poivre|4 œufs|15 ml (1 c. à table) de moutarde|58 g (1/4 tasse) de mayonnaise Hellmann’s faible en gras ",
      methods:
        "Laver les pommes de terre et ne pas les éplucher. Dans un grand chaudron, ajouter le bouillon de poulet et les pommes de terre (si les pommes de terre ne sont pas entièrement recouvertes de bouillon, ajouter de l'eau pour qu'elles le soient) et porter à ébullition. |Réduire à feu moyen et couvrir. Cuire jusqu’à ce que les pommes de terre soient tendres, environ 30 minutes.|Une fois la cuisson terminée, égoutter et retirer la pelure sous l'eau froide. |Pendant que les pommes de terre cuisent, mettre les œufs dans un petit chaudron et les recouvrir d'eau froide. Porter l’eau à ébullition; couvrir et retirer du feu. Laisser les œufs reposer dans l’eau chaude pendant 12 à 14 minutes. |Retirer les œufs de l'eau, les laisser refroidir et les écaler. Enlever les jaunes des œufs et hacher les blancs d'œufs.|Une fois la cuisson des pommes de terre terminée, les mettre dans un grand bol et les écraser à l'aide d'une fourchette en laissant quelques petits morceaux. |Ajouter la relish, l’oignon, le céleri, les blancs d’œufs, le sel, le poivre, la moutarde et la mayonnaise. |Bien mélanger et réfrigérer jusqu’à ce que les pommes de terre soient refroidies. ",
      serves: 6,
      time: 50,
      fat: 1,
      calories: 136,
      cholesterol: 0,
      carbohydrate: 28,
      saturated: 0,
      sodium: 439,
      fiber: 2,
      difficulty: "facile",
      category: "déjeuner|mets préférés de la famille",
    },
    {
      title: "Gâteau à l’orange",
      slug: "gateau-a-lorange",
      img: "orange-cake",
      hasImage: true,
      description:
        "Ce délicieux gâteau est très moelleux et encore plus savoureux lorsqu'il est légèrement refroidi avant d'être servi.",
      ingredients:
        "1 boîte de préparation pour gâteau à l'orange|1 boîte de garniture de tarte aux pêches|1/2 tasse de mélange Eggbeaters (ou 4 blancs d'œufs) |123 g (1/2 tasse) de crème sure sans gras / yogourt grec sans gras |226 g (8 oz) de fromage à la crème sans gras|1 petite boîte de pouding à la vanille sans gras et sans sucre|Une boite de 566 g (20 oz) d’ananas broyés (non égouttés)|226 g (8 oz) de garniture fouettée Cool Whip sans gras",
      methods:
        "Préchauffer le four à 176°C (350°F). |Bien mélanger les ingrédients du gâteau et verser dans un moule à gâteau vaporisé avec un enduit à cuisson. |Vous pouvez utiliser un moule à cheminée ou un moule à cuisson de 23 cm par 33 cm (9 po par 13 po). |Cuire durant 40 à 45 minutes ou jusqu’à ce qu’un cure-dent inséré au centre du gâteau en ressorte propre.|Laisser refroidir complètement.|Bien mélanger les trois premiers ingrédients du glaçage, puis incorporer la garniture fouettée Cool Whip sans gras. |Glacer le gâteau refroidi.",
      serves: 12,
      time: 60,
      fat: 4,
      calories: 326,
      cholesterol: 3,
      carbohydrate: 65,
      saturated: 1,
      sodium: 534,
      difficulty: "facile",
      category: "dessert|mets préférés de la famille",
    },
    {
      title: "Tomates vertes frites au four",
      slug: "tomates-vertes-frites-au-four",
      img: "oven-fried-green-tomatoes",
      hasImage: true,
      description: "Une option faible en gras d’un autre mets populaire de la région du Sud!",
      ingredients:
        "3 tomates vertes, coupées en tranches de 6 mm (1/4 po) |125 g (1 tasse) de chapelure panko |113 ml (1/2 tasse) de babeurre faible en gras |1 blanc d’œuf (gros œuf) |Enduit à cuisson antiadhésif en vaporisateur |2,5 ml (1/2 c. à thé) de sauce piquante |5 ml (1 c. à thé) de sel |3,75 ml (3/4 c. à thé) de poivre",
      methods:
        "Préchauffer le four à 232°C (450°F). Mettre une plaque de cuisson au four. |Mélanger la chapelure panko, le sel, le poivre et la sauce piquante dans un plat peu profond et réserver. |Dans un grand bol, fouetter le babeurre et l’œuf. |Mettre les tranches de tomates dans le bol avec le mélange de babeurre. |À l'aide d'une cuillère à égoutter, retirer les tomates du mélange et les ajouter à la chapelure panko. |S’assurer que les tranches de tomates sont uniformément enrobées du mélange de chapelure panko. |Retirer la plaque de cuisson chaude du four et la vaporiser avec un enduit à cuisson. Disposer les tranches de tomates enrobées sur la plaque de cuisson chaude et les vaporiser d’une légère couche d’enduit à cuisson. |Cuire au four pendant 15 minutes. |Retourner les tranches de tomates et les vaporiser avec un enduit à cuisson, puis les remettre au four pendant 10 minutes de plus. |Les tomates devraient être croustillantes et dorées. Assaisonner au goût. Servir immédiatement. Servez ce plat avec votre trempette sans gras préférée ou un délicieux chutney!",
      serves: 6,
      time: 45,
      fat: 0.5,
      calories: 60,
      cholesterol: 0.8,
      carbohydrate: 11,
      saturated: 0.1,
      sodium: 476,
      fiber: 1,
      difficulty: "facile",
      category: "appetizers|mets préférés de la famille",
    },
    {
      title: "Okras frits au four",
      slug: "okras-frits-au-four",
      img: "oven-fried-okra",
      hasImage: true,
      description: "Une option faible en gras d'un mets classique de la région du Sud!",
      ingredients:
        "1 livre de gousses d'okras fraîches, équeutées et coupées en morceaux de 1,9 cm (3/4 po) |120 g (1 tasse) de semoule de maïs jaune, moulue sur pierre |113 ml (1/2 tasse) de babeurre faible en gras |1 gros œuf, légèrement battu |Enduit à cuisson antiadhésif en vaporisateur |1,25 ml (1/4 c. à thé) de poivre de Cayenne |2,5 ml (1/2 c. à thé) de sel  |2,5 ml (1/2 c. à thé) de poivre",
      methods:
        "Mettre une plaque de cuisson à rebords dans le four. Préchauffer le four à 232°C (450°F). |Mélanger la semoule de maïs et le poivre de Cayenne dans un plat peu profond et réserver. |Dans un grand bol, fouetter le babeurre et l’œuf. Ajouter les okras, le sel et le poivre. |Remuer pour mélanger et laisser reposer environ 3 minutes pour bien enrober les okras. |À l'aide d'une cuillère à égoutter, retirer les okras du mélange et les ajouter à la semoule de maïs. |Tremper les okras dans la semoule de maïs. |Retirer la plaque de cuisson chaude du four et la vaporiser avec un enduit à cuisson. Disposer les okras enrobés sur la plaque chauffée et les vaporiser légèrement avec un enduit à cuisson. |Cuire au four pendant 25 minutes, et les retourner une fois. |Les vaporiser de nouveau avec l’enduit à cuisson. Poursuivre la cuisson pendant 10 minutes. |Les okras devraient être croustillants et dorés. Assaisonner au goût. Servir immédiatement.",
      serves: 6,
      time: 50,
      fat: 1.9,
      calories: 119,
      cholesterol: 32,
      carbohydrate: 22,
      saturated: 0.5,
      sodium: 257,
      fiber: 4,
      difficulty: "facile",
      category: "dîner|végétarien",
    },
    {
      title: "Poulet frit au four avec frites minces",
      slug: "poulet-frit-au-four-avec-frites-minces",
      img: "oven-fried-chicken-with-skinny-french-fries",
      hasImage: true,
      description:
        "Les aliments frits vous manquent? Vous apprécierez ce mets « non frit ».",
      ingredients:
        "1 à 1 ½ lb (16 à 24 oz) de poitrine de poulet désossée, sans peau|75 g (1/3 tasse) de vinaigrette Ranch sans gras|1 paquet de 94 g (3/4 tasse) de chapelure panko assaisonnée|Frites minces : 4 pommes de terre White Rose|paprika et autres assaisonnements, au besoin",
      methods:
        "Poulet : Préchauffer le four à 204°C (400˚F). |Verser la vinaigrette Ranch dans un grand sac en plastique. Ajouter le poulet et mélanger. |Tremper le poulet dans le mélange de chapelure et disposer sur une plaque de cuisson antiadhésive recouverte de papier d’aluminium. |Cuire au four durant 25 à 30 minutes, en retournant le poulet une fois. |Frites minces : Préchauffer le four à 218°C (425°F).|Trancher les pommes de terre en « frites » épaisses.|Vaporiser une plaque à biscuits recouverte de papier d’aluminium avec un enduit à cuisson antiadhésif.|Répartir les frites sur la plaque et ajouter l’assaisonnement.|Cuire au four pendant environ 30 minutes, en les retournant fréquemment.",
      note: "May replace chicken with pork tenderloins or whiteﬁsh, like cod",
      serves: 4,
      time: 80,
      fat: 2.5,
      calories: 318,
      cholesterol: 68,
      carbohydrate: 43,
      saturated: 0.4,
      sodium: 607,
      difficulty: "facile",
      category: "dîner|mets préférés de la famille",
    },
    {
      title: "Pouding au pain à l'ananas",
      slug: "pouding-au-pain-a-lananas",
      img: "pineapple-bread-pudding",
      hasImage: true,
      description:
        "Cette recette qui ne requiert qu’un seul bol est rapide et facile à préparer. Elle peut être consommée comme dessert, comme déjeuner sucré ou servie lors d'un brunch. Savourez!",
      ingredients:
        "3 ou 4 tranches de pain de blé entier (brisées en petits morceaux à la main)|1 boite de 566 g (20 oz) d’ananas broyé dans son propre jus (égoutter légèrement)|30 ml (2 c. à table) de margarine légère (pour une version sans gras, utiliser du beurre en aérosol sans gras)|85 g (1/2 tasse) d’édulcorant artificiel à la cassonade ou 85 g (1/2 tasse) de cassonade|240 g (1 tasse) de substitut d’œuf|113 ml (1/2 tasse) de lait écrémé ou de lait 1 % ",
      methods:
        "Préchauffer le four à 176°C (350°F). |Faire fondre la margarine. Mettre d'abord la margarine dans un grand bol avec le lait, puis ajouter tous les ingrédients, sauf le pain. Mélanger à la main jusqu’à ce que le tout soit bien homogène.|Ajouter le pain et remuer. |Verser dans un plat de cuisson de 20 cm par 20 cm (8 po par 8 po) ou de 23 cm par 23 cm (9 po par 9 po), vaporisé avec un enduit à cuisson antiadhésif. |Cuire au four pendant 35 à 45 minutes ou jusqu’à ce que le pouding semble prêt et qu’un couteau inséré au centre en ressorte propre.",
      serves: 9,
      time: 55,
      fat: 2.5,
      calories: 133,
      cholesterol: 3,
      carbohydrate: 23,
      saturated: 1.2,
      sodium: 102,
      difficulty: "facile",
      category: "dessert|mets préférés de la famille",
    },
    {
      title: "Gratin de pommes de terre et de brocoli",
      slug: "gratin-de-pommes-de-terre-et-de-brocoli",
      img: "potato-broccoli-bake",
      hasImage: true,
      description:
        "Vous pouvez préparer ce plat en suivant la recette ou en utilisant des restes de purée de pommes de terre sans gras. Doubler la portion pour un repas plus rassasiant.",
      ingredients:
        "3 pommes de terre Russet, lavées, épluchées et coupées en dés|15 ml (1 c. à table) de margarine légère, sans gras trans|5 ml (1 c. à thé) de moutarde|30 ml (2 c. à table) de lait écrémé|sel et poivre, au goût (si désiré)|525 g (3 tasses) de brocoli haché, congelé |29 g (1/4 tasse) de cheddar râpé, faible en gras",
      methods:
        "Préchauffer le four à 176°C (350°F). |Cuire et égoutter les pommes de terre et les mettre en purée avec de la margarine, de la moutarde, du lait, du sel et du poivre. |Cuire le brocoli selon les indications de l’emballage, bien égoutter et incorporer aux pommes de terre. |Mettre dans un plat de cuisson de 20 cm par 20 cm (8 po par 8 po), vaporisé avec un enduit à cuisson antiadhésif. |Ajouter le fromage et cuire au four pendant 15 minutes.",
      serves: 4,
      time: 25,
      fat: 2.5,
      calories: 158,
      cholesterol: 5,
      carbohydrate: 28,
      saturated: 1,
      sodium: 113,
      difficulty: "facile",
      category: "dîner|rapides et faciles|végétarien",
    },
    {
      title: "Poulet effiloché à la mijoteuse",
      slug: "poulet-effiloche-a-la-mijoteuse",
      img: "pulled-chicken-in-a-crock-pot",
      hasImage: true,
      description: "Ne dites à personne à quel point cette recette est facile.",
      ingredients:
        "4 à 6 poitrines de poulet désossées, sans peau|226 à 340 g (8 à 12 oz) de votre sauce barbecue préférée",
      methods:
        "Mettre le poulet et la sauce dans une mijoteuse vaporisée avec un enduit à cuisson antiadhésif et couvrir. |Cuire à feu doux pendant 6 heures ou à feu vif pendant 3 heures. |Avant de servir, défaire les poitrines de poulet à l’aide d’une fourchette pour un repas de poulet effiloché facile à préparer. |Suggestion de présentation * : Servir avec un plat d’accompagnement et une salade ou sur un petit pain de blé entier avec une salade.|*Les garnitures facultatives ne sont pas calculées dans l’analyse nutritionnelle",
      serves: 4,
      fat: 1.5,
      calories: 251,
      cholesterol: 68,
      carbohydrate: 32,
      saturated: 0.4,
      sodium: 757,
      difficulty: "facile",
      category: "dîner|mets préférés de la famille",
    },
    {
      title: "Brownies aux pépites de chocolat et à la citrouille",
      slug: "brownies-aux-pepites-de-chocolat-et-a-la-citrouille",
      img: "pumpkin-chocolate-chip-brownies",
      hasImage: true,
      description:
        "Une gâterie sucrée et chocolatée, idéale à tout moment de l’année et non seulement à l’automne!",
      ingredients:
        "115 g (1/2 tasse) de purée de citrouille |1 œuf entier |1/2 tasse de blancs d’œufs (2 blancs d’œufs) |45 ml (3 c. à table) de yogourt grec à la vanille sans gras |140 g (1 tasse) de farine |5 ml (1 c. à thé) de poudre à pâte  |5 ml (1 c. à thé) de poudre de cacao non sucrée |2,5 ml (1/2 c. à thé) de cannelle moulue |2,5 ml (1/2 c. à thé) de piment de la Jamaïque moulu |1,25 ml (1/4 c. à thé) de muscade moulue |1,25 ml (1/4 c. à thé ) de sel |113 g (2/3 tasse) de cassonade, tassée |90 g (1/2 tasse) de pépites de chocolat mi-sucrées",
      methods:
        "Préchauffer le four à 176°C (350°F). Recouvrir un moule de 28 cm par 18 cm (11 po par 7 po) de papier parchemin.|Dans un grand bol, mélanger la purée de citrouille, les œufs et le yogourt, jusqu’à l’obtention d’une consistance lisse. Mettre de côté. |Dans un autre bol de grosseur moyenne, mélanger la farine, la poudre à pâte, les épices, le sel et la cassonade. Ajouter aux ingrédients liquides et mélanger jusqu’à ce qu’ils soient bien incorporés. Incorporer les grains de chocolat. |Verser dans le moule préparé et répartir uniformément. Cuire au four pendant 15 à 20 minutes, jusqu’à ce qu’un cure-dent inséré au centre des brownies en ressortir propre. |Laisser refroidir complètement avant de couper. ",
      serves: 24,
      time: 45,
      fat: 1.4,
      calories: 63,
      cholesterol: 8,
      carbohydrate: 12,
      saturated: 0.7,
      sodium: 37,
      difficulty: "facile",
      category: "dessert|mets préférés de la famille",
    },
    {
      title: "Crêpes à la citrouille",
      slug: "crepes-a-la-citrouille",
      img: "pumpkin-pancakes",
      hasImage: true,
      description:
        "De délicieuses crêpes plus nourrissantes grâce à l’ajout de citrouille riche en bêta-carotène. Servir avec du vrai sirop d’érable à 100 %.",
      ingredients:
        "10 ml (2 c. à thé) de poudre à pâte |115 g (1/2 tasse) de citrouille en conserve |120 g (1/2 tasse) de substitut d’œuf |175 g (1 ¼ tasse) de farine tout usage |225 ml (1 tasse) de lait écrémé |11,25 ml (2 ¼ c. à thé) d’huile végétale|3,75 ml (3/4 c. à thé) d’épices pour tarte à la citrouille |50 g (1/4 tasse) de sucre",
      methods:
        "Vaporiser un enduit à cuisson sur une plaque chauffante et la chauffer à feu moyen.|Tamiser les ingrédients secs ensemble dans un bol.|Dans un bol, fouetter le lait, la citrouille, le substitut d’œuf et l’huile.|Verser le mélange liquide dans le mélange sec et remuer jusqu'à ce que la préparation soit juste humide, mais grumeleuse. Ne pas trop mélanger.|À l’aide d’une petite louche ou d’une cuillère, verser environ ½ tasse de pâte sur la plaque chauffante et cuire jusqu’à ce que des bulles se forment sur les bords et que la crêpe soit légèrement dorée, soit environ 1 à 3 minutes. Retourner la crêpe et la cuire de l’autre côté jusqu’à ce qu’elle soit dorée, pendant environ 1 minute de plus.|Répéter les étapes avec le reste de la pâte. |Cette recette donne 8 crêpes.",
      serves: 4,
      time: 15,
      fat: 2.8,
      calories: 278,
      cholesterol: 1,
      carbohydrate: 58,
      saturated: 0.6,
      sodium: 355,
      difficulty: "facile",
      category: "petit déjeuner|mets préférés de la famille|rapides et faciles",
    },
    {
      title: "Roulé à la citrouille",
      slug: "roule-a-la-citrouille",
      img: "pumpkin-roll",
      hasImage: true,
      description:
        "Ce délicieux roulé est riche et moelleux et se veut le clou final à tout festin de la période des Fêtes.",
      ingredients:
        "150 g (2/3 tasse) de citrouille en conserve |150 g (3/4 tasse) de sucre |105 g (3/4 tasse) de farine |5 ml (1 c. à thé) de bicarbonate de soude |2,5 ml (1/2 c. à thé) de cannelle |1,25 ml (1/4 c. à thé) de muscade |3 œufs |113 g (4 oz) de fromage à la crème sans gras|113 g (4 oz) de fromage Neufchâtel|60 g (1 tasse) de garniture fouettée Cool Whip sans gras|200 g (1 tasse) de sucre à glacer|2,5 ml (1/2 c. à thé) de vanille",
      methods:
        "Préchauffer le four à 190°C (375°F). |Vaporiser le moule à roulé avec du Pam. |Recouvrir le fond du moule de papier ciré et vaporiser à nouveau avec du Pam. |Mélanger à la main les ingrédients pour la pâte. |Verser la pâte sur le papier ciré et répartir uniformément. |Cuire durant 10 à 12 minutes. |Après la cuisson, renverser le gâteau cuit sur un linge à vaisselle en coton et retirer le papier ciré. |Rouler le gâteau fermement dans la serviette et le laisser refroidir complètement. |Mélanger les ingrédients de la garniture pendant que le gâteau refroidit, puis réfrigérer. |Dérouler le gâteau et étendre la garniture; rouler à nouveau et réfrigérer.",
      serves: 10,
      time: 25,
      fat: 4.3,
      calories: 210,
      cholesterol: 66,
      carbohydrate: 37,
      saturated: 2.0,
      sodium: 270,
      difficulty: "facile",
      category: "dessert|mets préférés de la famille",
    },
    {
      title: "Gâteau au chocolat, rapide et facile à préparer",
      slug: "gateau-au-chocolat-rapide-et-facile-a-preparer",
      img: "quick-and-easy-chocolate-cake",
      hasImage: true,
      description:
        "Tellement facile : il ne faut que 2 ingrédients. Il suffit de doubler la recette pour servir un plus grand nombre de convives.",
      ingredients:
        "1 boîte de mélange à gâteau Super Moist à saveur de chocolat (432 g) (15,25 oz)|1 boîte de purée de citrouille en conserve |60 g (1/4 tasse) de beurre d’arachides faible en gras (ingrédient facultatif mais inclus dans l’analyse nutritionnelle)|56 ml (1/4 tasse) d’eau et 30 ml (2 c. à table) d’eau (utiliser uniquement pour mélanger au beurre d'arachides)",
      methods:
        "Préchauffer le four à 204°C (400˚F). |Vaporiser un moule de 20 cm par 20 cm (8 po par 8 po) avec un enduit à cuisson antiadhésif.|Dans un grand bol, bien mélanger la préparation pour gâteau et la citrouille en conserve.|Verser le mélange uniformément dans le moule préparé.|Dans un autre bol, mélanger le beurre d'arachides et l'eau et déposer en spirale sur la préparation pour gâteau.|Cuire pendant 25 à 30 minutes ou jusqu’à ce qu’un cure-dent inséré dans le gâteau en ressorte propre.",
      serves: 12,
      time: 45,
      fat: 2,
      calories: 163,
      cholesterol: 0,
      carbohydrate: 33.72,
      saturated: 0,
      sodium: 333,
      difficulty: "facile",
      category: "dessert|mets préférés de la famille",
    },
    {
      title: "Dîner rapide aux burritos",
      slug: "diner-rapide-aux-burritos",
      img: "quick-burrito-lunch",
      hasImage: true,
      description:
        "Gardez ces ingrédients de base sous la main pour préparer un dîner rapide et facile. Les haricots pinto ou les haricots noirs sont offerts sous forme de haricots frits sans gras, parfaits pour cette recette.",
      ingredients:
        "100 g (1/2 tasse) de haricots frits sans gras, épicés ou réguliers (réchauffés au four à micro-ondes)|30 ml (2 c. à table) de salsa|50 à 100 g (1/4 à 1/2 tasse) de tomates fraîches hachées|25 g (1/4 tasse) de fromage cheddar ou de fromage mexicain râpé, sans gras ou avec 2 % de matières grasses|1 tortilla de farine sans gras ou faible en gras",
      methods:
        "Mettre la tortilla dans une assiette allant au four à micro-ondes. |Tartiner la tortilla de haricots chauds et de salsa, puis garnir de tomates et de fromage. |Chauffer au four à micro-ondes à puissance élevée. |Replier et déguster!",
      serves: 1,
      time: 10,
      fat: 1.5,
      calories: 251,
      cholesterol: 3,
      carbohydrate: 39,
      saturated: 0,
      sodium: 870,
      difficulty: "facile",
      category: "déjeuner|mets préférés de la famille|rapides et faciles",
    },
    {
      title: "Gratin de légumes et de quinoa",
      slug: "gratin-de-legumes-et-de-quinoa",
      img: "quinoa-veggie-bake",
      hasImage: true,
      description:
        "Facile à préparer et délicieux, ce plat est un excellent complément à n'importe quel repas!",
      ingredients:
        "180 g (1 tasse) de quinoa|450 ml (2 tasses) d’eau|200 g (2 tasses) de cheddar râpé sans gras|350 g (2 tasses) de brocoli, haché|50 g (1 tasse) de carottes, râpées|1 tasse de blancs d’œufs (8 œufs) |5 ml (1 c. à thé) de poivre",
      methods:
        "Cuire le quinoa conformément aux indications sur l’emballage, soit 180 g (1 tasse) de quinoa et 450 ml (2 tasses) d’eau.|Une fois le quinoa cuit et égoutté, le transférer dans un bol, ajouter le fromage et remuer.|Ajouter le brocoli, les carottes, les blancs d’œufs et le poivre au mélange de quinoa et de fromage. Bien remuer.|Verser le mélange dans un plat de cuisson de 23 cm par 33 cm (9 po par 13 po). Cuire pendant 25 à 30 minutes à 218°C (425˚F).",
      serves: 6,
      time: 40,
      fat: 0.8,
      calories: 137,
      cholesterol: 7,
      carbohydrate: 14,
      saturated: 0.1,
      sodium: 474,
      fiber: 2,
      difficulty: "facile",
      category: "déjeuner|mets préférés de la famille|végétarien",
    },
    {
      title: "Vinaigrette Ranch",
      slug: "vinaigrette-ranch",
      img: "ranch-dressing",
      hasImage: true,
      description:
        "Cette vinaigrette peut facilement être préparée quand vous en avez besoin en utilisant des ingrédients de votre garde-manger.",
      ingredients:
        "58 g (1/4 tasse) de mayonnaise sans gras|56 ml (1/4 tasse) de lait écrémé sans gras |70 g (1/4 tasse) de yogourt grec nature, sans gras |15 ml (1 c. à table) de vinaigre de cidre de pomme |5 ml (1 c. à thé) de persil séché |2,5 ml (1/2 c. à thé) de ciboulette séchée |1,25 ml (1/4 c. à thé) de poudre d’oignon |1,25 ml (1/4 c. à thé) d’aneth séché |0,62 ml (1/8 c. à thé) de poudre d’ail |0,625 ml (1/8 c. à thé) de poivre noir ",
      methods: "Mélanger les ingrédients dans un bol et fouetter jusqu’à ce que le mélange soit homogène.",
      serves: 5,
      time: 10,
      fat: 0.4,
      calories: 21,
      cholesterol: 2,
      carbohydrate: 3,
      saturated: 0.1,
      sodium: 164,
      fiber: 0,
      difficulty: "facile",
      category: "mets préférés de la famille|végétarien|rapides et faciles",
    },
    {
      title: "Entrée de champignons à la vinaigrette Ranch",
      slug: "entree-de-champignons-a-la-vinaigrette-ranch",
      img: "ranch-mushroom-appetizer",
      hasImage: true,
      description: "Une excellente option à servir comme entrée ou en plat d’accompagnement!",
      ingredients:
        "454 g (1 lb) de champignons frais|5 ml (1 c. à thé) de pépites à saveur de beurre (p. ex., Butter Buds ou Molly McButter)|1 sachet de mélange de vinaigrette Ranch (28 g) (1 oz)|Eau, au besoin",
      methods:
        "Mettre les champignons dans une mijoteuse.|Mélanger les pépites de beurre et la préparation de vinaigrette Ranch. Ajouter juste assez d’eau pour que le mélange puisse être versé sur les champignons.|Verser le mélange sur les champignons, remuer et cuire à feu doux pendant 3 à 4 heures.|Les champignons finissent par libérer un peu d’eau, ce qui aide à la préparation de la sauce.|Si les champignons semblent secs, ajouter une petite quantité d’eau et remuer pour rendre la sauce plus onctueuse. ",
      serves: 6,
      fat: 0.3,
      calories: 17,
      cholesterol: 0,
      carbohydrate: 5,
      saturated: 0.04,
      sodium: 394,
      fiber: 0.75,
      difficulty: "facile",
      category: "végétarien|appetizer",
    },
    {
      title: "Recette de gombo de fruits de mer de Randy",
      slug: "recette-de-gombo-de-fruits-de-mer-de-Randy",
      img: "randys-seafood-gumbo",
      hasImage: true,
      description:
        "Voici la recette de gombo préférée d’un natif de la Louisiane. Randy aime préparer cette recette dans un grand chaudron. Il congèle les restes pour les utiliser lors des journées très occupées.",
      ingredients:
        "450 g (3 tasses) d’oignons hachés (Vidalia ou autre) et la même quantité de céleri haché|6 gousses d’ail, émincées ou 15 ml (3 c. à thé) d’ail émincé|130 g (1 tasse) de mélange instantané pour préparer un roux|1,8 l (8 tasses) d’eau|133 à 226 g (4 à 8 oz) de sauce tomate (si désiré)|1 paquet de 340 g (12 oz) d’okras congelés, hachés (environ 3 tasses)|5 ml (1 c. à thé) d’assaisonnement créole |454 g (1 lb) de crevettes décortiquées (ou des crevettes congelées, cuites, sans queue)|454 g (1 lb) de chair de crabe (ou de la chair de crabe en conserve, en grumeaux pasteurisés, en préparation spéciale ou la chair de pinces de crabe)|5 ml (1 c. à thé) d’assaisonnement pour gombo (si désiré, mais recommandé par Randy)|2 échalotes / oignons verts, émincés|30 ml (2 c. à table) de persil italien frais, haché|Sauce piquante de la Louisiane (de marque Crystal) ou Tabasco (au goût)|1 250 g (5 tasses) de riz cuit",
      methods:
        "Dans une grande marmite vaporisée avec un enduit à cuisson antiadhésif, faire revenir l’ail, les oignons et le céleri jusqu’à ce que les oignons soient translucides.|Dans un autre chaudron, fouetter 130 g (1 tasse) du mélange instantané pour préparer le roux et 450 ml (2 tasses) d’eau froide et cuire à feu doux ou moyen. |Lorsque le mélange pour le roux commence à épaissir, l’ajouter à la marmite avec 1,35 l (6 tasses) d’eau.|Ajouter la sauce tomate, l’assaisonnement créole et les okras et cuire à feu doux, en remuant souvent.|Ajouter les crevettes et la chair de crabe et poursuivre la cuisson à feu doux pendant 15 minutes.|Ajouter le persil, les échalotes / les oignons verts et l’assaisonnement pour gombo et laisser mijoter pendant 30 minutes avant de servir. Servir sur du riz et ajouter du Tabasco selon le goût de chaque convive.",
      serves: 10,
      time: 60,
      fat: 1.0,
      calories: 262,
      cholesterol: 126,
      carbohydrate: 40,
      saturated: 0,
      sodium: 976,
      fiber: 3,
      difficulty: "moyen",
      category: "dîner|hearty meal",
    },
    {
      title: "Ratatouille",
      slug: "ratatouille",
      img: "ratatouille",
      hasImage: true,
      description:
        "Au début de l’automne, les légumes sont abondants et plusieurs variétés sont disponibles. Profitez-en pour préparer ce savoureux mets végétarien. Cette recette peut être servie comme plat d’accompagnement ou pour garnir un plat de pâtes ou de riz brun et en faire un repas.",
      ingredients:
        "1 gros oignon, haché|2 gousses d’ail, émincées |7,5 ml (1/2 c. à table) d’huile d’olive |1 poivron rouge, haché|1 poivron vert, haché |1 grosse aubergine, pelée et coupée en cubes|3 tomates de grosseur moyenne, hachées |2 courgettes de grosseur moyenne, hachées",
      methods:
        "Dans une grande marmite, faire revenir les oignons et l’ail dans de l’huile d’olive, pendant 2 à 3 minutes. |Ajouter les poivrons et faire revenir 2 minutes de plus. |Ajouter le reste des ingrédients et couvrir. |Laisser mijoter de 45 à 60 minutes ou jusqu’à ce que les légumes soient tendres.",
      serves: 6,
      time: 80,
      fat: 1.8,
      calories: 88,
      cholesterol: 0,
      carbohydrate: 17,
      saturated: 0.3,
      sodium: 14,
      fiber: 6,
      difficulty: "facile",
      category: "dîner|hearty meal",
    },
    {
      title: "Sauté aux pétoncles et aux légumes",
      slug: "saute-aux-petoncles-et-aux-legumes",
      img: "scallop-vegetable-saute",
      hasImage: true,
      description:
        "Ce plat léger et savoureux est tellement facile à préparer. Utilisez les crustacés de votre choix car la plupart d’entre eux ne contiennent pratiquement aucune matière grasse.",
      ingredients:
        "1 ½ lb (24 oz) de pétoncles|60 ml (1/4 tasse) de vin blanc ou de bouillon |150 g (2 tasses) de champignons, tranchés|2 tomates, coupées en morceaux|1 poivron jaune, haché |30 ml (2 c. à table) d’échalotes / d’oignons verts, émincés |2 gousses d’ail, émincées|5 ml (1 c. à thé) d’herbe d’aneth, séchée|le jus d’un citron|poivre noir, au goût|900 g (4 tasses) de riz, de couscous ou de quinoa",
      methods:
        "Faire revenir les champignons, le poivre et les tomates dans un plat à sauter vaporisé avec un enduit à cuisson antiadhésif, pendant 3 à 5 minutes.|Ajouter le vin ou le bouillon et les pétoncles et poursuivre la cuisson durant 5 à 8 minutes.|Ajouter l’aneth et les échalotes / les oignons verts et poursuivre la cuisson pendant 2 minutes, jusqu’à ce que les pétoncles ne soient plus translucides. |Ajouter le jus d’un citron pressé et le poivre, au goût, et servir sur du riz, du couscous ou du quinoa.",
      serves: 4,
      time: 25,
      fat: 1.5,
      calories: 339,
      cholesterol: 60,
      carbohydrate: 49,
      saturated: 0.2,
      sodium: 305,
      protein: 32,
      difficulty: "facile",
      category: "dîner|poisson",
    },
    {
      title: "Gratin de pommes de terre",
      slug: "gratin-de-pommes-de-terre",
      img: "scalloped-potatoes",
      hasImage: true,
      description:
        "Un plat crémeux en cocotte que vous pouvez apporter n’importe où et qui fera toujours sensation! Un régal savoureux qui fait l’unanimité. De plus, personne ne se doutera qu’il s’agit un mets faible en gras!",
      ingredients:
        "70 g (1/2 tasse) de farine non blanchie |20 ml (4 c. à thé) de persil séché |1,25 ml (1/4 c. à thé) de poivre noir moulu |1,1 kg (2 ½ livres) de pommes de terre à cuisson (environ 4 pommes de terre de grosseur moyenne) |2 petits oignons jaunes, tranchés finement et séparés en rondelles |1 gousse d’ail |50 g (1/2 tasse) de parmesan râpé |340 ml (1½ tasse) de lait écrémé",
      methods:
        "Mettre la farine, le persil et le poivre dans un petit bol. Mélanger les ingrédients et réserver.|Frotter les pommes de terre pour les nettoyer avant de les couper en tranches fines de moins de 6 mm (1/4 po) d’épaisseur.|Vaporiser l’enduit à cuisson antiadhésif sur le fond et les côtés d’un plat de cuisson de 20 cm par 30 cm (8 po par 12 po).|Recouvrir le fond du plat avec une couche de pommes de terre tranchées, en chevauchant légèrement les tranches. Ajouter ensuite une couche d’oignons en rondelles sur les pommes de terre (la moitié des oignons tranchées en rondelles) avec la moitié de la gousse d’ail parsemée sur le dessus. Saupoudrer la moitié du mélange de farine sur cette couche. Saupoudrer ensuite uniformément 30 ml (2 c. à table) de parmesan par-dessus. Répéter cette étape pour ajouter une couche de plus de pommes de terre, d’oignons, d’ail, de farine et de fromage.|Verser le lait sur les pommes de terre et saupoudrer le reste du parmesan.|Couvrir le plat avec un papier d’aluminium et cuire au four à 176°C (350°F) pendant 45 minutes. Retirer le papier d’aluminium et cuire 30 minutes de plus ou jusqu’à ce que les pommes de terre soient tendres et que le dessus soit d’un beau brun doré. Laisser reposer pendant 5 minutes avant de servir.",
      serves: 10,
      time: 60,
      fat: 1.3,
      calories: 191,
      cholesterol: 4,
      carbohydrate: 27,
      saturated: 0.8,
      sodium: 105,
      protein: 6,
      difficulty: "facile",
      category: "dîner|végétarien|mets préférés de la famille",
    },
    {
      title: "Linguine aux crevettes et aux artichauts",
      slug: "linguine-aux-crevettes-et-aux-artichauts",
      img: "shrimp-and-artichokes-over-linguine",
      hasImage: true,
      ingredients:
        "1 gros oignon, haché |2 gousses d’ail, émincées |7,5 ml (1/2 c. à table) d’huile d’olive |3 tomates de grosseur moyenne, hachées |1 boite de 396 g (14 oz) de cœurs d’artichaut, coupés en quartiers, dans de l’eau ou de la saumure (sans huile) |169 ml (3/4 tasse) de vin blanc |454 g (1 lb) de grosses crevettes, décortiquées et déveinées |454 g (1 lb) de linguine à grains entiers, cuits ",
      methods:
        "Faire revenir l’oignon et l’ail avec l’huile d’olive dans une poêle à frire antiadhésive pendant quelques minutes. |Ajouter les tomates, les cœurs d’artichaut (égouttés) et le vin blanc. Laisser mijoter pendant 10 minutes. |Ajouter les crevettes et cuire jusqu’à ce qu’elles soient roses et bien cuites à l’intérieur. Mélanger avec les linguines et savourer. ",
      serves: 6,
      time: 25,
      fat: 4.2,
      calories: 400,
      cholesterol: 95,
      carbohydrate: 66,
      saturated: 0.41,
      sodium: 459,
      protein: 22,
      difficulty: "facile",
      category: "dîner|rapides et faciles",
    },
    {
      title: "Crevettes et gruau de maïs",
      slug: "crevettes-et-gruau-de-mais",
      img: "shrimp-and-grits",
      hasImage: true,
      description: "Une version facile à préparer et faible en gras d’un merveilleux mets classique à la mode du Sud!",
      ingredients:
        "340 g (3/4 lb) de crevettes crues, de grosseur moyenne, décortiquées, décongelées (si congelées) |Enduit à cuisson antiadhésif en vaporisateur |30 ml (2 c. à table) d’eau |5 ml (1 c. à thé) ou 7,5 ml (1 ½ c. à thé) si vous préférez les plats plus épicés, d’assaisonnement pour fruits de mer (Tony Chacheres ou un produit similaire)  |5 ml (1 c. à thé) d’huile de colza |90 g (1/2 tasse) de poivron rouge, haché finement |90 g (1/2 tasse) de poivron vert, haché finement |75 g (1/2 tasse) d’oignon blanc, haché |450 ml (2 tasses) de lait écrémé |130 g (1/2 tasse) de gruau de maïs non cuit, à cuisson rapide |1,25 ml (1/4 c. à thé ) de sel |50 g (1/2 tasse) de cheddar fort, râpé, faible en gras |5 ml (1 c. à thé) de sauce Worcestershire ",
      methods:
        "Chauffer une poêle à frire antiadhésive à feu moyen. |Vaporiser le fond de la poêle à frire avec un enduit à cuisson antiadhésif. |Ajouter les crevettes, l’eau et 2,5 ml (1/2 c. à thé) d’assaisonnement pour fruits de mer. |Cuire en remuant fréquemment pendant 5 minutes ou jusqu’à ce que les crevettes soient roses à l’extérieur et opaques au centre. |Verser dans un bol. |À l’aide de la même poêle à frire, chauffer l’huile à feu moyen en la faisant tournoyer pour qu’elle couvre bien le fond. |Cuire les poivrons et les oignons pendant 5 minutes ou jusqu’à ce qu’ils soient tendres, en remuant fréquemment. |Incorporer le lait, le gruau de maïs et le sel et mélanger. |Monter le feu à moyen-vif et porter à ébullition. |Baisser le feu et laisser mijoter la poêle, couverte, pendant 5 minutes ou jusqu’à ce que le mélange épaississe, en remuant de temps en temps. Retirer du feu. |Incorporer le cheddar, la sauce Worcestershire, les crevettes avec tout liquide accumulé et le reste du mélange d’assaisonnement pour fruits de mer, soit 2,5 ml (1/2 c. à thé) ou 5 ml (1 c. à thé) si vous aimez les plats épicés.",
      serves: 5,
      time: 35,
      fat: 4.1,
      calories: 204,
      cholesterol: 119,
      carbohydrate: 21,
      saturated: 1.8,
      sodium: 603,
      difficulty: "moyen",
      category: "dîner|mets préférés de la famille",
    },
    {
      title: "Salade de crevettes et de pâtes",
      slug: "salade-de-crevettes-et-de-pates",
      img: "shrimp-and-pasta-salad",
      hasImage: true,
      description:
        "Ce plat est idéal pour un barbecue estival ou pour un souper rafraichissant durant l’été.",
      ingredients:
        "454 g (1 lb) de rotinis (pâtes en forme de spirales), cuits |454 g (1 lb) de crevettes cuites (grosseur 31 -35) |1 sac de 283 g (10 oz) de pois congelés, décongelés et égouttés |1 grosse tomate, épépinée et coupée en morceaux |225 g (1 tasse) de céleri, haché |50 g (1/3 tasse) d’échalotes / d’oignons verts, hachés |250 g (1 tasse) de vinaigrette Ranch, sans gras |5 ml (1 c. à thé) de raifort préparé |2,5 ml (1/2 c. à thé) d’assaisonnement à l’ail et aux fines herbes Mrs. Dash (ou votre sel assaisonné préféré) |75 g (1 tasse) de laitue, déchiquetée en morceaux",
      methods:
        "Dans un grand bol, mélanger les rotinis, les crevettes, les pois, la tomate, le céleri et les échalotes / oignons verts. |Dans un petit bol, mélanger la vinaigrette, le raifort et l’assaisonnement à l’ail et aux fines herbes Mrs. Dash. |Ajouter le mélange de vinaigrette à la salade; remuer pour bien enrober tous les ingrédients. |Couvrir et bien refroidir. |Ajouter la laitue avant de servir. ",
      serves: 8,
      time: 15,
      fat: 1.7,
      calories: 335,
      cholesterol: 109,
      carbohydrate: 56,
      saturated: 0.3,
      sodium: 358,
      protein: 23,
      difficulty: "facile",
      category: "déjeuner|mets préférés de la famille|rapides et faciles",
    },
    {
      title: "Cocotte de crevettes",
      slug: "cocotte-de-crevettes",
      img: "shrimp-casserole",
      hasImage: true,
      description:
        "Également connue sous le nom de crevettes à l'étouﬀée, cette recette est une version facile à préparer d'un plat cajun très populaire qui ne contient presque pas de gras. Épicez-le plus ou moins selon votre goût.",
      ingredients:
        "150 g (1 tasse) d’oignons, hachés|225 g (1 tasse) de céleri, haché|175 g (1 tasse) de poivrons verts, hachés|5 ml (1 c. à thé) de poudre d’ail|45 ml (3 c. à table) de pâte de tomate|1 boîte de crème de poulet faible en gras; 1 sac de 340 g (12 oz) de crevettes cuites, congelées, de grosseur moyenne|5 ml (1 c. à thé) d’assaisonnement cajun|2,5 ml (1/2 c. à thé) de sauce aux piments épicée",
      methods:
        "Vaporiser un plat pour le four à micro-ondes de 2,2 litres avec un enduit à cuisson antiadhésif.|Ajouter les oignons, le céleri, les poivrons verts et l’ail. |Vaporiser le dessus des légumes avec un enduit à cuisson antiadhésif.|Chauffer au four à micro-ondes pendant 8 minutes.|Retirer du four et remuer, puis ajouter la pâte de tomate, le contenu de la boîte de soupe, les crevettes, l’assaisonnement cajun et la sauce aux piments épicée.|Remettre au four à micro-ondes et cuire 5 minutes de plus, jusqu’à ce que le mélange épaississe.|Facultatif : Servir sur 115 à 225 g (1/2 à 1 tasse) de riz blanc nature ou de riz brun cuit qui ne contient presqu’aucun gras.",
      serves: 4,
      time: 25,
      fat: 2.5,
      calories: 150,
      cholesterol: 118,
      carbohydrate: 14,
      saturated: 1,
      sodium: 567,
      protein: 18,
      difficulty: "facile",
      category: "dîner|rapides et faciles",
    },
    {
      title: "Crevettes créoles",
      slug: "crevettes-creoles",
      img: "shrimp-creole",
      hasImage: true,
      description: "Savourez des fruits de mer préparés à la mode du Sud.",
      ingredients:
        "454 g (1 lb) de crevettes surgelées, cuites, décortiquées et déveinées|1 gros oignon, émincé|2 branches de céleri, coupées en morceaux|1 poivron vert, haché|2 gousses d’ail ou 2,5 ml (1/2 c. à thé) de poudre d’ail|1 boite de 396 g (14 oz) de tomates, coupées en dés, sans sel ajouté|1 boite de 226 g (8 oz) de sauce tomate, sans sel ajouté|10 ml (2 c. à thé) de sauce Worcestershire|1,25 ml (1/4 c. à thé) de thym moulu|2,5 ml (1/2 c. à thé) de paprika|15 à 45 ml (1 à 3 c. à table) d’assaisonnement créole, plus ou moins selon votre goût |Tabasco, au goût (pour un plat plus épicé)|10 ml (2 c. à thé) de sucre (ou 1 sachet d’édulcorant artificiel)|15 ml (1 c. à table) de persil frais, haché",
      methods:
        "Faire revenir les oignons, le céleri, les poivrons et l’ail dans un grand plat à sauter vaporisé avec un enduit à cuisson antiadhésif, pendant 4 à 5 minutes. |Ajouter le reste des ingrédients, sauf les crevettes, et laisser mijoter pour que les saveurs puissent bien se mélanger. |Ajouter les crevettes cuites décongelées et bien chauffer (ne pas trop cuire les crevettes). |Servir sur du riz vapeur ou sur du riz brun.",
      serves: 4,
      time: 20,
      fat: 2.5,
      calories: 186,
      cholesterol: 212,
      carbohydrate: 13,
      saturated: 0,
      sodium: 479,
      protein: 28,
      difficulty: "facile",
      category: "dîner|rapides et faciles",
    },
    {
      title: "Purée de pommes de terre allégée",
      slug: "puree-de-pommes-de-terre-allegee",
      img: "skinny-mashed-potatoes",
      hasImage: true,
      description: "Une façon santé de préparer ce populaire plat réconfortant.",
      ingredients:
        "3 grosses pommes de terre Russet |907 g (32 oz) de bouillon de poulet sans gras, à teneur réduite en sodium |450 ml (2 tasses) d’eau |113 ml (1/2 tasse) de lait écrémé |8 morceaux de fromage à tartiner La Vache qui rit, léger, à l’ail et aux fines herbes ou toute autre saveur de votre choix |Sel et poivre au goût",
      methods:
        "Laver, éplucher et couper les pommes de terre en petits morceaux.|Mettre le bouillon de poulet et les pommes de terre dans un grand chaudron. Ajouter de l’eau pour bien recouvrir les pommes de terre.|Faire bouillir les pommes de terre jusqu’à ce qu’elles soient tendres, puis les égoutter à l’aide d’une passoire.|Mettre les pommes de terre et le lait écrémé dans un bol et mettre en purée avec un mélangeur à vitesse moyenne pendant 1 minute. |Ajouter la moitié du fromage et mélanger à vitesse moyenne jusqu’à ce que le mélange soit bien homogène, puis ajouter l’autre moitié.|Ajouter du sel et du poivre, au goût.",
      serves: 5,
      time: 15,
      fat: 2.5,
      calories: 186,
      cholesterol: 4.5,
      carbohydrate: 29,
      saturated: 1.6,
      sodium: 400,
      protein: 9,
      difficulty: "facile",
      category: "dîner|rapides et faciles|mets préférés de la famille",
    },
    {
      title: "Filet de porc à la cassonade préparé à la mijoteuse",
      slug: "filet-de-porc-a-la-cassonade-prepare-a-la-mijoteuse",
      img: "slow-cooker-brown-sugar-pork-tenderloin",
      hasImage: true,
      description: "Sucré et facile à préparer, ce plat plaira à coup sûr!",
      ingredients:
        "567,5 g (1,25 lb) de filets de porc, parés de gras |43 g (1/4 tasse) de cassonade |88 g (1/4 tasse) de sirop d’érable |188 g (1 ¼ tasse) de pois mange-tout |680 g (1,5 lb ou 24 unités) de pommes de terre rouges miniatures |225 ml (1 tasse) d’eau ",
      methods:
        "Mettre le filet de porc dans la mijoteuse.|Mélanger le sirop et la cassonade dans un bol. |Frotter le mélange de sirop et de cassonade sur le filet de porc de façon à couvrir tout le morceau. Conserver une petite quantité du mélange de sirop dans le bol. |Ajouter les légumes à la mijoteuse.|Ajouter 225 ml (1 tasse) d’eau à la mijoteuse. |Verser le reste du mélange de sirop et de cassonade sur les légumes.|Mettre le couvercle sur la mijoteuse et cuire pendant 7,5 heures à feu doux. ",
      serves: 4,
      fat: 3.4,
      calories: 367,
      cholesterol: 92,
      carbohydrate: 51,
      saturated: 1.1,
      sodium: 113,
      protein: 34,
      difficulty: "moyen",
      category: "dîner|mets préférés de la famille|hearty meal",
    },
    {
      title: "Soupe aux tacos à la mode du sud ",
      slug: "soupe-aux-tacos-a-la-mode-du-sud ",
      img: "south-of-the-border-taco-soup",
      hasImage: true,
      description:
        "Cette source repas facile à préparer sera la bienvenue lors d'une soirée fraîche. Elle peut être préparée avec du bœuf haché « extra maigre » ou de la dinde hachée. Servir avec une salade et des fruits.",
      ingredients:
        "454 g (1 lb) de bœuf haché ou de dinde hachée, sans gras à 95 %|1 oignon, émincé|1 poivron vert, haché (facultatif)|1 boite de 425 g (15 oz) de maïs « sans sel ajouté » ou 340 g (1 ½ tasse) de maïs congelé|1 boite de 425 g (15 oz) de maïs en crème (ne contient pas vraiment de crème!)|1 boîte de tomates en dés, sans sel ajouté, ou rôties si désiré|425 g (15 oz) de haricots pinto (rincés)|55 g (1/4 tasse) de salsa|450 ml (2 tasses) de bouillon de poulet (à faible teneur en sodium)|62 g (1/4 tasse) de vinaigrette Ranch, sans gras|1/2 sachet de mélange d’assaisonnements pour tacos|Garniture facultative * : Croustilles de maïs, faibles en gras, cuites au four et cheddar râpé, sans gras ou faible en gras",
      methods:
        "Dans une marmite vaporisée avec un enduit à cuisson antiadhésif, faire revenir les oignons quelques minutes, ajouter le bœuf ou la dinde et faire dorer jusqu’à ce que les aliments soient bien cuits. |Ajouter le reste des ingrédients et chauffer jusqu’à ce que le tout soit chaud. |Servir dans des bols et garnir de croustilles émiettées et de fromage râpé, si désiré.|* Les garnitures facultatives ne sont pas comprises dans l’analyse nutritionnelle.",
      serves: 5,
      fat: 8,
      calories: 274,
      cholesterol: 55,
      carbohydrate: 36,
      saturated: 0,
      sodium: 765,
      protein: 28,
      difficulty: "facile",
      category: "dîner|hearty meal",
    },
    {
      title: "Poulet épicé avec des nouilles au sésame",
      slug: "poulet-epice-avec-des-nouilles-au-sesame",
      img: "spicy-chicken-with-sesame-noodles",
      hasImage: true,
      description:
        "Un plat rapide et facile à préparer, très rassasiant et vraiment savoureux. Servi de préférence à la température ambiante ou même froid pour les restes.",
      ingredients:
        "680 g (1 ½ lb) de poitrines de poulet, désossées, sans peau|340 g (12 oz) de linguines|2 poivrons colorés (rouge, jaune ou orange), coupés en tranches minces |350 g (2 tasses) de fleurons de brocoli, coupés en morceaux|4 échalotes / oignons verts; trancher les tiges en morceaux de 1,3 cm (1/2 po) et couper le reste en dés|15 ou 30 ml (1 ou 2 c. à table) de mélange d’assaisonnement cajun|15 ml (1 c. à table) de poudre d’oignon|15 ml (1 c. à table) de poudre d’ail|5 ou10 ml (1 ou 2 c. à thé) de sel|15 ml (1 c. à table) de poivre noir|115 g (1/2 tasse) de sauce chili sucrée|30 ml (2 c. à table) d’huile de sésame|Enduit à cuisson ",
      methods:
        "Chauffer l’eau pour les pâtes, cuire selon les directives de cuisson, égoutter et mélanger avec de l’huile de sésame pendant que les nouilles sont chaudes. Laisser refroidir pendant la cuisson du poulet. |Couper le poulet en cubes de 1,9 cm à 2,5 cm (3/4 po à 1 po). |Ajouter l’assaisonnement cajun, la poudre d’oignon et la poudre d’ail, le sel et le poivre dans un sac de plastique à ouverture-éclair. |Mettre le poulet dans le sac, bien secouer pour l’enrober. |Mettre le poulet dans un wok ou un grand poêlon vaporisé avec un enduit à cuisson et cuire à feu moyen-vif jusqu’à ce qu’il soit prêt. |Retirer le poulet du poêlon et vaporiser celui-ci de nouveau. |Ajouter les poivrons, faire revenir pendant 5 minutes, puis ajouter le brocoli et les tiges d’échalotes / d’oignons verts. Faire revenir jusqu’à ce que le brocoli soit tendre. |Mélanger les légumes et le poulet avec les nouilles et bien remuer tous les ingrédients. |Ajouter la sauce chili sucrée et les pointes d’échalotes / d’oignons verts, puis mélanger à nouveau. Servir. ",
      note:
        "To lower fat to 7.4 grams per serving, use only 1 tablespoon sesame oil.| For bigger appetites (and fat allowances) divide recipe by 4 providing 680 calories with 14.6g fat per serving.",
      serves: 6,
      time: 40,
      fat: 9.3,
      calories: 467,
      cholesterol: 83,
      carbohydrate: 57,
      saturated: 1.3,
      sodium: 654,
      protein: 34,
      fiber: 9,
      difficulty: "facile",
      category: "dîner|mets préférés de la famille|rapides et faciles",
    },
    {
      title: "Gratin aux épinards et au fromage",
      slug: "gratin-aux-epinards-et-au-fromage",
      img: "spinach-cheese-bake",
      hasImage: true,
      description:
        "Facile à préparer. Un plat très apprécié. Servir comme plat principal au souper, au dîner ou lors d'un brunch, avec des fruits frais et une salade.",
      ingredients:
        "283 à 340 g (10 à 12 oz) d’épinards congelés, hachés; décongeler et bien égoutter|4 blancs d’œufs|450 g (16 oz) de fromage cottage sans gras |450 g (16 oz) de cheddar ou de mozzarella, avec 2 % de matières grasses, râpé *|45 ml (3 c. à table) de farine|1,25 ml (1/4 c. à thé) de poudre d’ail|poivre noir, au goût",
      methods:
        "Préchauffer le four à 176°C (350°F). |Mélanger tous les ingrédients et les mettre dans un plat de cuisson de 20 cm par 20 cm (8 po par 8 po), vaporisé avec un enduit à cuisson antiadhésif. |Cuire au four pendant 35 à 45 minutes (jusqu’à ce qu’un couteau inséré au centre du gratin en ressorte propre). |Laisser refroidir 10 minutes.",
      note:
        "*To lower fat to 1 g/serving, use nonfat shredded cheese. To add 2 grams of fat/serving, sprinkle with 2 tbsp. of slivered almonds.",
      serves: 4,
      time: 55,
      fat: 7.5,
      calories: 240,
      cholesterol: 10,
      carbohydrate: 14,
      saturated: 4.5,
      sodium: 790,
      protein: 29,
      difficulty: "facile",
      category: "dîner|déjeuner|mets préférés de la famille",
    },
    {
      title: "Boulettes au fromage et aux épinards",
      slug: "boulettes-au-fromage-et-aux-epinards",
      img: "spinach-cheese-balls",
      hasImage: true,
      description:
        "Servez ces hors-d’œuvre faibles en gras à vos convives surtout pendant le temps des Fêtes. Préparer les boulettes une journée à l'avance et les cuire au four au moment opportun. Les restes peuvent être réchauffés au four à micro-ondes.",
      ingredients:
        "283 g (10 oz) d’épinards congelés, hachés; décongeler et bien égoutter|1/2 tasse de substitut d’œuf ou 3 blancs d’œufs|70 g (3/4 tasse) de chapelure|35 g (1/3 tasse) de parmesan|40 g (1/4 tasse) d’oignon, finement émincé|2,5 ml (1/2 c. à thé) d’assaisonnement pour volaille |1,25 ml (1/4 c. à thé) de poudre d’ail|0,62 ml (1/8 c. à thé) de poivre",
      methods:
        "Préchauffer le four à 176°C (350°F). |Mettre tous les ingrédients dans un bol et bien mélanger.|Former 18 boules de 2,5 cm (1 po). Les disposer sur une plaque à biscuits non graissée.|Cuire au four pendant 15 à 20 minutes.|Servir chaudes avec de la moutarde au miel ou une autre trempette, au goût (non comprise dans l’analyse).",
      serves: 4,
      time: 30,
      fat: 2,
      calories: 96,
      cholesterol: 4,
      carbohydrate: 13,
      saturated: 1,
      sodium: 300,
      protein: 7,
      difficulty: "facile",
      category: "appetizer|mets préférés de la famille|rapides et faciles|végétarien",
    },
    {
      title: "Pain plat aux crevettes printanières",
      slug: "pain-plat-aux-crevettes-printanieres",
      img: "springtime-shrimp-flatbread",
      hasImage: true,
      ingredients:
        "4 crevettes géantes (grosseur 16-20 crevettes par livre) |2 tomates italiennes Roma|10 g (1/2 tasse) de basilic frais|15 ml (1 c. à table) d’ail frais, émincé|Sel et poivre|225 g (1 tasse) de jeunes épinards frais|25 g (1/4 tasse) de mozzarella sans gras|1 pita de blé entier",
      methods:
        "Préchauffer le four à 135°C (275°F). |Décortiquer et déveiner les crevettes et les mettre sur un gril ou dans une poêle à frire chaude. Griller les crevettes jusqu'à ce qu’elles soient à moitié cuites. |Retirer les crevettes du feu et les couper en morceaux de 6 mm (1/4 po).|Couper les tomates en quartiers et les mélanger avec l’ail émincé et la moitié du basilic. Ajouter une pincée de sel et de poivre. Cuire au four pendant 20 minutes.|Après avoir retiré les tomates du four, monter la température du four à 204°C (400°F).|Disposer le pain pita sur une plaque à biscuits. Garnir avec le mélange de tomates et de crevettes, les épinards et le mozzarella. |Cuire jusqu’à ce que le tout soit croustillant. Retirer du four et garnir avec le reste du basilic. ",
      serves: 1,
      fat: 4.2,
      calories: 385,
      cholesterol: 188,
      carbohydrate: 44,
      sodium: 660,
      protein: 41,
      difficulty: "moyen",
      category: "déjeuner",
    },
    {
      title: "Lait frappé au chocolat et au beurre ",
      slug: "lait-frappe-au-chocolat-et-au-beurre ",
      img: "stacees-pb2-shake",
      hasImage: true,
      description:
        "Une excellente idée pour un lait frappé avec du chocolat et du beurre d'arachides!",
      ingredients:
        "169 ml (3/4 tasse) de lait écrémé |30 ml (2 c. à table) de poudre de beurre d’arachides|30 ml (2 c. à table) de sirop au chocolat |6 à 8 glaçons",
      methods: "Mettre tous les ingrédients dans un mélangeur et mélanger jusqu’à l’obtention d’une mousse.",
      serves: 1,
      time: 10,
      fat: 2.1,
      calories: 337,
      cholesterol: 7,
      carbohydrate: 66,
      sodium: 286,
      protein: 17,
      fiber: 3,
      difficulty: "facile",
      category: "collation|rapides et faciles|mets préférés de la famille",
    },
    {
      title: "Salade aux fraises et aux épinards",
      slug: "salade-aux-fraises-et-aux-epinards",
      img: "strawberry-spinach-salad",
      hasImage: true,
      description: "Une salade légère et savoureuse, le complément parfait de n’importe quel repas!",
      ingredients:
        "675 g (3 tasses) de jeunes épinards frais |400 g (2 tasses) de fraises fraîches, tranchées|45 ml (3 c. à table) de parmesan râpé|113 ml (1/2 tasse) de vinaigre balsamique|88 g (1/4 tasse) de miel|Une pincée de sel et de poivre",
      methods:
        "Mélanger les épinards, les fraises et le fromage.|Mélanger les ingrédients de la vinaigrette dans un bol de grosseur moyenne, ajouter à la salade et bien remuer.",
      serves: 3,
      time: 10,
      fat: 1.7,
      calories: 178,
      cholesterol: 4,
      carbohydrate: 39,
      sodium: 113,
      protein: 3,
      fiber: 2,
      difficulty: "facile",
      category: "déjeuner|rapides et faciles|végétarien",
    },
    {
      title: "Poivrons farcis",
      slug: "poivrons-farcis",
      img: "stuffed-peppers",
      hasImage: true,
      ingredients:
        "4 gros poivrons verts|227 g (1/2 lb) de bœuf haché, maigre à 96 %|40 g (1/4 tasse) d’oignon haché|2,5 ml (1/2 c. à thé) de poudre de chili|1,25 ml (1/4 c. à thé) de poivre de Cayenne (facultatif)|1 gousse d’ail, émincée|1,23 ml (1/4 c. à thé) de poivre|2,5 ml (1/2 c. à thé) de sel|7,5 ml (1/2 c. à table) de sauce Worcestershire|375 g (1 ½ tasse) de riz brun cuit|50 g (1/2 tasse) de cheddar râpé sans gras, en quantités divisées|1 boite de 226 g (8 oz) de sauce tomate",
      methods:
        "Préchauffer le four à 176°C (350°F).|Mettre la viande dans une grande poêle à frire et cuire à feu moyen, en remuant pour défaire la viande jusqu'à ce qu'elle ait perdu sa teinte rosée. Incorporer l’oignon, la poudre de chili, l’ail, le poivre, le sel et la sauce Worcestershire et poursuivre la cuisson jusqu’à ce que l’oignon soit tendre. |Retirer la poêle à frire du feu et incorporer le riz, la sauce tomate et 25 g (1/4 tasse) de fromage.|Couper le dessus des poivrons et retirer les membranes et les graines. Farcir les poivrons avec le mélange de viande. |Disposer les poivrons debout dans une cocotte. Recouvrir de papier d’aluminium et cuire au four à 176°C (350°F) pendant 55 minutes, puis retirer les poivrons et saupoudrer uniformément le reste du cheddar sur chaque poivron. Remettre au four et cuire 5 minutes de plus sans papier d’aluminium. Servir chaud.",
      serves: 4,
      fat: 3.4,
      calories: 233,
      cholesterol: 31,
      carbohydrate: 34,
      sodium: 766,
      protein: 18,
      fiber: 4,
      difficulty: "facile",
      category: "déjeuner|végétarien",
    },
    {
      title: "Gaspacho aux légumes d’été",
      slug: "gaspacho-aux-legumes-dete",
      img: "summer-veggie-gazpacho",
      hasImage: true,
      description: "Quelle façon agréable de consommer vos portions quotidiennes de légumes.",
      ingredients:
        "450 ml (2 tasses) de jus de tomate ou de jus de légumes, faible en sodium, si désiré|3 tomates, coupées en quartiers|1 poivron vert, coupé en quartiers|1 concombre, pelé et épépiné |1/2 oignon|jus de 1/2 citron|le jus d’une lime|15 ml (1 c. à table) de vinaigre de vin rouge|3,75 ml (3/4 c. à thé) chacun d’estragon et de basilic; et 1/4 tasse de persil frais|une goutte ou deux de sauce aux piments forts |une pincée ou deux de cumin moulu|poivre noir, au goût",
      methods:
        "Mélanger tous les ingrédients dans un robot culinaire en mode intermittent jusqu’à l’obtention de la consistance désirée. |Réfrigérer au moins 2 heures. |Garnir de yogourt ou de crème sure sans gras / yogourt grec sans gras et servir.|Donne 4 tasses.",
      serves: 4,
      fat: 0,
      calories: 56,
      cholesterol: 0,
      carbohydrate: 11,
      sodium: 300,
      protein: 2,
      fiber: 2,
      difficulty: "facile",
      category: "appetizer|végétarien",
    },
    {
      title: "Chili super facile ",
      slug: "chili-super-facile ",
      img: "super-easy-chili",
      hasImage: true,
      description: "Un repas réconfortant pour les soirées fraîches.",
      ingredients:
        "454 g (1 lb) de dinde hachée ou de bœuf haché extra maigre (au moins 95 % sans gras) |1 boite de 396 g (14 oz) de tomates, coupées en dés|1 boite de 170 g (6 oz) de pâte de tomate|1 boite 425 g (15 oz) de haricots pinto ou de haricots rouges, rincés et égouttés|1 oignon, haché|15 ou 30 ml (1 ou 2 c. à table) de poudre de chili|2,5 ml (1/2 c. à thé) de poudre d’ail",
      methods:
        "Dans une grande poêle à frire antiadhésive, faire brunir la dinde et les oignons. |Ajouter le reste des ingrédients et 169 ml (3/4 tasse) d’eau, puis laisser mijoter.",
      serves: 4,
      fat: 6,
      calories: 358,
      cholesterol: 80,
      carbohydrate: 45,
      sodium: 468,
      protein: 31,
      difficulty: "facile",
      category: "dîner|hearty meal|mets préférés de la famille",
    },
    {
      title: "Cocotte de patates douces",
      slug: "cocotte-de-patates-douces",
      img: "sweet-potato-casserole",
      hasImage: true,
      description: "Un mets préféré de la famille!",
      ingredients:
        "1 950 g (6 tasses) de patates douces fraîches, bouillies, sans pelure et en purée|113 ml (1/2 tasse) de lait écrémé|200 g (1 tasse) d’édulcorant artificiel ou d’un autre substitut de sucre non calorique |1/4 tasse (2 œufs) de blancs d’œufs|15 ml (1 c. à table) de vanille|3 garnitures facultatives (non comprises dans l’analyse):| 100 g (1/2 tasse) de cassonade et 100 g (1/2 tasse) d’édulcorant artificiel|100 g (2 tasses) de mini guimauves|Cannelle et muscade",
      methods:
        "Préchauffer le four à 176°C (350°F).|Faire bouillir les patates douces jusqu’à ce qu’elles soient cuites, laisser refroidir et retirer la pelure.|Écraser et fouetter les patates avec le reste des ingrédients liquides.|Verser dans une cocotte de 23 cm par 33 cm (9 po par 13 po) vaporisée avec un enduit à cuisson antiadhésif.|Cuire au four pendant 30 minutes. |Si vous décidez d’utiliser une garniture facultative, l’ajouter au cours des 10 dernières minutes de cuisson.",
      serves: 16,
      time: 60,
      fat: 0.2,
      calories: 100,
      cholesterol: 0,
      carbohydrate: 24,
      sodium: 43,
      protein: 2,
      fiber: 3,
      difficulty: "facile",
      category: "dîner|mets préférés de la famille",
    },
    {
      title: "Caviar du Texas",
      slug: "caviar-du-texas",
      img: "texas-caviar",
      hasImage: true,
      description:
        "Également connu sous le nom de caviar du Texas ou caviar de cowboy, ce hors-d’œuvre est facile à préparer en quelques minutes la veille d’un souper entre amis.",
      ingredients:
        "1 poivron jaune|1 poivron orange|1 poivron rouge|2 piments jalapeños|1 boite de 425 g (15 oz) de haricots à œil noir|1 boite de 425 g (15 oz) de haricots noirs|1 boite de 425 g (15 oz) de maïs sucré blanc|1 bouteille de 453 g (16 oz) de vinaigrette italienne, sans gras|4 tomates Roma (italiennes)|1 bouquet de coriandre",
      methods:
        "Retirer les membranes et les graines des poivrons.|Couper les poivrons jaunes, orange et rouges en dés et les mettre dans un grand bol en plastique.|Hacher finement les piments jalapeños et les mettre dans le même bol.|Égoutter et rincer les haricots noirs, les haricots à œil noir et le maïs, puis les ajouter aux poivrons.|Ajouter la vinaigrette italienne sans gras et bien remuer.|Couvrir et laisser mariner au réfrigérateur pendant au moins 8 heures.|Retirer du réfrigérateur et égoutter l’excédent de liquide du mélange.|Couper les tomates en dés et la coriandre et les incorporer au mélange.",
      serves: 8,
      fat: 1.9,
      calories: 201,
      cholesterol: 1.1,
      carbohydrate: 39,
      sodium: 806,
      protein: 10,
      fiber: 9,
      difficulty: "facile",
      category: "appetizer|mets préférés de la famille|végétarien",
    },
    {
      title: "Salade aux trois haricots",
      slug: "salade-aux-trois-haricots",
      img: "three-bean-salad",
      hasImage: true,
      description:
        "Servez cette salade sucrée et acidulée lors de votre prochain pique-nique ou barbecue familial.",
      ingredients:
        "1 boite de 425 g (15 oz) de haricots verts coupés|1 boite de 425 g (15 oz) de haricots jaunes coupés|1 boite de 425 g (15 oz) de haricots rouge foncé|1 pot de 113 g (4 oz) de piments hachés|1 poivron vert, haché|1 petit oignon doux, haché|2 branches de céleri, hachées|100 g (1/2 tasse) de sucre|150 ml (2/3 tasse) de vinaigre|22,5 ml (1 ½ c. à table) d’huile de colza|5 ml (1 c. à thé) de sel|2,5 ml (1/2 c. à thé) de poivre noir moulu",
      methods:
        "Mettre les 7 premiers ingrédients de la liste dans un grand bol et mélanger.|Fouetter le sucre, le vinaigre, l’huile de colza, le sel et le poivre.|Verser sur le mélange de haricots et réfrigérer avant de servir.",
      serves: 8,
      time: 15,
      fat: 3.3,
      calories: 189,
      cholesterol: 0,
      carbohydrate: 35,
      saturated: 0.3,
      sodium: 539,
      protein: 6,
      fiber: 5,
      difficulty: "facile",
      category: "déjeuner|mets préférés de la famille|rapides et faciles|végétarien",
    },
    {
      title: "Bifteck de haut de ronde sur le gril",
      slug: "bifteck-de-haut-de-ronde-sur-le-gril",
      img: "top-round-steak-for-grill",
      hasImage: true,
      description:
        "Si vous aimez le bœuf, essayez cette recette avec 113 g (4 oz) de bœuf cuit qui contient 5 g de matières grasses au total. Assurez-vous que votre portion ne dépasse pas la consommation quotidienne maximale de matières grasses!",
      ingredients:
        "454 g (1 lb) d’intérieur de haut de ronde, paré de tout gras visible |56 ml (1/4 tasse) de vinaigre balsamique|10 ml (2 c. à thé) de sauce Worcestershire |10 ml (2 c. à thé) de moutarde de Dijon|3 gousses d’ail, émincées|28 ml (1/8 tasse) de jus d’orange|56 ml (1/4 tasse) d’eau|Poivre, au goût",
      methods:
        "Fouetter les ingrédients ensemble.|Attendrir la viande en la piquant plusieurs fois avec une fourchette.|Verser la marinade sur la viande et laisser reposer pendant 4 heures ou toute une nuit.|Cuire la viande jusqu'au degré de cuisson désiré à l’aide d’un thermomètre à viande.|À titre de suggestion, vaporiser un poêlon avec un enduit à cuisson pour faire revenir des oignons tranchés et des poivrons rouges tranchés (en pot, dans une saumure et non pas dans de l'huile). Au moment de servir, déposer à la cuillère sur les tranches de bœuf.",
      serves: 4,
      fat: 5.2,
      calories: 235,
      cholesterol: 95,
      carbohydrate: 10,
      saturated: 2.2,
      sodium: 178,
      protein: 34,
      fiber: 0,
      difficulty: "facile",
      category: "dîner",
    },
    {
      title: "Plat cuisiné de pâtes au thon ",
      slug: "plat-cuisine-de-pates-au-thon",
      img: "tuna-pasta-un-casserole",
      hasImage: true,
      description:
        "Cette recette est une alternative pratique et faible en matières grasses du plat cuisiné traditionnel de nouilles au thon. Vous pouvez préparer ce plat avec des aliments que vous avez dans votre garde-manger... faciles à garder à portée de la main.",
      ingredients:
        "1 boite de 340 g (12 oz) de thon dans l’eau|1 boite de 283 g (10 oz) de crème de champignons| 113 ml (1/2 tasse) de lait écrémé|75 g (1/2 tasse) de pois congelés|1 boite de 226 g (8 oz) de champignons, tranchés|petit pot de piments|2,5 ml (1/2 c. à thé) de poudre d’ail|poivre blanc ou poivre noir, au goût|Beurre en aérosol sans gras|45 ml (3 c. à table) de parmesan, comme garniture (si désiré)|800 g (4 tasses) de pâtes cuites, au choix",
      methods:
        "Dans un bol allant au four à micro-ondes, mélanger les champignons, la soupe et le lait et bien remuer jusqu’à l’obtention d’une consistance homogène. Ajouter le thon, les pois, les piments et les épices.|Vaporiser du beurre en aérosol à 15 reprises, si désiré, et bien remuer. |Chauffer pendant 4 minutes à puissance élevée.|Servir sur 1 tasse de pâtes cuites.|Garnir chaque portion de 10 ml (2 c. à thé) de parmesan, au goût",
      serves: 4,
      time: 25,
      fat: 3,
      calories: 331,
      cholesterol: 35,
      carbohydrate: 48,
      saturated: 0.5,
      sodium: 510,
      protein: 28,
      difficulty: "facile",
      category: "dîner|mets préférés de la famille|rapides et faciles",
    },
    {
      title: "Chili à la dinde à la mijoteuse",
      slug: "chili-a-la-dinde-a-la-mijoteuse",
      img: "turkey-chili-in-a-crock-pot",
      hasImage: true,
      description:
        "Cette recette est tellement facile – préparer le matin, laisser dans la mijoteuse pendant la journée, et le souper est prêt au retour du travail.",
      ingredients:
        "10 ml (2 c. à thé) d’huile de colza|1 oignon doux moyen, haché |2 gousses d’ail, émincées|454 g (1 lb) de poitrines de dinde hachées, extra maigres (sans gras)|1 boîte de 425 g (15 oz) de maïs sans ajout de sel (ou utiliser un produit équivalent congelé)|1 boîte de 425 g (15 oz) de tomates en dés sans ajout de sel|1 boîte de 425 g (15 oz) de tomates en dés avec piments verts|1 boîte de 425 g (15 oz) de haricots rouges foncés Goya, à faible teneur en sodium|1/2 sachet de mélange d’assaisonnement au chili, à teneur réduite en sodium|7,5 ml (1/2 c. à table) de poudre de chili|7,5 ml (½ c. à table) d’assaisonnement Fiesta à la lime, sans sel",
      methods:
        "Faire revenir l’oignon et l’ail dans l’huile de colza. |Ajouter la dinde et cuire jusqu’à ce qu’elle soit dorée et entièrement cuite. |Mettre le mélange à la mijoteuse. |Incorporer le reste des ingrédients et laisser mijoter dans la mijoteuse pendant 4 à 6 heures.|Garnir de crème sure sans gras ou de yogourt grec sans gras.",
      serves: 6,
      fat: 3,
      calories: 248,
      cholesterol: 47,
      carbohydrate: 29,
      saturated: 0.1,
      sodium: 410,
      protein: 26,
      difficulty: "facile",
      category: "dîner|mets préférés de la famille|hearty meal",
    },
    {
      title: "Pâté aux tamales de dinde",
      slug: "pate-aux-tamales-de-dinde",
      img: "turkey-tamale-pie",
      hasImage: true,
      description: "Un mets chaud et rassasiant.",
      ingredients:
        "1 oignon, émincé|1 poivron vert, haché|340 g à 454 g (3/4 lb à 1 lb) de dinde hachée extra maigre (99 % sans gras)|175 g (1 tasse) de maïs congelé|1 boite de 453 g (16 oz) de haricots pinto, rincés|1 boite de 113 g (4 oz) de piments chilis verts, rôtis sur le feu (doux)|1 boite de 226 g (8 oz) de sauce tomate (sans sel ajouté, si désiré)|55 g (1/4 tasse) de salsa|15 ml ou 30 ml (1 ou 2 c. à table) de poudre de chili|45 ml à 60 ml (3 à 4 c. à table) d’eau|120 g (1 tasse) de semoule de maïs|560 ml (2 ½ tasses) d’eau|1,25 ml (1/4 c. à thé) de sel, si désiré",
      methods:
        "Préchauffer le four à 190°C (375°F). |Faire revenir les oignons et les poivrons dans une poêle vaporisée avec un enduit à cuisson antiadhésif. |Ajouter la dinde hachée et faire revenir jusqu’à ce qu’elle ne soit plus rose. |Ajouter le maïs, les haricots, les piments chilis, la sauce tomate, la salsa, la poudre de chili et l’eau, puis remuer. |Verser la garniture dans un plat de cuisson de 20 cm par 20 cm (8 po par 8 po) ou de 23 cm par 23 cm (9 po par 9 po), vaporisé avec un enduit à cuisson antiadhésif.|Dans une petite casserole, mélanger l’eau, la semoule de maïs et le sel.|Porter à ébullition, puis réduire le feu et remuer pendant 1 minute, jusqu’à ce que la garniture épaississe légèrement. Répartir sur la garniture.|Cuire au four pendant 30 minutes.",
      serves: 4 - 6,
      fat: 2,
      calories: 270,
      cholesterol: 33,
      carbohydrate: 39,
      saturated: 0,
      sodium: 389,
      protein: 24,
      difficulty: "facile",
      category: "dîner|mets préférés de la famille|hearty meal",
    },
    {
      title: "Dinde tetrazzini ",
      slug: "dinde-tetrazzini ",
      img: "turkey-tetrazzini",
      hasImage: true,
      description:
        "Le plat réconfortant idéal que vous pouvez préparer à l'avance et réfrigérer, et simplement mettre au four au moment de servir.",
      ingredients:
        "283 g (10 oz) de nouilles spaghetti, non cuites |454 g (1 livre) d'escalopes de poitrine de dinde |2,5 ml (1/2 c. à thé) de sel, en quantités divisées |2,5 ml (1/2 c. à thé) de poivre noir, en quantités divisées |30 ml (2 c. à table) de xérès sec, facultatif |1 paquet de 453 g (16 oz) de champignons prétranchés |40 g (1/4 tasse) d’oignons, hachés |115 g (3/4 tasse) de pois surgelés, décongelés |169 ml (3/4 tasse) de lait écrémé |163 g (2/3 tasse) de crème sure sans gras ou de yogourt grec sans gras |35 g (1/3 tasse) de parmesan râpé |1 boîte de crème de poulet à teneur réduite en gras (Campbell’s Demande Santé) |Enduit à cuisson |30 g (1/3 tasse) de chapelure, sèche",
      methods:
        "Préchauffer le four à 232°C (450°F). |Cuire les pâtes selon les indications sur l’emballage, sans ajouter de sel ni d’élément gras. Égoutter. |Couper les poitrines de dinde en morceaux et saupoudrer de 1,25 ml (1/4 c. à thé) de sel et de 1,25 ml (1/4 c. à thé) de poivre. Vaporiser la poêle à frire d’un enduit à cuisson et chauffer à feu moyen-vif. Mettre la dinde dans la poêle et cuire jusqu’à ce qu’elle ne soit plus rose. Retirer la dinde de la poêle. |Ajouter l’oignon, les champignons et le xérès à la poêle. Couvrir et cuire durant 4 minutes ou jusqu’à ce que le tout soit tendre. |Dans un grand bol, mélanger les pois, le lait, la crème sure / le yogourt grec, le fromage et la soupe. Ajouter le reste du sel et du poivre ainsi que le mélange de dinde, de pâtes et de champignons au mélange de soupe. Remuer délicatement pour bien mélanger les ingrédients. Verser le mélange à la cuillère dans un plat de cuisson de 33 cm par 23 cm (9 po par 13 po), vaporisé avec un enduit à cuisson.|Parsemer la chapelure sur le mélange de pâtes. Cuire au four à à 232°C (450˚F) pendant 12 minutes ou jusqu’à ce que le mélange bouillonne et soit bien chaud.",
      serves: 6,
      fat: 5.3,
      calories: 408,
      cholesterol: 49,
      carbohydrate: 58,
      saturated: 1.5,
      sodium: 650,
      protein: 32,
      fiber: 5,
      difficulty: "moyen",
      category: "dîner|mets préférés de la famille|hearty meal",
    },
    {
      title: "Rouleaux au fromage à la crème et aux légumes",
      slug: "rouleaux-au-fromage",
      img: "vegetable-cream-cheese-roll-ups",
      hasImage: true,
      description:
        "Ces petits rouleaux sont une délicieuse surprise à savourer quand vous avez envie d’un changement. ",
      ingredients:
        "226 g (8 oz) de fromage à la crème sans gras|1 concombre, pelé, épépiné et émincé|1 poivron rouge, émincé|1 tomate Roma italienne, émincée; 1,25 ml (1/4 c. à thé) de poudre d’ail|30 ml (2 c. à table) de ciboulette séchée|3 tortillas à la farine, faible en gras (de la grosseur d’un taco souple)",
      methods:
        "Mélanger tous les ingrédients (sauf les tortillas) et laisser reposer au réfrigérateur pendant 2 heures. |Répartir le mélange uniformément sur 2 ou 3 tortillas à la farine. |Rouler serré et couper en petits rouleaux d’environ 1,3 cm (1/2 po) d’épaisseur.|Servir sur un plateau.",
      serves: 3,
      fat: 3,
      calories: 235,
      cholesterol: 20,
      carbohydrate: 30,
      saturated: 1,
      sodium: 813,
      protein: 22,
      difficulty: "facile",
      category: "dîner|mets préférés de la famille|végétarien",
    },
    {
      title: "Chili végétarien",
      slug: "chili-vegetarien",
      img: "vegetarian-chili",
      hasImage: true,
      description:
        "Vous désirez un plat chaud et délicieux pour le souper de ce soir? Essayez cette recette végétarienne de chili avec une croustade de soya. Le bœuf haché ne vous manquera pas du tout.",
      ingredients:
        "15 ml (1 c. à table) d’huile de colza |1 gros oignon, haché |1 ou 2 gousses d’ail, émincées|1/2 poivron rouge, haché|1/2 poivron vert, haché|1 piment jalapeño, coupé en dés|2 boites de 793 g (28 oz) de tomates entières, pelées|45 ml (3 c. à table) de poudre de chili|2,5 ml (1/2 c. à thé) de cumin|2,5 ml (1/2 c. à thé) de poivre noir|2,5 ml (1/2 c. à thé) d’origan|2,5 ml (1/2 c. à thé) de votre sauce piquante préférée (facultatif)|2,5 ml (1/2 c. à thé) de poivre de Cayenne (facultatif)|2 boites de 439 g (15,5 oz) de haricots rouges, à faible teneur en sodium, égouttés",
      methods:
        "Faire revenir l’oignon, l’ail et les poivrons dans l’huile de colza jusqu’à ce qu’ils soient tendres. |Dans une grande marmite, écraser les tomates à la main et ajouter le mélange de poivrons et d’oignon. |Ajouter la poudre de chili, le cumin, le poivre noir, l’origan et la sauce piquante et le poivre de Cayenne (ces deux derniers ingrédients sont facultatifs), selon votre préférence. |Laisser mijoter pendant 45 minutes. |Ajouter les haricots rouges, puis laisser mijoter pendant 15 minutes de plus.|Garnir de crème sure sans gras ou de yogourt grec sans gras, au goût. ",
      serves: 8,
      time: 75,
      fat: 4.3,
      calories: 231,
      cholesterol: 0,
      carbohydrate: 35,
      saturated: 0,
      sodium: 618,
      protein: 16,
      difficulty: "facile",
      category: "dîner|mets préférés de la famille|végétarien",
    },
    {
      title: "Salade de tacos végétarienne",
      slug: "salade-de-tacos-vegetarienne",
      img: "veggie-taco-salad",
      hasImage: true,
      description:
        "Une salade de tacos avec une préparation « végétarienne » imitant le bœuf haché.... qui ne vous manquera même pas.",
      ingredients:
        "300 g (4 tasses) de laitues vertes mélangées|8 à 10 tomates cerises, coupées en deux |1/4 oignon rouge, émincé|1/2 poivron vert, haché|1 poivron jaune ou rouge, haché |75 g (1/2 tasse) de maïs congelé, décongelé|1 boite de 453 g (16 oz) de haricots noirs, à teneur réduite en sel; rincer et égoutter|60 ml (4 c. à table) d’olives noires tranchées (ou environ 4 grosses olives noires, tranchées)|1 tasse (1/2 paquet de 12 oz) de préparation végétarienne « miettes de viande », saveur de taco|55 g (1/4 tasse) de salsa|1 portion de croustilles de maïs, faible en gras, cuites au four (1 oz ou environ 18 croustilles)",
      methods:
        "Faire revenir la préparation végétarienne imitant le bœuf haché pendant 5 à 10 minutes pour bien chauffer.|Mettre les autres ingrédients, sauf la salsa et les croustilles, dans un grand bol de service.|Recouvrir de la préparation végétarienne réchauffée, de salsa et de croustilles de maïs émiettées.",
      serves: 2,
      time: 15,
      fat: 3.5,
      calories: 408,
      cholesterol: 0,
      carbohydrate: 71,
      saturated: 0,
      sodium: 648,
      protein: 30,
      difficulty: "facile",
      category: "déjeuner|collation|végétarien|rapides et faciles",
    },
    {
      title: "Salade Waldorf ",
      slug: "salade-waldorf ",
      img: "waldorf-salad",
      hasImage: true,
      description: "Un plat d’accompagnement facile à préparer en seulement quelques minutes! ",
      ingredients:
        "2 grosses pommes (rouges ou vertes) (coupées en dés)|100 g (1 tasse) de raisins, (coupés en deux)|450 g (2 tasses) de céleri haché |125 g (1/2 tasse) de yogourt sans gras|15 ml (1 c. à table) de jus de citron|30 ml (2 c. à table) de vinaigre de cidre de pomme|5 ml (1 c. à thé) d’estragon séché",
      methods:
        "Couper les pommes en dés.|Mélanger les pommes, les raisins et le céleri dans un grand bol|Ajouter le reste des ingrédients dans le bol et mélanger.|Servir immédiatement ou couvrir et réfrigérer et servir plus tard.",
      serves: 7,
      time: 15,
      fat: 0.2,
      calories: 59,
      cholesterol: 0.8,
      carbohydrate: 12,
      saturated: 0.05,
      sodium: 25,
      protein: 2,
      fiber: 2,
      difficulty: "facile",
      category: "déjeuner|végétarien|rapides et faciles",
    },
    {
      title: "Trempette chaude aux artichauts",
      slug: "trempette-chaude-aux-artichauts",
      img: "warm-artichoke-dip",
      hasImage: true,
      description:
        "Gardez ces ingrédients dans votre garde-manger et vous serez toujours en mesure de recevoir des amis!",
      ingredients:
        "2 boites de 425 g (15 oz) de cœurs d’artichaut dans de l’eau, bien égouttées |50 g (1/2 tasse) de parmesan râpé |115 g (1/2 tasse) de mayonnaise sans gras (Kraft offre ce produit) |2,5 ml (1/2 c. à thé) de poudre d’ail |Enduit à cuisson antiadhésif |24 biscottes Melba ",
      methods:
        "Préchauffer le four à 176°C (350°F).| Mettre tous les ingrédients dans un mélangeur ou dans un robot culinaire et réduire en purée jusqu'à l'obtention d'un mélange homogène avec une légère texture. | Transférer dans un petit plat de cuisson (que vous utiliserez également pour le service), vaporisé avec un enduit à cuisson antiadhésif.| Cuire pendant 25 à 30 minutes, jusqu’à ce que les bords soient chauds et bruns. | Servir avec des craquelins nature, faibles en gras, comme des biscottes Melba Classic (qui ne contiennent aucun gras) ou utiliser comme trempette pour des carottes.",
      serves: 8,
      time: 40,
      fat: 1.8,
      calories: 111,
      cholesterol: 3,
      carbohydrate: 20,
      saturated: 1,
      sodium: 357,
      protein: 4,
      difficulty: "facile",
      category: "collation|végétarien|rapides et faciles",
    },
    {
      title: "Saucisse pour le déjeuner de la fin de semaine",
      slug: "saucisse-pour-le-dejeuner-de-la-fin-de-semaine",
      img: "weekend-breakfast-sausage",
      hasImage: true,
      description:
        "Ces saucisses sont tellement délicieuses. Personne ne se doutera qu'elles ont été préparées avec de la poitrine de dinde.",
      ingredients:
        "454 g (1 lb) de poitrine de dinde hachée, extra maigre (sans gras à 99 %)|1 tranche de pain de blé entier, réduite en chapelure|1 blanc d’œuf|150 g (2 tasses) de champignons portobello, hachés dans un robot culinaire|10 ml (2 c. à thé) de sauge moulue|2,5 ml (1/2 c. à thé) de thym moulu|2,5 ml (1/2 c. à thé) de poivre de Cayenne (ou un peu moins pour un goût plus doux) |1,25 ml (1/4 c. à thé) de poudre d’ail|2,5 ml (1/2 c. à thé) de sel|poivre noir, au goût|4 feuilles de laurier",
      methods:
        "Dans un grand bol, bien mélanger tous les ingrédients (sauf les feuilles de laurier).|Former 12 galettes. Frire les galettes « à sec » dans une poêle antiadhésive vaporisée avec un enduit à cuisson antiadhésif. |Ajouter les feuilles de laurier pour plus de saveur. |Retourner et cuire jusqu’à ce que les galettes soient cuites. ",
      serves: 4,
      time: 25,
      fat: 1.5,
      calories: 146,
      cholesterol: 55,
      carbohydrate: 5,
      saturated: 0.5,
      sodium: 350,
      protein: 28,
      difficulty: "facile",
      category: "petit déjeuner|rapides et faciles",
    },
    {
      title: "Trempette aux haricots blancs",
      slug: "trempette-aux-haricots-blancs",
      img: "white-bean-dip",
      hasImage: true,
      description: "C’est une recette santé.",
      ingredients:
        "2 boites de 425 g (15 oz) de petits haricots blancs ou de haricots cannellini, égouttés|15 ml (1 c. à table) d’origan ou de fines herbes italiennes séchées|2,5 ml (1/2 c. à thé) de poudre d’ail |56 ml (1/4 tasse) d’eau",
      methods:
        "Mettre tous les ingrédients dans un mélangeur ou dans un robot culinaire.|Mélanger jusqu’à l’obtention d’une consistance lisse. |Servir avec des légumes coupés ou avec des craquelins faibles en gras.",
      serves: 8,
      time: 10,
      fat: 0.4,
      calories: 85,
      cholesterol: 0,
      carbohydrate: 14,
      saturated: 0,
      sodium: 226,
      protein: 5,
      fiber: 5,
      difficulty: "facile",
      category: "collation|rapides et faciles",
    },
    {
      title: "Couscous pilaf au blé entier",
      slug: "couscous-pilaf-au-ble-entier",
      img: "whole-wheat-couscous-pilaf",
      hasImage: true,
      description:
        "Le couscous est composé de graines de semoule ou d'autres grains de blé concassés. Pour ce plat, le couscous est préparé avec des légumes coupés en morceaux et des pois chiches.",
      ingredients:
        "225 ml (1 tasse) de bouillon de légumes sans gras |124 g (3/4 tasse) de couscous de blé entier |175 g (1 tasse) ou plus de fleurons de brocoli |1 ou 2 carottes, râpées|8 champignons ou plus, tranchés |2 échalotes / oignons verts, tranchés|75 g (1/2 tasse) de pois, congelés|1 tasse de pois chiches, à teneur réduite en sel, rincés|1 gousse d’ail, émincée|5 ml (1 c. à thé) d’huile de colza ou d’huile d’olive",
      methods:
        "Porter le bouillon à ébullition.|Ajouter le couscous. Retirer du feu et laisser reposer le chaudron couvert pendant 5 minutes. |Dans un plat à sauter, faire revenir l’ail avec de l’huile puis ajouter les légumes et les pois chiches.|Faire revenir pendant environ 5 à 8 minutes. |Ajouter le mélange de légumes au couscous, remuer et servir.",
      serves: 4,
      time: 25,
      fat: 1.5,
      calories: 233,
      cholesterol: 0,
      carbohydrate: 45,
      saturated: 0,
      sodium: 155,
      protein: 11,
      difficulty: "facile",
      category: "déjeuner|rapides et faciles|végétarien",
    },
    {
      title: "Pain aux courgettes faible en gras",
      slug: "pain-aux-courgettes-faible-en-gras",
      img: "low-fat-zucchini-bread",
      hasImage: true,
      description:
        "Vous ne savez pas quoi faire de toutes les courgettes récoltées dans votre jardin? Essayez cette recette facile pour une délicieuse collation sucrée et santé.",
      ingredients:
        "490 g (3 ½ tasses) de farine |5 ml (1 c. à thé) de poudre à pâte  |5 ml (1 c. à thé) de bicarbonate de soude |2,5 ml (½ c. à thé) de sel |10 ml (2 c. à thé) de cannelle |2,5 ml (1/2 c. à thé) de muscade |180 g (3/4 tasse) de substitut d’œuf |350 g (1 ¾ tasse) de sucre |190 g (3/4 tasse) de yogourt nature sans gras |7,5 ml (1 ½ c. à thé) d’extrait de vanille|300 g (2 tasses) de courgettes râpées grossièrement et légèrement tassées |150 g (1 tasse) de raisins secs (facultatif)",
      methods:
        "Préchauffer le four à 176°C (350°F). |Vaporiser deux moules à pain de 23 cm par 13 cm (9 po par 5 po) avec un enduit à cuisson. |Tamiser les ingrédients secs ensemble dans un grand bol. |Dans un autre grand bol, battre le substitut d’œuf, le sucre, le yogourt et la vanille. |Ajouter graduellement les ingrédients secs aux ingrédients liquides. |Incorporer les courgettes et les raisins secs (ingrédient facultatif). |Répartir dans les 2 moules à pain. |Cuire au four pendant 50 minutes (ou jusqu’à ce qu’un cure-dent inséré au centre du pain en ressorte propre).",
      serves: 24,
      time: 70,
      fat: 0.5,
      calories: 135,
      cholesterol: 0,
      carbohydrate: 29,
      saturated: 0,
      sodium: 137,
      protein: 3,
      fiber: 1,
      difficulty: "moyen",
      category: "collation|végétarien",
    },
    {
      title: "Pochettes de pita au poulet épicé",
      slug: "pochettes-de-pita-au-poulet-epice",
      img: "spicy-chicken-pitta-pockets",
      hasImage: true,
      description:
        "Préparez ces pitas de poulet assaisonnés à la pâte harissa pour le dîner ou pour un souper vite fait.",
      ingredients:
        "30 ml (2 c. à table) de yogourt grec faible en gras |10 ml (2 c. à thé) de pâte harissa |15 ml (1 c. à table) d’huile d’olive |1 petit oignon |1 gros filet de poitrine de poulet, sans la peau et coupé en tranches |1 poivron rouge, épépiné et tranché|100 g (1 tasse) de champignons, tranchés |2 pains pita à grains entiers |Feuilles de laitue, lavées et asséchées |Deux quartiers de citron (ingrédient facultatif) ",
      methods:
        "Faire griller les pains pita. Ils sont prêts une fois gonflés. Couper le long de la partie supérieure de façon à former une pochette à garnir plus tard en y ajoutant le mélange de poulet et la laitue. |Pendant ce temps, cuire l’oignon doucement dans l’huile quelques minutes, jusqu’à ce qu’il soit tendre.|Ajouter le poulet tranché et cuire jusqu’à ce que tous les côtés soient dorés.|Ajouter les champignons et les poivrons et poursuivre la cuisson pendant 5 à 10 minutes jusqu’à ce que les champignons soient tendres et que les poivrons ramollissent mais qu’ils soient encore un peu croquants. Incorporer la pâte harissa et s’assurer que tous les morceaux de poulet et les légumes sont bien enrobés. Laisser refroidir pendant quelques minutes.|Et maintenant pour assembler le contenu : commencer par mettre de la laitue dans chaque pita, puis ajouter le mélange de poulet. Terminer par une feuille de laitue et un soupçon de yogourt.|Servir immédiatement avec des quartiers de citron| Conseil pour la recette : Vous pouvez utiliser les mêmes ingrédients pour préparer des sandwichs ou des roulés. Choisissez des variétés à grains entiers.",
      serves: 2,
      time: 25,
      fat: 9,
      calories: 440,
      saturated: 1.5,
      sodium: 1.3,
      difficulty: "facile",
      heartuk: true,
      category: "collation|dîner|mets préférés de la famille|rapides et faciles",
    },
    {
      title: "Hamburgers épicés",
      slug: "hamburgers-epices",
      img: "spicy-burgers",
      hasImage: true,
      description:
        "Ces populaires hamburgers à l’arôme de paprika sont légers et savoureux tout en étant faciles à préparer.",
      ingredients:
        "450 g (1 lb) de poulet, haché |1 oignon, râpé |2 gousses d’ail, écrasées |5 ml (1 c. à thé) de paprika ",
      methods:
        "Préchauffer le gril à feu moyen. |Mélanger tous les ingrédients. |Répartir le mélange en 8 hamburgers. |Faire griller à feu moyen pendant 4 à 5 minutes de chaque côté ou jusqu’à ce que la viande soit entièrement cuite. |Servir avec une salade ou dans des petits pains à grains entiers.",
      serves: 4,
      time: 15,
      fat: 1.4,
      calories: 110,
      saturated: 0.4,
      sodium: 0.17,
      difficulty: "facile",
      heartuk: true,
      category: "dîner|mets préférés de la famille|rapides et faciles",
    },
    {
      title: "Poulet rôti à l'ail et aux fines herbes",
      slug: "poulet-roti-a-lail-et-aux-fines-herbes",
      img: "herb-and-garlic-baked-chicken",
      hasImage: true,
      description:
        "Grâce au mélange de fines herbes et d'ail, il n'est pas nécessaire d'ajouter du sel pour obtenir un poulet savoureux et cuit à la perfection.",
      ingredients:
        "4 poitrines de poulet sans peau, parées de tout le gras|Une poignée de fines herbes hachées, comme de l'estragon, de la sauge ou du persil.|3 gousses d’ail, écrasées|Mélange d'huile et d'eau à vaporiser|600 g à 650 g (4 à 5 tasses) de légumes sautés (au choix : oignons, poivrons, champignons, chou râpé, bâtonnets de légumes légèrement bouillis, haricots rouges en conserve)",
      methods:
        "Mélanger l’ail et les fines herbes.|Faire 3 ou 4 entailles peu profondes dans les poitrines de poulet. Farcir avec le mélange d'ail et de fines herbes.|Vaporiser une plaque à pâtisserie avec le mélange d'huile et d'eau. Déposer le poulet et vaporiser de nouveau.|Couvrir légèrement avec du papier d'aluminium pour empêcher le poulet de brûler ou de sécher lors de la cuisson au four.|Cuire au four pendant 30 minutes à 200°C (400°F) ou jusqu'à ce que les jus de cuisson soient clairs lorsqu'on pique une brochette dans le poulet.|Vaporiser légèrement une poêle à frire avec le mélange d'huile et d'eau. Chauffer la poêle, ajouter les légumes et cuire pendant quelques minutes jusqu’à ce qu’ils soient tendres.|Servir avec le poulet.",
      serves: 4,
      time: 45,
      fat: 2.7,
      calories: 186,
      saturated: 0.6,
      sodium: 1,
      difficulty: "facile",
      heartuk: true,
      category: "dîner|déjeuner|mets préférés de la famille",
    },
    {
      title: "Pâtes épicées",
      slug: "pates-epicees",
      img: "spicy-pasta",
      hasImage: true,
      description:
        "Une recette de pâtes immanquable, faible en gras, aux saveurs riches et audacieuses, que vous pouvez cuisiner en moins d'une demi-heure.",
      ingredients:
        "450 g (3 ½ tasses) de pennes de blé entier ou un autre type de pâtes |15 ml (1 c. à table) d’huile d’olive |5 ml (1 c. à thé) de graines de cumin |5 ml (1 c. à thé) de graines de moutarde |1 gros oignon, haché |5 ml (1 c. à thé) d’ail, haché |2 piments chilis verts, hachés finement |225 g (2 tasses) de champignons de Paris |1 boite de 400 ml (14 oz) de tomates, en purée |5 ml (1 c. à thé) de garam masala |5 ml (1 c. à thé) de coriandre, hachée",
      methods:
        "Cuire les pâtes selon le mode d’emploi sur l’emballage, de préférence al dente. Bien égoutter. |Chauffer une grande casserole antiadhésive. Ajouter l’huile, puis les graines de cumin, les graines de moutarde et les oignons. Cuire jusqu’à ce que les oignons soient légèrement dorés. Ajouter l’ail, les piments chilis et les champignons et poursuivre la cuisson pendant 2 minutes. |Ajouter les tomates et porter à ébullition. |Ajouter le garam masala, baisser le feu et laisser mijoter pendant 5 minutes.|Incorporer les pâtes et bien mélanger. Garnir de coriandre et servir chaud.",
      serves: 6,
      time: 28,
      fat: 4.7,
      calories: 339,
      saturated: 0.7,
      difficulty: "facile",
      heartuk: true,
      category: "dîner|mets préférés de la famille|rapides et faciles|végétarien",
    },
    {
      title: "Cocotte de poulet",
      slug: "cocotte-de-poulet",
      img: "chicken-casserole",
      hasImage: true,
      description:
        "Régalez votre famille avec cette cocotte de poulet réconfortante et savoureuse, faible en gras.",
      ingredients:
        "1 kg (2 lb) de morceaux de poulet |1 gros oignon, coupé en dés |6 gousses d’ail |15 ml (1 c. à table) d’huile de colza |1 boîte de 400 g (14 oz) de tomates, coupées en morceaux |2,5 ml (½ c. à thé) de fines herbes séchées, comme de l’estragon, de la coriandre ou de l’origan |75 ml (2,5 oz) de vinaigre de vin rouge|75 ml (2,5 oz) de vinaigre de vin blanc |150 ml (5 oz) de bouillon de poulet, faible en sel |poivre pour assaisonner, au goût",
      methods:
        "Préchauffer le four à 150°C (300°F). |Retirer la peau des morceaux de poulet et enlever toute trace de gras apparent.|Dans un grand plat de cuisson ou dans une cocotte résistant à la chaleur, cuire l'oignon et les gousses d’ail entières dans l'huile sans les faire dorer pendant 5 minutes.|Ajouter les tomates en conserve, les fines herbes séchées, les vinaigres et le bouillon de poulet, puis faire mijoter.|Laisser réduire légèrement.|Ajouter les morceaux de poulet à la sauce et cuire pendant 5 minutes.|Placer le poulet dans un plat de cuisson, couvrir, mettre au four préchauffé et cuire 50 minutes.|Servir avec des pommes de terre ou avec des morceaux de pain de grains entiers et des haricots verts.",
      serves: 4,
      time: 80,
      fat: 6.7,
      calories: 255,
      saturated: 1.2,
      sodium: 0.43,
      difficulty: "facile",
      heartuk: true,
      category: "dîner|mets préférés de la famille|hearty meal",
    },
    {
      title: "Pâté campagnard",
      slug: "pate-campagnard",
      img: "cottage-pie",
      hasImage: true,
      description:
        "Une version réinventée d’un plat traditionnel apprécié par toute la famille : un gratin de hachis de bœuf faible en gras garni d’une purée de pommes de terre onctueuse.",
      ingredients:
        "400 g (1 lb) de bœuf haché maigre |15 ml (1 c. à table) d’huile végétale |1 gros oignon, haché |1 gousse d’ail, écrasée ou hachée |15 ml (1 c. à table) de sauce Worcestershire (facultatif) |1 grosse carotte, coupée en dés |15 ml à 30 ml (1 à 2 c. à table) de purée de tomates |50 g (1/4 tasse) de pois congelés |1 cube de bouillon de bœuf, à faible teneur en sel |1 boîte de 400 g (14 oz) de tomates, coupées en morceaux |500 g (17 oz) de pommes de terre, épluchées |30 ml à 45 ml (2 à 3 c. à table) de lait écrémé |15 ml (1 c. à table) de tartinade aux légumes (aux olives ou au tournesol) |Poivre pour assaisonner",
      methods:
        "Préchauffer le four à 200°C (400°F). |Pour la garniture: |Chauffer doucement l’huile dans une grande casserole ou dans une poêle à frire. Ajouter l'oignon et l'ail hachés et cuire pendant 5 minutes sans faire dorer.|Ajouter le boeuf haché et poursuivre la cuisson jusqu’à ce que la viande soit brune.|Ajouter le cube de bouillon, les tomates en conserve, la purée de tomates, la sauce Worcestershire et les morceaux de carottes.|Porter à ébullition et laisser mijoter pendant 10 à 15 minutes. La sauce devrait s’épaissir rapidement.|Ajouter les pois congelés et continuer à mijoter pendant 5 minutes, assaisonner avec du poivre.|Pour la garniture: |Éplucher les pommes de terre et les couper en morceaux de la taille d'un œuf. Faire bouillir pendant environ 20 minutes ou jusqu’à ce qu’elles soient tendres. |Égoutter les pommes de terre, ajouter la tartinade aux légumes et le lait et écraser jusqu’à l’obtention d’une purée lisse et crémeuse.|Préparation du pâté : |Mettre le mélange de viande dans une casserole ou dans un plat de cuisson et garnir de purée de pommes de terre.|Cuire au four pendant 45 minutes (30 minutes à partir du moment où le four est chaud), jusqu'à ce que le dessus soit doré.|Servir tel quel ou avec des légumes de saison|Conseil pour la recette : Pour varier la recette, ajoutez des haricots en conserve au mélange de viande au lieu des pois, ajoutez des champignons coupés en morceaux ou, pour obtenir une sauce plus lisse, réduisez les tomates coupées en purée avant de les ajouter à la préparation.",
      serves: 4,
      time: 50,
      fat: 12,
      calories: 322,
      saturated: 3.1,
      sodium: 1.3,
      difficulty: "moyen",
      heartuk: true,
      category: "dîner|mets préférés de la famille|hearty meal",
    },
    {
      title: "Risotto à la courge musquée",
      slug: "risotto-a-la-courge-musquee",
      img: "butternut-squash-risotto",
      hasImage: true,
      description:
        "Le goût sucré de la courge musquée et le soupçon de sel du bacon feront de ce plat l'un des préférés de la famille.",
      ingredients:
        "4 morceaux de bacon, parés de gras et hachés en morceaux  |15 ml (1 c. à table) d’huile de colza |1 gros oignon, haché finement |1 petite courge musquée, pelée et coupée en petits morceaux |300 g (2 ½ tasses) de riz à risotto (Arborio) |1 litre (4 tasses) de bouillon préparé avec 2 cubes de bouillon de légumes ou de poulet à faible teneur en sel |poivre pour assaisonner, au goût",
      methods:
        "Cuire le bacon et la courge musquée dans une grande poêle à frire antiadhésive avec de l'huile, pendant 10 minutes. |Ajouter l'oignon et poursuivre la cuisson pendant environ 5 minutes. La courge et l'oignon devraient tous deux avoir ramolli. |Incorporer le riz, puis ajouter le bouillon chaud, remuer à nouveau et faire mijoter. |Cuire de 15 à 20 minutes, en remuant régulièrement afin que le riz ne colle pas. |Lorsque presque tout le bouillon a été absorbé et que le riz est tendre, le plat est prêt. Assaisonner et servir.| Conseil pour la recette : pour réduire davantage la quantité de sel, remplacer le bacon par une petite poitrine de poulet, coupée en minuscules lanières.",
      serves: 4,
      time: 50,
      fat: 9.1,
      calories: 447,
      saturated: 2.4,
      sodium: 1.2,
      difficulty: "facile",
      heartuk: true,
      category: "dîner|mets préférés de la famille|végétarien",
    },
    {
      title: "Lasagne méditerranéenne aux légumes",
      slug: "lasagne-mediterraneenne-aux-legumes",
      img: "mediterranean-vegetable-lasagne",
      hasImage: true,
      description:
        "Ce plat de pâtes se veut une alternative santé à la recette traditionnelle. De délicieux légumes méditerranéens rôtis remplacent la viande et sont rehaussés d'une sauce plus légère, mais tout aussi onctueuse. Buon Appetito!",
      ingredients:
        "2 aubergines, coupées en morceaux |2 oignons rouges, coupés en quartiers |2 poivrons rouges, coupés en morceaux |4 gousses d’ail, hachées finement |45 ml (3 c. à table) d’huile d’olive |450 g (4 tasses) de courgettes, tranchées |225 g (8 oz) de pâtes pour lasagne |30 g (2 c. à table) de parmesan |900 ml (30 oz) de lait écrémé |60 g (2 oz) de tartinade aux olives |70 g (2,5 oz) de farine tout usage |125 g (1/3 tasse) de fromage à pâte ferme, faible en gras (nous avons utilisé de l'Edam)",
      methods:
        "Préchauffer le four à 220°C (428°F). Dans un bol, mélanger les aubergines, les oignons, les poivrons, l’ail et la moitié de l’huile. Transférer dans une grande rôtissoire et cuire pendant 30 minutes ou jusqu'à ce que les légumes soient tendres. |Pendant ce temps, chauffer le reste de l'huile dans une poêle à frire. Frire les courgettes pendant 4 minutes, jusqu’à ce qu’elles soient dorées. |Retirer les légumes rôtis du four et incorporer les courgettes. Baisser la température du four à 200°C (390°F). |Pour la sauce, porter le lait à ébullition dans une poêle et réserver. Faire fondre la tartinade aux olives dans une poêle, ajouter la farine et cuire pendant 1 minute, puis retirer du feu. Incorporer graduellement le lait, puis porter à ébullition tout en remuant et laisser mijoter doucement pendant 10 minutes en remuant de temps en temps. |Cuire les pâtes pour lasagne selon les indications de l’emballage.|Ajouter le fromage à la sauce et assaisonner de poivre. Verser à la cuillère une mince couche du mélange dans le fond d'un plat de cuisson légèrement huilé, puis couvrir de 4 pâtes pour lasagne qui se chevauchent légèrement. Ajouter la moitié des légumes puis le tiers de la sauce restante, et disposer ensuite 4 autres pâtes pour lasagne. Répéter ce processus une fois de plus, puis verser le reste de la sauce et saupoudrer de parmesan. Cuire au four pendant 40 minutes jusqu’à ce que le dessus soit d’une couleur dorée.",
      serves: 8,
      time: 105,
      fat: 13,
      calories: 282,
      saturated: 3.6,
      sodium: 0.29,
      difficulty: "moyen",
      heartuk: true,
      category: "dîner|mets préférés de la famille|végétarien",
    },
    {
      title: "Croustade d’avoine aux pommes et aux mûres",
      slug: "croustade-davoine-aux-pommes-et-aux-mures",
      img: "apple-and-blackberry-oat-crumble",
      hasImage: true,
      description: "Une populaire recette hivernale réconfortante qui s'ajoute à vos cinq portions de fruits par jour.",
      ingredients:
        "7 pommes Braeburn, coupées en morceaux|20 ml (4 c. à thé) de sucre ou de votre édulcorant préféré|5 ml (1 c. à thé) de cannelle|100 g (3/4 tasse) de mûres (ou de bleuets)|50 ml (1,5 oz) d’eau|60 g (2 oz) d’avoine / de gruau |120 g (4 oz) de farine complète / de farine de grains entiers|40 g (1/3 tasse) de cassonade |5 ml (1 c. à thé) de cannelle|75 g (2,5 oz) de margarine légère, non hydrogénée (p. ex. : Becel légère) ",
      methods:
        "Préchauffer le four conventionnel à 200°C (392°F) ou le four à convection à 180°C (356°F).|Peler les pommes et les couper en morceaux de 2 cm. Saupoudrer 20 ml (4 c. à thé) d'édulcorant et 5 ml (1 c. à thé) de cannelle, puis couvrir et faire mijoter dans 50 ml d'eau, à feu moyen, jusqu'à ce que les pommes ramollissent. Vérifier et remuer régulièrement. Une fois que les fruits sont assez mous pour être écrasés facilement avec une cuillère, fermer le feu et laisser refroidir. |Pendant que les pommes mijotent, préparer la croustade. Mélanger l’avoine et la farine nature dans un grand bol. Ajouter l’édulcorant, la cannelle et la cassonade muscovado et bien mélanger à l’aide d’une cuillère. Avec le bout des doigts, ajouter la tartinade et l’incorporer au mélange de farine et d’avoine en la frottant aux ingrédients pour former une texture humide et friable, jusqu’à ce que le mélange ressemble à de la chapelure.|Verser les pommes mijotées dans un plat allant au four, incorporer les mûres et saupoudrer la garniture de la croustade sur le tout. Cuire au four pendant 25 minutes, jusqu’à l’obtention d’une croûte dorée. Si désiré, saupoudrer d’une pincée de sucre à glacer et napper d’une cuillère à table de crème pâtissière faible en gras avant de servir.",
      serves: 8,
      time: 45,
      fat: 5.1,
      calories: 230,
      saturated: 1.1,
      sodium: 0.12,
      difficulty: "facile",
      heartuk: true,
      category: "dessert|collation|mets préférés de la famille|végétarien",
    },
    {
      title: "Pavlova tropicale",
      slug: "pavlova-tropicale",
      img: "tropical-pavlova",
      hasImage: true,
      description:
        "La pavlova est un dessert très populaire et cette recette la transforme en version tropicale avec des mangues, des ananas et des fruits de la passion.",
      ingredients:
        "3 blancs d’œufs|175 g (1 tasse) de sucre superfin|5 ml (1 c. à thé) de café noir corsé|250 g (4 tasses) de fromage frais naturel / de fromage cottage faible en gras mélangé à un peu de lait écrémé, jusqu’à l’obtention d’une consistance lisse. |125 g (1 tasse) de mangues, coupées en dés|125 g (1 tasse) d’ananas frais, coupé en morceaux|1 ou 2 fruits de la passion",
      methods:
        "Fouetter les blancs d’œufs dans un bol jusqu’à l’obtention d’une texture ferme. Incorporer délicatement 15 ml (1 c. à table) de sucre, puis ajouter progressivement le reste au mélange en fouettant. La meringue doit être lustrée et doit former des pics lorsqu’elle est déposée à la cuillère dans le bol. Incorporer délicatement le café noir.|Verser le mélange de meringue sur une grande feuille de papier sulfurisé de façon à former un cercle de 20 cm (8 po) de diamètre. Faire un léger creux au centre de la meringue et cuire dans un four préchauffé à 120°C (250°F) pendant 1 heure jusqu’à ce que la meringue soit croustillante. Retirer du four et laisser la meringue refroidir sur le papier pendant environ 10 minutes avant de la décoller.|Lorsque la meringue est froide, remplir le creux de fromage frais. Disposer les morceaux de mangues et d’ananas sur le dessus, puis saupoudrer de graines de fruit de la passion et arroser délicatement de jus.| Vous pouvez également essayer ces autres mélanges de fruits pour garnir la pavlova : fraises et mangues, framboises et bleuets, cerises et nectarines, ananas et papayes.",
      serves: 4,
      time: 80,
      fat: 0.5,
      calories: 269,
      saturated: 0.1,
      sodium: 0.18,
      difficulty: "facile",
      heartuk: true,
      category: "dessert|mets préférés de la famille",
    },
    {
      title: "Brownies fondants au babeurre",
      slug: "brownies-fondants-au-babeurre",
      img: "fudgy-buttermilk-brownies",
      hasImage: true,
      description:
        "Qui n’aime pas se laisser tenter par le chocolat! Contrairement à la plupart des recettes à base de chocolat, celle-ci est réalisée avec du babeurre faible en gras. Que vous décidiez d'ajouter ou non un glaçage, vous apprécierez surement ces brownies riches et crémeux.",
      ingredients:
        "5 ml (1 c. à thé) d’huile végétale |100 g (3/4 tasse) de farine blanche |50 g (1/3 tasse) de farine de grains entiers |175 g (3/4 tasse) de cassonade molle |50 g (1/3 tasse) de poudre de cacao (pour les brownies) |2,5 ml (1/2 c. à thé) de bicarbonate de soude |1 gros œuf |1 pomme de cuisson de grosseur moyenne, tranchée, délicatement cuite dans un peu d’eau et écrasée |120 ml (1/2 tasse) de babeurre faible en gras |10 ml (2 c. à thé) d’essence de vanille |150 g (1 tasse) de sucre à glacer, tamisé |50 g (1/3 tasse) de poudre de cacao (pour le glaçage) |15 ml à 45 ml (1 à 3 c. à table) de lait écrémé",
      methods:
        "Préchauffer le four à 180°C (360°F).|Graisser légèrement une plaque à pâtisserie carrée de 23 cm (9 po) avec de l’huile végétale. |Dans un bol, mélanger les deux farines, le sucre, la poudre de cacao et la poudre à pâte. |Dans un autre bol, fouetter l’œuf. Ajouter la pomme, le babeurre et l’extrait de vanille. |Ajouter à la préparation de farine et remuer jusqu’à ce que le mélange soit bien homogène. |Verser sur la plaque de cuisson. |Cuire au four pendant 30 minutes. |Si vous préparez le glaçage, mélangez le sucre à glacer et la poudre de cacao. |Ajouter graduellement le lait jusqu'à ce que le mélange ait la consistance d’une tartinade. Étendre le glaçage sur les brownies refroidis. Couper les brownies en 16 carrés et les retirer délicatement de la plaque à pâtisserie.",
      serves: 16,
      time: 45,
      fat: 2.3,
      calories: 155,
      saturated: 1,
      sodium: 0.22,
      difficulty: "facile",
      heartuk: true,
      category: "dessert|mets préférés de la famille",
    },
    {
      title: "Gâteau aux fruits purs",
      slug: "gateau-aux-fruits-purs",
      img: "pure-fruit-cake",
      hasImage: true,
      description:
        "Dans cette recette, les dattes en purée constituent un excellent substitut de gras et lient tous les fruits ensemble pour créer un gâteau assez riche pour être servi à Noël. Il ne manque que le père Noël!",
      ingredients:
        "Enduit à cuisson pour graisser le moule|250 g (2 tasses) de dattes dénoyautées|300 ml (10 oz) d’eau|175 g (1 ½ tasse) de raisins sans pépins foncés|125 g (1 tasse) de raisins secs sultanas/dorés|125 g (1 tasse) de groseilles|50 g (1/3 tasse) de fruits confits, pelés, hachés|175 g (1 ½ tasse) de farine tout usage de grains entiers |45 ml (3 c. à thé) de poudre à pâte|5 ml (1 c. à thé) d’épices mélangées / d’épices pour tarte à la citrouille|zeste râpé et jus d’une orange ou d’un citron|25 g (0,8 oz) d’amandes moulues|Décorer le gâteau avec quelques noix de Grenoble et des cerises confites (facultatif)",
      methods:
        "Graisser un moule à pain de 1 kg (35 oz). Mettre les dattes dans une casserole avec la quantité d’eau recommandée et chauffer doucement jusqu’à ce qu’elles soient tendres. Retirer du feu et écraser les dattes à la fourchette jusqu’à l’obtention d’une purée.|Mettre la purée de dattes dans un bol avec tous les autres ingrédients, sauf les amandes effilées, et ajouter 60 ml (4 c. à table) d'eau. Bien mélanger tous les ingrédients ensemble. Verser le mélange à la cuillère dans le moule préparé et égaliser le dessus. Parsemer d'amandes effilées.|Cuire dans un four préchauffé à 160°C (325°F) pendant 1 heure 30 minutes, jusqu’à ce qu’une brochette insérée au centre en ressorte propre. Vers la fin de la cuisson, protéger le dessus du gâteau avec du papier d’aluminium pour éviter qu’il ne brunisse trop.|Laisser le gâteau refroidir quelque temps dans le moule, puis le renverser sur une grille métallique et le laisser refroidir complètement.",
      serves: 12,
      time: 105,
      fat: 1.9,
      calories: 250,
      saturated: 0.2,
      sodium: 0.37,
      difficulty: "facile",
      heartuk: true,
      category: "dessert|mets préférés de la famille",
    },
  ];
  