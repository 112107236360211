<template>
<div class="container lifestyle">
    <div class="row small">
        <img class="img-03" src="@/assets/img/img-weight.png">
        <div class="col s12 m9">
            <h1 class="hero-heading">Tips for maintaining weight while eating less fat</h1>
            <p class="hero-p"><strong>Why does eating less fat sometimes lead to weight loss?</strong></p>
            <p class="hero-p">Fat has more than twice as many calories as carbohydrates
                and proteins. So when you cut fat from an average of 30%
                of calories to less than 20%, it is necessary to replace those
                fat calories with protein and carbohydrate calories,
                to maintain weight.</p>
        </div>
    </div>

    <div class="row small">
        <div class="col s12">
            <h3>Why does eating less fat sometimes lead to weight loss?</h3>
        </div>
        <div class="col s12 m6">
            <div class="card-panel">
                <p class="hero-p">
                    Fat has more than twice as many calories as carbohydrates and proteins. So when you cut fat from an average of 30% of calories to less than 20%, it is necessary to replace those fat calories with protein and carbohydrate calories, to maintain weight.
                </p>
                <h4>Here’s the math for a sample 2,000 calorie eating plan:</h4>
                <p class="hero-p">
                <ul>
                    <li>30% of calories as fat = 600 calories</li>
                    <li>20% of calories as fat = 400 calories</li>
                </ul>
                </p>
            </div>
        </div>
        <div class="col s12 m6">
            <div class="card-panel">
                <p class="hero-p">
                    So, in this instance, one needs to replace 200 fat calories
                    daily with protein and carbohydrate calories to maintain
                    weight. If you don’t, then weight loss may occur.
                </p>
            </div>

            <img class="responsive-img maintaining-weight-img" src="@/assets/img/maintaining-weight.png" alt="">
        </div>
    </div>

    <div class="row small">
        <div class="col s12">
            <h3>How can I increase my calorie intake to avoid weight loss?</h3>
        </div>
        <div class="col s12 m6">
            <div class="card-panel weight-panel">
                <img class="weight-bubble" src="@/assets/img/weight-eggs.png" alt="">
                <h4>1. Start by eating a little more protein:</h4>
                <p class="hero-p">
                <ul>
                    <li>Boiled egg whites (make into egg salad with 1 whole
                        egg and 3 extra whites with low-fat mayo or fat-free
                        yogurt and mustard).</li>
                    <li>Egg substitute (make into
                        scrambled eggs or an omelet with butter flavored
                        nonstick cooking spray or make an “eggy” style of
                        French toast)</li>
                    <li>Tuna (packed in water) made into a salad with celery
                        and low-fat mayo</li>
                    <li>Shellfish like shrimp (super low-fat; can be boiled
                        and spiced)</li>
                    <li>Turkey or chicken breast (make extra for dinner and
                        use for mini meals)</li>
                    <li>Lean white fish like halibut and tilapia
                    <li>Fat-free cheese slices (make into a grilled cheese
                        sandwich)</li>
                    <li>Fat-free Greek yogurt (go for the one with the
                        highest calories)</li>
                    <li>Low-fat peanut butter</li>
                    <li>Fat-free milk or low-fat soy milk (add fat-free chocolate
                        syrup for a treat)</li>
                    <li>Fat-free milk and hot or low-fat dry cereal</li>
                    <li>Beans (if they do not cause stomach problems for you)</li>
                    <li>Fat-free nutrition drink</li>
                </ul>
                </p>
            </div>
        </div>
        <div class="col s12 m6">
            <div class="card-panel weight-panel">
                <img class="weight-bubble" src="@/assets/img/weight-apple.png" alt="">
                <h4>2. Add generous portions of “healthy” carbohydrates:</h4>
                <p class="hero-p">
                <ul>
                    <li>Fruit (no grapefruit)</li>
                    <li>Dried fruit (take some raisins and dried apricots with
                        you on the road)</li>
                    <li>Apple sauce and canned fruit</li>
                    <li>Baked potato (top with salsa, fat-free sour cream,
                        butter spray or BBQ sauce)</li>
                    <li>Baked yam and sweet potato (bake with brown sugar
                        and butter spray)</li>
                    <li>Boiled new potatoes and “skinny” mashed potatoes
                        made with fat-free milk and butter flavoring</li>
                    <li>Roasted root vegetables (like potatoes, carrots and
                        squash) with seasoning</li>
                    <li>Baked winter squash</li>
                    <li>Peas and corn</li>
                    <li>Pasta with low-fat tomato sauce</li>
                    <li>Rice flavored with broth</li>
                    <li>Low-fat peanut butter and jelly sandwich</li>
                    <li>Fat-free cheese sandwich or grilled cheese made with
                        butter flavored nonstick spray</li>
                    <li>Bagels</li>
                    <li>Pretzels</li>
                </ul>
                </p>
            </div>
        </div>
    </div>

    <div class="row small">
        <div class="col s12 m6">
            <div class="card-panel weight-panel">
                <img class="weight-bubble" src="@/assets/img/weight-juice.png" alt="">
                <h4>3. Add fat-free sweets in moderation (discuss this with your doctor first):</h4>
                <p class="hero-p">
                <ul>
                    <li> 100% fruit juices (no grapefruit juice)</li>
                    <li> Blended juice drinks (no grapefruit juice) and smoothies</li>
                    <li> Fat-free fig cookies</li>
                    <li> Fat-free ice cream and frozen yogurt</li>
                    <li> Sorbet and fruit ices and frozen fruit pops</li>
                    <li> Licorice, jelly</li>
                </ul>
                </p>
            </div>
        </div>
        <div class="col s12 m6">
            <div class="card-panel weight-panel">
                <img class="weight-bubble" src="@/assets/img/weight-time.png" alt="">
                <h4>4. Eat more frequently.</h4>
                <p class="hero-p">
                <ul>
                    <p class="hero-p">Make a plan to include at least 2 extra snacks during the
                        day. You may need to think ahead and take something with
                        you to work, in your purse or bag or in the car. Consider portable
                        items like dried fruit, fruit cups, a package of pretzels or a
                        low-fat peanut butter sandwich. If you have refrigeration,
                        consider a high calorie low-fat yogurt or low-fat cheese
                        stick or a turkey sandwich. A good snack habit is to drink
                        a high calorie shake made with frozen fruit, a banana and
                        some fat-free milk or soy milk. Think about what you enjoy
                        and make it convenient for you to eat.</p>
                    <br>
                    <p class="hero-p">If you have lost weight and are struggling to regain weight,
                        have patience. Start first by stabilizing your weight and
                        then add extra calories to increase weight. You may need
                        as many as 500 or more extra calories per day. Your doctor
                        or dietitian will work with you to determine the best source
                        of extra calories and personalize a plan to increase your
                        food intake, including meal and snacks.</p>
                </ul>
                </p>
            </div>
        </div>
    </div>

    <!-- <div class="row small card-panel yellow">
      <div class="col s12 m1">
        <img class="responsive-img info-img" src="@/assets/img/info-img.png" alt="">
      </div>
      <div class="col s12 m11 valign-wrapper">
        <p class="hero-p"><i>
        <strong>Note: </strong>  Talk to your physician or other healthcare provider
          before making any changes to your diet, especially if you have
          food restrictions for conditions like diabetes or kidney disease.
          Check with your doctor to see if these tips will work for you.
        </i></p>
      </div>
    </div> -->

</div>
</template>

<script>
export default {
    name: 'MaintainingWeight',
    data() {
        return {}
    },
    mounted() {
        document.body.classList.add("sub-page");
    },
    methods: {},
    computed: {}
}
</script>

<style>
.maintaining-weight-img {
    max-width: 34% !important;
    margin-top: 2rem;
}

.weight-bubble {
    position: absolute;
    top: -50px;
    right: 30px;
    max-width: 100px;
}

.weight-panel {
    margin-top: 60px;
    position: relative;
}

.weight-panel h4 {
    max-width: 80%;
}

@media screen and (max-device-width: 420px) {
    .weight-bubble {
        position: absolute;
        top: -20px;
        right: -20px;
        max-width: 80px;
    }

    .weight-panel {
        margin-top: 30px;
    }
}
</style>
