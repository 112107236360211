<template>
<div class="container mealPlan">
    <!-- Intro -->
    <div class="row">
        <img class="img-03" src="@/assets/img/img-planner.png">
        <div class="col s12 m8">
            <h1 class="hero-heading">Create a meal plan</h1>
            <p class="hero-p">Create a daily meal plan from our database of recipes to help you keep your food choices interesting while also staying beneath your maximum daily fat allowance. </p>
        </div>
        <div class="col s12 m7">
            <h3>Enter your recommended max daily fat amount: </h3>
        </div>
        <div class="col s12 m2">
            <select class="formSelect" v-model="dailyFatAllowance">
                <option value="" disabled selected>Fat (g)</option>

                <template v-for="(item, index) in getNumbers(20,68)">
                    <option :key="index" v-bind:value="item">{{item}}g</option>
                </template>

            </select>
            <label>Fat (g)</label>
        </div>
    </div>

    <div class="revealSwapper" v-bind:class="{ active: dailyFatAllowance }">
        <!-- Add breakfast -->
        <div class="row meal-swapper">
            <div class="col s12 m6">
                <div>
                    <a @click="openMealModal()">
                        <div class="card-panel lighten-5 z-depth-1">
                            <div class="valign-wrapper">
                                <div class="col m2 s2">
                                    <i class="medium material-icons">add</i>
                                </div>
                                <div class="col m10 s10">
                                    <h3>Add main meal</h3>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>

            </div>
            <div class="col s12 m6">
                <div>
                    <a @click="openSnackModal()">
                        <div class="card-panel lighten-5 z-depth-1">
                            <div class="valign-wrapper">
                                <div class="col m2 s2">
                                    <i class="medium material-icons">add</i>
                                </div>
                                <div class="col m10 s10">
                                    <h3>Add low fat snack/side</h3>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div v-if="mealList" class="meal-list">

        <div v-for="(meal, index) in mealList" :key="index" class="mealSelected">
            <h2>Meal {{index+1}}</h2>
            <div class="card-panel lighten-5 z-depth-1">
                <div class="row valign-wrapper m0">
                    <div class="col s12 m2 hide-on-small-only">
                        <div class="swapper-crop">
                            <img v-if="meal.slug" class="responsive-img" :src="require(`@/assets/img/recipes/${meal.slug}.jpg`)">
                            <img v-if="!meal.slug" class="responsive-img" :src="require(`@/assets/img/default-recipe.jpg`)">
                        </div>
                    </div>
                    <div class="col s8 m7">
                        <span class="black-text">
                            <h3>{{meal.title}} <span v-if="meal.serves">(1/{{meal.serves}} of recipe)</span></h3>
                            <p v-if="meal.difficulty"><span v-if="meal.time"><strong>Prep time:</strong> {{meal.time}}mins &#x000B7;</span> <strong>Difficuly:</strong> {{meal.difficulty}}</p>
                            <p class="nutitionalInfo">
                                <strong>Fat:</strong> {{meal.fat}}g
                                <span v-if="meal.calories"> &#x000B7; <strong>Calories:</strong> {{meal.calories}}kcals</span>
                            </p>
                        </span>
                    </div>
                    <!-- <div class="col s1 offset-s1">
                            <a class="btn-floating btn-large waves-effect waves-light modal-trigger blue" href="#breakfast-modal"><i class="material-icons">restaurant</i></a>
                        </div> 
                        <div class="col s2">
                            <a class="btn-floating btn-large waves-effect waves-light modal-trigger" href="#breakfast-modal"><i class="material-icons">remove</i></a>
                        </div> -->

                    <div class="col s4 m3">
                        <router-link v-if="meal.slug" :to="'/recipe/' + meal.slug" target="_blank" class="btn waves-effect waves-light viewRecipe">View recipe
                            <i class="material-icons right">restaurant</i>
                        </router-link>
                        <a class="btn waves-effect waves-light yellow mt10 changeRecipe" @click="removeRecipe(index)">Remove meal
                            <i class="material-icons right">delete</i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="snackList" class="snack-list">
        <div class="row">
            <div v-for="(snack, index) in snackList" :key="index" class="col s12 m4">
                <h2>Snack {{index+1}}</h2>
                <div class="card-panel lighten-5 z-depth-1">
                    <h3>{{snack.title}}</h3>
                    <p class="nutitionalInfo"><strong>Fat:</strong> {{snack.fat}}g</p>
                    <a class="btn waves-effect waves-light yellow mt20 changeRecipe" @click="removeSnack(index)">Remove snack
                        <i class="material-icons right">delete</i>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div id="printMe">
        <h2 style="color: #21a3ca;">Your meal plan from HoFHrecipes.com</h2>
        <h2>Meals</h2>
        <div>
            <div v-for="(meal, index) in mealList" :key="index">
                <h3>Meal {{index+1}}: {{meal.title}}</h3>
                <p class="nutitionalInfo">
                    <strong>Fat:</strong> {{meal.fat}}g
                    <!-- <span v-if="meal.calories"> &#x000B7; <strong>Calories:</strong> {{meal.calories}}kcals</span> -->
                </p>
                <hr>
            </div>
        </div>

        <h2>Snacks</h2>
        <div>
            <div v-for="(snack, index) in snackList" :key="index">
                <h3>Snack {{index+1}}: {{snack.title}}</h3>
                <p class="nutitionalInfo">
                    <strong>Fat:</strong> {{snack.fat}}g
                    <!-- <span v-if="snack.calories"> &#x000B7; <strong>Calories:</strong> {{snack.calories}}kcals</span> -->
                </p>
                <hr>
            </div>
        </div>

        <h2>Nutitional totals</h2>
        <h3>Total fat: {{totalFat}}g</h3>

    </div>

    <!-- Total value shelf -->
    <div class="planTotal z-depth-3" v-bind:class="{ active: dailyFatAllowance }">
        <div class="row">
            <div class="col s9 m10">
                <div class="planTotalLeft">
                    <h2>Plan nutritional information:</h2>
                    <h3 class="mt10"><span class="planFatTotal" v-bind:class="{ aboveFatTotal: aboveFatTotal }"><strong>Total fat:</strong> {{totalFat | toDecimel}}g</span> <!-- &#x000B7; <strong>Total calories:</strong> {{totalCals}}kcals -->
                    </h3>
                </div>
            </div>
            <div class="col s3 m2">
                <a href="#" @click="downloadPdf">
                    <div class="planDownload" v-bind:class="{ aboveFatTotal: aboveFatTotal, readyToDownload: mealList.length}">
                        <div v-if="!pdfLoading">
                            <i class="material-icons medium">file_download</i>
                            <p class="hide-on-small-only">Download plan</p>
                        </div>

                        <div v-if="pdfLoading" class="preloader-wrapper big active">
                            <div class="spinner-layer spinner-blue-only">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div>
                                <div class="gap-patch">
                                    <div class="circle"></div>
                                </div>
                                <div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>

    <!-- Meal Modal -->
    <div id="meal-modal" class="modal swapper-modal modal-fixed-footer">
        <div class="modal-content">
            <h2>Select meal {{mealNum}}</h2>
            <blockquote class="left-align">Manually enter a meal/item name and it's fat content:</blockquote>
            <div class="row manualMeal">
                <div class="input-field col m6 s12">
                    <input id="manual_meal" type="text" class="validate" v-model="manualMealTitle">
                    <label for="manual_meal">Meal/item name</label>
                </div>
                <div class="input-field col m4 s8">
                    <input id="manual_fat" type="text" class="validate" v-model="manualMealFat">
                    <label for="manual_fat">Enter fat content (g)</label>
                </div>
                <div class="input-field col m2 s4">
                    <a class="btn-floating btn-large waves-effect waves-light" v-on:click="addManualMeal()"><i class="material-icons">add</i></a>
                </div>
            </div>
            <blockquote class="left-align">Or select a meal from our database:</blockquote>
            <h2>Breakfast</h2>
            <div>
                <div v-for="(recipe, index) in orderedBreakfastRecipes" :key="index">
                    <div class="row valign-wrapper" v-on:click="addBreakfast(index)">
                        <div class="col s3 m2">
                            <div class="swapper-crop">
                                <img class="responsive-img" :src="require(`@/assets/img/recipes/${recipe.slug}.jpg`)">
                            </div>
                        </div>
                        <div class="col s9 m8">
                            <span class="black-text">
                                <h3>{{recipe.title}}</h3>
                                <!-- <p><span v-if="recipe.time">Prep time: {{recipe.time}}mins &#x000B7;</span> Difficuly: {{recipe.difficulty}}</p> -->
                                <p class="nutitionalInfo">Fat: {{recipe.fat}}g &#x000B7; Calories: {{recipe.calories}}kcals </p>
                            </span>
                        </div>
                        <div class="col m2 s12 hide-on-small-only">
                            <a class="btn-floating btn-large waves-effect waves-light"><i class="material-icons">add</i></a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="divider"></div>
                    </div>
                </div>
            </div>
            <h2>Lunch</h2>
            <div>
                <div v-for="(recipe, index) in orderedLunchRecipes" :key="index">
                    <div class="row valign-wrapper" v-on:click="addLunch(index)">
                        <div class="col s3 m2">
                            <div class="swapper-crop">
                                <img class="responsive-img" :src="require(`@/assets/img/recipes/${recipe.slug}.jpg`)">
                            </div>
                        </div>
                        <div class="col s9 m8">
                            <span class="black-text">
                                <h3>{{recipe.title}}</h3>
                                <!-- <p><span v-if="recipe.time">Prep time: {{recipe.time}}mins &#x000B7;</span> Difficuly: {{recipe.difficulty}}</p> -->
                                <p class="nutitionalInfo">Fat: {{recipe.fat}}g &#x000B7; Calories: {{recipe.calories}}kcals </p>
                            </span>
                        </div>
                        <div class="col m2 s12 hide-on-small-only">
                            <a class="btn-floating btn-large waves-effect waves-light"><i class="material-icons">add</i></a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="divider"></div>
                    </div>
                </div>
            </div>
            <h2>Dinner</h2>
            <div>
                <div v-for="(recipe, index) in orderedDinnerRecipes" :key="index">
                    <div class="row valign-wrapper" v-on:click="addDinner(index)">
                        <div class="col s3 m2">
                            <div class="swapper-crop">
                                <img class="responsive-img" :src="require(`@/assets/img/recipes/${recipe.slug}.jpg`)">
                            </div>
                        </div>
                        <div class="col s9 m8">
                            <span class="black-text">
                                <h3>{{recipe.title}}</h3>
                                <!-- <p><span v-if="recipe.time">Prep time: {{recipe.time}}mins &#x000B7;</span> Difficuly: {{recipe.difficulty}}</p> -->
                                <p class="nutitionalInfo">Fat: {{recipe.fat}}g &#x000B7; Calories: {{recipe.calories}}kcals </p>
                            </span>
                        </div>
                        <div class="col m2 s12 hide-on-small-only">
                            <a class="btn-floating btn-large waves-effect waves-light"><i class="material-icons">add</i></a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="divider"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <a href="#!" class="modal-close waves-effect waves-green btn-flat">Close</a>
        </div>
    </div>

    <!-- Snacks Modal -->
    <div id="snacks-modal" class="modal swapper-modal snacks-modal">
        <div class="modal-content">

            <blockquote class="left-align">Manually enter a snack name and it's fat content:</blockquote>
            <div class="row manualMeal">
                <div class="input-field col m6 s12">
                    <input id="manual_snack" type="text" class="validate" v-model="manualSnackTitle">
                    <label for="manual_snack">Snack name</label>
                </div>
                <div class="input-field col m4 s8">
                    <input id="manual_snack_fat" type="text" class="validate" v-model="manualSnackFat">
                    <label for="manual_snack_fat">Enter fat content (g)</label>
                </div>
                <div class="input-field col m2 s4">
                    <a class="btn-floating btn-large waves-effect waves-light" v-on:click="addManualSnack()"><i class="material-icons">add</i></a>
                </div>
            </div>
            <blockquote class="left-align">Or select a snack from our database:</blockquote>

            <ul class="collapsible">
                <!-- Snacks - Fruit -->
                <li>
                    <div class="collapsible-header">
                        <div class="row">
                            <div class="col s10">
                                <h3>Fruit</h3>
                            </div>
                            <div class="col s2">
                                <i class="material-icons right-align">expand_more</i>
                            </div>
                        </div>
                    </div>
                    <div class="collapsible-body">
                        <div v-for="(snack, index) in snacksFruits" :key="index">
                            <div class="row valign-wrapper" v-on:click="addSnack(snack)">
                                <div class="col s10">
                                    <p><strong>{{snack.title}}</strong></p>
                                    <p><strong> Serving:</strong> {{snack.serves}} &#x000B7; <strong>Fat:</strong> {{snack.fat}}g</p>
                                </div>
                                <div class="col s2">
                                    <a class="btn-floating btn-small waves-effect waves-light"><i class="material-icons">add</i></a>
                                </div>
                            </div>
                            <div class="row">
                                <div class="divider"></div>
                            </div>
                        </div>
                    </div>
                </li>
                <!-- Snacks - Veg -->
                <li>
                    <div class="collapsible-header">
                        <div class="row">
                            <div class="col s10">
                                <h3>Vegetables</h3>
                            </div>
                            <div class="col s2">
                                <i class="material-icons right-align">expand_more</i>
                            </div>
                        </div>
                    </div>
                    <div class="collapsible-body">
                        <div v-for="(snack, index) in snacksVegetables" :key="index">
                            <div class="row valign-wrapper" v-on:click="addSnack(snack)">
                                <div class="col s10">
                                    <p><strong>{{snack.title}}</strong></p>
                                    <p><strong> Serving:</strong> {{snack.serves}} &#x000B7; <strong>Fat:</strong> {{snack.fat}}g</p>
                                </div>
                                <div class="col s2">
                                    <a class="btn-floating btn-small waves-effect waves-light"><i class="material-icons">add</i></a>
                                </div>
                            </div>
                            <div class="row">
                                <div class="divider"></div>
                            </div>
                        </div>
                    </div>
                </li>
                <!-- Snacks - Meats -->
                <li>
                    <div class="collapsible-header">
                        <div class="row">
                            <div class="col s10">
                                <h3>Meats/Fish/Poultry</h3>
                            </div>
                            <div class="col s2">
                                <i class="material-icons right-align">expand_more</i>
                            </div>
                        </div>
                    </div>
                    <div class="collapsible-body">
                        <div v-for="(snack, index) in snacksMeats" :key="index">
                            <div class="row valign-wrapper" v-on:click="addSnack(snack)">
                                <div class="col s10">
                                    <p><strong>{{snack.title}}</strong></p>
                                    <p><strong> Serving:</strong> {{snack.serves}} &#x000B7; <strong>Fat:</strong> {{snack.fat}}g</p>
                                </div>
                                <div class="col s2">
                                    <a class="btn-floating btn-small waves-effect waves-light"><i class="material-icons">add</i></a>
                                </div>
                            </div>
                            <div class="row">
                                <div class="divider"></div>
                            </div>
                        </div>
                    </div>
                </li>
                <!-- Snacks - Dairy -->
                <li>
                    <div class="collapsible-header">
                        <div class="row">
                            <div class="col s10">
                                <h3>Dairy</h3>
                            </div>
                            <div class="col s2">
                                <i class="material-icons right-align">expand_more</i>
                            </div>
                        </div>
                    </div>
                    <div class="collapsible-body">
                        <div v-for="(snack, index) in snacksDairy" :key="index">
                            <div class="row valign-wrapper" v-on:click="addSnack(snack)">
                                <div class="col s10">
                                    <p><strong>{{snack.title}}</strong></p>
                                    <p><strong> Serving:</strong> {{snack.serves}} &#x000B7; <strong>Fat:</strong> {{snack.fat}}g</p>
                                </div>
                                <div class="col s2">
                                    <a class="btn-floating btn-small waves-effect waves-light"><i class="material-icons">add</i></a>
                                </div>
                            </div>
                            <div class="row">
                                <div class="divider"></div>
                            </div>
                        </div>
                    </div>
                </li>
                <!-- Snacks - Bread -->
                <li>
                    <div class="collapsible-header">
                        <div class="row">
                            <div class="col s10">
                                <h3>Bread/Cereal/Grains</h3>
                            </div>
                            <div class="col s2">
                                <i class="material-icons right-align">expand_more</i>
                            </div>
                        </div>
                    </div>
                    <div class="collapsible-body">
                        <div v-for="(snack, index) in snacksBread" :key="index">
                            <div class="row valign-wrapper" v-on:click="addSnack(snack)">
                                <div class="col s10">
                                    <p><strong>{{snack.title}}</strong></p>
                                    <p><strong> Serving:</strong> {{snack.serves}} &#x000B7; <strong>Fat:</strong> {{snack.fat}}g</p>
                                </div>
                                <div class="col s2">
                                    <a class="btn-floating btn-small waves-effect waves-light"><i class="material-icons">add</i></a>
                                </div>
                            </div>
                            <div class="row">
                                <div class="divider"></div>
                            </div>
                        </div>
                    </div>
                </li>
                <!-- Snacks - Sweets -->
                <li>
                    <div class="collapsible-header">
                        <div class="row">
                            <div class="col s10">
                                <h3>Sweets</h3>
                            </div>
                            <div class="col s2">
                                <i class="material-icons right-align">expand_more</i>
                            </div>
                        </div>
                    </div>
                    <div class="collapsible-body">
                        <div v-for="(snack, index) in snacksSweets" :key="index">
                            <div class="row valign-wrapper" v-on:click="addSnack(snack)">
                                <div class="col s10">
                                    <p><strong>{{snack.title}}</strong></p>
                                    <p><strong> Serving:</strong> 1/{{snack.serves}} of recipe &#x000B7; <strong>Fat:</strong> {{snack.fat}}g</p>
                                </div>
                                <div class="col s2">
                                    <a class="btn-floating btn-small waves-effect waves-light"><i class="material-icons">add</i></a>
                                </div>
                            </div>
                            <div class="row">
                                <div class="divider"></div>
                            </div>
                        </div>
                    </div>
                </li>
                <!-- Snacks - Baking/Condiments -->
                <li>
                    <div class="collapsible-header">
                        <div class="row">
                            <div class="col s10">
                                <h3>Baking/Condiments</h3>
                            </div>
                            <div class="col s2">
                                <i class="material-icons right-align">expand_more</i>
                            </div>
                        </div>
                    </div>
                    <div class="collapsible-body">
                        <div v-for="(snack, index) in snacksBaking" :key="index">
                            <div class="row valign-wrapper" v-on:click="addSnack(snack)">
                                <div class="col s10">
                                    <p><strong>{{snack.title}}</strong></p>
                                    <p><strong> Serving:</strong> {{snack.serves}} &#x000B7; <strong>Fat:</strong> {{snack.fat}}g</p>
                                </div>
                                <div class="col s2">
                                    <a class="btn-floating btn-small waves-effect waves-light"><i class="material-icons">add</i></a>
                                </div>
                            </div>
                            <div class="row">
                                <div class="divider"></div>
                            </div>
                        </div>
                    </div>
                </li>
                <!-- Snacks - Other -->
                <li>
                    <div class="collapsible-header">
                        <div class="row">
                            <div class="col s10">
                                <h3>Other</h3>
                            </div>
                            <div class="col s2">
                                <i class="material-icons right-align">expand_more</i>
                            </div>
                        </div>
                    </div>
                    <div class="collapsible-body">
                        <div v-for="(snack, index) in snacksOther" :key="index">
                            <div class="row valign-wrapper" v-on:click="addSnack(snack)">
                                <div class="col s10">
                                    <p><strong>{{snack.title}}</strong></p>
                                    <p><strong> Serving:</strong> {{snack.serves}} &#x000B7; <strong>Fat:</strong> {{snack.fat}}g</p>
                                </div>
                                <div class="col s2">
                                    <a class="btn-floating btn-small waves-effect waves-light"><i class="material-icons">add</i></a>
                                </div>
                            </div>
                            <div class="row">
                                <div class="divider"></div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="modal-footer">
            <a href="#!" class="modal-close waves-effect waves-green btn-flat">Close</a>
        </div>
    </div>

    <div id="aboveFatModal" class="modal">
        <div class="modal-content">
            <h4>The fat content of this meal plan is too high!</h4>
            <p>Your meal plan is now higher in fat content than the recommended maximum daily amount you entered.</p>
            <p>You will not be able to download and use this plan until you replace one or more of the selected recipes with a recipe of lower fat content.</p>
        </div>
        <div class="modal-footer">
            <a href="#!" class="modal-close waves-effect waves-green btn-flat">Okay</a>
        </div>
    </div>

    <div id="notReadyModal" class="modal">
        <div class="modal-content">
            <h4>You must select an option for each meal</h4>
            <p>In order to complete the meal plan you must select an option for each meal. If you want to print an indivual recipe, click the "view recipe" button and click the "print recipe" button. </p>
        </div>
        <div class="modal-footer">
            <a href="#!" class="modal-close waves-effect waves-green btn-flat">Okay</a>
        </div>
    </div>
</div>
</template>

<script>
import firebase from 'firebase';
import getRecipes from '@/assets/data/recipes-v03';
import getSnacks from '@/assets/data/snacks-v01';
import _ from 'lodash';
import html2canvas from 'html2canvas'
import * as jsPDF from 'jspdf'
import request from 'request';

export default {
    name: 'Profile',
    data() {
        return {
            user: firebase.auth().currentUser,

            recipes: [],
            snacks: [],

            dailyFatAllowance: '',

            mealNum: 1,

            manualMealTitle: null,
            manualMealFat: null,

            manualSnackTitle: null,
            manualSnackFat: null,

            mealModal: null,
            snackModal: null,

            mealList: [],
            snackList: [],

            breakfastRecipes: [],
            lunchRecipes: [],
            dinnerRecipes: [],

            snacksFruits: [],
            snacksVegetables: [],
            snacksMeats: [],
            snacksDairy: [],
            snacksBread: [],
            snacksSweets: [],
            snacksBaking: [],
            snacksOther: [],

            totalFat: 0,
            totalCals: 0,

            aboveFatTotal: false,
            aboveFatModal: null,

            notReadyModal: null,
            readyToDownload: null,

            pdfLoading: false,

            output: null

            // snacksFruits: ["Apples, applesauce","Bananas","Berries","Grapes","Kiwi","Oranges, tangerines","Peaches, nectarines","Fruit cocktail and other canned fruit (except grapefruit)","Raisins, other dried fruit"],
            // snacksVegetables: ["Broccoli","Carrots","Cauliflower","Green beans","Salad greens, spinach","Tomatoes, canned tomato products","Onions","Potatoes, yams","Zucchini","Frozen vegetables without added fat","Canned beans, fat-free refried beans, vegetarian baked beans, 99% fat-free chili with beans"],
            // snacksMeats: ["Egg whites, egg substitute","99% fat-free ground turkey","Turkey breast, turkey deli slices","Low-fat hot dogs like Ball Park®","Smoked White Meat Franks (0 g fat) and Hebrew National® 97% Fat-free Beef Franks (1 g fat)","Tuna in water","Lean white fish like sole and tilapia","Shrimp, scallops, other shellfish"],
            // snacksDairy: ["Skim milk","Fat-free cottage or ricotta cheese","Fat-free yogurt or Greek yogurt","Fat-free cheese, cream cheese, sour cream","Dairy Fat-free half and half"],
            // snacksBread: ["Sliced bread, rolls, bagels, pita bread, English muffin","Corn tortillas, fat-free flour tortillas","Cereals with <1.5 g fat per serving like CheeriosTM, Wheaties®, or Rice ChexTM","Hot cereal like Cream of WheatTM","Pasta, rice","Fat-free saltine crackers, matzo, rice cakes","Pretzels"],
            // snacksSweets: ["Angel cake","Fat-free fig cookies","Gelatin, fat-free pudding","Sorbet, popsicles, fat-free frozen yogurt and ice cream, fat-free Fudgsicles®","Fat-free chocolate syrup (like Hershey’s®)"],
            // snacksBaking: ["Nonstick cooking spray","Fat-free butter flavored spray","Jelly, jam, fruit spread","Fat-free salad dressing","Fresh lemon, lime, vinegars","Fat-free mayonnaise","Condiments like ketchup, mustard, barbeque sauce, teriyaki sauce, soy sauce, salsa, hot sauce, Tabasco®,relish, pickles"],
            // snacksOther: ["Boca® original vegan veggie burger (1 g fat per patty)","CedarLane® low-fat beans, rice and cheese-style burrito (frozen, 1 g fat per burrito)","Low-fat peanut butter like PB2® and chocolate PB2® (powdered) peanut butter (1-1.5 g fat/2 tbsp.)","Cedar’s® fat-free hummus","Soups (up to 1.5 g fat per cup)","Fat-free tomato, spaghetti or pizza sauce"]
        }
    },
    created() {
        /* eslint-disable */
        this.recipes = getRecipes;
        this.snacks = getSnacks;

        for (var i = 0; i < this.recipes.length; i++) {

            var obj = this.recipes[i];

            if (obj.category.includes("breakfast")) {

                this.breakfastRecipes.push(this.recipes[i])

            }
            if (obj.category.includes("lunch")) {

                this.lunchRecipes.push(this.recipes[i])

            }
            if (obj.category.includes("dinner")) {

                this.dinnerRecipes.push(this.recipes[i])

            }
            if (obj.category.includes("dessert")) {

                this.snacksSweets.push(this.recipes[i])

            }

        }

        for (var i = 0; i < this.snacks.length; i++) {

            var obj = this.snacks[i];

            if (obj.category.includes("fruit")) {

                this.snacksFruits.push(this.snacks[i])

            }
            if (obj.category.includes("vegetables")) {

                this.snacksVegetables.push(this.snacks[i])

            }
            if (obj.category.includes("meat")) {

                this.snacksMeats.push(this.snacks[i])

            }
            if (obj.category.includes("dairy")) {

                this.snacksDairy.push(this.snacks[i])

            }
            if (obj.category.includes("bread")) {

                this.snacksBread.push(this.snacks[i])

            }
            if (obj.category.includes("baking")) {

                this.snacksBaking.push(this.snacks[i])

            }
            if (obj.category.includes("other")) {

                this.snacksOther.push(this.snacks[i])

            }

        }
    },
    mounted() {
        /* eslint-disable */

        const formSelect = document.querySelector('.formSelect')
        M.FormSelect.init(formSelect)

        const mealModal = document.querySelector('#meal-modal')
        this.mealModal = M.Modal.init(mealModal)

        const snackModal = document.querySelector('#snacks-modal')
        this.snackModal = M.Modal.init(snackModal)

        const aboveFatModal = document.querySelector('#aboveFatModal')
        this.aboveFatModal = M.Modal.init(aboveFatModal)

        const notReadyModal = document.querySelector('#notReadyModal')
        this.notReadyModal = M.Modal.init(notReadyModal)

        const collapsible = document.querySelectorAll('.collapsible')
        M.Collapsible.init(collapsible)

        const tooltip = document.querySelectorAll('.tooltip')
        M.Tooltip.init(tooltip)

        document.body.classList.add("sub-page");
    },
    methods: {

        openMealModal() {
            if (this.dailyFatAllowance) {
                this.mealModal.open();
            } else {
                var toastHTML = '<span>Enter you daily recommended fat allowance before adding a meal</span>';
                M.toast({
                    html: toastHTML,
                    classes: 'red'
                });
            }
        },

        openSnackModal() {
            if (this.dailyFatAllowance) {
                this.snackModal.open();
            } else {
                var toastHTML = '<span>Enter you daily recommended fat allowance before adding a snack</span>';
                M.toast({
                    html: toastHTML,
                    classes: 'red'
                });
            }
        },

        addManualMeal() {
            console.log(this.manualMealTitle + ": " + this.manualMealFat)

            var mealObj = {
                title: this.manualMealTitle,
                fat: parseInt(this.manualMealFat),
                calories: 0
            }

            this.mealList.push(mealObj)

            this.calculateTotals();

            var toastHTML = '<span>Meal added</span>';
            M.toast({
                html: toastHTML
            });
        },

        addBreakfast(index) {

            this.mealList.push(this.orderedBreakfastRecipes[index])

            this.calculateTotals();

            var toastHTML = '<span>Meal added</span>';
            M.toast({
                html: toastHTML
            });
        },

        addLunch(index) {
            this.mealList.push(this.orderedLunchRecipes[index])

            this.calculateTotals();

            var toastHTML = '<span>Meal added</span>';
            M.toast({
                html: toastHTML
            });
        },

        addDinner(index) {
            this.mealList.push(this.orderedDinnerRecipes[index])

            this.calculateTotals();

            var toastHTML = '<span>Meal added</span>';
            M.toast({
                html: toastHTML
            });
        },

        addManualSnack() {
            console.log(this.manualSnackTitle + ": " + this.manualSnackFat)

            var snackObj = {
                title: this.manualSnackTitle,
                fat: parseInt(this.manualSnackFat),
                calories: 0
            }

            this.snackList.push(snackObj)

            this.calculateTotals();

            var toastHTML = '<span>Snack added</span>';
            M.toast({
                html: toastHTML
            });
        },

        addSnack(index) {

            // var snackObj = {title:index, fat:0, calories:0}

            this.snackList.push(index)

            this.calculateTotals();

            var toastHTML = '<span>Snack added</span>';
            M.toast({
                html: toastHTML
            });
        },

        removeRecipe(index) {
            this.mealList.splice(index, 1);

            this.calculateTotals();

            var toastHTML = '<span>Meal removed</span>';
            M.toast({
                html: toastHTML
            });
        },

        removeSnack(index) {
            this.snackList.splice(index, 1);

            this.calculateTotals();

            var toastHTML = '<span>Snack removed</span>';
            M.toast({
                html: toastHTML
            });
        },

        calculateTotals() {

            this.totalFat = 0;
            this.totalCals = 0;

            for (var i = 0; i < this.mealList.length; i++) {
                this.totalFat = this.totalFat + this.mealList[i].fat;
                this.totalCals = this.totalCals + this.mealList[i].calories;
            }

            for (var i = 0; i < this.snackList.length; i++) {
                this.totalFat = this.totalFat + this.snackList[i].fat;
                // this.totalCals = this.totalCals + this.snackList[i].calories;
            }

            if (this.dailyFatAllowance && this.totalFat > this.dailyFatAllowance) {
                this.aboveFatTotal = true;
                this.aboveFatModal.open();
            } else {
                this.aboveFatTotal = false;
            }

            this.mealModal.close();
            this.snackModal.close();

        },

        downloadPdf() {

            this.pdfLoading = true;

            // window.scrollTo(0,0)

            const filename = 'HoFHRecipes-meal-plan.pdf';

            html2canvas(document.querySelector('#printMe'), {
                scrollX: 0,
                scrollY: -window.scrollY
            }).then(canvas => {

                let pdf = new jsPDF('p', 'mm', 'a4');
                const imgData = canvas.toDataURL('image/png')
                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth() - pdf.internal.pageSize.getWidth() / 4;
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(imgData, 'PNG', 5, 5, pdfWidth, pdfHeight);
                // pdf.save(filename);

                pdf.save(filename, {
                    returnPromise: true
                }).then((value) => {
                    console.log("DOWNLOAD COMPLETE")
                    var toastHTML = '<span>Meal plan downloaded</span>';
                    M.toast({
                        html: toastHTML
                    });
                    this.pdfLoading = null;
                });

                const url = 'https://hooks.slack.com/services/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';
                const text = this.user.email + ' has downloaded a meal plan';

                request.post({
                        headers: {
                            'Content-type': 'application/json'
                        },
                        url,
                        form: JSON.stringify({
                            text
                        }),
                    },
                    (error, res, body) => console.log(error, body, res.statusCode)
                );
            });
        },

        pdfDone() {
            this.pdfLoading = false;
        },

        getNumbers: function (start, stop) {
            return new Array(stop - start).fill(start).map((n, i) => n + i);
        }

    },
    computed: {
        orderedMealRecipes: function () {
            return _.orderBy(this.breakfastRecipes, 'title')
        },

        orderedBreakfastRecipes: function () {
            return _.orderBy(this.breakfastRecipes, 'title')
        },

        orderedLunchRecipes: function () {
            return _.orderBy(this.lunchRecipes, 'title')
        },

        orderedDinnerRecipes: function () {
            return _.orderBy(this.dinnerRecipes, 'title')
        }
    },
    filters: {
        toDecimel(num) {
            return parseFloat(num).toFixed(1);
        }
    }
}
</script>

<style>
input:-internal-autofill-selected {
    background-color: #fff !important;
}

.mealPlan .sub-page .hero-p {
    color: #1a727e;
    margin-bottom: 25px;
}

.mealPlan {
    margin-top: 100px !important
}

.mealPlan h1 {
    text-align: left !important;
    margin: 0 0 .5em !important;
    padding: 0 !important;
}

.meal-list h2,
.snack-list h2 {
    margin-top: 1em !important;
}

.mealPlan h3 {
    margin-top: 22px;
    color: #fbab18;
}

.revealSwapper h3 {
    margin-bottom: 0;
    margin-top: 0;
    color: #1a727d;
    font-family: "Avenir", Helvetica, Arial, sans-serif;
}

.revealSwapper {
    -webkit-transition: all 500ms ease-in-out;
    -moz-transition: all 500ms ease-in-out;
    -o-transition: all 500ms ease-in-out;
    transition: all 500ms ease-in-out;
    opacity: 0.3;
    cursor: not-allowed;
}

.revealSwapper.active {
    opacity: 1;
    cursor: auto;
    pointer-events: all;
}

.dropdown-content li {
    min-height: unset;
    line-height: initial;
}

.dropdown-content li>a,
.dropdown-content li>span {
    padding: 11px 16px;
}

.meal-swapper .card-panel {
    padding: 20px;
}

.meal-swapper i.medium {
    font-size: 2.5rem;
    color: #1a727d;
}

.meal-list h3,
.snack-list h3 {
    margin-bottom: 0;
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    font-size: 1.3em;
    color: #1a727d;
    font-family: 'Baloo Thambi', cursive !important;
    margin-top: 10px !important;
}

.meal-list h3 span,
.snack-list h3 span {
    color: #85b0b5;
}

.meal-list .card-panel .m0 {
    margin-bottom: 5px;
}

.swapper-crop {
    border-radius: 50%;
    overflow: hidden;
    width: 70px;
    height: 70px;
    position: relative;
    margin: auto;
}

.swapper-modal {
    height: 90% !important;
    max-height: 90% !important;
    top: 5% !important;
}

.swapper-modal .row {
    margin-bottom: 15px;
}

.swapper-modal h3 {
    color: #1a727d;
    margin-top: 15px;
    margin-bottom: 0;
}

.meal-list p,
.snack-list p {
    margin: 10px 0;
    text-align: left;
    color: #fbab18;
}

.meal-list .nutitionalInfo,
.snack-list .nutitionalInfo,
#printMe .nutitionalInfo {
    color: #1a727d;
    text-align: left;
}

.swapper-modal blockquote {
    margin: 40px 0 30px;
    padding: 1rem 1.5rem;
    border-left: 5px solid #fbab18;
    background-color: #f1f1f1;
}

.swapper-crop img {
    max-width: 170%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.meal-swapper.added-margin {
    margin-bottom: 140px;
}

.swapper-modal .btn-floating {
    background-color: #fbab18 !important;
    width: 40px;
    height: 40px;
}

.swapper-modal .btn-floating i {
    line-height: 40px;
}

.mealSelected .btn {
    width: 100%;
    font-weight: 700;
}

.mealSelected .viewRecipe {
    background-color: #1a727c !important;
}

.meal-list .changeRecipe,
.snack-list .changeRecipe {
    background-color: #fbab18 !important;
    display: block;
}

.snack-list .changeRecipe {
    width: 80%;
}

.planTotal {
    position: fixed;
    bottom: -50%;
    left: 0;
    background-color: #fff;
    width: 100%;
    z-index: 1;
    -webkit-transition: all 500ms ease-in-out;
    -moz-transition: all 500ms ease-in-out;
    -o-transition: all 500ms ease-in-out;
    transition: all 500ms ease-in-out;
}

.planTotal.active {
    bottom: 0;
}

.planTotal .row,
.planTotal .col,
.planTotal h2,
.planTotal h3,
.planDownload p {
    margin: 0;
    padding: 0;
}

.planTotalLeft {
    padding: 1.5em 6em;
}

.planTotalLeft h2 {
    color: #fbab16;
}

.planTotalLeft h3 {
    color: #1a727c;
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    padding-top: 5px;
}

.preloader-wrapper.big {
    width: 84px;
    height: 84px;
}

.spinner-blue,
.spinner-blue-only {
    border-color: #fff;
}

.planDownload {
    background-color: #fbab16;
    height: 100%;
    padding: 1em;
    color: #1a727c;
    opacity: .3;
}

.readyToDownload.planDownload {
    opacity: 1
}

.planDownload.aboveFatTotal {
    background-color: #ee6e73;
    cursor: not-allowed;
}

.planDownload.aboveFatTotal .material-icons,
.planDownload.aboveFatTotal p {
    opacity: 0.3;
}

.planFatTotal {
    color: #38b538;
}

.planFatTotal.aboveFatTotal {
    color: #ee6e73;
}

.snacks-modal .collapsible-body {
    padding: 1rem;
}

.snacks-modal .collapsible-header {
    display: block;
}

.snacks-modal .row {
    margin-bottom: 0px;
}

.snacks-modal .btn-floating {
    margin-top: 0px;
}

.snacks-modal p {
    color: #1a727c;
    margin-bottom: 13px;
}

.snacks-modal h3 {
    margin-top: 0;
}

#aboveFatModal h4 {
    color: #f16a54;
}

hr.hrStyle {
    border: 0;
    border-bottom: 1px dashed #fbab16;
    background: #fbab16;
    margin-bottom: 50px;
}

.pdfBuilder {
    /* display: none; */
}

.pdfBuilder ul:not(.browser-default)>li {
    list-style-type: disc;
    margin-left: 18px;
}

.meal-modal .modal-content ul:not(.browser-default)>li {
    list-style-type: none;
}

.pdfBuilder h4 {
    font-size: 1em;
    font-family: "Avenir", Helvetica, Arial, sans-serif;
}

.pdfBuilder .row {
    margin-bottom: 10px;
}

.pdfBuilder h1 {
    font-size: 2em;
}

.pdfBuilder .hrStyle {
    margin-bottom: 30px;
}

#printMe {
    position: absolute;
    width: 850px;
    left: -10000px;
}

#printMe hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

@media screen and (max-device-width: 420px) {
    .meal-swapper .card-panel {
        padding: 14px;
    }

    .planTotal.active {
        bottom: 78px;
    }

    .planTotalLeft {
        padding: 1rem 1.5em;
    }

    .planTotalLeft h2 {
        font-size: 1.3em;
    }

    .planTotalLeft h3 {
        font-size: 1em;
        padding-top: 0px;
    }

    .planDownload i.medium {
        font-size: 3rem;
    }

    .preloader-wrapper.big {
        width: 39px;
        height: 39px;
    }

    .swapper-crop {
        width: 50px;
        height: 50px;
    }

    .swapper-modal .modal-content {
        padding: 20px 10px;
    }

    .meal-swapper .card-panel {
        padding: 7px 2px;
    }

    .meal-list .card-panel {
        padding: 10px;
    }

    .mealSelected .btn {
        padding: 0;
    }
}

@media print {
    body * {
        visibility: hidden;
    }

    body.sub-page {
        background-image: none;
    }

    #printThis,
    #printThis * {
        visibility: visible;
    }

    #printThis {
        position: absolute;
        left: 0;
        top: 0;
    }

    .row {
        margin-bottom: 20px;
    }

    .printHeader {
        color: #25b7d3;
        display: block;
    }

    .printHeader span {
        font-size: 1rem;
    }

    .recipe .blob {
        height: 80px;
    }

    p,
    .methods li {
        margin: 7px 0;
    }

    .recipe .blob p {
        font-size: 1.3rem;
    }

    .recipe h4 {
        font-size: 1.3rem;
    }

    ul:not(.browser-default)>li {
        list-style-type: disc;
    }

    [type="checkbox"]+span:not(.lever):before,
    [type="checkbox"]:not(.filled-in)+span:not(.lever):after {
        display: none;
        padding-left: 0;
    }

    [type="checkbox"]:not(:checked),
    [type="checkbox"]:checked {
        display: none;
    }

    [type="checkbox"]:not(.filled-in)+span:not(.lever):after {
        display: none;
    }
}
</style>
