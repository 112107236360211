export default [
  {
    title: "Everyday Banana Bread",
    slug: "everyday-banana-bread",
    img: "everyday-banana-bread",
    hasImage: true,
    description: "Delicious and moist.",
    ingredients:
      "3 ripe bananas, mashed|2 egg whites|2 tbsp. canola oil|1/3-1/2 cup (67g-100g) sugar|1/4 cup (56ml) skim milk|1/2 tsp. salt|1/2 tsp. baking soda|1 tsp. baking powder|1½ cups (210g) flour.",
    methods:
      'Preheat oven to 350°F (176°C).|In a large mixing bowl, combine the first 5 ingredients and blend well.|Gently blend in the dry ingredients and stir until moistened.|Pour into a 5"x9" loaf pan coated with nonstick cooking spray.|Bake for 50-55 minutes, until a knife comes out clean.|Refrigerate leftovers.',
    time: 65,
    serves: 10,
    fat: 3,
    saturated: 0,
    calories: 156,
    cholesterol: 0,
    carbohydrate: 29,
    protein: 3,
    sodium: 243,
    difficulty: "easy",
    category: "dessert|snack",
  },
  {
    title: "Oven Fried Sole",
    slug: "oven-fried-sole",
    img: "oven-fried-sole",
    hasImage: true,
    ingredients:
      "1 pound of sole or flounder |1 cup (125g) dry bread crumbs |1/4 cup (55g) Fat Free French or Thousand Island dressing ",
    methods:
      "Preheat oven to 450°F (232°C).| Dip fish into dressing, then into bread crumbs. | Spray cookie sheet with Pam and arrange fish.|  Bake for 10-12 minutes, or until the fish flakes easily when tested with a fork. | Flip half of the way through. | Serve with lemon or more dressing to dip.  ",
    serves: 4,
    time: 20,
    fat: 3.6,
    calories: 207,
    cholesterol: 52,
    carbohydrate: 24,
    saturated: 0.8,
    sodium: 670,
    fiber: 2,
    difficulty: "easy",
    category: "dinner|quick and easy",
  },
  {
    title: "Beef Stroganoff",
    slug: "beef-stroganoff",
    img: "beef-stroganoff",
    hasImage: true,
    description:
      "Reduce the fat in this classic dish by using fat free sour cream/fat free greek yogurt and non-stick cooking spray without reducing the flavor!",
    ingredients:
      "1 pound boneless beef sirloin steak about 1/2 inch thick|2 tsp paprika|1 8oz (236ml) container of fat-free sour cream/fat free greek yogurt|2 tablespoons all-purpose flour|1 tablespoon tomato paste|1/2 cup (113ml) low sodium, fat-free beef broth|1/8 teaspoon black pepper|8 oz (236ml) yolk free wide egg noodles|Nonstick cooking spray|1 8-ounce (236ml) container of sliced mushrooms|1/2 cup (88g) chopped onion|1 tsp minced garlic|Salt to taste",
    methods:
      "Trim the fat from the beef. Thinly slice across the grain into bite-sized strips then coat with paprika. In a small mixing bowl stir together the sour cream/greek yogurt, flour, and tomato paste. Stir in the broth and pepper. Set aside. |Cook the noodles per package directions. Omit any oil. Drain well. |Meanwhile spray a large skillet with nonstick spray. Preheat over medium heat. Add the onion, garlic and cook until onions are tender about 3 minutes. Stir in the strips of meat and cook for another 3 minutes. Add the mushrooms, cover and simmer for 5 minutes. |Add the sour cream/greek yogurt mixture to the skillet. Cook and stir over medium heat until thickened and bubbly. Serve meat mixture over the noodles.",
    serves: 5,
    fat: 7.4,
    saturated: 1.9,
    calories: 370,
    cholesterol: 63,
    carbohydrate: 47,
    protein: 28,
    sodium: 164,
    difficulty: "medium",
    category: "dinner|family favorites",
  },
  {
    title: "Asian Barbequed Pork Loin",
    slug: "asian-barbequed-pork-loin",
    img: "asian-barbequed-pork-loin",
    hasImage: true,
    description:
      "A juicy pork tenderloin with a slightly sweet barbeque sauce.",
    ingredients:
      "3 lb. (48oz) boneless whole pork loin roast (trimmed of fat)|1/2 tsp. garlic salt|1/4 tsp. black pepper|nonstick cooking spray|1/4 cup (44g) chopped onion|1/2 cup (175g) ketchup|1/3 cup (57g) brown sugar|1 tbsp. hoisin sauce|1½ tsp. mustard|1 tsp. soy sauce|1/2 tsp. garlic powder|1/4 tsp. ground ginger|1/4 tsp. Chinese five spice powder",
    methods:
      "Set oven to 350°F (176°C).|Sprinkle roast with garlic salt and pepper. Place in a roasting pan and bake, uncovered, for 40 minutes.|In a nonstick sauce pan, spray with cooking spray and sauté the onions. Stir in remaining ingredients and bring to a boil. Simmer uncovered until sauce is reduced to 3/4 cup, stirring often (20-25 minutes).|Pour sauce over pork. Bake an additional 10-20 minutes, until a meat thermometer reads 145°F (62°C).|Remove from the oven, cover with foil and let the roast rest 10-15 minutes before slicing to keep the juices in.",
    time: 100,
    serves: 12,
    fat: 3.2,
    saturated: 1,
    calories: 146,
    cholesterol: 52,
    carbohydrate: 10,
    protein: 32,
    sodium: 291,
    difficulty: "hard",
    category: "dinner|hearty meal|pork",
  },
  {
    title: "Banana Bran Muffins",
    slug: "banana-bran-muffins",
    img: "banana-bran-muffins",
    hasImage: true,
    description:
      "This recipe uses those “spotted” bananas. Enjoy for breakfast or anytime.",
    ingredients:
      "2 ripe bananas|2½ cups (250g) bran-flake cereal|1 cup (225ml) skimmed milk|2 tbsp. applesauce|2 egg whites|1 tsp. vanilla extract|1 cup (140g) flour|1/3-1/2 cup (75g-113g) sugar|1 tbsp. baking powder|1 tsp. cinnamon|1/4 cup (44g) raisins, if desired",
    methods:
      "Preheat oven to 375°F (190°C).|In a large mixing bowl, mash bananas with a fork, stir in the rest of the ingredients in the order given, and mix well.|Prepare a muffin tin with nonstick cooking spray.|Divide mix among 12 muffins.|Bake for 20-30 minutes or until a knife comes out clean.|Cool and eat. Refrigerate leftovers.",
    time: 40,
    serves: 12,
    fat: 1,
    saturated: 0,
    calories: 141,
    cholesterol: 0,
    carbohydrate: 30,
    protein: 3,
    sodium: 203,
    fiber: 3,
    difficulty: "easy",
    category: "light snacks|breakfast|family favorites",
  },
  {
    title: "Eggplant/Aubergine Parmesan",
    slug: "eggplant-parmesan",
    img: "eggplant-parmesan",
    hasImage: true,
    description:
      "Authentic taste without gobs of grease. The big difference is that the eggplant/aubergine is breaded then baked, not fried.",
    ingredients:
      "1 large eggplant/aubergine, peeled and sliced|1 zucchini/courgette, chopped|1 summer squash, chopped|8oz (1/2 lb.) mushrooms, sliced|26 oz. (737g) jar tomato sauce (check for lowest fat content)|4 oz. (113g) non-fat or low-fat mozzarella cheese, shredded*|4 tbsp. Parmesan cheese|2 egg whites|1 cup (50g) bread crumbs",
    methods:
      'Preheat oven to 375°F (190°C).|Mix whites in 1 bowl, dip eggplant/aubergine slices, then dip in another bowl with bread crumbs.|Put in 9"x13" inch baking dish prepared with nonstick cooking spray.|Bake for 30 minutes. Remove from oven.|Pour sauce over eggplant/aubergine, sprinkle with veggies and cheese, and resume baking for 20-25 minutes until bubbly.',
    note:
      "* Analysis based on using non fat mozzarella. If low-fat cheese is used, fat will increase to 7g fat/serving.",
    time: 65,
    serves: 4,
    fat: 3,
    saturated: 2,
    calories: 279,
    cholesterol: 10,
    carbohydrate: 42,
    protein: 21,
    sodium: 479,
    difficulty: "medium",
    category: "dinner|vegetarian",
  },
  {
    title: "Berry Crunch Treat",
    slug: "berry-crunch-treat",
    img: "berry-crunch-treat",
    hasImage: true,
    description: "Wonderful for Breakfast, Lunch or Snack!",
    ingredients:
      "1/2 cup (100g) Fresh or Frozen Berries|2 tablespoon Simply Fruit Preserves (any flavor)|1/8 cup (18g) granola|1/2 cup (125g) Nonfat Plain Greek Yogurt",
    methods: "Mix all together and enjoy!",
    time: 5,
    serves: 1,
    fat: 0.7,
    saturated: 0.18,
    calories: 205,
    cholesterol: 6,
    carbohydrate: 37,
    protein: 13,
    sodium: 63,
    fiber: 5,
    difficulty: "easy",
    category: "quick and easy|breakfast|snack",
  },
  {
    title: "Southern Black-Eyed Pea Patties",
    slug: "black-eyed-pea-patties",
    img: "black-eyed-pea-patties",
    hasImage: true,
    description:
      "An interesting twist to traditional veggie burgers. Serve in a pita pocket with tomato, cucumber and nonfat sour cream/fat free greek yogurt or salsa.",
    ingredients:
      "2/3 cup (93g) oats|2½ cups (375g) black-eyed peas, cooked|3 sliced green onions/spring onions|2 egg whites|1 teaspoons cumin|1½ teaspoons chili powder|1/8 teaspoons cayenne pepper|1/4 teaspoons salt|1 tablespoon oil",
    methods:
      "Process oats in a food processor to a powder. |Take out 3 tbsp oat powder and save for later use. |Add all other ingredients except oil to the food processor and process to a thick paste (add a tiny bit of water if needed).| Remove and make 6 patties. Roll in reserved oat powder and sauté in oil on a skillet. Cook through and brown on each side.",
    note:
      "Black eyed peas are available in convenient precooked frozen or fresh form, as well as canned. If you use canned beans, rinse and drain well.",
    serves: 6,
    fat: 4,
    saturated: 1,
    calories: 166,
    cholesterol: 0,
    carbohydrate: 27,
    protein: 7,
    sodium: 146,
    fiber: 6,
    difficulty: "easy",
    category: "vegetarian|lunch|dinner",
  },
  {
    title: "Blackened Pork Chops w/ Curried Rice and Vegetables",
    slug: "blackened-pork-chops",
    img: "blackened-pork-chops",
    hasImage: true,
    description:
      "A dish with a kick! Use Creole seasoning if you want a tamer version. In either case, a hearty and delicious dinner or lunch treat.",
    ingredients:
      '1 pound center cut boneless pork chops, trimmed (Approx. 4 0z. (113g) Each chop)|1 cup (200g) rice, uncooked|1 cup (140g) fat-free chicken stock|1 cup (225ml) water|4 green onions/spring onions, cut stems into 1/2" pieces, dice green top|12 ounce (340g) bag frozen peas and carrots|2 teaspoon sesame oil|1 Tablespoon curry powder|1 teaspoon garlic powder|Blackening seasoning (or Creole seasoning for less spicy chop)|Cooking spray',
    methods:
      "Add the sesame oil, curry powder and garlic powder to a 2½ qt. sauce pan.|Heat on medium-high until oil begins to sizzle, add onion stems. Sauté for one minute, then add rice, stir until well coated.|Add chicken stock and water, reduce heat to low simmer.|Cover, allow rice to cook until all water is absorbed.|Cook frozen peas and carrots in the microwave, as directed, then drain liquid.|Add vegetables and green onion/spring onion tops to the rice and allow to stand, covered while preparing the pork chops.|Season pork chops with blackening seasoning on one side. Spray frying pan with pan spray, set on medium heat.|Add pork chops to pan, seasoned side down, and season other side. Cook 3-4 minutes per side, or until done.|Mound rice and vegetable mixture on a plate, top with a pork chop - and enjoy!",
    serves: 4,
    fat: 7.2,
    saturated: 2,
    calories: 386,
    cholesterol: 59,
    carbohydrate: 46,
    protein: 35,
    sodium: 457,
    fiber: 4,
    difficulty: "medium",
    category: "dinner|hearty meal|pork",
  },
  {
    title: "Chicken (or Pork) Dijon",
    slug: "chicken-or-pork-dijon",
    img: "chicken-or-pork-dijon",
    hasImage: true,
    description:
      "This sauce can be used ahead of time as a marinade or to spoon over boneless skinless breasts or pork tenderloins.",
    ingredients:
      "1lb. (16oz) boneless, skinless chicken breasts|1/2 cup (175g) Dijon mustard|1/2 cup (175g) maple syrup|2 tsp. red wine vinegar|rosemary-garlic spice blend, to taste|fresh rosemary sprigs, if desired",
    methods:
      "Preheat oven to 375°F (190°C).|In a small bowl, mix together mustard, syrup, and vinegar.|Pour over chicken breasts that have been placed in a nonstick, foillined pan. Reserve a little sauce for basting.|Sprinkle rosemary-garlic blend (to taste) and place a couple fresh rosemary sprigs between breasts.|Bake for 30-35 minutes.",
    time: 45,
    serves: 4,
    fat: 4,
    saturated: 1,
    calories: 172,
    cholesterol: 75,
    carbohydrate: 6,
    protein: 28,
    sodium: 381,
    difficulty: "easy",
    category: "dinner|quick and easy",
  },
  {
    title: "Chicken Breasts with Savory Peach Glaze",
    slug: "chicken-breasts-with-savory-peach-glaze",
    img: "chicken-breasts-with-savory-peach-glaze",
    hasImage: true,
    description:
      "This easy savory recipe is another way to liven up your tiresome chicken breasts! The delicious glaze can also be added to pork tenderloin.",
    ingredients:
      "1 cup (350g) peach preserves|1 tablespoon soy sauce|1 tablespoon Dijon mustard|1 small clove of garlic, minced|4 scallions, chopped|1/3 of a sweet red bell pepper, diced|1/8 tsp red pepper flakes (optional)|6 skinless, boneless, chicken breast halves",
    methods:
      "Preheat oven to 350°F (176°C).|Mix all ingredients, except chicken, in a medium sized bowl.|Place chicken in a pan and cover with mixture.|Bake for 30 minutes or until chicken reaches an internal temperature of 165°F (73°C)",
    time: 40,
    serves: 6,
    fat: 4.5,
    saturated: 1,
    calories: 299,
    cholesterol: 124,
    carbohydrate: 21,
    protein: 39,
    sodium: 304,
    difficulty: "easy",
    category: "dinner|quick and easy",
  },
  {
    title: "Chicken Caesar Salad",
    slug: "chicken-caesar-salad",
    img: "chicken-caesar-salad",
    hasImage: true,
    description:
      "A healthy version of a high-fat favorite. Add bright red cherry tomatoes for a delicious twist.",
    ingredients:
      "8 cups (600g) prewashed and torn romaine lettuce|1 lb. (16oz) precooked and sliced boneless, skinless chicken breasts|1 cup (115g) fat-free croutons|1 oz. (28g) low-fat cheese, shredded (if available, fat-free Parmesan cheese)|1/2 cup (175g) fat-free creamy Caesar dressing|1/2 tsp. freshly ground black pepper|1 fresh lemon",
    methods:
      "Place lettuce and croutons into a large bowl and add dressing, tossing well to coat.|Squeeze lemon into lettuce mixture and add cheese; toss again.|Divide mixture on to 4 serving plates.|Place thinly sliced or diced cooked chicken and divide equally atop each serving.",
    time: 10,
    serves: 4,
    fat: 4,
    saturated: 2,
    calories: 214,
    cholesterol: 92,
    carbohydrate: 22,
    protein: 19,
    sodium: 484,
    difficulty: "easy",
    category: "lunch|quick and easy|dinner",
  },
  {
    title: "Chicken Picante",
    slug: "chicken-picante",
    img: "chicken-picante",
    hasImage: true,
    description:
      "This easy marinade is an exciting solution to boring chicken.",
    ingredients:
      "4 boneless, skinless chicken breasts|3/4 cup (263g) salsa|2 tbsp. Dijon mustard (more if you like it spicy!)|juice from 2 fresh limes",
    methods:
      "Preheat oven to 350°F (176°C).|Place chicken in a baking dish prepared with nonstick spray or in a pan lined with nonstick aluminum foil.|In a small bowl, mix salsa, mustard and lime juice and pour over chicken.|Place in the refrigerator for 1 hour or more.|Cover and bake for 35-40 minutes.|Serve with brown rice (there will be plenty of sauce to flavor the rice).",
    time: 110,
    serves: 4,
    fat: 4,
    saturated: 1,
    calories: 172,
    cholesterol: 85,
    carbohydrate: 3,
    protein: 31,
    sodium: 420,
    difficulty: "easy",
    category: "lunch|dinner|family favorites",
  },
  {
    title: "Chocolate Chip Cookie Bars",
    slug: "chocolate-chip-cookie-bars",
    img: "chocolate-chip-cookie-bars",
    hasImage: true,
    description:
      "These bake up like a thick, blonde brownie that the whole family can enjoy for an after-school treat.",
    ingredients:
      "1/4 cup (56g) unsalted butter|1/2 cup (147g) unsweetened applesauce, at room temperature|2 cups (340g) packed brown sugar|2¾ cups (385g) all-purpose flour|1 tbsp. baking powder|1/2 tsp. salt|3 eggs|1 tsp. vanilla extract|1/4 cup (38g) plus 2 tbsp. mini semi-sweet chocolate chips|Non-stick cooking spray and extra flour to prepare the pan",
    methods:
      "Melt the butter, then add the applesauce and set aside to cool.|Prepare a 9x13 pan by spraying it with non-stick cooking spray and flouring it.|In a separate bowl, mix together the flour, baking powder and salt.|Once the butter mixture is cooled, add the eggs, one at a time, mixing after each one.|Add in the vanilla.|Add in the dry ingredients and mix well, incorporating all of the flour mixture.|Stir in the mini chocolate chips.|Bake for 30-40 minutes at 325°F (162°C).|Once cooled, cut into 24 bars.",
    time: 60,
    serves: 24,
    fat: 3.5,
    saturated: 1.9,
    calories: 164,
    cholesterol: 28,
    carbohydrate: 32,
    protein: 2,
    sodium: 124,
    difficulty: "easy",
    category: "dessert|family favorites|light snacks",
  },
  {
    title: "Chopped Vegetable Frittata",
    slug: "chopped-vegetable-frittata",
    img: "chopped-vegetable-frittata",
    hasImage: true,
    description: "Great for a late, lazy breakfast, brunch, or even dinner.",
    ingredients:
      "8 oz. (226g) carton egg substitute/8 egg whites, slightly beaten|2 green onions/spring onions, minced|1 tomato, chopped|1/2 red pepper, chopped|1 green pepper, chopped|1-2 cups (175g-350g) mushrooms, sliced|1 cup (175g) red potato, cooked and sliced|1/2 cucumber, peeled and sliced|4 oz. (113g) can green chiles|1/2 tsp. garlic powder|1/4 tsp. white pepper|salt to taste, if desired",
    methods:
      "Prepare a sauté pan with nonstick cooking spray.|Sauté all vegetables until crisp tender.|Pour egg substitute/egg whites over the vegetables and cook, covered, over low to medium heat until egg is fully cooked and puffy.",
    time: 20,
    serves: 2,
    fat: 4,
    saturated: 0,
    calories: 224,
    cholesterol: 0,
    carbohydrate: 30,
    protein: 18,
    sodium: 408,
    difficulty: "easy",
    category: "breakfast|light snacks|quick and easy",
  },
  {
    title: "Cinnamon Baked Oatmeal",
    slug: "cinnamon-baked-oatmeal",
    img: "cinnamon-baked-oatmeal",
    hasImage: true,
    description:
      "This makes a cozy weekend breakfast. Refrigerate leftovers and microwave for a quick weekday breakfast. May also be frozen as individual servings and thawed for later use.",
    ingredients:
      "3 cups (420g) dry oatmeal (quick or regular)|2-4 tablespoons brown sugar|2 teaspoons ground cinnamon|1/4 teaspoon salt|1½ cups (338ml) skim milk|1 whole egg|1/2 cup (125g) plain nonfat yogurt|1 teaspoon vanilla extract|2 cups (350g) mixed berries, fresh or frozen",
    methods:
      "Preheat oven to 350 degrees (176°C).| Prepare an 8 inch by 8 inch glass baking dish with nonstick cooking spray.| In a medium bowl, combine and mix all ingredients, except fruit, thoroughly.| Pour 2/3 of the batter into the baking dish. Then layer the fruit on top of batter and cover with the final third of the batter.| Bake for 30 minutes.| Cool, cut into 6 pieces (or cut into 9 pieces for a smaller serving) and serve.",
    time: 40,
    serves: 6,
    fat: 3.9,
    saturated: 0.8,
    calories: 224,
    cholesterol: 33,
    carbohydrate: 42,
    protein: 11,
    sodium: 153,
    difficulty: "easy",
    category: "breakfast|family favorites|light snacks|quick and easy",
  },
  {
    title: "Company Turkey Breast",
    slug: "company-turkey-breast",
    img: "company-turkey-breast",
    hasImage: true,
    description:
      "Try this bread topping to keep a turkey breast or tenderloin moist and flavorful.",
    ingredients:
      "1-2 lbs. (16-32oz) boneless turkey breast with skin removed|1½ cups (263g) mushrooms, minced|3 green onions/spring onions, minced|1 slice whole-wheat bread (processed into crumbs)|1/3 cup (75ml) sherry|1 tsp. dried basil",
    methods:
      'Preheat oven to 325°F (162°C).|In a medium sauté pan prepared with nonstick cooking spray, sauté mushrooms, onions, sherry, and basil for 3-4 minutes.|Stir in bread crumbs and remove from heat.|Place turkey breast on a rack in a 9"x13" pan.|Spread or pat stuffing mixture over the turkey.|Cover with foil and bake according to package directions (up to 2 hours; less for individual turkey tenderloin pieces).|Uncover for the last 30 minutes.|Let stand 10 minutes before slicing.',
    time: 150,
    serves: 4,
    fat: 2,
    saturated: 0.5,
    calories: 162,
    cholesterol: 86,
    carbohydrate: 6,
    protein: 30,
    sodium: 118,
    difficulty: "medium",
    category: "dinner|family favorites",
  },
  {
    title: "Confetti Coleslaw",
    slug: "confetti-coleslaw",
    img: "confetti-coleslaw",
    hasImage: true,
    ingredients:
      "1, 16 oz. (453g) bag of shredded green cabbage, carrots and red cabbage|1/2 cup (75g) frozen peas, thawed|1/2 cup (75g) golden or black raisins|2 tbsp. light mayonnaise (3.5 g fat per tbsp.)|2 tbsp. fat-free mayo or non-fat Greek yogurt or fat-free sour cream/fat free greek yogurt|2 tbsp. red wine vinegar|1/2 tbsp. celery seed|1/4 tsp. garlic powder|5 packets artificial sweetener or 3 tbsp. sugar|squeeze of fresh lemon|salt and pepper, to taste",
    methods:
      "Put cabbage mixture in a large bowl.|Add the remaining ingredients and stir to blend thoroughly.|Chill to let flavors blend and serve.",
    note: "To lower fat futher, use fat-free mayo.",
    time: 15,
    serves: 5,
    fat: 1.4,
    saturated: 0,
    calories: 80,
    cholesterol: 1,
    carbohydrate: 14,
    protein: 2,
    sodium: 79,
    difficulty: "easy",
    category: "lunch|family favorites|quick and easy",
  },
  {
    title: "Corn and Black Bean Salad",
    slug: "corn-and-black-bean-salad",
    img: "corn-and-black-bean-salad",
    hasImage: true,
    description:
      "This salad is easy to toss together with ingredients you may already have in your kitchen.",
    ingredients:
      "16 oz. (453g) can lower-sodium black beans, rinsed and drained|1 cup (175g) chopped fresh Roma tomato|1/2 cup (75g) frozen corn, thawed|1/2 cup (75g) chopped green pepper|2 tbsp. minced red onion|1/4 cup (88g) salsa (as spicy as you like it)|juice from 1 lime",
    methods:
      "Mix all ingredients. Chill and serve as a main course or side salad.",
    time: 10,
    serves: 2,
    fat: 0,
    saturated: 0,
    calories: 270,
    cholesterol: 0,
    carbohydrate: 52,
    protein: 16,
    sodium: 382,
    difficulty: "easy",
    category: "lunch|family favorites|quick and easy|vegetarian|fat free",
  },
  {
    title: "Country Gravy",
    slug: "country-gravy",
    img: "country-gravy",
    hasImage: true,
    description:
      "Tastes just like the real thing and totally fat free. Quick and easy to make. Try it over low-fat biscuits, mashed potatoes or baked chicken.",
    ingredients:
      "2 cups (450ml) skim milk|1/4 cup (35g) all purpose flour|1 tsp. seasoned Salt|½ tsp. pepper (or to taste)",
    methods:
      "Pour all ingredients into a blender or container with lid and shake until well blended|Heat a skillet on medium heat.|When warm, add gravy mixture to skillet and slowly stir until thickened to your desired consistency.",
    time: 15,
    serves: 4,
    fat: 0,
    saturated: 0,
    calories: 45,
    cholesterol: 3,
    carbohydrate: 7,
    protein: 4,
    sodium: 63,
    difficulty: "easy",
    category: "family favorites|quick and easy|fat free",
  },
  {
    title: "Cranberry Pumpkin Bread",
    slug: "cranberry-pumpkin-bread",
    img: "cranberry-pumpkin-bread",
    hasImage: true,
    ingredients:
      "3 ½ cups (420g) of flour|1 ¼ cups (250g) sugar|2 ¼ teaspoon baking soda|1 ¼ teaspoon baking powder|1 teaspoon cinnamon|1/4 teaspoon allspice|1/4 teaspoon ground cloves|1/4 teaspoon ginger|1 16 ounce (453g) can whole berry cranberry sauce|1 16 ounce (453g) can pumpkin|1/4 cup (60ml) canola oil|1/2 cup (147g) unsweetened apple sauce|2 large egg whites|1 tablespoon pecans, chopped|Nonstick cooking spray",
    methods:
      "Preheat oven to 350 degrees (176°C).|Combine all ingredients in a large bowl. Mix well.|Spray two 9x5x3 loaf pans with nonstick spray. |Pour into pans. Bake for 1 hour, or until bread tests done with a wooden toothpick.",
    time: 80,
    serves: 16,
    fat: 2.0,
    saturated: 0.2,
    calories: 133,
    cholesterol: 0,
    carbohydrate: 26,
    protein: 2,
    sodium: 115,
    fiber: 1,
    difficulty: "medium",
    category: "family favorites|dessert",
  },
  {
    title: "Cream of Potato Soup",
    slug: "cream-of-potato-soup",
    img: "cream-of-potato-soup",
    hasImage: true,
    ingredients:
      "1 cup (175g) sliced potatoes|1 ½ cups (338ml) fat-free, low sodium chicken broth|1/2 cup (88g) chopped onion|1 teaspoon olive oil|2 Tablespoon white all-purpose flour|1/4 teaspoon salt|Dash of white pepper (optional)|1 cup (225ml) fat-free milk",
    methods:
      "In a saucepan, combine the potatoes, chicken broth, onion and dill. Bring the mixture to a boil. Reduce the heat, cover and simmer for 10 minutes. |Place half of the vegetable mixture in a blender or food processor. Blend 30 to 60 seconds until smooth.| Pour into a bowl. For a completely smooth texture, blend the remaining vegetable mixture. For a more chunky texture, leave the second half unblended. Set all aside.|In the same saucepan over medium heat, add the olive oil and warm it.| Stir in the flour, salt and pepper (if desired) and whisk it all together. |Cook for 1 minute. Add the milk. Cook and stir with the whisk until the mixture is thickened and bubbly.| Stir in the vegetable and broth mixture. Cook and stir until the soup is heated through.| Season to taste with additional salt and pepper.",
    time: 30,
    serves: 3,
    fat: 2.4,
    saturated: 1,
    calories: 135,
    cholesterol: 2,
    carbohydrate: 20,
    protein: 7,
    sodium: 268,
    fiber: 2,
    difficulty: "medium",
    category: "family favorites|lunch|dinner|vegetarian",
  },
  {
    title: "Creamy Butternut Squash Soup",
    slug: "creamy-butternut-squash-soup",
    img: "creamy-butternut-squash-soup",
    hasImage: true,
    description:
      "This soup can be prepared as a creamy sweet soup or add some curry powder for a spicy taste. Serve with a big salad and some crusty bread.",
    ingredients:
      "1 onion, chopped|2 carrots, sliced or diced|2 celery stalks, sliced|12 oz. (340g) package “ready to use” cut butternut squash (3 cups cubed)|1 potato, diced|2 garlic cloves, minced|5 cups (1125ml) nonfat, reduced-sodium chicken broth|1-2 tbsp. sugar or honey (or artificial sweetener)|Pepper to taste|2-3 tsp. curry powder, if desired",
    methods:
      "In a large sauté or sauce pan, prepared with nonstick cooking spray, sauté onions, carrots, celery, and garlic until crisp tender.|Stir in remaining ingredients, except curry powder, and bring to a boil. Then reduce heat and simmer for 30 minutes until vegetables are very tender.|Move mixture to a food processor and process until smooth.|If everyone likes curry, add 2-3 tsp. of curry powder. Otherwise, add 1/2 tsp. or more to each bowl as it is served.",
    time: 40,
    serves: 4,
    fat: 0,
    saturated: 0,
    calories: 104,
    cholesterol: 0,
    carbohydrate: 23,
    protein: 3,
    sodium: 600,
    difficulty: "medium",
    category: "family favorites|lunch|dinner|vegetarian|fat free",
  },
  {
    title: "Crock Pot Beef Roast & Veggies",
    slug: "crock-pot-beef-roast-and-veggies",
    img: "crock-pot-beef-roast-and-veggies",
    hasImage: true,
    description:
      "Quick, easy, and delicious—this is perfect any time of the year.",
    ingredients:
      "1 lb. (16oz) beef round, lean only|1 cup (150g) green beans|2½ cups (125g) carrots, chopped|1/2 cup (113ml) beef broth|1 cup (200g) white rice|3 tbsp. balsamic vinegar|2 tbsp. mesquite seasonings, dry",
    methods:
      "Combine all ingredients except rice in crock pot.|Cook on low for 8-10 hours until beef shreds easily with a fork.|Cook rice when crock pot is almost finished, per recipe directions.|Serve crock pot ingredients over bed of rice.",
    serves: 4,
    fat: 8,
    saturated: 5,
    calories: 435,
    cholesterol: 103,
    carbohydrate: 52,
    protein: 39,
    sodium: 660,
    difficulty: "easy",
    category: "family favorites|dinner|hearty meal",
  },
  {
    title: "Crock Pot Caribbean Chicken",
    slug: "crock-pot-caribbean-chicken",
    img: "crock-pot-caribbean-chicken",
    hasImage: true,
    description: "An easy tropical dish with a sweet, delicate taste.",
    ingredients:
      "1 lb. (16oz) boneless, skinless chicken breasts|1/4 cup (43g) packed brown sugar|1/2 tsp. nutmeg|1/3 cup (75ml) orange juice|1/2 cup (88g) raisins|8 oz. (226g) canned pineapple chunks with juice|1/2 tsp. cinnamon",
    methods:
      "Put all ingredients into crock pot.|Cook on low for 4-6 hours.|Serve over steamed rice, if desired.",
    serves: 4,
    fat: 4,
    saturated: 0,
    calories: 399,
    cholesterol: 80,
    carbohydrate: 68,
    protein: 26,
    sodium: 65,
    difficulty: "easy",
    category: "family favorites|dinner|hearty meal",
  },
  {
    title: "Crock Pot Mexican Chicken Soup",
    slug: "crock-pot-mexican-chicken-soup",
    img: "crock-pot-mexican-chicken-soup",
    hasImage: true,
    description: "A flavorful dish full of spice.",
    ingredients:
      "2 tbsp. lime juice|3½ cups (175g) carrots, chopped|1 tsp. chili powder|1 tbsp. minced garlic|4 cups (900ml) fat-free, reduced-sodium chicken broth|1 cup (225ml) tomato juice|1 tsp. fennel seed|1 tsp. cumin, ground|1 lb. (16oz) chicken breasts, skinless and boneless|1 large-sized yellow onion, chopped",
    methods:
      "Combine all ingredients in a crock pot and cook for 6-8 hours on low or until chicken is cooked through.|Shred chicken and serve.",
    serves: 4,
    fat: 4,
    saturated: 0,
    calories: 229,
    cholesterol: 66,
    carbohydrate: 17,
    protein: 29,
    sodium: 369,
    difficulty: "easy",
    category: "family favorites|dinner|hearty meal",
  },
  {
    title: "Crock Pot Honey Garlic Chicken ‘n Veggies",
    slug: "crock-pot-honey-garlic-chicken",
    img: "crock-pot-honey-garlic-chicken",
    hasImage: true,
    description: "A new twist to traditional slow-cooker chicken.",
    ingredients:
      "2 pounds (32oz) chicken breasts|16 oz (453g) baby red potatoes|16 oz (453g) baby carrots|2 tablespoons chopped parsley|1/2 cup (175g) reduced sodium soy sauce|1 cup (350g) honey|1/4 cup (88g) ketchup|2 cloves garlic, minced|1 teaspoon dried basil|1/2 teaspoon dried oregano|1/4 teaspoon crushed red pepper flakes|1/4 teaspoon ground black pepper|1/2 cup (113ml) water",
    methods:
      "In a large bowl, combine the soy sauce, honey, ketchup, garlic, basil, oregano, red pepper flakes, and pepper.|Place chicken, potatoes, and carrots into a slow-cooker.|Pour sauce mixture over the chicken, potatoes, and carrots.|Add the water to the crockpot.|Cover and cook on low heat for 7.5 hours or high for 3.5 hours.",
    serves: 4,
    fat: 6.6,
    saturated: 1.4,
    calories: 721,
    cholesterol: 166,
    carbohydrate: 110,
    protein: 59,
    sodium: 1194,
    fiber: 6,
    difficulty: "easy",
    category: "family favorites|dinner|hearty meal",
  },
  {
    title: "Banana Pudding",
    slug: "banana-pudding",
    img: "banana-pudding",
    hasImage: true,
    description:
      "Easy dessert to whip up for your family or great to take to a get together",
    ingredients:
      "2 large ripe bananas, sliced|2 packages of instant sugar free, fat free vanilla pudding (1 ounce (28g) box)|3 cups (710ml) of fat-free milk|4 oz (113g) of fat-free whipped topping|1/2 cup (113g) of fat-free sour cream/fat free greek yogurt|1/4 cup (50g) of granulated sugar (or sweetener)|30 reduced-fat vanilla wafer",
    methods:
      "Empty both packages of powdered pudding into a mixing bowl and mix with 3 cups (710ml) of cold milk. Let cool and set in the refrigerator until thickened.|In a separate bowl, mix whipped topping, fat-free sour cream/fat free greek yogurt and sweetener.|Take a casserole dish or clear bowl and layer ingredients: start with 10 wafers, sliced bananas, pudding and repeat. Then top with whipped cream mixture topping.",
    serves: 10,
    fat: 1.5,
    saturated: 0,
    calories: 128,
    cholesterol: 3,
    carbohydrate: 26,
    protein: 4,
    sodium: 168,
    fiber: 1,
    difficulty: "easy",
    category: "light snacks|dessert|family favorites|quick and easy",
  },
  {
    title: "Easy Candied Sweet Potatoes",
    slug: "easy-candied-sweet-potatoes",
    img: "easy-candied-sweet-potatoes",
    hasImage: true,
    description:
      "Here is an easy seasonal dish that can be enjoyed any time of year!",
    ingredients:
      "1½ lbs. (24oz) sweet potatoes or yams, peeled and thinly sliced|2-3 tbsp. orange or apricot or apple preserves|1/2-3/4 cup (113ml-169ml) orange juice|Mini marshmallows (optional/not included in analysis)",
    methods:
      "Preheat oven to 350°F (176°C).|In a small bowl, combine preserves and orange juice.|Place sliced sweet potatoes in a casserole dish.|Pour juice mixture over potatoes and cover with foil or top.|Bake for 45 minutes, stirring half way through.|Top with mini marshmallows during final 10 minutes of baking, if desired.|You may also microwave in a microwave safe dish for 20-25 minutes, stirring occasionally, until potatoes are tender.",
    time: 55,
    serves: 4,
    fat: 0.2,
    saturated: 0,
    calories: 184,
    cholesterol: 0,
    carbohydrate: 44,
    protein: 3,
    sodium: 98,
    fiber: 5,
    difficulty: "easy",
    category: "family favorites|dinner",
  },
  {
    title: "Easy Crockpot Refried Beans",
    slug: "easy-crock-pot-refried-beans",
    img: "easy-crock-pot-refried-beans",
    hasImage: true,
    description: "This recipe is so easy you can do it while you sleep!",
    ingredients:
      "1 onion, peeled and halved|2 cups (300g) dry pinto beans, rinsed|2 cloves garlic, minced|3/4 teaspoons salt|1/2 teaspoon black pepper|1/8 teaspoon cumin|6 cups (1350ml) water",
    methods:
      "Combine all ingredients in the crockpot.|Cook on high for 8 hours or leave them overnight while you are sleeping.|Remove the bigger onion chunks and drain the extra liquid. Save some of the excess liquid to add back to the final product to reach the ideal consistency of beans (you don’t want them too dry).|Mash remaining beans with a potato masher and you’re done!",
    serves: 10,
    fat: 0.5,
    saturated: 0,
    calories: 134,
    cholesterol: 0,
    carbohydrate: 25,
    protein: 8,
    sodium: 184,
    difficulty: "easy",
    category: "family favorites|dinner|hearty meal",
  },
  {
    title: "Easy Eggless Cocoa Cake",
    slug: "easy-eggless-cocoa-cake",
    img: "easy-eggless-cocoa-cake",
    hasImage: true,
    ingredients:
      "1½ cups (210g) flour |1 cup (200g) sugar| 1/4 cup (31g) cocoa| 1 teaspoon baking powder| 1/4 teaspoon salt|1 cup (225ml) water| 2 Tablespoons oil |2 Tablespoons non-fat, plain yogurt |1 Tablespoon vinegar| 1 teaspoon vanilla extract",
    methods:
      "Preheat oven to 350°F (176°C).|Combine dry ingredients. Add liquid ingredients and stir until smooth.| Pour into 8x8-inch baking pan prepared with non stick cooking spray for baking. |Bake for 30-35 minutes or until knife comes out clean. Cool.| Dust with powdered sugar and sprinkle with chocolate chips.",
    time: 45,
    serves: 9,
    fat: 3.6,
    saturated: 0.6,
    calories: 199,
    cholesterol: 0,
    carbohydrate: 40,
    protein: 3,
    sodium: 122,
    difficulty: "easy",
    category: "family favorites|dessert",
  },
  {
    title: "Easy Fat-Free Cheesecake",
    slug: "easy-fat-free-cheesecake",
    img: "easy-fat-free-cheesecake",
    hasImage: true,
    description:
      "Creamy and tastes like the original version. You can reduce sugar and carbohydrate by substituting non-calorie sweetener.",
    ingredients:
      "16 oz. (453g) of fat-free cream cheese, room temperature|1/2 cup (113g) of granulated white sugar (or 1/2 cup (113g) sweetner)|3/4 cup (190g) fat-free plain Greek yogurt, room temperature|1 tsp. of vanilla|4 egg whites or ½ cup liquid egg whites|2 tbsp. of lemon juice|2 tbsp. of all purpose flour|Optional topping - Lite cherry pie filling or fresh berries",
    methods:
      "Preheat oven to 350°F (176°C).|Spray a 9 inch spring form pan with nonstick cooking spray.|In a large bowl, combine cream cheese, sugar (or non-calorie sweetener) and vanilla and beat with an electric mixer until smooth.|Add yogurt, egg whites, lemon juice and flour and continue to mix.|Pour evenly into prepared spring form pan.|Bake until firm and springs back at touch, 30-40 minutes.|Let cool completely.|If choose, add additional topping.",
    time: 50,
    serves: 8,
    fat: 1,
    saturated: 19,
    calories: 142,
    cholesterol: 6,
    carbohydrate: 19,
    protein: 14,
    sodium: 418,
    fiber: 1,
    difficulty: "easy",
    category: "family favorites|dessert",
  },
  {
    title: "Easy Ice Cream",
    slug: "easy-ice-cream",
    img: "easy-ice-cream",
    hasImage: true,
    description:
      "A fast, low fat treat. You can use other frozen fruits for different flavors.",
    ingredients:
      "1 package of frozen strawberries (10 oz)(283g)| 1/2 cup (113g) sugar| 2/3 cup (150ml) fat free half and half",
    methods:
      "Put fruit and sugar in a food processor or blender and process until the fruit is roughly chopped.| While processor is running, slowly pour the half and half in and continue to process until it is fully incorporated.| If you like the consistency of soft-serve ice cream, serve immediately. If you like ice cream to be firm, freeze it until it is firm.",
    serves: 4,
    fat: 0,
    saturated: 0,
    calories: 147,
    cholesterol: 0,
    carbohydrate: 36,
    protein: 2,
    sodium: 40,
    fiber: 2,
    difficulty: "easy",
    category: "family favorites|dessert|light snacks|fat free",
  },
  {
    title: "Easy Pumpkin Spice Cake",
    slug: "easy-pumpkin-spice-cake",
    img: "easy-pumpkin-spice-cake",
    hasImage: true,
    ingredients:
      "1 box (15.25 oz.) (432g) Spice Super Moist Cake Mix|1 can (15 oz.) (425g) pumpkin|You can use any flavor Super Moist Cake Mix with canned pumpkin to make a quick and easy low fat cake.",
    methods:
      "Preheat oven to 350°F (176°C).|Mix both ingredients together in a mixing bowl until well blended (batter will be thick).|Spread into a 13”X 9” inch glass baking dish prepared with nonstick cooking spray.|Bake for 20-25 minutes or until knife comes out clean.|Cool and serve with fat-free whipped topping, nonfat yogurt or ice cream or drizzle with nonfat milk and confectioners’ sugar.",
    time: 40,
    serves: 15,
    fat: 1,
    saturated: 0,
    calories: 118,
    cholesterol: 0,
    carbohydrate: 26,
    protein: 2,
    sodium: 214,
    difficulty: "easy",
    category: "family favorites|dessert|light snacks",
  },
  {
    title: "Easy Southwest Chicken Soup",
    slug: "easy-southwest-chicken-soup",
    img: "easy-southwest-chicken-soup",
    hasImage: true,
    description: 'Emphasis on the "easy"...and delicious!',
    ingredients:
      "1 7-ounce (198g) can of salsa verde| 3 cups (375g) of cooked chicken breast shredded or cut into pieces|1 15-ounce (435g) of cannellini or northern beans, low sodium and drained|4 cups (900ml) of low fat, low sodium chicken broth|2 cups (450ml) of water added to broth|1 ½ teaspoons of ground cumin|Optional Toppings:|2 green onions/spring onions chopped|Chopped coriander/cilantro|corn tortilla chips",
    methods:
      "Empty salsa verde and chicken broth into a large pot.|Add the rest of the ingredients and cook on medium heat for 30 minutes.|Optional toppings: Cut tortillas into triangles, spray with non-stick cooking spray, sprinkle with low sodium Mexican seasoning, microwave for 90 seconds, let cool to be crisp)",
    time: 40,
    serves: 4,
    fat: 5.1,
    saturated: 1.1,
    calories: 348,
    cholesterol: 85,
    carbohydrate: 33,
    protein: 41,
    sodium: 1464,
    difficulty: "easy",
    category: "lunch|dinner|quick and easy",
  },
  {
    title: "Easy-As-Pie Pumpkin Pie",
    slug: "easy-as-pie-pumpkin-pie",
    img: "easy-as-pie-pumpkin-pie",
    hasImage: true,
    description:
      "Enjoy this holiday favorite all year long. If you want, go crustless and serve in custard cups prepared with nonstick cooking spray.",
    ingredients:
      "1 can (30 oz.) (850g) Pumpkin Pie Mix |2/3 cup (150ml) evaporated fat-free milk|1/2 cup (90g) egg substitute|3-4 tbsp honey|5 low-fat honey graham cracker sheet (put in a plastic bag and hit with the back of a spoon/makes about 1 cup (50g) crumbs)",
    methods:
      'Preheat over to 350°F (176°C).|Make crust by mixing together the honey with the graham cracker crumbs.|Press into the bottom of a 9 1/2" pie pan prepared with nonstick cooking spray. |Bake for 7-9 minutes (honey browns quickly…do not over bake). Cool before filling. |Mix pumpkin, evaporated milk and egg substitute together until well blended.|Pour mixture into cooled pie crust.|Bake pie for 60-70 minutes or until knife comes out clean.|If you have any extra pie filling (mixture perfectly fills a 9 1/2" pie pan…if you have a smaller one, you may have extra filling), spoon into a custard cup prepared with nonstick cooking spray.|Enjoy a little taste before the company comes!',
    time: 100,
    serves: 8,
    fat: 1,
    saturated: 0,
    calories: 208,
    cholesterol: 0,
    carbohydrate: 43,
    protein: 5,
    sodium: 264,
    difficulty: "medium",
    category: "family favorites|dessert",
  },
  {
    title: "Eggplant/Aubergine Caviar",
    slug: "eggplant-caviar",
    img: "eggplant-caviar",
    hasImage: true,
    description:
      "This eggplant/aubergine dish, traditional in Middle Eastern countries, makes a great filling for whole-wheat pita, or use it to top toast or crackers.",
    ingredients:
      "1-2 eggplants/aubergine (about 3 cups (675g) mashed pulp)|1/4 cup (44g) onion, finely minced|1/4 cup (44g) green onion/spring onion, finely minced|1/4 cup (44g) green pepper, finely minced|2-3 cloves of garlic, finely minced (or 1/2 tsp. garlic powder)|1 cup (175g) finely diced, peeled tomatoes (may used canned, diced)|1 tsp. sugar|juice from 1 lemon|black pepper, to taste",
    methods:
      "Microwave “pierced” eggplant(s)/aubergine on high for 10 minutes. Let cool slightly.|In a large bowl, combine remaining ingredients.|Slice eggplant/aubergine in half and scoop out insides into bowl. Stir well to combine.|Refrigerate 2 or more hours before serving.",
    serves: 5,
    fat: 0.4,
    saturated: 0,
    calories: 76,
    cholesterol: 0,
    carbohydrate: 16,
    protein: 3,
    sodium: 13,
    difficulty: "medium",
    category: "lunch|snack|vegetarian",
  },
  {
    title: "Bean and Macaroni Soup",
    slug: "bean-and-macaroni-soup",
    img: "bean-and-macaroni-soup",
    hasImage: true,
    description:
      "This hearty, thick soup is a filling lunch meal. Double the portion for a bigger appetite.",
    ingredients:
      "14 oz. (397g) can white kidney beans or navy beans, rinsed and drained|14 oz. (397g) can diced tomatoes|nonstick cooking spray|1 onion, chopped|4 carrots, thinly sliced|1/2 tsp. garlic powder|1/2 tsp. Italian seasoning|2/3 cup (77g) uncooked macaroni|3 or more cups (710ml) water",
    methods:
      "Spray a nonstick 4-quart pan with nonstick cooking spray.|Add onion and carrots and sauté for 5 minutes.|Add tomatoes, spices, and water; bring to a boil.|Add macaroni and beans and boil for 10 minutes until macaroni is al dente. Stir frequently.|Ladle into a big bowl and enjoy!",
    time: 20,
    serves: 4,
    fat: 1,
    saturated: 0,
    calories: 197,
    cholesterol: 0,
    carbohydrate: 39,
    protein: 7.5,
    sodium: 358,
    difficulty: "easy",
    category: "quick and easy|vegetarian|lunch|hearty meal",
  },
  {
    title: "Elegant Artichoke Pockets",
    slug: "elegant-artichoke-pockets",
    img: "elegant-artichoke-pockets",
    hasImage: true,
    description:
      "These appetizers only look like hard work! Great for company.",
    ingredients:
      "32 wonton wrappers|2 13 oz. (368g) cans artichoke hearts (rinse and drain)|1/2 red pepper, minced|1/4 cup (25g) Parmesan cheese|1/3 cup (76g) fat-free mayonnaise|2 oz. (56g) can sliced ripe olives (finely chopped)|3/4 tsp. garlic powder",
    methods:
      "Preheat oven to 350°F (176°C).|Blend artichoke hearts, mayonnaise, Parmesan cheese and garlic in food processor until smooth.|Remove blade and blend in red pepper and olives.|Divide evenly (about 1 tbsp. each) between wrappers.|Pull wrappers up at corners and press to close and create a pocket.|Bake for 20-25 minutes until edges are brown.|Serve warm. Leftovers may be reheated or microwaved.",
    time: 35,
    serves: 8,
    fat: 1.9,
    saturated: 0.5,
    calories: 141,
    cholesterol: 5,
    carbohydrate: 26,
    protein: 5,
    sodium: 426,
    difficulty: "medium",
    category: "appetizers|vegetarian",
  },
  {
    title: "Fabulous Blintz Souffle",
    slug: "fabulous-blintz-souffle",
    img: "fabulous-blintz-souffle",
    hasImage: true,
    description:
      "So simple to prepare and elegant to serve. No one will know that your actual (in the kitchen working) prep time was less than 10 minutes.",
    ingredients:
      "12 frozen cheese blintzes (2 packages)|16 oz. (453g) carton egg substitute|16 oz. (453g) fat-free sour cream|1/4 cup (56ml) orange juice|1/4 cup (50g) sugar (or 6 packets sweetner)|1 tsp. vanilla extract|a sprinkle of cinnamon",
    methods:
      'Preheat oven to 350°F (176°C).|Prepare a 9"x12" glass baking dish with nonstick cooking spray.|Arrange frozen blintzes in the bottom of the baking dish.|In a medium bowl, stir egg substitute, sour cream/greek yogurt, orange juice, vanilla extract, and sugar until well blended.|Pour over blintzes to cover.|Sprinkle with cinnamon.|Bake until set (about 50-60 minutes).|Serve with berries, applesauce, and/or jam. Tastes great reheated in the microwave the next day!',
    time: 75,
    serves: 6,
    fat: 3,
    saturated: 2,
    calories: 303,
    cholesterol: 42,
    carbohydrate: 51,
    protein: 18,
    sodium: 460,
    difficulty: "easy",
    category: "dessert",
  },
  {
    title: "Fat-Free Corn Bread",
    slug: "fat-free-corn-bread",
    img: "fat-free-corn-bread",
    hasImage: true,
    description:
      "A southern favorite. Easy to make and you won’t miss the fat.",
    ingredients:
      "1 cup yellow cornmeal|1/2 cup (70g) all purpose flour|1/2 tsp. salt|1 tbsp. baking powder|4 whites or 1/2 cup egg substitute|1 cup (250g) fat-free plain yogurt|1/2 cup (113ml) skim milk|1/4 cup (50g) sugar (to reduce carbohydrate content you may substitute a non-calorie sweetener or omit sugar if a less sweet taste is preferred)",
    methods:
      'Preheat oven to 350°F (176°C).|Spray an 8 or 9" iron skillet or pie pan with nonstick cooking spray.|Mix all ingredients in a bowl and stir.|Pour into prepared dish.|Bake for 30-35 minutes.',
    time: 45,
    serves: 8,
    fat: 0.5,
    saturated: 0,
    calories: 97,
    cholesterol: 3,
    carbohydrate: 19,
    protein: 4,
    sodium: 92,
    fiber: 3,
    difficulty: "easy",
    category: "dessert|snack|quick and easy|fat free",
  },
  {
    title: "Fat-Free Date Bread",
    slug: "fat-free-date-bread",
    img: "fat-free-date-bread",
    hasImage: true,
    description:
      "No one will guess this delicious treat is fat-free. The dates keep it moist.",
    ingredients:
      "2 cups (50g) pitted dates|1 cup (225ml) hot water|1 tsp. baking soda|2 egg whites|1/2 cup (100g) sugar|1 tsp. vanilla|1½ cups (210g) flour",
    methods:
      'Preheat oven to 350°F (176°C).|Place dates in small bowl. Add baking soda to water and pour over dates. |In a food processor, blend egg whites, sugar, and vanilla.|Add about 1/4 of the date/water mixture and process until fairly smooth.|Add flour and process until blended.|Add the remaining date/water mixture and process briefly for 15-30 seconds. Stir to be sure mixture is thoroughly blended.|Pour into a 5"x9" loaf pan coated with nonstick cooking spray. |Bake for 60-75 minutes until knife comes out clean.|Cool and slice. Can be served with nonfat cream cheese or ricotta.',
    time: 90,
    serves: 12,
    fat: 0,
    saturated: 0,
    calories: 148,
    cholesterol: 0,
    carbohydrate: 34,
    protein: 3,
    sodium: 114,
    difficulty: "medium",
    category: "dessert|snack|fat free",
  },
  {
    title: "Chewy Oatmeal Raisin Cookies",
    slug: "chewy-oatmeal-raisin-cookies",
    img: "chewy-oatmeal-raisin-cookies",
    hasImage: true,
    description: "Great for any holiday gathering!",
    ingredients:
      "1 cup (140g) old fashioned oats|1 cup (140g) flour|1/2 tsp. baking soda|3/4 tsp. ground cinnamon|1/2 tsp. salt|1/2 tsp. vanilla|1/2 cup (85g) brown sugar|1/4 cup (50g) white sugar|1/4 cup (44g) raisins|1/2 cup (175g) apple sauce|1 egg white|2 tbsp. skim milk",
    methods:
      "Preheat oven to 375°F (190°C).|Mix all dry ingredients with raisins.|Add apple sauce, egg white and skim milk to the dry ingredients. Mix to blend.|Drop onto a non-stick cookie sheet.|Bake for 12-15 minutes or until golden.|Let set for a few minutes before lifting from pan to cooling rack.",
    time: 20,
    serves: 12,
    fat: 0.6,
    saturated: 0.1,
    calories: 121,
    cholesterol: 0,
    carbohydrate: 27,
    protein: 2,
    sodium: 158,
    fiber: 1,
    difficulty: "easy",
    category: "dessert|snack|family favorites|quick and easy",
  },
  {
    title: "Barbequed Vegetables",
    slug: "barbequed-vegetables",
    img: "barbequed-vegetables",
    hasImage: true,
    description:
      "Make vegetables taste special. Serve with brown basmati rice.",
    ingredients:
      "1 onion, sliced|1 zucchini/courgette, sliced|1 summer squash, sliced|1 green pepper, sliced|1 red pepper, sliced|2 new potatoes, cut into bite-sized pieces|8 or more large mushrooms|1/2 cup (70g) barbeque sauce",
    methods:
      'Preheat oven to 425°F (218°C).|Precook potatoes slightly by microwaving them for 3 minutes on high.|Spray a 9"x13" baking pan with nonstick cooking spray, or line with nonstick aluminum foil.|Put all veggies into the pan and brush with barbeque sauce.|Bake for 15 minutes and finish off under the broiler for 5 minutes to slightly blacken.',
    serves: 4,
    fat: 1,
    saturated: 0,
    calories: 115,
    cholesterol: 0,
    carbohydrate: 24,
    protein: 4,
    sodium: 210,
    fiber: 4,
    difficulty: "easy",
    category: "quick and easy|vegetarian|dinner|lunch",
  },
  {
    title: "Fat-Free Peach Cobbler",
    slug: "fat-free-peach-cobbler",
    img: "fat-free-peach-cobbler",
    hasImage: true,
    description: "This is a simple dessert to make for any occasion.",
    ingredients:
      "1/2 cup (113g) white sugar (or non-calorie sweetener for lower carbohydrate)|1/2 cup (70g) flour|1/2 cup (113ml) skim milk|1 tsp. baking powder|1/4 tsp. salt|3 cups (525g) of frozen peaches, no added sugar",
    methods:
      'Preheat oven to 350°F (176°C).|Spray an 8"x8" square pan with nonstick cooking spray.|Combine the flour, milk, baking powder and salt in one bowl.|In another bowl add the peaches and mix with 1/2 cup (113g) of sugar (or sweetner or stevia for lower carbohydrate version). |Pour the dough mixture onto the pan first and spread it out. |Next add the fruit mixed with sugar (or sweetener) righ on top of dough. |The crust will bake through to the top.|Bake in the oven for 30-35 minutes until browned to your liking.|Optional topping: Fat-free vanilla ice cream (if watching carbohydrates choose the no added sugar fat-free version).',
    time: 45,
    serves: 6,
    fat: 0,
    saturated: 3,
    calories: 118,
    cholesterol: 0,
    carbohydrate: 30,
    protein: 3,
    sodium: 103,
    fiber: 2,
    difficulty: "medium",
    category: "dessert|fat free",
  },
  {
    title: "Fish Made Simple",
    slug: "fish-made-simple",
    img: "fish-made-simple",
    hasImage: true,
    description:
      "Enjoy the subtle flavor of fish with this simple preparation.",
    ingredients:
      "12-16 oz. (340-453g) lean fish like sole, haddock, cod, tilapia, mahi mahi or halibut|2 egg whites or 1/2 cup egg substitute|1/2 cup (63g) Italian-style bread crumbs|1 fresh lemon",
    methods:
      "Preheat oven to 425°F (218°C).|Wash and dry fish filets.|Put egg into bowl and dip fish.|Put crumbs into another bowl and use to coat fish.|Place coated filets on nonstick aluminum foil on a baking sheet (for easier clean up).|Top with thinly sliced lemon.|Bake for 20 minutes (depends on thickness) or until fish flakes.|Serve with fresh lemon.",
    note:
      "If you prefer a buttery taste without a crumb coating, spray filets with butter spray, season as desired, top with thin lemon slices and bake as above. Alternate toppings can include fruit salsa (mango), tomato salsa, fat-free ginger marinade or teriyaki sauce.",
    time: 30,
    serves: 2,
    fat: 3,
    saturated: 0,
    calories: 223,
    cholesterol: 83,
    carbohydrate: 10,
    protein: 37,
    sodium: 395,
    difficulty: "easy",
    category: "dinner|fish|quick and easy",
  },
  {
    title: "Fish Stew",
    slug: "fish-stew",
    img: "fish-stew",
    hasImage: true,
    description:
      "A favorite to many, this easy-to-make seafood dish is great for a busy weeknight.",
    ingredients:
      "1 lb. (16oz) tilapia filet|1/4 cup (56ml) vegetable broth|1 medium onion, chopped|4 garlic cloves, minced|1, 14 oz. (396g) can diced tomatoes|1, chili pepper, chopped|1/4 cup (15g) fresh coriander/cilantro, chopped|1, tbsp. capers, rinsed|1 tbsp. dried oregano|1/2 tsp. salt|3/4 cup (169ml) water, as needed|1/8 avocado for garnish",
    methods:
      "Heat vegetable broth in a skillet over medium heat.|Add onion and cook, stirring occasionally, until softened.|Add garlic and cook about 1 minute.|Add fish, tomatoes and their juices along with chili pepper, coriander/cilantro, capers, oregano, and salt. Stir to combine.|Add 1/2 – 3/4 cup (113-169ml) water if it seems dry. |Cover and simmer for 20 minutes. |Remove from heat, serve warm garnished with avocado if desired.",
    time: 40,
    serves: 4,
    fat: 3,
    saturated: 1,
    calories: 160,
    cholesterol: 50,
    carbohydrate: 10,
    protein: 23,
    sodium: 125,
    difficulty: "medium",
    category: "dinner|fish ",
  },
  {
    title: "Gingery Chinese Stir-Fry",
    slug: "gingery-chinese-stir-fry",
    img: "gingery-chinese-stir-fry",
    hasImage: true,
    description:
      "Works with any vegetables or protein (chicken, shrimp, lean beef or pork)",
    ingredients:
      "2-3 tbsp. fresh ginger, minced (can also use prepared minced ginger)|3-4 garlic cloves, minced|1 lb. (16oz) boneless skinless chicken breast, cut into strips|1 cup (150g) Chinese pea pods|2 cups (300g) broccoli florets|1 cup (175g) mushrooms, sliced|1-2 baby bok choy, sliced|1-2 cups (150-300g) fresh bean sprouts|2 green onions/spring onions, chopped|1/2 cup (88g) sliced carrot slivers|1/2 cup (88g) sliced red pepper|small can water chestnuts, rinsed|1½ cups (338ml) chicken broth (reduced sodium if desired)|2 tbsp. cornstarch (use a little more if needed to thicken)|2-3 tbsp. lite soy sauce|fresh pepper, to taste",
    methods:
      "In a large sauté pan or wok prepared with nonstick cooking spray, sauté chicken until almost done. |Add mushrooms and carrots and continue sautéing. Then add remaining vegetables and 1 cup (225ml) chicken broth. |Cover for 5 minutes, heating. |Mix 2 tbsp. cornstarch with remaining broth, then stir into mixture along with soy sauce. Cook briefly to thicken. |Add fresh ground pepper and serve over 1 cup (200g) brown or white rice, per person.",
    serves: 4,
    fat: 3,
    saturated: 1,
    calories: 435,
    cholesterol: 65,
    carbohydrate: 66,
    protein: 36,
    sodium: 579 - 972,
    difficulty: "medium",
    category: "dinner|family favorites ",
  },
  {
    title: "Grilled Pork Tenderloin",
    slug: "grilled-pork-tenderloin",
    img: "grilled-pork-tenderloin",
    hasImage: true,
    description:
      "Great for kids and adults. Simple and good to have as leftovers.The recipe is for 2 one pound pork tenderloins; cut ingredients by half if you are using only one pound.",
    ingredients:
      "1/4 cup (63g) soy sauce (to lower sodium, use less sodium version)|1/4 cup (43g) brown sugar|3 cloves garlic|1/4 cup (88g) honey dijon mustard|1/4 tsp. powdered ginger|1 tsp. Worcestershire sauce|2 one pound pork tenderloins",
    methods:
      "Combine first 6 ingredients and whisk.|Place pork tenderloins in a plastic bag with the marinade and refrigerate overnight.|Grill over medium heat 15-25 minutes until pork has reached an internal temp of 165˚F (73˚C) with a meat thermometer. |Remove from grill and let sit for 15 minutes. |Cut each pound into ¼ inch slices (with 4 servings per pound or approximately 3 oz. cooked per serving or 8 total servings).|Fabulous warm or served cold for sandwiches!",
    time: 50,
    serves: 8,
    fat: 2.7,
    saturated: 0.8,
    calories: 150,
    cholesterol: 54,
    carbohydrate: 6,
    protein: 25,
    sodium: 693,
    difficulty: "medium",
    category: "dinner|family favorites|pork",
  },
  {
    title: "“Half” Homemade Brownies",
    slug: "half-homemade-brownies",
    img: "half-homemade-brownies",
    hasImage: true,
    description: "All the glory…none of the work.",
    ingredients:
      "1 box of brownie mix |1/2 egg substitute|1/2 cup (125g) plain nonfat yogurt or Greek yogurt|1/4 cup (56ml) water (or per package recipe)",
    methods:
      'Preheat oven to 350°F (176°C).|Mix all ingredients and place in a 13"x9" pan prepared with nonstick cooking spray.|Bake for 25 minutes or until knife comes out clean.|Cool and cut into 18 pieces.',
    time: 35,
    serves: 18,
    fat: 3.5,
    saturated: 1,
    calories: 141,
    cholesterol: 0,
    carbohydrate: 25,
    protein: 3,
    sodium: 97,
    difficulty: "easy",
    category: "dessert|family favorites|quick and easy",
  },
  {
    title: "Harvest Apple Cake",
    slug: "harvest-apple-cake",
    img: "harvest-apple-cake",
    hasImage: true,
    description: "This cake contains a surprise ingredient! Don’t tell anyone.",
    ingredients:
      "1/2 cup (100g) sugar|1/2 cup (70g) flour|1 cup (150g) pinto beans, rinsed and drained|1 egg white|2 tbsp. oil (use 2 tbsp. applesauce to lower the fat content to less than 1 g per serving)|1 tsp. vanilla|1/2 tsp. baking soda|1/4 tsp. cloves|1 tsp. cinnamon|1 cup (175g) minced raw apple (include skin)|1/3 cup (58g) raisins",
    methods:
      'Preheat oven to 375°F (190°C).|Blend apples in food processor until minced. Remove.|Blend all ingredients except apples and raisins in the food processor until smooth.|Stir in apples and raisins.|Pour into an 8"x8" glass baking dish prepared with nonstick cooking spray.|Bake for 50 minutes or until a knife comes out clean.',
    time: 60,
    serves: 9,
    fat: 3,
    saturated: 0,
    calories: 141,
    cholesterol: 0,
    carbohydrate: 27,
    protein: 2.5,
    sodium: 115,
    fiber: 2,
    difficulty: "easy",
    category: "dessert|family favorites",
  },
  {
    title: "Herbed New Potato Salad",
    slug: "herbed-new-potato-salad",
    img: "herbed-new-potato-salad",
    hasImage: true,
    description:
      "Colorful and delicious. If you have the ingredients handy, it’s so easy to put together.",
    ingredients:
      "Approximately 2 pounds (32oz) small red or new potatoes (or 4-5 medium red potatoes)|1/2 cup (75g) frozen peas, thawed|1 red pepper, chopped|1-2 green onions/spring onion, minced|1/4 cup (58g) “Light” mayonnaise (3.5g fat per tbsp.)|1/4 cup (65g) fat-free Greek yogurt|1-2 tsp. Dijon mustard|2-3 tbsp. fresh dill, minced or 2-3 tsp. dried dill|1/4 tsp. garlic powder|salt and fresh ground pepper, to taste",
    methods:
      "Boil potatoes until just cooked. Cool, (don’t peel) and cut into quarters or eighths. |In a large bowl, mix mayonnaise, yogurt, mustard and spices. |Add the rest of the ingredients and toss. Chill and serve. |To further lower fat content, use low fat or fat-free mayonnaise or fat-free Greek yogurt.",
    serves: 4,
    fat: 4,
    saturated: 0,
    calories: 209,
    cholesterol: 2,
    carbohydrate: 41,
    protein: 6,
    sodium: 184,
    fiber: 5,
    difficulty: "easy",
    category: "lunch|family favorites|vegetarian",
  },
  {
    title: "Holiday Breakfast Casserole",
    slug: "holiday-breakfast-casserole",
    img: "holiday-breakfast-casserole",
    hasImage: true,
    description:
      "An easy casserole that feeds the family and can be prepared the night before.",
    ingredients:
      "7 slices white bread|1, 8 oz. (226g) package of 96% fat-free diced ham|1, 8 oz. (226g) package shredded fat-free cheese (cheddar or Swiss)*|1, 16 oz. (453g) container liquid egg substitute|1¾ cup (394ml) skim milk|1/4 tsp. black pepper",
    methods:
      'Tear the bread into pieces and lay in a 9"x13" pan (or a 3-quart casserole). |Spread the diced ham and cheese over the bread. |Beat together the egg substitute, milk and pepper. Pour the mixture over the layers of bread, ham and cheese. |Cover with plastic wrap and press lightly on the bread pieces until the milk comes through to the top of the layers. Refrigerate overnight.|When ready to bake, remove the plastic wrap and preheat the oven to 350°F (176°C). Bake the casserole for 1 hour.',
    note:
      "*The fat-free shredded cheese can be substituted with 2% reduced-fat shredded cheese, which brings the Total Fat to 7.7 g per serving.",
    time: 80,
    serves: 8,
    fat: 1.6,
    saturated: 0,
    calories: 178,
    cholesterol: 19,
    carbohydrate: 49,
    protein: 22,
    sodium: 866,
    difficulty: "medium",
    category: "breakfast|family favorites ",
  },
  {
    title: "7 Layer Dip",
    slug: "seven-layer-dip",
    img: "seven-layer-dip",
    hasImage: true,
    description:
      "This Mexican-style dip is a great appetizer to bring to a party. No one will ever guess it’s low fat. Serve with baked tortilla chips or wedges of pita bread.",
    ingredients:
      "1/4 cup (44g) avocado, chopped|1 tablespoon fresh lemon juice|1 small clove garlic, minced|2 teaspoons onion, minced|1/2 cup (115g) Greek nonfat, plain yogurt|1/4 teaspoon salt|1/8 teaspoon pepper|1 cup (150g) (1/2 can) fat-free refried beans|2/3 cup (165g) fat-free sour cream/fat free greek yogurt|1/2 cup (115g) mild picante sauce|1/4 cup (29g) light finely shredded taco cheese|1 cup (175g) plum tomatoes, chopped|1 tablespoon fresh coriander/cilantro, chopped|1 tablespoon scallions, chopped",
    methods:
      "In small bowl, make guacamole by mixing first 7 ingredients.|In a 9 inch pie plate layer the following:|Layer 1: refried beans|Layer 2: sour cream/greek yogurt|Layer 3: guacamole|Layer 4: picante sauce|Layer 5: cheese|Layer 6: tomatoes|Layer 7: scallions/cilantro",
    time: 15,
    serves: 10,
    fat: 1.5,
    saturated: 0.4,
    calories: 68,
    cholesterol: 2,
    carbohydrate: 10,
    protein: 4,
    sodium: 299,
    difficulty: "easy",
    category: "snack|lunch|family favorites|quick and easy",
  },
  {
    title: "Apple Cider Jam Turnover",
    slug: "apple-cider-jam-turnovers",
    img: "apple-cider-jam-turnovers",
    hasImage: true,
    description:
      "These delicious little treats have the flavor of apple pie without the fat!",
    ingredients:
      "1/2 box filo/phyllo pastry sheets (approx. 20 sheets), thawed|8 oz (226g) fat free cream cheese, room temperature|1/2 cup (160g) apple jam or jelly|1 egg white plus 1 tablespoon water|Granulated sugar",
    methods:
      "Preheat oven to 350°F (176°C) and line 2 baking sheets with parchment paper.|Combine cream cheese and jam in small bowl until uniformly mixed.|Carefully unroll pastry sheets and cover with damp towel to keep from drying out.|Take 1 pastry sheet and fold in half lengthwise.|Place 1 tablespoon of cream cheese mixture in lower corner of pastry sheet.|Fold corner of pastry sheet diagonally across to opposite edge to form a triangle.|Continue to fold triangle onto itself, similar to folding a flag.|Place on baking sheet.|Whisk egg and water.  Brush egg wash on each triangle.  Sprinkle with sugar.|Bake for 20 minutes.",
    time: 35,
    serves: 10,
    fat: 0.6,
    saturated: 0.1,
    calories: 118,
    cholesterol: 3,
    carbohydrate: 21,
    protein: 6,
    sodium: 245,
    difficulty: "medium",
    category: "snack|dessert|family favorites",
  },
  {
    title: "Apple Crisp",
    slug: "apple-crisp",
    img: "apple-crisp",
    hasImage: true,
    description:
      "Enjoy this classic recipe with a scoop of fat-free vanilla frozen yogurt.",
    ingredients:
      "5 cups (875g) apples, peeled and sliced|2/3 cup (93g) rolled oats|1/3 cup (47g) flour|2/3 cup (113g) brown sugar, lightly packed|3/4 teaspoon cinnamon|3/4 teaspoon nutmeg|4 tablespoons honey",
    methods:
      "Preheat oven to 350°F (176°C) and spray 8 x 8 inch pan with nonstick spray.|Add apples to prepared pan.|Combine the rest of the ingredients in large bowl and mix well.|Sprinkle over apples and bake for 30-35 minutes.",
    time: 45,
    serves: 8,
    fat: 1.1,
    saturated: 0.2,
    calories: 182,
    cholesterol: 0,
    carbohydrate: 42,
    protein: 3,
    sodium: 4,
    fiber: 3,
    difficulty: "easy",
    category: "snack|dessert|family favorites",
  },
  {
    title: "Blueberry Pie",
    slug: "blueberry-pie",
    img: "blueberry-pie",
    hasImage: true,
    description:
      "Most of the fat in fruit pies comes from the crust. This low fat pie crust works perfectly with this classic pie.",
    ingredients:
      "Pie Crust (makes 1 crust; double it for 2 crusts)|1⅓ cups (187g) white flour |1 container (usually about 5.3 oz)(150g) Greek yogurt, plain |1/2 teaspoon olive oil |4 tablespoons cold water |6 cups (600g) frozen or fresh blueberries|3/4 cup (169g) sugar|4 tablespoons of quick tapioca|½ teaspoon cinnamon|1 tablespoon lemon juice",
    methods:
      "Preheat oven to 425°F (218°C).|Mix flour, Greek yogurt, and oil together until crumbly. |Add cold water and stir until mixed (do not overmix). |Knead dough 6-8 times. |Roll out on floured surface to fit pie pan.|For Filling: Mix ingredients in large bowl. |Add to prepared pie crust in a 9 inch pie pan. |Can top filling with a 2nd pie crust or cut 2nd pie crust into a lattice and add to top.|Bake at 425°F (218°C) for 60 minutes (few minutes more for frozen berries). ",
    time: 80,
    serves: 8,
    fat: 1.3,
    saturated: 0.2,
    calories: 330,
    cholesterol: 2,
    carbohydrate: 71,
    protein: 9,
    sodium: 20,
    fiber: 6,
    difficulty: "medium",
    category: "dessert|family favorites",
  },
  {
    title: "Bruschetta",
    slug: "bruschetta",
    img: "bruschetta",
    hasImage: true,
    description: "The simple appetizer recipe is delicious and easy to make. ",
    ingredients:
      "4 medium tomatoes, diced, seeds removed|1 cloves garlic, minced|1/4 cup (5g) fresh basil, chopped|1/2 tablespoon olive oil|1/4 teaspoon salt|1/4 teaspoon pepper|8-10 slices fresh Italian bread",
    methods:
      "In bowl, mix tomatoes, garlic, basil, olive oil, salt and pepper. Let sit for 10 minutes.|Toast bread slices on baking sheet.|Spoon tomato mixture on to bread slices and serve.",
    time: 15,
    serves: 8,
    fat: 2.0,
    saturated: 0.4,
    calories: 101,
    cholesterol: 0,
    carbohydrate: 18,
    protein: 3,
    sodium: 261,
    fiber: 2,
    difficulty: "easy",
    category: "appetizer|lunch|family favorites|quick and easy",
  },
  {
    title: "Buffalo Chicken Tenders",
    slug: "buffalo-chicken-tenders",
    img: "buffalo-chicken-tenders",
    hasImage: true,
    description:
      "This recipe is a hot and spicy but low fat version of this game day appetizer. Serve with your favorite fat free dressing.",
    ingredients:
      "1/2 cup (113ml) fat-free Buttermilk, divided|1/3 cup (75g) + 3 tablespoons Hot sauce, divided|3 tablespoons white vinegar|2 pounds (32oz) chicken tenders|6 tablespoons flour|6 tablespoons cornmeal|1/2 teaspoon cayenne pepper|1 teaspoon paprika|2 tablespoons fat-free sour cream ",
    methods:
      "Whisk 1/4 cup (56ml) buttermilk, 3 tablespoons hot sauce, and vinegar in large bowl. Add chicken and marinade in refrigerator for at least 30 minutes.|Mix flour, cornmeal, cayenne pepper and paprika in shallow dish.|Preheat oven to 425°F (218°C). Remove chicken from marinade and toss with cornmeal mixture. |Place chicken on large baking sheet lined with foil and sprayed with nonstick spray. |Bake chicken for 20 minutes, carefully turning after 10 minutes.|In small bowl, prepare additional buffalo sauce by combining 1/4 cup (56ml) buttermilk, 1/3 cup (75g) hot sauce and fat-free sour cream/fat free greek yogurt.|Once chicken is ready, drizzle desired amount of buffalo sauce over chicken.|Serve with carrot and celery sticks.",
    time: 60,
    serves: 6,
    fat: 4.6,
    saturated: 1.0,
    calories: 259,
    cholesterol: 112,
    carbohydrate: 15,
    protein: 37,
    sodium: 612,
    fiber: 1,
    difficulty: "medium",
    category: "lunch|dinner|family favorites",
  },
  {
    title: "Chicken Stew",
    slug: "chicken-stew",
    img: "chicken-stew",
    hasImage: true,
    description:
      "This hearty stew will warm you up during those cold nights. This recipe can be easily doubled to feed a larger crowd.",
    ingredients:
      "1/2 tablespoon olive oil|1/2 cup (25g) celery, chopped|1/2 cup (25g) carrots, chopped|1/2 cup (75g) onions, chopped |Salt and pepper to taste|14 ounce (396g) can diced tomatoes|14 ounce (396g) can fat-free, low sodium chicken broth|1/2 cup (10g) fresh basil leaves, torn|1 tablespoon tomato paste|1/2 teaspoon thyme leaves|1 large potato, cubed|1 pound boneless skinless chicken breast",
    methods:
      "Heat oil in large saucepan over medium heat and add celery, carrot, onion.|Cook for 5-10 minutes; add salt and pepper.|Stir in the remaining ingredients, adding the chicken last.|Bring to a boil and then simmer uncovered for 25 minutes or until chicken reaches internal temperature of 165°F (73°C).|Take out chicken and cut into bite size pieces; then add back to stew and simmer for an additional 5 minutes.|Serve with crusty bread.",
    time: 60,
    serves: 4,
    fat: 4.9,
    saturated: 0.9,
    calories: 233,
    cholesterol: 83,
    carbohydrate: 17,
    protein: 28,
    sodium: 521,
    fiber: 3,
    difficulty: "medium",
    category: "dinner|family favorites|hearty meal",
  },
  {
    title: "Cocktail Meatballs",
    slug: "cocktail-meatballs",
    img: "cocktail-meatballs",
    hasImage: true,
    description:
      "This a low fat version of a popular appetizer. Sweet, tangy and delicious!",
    ingredients:
      "1 pound “Extra Lean” ground beef (at least 95% fat-free)|1/2 cup (63g) seasoned breadcrumbs|2 tablespoons fat-free milk|2 egg whites|3 tablespoons onion, minced|8 oz (226g) jellied cranberry sauce|3/4 cup (170g) chili sauce|1½ teaspoons lemon juice|1½ tablespoons brown sugar",
    methods:
      "Preheat oven to 350°F (176°C).|In a large bowl mix ground beef, bread crumbs, milk, egg whites, and onion.|Roll into small balls (approx. 1.5 inch) and place on cookie sheet that has been lined with non-stick foil.|Bake for 20-25 minutes or until internal temperature has reached minimum of 160°F (71°C); turn meatballs halfway through cooking time.|In a saucepan, combine remaining ingredients and bring to a simmer.|Add meatballs and simmer for 30 minutes.|Can add to a crockpot to keep warm and serve with toothpicks. |Makes 24 meatballs.",
    time: 60,
    serves: 8,
    fat: 3.4,
    saturated: 1.4,
    calories: 190,
    cholesterol: 35,
    carbohydrate: 24,
    protein: 15,
    sodium: 505,
    fiber: 2,
    difficulty: "easy",
    category: "appetizer|family favorites",
  },
  {
    title: "Creamy Mustard Tarragon Chicken",
    slug: "creamy-mustard-tarragon-chicken",
    img: "creamy-mustard-tarragon-chicken",
    hasImage: true,
    description:
      "This creamy sauce will bring a new flavor to your chicken dinner.",
    ingredients:
      "4 skinless boneless chicken breast halves, cooked|1 teaspoon soft tub margarine|1/4 cup (25g) shallots, finely chopped|1/4 cup (56ml) fat free, low sodium chicken broth|1/3 cup (76g) Dijon Mustard (or Onion Mustard)|1/2 cup (113ml) evaporated skim milk|1 tablespoon fresh tarragon, chopped",
    methods:
      "Melt margarine in a large skillet over medium-high heat.|Add shallots and sauté for 1-2 minutes. |Add chicken broth and Caramelized Onion Mustard and bring to simmer.|Slowly add evaporated skim milk and tarragon. Stir as it thickens slightly.|Add cooked chicken breast and heat through.",
    serves: 4,
    fat: 5.6,
    saturated: 1.2,
    calories: 269,
    cholesterol: 125,
    carbohydrate: 9,
    protein: 41,
    sodium: 367,
    fiber: 0,
    difficulty: "easy",
    category: "dinner|family favorites",
  },
  {
    title: "Easy Fiesta Fish",
    slug: "easy-fiesta-fish",
    img: "easy-fiesta-fish",
    hasImage: true,
    description:
      "This is a simple, but delicious recipe for white fish. Leftovers are perfect for fish tacos.",
    ingredients:
      "1 small red pepper, sliced thin|1 small onion, sliced thin|Nonstick spray|2 pounds (32oz) cod fish or other extra lean white fish|1 cup (225g) fat free salsa|3/4 cup (86g) Sargento Reduced Fat Cheddar",
    methods:
      "Preheat oven to 350°F (176°C).|Spray skillet with nonstick spray.|Sauté onions and peppers in skillet for 5-10 minutes.|Place fish in large 13x9 pan and add onions and pepper mixture on top.|Spread salsa on top of fish and then sprinkle cheese.|Bake for 30-35 minutes or until fish flakes easily.|Garnish with fat-free sour cream/fat free greek yogurt.",
    time: 50,
    serves: 6,
    fat: 4.1,
    saturated: 2.2,
    calories: 201,
    cholesterol: 75,
    carbohydrate: 10,
    protein: 31,
    sodium: 177,
    fiber: 1,
    difficulty: "easy",
    category: "dinner|family favorites|fish",
  },
  {
    title: "Easy Meatloaf",
    slug: "easy-meatloaf",
    img: "easy-meatloaf",
    hasImage: true,
    description:
      "If you are missing your beef, try this recipe for a taste of one of America’s favorite comfort foods.",
    ingredients:
      "1½ pounds (24oz) “Extra Lean” ground beef (at least 95% fat-free)|1 cup (125g) plain breadcrumbs|3 egg whites|1 cup (225ml) fat-free milk|1/4 cup (40g) onions, diced|1/4 teaspoon pepper|2 teaspoons Worcestershire Sauce|2/3 cup (153g) ketchup|1/3 cup (57g) brown sugar|3 tablespoons mustard",
    methods:
      "Preheat oven to 350°F (176°C).|In a large bowl mix ground beef, breadcrumbs, egg whites, milk, onions, pepper, and Worcestershire sauce.|In separate bowl, mix remaining ingredients to make sauce.|Add beef mixture to a 5 x 9 loaf pan and bake for 40 minutes and then add ½ sauce mixture to top of loaf.  |Bake an additional 50 minutes or until internal temperature has reached minimum of 160°F (71°C).|Serve the meatloaf with the remaining sauce on the side.",
    time: 100,
    serves: 6,
    fat: 6.9,
    saturated: 3.0,
    calories: 317,
    cholesterol: 71,
    carbohydrate: 31,
    protein: 31,
    sodium: 513,
    fiber: 1,
    difficulty: "easy",
    category: "dinner|family favorites|hearty meal",
  },
  {
    title: "Eye Round Roast with Vegetables",
    slug: "eye-round-roast-with-vegetables",
    img: "eye-round-roast-with-vegetables",
    hasImage: true,
    description:
      "The key to this recipe is to use a meat thermometer and to avoid overcooking the roast.",
    ingredients:
      "2 pound eye of the round beef roast|1-2 garlic cloves, minced|1/4 teaspoon basil|1/4 teaspoon oregano|1/8 teaspoon thyme|1/2 teaspoon chili powder|1/2 teaspoon salt|1 teaspoon pepper|1/4 cup (56ml) fat-free, low sodium beef broth|2 celery stalks, chopped|3 carrots, chopped|2 medium potatoes, cubed|1 onion, quartered",
    methods:
      "Preheat oven to 375°F (190°C).|In a small bowl mix garlic, basil, oregano, thyme, chili powder, salt and pepper.|Place roast in shallow roasting pan and rub with spice mixture.|Add beef broth and vegetables around the roast.|Bake for approximately 1 hour or to desired doneness.(Internal temperature for medium rare is approximately 130°F (54°C)).|Let roast rest for 15 minutes before carving.",
    time: 90,
    serves: 8,
    fat: 3.6,
    saturated: 1.3,
    calories: 195,
    cholesterol: 68,
    carbohydrate: 13,
    protein: 28,
    sodium: 252,
    fiber: 2,
    difficulty: "medium",
    category: "dinner|family favorites|hearty meal",
  },
  {
    title: "Fish Chowder",
    slug: "fish-chowder",
    img: "fish-chowder",
    hasImage: true,
    description:
      "This is a warm and tasty chowder to enjoy as the weather gets cool. Pair with some crusty bread or low fat oyster crackers.",
    ingredients:
      "2 pounds (32oz) cod or haddock fillet|2 oz (56g) diced extra-lean turkey bacon (approximately 4 slices) |2 teaspoon canola oil|2 sliced medium onions|1 cup (225g) chopped celery|3 diced large potatoes|1 crumbled bay leaf|4 cups (900ml) skim milk (try ultra-filtered fat free milk or evaporated skim milk-it’s creamier!)|1 Tablespoon light tub margarine|Pepper to taste",
    methods:
      "Simmer fish in 2 cups (450ml) of water for 15 minutes. |Drain off and reserve broth. |Saute turkey bacon in canola oil in large pot until crisp. |Add onions and sauté until light golden brown.|Add fish, celery, potatoes, and bay leaf. |Measure reserved fish broth plus enough water to make 3 cups (675ml) liquid. Add to pot and simmer for 40 minutes.|Add milk and margarine and simmer additional 5 min. Pepper to taste.",
    time: 80,
    serves: 8,
    fat: 3.5,
    saturated: 0.7,
    calories: 230,
    cholesterol: 56,
    carbohydrate: 22,
    protein: 27,
    sodium: 227,
    fiber: 2,
    difficulty: "medium",
    category: "dinner |family favorites|hearty meal|fish",
  },
  {
    title: "Herb Encrusted Chicken Breast with Mango Chutney",
    slug: "herb-encrusted-chicken-breast-with-mango-chutney",
    img: "herb-encrusted-chicken-breast-with-mango-chutney",
    hasImage: true,
    description:
      "Try this recipe to bring more flavor to your weekly grilled chicken breast.",
    ingredients:
      "6 chicken breasts, approximately 6 oz (170g) each|Non-stick spray|Salt and pepper|Dijon mustard|Sprigs of fresh rosemary, basil, and parsley (or your favorite fresh herbs), minced|3/4 cup (130g) tomatoes, diced|3/4 cup (130) mango, diced|1/2 cup (90g) mild green chiles, diced|1/4 cup (25g) scallions, sliced|2 tablespoons lime juice|1/4 teaspoon salt|1/4 teaspoon ground black pepper|1/8 teaspoon cayenne pepper",
    methods:
      "Preheat oven to 350°F (176°C).|Season chicken with salt and pepper. Spray either indoor or outdoor grill with non-stick spray and grill chicken to mark it for approximately 1-2 minutes.|After marking the chicken, place in large baking dish. |Add a thin layer of Dijon mustard on top of chicken and then add fresh herbs of your choosing.|Bake in oven until internal temperature reaches 165°F (73°C).|Mix ingredients for Mango Chutney and serve on top of chicken breast.",
    serves: 6,
    fat: 4.5,
    saturated: 1.0,
    calories: 215,
    cholesterol: 124,
    carbohydrate: 2,
    protein: 39,
    sodium: 191,
    fiber: 0,
    difficulty: "medium",
    category: "dinner|hearty meal",
  },
  {
    title: "Honey Lime Dressing",
    slug: "honey-lime-dressing",
    img: "honey-lime-dressing",
    hasImage: true,
    description:
      "Add this dressing to your favorite salad. No one will ever guess it is fat free.",
    ingredients:
      "8 oz (226g) nonfat plain yogurt|8 oz (226g) champagne vinegar|1 tablespoon honey|1½ teaspoons lime zest|1/2 teaspoon ground black pepper",
    methods: "Whisk ingredients together. Makes 1 pint.",
    time: 5,
    serves: 16,
    fat: 0,
    saturated: 0,
    calories: 18,
    cholesterol: 0,
    carbohydrate: 2,
    protein: 1,
    sodium: 13,
    fiber: 0,
    difficulty: "easy",
    category: "lunch|quick and easy",
  },
  {
    title: "Apple Brown Betty",
    slug: "apple-brown-betty",
    img: "apple-brown-betty",
    hasImage: true,
    description:
      "A sweet, cinnamon aroma will fill your home as this easy slow-cooker recipe cooks!",
    ingredients:
      "6 cups cooking apples, peeled, cored and cut into eights (about 5 medium-sized apples)|1 cup (125g) bread crumbs|1 teaspoon ground cinnamon|1 teaspoon ground nutmeg|1/8 teaspoon salt|3/4 cup (128g) packed light brown sugar|3/4 cup (190g) plain Greek yogurt|2 tablespoons light butter, melted|1/4 cup (29g) finely chopped walnuts|1/2 cup (75g) fat-free vanilla ice-cream|Add 1/2 (75g) cup fat-free ice-cream if you’d like!",
    methods:
      "Coat your slow cooker with nonstick cooking spray. Put apples in bottom.|Combine bread crumbs, cinnamon, nutmeg, salt, sugar, butter, Greek yogurt, and walnuts in a medium-sized bowl. Spread over apples. Cover; cook on LOW 3-4 hours or on HIGH 2 hours. |Serve warm with fat-free ice-cream or fat-free whipped cream",
    time: 130,
    serves: 8,
    fat: 3.1,
    saturated: 0.5,
    calories: 170,
    cholesterol: 1,
    carbohydrate: 33,
    protein: 3,
    sodium: 99,
    fiber: 3,
    difficulty: "medium",
    category: "dessert|family favorites",
  },
  {
    title: "Beef Kebabs",
    slug: "beef-kebabs",
    img: "beef-kebabs",
    hasImage: true,
    description: "A tasty meal, great for summertime cookouts!",
    ingredients:
      "16 oz (453g) sirloin steak, lean only, chopped|2 potatoes, cubed|16 cherry tomatoes|1 red onion, sliced|2 bell peppers, sliced|1 tablespoon seasoning|8 Skewers|Nonstick cooking spray",
    methods:
      "Trim any visible fat off the lean only sirloin. Cut into 1 ounce (28g) (1 inch x 1 inch) cubes.|Wash potatoes and cut into 1 inch x 1 inch cubes.|Slice onion and cut into small pieces.|Place 2 cherry tomatoes, 2 1-ounce sirloin cubes, 3 potato cubes, 3 slices of pepper, and 3 onion slices on each skewer alternating items. Do this 8 times.|Place the completed skewers on the grill or baking dish. Season with Mrs. Dash seasoning.|To grill: Spray the grill with non-stick cooking spray and grill until the meat is at your desired doneness and you can stick a fork through the potatoes. |To bake: cook at 425°F (218°C) for 30 minutes or until done.",
    serves: 4,
    fat: 6.9,
    saturated: 2.6,
    calories: 330,
    cholesterol: 93,
    carbohydrate: 28,
    protein: 38,
    sodium: 87,
    fiber: 4,
    difficulty: "medium",
    category: "dinner|family favorites",
  },
  {
    title: "Berry Trifle",
    slug: "berry-trifle",
    img: "berry-trifle",
    hasImage: true,
    description: "A fun and easy dessert to take to a picnic or gathering.",
    ingredients:
      "10 ounce (283g) prepared angel food cake, cut and cubed|1 pint of fresh strawberries, sliced|2-6 ounce (56-170g) container of fresh blueberries |1-12 ounce (28-340g) tub of fat free whipped cream",
    methods:
      "Layer ingredients starting with sliced strawberries, cake, Cool Whip and blueberries and repeat. ",
    time: 10,
    serves: 8,
    fat: 0.5,
    saturated: 0,
    calories: 189,
    cholesterol: 0,
    carbohydrate: 41,
    protein: 3,
    sodium: 290,
    fiber: 2,
    difficulty: "easy",
    category: "dessert|family favorites",
  },
  {
    title: "Boneless Lean Stew",
    slug: "boneless-lean-stew",
    img: "boneless-lean-stew",
    hasImage: true,
    description: "A warm treat for a cold night.",
    ingredients:
      "1 tbsp. olive or vegetable oil|1 1/4 lb. (20oz) lean stew meat|1 clove garlic|1 medium onion|1 green pepper|1 tsp. turmeric|1 tsp. sage|1 tsp. cumin|2 cups (300g) baby peas (frozen or canned)|1 lb. (16oz) baby carrots|4 medium potatoes peeled and cut to quarters",
    methods:
      "Cover the bottom of a large pot lightly with oil. Turn heat on low.|Cut up onion and garlic and add to pot. Heat until onions are soft.|Add the rest of the ingredients, except the water. Let cook for 5 minutes, stirring occasionally, to let the alcohol cook down. |Add water (enough to cover other ingredients). For a thicker sauce, add flour a tablespoon or two at a time. Cover and allow stew to simmer for about 30 minutes on low to medium heat.|Tip: This stew is even better the second or third day, so don’t be afraid to freeze it.",
    time: 45,
    serves: 6,
    fat: 6,
    saturated: 2,
    calories: 312,
    cholesterol: 62,
    carbohydrate: 39,
    protein: 26,
    sodium: 143,
    fiber: 7,
    difficulty: "medium",
    category: "dinner|family favorites|hearty meal",
  },
  {
    title: "Chicken Salad",
    slug: "chicken-salad",
    img: "chicken-salad",
    hasImage: true,
    description: "A quick and easy version of a summer favorite!",
    ingredients:
      "2 large cans of chunk white chicken breast, drained - shredded with fork to break up|3 celery stalks, chopped|1/2 medium onion, chopped|1/4 cup (65g) fat free plain Greek yogurt|1/8 teaspoon of salt|1/4 teaspoon of ground black pepper",
    methods:
      "Shred chicken in a medium size mixing bowl.|Add all other ingredients and mix.|Optional Fat Free additions: chopped apple, grapes, sweet relish or crushed pineapple",
    time: 10,
    serves: 6,
    fat: 0.1,
    saturated: 0,
    calories: 101,
    cholesterol: 0.5,
    carbohydrate: 6,
    protein: 16,
    sodium: 437,
    fiber: 2,
    difficulty: "easy",
    category: "lunch|quick and easy",
  },
  {
    title: "Christmas Morning Crock Pot French Toast",
    slug: "christmas-morning-crock-pot-french-toast",
    img: "christmas-morning-crock-pot-french-toast",
    hasImage: true,
    description:
      "A sweet casserole to have on any morning. The smells of cinnamon will enter your home while you’re waiting for this soft and delicious treat to be finished!",
    ingredients:
      "1 whole egg|3 egg whites|1½ cups (338ml) skim milk|2 tablespoons honey|1 teaspoon vanilla extract|1/2 teaspoon cinnamon|9 slices white bread|3 cups finely diced uncooked apple pieces (Gala works great; 3 cups is about 3 medium-sized apples)|3 tablespoons honey|1 teaspoon lemon juice|1/2 teaspoon cinnamon",
    methods:
      'Spray the inside of the crock pot with nonstick cooking spray.|Place egg, egg whites, milk, honey, vanilla extract, and 1/2 teaspoon cinnamon in a medium-sized mixing bowl. Whisk thoroughly.|Dice up the apples finely. |Add the apple pieces (3 cups is about 3 medium-sized apples), 3 tablespoons of honey, 1 teaspoon lemon juice, and 1/2 teaspoon cinnamon in a small mixing bowl. Stir these ingredients together, making sure to cover all the apple pieces. Set this bowl aside, it will be your "filling".|Cut bread pieces in half making them triangular. Place one layer of bread (about 6 triangles) on the bottom of the crock pot. Add 1/4 of the filling and repeat until there are 3 layers. Add the remaining filling to the top.|Pour the egg mixture over the bread. Put the lid on the crock pot and cook on high 2 to 2.5 hours or low for 4 hours.',
    time: 10,
    serves: 9,
    fat: 1.5,
    saturated: 0.4,
    calories: 164,
    cholesterol: 21,
    carbohydrate: 32,
    protein: 6,
    sodium: 167,
    fiber: 2,
    difficulty: "medium",
    category: "breakfast|family favorites|christmas",
  },
  {
    title: "Cranberry Orange Muffins",
    slug: "cranberry-orange-muffins",
    img: "cranberry-orange-muffins",
    hasImage: true,
    ingredients:
      "2 cups (280g) all-purpose flour|2/3 cups (133g) sugar|2 teaspoons baking powder|1/4 teaspoon salt|1 cup (100g) chopped fresh cranberries|2/3 cup (150ml) 2% milk|6 ounce (170g) container of non-fat Greek yogurt|1 tablespoon canola oil|1 teaspoon grated orange rind|1/2 teaspoon vanilla extract|2 egg whites|Cooking spray",
    methods:
      "Preheat oven to 400°F (204°C) and spray muffin tin with cooking spray|Spoon flour into measuring cup and level with a knife. |Combine flour, sugar, baking powder and salt in a large bowl and stir well. |Stir in cranberries and make a well in the center of the mixture.|Combine milk, applesauce, rind, vanilla and egg whites; add to flour mixture, stirring just until moist. |Spoon batter into 12 muffin cups coated with cooking spray. Bake at 400 degrees for 18 minutes or until muffins spring back when touched lightly in the center. Remove muffins from pan immediately; place on wire rack.",
    time: 25,
    serves: 12,
    fat: 1.4,
    saturated: 0.2,
    calories: 144,
    cholesterol: 1,
    carbohydrate: 28,
    sodium: 146,
    fiber: 1,
    difficulty: "easy",
    category: "snack|family favorites|quick and easy",
  },
  {
    title: "Creamy Mushroom Chicken",
    slug: "creamy-mushroom-chicken",
    img: "creamy-mushroom-chicken",
    hasImage: true,
    description:
      "If you are looking for a flavorful and creamy taste, look no further than this deceptively simple recipe.",
    ingredients:
      "1½ pound skinless chicken breast |10 ounce (283g) can Cream of Mushroom Soup |1 cup (250g) fat-free sour cream/fat free greek yogurt |3 tablespoons sherry |8 ounce (226g) can sliced mushrooms, drained |1 cup (150g) frozen peas, thawed |1 small jar pimentos, if desired Black pepper to taste |4 cups (800g) brown rice, cooked ",
    methods:
      "Preheat oven to 350°F (176°C)| Put chicken breasts in a baking dish prepared with nonstick cooking spray.|In a small bowl, mix together soup, sour cream/greek yogurt, mushrooms, and sherry. Pour over chicken and cover.|Bake for 50 - 65 minutes, until bubbly.|Add thawed frozen peas and pimentos (if desired) at the end of cooking.|Allow to sit for 10 minutes.|Serve chicken and sauce over 1 cup (200g) brown rice per person.",
    time: 90,
    serves: 4,
    fat: 7.4,
    saturated: 1.6,
    calories: 558,
    cholesterol: 133,
    carbohydrate: 68,
    sodium: 723,
    fiber: 6,
    difficulty: "easy",
    category: "dinner|hearty meal",
  },
  {
    title: "Crockpot Banana Nut Bread",
    slug: "crock-pot-banana-nut-bread",
    img: "crock-pot-banana-nut-bread",
    hasImage: true,
    description: "A light and fluffy banana nut bread that all will enjoy!",
    ingredients:
      "1¾ cups (245g) all-purpose flour|2 teaspoons baking powder|1/2 teaspoon salt|1/4 teaspoon baking soda|1/4 cup (56g) light margarine|1/2 cup (125g) plain Greek yogurt|2/3 cup (150g) sugar|1/2 cup (4 eggs) egg whites|2 tablespoons dark corn syrup|3 ripe bananas, well mashed|1/4 cup (125g) chopped walnuts|Cinnamon to taste",
    methods:
      "Grease and flour slow cooker. Sift together flour, baking powder, salt, and baking soda in small bowl; set aside.|Cream butter and Greek yogurt in large bowl with electric mixer at medium-high speed until fluffy. Slowly add sugar, eggs, corn syrup, and mashed bananas. Beat until smooth. Gradually add flour mixture to creamed mixture. Add walnuts and mix well.| Pour into slow cooker. Cover, cook on HIGH 1 ¼ to 1 ½ hours or until toothpick inserted into center comes out clean.|Let cool, then invert bread onto serving platter. Slice and sprinkle with cinnamon to your taste.",
    time: 100,
    serves: 8,
    fat: 4.8,
    saturated: 0.7,
    calories: 269,
    cholesterol: 0,
    carbohydrate: 52,
    sodium: 357,
    fiber: 2,
    difficulty: "medium",
    category: "snack|family favorites",
  },
  {
    title: "Crockpot Corn Chowder",
    slug: "crock-pot-corn-chowder",
    img: "crock-pot-corn-chowder",
    hasImage: true,
    description:
      "A warm, hearty meal full of flavor and easy to make! Sprinkle with fat-free cheddar cheese at the end if you’d like!",
    ingredients:
      "2, 10 ounce (283g) bags of frozen corn kernels|4 slices of turkey bacon, chopped|2 celery ribs, diced|1 yellow onion, chopped|2 russet potatoes, washed and diced|4, 12 ounce (340g) cans of fat-free evaporated milk|1/2 teaspoons of salt|1½ teaspoon pepper|1 tablespoon unsalted butter|1 garlic clove, minced",
    methods:
      "Chop bacon, celery, onion, and potatoes into bite-sized pieces.|Place all ingredients in crock pot.|Cook on high for 4 hours or low for 8 hours.",
    serves: 6,
    fat: 4.4,
    saturated: 1.7,
    calories: 390,
    cholesterol: 32,
    carbohydrate: 64,
    sodium: 642,
    fiber: 4,
    difficulty: "easy",
    category: "dinner|hearty meal",
  },
  {
    title: "Easy Chocolate Cannoli Treats",
    slug: "easy-chocolate-cannoli-treats",
    img: "easy-chocolate-cannoli-treats",
    hasImage: true,
    description:
      "Quick, delicious, and chocolatey! What a great dessert you can bring with you to an event or if you are hosting.",
    ingredients:
      "1½ cups (375g) nonfat ricotta cheese|1/4 cup (50g) confectioners’ sugar|1/2 teaspoon vanilla extract|36 chocolate wafer cookies|18 large strawberries, halved|1/4 cup (88g) fat-free chocolate syrup",
    methods:
      "Put the ricotta cheese, confectioner’s sugar, and vanilla extract in a large mixing bowl. Mix until light and creamy. You may also use a food processor.|Place 1 teaspoonful of the cheese mixture in the middle of each cookie. Press a strawberry half into the cheese. |Arrange on a serving plate and drizzle chocolate syrup over the cookies.|Refrigerate if there are any left overs.",
    serves: 18,
    fat: 1.8,
    saturated: 0.5,
    calories: 88,
    cholesterol: 5,
    carbohydrate: 15,
    sodium: 133,
    fiber: 1,
    difficulty: "easy",
    category: "dessert|family favorites|quick and easy",
  },
  {
    title: "Easy Pie Crust",
    slug: "easy-pie-crust",
    img: "easy-pie-crust",
    hasImage: true,
    description: "A tasty and easy base for all of your favorite pies!",
    ingredients:
      "1⅓ cups (187g) white flour|1 container (usually about 5.3 oz)(150g) Greek yogurt, plain|1/2 teaspoon olive oil|4 tablespoons cold water|1 teaspoon cinnamon",
    methods:
      "Mix flour, Greek yogurt, and oil together until crumbly.|Add cold water and stir until mixed (do not overmix).|Knead dough 6-8 times.|Roll out on floured surface to fit pie pan.|Sprinkle cinnamon on rolled-out dough.|Bake at 425°F (218°C) for 15-20 minutes.",
    time: 30,
    serves: 8,
    fat: 0.6,
    saturated: 0.1,
    calories: 91,
    cholesterol: 1,
    carbohydrate: 17,
    sodium: 8,
    fiber: 1,
    difficulty: "easy",
    category: "dessert|family favorites|quick and easy",
  },
  {
    title: "Easy Spinach Pasta Salad",
    slug: "easy-spinach-pasta-salad",
    img: "easy-spinach-pasta-salad",
    hasImage: true,
    ingredients:
      "1 cup (175g) chopped sun-dried tomatoes, moist but not packed in oil|14.5 oz (411g) of bow tie pasta|6 cups (1350g) baby spinach|1 can of cannellini beans|2/3 cup (165ml) fat-free Italian dressing|2 cloves of garlic, crushed|1/2 cup (50g) fat-free Parmesan cheese|Pepper or crushed red pepper optional",
    methods:
      "Cook pasta according to directions. Drain and rinse with cold water and put in a large bowl.|Add in remaining ingredients and mix well.|Cover and chill for 2 hours before serving.",
    serves: 12,
    fat: 1.1,
    saturated: 0,
    calories: 214,
    cholesterol: 1,
    sodium: 255,
    fiber: 4,
    difficulty: "easy",
    category: "lunch|dinner|family favorites",
  },
  {
    title: "Fat Free Biscuits",
    slug: "fat-free-biscuits",
    img: "fat-free-biscuits",
    hasImage: true,
    description:
      "Using nonfat yogurt to replace the butter and milk, you still get great rise and texture.",
    ingredients:
      "2 cups (280g) all-purpose flour|1 tablespoon baking powder|1/2 teaspoon baking soda|1 teaspoon salt|1¼ cup (315g) nonfat plain yogurt",
    methods:
      "Preheat oven to 400°F (204°C).|Spray a cookie sheet with non-stick spray.|Combine flour, baking powder, baking soda, and salt. Mix well.|Add nonfat yogurt. Mix flour and yogurt mixture.|Should make 8 biscuits. You can make drop biscuits or spread the dough onto a floured surface and pat down to about ¾ inch thick. Cut with a round floured cutter.|Bake until golden brown. 12 minutes.",
    time: 20,
    serves: 8,
    fat: 0,
    saturated: 0,
    calories: 133,
    cholesterol: 1,
    carbohydrate: 27,
    protein: 5,
    sodium: 908,
    fiber: 1,
    difficulty: "easy",
    category: "snack|fat free|family favorites|quick and easy",
  },
  {
    title: "Fat Free Oatmeal Cookies",
    slug: "fat-free-oatmeal-cookies",
    img: "fat-free-oatmeal-cookies",
    hasImage: true,
    ingredients:
      "1 cup (140g) oats, dry |1 cup (140g) flour |1/2 teaspoon salt |1/2 teaspoon baking soda |1/2 teaspoon vanilla extract |1/4 cup (56g) granulated sugar |1/2 cup (85g) brown sugar |1/4 cup (40g) raisins |1 cup (250g) applesauce |1 egg white |2 tablespoons soy milk",
    methods:
      "Preheat oven to 375°F (190°C).|Mix all dry ingredients with raisins.|Add apple sauce, egg white and soy milk to the dry ingredients.|Drop onto a non-stick cookie sheet.|Bake for 12-15 minutes",
    time: 25,
    serves: 12,
    fat: 0.6,
    saturated: 0.1,
    calories: 122,
    cholesterol: 0,
    carbohydrate: 27,
    protein: 3,
    sodium: 158,
    fiber: 1,
    difficulty: "easy",
    category: "snack|fat free|family favorites|quick and easy",
  },
  {
    title: "Fluffy Pie",
    slug: "fluffy-pie",
    img: "fluffy-pie",
    hasImage: true,
    ingredients:
      "3-4 Tablespoons honey|5 sheets of low-fat honey graham crackers (put in plastic bag and crush with a spoon, makes 1 cup (125g) of crumbs)|1/2 cup (113ml) boiling water|1 small package lemon gelatin (or strawberry, cherry)|1¾ cups (440g) nonfat lemon yogurt (or strawberry, cherry)|1½ cups (360ml) nonfat whipped topping",
    methods:
      "Preheat oven to 350°F (176°C).|Make crust by mixing together the honey with the graham crackers crumbs. Press into the bottom of a 9 ½ “pie pan prepared with nonstick cooking spray. Bake for 7 minutes. (Honey browns quickly… do not overbake.) Cool before filling.|To make the filling, place the boiling water in a large bowl. Sprinkle the gelatin over the water and whisk for 3 minutes or until gelatin is completely dissolved. Set mixture aside for 20 minutes or until it has cooled to room temperature.|When the gelatin is cooled, whisk in the yogurt. Chill for 10 minutes. Stir the mixture, it should be the consistency of pudding. |When the gelatin mixture has reached the proper consistency, stir it well. Gently fold in the whipped topping. Spoon the mixture into the pie shell. Chill for at least 3 hours before cutting.",
    serves: 8,
    fat: 2.1,
    saturated: 1.5,
    calories: 169,
    cholesterol: 0,
    carbohydrate: 31,
    protein: 3,
    sodium: 152,
    fiber: 0,
    difficulty: "medium",
    category: "dessert|family favorites",
  },
  {
    title: "Hashbrown Potatoes",
    slug: "hashbrown-potatoes",
    img: "hashbrown-potatoes",
    hasImage: true,
    description:
      "This dish is easy to make, full of flavor and may be adapted to your taste with seasonings and different vegetables.",
    ingredients:
      "4 medium potatoes |1/2 medium onion |1 zucchini/courgette, optional |Chives |Salt to taste |Pepper to taste |Greek No-Fat, Plain Yogurt (we’ve used Fage 0% Greek yogurt)|Applesauce",
    methods:
      "Grate the potato and onion. Add grated zucchini/courgette, if desired.|Place mixture in a towel and wring out as much excess moisture as you can. |Season mixture as desired with chopped chives and salt and pepper to taste.|Spray a skillet with cooking spray. |Heat pan, then add mixture in one large pancake. Press mixture down slightly so that ingredients are evenly distributed in pan.|Allow mixture to brown, then carefully flip pancake and brown other side. |If you like, you may place pancake under broiler, watching carefully until it reaches desired level of crispness.|Top pancake with your choice of toppings: applesauce, fat-free Fage Greek yogurt.|Enjoy!",
    serves: 4,
    fat: 0.3,
    saturated: 0,
    calories: 167,
    cholesterol: 0,
    carbohydrate: 38,
    protein: 4,
    sodium: 15,
    fiber: 3,
    difficulty: "easy",
    category: "light snacks|family favorites",
  },
  {
    title: "Homemade Pizza Dough",
    slug: "homemade-pizza-dough",
    img: "homemade-pizza-dough",
    hasImage: true,
    ingredients:
      "1 package of active dry yeast|1 cup (225ml) warm water|1 tablespoon honey|2½ cups (340g) bread flour (Note bread flour makes a crispier crust, all-purpose flour makes a chewy crust)|1/2 teaspoon salt",
    methods:
      "Dissolve yeast and honey in warm water. Let it sit for 10 minutes until it is foamy.|Mix flour and salt in a bowl and gradually add yeast mixture. Form dough into a ball. Kneed for 5 minutes and put the dough into a lightly floured bowl. Cover with plastic wrap and let it sit for an hour or until it doubles in size. |Preheat oven to 450°F (232°C).|Remove dough from the bowl and punch it down. Roll it out into a 12- inch round. Place on a baking stone or sheet and add your preferred toppings. Bake for 10-13 minutes or until crust is golden brown.",
    time: 25,
    serves: 8,
    fat: 0.8,
    saturated: 0,
    calories: 165,
    cholesterol: 0,
    carbohydrate: 34,
    protein: 6,
    sodium: 147,
    fiber: 1,
    difficulty: "easy",
    category: "dinner|family favorites|quick and easy",
  },
  {
    title: "Honey Dijon Mustard Dressing",
    slug: "honey-dijon-mustard-dressing",
    img: "honey-dijon-mustard-dressing",
    hasImage: true,
    description:
      "This basic dressing begs you to see how many variations you can make by using different types of mustard and vinegar, not to mention using jam or jelly in place of the honey.",
    ingredients:
      "2 tablespoons Dijon-style mustard|2 tablespoons red wine vinegar|1 tablespoon honey|Freshly ground black pepper, to taste",
    methods: "Combine the ingredients in a bowl and whisk until combined.",
    time: 10,
    serves: 2,
    fat: 0,
    saturated: 0,
    calories: 35,
    cholesterol: 0,
    carbohydrate: 9,
    protein: 0,
    sodium: 347,
    fiber: 0,
    difficulty: "easy",
    category: "quick and easy",
  },
  {
    title: "Hoppin’ John",
    slug: "hoppin-john",
    img: "hoppin-john",
    hasImage: true,
    description:
      "This tasty dish is a New Year’s tradition in the South, where it is thought to bring good luck when eaten on New Year’s Day.",
    ingredients:
      "3 cups (450g) dried black-eyed peas, sorted|4 cups (900ml) of low sodium, fat-free chicken broth|1 medium yellow onion, chopped|1½ teaspoons crushed fresh garlic|1½ teaspoons dried sage|8 oz (226g) diced Canadian bacon|1/4 teaspoon ground black pepper|1/4 teaspoon ground cayenne pepper|3 chopped jalapeños (optional)|14.5 ounce (411g) can petite diced tomatoes (optional)",
    methods:
      "Place the black eyed peas in a 4 quart pot, and add 2 ½ quarts of water. Cover and soak for 4 hours. |When ready to cook, drain off the soaking water and return the peas to the pot.|Add all of the remaining ingredients, except for the jalapeños and tomatoes to the pot and bring the mixture to a boil over high heat. Lower the heat, and cover to simmer for 30 minutes. |If desired add the jalapeños and tomatoes then cook for another 15-20 minutes or until beans are tender and the liquid has thickened. Stir occasionally, and add more water if needed. (The liquid should barely cover the peas.)|Serve hot over brown rice or eat it as it is. If you like it extra spicy add some Tabasco sauce and enjoy!",
    serves: 9,
    fat: 1.3,
    saturated: 0.5,
    calories: 96,
    cholesterol: 14,
    carbohydrate: 14,
    protein: 7,
    sodium: 370,
    fiber: 3,
    difficulty: "medium",
    category: "dinner|christmas",
  },
  {
    title: "Light ‘n’ Low Pancakes",
    slug: "light-and-low-pancakes",
    img: "light-and-low-pancakes",
    hasImage: true,
    description:
      "These low fat pancakes are deliciously versatile, working well with both savoury and sweet toppings.",
    ingredients:
      "125g (½ cup) brown or wholemeal/whole wheat plain flour|1 egg|300ml (8oz) skimmed milk (if using wholemeal/whole wheat flour, you will need a little more)|1 teaspoon vegetable oil, plus a little extra for cooking, or use an oil-water spray",
    methods:
      "Sift the flour into a bowl. If using wholemeal/whole wheat flour, also add the bran in the sieve to the flour in the bowl.|Beat the egg, milk and oil together, then slowly add to the flour. Stir the mixture until a smooth batter forms.|Leave to stand for about 20 minutes, then stir again.|Heat a little oil in a non-stick frying pan, or spray with an oil-water spray. When the oil is hot, add 2 tablespoons of the pancake mixture and shake the pan so that it spreads.|Cook the pancake for 2 minutes until the underside is lightly browned, then flip or turn over and cook the other side for a minute or so.|Keep the pancake warm in the oven while you cook the rest – you can stack one on top of the other as they are cooked. The mixture should make 8 pancakes in all. Serve with your chosen topping.",
    time: 30,
    serves: 4,
    fat: 3.1,
    saturated: 0.6,
    calories: 162,
    sodium: 140,
    difficulty: "easy",
    heartuk: true,
    category:
      "breakfast|family favorites|light snacks|quick and easy|vegetarian",
  },
  {
    title: "Creamy Porridge With Summer Berries",
    slug: "creamy-porridge-with-summer-berries",
    img: "creamy-porridge-with-summer-berries",
    hasImage: true,
    description:
      "Start your day with this nutritious, yet delicious, breakfast. Oats are a great source of soluble fibre and the berries helps towards your 5 a day. Fresh, frozen, tinned and dried fruit all count.",
    ingredients:
      "600 ml (20oz) water|125 g (1 cup) porridge oats|150 ml (5oz) skimmed milk|1 handful of fresh, frozen and thawed, tinned or cooked berries, such as raspberries, strawberries, blackberries or cranberries",
    methods:
      "Place the water and oats in a saucepan and bring to the boil. Simmer for 10-20 minutes, stirring occasionally.|Add the skimmed milk, stir and simmer for a few more minutes.|Serve with your chosen berries.",
    time: 5,
    serves: 2,
    fat: 5.4,
    saturated: 0.9,
    sodium: 90,
    calories: 296,
    difficulty: "easy",
    heartuk: true,
    category:
      "breakfast|family favorites|light snacks|quick and easy|vegetarian",
  },
  {
    title: "Carrot and Coriander/Cilantro Soup",
    slug: "carrot-and-coriander-soup",
    img: "carrot-and-coriander-soup",
    hasImage: true,
    description:
      "Perfect for a super quick supper or as a light lunch for one.",
    ingredients:
      "1 tablespoon sunflower or rapeseed oil|1 clove garlic, crushed|1 onion, chopped|2 medium carrots, grated|2 celery sticks, chopped|2 tomatoes, chopped|600mls (20oz) low-salt vegetable stock|300mls (10oz) orange juice|3 tablespoons freshly chopped coriander/cilantro|freshly ground black pepper to season",
    methods:
      "Heat the oil, add the garlic, onion, carrot, celery and tomatoes and cook for a few minutes until softened.|Add the stock, season and simmer for 20-25 minutes.|Add the coriander/cilantro and orange juice.|Pulse in a blender, reheat, check the seasoning and serve.|Serve with chunks of wholegrain bread",
    time: 40,
    serves: 4,
    fat: 3.6,
    saturated: 0.3,
    calories: 109,
    sodium: 110,
    difficulty: "easy",
    heartuk: true,
    category: "lunch|light snacks|vegetarian",
  },
  {
    title: "Mediterranean Soup",
    slug: "mediterranean-soup",
    img: "mediterranean-soup",
    hasImage: true,
    description:
      "We can’t get enough of this recipe. It is especially great for using up leftover courgettes/zucchini and peppers. Enjoy! Each portion provided at least 2 of your 5-a-day.",
    ingredients:
      "1 onion, finely chopped |1 clove garlic, crushed |1 tbsp olive oil |1 bell pepper (red or yellow), diced |2 courgettes/zucchini, diced |1 tsp paprika |1 tsp fresh rosemary, chopped |1 tsp Balsamic vinegar |400g (14oz) can chopped or pureed tomatoes |1 litre (4 cups) vegetable stock made from 2 low salt stock cubes |1 tbsp Tomato puree|1 sprig fresh flat-leaf parsley (optional) |Freshly ground pepper",
    methods:
      "Heat the oil in a pan and gently cook the onion and garlic for 5 minutes without colouring. |Set aside 1 tbsp of diced pepper then add the remainder of the pepper along with the courgettes/zucchini, paprika, rosemary to the pan and cook for a few minutes. |Add the balsamic vinegar, after 2 minutes add the tomatoes and stock and bring to the boil. |Gently simmer until the vegetables are cooked through and tender, about 10 minutes.|Finally, stir in the tomato paste. Either leave the soup as a rustic, chunky vegetable broth or use a handheld blender to blend until smooth.|Season with fresh ground pepper and serve topped with the reserved diced pepper and a sprig of parsley.",
    time: 35,
    serves: 4,
    fat: 4.1,
    saturated: 0.6,
    sodium: 220,
    calories: 122,
    difficulty: "easy",
    heartuk: true,
    category: "lunch|light snacks|vegetarian|soup",
  },
  {
    title: "Tasty Tuna Sandwiches",
    slug: "tasty-tuna-sandwiches",
    img: "tasty-tuna-sandwiches",
    hasImage: true,
    description:
      "This lighter take on the classic 'tuna mayo' will be a hit for lunchtimes on the go.",
    ingredients:
      "1 can of tuna in spring water, drained |2 tbsp low fat Greek style yogurt |1 tbsp chopped fresh coriander/cilantro |Ground black pepper |4 slices wholemeal/whole grain bread or 2 rolls |¼ cucumber, diced|¼ red bell pepper, diced|handful fresh salad leaves|Scrape low fat mayonnaise - if needed ",
    methods:
      "Mix with the yogurt with the coriander/cilantro and season with black pepper. Flake in the tuna and add the diced cucumber and red pepper.|Spread the 2 slices of bread with a little low-fat mayonnaise (to help the filling stick to the bread) and then divide the tuna mixture over the slices.|Top the filling with salad leaves and the remaining bread slices and serve. ",
    time: 5,
    serves: 2,
    fat: 4.4,
    saturated: 0.7,
    sodium: 98,
    calories: 282,
    difficulty: "easy",
    heartuk: true,
    category: "lunch|light snacks",
  },
  {
    title: "Mushroom and Lentil Soup",
    slug: "mushroom-and-lentil-soup",
    img: "mushroom-and-lentil-soup",
    hasImage: true,
    description:
      "You can use a variety of speciality mushrooms in this recipe or stick to the cheapest and it still comes out tasting delicious. We used a good quality vegetable stock but you could use your own homemade stock or even substitute a 250mls of the vegetable stock for brown ale.",
    ingredients:
      "1 tablespoon olive oil |2 small onions, roughly chopped|2 sticks celery, roughly chopped|1 carrot, roughly chopped|400g (4 cups) mushrooms, roughly sliced|100g (1 cup) green lentils, pre-cooked in boiling water|1 litre (4 cups) vegetable stock, made from 2 low-salt vegetable stock cubes|1 bay leaf |1 tbsp Worcester sauce|Ground black pepper|Optional to serve - 4 tsp toasted pine nuts |Optional to serve - 4 tsp soy yogurt",
    methods:
      "Sweat the onions in the olive oil for a few minutes until softened, taking care not to colour them. |Add the celery and carrot and continue to stir and cook for a few minutes on a low heat |Set aside a few slices of mushrooms as a garnish, then add the remaining mushrooms, cooked lentils, vegetable stock, Worcester Sauce and bay leaf to the pan and simmer until all the vegetables are cooked and tender |Remove the bay leaf and using a hand blender puree the soup |Season with black pepper and serve, garnished with reserved sliced mushrooms, pine nuts and yogurt, if using.",
    time: 30,
    serves: 4,
    fat: 4.6,
    saturated: 0.7,
    sodium: 280,
    calories: 161,
    difficulty: "easy",
    heartuk: true,
    category: "lunch|light snacks|vegetarian|soup",
  },
  {
    title: "Red Super Soup",
    slug: "red-super-soup",
    img: "red-super-soup",
    hasImage: true,
    description:
      "This soup came about as a result of a great conversation between our helpline team.  It has a base of tomatoes, lentil, onions and garlic but the great thing is you can add other ingredients to suit.  We included all the leftover “red vegetables” in this version and it turned out a fantastic deep red and pretty tasty too.   It can be served chunky or pureed to a smooth soup, whichever you prefer!",
    ingredients:
      "1 tablespoon olive oil |2 small red onions, roughly chopped |2 cloves garlic, crushed |1 stick celery, roughly chopped |1 carrot, roughly chopped |250g (2 cups) red cabbage, finely shredded |50g (⅓ cup) red pepper, roughly chopped |100g (1 cups) red lentils, precooked in boiling water |2 cans (28oz) chopped tomatoes, (pureed if you are not going to blend the final soup)|½ litre (2 cups) vegetable stock made from 1.5 cubes of reduced salt stock cubes|1 tbsp balsamic vinegar |Ground black pepper |Flat Leaf Parsley, to garnish (Optional)",
    methods:
      "Sweat the onions in the olive oil for a few minutes until softened, taking care not to colour them. |Add the celery and carrot and continue to stir and cook for a few minutes on a low heat |Set aside a few slices of mushrooms as a garnish, then add the remaining mushrooms, cooked lentils, vegetable stock, Worcester Sauce and bay leaf to the pan and simmer until all the vegetables are cooked and tender |Remove the bay leaf and using a hand blender puree the soup |Season with black pepper and serve, garnished with reserved sliced mushrooms, pine nuts and yogurt, if using.",
    time: 30,
    serves: 4,
    fat: 4.7,
    calories: 230,
    saturated: 0.6,
    sodium: 140,
    difficulty: "easy",
    heartuk: true,
    category: "lunch|light snacks|vegetarian|soup",
  },
  {
    title: "Mung Dhal with Spinach",
    slug: "mung-dhal-with-spinach",
    img: "mung-dhal-with-spinach",
    hasImage: true,
    description:
      "Simple, wholesome ingredients combine to make a brilliant dahl recipe. Make in one batch then freeze any leftovers.",
    ingredients:
      "150g (1 cup) mung dahl (split or husked mung beans/peas)|100g (1 cup) new potatoes, halved and boiled until tender |250g (2 cup) spinach, washed and finely chopped |1 tbsp rapeseed oil |1 tsp chopped freshv ginger |1-2 green chillies chopped |½ tsp cumin seeds |½ tsp turmeric |Juice of ½ lime ",
    methods:
      "Wash dhal in several changes of water. |Heat the oil in a pan and fry the ginger, green chillies and cumin for 1 minute. |Add the dhal and spinach and cook for another 1-2 minutes. |Add the turmeric and 1½ litres water. |Cook, uncovered on low heat, stirring from time to time till dhal tender. You may need to add extra water. Stir in lime juice and cooked potatoes and warm through. Serve with pitta bread or rice. ",
    time: 60,
    serves: 6,
    fat: 2.7,
    calories: 95,
    saturated: 0.2,
    sodium: 160,
    difficulty: "medium",
    heartuk: true,
    category: "dinner|vegetarian",
  },
  {
    title: "Fish Tikkas",
    slug: "fish-tikkas",
    img: "fish-tikkas",
    hasImage: true,
    description:
      "Marinate white fish in a low-fat tikka masala dressing and serve with salad to make this Indian-inspired supper. You can use any sustainably-sourced firm white fish in this recipe.",
    ingredients:
      "1 kg (2.2lbs) cod fillets (or any firm fish), cut into cubes|1 tablespoon lemon juice|½ cup low fat natural yogurt|1 tablespoon vinegar|1 tablespoon garam marsala|2 teaspoons ground cumin seeds|1 teaspoon red chilli powder|2 teaspoons garlic paste|vegetable oil ",
    methods:
      "Wash dhal in several changes of water.|Heat the oil in a pan and fry the ginger, green chillies and cumin for 1 minute. |Add the dhal and spinach and cook for another 1-2 minutes. |Add the turmeric and 1½ litres water. |Cook, uncovered on low heat, stirring from time to time till dhal tender. You may need to add extra water. Stir in lime juice and cooked potatoes and warm through. Serve with pitta bread or rice. ",
    time: 25,
    serves: 6,
    fat: 3.1,
    calories: 165,
    saturated: 0.5,
    sodium: 610,
    difficulty: "medium",
    heartuk: true,
    category: "dinner|fish",
  },
  {
    title: "Tandoori Chicken with Herb Yoghurt Sauce",
    slug: "tandoori-chicken-with-herb-yoghurt-sauce",
    img: "tandoori-chicken-with-herb-yoghurt-sauce",
    hasImage: true,
    description:
      "This wonderful tandoori marinade has no added fat or sugar and can be used for whole fish, lamb or fish fillets – just adjust the cooking time as necessary.",
    ingredients:
      "4 boneless, skinless chicken breasts, about 150g (1 cup) each|Wine or water|A few herb sprigs, such as rosemary, thyme or parsley|1 tbsp grated fresh root ginger|2 tsp coriander/cilantro seeds, toasted|2 tsp rosemary leaves|1 tsp grated lemon rind|½ tsp ground cardamom|½ tsp ground cumin|¼ tsp crushed black peppercorns|¼ tsp chilli sauce or powder|325g (1 cups) low fat natural yoghurt|2 tbsp lemon juice|125g (1 cup) grated cucumber|1 tbsp chopped dill or mint|Freshly ground black pepper",
    methods:
      "Mix together all the ingredients for the marinade in a bowl.|Place the chicken in a non-metallic dish. Spoon over the marinade and rub well into the chicken. Cover and refrigerate for 2-4 hours.|Scrape the excess marinade from the chicken. Place the chicken on a wire rack set in a roasting tray. Pour in wine or water to the depth of 2.5cm (1 inch) and add the herb sprigs, to keep the meat moist during cooking.|Bake the chicken in a preheated oven, 240 C (475 F), Gas Mark 9, for 10 minutes. Turn over and bake for a further 10 minutes until cooked through.|While the chicken is cooking, mix all the ingredients for the herb yoghurt sauce in a bowl. Cover and chill in the fridge for about 20 minutes to allow all the flavours to develop.|Serve the chicken with the herb yoghurt sauce, and accompany with basmati rice and a green salad.",
    time: 30,
    serves: 4,
    fat: 3,
    calories: 222,
    saturated: 1,
    sodium: 380,
    difficulty: "medium",
    heartuk: true,
    category: "dinner|family favorites",
  },
  {
    title: "Fish Curry",
    slug: "fish-curry",
    img: "fish-curry",
    hasImage: true,
    description:
      "Whip up a curry-in-a-hurry with this aromatic fish dish then serve with rice or pitta bread and salad for a healthy supper. Make sure to use a sustainably-sourced white fish.",
    ingredients:
      "450g/1lb white fish – cod or haddock, boned and cut into chunks |2 tablespoons lemon juice |1 tablespoon rapeseed oil |2 small onions, chopped |1 teaspoon crushed garlic |1 teaspoon crushed ginger |1 teaspoon green chilli, finely chopped |200 ml (7oz) canned tomatoes |½ teaspoon turmeric powder |1 teaspoon salt |1 teaspoon garam masala |2 tablespoons chopped fresh coriander/cilantro for garnish",
    methods:
      "Mix together all the ingredients for the marinade in a bowl.|Place the chicken in a non-metallic dish. Spoon over the marinade and rub well into the chicken. Cover and refrigerate for 2-4 hours.|Scrape the excess marinade from the chicken. Place the chicken on a wire rack set in a roasting tray. Pour in wine or water to the depth of 2.5cm (1 inch) and add the herb sprigs, to keep the meat moist during cooking.|Bake the chicken in a preheated oven, 240 C (475 F), Gas Mark 9, for 10 minutes. Turn over and bake for a further 10 minutes until cooked through.|While the chicken is cooking, mix all the ingredients for the herb yoghurt sauce in a bowl. Cover and chill in the fridge for about 20 minutes to allow all the flavours to develop.|Serve the chicken with the herb yoghurt sauce, and accompany with basmati rice and a green salad.",
    time: 30,
    serves: 4,
    fat: 4.4,
    calories: 147,
    saturated: 0.4,
    sodium: 1400,
    difficulty: "medium",
    heartuk: true,
    category: "dinner|family favorites|fish|hearty meal",
  },
  {
    title: "Thai-Style Monkfish & Mushroom Kebabs",
    slug: "thai-style-monkfish-mushroom-kebabs",
    img: "thai-style-monkfish-mushroom-kebabs",
    hasImage: true,
    description:
      "Monkfish is a firm fish with a delicate flavour which works perfectly with this Thai style marinade. A wonderful infusion of flavours and so simple it can be enjoyed any night of the week.",
    ingredients:
      "500g-750g (1-1.5 lbs) monkfish tails, skinned|1 red onion, quartered and layers separated|8 mushrooms|1 courgette, cut into 8 pieces|1 yellow pepper, deseed and cut into chunks|4 cherry tomatoes|Vegetable oil, for brushing|Watercress or flat leaf parsley, to garnish|Grated rind and juice of 2 limes|1 garlic clove, finely chopped|2 tbsp finely sliced fresh root ginger|2 fresh chillies, red or green or 1 of each, deseeded and finely chopped|2 lemon grass stalks, finely chopped|1 cup of red wine|2 tbsp sesame oil|freshly ground black pepper",
    methods:
      "Combine the ingredients for the marinade in a large bowl. Cut the fish into large cubes and add to the marinade with the onion, mushrooms and courgette. Cover and refrigerate for 1 hour to allow the flavours to blend.|Brush the rack of a grill pan lightly with oil to prevent the kebabs from sticking. Thread 4 skewers with the chunks of fish, mushrooms, courgette and onion alternately with a tomato in the middle. Brush with a little oil and grill under a preheated hot grill for about 10 minutes, turning at intervals. Garnish with watercress or flat leaf parsley.",
    time: 28,
    serves: 6,
    fat: 8,
    calories: 249,
    saturated: 1.2,
    sodium: 90,
    difficulty: "easy",
    heartuk: true,
    category: "dinner|family favorites|fish|hearty meal",
    notes:
      "You can use any firm white fish such as halibut, sea bass, swordfish, cod or haddock, if you prefer.",
  },
  {
    title: "Tuna Pasta Bake",
    slug: "tuna-pasta-bake",
    img: "tuna-pasta-bake",
    hasImage: true,
    description:
      "A family favorite, this tasty and filling supper is ready in 45 minutes and makes the perfect comfort food with none of the guilt.",
    ingredients:
      "400g (3 cups) wholemeal/whole grain pasta shapes (we used Fusilli)|2 x 160g (6oz) tins tuna in water, drained |100g (1 cup) of spinach |1 can sweetcorn, drained  |75g (½ cup) wholemeal/whole grain breadcrumbs |1 x 500ml (16oz) jar of tomato sauce  |100g (¾ cup) 10% fat/reduced fat mozzarella cheese, grated or cut into small pieces  |Black pepper ",
    methods:
      "Warm the oven to 200C (180C for a fan oven) .|Cook the pasta in a large pan (following the pack instructions) until cooked but still firm - “al dente”.|Add  the spinach to the pan of pasta for the last minute and allow to wilt.|Drain and transfer the pasta and spinach to a large baking dish.|Mix in the tuna, sweetcorn and tomato sauce and season with black pepper.|Top with the mozzarella and finally with the breadcrumbs.|Bake for at least 30 minutes or until bubbling.|Serve .",
    time: 45,
    serves: 6,
    fat: 5.3,
    calories: 397,
    saturated: 1.9,
    sodium: 150,
    difficulty: "medium",
    heartuk: true,
    category: "dinner|family favorites|fish|hearty meal",
    notes:
      "Try using other vegetables favorites including peas, mixed vegetables, broccoli florets, asparagus tips, mushrooms or bell peppers.",
  },
  {
    title: "Prawn and Vegetable Curry",
    slug: "prawn-and-vegetable-curry",
    img: "prawn-and-vegetable-curry",
    hasImage: true,
    description:
      "Who says you can’t cook a healthy meal in under 30 minutes, try this tasty curry one for a quick and easy mid-week meal! ",
    ingredients:
      "4 cloves garlic |1 onion |1 tbsp olive oil |1 apple, grated |2½ tbsp curry powder |1 tsp garam masala |½ tsp turmeric |400g (14oz can) chopped tomatoes, tinned |½ red chilli, chopped finely |2 tsp sweetener |110g (1 cup) tinned chickpeas, drained and rinsed|200g (1½ cups) frozen prawns |50ml (1.7oz) soy milk |Few coriander/cilantro leaves|265g (2 cups) basmati rice",
    methods:
      "Measure and pour out the basmati rice, cover with the boiled water and cook according to packet instructions. |Cook the garlic and onion in olive oil over a medium heat until golden. Add the curry powder, garam masala and turmeric and cook for a further minute. Add in the grated apple, chopped tomatoes and red chilli and stir to combine then add 200ml of water and stir again. Bring up to the simmer and cook for a further 2 minutes.|Add the chickpeas and frozen prawns and cook until they soften and are hot all the way through. Once hot, stir in the sweetener and soy milk.|Serve the rice and curry in a bowl and garnish with coriander/cilantro leaves.",
    time: 25,
    serves: 4,
    fat: 5.8,
    calories: 416,
    saturated: 0.9,
    sodium: 540,
    difficulty: "medium",
    heartuk: true,
    category: "dinner|family favorites|fish|hearty meal",
  },
  {
    title: "Summer Pudding",
    slug: "summer-pudding",
    img: "summer-pudding",
    hasImage: true,
    description:
      "This traditional low-fat Summer pudding makes the most of all the gorgeous seasonal fruit. But it doesn’t have to be just for Summer, use frozen fruit when Summer berries are no longer in season. ",
    ingredients:
      "300g (2½ cups) stale medium-sliced white bread|875g (7 cups) mixed ripe summer fruits, such as redcurrants, whitecurrants, blackcurrants, raspberries, strawberries and cherries, prepared separately|75g (3oz) caster sugar|75ml (3oz) water|Extra fruit and fruit leaves, to decorate",
    methods:
      "Cut the crusts off the bread and use to line the base and sides of a 1.2 litre (2 pint) pudding basin, fitting the pieces of bread together closely and trimming to fit. Reserve sufficient bread for the top.|Place the fruit, except raspberries and strawberries, in a saucepan with the sugar and water. Heat gently, until the juice begins to run from the fruit and the sugar melts. Remove from the heat, and add the remaining fruit. Drain the fruit through a nylon sieve, reserving the juice. Spoon the fruit into the lined basin with half the juices and cover with the reserved bread for the top.|Press the top with a weighted saucer (cover the weight with clingfilm to prevent tainting the pudding). Stand the basin in a shallow dish to catch any escaping juices and then refrigerate overnight. Cover and refrigerate the remaining juices separately.|To serve, remove the weight and run a blunt-edged knife around the pudding. Invert on to an edged dish, shaking gently to release the pudding. Carefully lift off the basin. If the bread looks patchy, brush with the reserved juices. Serve any remaining juices separately. Decorate the pudding with fruit and leaves. Serve with low-fat natural yoghurt or fromage frais.",
    time: 30,
    serves: 6,
    fat: 1.2,
    calories: 221,
    saturated: 0,
    sodium: 510,
    difficulty: "medium",
    heartuk: true,
    category: "dessert|family favorites",
  },
  {
    title: "Chocolate shredded nests",
    slug: "chocolate-nests",
    img: "chocolate-nests",
    hasImage: true,
    description:
      "A great alternative to the traditional chocolate nests. Far lower in saturated fat and a good source of fibre. What’s not to enjoy! So simple kids will love to help make (and eat!).",
    ingredients:
      "4 shredded wheat, crushed|2tbsp coco powder|4tbsp brown sugar|8tbsp/4oz skim milk|16 chocolate mini eggs",
    methods:
      "Place 16 fairy cake cases on to a tray|Add cocoa powder, brown sugar and milk to a small saucepan|Heat on a medium heat, constantly whisking, until thickens and is silky in appearance, like a chocolate caramel sauce|Remove from heat|Add the crushed wheat and mix with a wooden spoon until all the wheat strands are coated|Spoon the mixture evenly into the fairy cake cases|Using a square of greaseproof paper, gently push down so the grains are packed together|Top each nest with 1 mini egg|Place the tray in the freezer for 1 hour to set",
    time: 20,
    serves: 16,
    fat: 0.9,
    calories: 55,
    saturated: 0.5,
    sodium: 20,
    difficulty: "easy",
    heartuk: true,
    category: "dessert|family favorites",
  },
  {
    title: "Island Chicken Dinner",
    slug: "island-chicken-dinner",
    img: "island-chicken-dinner",
    hasImage: true,
    description: "It’s like dinner in Hawaii.",
    ingredients:
      "1 lb. (16oz) chicken breast tenders |1 onion, chopped |1 each green, red, and yellow pepper, sliced |1 small can water chestnuts, roughly cut |1/2 cup (113ml) Teriyaki Sauce |1 small can pineapple chunks | Served over 1 cup (200g) brown rice",
    methods:
      "Sauté chicken tenders in a large sauté pan, prepared with nonstick cooking spray, until no longer pink. |Remove from pan. |Replace with onion and peppers. Sauté until crisp tender. |Add cooked chicken, water chestnuts, pineapple chunks, and teriyaki sauce. |Cook over medium heat until thoroughly heated. |Serve over brown rice.",
    serves: 4,
    fat: 4,
    calories: 462,
    cholesterol: 73,
    carbohydrate: 22,
    saturated: 1,
    sodium: 680,
    difficulty: "easy",
    category: "dinner|family favorites",
  },
  {
    title: "Italian Chicken Soup",
    slug: "italian-chicken-soup",
    img: "italian-chicken-soup",
    hasImage: true,
    description:
      "This is a tasty, low-fat but hearty soup that my family loves. Goes well with crusty French bread.",
    ingredients:
      "1 small onion, chopped |1 green bell pepper, diced |3 cloves garlic |1 tbsp. dried basil |1/4 tsp. crushed red pepper (optional) |1 cup chopped raw carrots |1 large zucchini/courgette, diced |1 can garbanzo beans, drained |1 can diced tomatoes |3 cans low-sodium, fat-free chicken broth |1 package Light Four Cheese Ravioli |1½ cups (190g) diced cooked chicken breast |reduced-fat Parmesan cheese |nonstick cooking spray ",
    methods:
      "Spray large sauce pan with nonstick cooking spray. |Add onion, bell pepper, garlic, basil and red pepper and sauté, over medium heat, until tender, about 10 minutes. |Add broth. Cover pot and simmer 10 minutes. |Add carrots and zucchini/courgette. |Cover and simmer until carrot is almost tender, about 5 minutes. |Increase heat to high and bring soup to a boil. Add ravioli and boil until tender, about 5 minutes. |Add chicken, beans and tomatoes and cook until heated through, about 1 minute. |Ladle soup into bowls, sprinkle with a little Parmesan cheese and enjoy.",
    serves: 6,
    fat: 6,
    calories: 303,
    cholesterol: 51,
    carbohydrate: 39,
    saturated: 3.7,
    sodium: 543,
    difficulty: "easy",
    category: "dinner|family favorites|hearty meal",
  },
  {
    title: "Italian Dressing",
    slug: "italian-dressing",
    img: "italian-dressing",
    hasImage: true,
    description:
      "The chopped red pepper adds bright color to this aromatic dressing.",
    ingredients:
      "1/4 cup (56ml) white wine vinegar |2 tablespoons red wine vinegar |1 large garlic clove, pressed |2 tablespoons finely chopped shallot |2 tablespoons finely chopped red bell pepper |2 teaspoons Dijon-style mustard |1 teaspoon honey |3/4 teaspoon table salt |1/4 teaspoon dried oregano |1/4 teaspoon dried parsley |Pinch of red pepper flakes (optional) |Freshly ground black pepper, to taste ",
    methods:
      "Combine the ingredients in a bowl and whisk until combined. | Taste and adjust seasonings as desired. ",
    time: 10,
    serves: 4,
    fat: 0,
    calories: 19,
    cholesterol: 0,
    carbohydrate: 3,
    saturated: 0,
    sodium: 496,
    fiber: 0,
    difficulty: "easy",
    category: "quick and easy",
  },
  {
    title: "Italian Meatballs",
    slug: "italian-meatballs",
    img: "italian-meatballs",
    hasImage: true,
    description:
      "Add this recipe to your weekly dinner routine and it is sure to become a family favorite!",
    ingredients:
      "1 pound “Extra Lean” ground beef (at least 95% fat-free) |1/2 cup (63g) plain breadcrumbs |1/4 cup (56ml) fat-free milk |2 egg whites |1/4 cup (40g) onion, minced |1 clove garlic, minced |1/2 teaspoon oregano |1/8 teaspoon pepper |4 cups (900g) of your favorite fat-free tomato sauce",
    methods:
      "Preheat oven to 350°F (176°C).|In a large bowl mix all ingredients except tomato sauce.|Roll into small balls (approx. 1.5 inch) and place on cookie sheet that has been lined with non-stick foil.|Bake for 20-25 minutes or until internal temperature has reached minimum of 160 degrees F; turn meatballs halfway through cooking time.|In large saucepan, add tomato sauce and bring to a simmer.|Add meatballs and simmer for 15- 20 minutes.|Serve over your favorite pasta.|Makes 24 meatballs.",
    time: 50,
    serves: 8,
    fat: 3.3,
    calories: 155,
    cholesterol: 35,
    carbohydrate: 14,
    saturated: 1.4,
    sodium: 630,
    fiber: 2,
    difficulty: "easy",
    category: "dinner|family favorites",
  },
  {
    title: "Italian Style Spaghetti Squash Bake ",
    slug: "italian-style-spaghetti-squash-bake",
    img: "italian-style-spaghetti-squash-bake",
    hasImage: true,
    description: "A wonderful change from pasta!",
    ingredients:
      "1 medium Spaghetti Squash (about 4 cups (500g) of squash) |1 zucchini/courgette squash, shredded |16 ounce (453g) low fat prepared pasta sauce (e.g. Barilla Basil and Garlic or other vegetable or herb type) |8 ounce (226g) can of mushrooms |1/2 cup (50g) 2% fat (reduced fat) shredded mozzarella cheese |Grated Parmesan cheese to taste (not included in analysis) |Salt, pepper and garlic powder to taste |Italian seasoning to taste ",
    methods:
      "Preheat oven to 350°F (176°C).|Cut squash in half lengthwise and remove seeds. |Place approximately 1/2 inch of water at bottom of a 12 inch by 8 inch baking dish and place squash (cut side down) in the dish and microwave for 12-15 minutes or until a fork can be pierced through the squash; cool for a few minutes. |In the same baking dish (sprayed with non-fat cooking spray) scoop out spaghetti squash using a large fork, into the pan and spread out evenly over the entire dish. Add shredded zucchini/courgette. |Drain mushrooms and spread evenly over top of squash. |Add salt (if desired), pepper, garlic powder and Italian seasoning over the mixture to taste. |Spread spaghetti sauce evenly over the squash mixture. |Distribute shredded mozzarella cheese evenly over top; then sprinkle w parmesan. Bake for approximately 25 minutes or until cheese is melted and dish is bubbling. |Let cool for a few minutes before serving. Delicious as entrée or side dish. ",
    time: 50,
    serves: 6,
    fat: 2.2,
    calories: 103,
    cholesterol: 5,
    carbohydrate: 17,
    saturated: 1.0,
    sodium: 630,
    fiber: 3.5,
    difficulty: "easy",
    category: "dinner|family favorites|hearty meal",
  },
  {
    title: "Jumbo Stuffed Shells",
    slug: "jumbo-stuffed-shells",
    img: "jumbo-stuffed-shells",
    hasImage: true,
    description: "Good enough to serve company!",
    ingredients:
      "16 oz. (453g) box of jumbo shells |2½ cups (440g) broccoli ﬂorets, steamed and minced (may use 10 oz. (283g) frozen package)| 2 egg whites |15 oz. (425g) nonfat ricotta cheese |4 tbsp. Parmesan cheese |1/4 tsp. garlic powder |1/2 tsp. nutmeg|1/4 tsp. black pepper|1/2 tsp. oregano|4 cups (900g) tomato sauce (use low sodium, if desired)|1 cup (75g) or more fresh mushrooms, sliced",
    methods:
      "Preheat oven to 350°F (176°C).|Cook shells according to package directions.| In a large bowl, mix cheeses, spices and egg whites. Stir in broccoli. |Use tablespoon to ﬁll shells.|Prepare a 13”x9” glass baking pan with nonstick cooking spray. |Put in 1/3 of the tomato sauce. |Arrange shells in the pan and pour in the rest of the sauce and mushrooms. |Bake for 35-40 minutes.",
    time: 60,
    serves: 6,
    fat: 2.3,
    calories: 242,
    cholesterol: 5,
    carbohydrate: 36,
    saturated: 1,
    sodium: 198,
    difficulty: "easy",
    category: "dinner|family favorites|hearty meal",
  },
  {
    title: "Justin’s Low Fat French Toast",
    slug: "justins-low-fat-french-toast",
    img: "justins-low-fat-french-toast",
    hasImage: true,
    description:
      "Treat yourself to a delicious weekend morning breakfast or lunch!",
    ingredients:
      "1/2 cup egg whites (two large eggs) |1/2 cup (113ml) skim milk |2 slices low-fat raisin bread |Fat-free cooking spray |2 tablespoons pure maple syrup |1/2 teaspoon cinnamon |Fat-free whipped topping |3 strawberries, stemmed and halved",
    methods:
      "Whisk the egg whites and skim milk with cinnamon in a container large enough to dip bread into. |Heat non-stick skillet on medium heat. |Coat with cooking spray. |Dip the raisin bread into the egg mix. |Add the bread to the pan, allow the eggs to stick to the bread and start to become slightly crispy. |Flip the bread over and do the same to the opposite side. |Place on your serving plate and top with syrup, whipped topping and the strawberries cut in half. ",
    time: 30,
    serves: 1,
    fat: 3.7,
    calories: 395,
    cholesterol: 6,
    carbohydrate: 72,
    saturated: 1.4,
    sodium: 516,
    fiber: 4,
    difficulty: "easy",
    category: "breakfast|family favorites|quick and easy",
  },
  {
    title: "Lazy Lasagna",
    slug: "lazy-lasagna",
    img: "lazy-lasagna",
    hasImage: true,
    description:
      "No cooking of noodles necessary! This is a tasty dish for any pasta lover.",
    ingredients:
      "12 lasagna noodles|2 cups (200g) shredded nonfat mozzarella cheese|1/4 cup (25g) grated reduced-fat Parmesan cheese|12 oz (340g) 99% lean ground turkey|2 cans (1 pound each) tomato sauce|1 can (1 pound) crushed tomatoes, unsalted|2 tablespoons tomato paste|2 cups (150g) sliced fresh mushrooms|1 medium onion, chopped|2 teaspoons garlic, minced|2½ teaspoons dried Italian seasoning|15 oz (425g) nonfat ricotta cheese|1 cup (225g) reduced-fat cottage cheese|1/4 cup (25g) reduced-fat grated Parmesan cheese|2 teaspoons dried parsley",
    methods:
      "Place ground turkey meat in a pot. Cook over medium heat, stirring to a crumble, until the meat is no longer pink. Drain off any fat.|Add the rest of the sauce ingredients to the browned meat, increase the heat to high and bring to a boil. |Reduce the heat to low, cover and simmer for 25 minutes. Set aside.|Combine all of the filling ingredients in a large bowl and stir well. Set aside.|Coat a 9x13 pan with nonstick cooking spray. |Spoon 1 cup (200ml) of the sauce over the bottom of the pan. Lay 4 of the uncooked noodles over the bottom of the pan, arranging 3 of the noodles lengthwise and 1 noodle crosswise (you may need to break this one to make it fit).|Top the noodles with half of the filling mixture, 3/4 cup (75g) of the mozzarella, and 1.5 cups (300ml) of the sauce. Repeat the noodles, filling, mozzarella, and sauce layers. |Top with the remaining noodles, sauce, Parmesan, and mozzarella.|Cover the pan with aluminum foil and bake at 350°F (176°C) for 45 minutes. |Remove the foil and bake for an additional 15 minutes. Let cool for about 5 minutes, cut, and serve!",
    time: 90,
    serves: 10,
    fat: 2,
    calories: 277,
    cholesterol: 40,
    carbohydrate: 36,
    saturated: 0.8,
    sodium: 998,
    fiber: 4,
    difficulty: "medium",
    category: "dinner|family favorites|hearty meal",
  },
  {
    title: "Lemon drop cookies",
    slug: "lemon-drop-cookies",
    img: "lemon-drop-cookies",
    hasImage: true,
    description:
      "These cookies only require 5 ingredients and are soft and chewy. Satisfies your appetite when you want something sweet.",
    ingredients:
      "1 box lemon cake mix |1/2 cup (30g) fat-free Cool Whip |Splash lemon extract |1 egg, beaten |confectioner’s sugar",
    methods:
      "Mix all ingredients, except confectioner’s sugar.|The mix will be a bit crumbly and you will need to mix well. |Roll into 1 inch balls and then roll around in bowl of confectioner’s sugar.|Spray cookie sheet with cooking spray.|Bake at 350°F (176°C) for 10-12 minutes. |Makes 32 cookies",
    time: 20,
    serves: 16,
    fat: 1,
    calories: 107,
    cholesterol: 13,
    carbohydrate: 23,
    saturated: 1,
    sodium: 205,
    fiber: 1,
    difficulty: "easy",
    category: "light snacks|family favorites|dessert|quick and easy",
  },
  {
    title: "Low Fat Chicken Enchiladas",
    slug: "low-fat-chicken-enchiladas",
    img: "low-fat-chicken-enchiladas",
    hasImage: true,
    description: "Fun and low-fat lunch or dinner!",
    ingredients:
      "1/2 cup (115g) salsa |1/2 cup (113ml) canned low sodium, fat free chicken broth |1 teaspoon ground cumin |3- 8 ounce (226g) cans of 'no-salt-added' tomato sauce |3 cups (375g) cubed cooked chicken breast |3 tablespoons chopped pickled jalapeño peppers |1- 15 ounce (425g) can no-salt added black beans, rinsed and drained |12- 6-inch corn tortillas |1½ cups (173g) Mexican Blend, shredded lite cheese |3/4 cup (184g) fat free sour cream/fat free greek yogurt |1/2 cup (75g) chopped green onions/spring onion",
    methods:
      "Preheat oven to 350°F (176°C).|Combine the first 4 ingredients; stir well. Spread 3/4 cup mixture in the bottom of a 13x9x2 inch baking dish.|Combine the chicken, jalapeno, black beans and 1/2 cup tomato sauce mixture in a medium bowl. Stir well.|Spoon chicken mixture evenly down centers of tortillas.|Roll up tortillas; place, seam side down, on sauce mixture in baking dish.|Top with remaining sauce mixture and cheese.|Cover and bake for 25 minutes.|Top with sour cream/greek yogurt and sprinkle with green onions/spring onions.",
    time: 40,
    serves: 6,
    fat: 8.8,
    calories: 439,
    cholesterol: 67,
    carbohydrate: 51,
    saturated: 3.0,
    sodium: 554,
    fiber: 11,
    difficulty: "easy",
    category: "dinner|family favorites",
  },
  {
    title: "Low Fat Chili",
    slug: "low-fat-chili",
    img: "low-fat-chili",
    hasImage: true,
    ingredients:
      "3 cloves garlic, minced|1 small can (5 ounce (141g)) green chile peppers diced|1 medium onion, chopped|1 green pepper, chopped|1 pound 95% lean ground beef|2-15 ounce (425g) cans dark red kidney beans|15 ounce (425g) can diced tomatoes|2 oz (56g) tomato paste|Chili powder, salt and pepper to taste|1/2 tablespoon cumin|1/2 tablespoon dried oregano|1/2 tablespoon chili powder|1 teaspoon oil",
    methods:
      "In a 12” skillet, sauté the onion, garlic and green pepper in 1 teaspoon of oil until soft and lightly browned.| Add the meat to the skillet and brown. Drain off any excess fat. |Add the remaining ingredients and bring to a boil; then reduce heat and simmer for 40 minutes.",
    time: 55,
    serves: 4,
    fat: 7.3,
    calories: 404,
    cholesterol: 70,
    carbohydrate: 52,
    saturated: 2.8,
    sodium: 720,
    fiber: 17,
    difficulty: "easy",
    category: "dinner|family favorites|hearty meal",
  },
  {
    title: "Low-Fat Green Bean Casserole",
    slug: "low-fat-green-bean-casserole",
    img: "low-fat-green-bean-casserole",
    hasImage: true,
    description: "A delicious version of a traditional holiday favorite!",
    ingredients:
      "4 cups (600g) cooked green beans, cut into 11/2-inch slices|15 oz. (425g) can of Campbell’s Healthy Request Cream of Mushroom condensed soup|1/2 cup (113ml) skim milk|1 tsp. dried minced onion|Dash black pepper|1 tsp. canola oil|1 medium sweet onion, sliced thin |1/2 cup (63g) Panko bread crumbs",
    methods:
      "Preheat oven to 350°F (176°C).|Place green beans in 11/2-2 quart casserole dish. |In a bowl, mix condensed soup, milk, dried minced onion, and dash black pepper. |Pour over green beans. |Heat 1 tsp. canola oil in nonstick pan and cook onions until golden. |Spread over top green bean mixture. |Sprinkle Panko bread crumbs on top. |Bake for 30 minutes or until golden brown.",
    time: 50,
    serves: 6,
    fat: 2,
    calories: 109,
    cholesterol: 4,
    carbohydrate: 18,
    saturated: 0.4,
    sodium: 281,
    fiber: 4,
    difficulty: "medium",
    category: "dinner|family favorites|hearty meal",
  },
  {
    title: "Low Fat Mac & Cheese",
    slug: "low-fat-mac-and-cheese",
    img: "low-fat-mac-and-cheese",
    hasImage: true,
    ingredients:
      "2 cups (200g) dry macaroni noodles|2 Tablespoons light canola butter|1 Tablespoon flour|1¼ cup (280ml) cold skim milk|1 package of Laughing Cow White Cheddar cheese|1/4 cup (29g) fat free shredded cheese|Salt and pepper to taste",
    methods:
      "Preheat oven to 350°F (176°C).|Cook noodles according to package directions and set aside in an oven safe dish.|In a small saucepan melt butter on medium heat and blend in the flour. Add the milk and stir well. Cook sauce on low until smooth and boiling. Add all 8 wedges of cheese and stir until smooth. |Add the cheese sauce to the oven safe dish with the cooked noodles and blend well. |Top with shredded cheese and bake for 20 minutes.",
    time: 35,
    serves: 8,
    fat: 3.2,
    calories: 173,
    cholesterol: 8,
    carbohydrate: 25,
    saturated: 1.9,
    sodium: 280,
    fiber: 1,
    difficulty: "easy",
    category: "dinner|family favorites|quick and easy",
  },
  {
    title: "Low Fat Strawberry Shortcake",
    slug: "low-fat-strawberry-shortcake",
    img: "low-fat-strawberry-shortcake",
    hasImage: true,
    description:
      "Quick and easy summer dessert recipe that the whole family will enjoy!",
    ingredients:
      "1 package of 6 yellow sponge cake dessert shells (6 per package)|2 cups (400g) sliced strawberries (fresh or frozen)|2 Tablespoons sugar|1/2 teaspoon vanilla extract|1 can Reddi-wip Fat Free whipped cream",
    methods:
      "Add Strawberries, sugar, and vanilla extract to a pot|Heat over medium heat, stirring regularly for about 4 minutes (until liquid is covering bottom of pot)|Take off heat and put in a bowl|Put strawberry mixture into the freezer for ten minutes|Build the strawberry shortcake with dessert shell on bottom, then |Add 1/6 of strawberry mixture to each shell |Add 2 Tablespoons of Reddi-wip Fat Free whipped cream on top of each shell |And Enjoy!",
    time: 15,
    serves: 3,
    fat: 3.6,
    calories: 277,
    cholesterol: 41,
    carbohydrate: 56,
    saturated: 0,
    sodium: 285,
    fiber: 2,
    difficulty: "easy",
    category: "dessert|family favorites|quick and easy",
  },
  {
    title: "Low-Fat Chicken and Dumplings",
    slug: "low-fat-chicken-and-dumplings",
    img: "low-fat-chicken-and-dumplings",
    hasImage: true,
    description: "A great low-fat way to enjoy this traditional dish.",
    ingredients:
      "1 lb. (16oz) boneless skinless chicken breasts, cut into cubes |3 cans (14.5 oz. each) reduced-sodium chicken broth |3 cups (675ml) water |3 large carrots, chopped |1 medium onion, chopped |2 celery ribs (stalks), chopped |1 tsp. parsley |1/2 tsp. salt |1 garlic clove, minced |3 egg whites |1/2 cup (115g) 1% cottage cheese |2 tbsp. water |1 cup (140g) all-purpose flour ",
    methods:
      "In a large nonstick skillet, fill with  about 1/4 inch water. Cook chicken  until no longer pink. Add the broth,  water, vegetables and seasonings.  Bring to a boil. Reduce heat; simmer, uncovered, for 30 minutes or until vegetables are tender.|Meanwhile, for dumplings, in a large bowl, beat the egg whites and cottage cheese until blended. Add water and  salt. Stir in the flour and mix well.|Bring soup to a boil on the stove. Drop dumplings by tablespoonfuls onto the boiling soup. Reduce heat; cover and simmer for 15 minutes or until a toothpick inserted in dumplings comes out clean. Be sure not to lift the cover while simmering.|For extra calories, you can add crackers, such as Kashi Original 7-Grain Sea Salt Pita Crisps. ",
    time: 60,
    serves: 4,
    fat: 3.8,
    calories: 334,
    cholesterol: 67,
    carbohydrate: 51,
    saturated: 1,
    sodium: 712,
    fiber: 3,
    difficulty: "medium",
    category: "dinner",
  },
  {
    title: "Low-Fat Gingerbread Cookies",
    slug: "low-fat-gingerbread-cookies",
    img: "low-fat-gingerbread-cookies",
    hasImage: true,
    description:
      "A tasty holiday dessert, this is sure to please any sweet tooth!",
    ingredients:
      "3 tablespoons unsalted butter, softened |3/4 cup (150g) firmly packed brown sugar |1/2 cup (147g) unsweetened applesauce |1 egg |1/3 cup (110g) dark molasses |3 cups (385g) all-purpose flour plus more for dusting |1 teaspoon baking soda |2 teaspoons ground ginger |1 teaspoon ground cinnamon |1/2 teaspoon allspice |1/4 teaspoon ground cloves |1½ tablespoons egg whites |1/4 cup (25g) powdered sugar |Pinch of cream of tartar ",
    methods:
      "In a large mixing bowl, beat the butter, sugar, and applesauce until smooth. Add the egg and molasses, mix well. |In another large bowl, combine flour, baking soda and spices. Add to the sugar and molasses mixture, stirring well. |Divide the dough into two flat balls; cover with plastic wrap and chill in the refrigerator for at least 2.5 hours. |Preheat oven to 350°F (176°C). Generously dust the surface of your working area with flour before rolling out the dough. Work with one ball of dough at a time, keep the other refrigerated while you do so. |Roll the dough out to 1/8 inch thickness, sprinkle a little flour on top of the dough it it’s a little sticky.  |Cut the gingerbread with a cookie cutter shape of your choice, try to find a smaller one to reach the goal of 48 cookies. |Place the cookies 1 or 2 inches apart on a lined baking sheet. Bake 10-12 minutes.|Mix the egg whites with lemon juice. Combine with powdered sugar, mixing well. If the icing is too thin, add more powdered sugar, it it’s too thick add a drop of lemon juice. Add to a piping bag to decorate cookies when cookies are cooled. |Depending on the size of your cookie cutter, this recipe makes about 48 cookies. ",
    serves: 16,
    fat: 2.7,
    calories: 166,
    cholesterol: 17,
    carbohydrate: 33,
    saturated: 1.5,
    sodium: 90,
    fiber: 1,
    difficulty: "medium",
    category: "dessert|family favorites|christmas",
  },
  {
    title: "Low-Fat Potato Latkes",
    slug: "low-fat-potato-latkes",
    img: "low-fat-potato-latkes",
    hasImage: true,
    description: "A delicious low-fat spin on a traditional holiday dish.",
    ingredients:
      "1/2 pound/225 g potatoes e.g. Yukon gold , Russet, (flaky or medium starch potato) |1 medium onion|1/4 cup (35g) ﬂour |2 egg whites |Freshly ground black pepper",
    methods:
      "Preheat oven to 450˚F (232°C).|Peel, and shred potatoes and onion, using either a grater or a food processor.|Place a large cookie sheet in hot oven. |Empty shredded potatoes and onion into a colander and squeeze out as much excess moisture as you can. |Transfer potato and onion mixture to a large bowl.|Add ﬂour, egg whites and black pepper, and combine well with a fork. |Remove cookie sheet from oven. Coat with nonstick cooking spray |Form 2-2 1/2 -inch ﬂattened rounds with the potato mixture and place on hot cookie sheet. |Return cookie sheet to oven and cook for 7-8 minutes each side, until crisp and golden. |To achieve a crispier pancake after baking, may broil potato pancakes if desired.|Makes 12-15 latkes.",
    time: 30,
    serves: 8,
    fat: 0.3,
    calories: 183,
    cholesterol: 0,
    carbohydrate: 40,
    saturated: 0,
    sodium: 37,
    fiber: 3,
    difficulty: "medium",
    category: "appetizers|family favorites",
  },
  {
    title: "Jambalaya",
    slug: "low-fat-jambalaya",
    img: "low-fat-jambalaya",
    hasImage: true,
    description: "A wonderful, easy to prepare, Cajun classic made low fat!",
    ingredients:
      "1/2 pound medium shrimp, peeled, deveined and chopped |1½ teaspoon seafood seasoning (such as Tony Chachere’s) |Non-stick cooking spray |1 teaspoon olive or canola oil |1 cup (150g) chopped onion |1/2 cup (90g) chopped green bell pepper |1/2 cup (115g) chopped celery |2 tablespoons chopped garlic |1 can diced tomatoes, no salt added |3 bay leaves |1 teaspoon Worcestershire sauce |1 teaspoon hot sauce (such as Tabasco)|3/4 cup (190g) white rice |2 cups (475ml) low sodium chicken broth |4 oz (113g) smoked turkey sausage, sliced |Salt, pepper, and hot sauce to taste ",
    methods:
      "In a bowl combine shrimp and Creole seasoning, and work in seasoning well. |Spray a large saucepan with non-stick cooking spray to coat bottom of pan. |Add oil, onion, pepper, and celery and heat over medium high heat, stirring frequently, for 3 minutes. |Add garlic, tomatoes, bay leaves, Worcestershire and hot sauce. |Stir in rice and slowly add chicken broth. |Reduce heat to medium and cook until rice absorbs liquid and becomes tender, stirring occasionally, about 15 minutes. |When rice is just tender add shrimp and sausage. |Cook until meat is done, stirring occasionally, about 10 minutes more. |Season to taste with salt, pepper and hot sauce. ",
    time: 40,
    serves: 6,
    fat: 3.1,
    calories: 179,
    cholesterol: 71,
    carbohydrate: 25,
    saturated: 0.8,
    sodium: 633,
    fiber: 3,
    difficulty: "medium",
    category: "dinner|family favorites|hearty meal",
  },
  {
    title: "Mac and Cheese",
    slug: "mac-and-cheese",
    img: "mac-and-cheese",
    hasImage: true,
    description:
      "Easy to make and wonderful comfort food. Serve  with a salad and low fat brownies for dessert.",
    ingredients:
      "15 oz. (425g) elbow macaroni|2 cans reduced-fat cheddar cheese soup|8 oz. (226g) shredded low-fat cheddar cheese",
    methods:
      "Cook macaroni according to package directions until al dente.|Drain and place in 13”x9” glass baking dish.| Mix in cheddar cheese soup. |Mix in shredded cheese. |Cover tightly with foil and bake at 350°F (176°C) for approximately 1 hour, or until bubbly.",
    time: 70,
    serves: 8,
    fat: 6,
    calories: 273,
    cholesterol: 6,
    carbohydrate: 40,
    saturated: 4,
    sodium: 515,
    fiber: 6,
    difficulty: "easy",
    category: "dinner|family favorites|hearty meal",
  },
  {
    title: "Maple Mustard Grilled Chicken",
    slug: "maple-mustard-grilled-chicken",
    img: "maple-mustard-grilled-chicken",
    hasImage: true,
    description:
      "Here is an easy chicken marinade, using ingredients you may already have in your pantry.",
    ingredients:
      "Four 4 oz. (113g) boneless skinless chicken breasts|1/4 cup (85g) 100% maple syrup|1/4 cup (62g) Dijon mustard (may use 1 tbsp. of “whole grain” Dijon  mustard with 3 tbsp. Dijon  mustard, if desired)|Juice from 1/2 lemon (1-2 tbsp.)|1 tbsp. dried whole rosemary",
    methods:
      "Add chicken breasts to a gallon size zip top bag.|In a small bowl, mix together marinade ingredients and pour the marinade over the chicken, reserving 2-3 tbsp.  for later basting. |Turn chicken to coat and let the  chicken marinate for one hour or more in the refrigerator.|Remove chicken from the marinade and discard marinade.|Heat a grill and grill chicken for ten minutes on each side, until  cooked through. |Toward the end of grilling, baste  each chicken breast with reserved marinade.|Enjoy at a cookout with your favorite potato or macaroni salad prepared  with fat-free mayonnaise. |Marinade may also be used for pork or turkey tenderloin.",
    time: 80,
    serves: 4,
    fat: 3.1,
    calories: 173,
    cholesterol: 83,
    carbohydrate: 7,
    saturated: 0.7,
    sodium: 233,
    fiber: 0,
    difficulty: "medium",
    category: "dinner|hearty meal",
  },
  {
    title: "Maple Mustard Broiled Salmon",
    slug: "maple-mustard-broiled-salmon",
    img: "maple-mustard-broiled-salmon",
    hasImage: true,
    ingredients:
      "4, 4 oz. (113g) wild-Alaskan salmon ﬁlets (do not to substitute farmed Atlantic salmon, as that contains double the fat) |1¼ cups (220g) frozen broccoli ﬂorets |1/4 cup (62g) Dijon mustard |1/4 cup (85g) real maple syrup |1 tbsp. fresh or dried dill weed |4 cups (1000g) fully cooked jasmine rice |freshly ground black pepper, to taste |cooking spray",
    methods:
      "Thaw frozen salmon ﬁllets in the refrigerator.|Start with fully cooked rice or prepare according to package directions, omitting any butter, margarine or oil. |Turn on broiler or grill. |Spray broiling/grill pan with cooking spray and place salmon ﬁlets on top. |Season with 2-3 grinds black pepper. Mix together mustard, syrup and dill and, using a basting brush, cover salmon with half of this mixture. |Place ﬁsh under broiler/on grill and cook for 5 minutes. |Place broccoli ﬂorets with 1/2 cup water  in a microwave-safe bowl and cover.|Place in microwave and set timer for 5 minutes.|Take ﬁsh out and gently turn with spatula, add 2-3 grinds more pepper and baste the rest of the mustard mixture on the other side of ﬁsh. |Cook ﬁsh another ﬁve minutes or until it ﬂakes easily and is 130˚F (54°C) when an instant-read thermometer is placed into the thickest part of the ﬁlet. |Place each salmon ﬁllet on 1 cup (250g) of the hot rice. Serve with 1 cup of the steamed broccoli.  ",
    serves: 4,
    fat: 7,
    calories: 350,
    cholesterol: 50,
    carbohydrate: 46,
    saturated: 2.5,
    sodium: 303,
    fiber: 3,
    difficulty: "medium",
    category: "dinner|hearty meal",
  },
  {
    title: "Margarita Mahi Mahi Fillets",
    slug: "margarita-mahi-mahi-fillets",
    img: "margarita-mahi-mahi-fillets",
    hasImage: true,
    description:
      "These fillets marinate in lime juice and tequila-ingredients of a margarita. Serve with Spanish rice and black beans. Left overs can be used the next day for fish tacos!",
    ingredients:
      "6 (4 ounce)(113g) Mahi Mahi fillets (or other white fish) |3/4 teaspoon salt, divided |1/4 cup (15g) fresh coriander/cilantro leaves |1/4 cup (44g) chopped onion |1 tablespoon finely chopped seeded serrano chile |3 tablespoons fresh lime juice |1 tablespoon tequila |1/2 tablespoon canola oil |1 garlic clove, chopped |Cooking spray |Chopped fresh chives (optional) |Lime wedges (optional) ",
    methods:
      "Place the fillets in a single layer in a 13 x 9-inch baking dish, and sprinkle with 1/4 teaspoon salt. |Combine 1/2 teaspoon salt, coriander/cilantro, and the next 7 ingredients (coriander/cilantro through garlic clove) in a food processor or blender and process until smooth. |Pour coriander/cilantro mixture over the fillets, turn to coat. |Marinate in the refrigerator for 30 minutes, turning once.|To grill: Prepare grill on high. Remove fish from dish and place on nonstick foil. Drizzle remaining marinade on fish and enclose fish. Place foil packet on grill and cook for 10-15 minutes until cooked through and flakes easy with a fork. Garnish with chopped chives and lime wedges if desired. |To bake: Preheat oven to 375˚F (190°C). Place 13 x 9- inch baking dish with marinated fish in the oven. Bake for 7 minutes, flip fish over and cook another 7 minutes. It should be opaque and flake easily with a fork. ",
    serves: 6,
    fat: 0.2,
    calories: 118,
    cholesterol: 83,
    carbohydrate: 1.6,
    saturated: 0.3,
    sodium: 393,
    fiber: 0,
    difficulty: "medium",
    category: "dinner|hearty meal",
  },
  {
    title: "Marinated Grilled Shrimp",
    slug: "marinade-for-grilled-shrimp",
    img: "marinade-for-grilled-shrimp",
    hasImage: true,
    description:
      "These delicious shrimp will be the hit of your next Barbeque.",
    ingredients:
      "1lbs (16oz) (or more) peeled, deveined, extra-large shrimp (26-30 count) |3-4 cloves garlic, minced |1/3 cup (75ml) reduced sodium soy sauce |1/4 cup (85g) honey |3/4 cup (169ml) water |Splash of lemon or orange juice |Pepper to taste ",
    methods:
      "Mix ingredients and pour over peeled and deveined shrimp on skewers. If using wooden skewers, be sure to soak them in water first.|Marinade shrimp for 1/2 hour and then grill. |Enjoy! ",
    serves: 4,
    time: 50,
    fat: 1.8,
    calories: 154,
    cholesterol: 160,
    carbohydrate: 11,
    saturated: 0.3,
    sodium: 488,
    fiber: 0,
    difficulty: "easy",
    category: "dinner|family favorites",
  },
  {
    title: "Martha’s Egg White Bites",
    slug: "marthas-egg-white-bites",
    img: "marthas-egg-white-bites",
    hasImage: true,
    description:
      "Enjoy for brunch or cook, in advance, for a fast breakfast. Tastes just as good the next day and can also be frozen. The best part is that you won’t miss the yolks in this dish, because the vegetables give it so much flavor. ",
    ingredients:
      "2-2½ cups (375g) of finely chopped vegetables (may include onions, mushrooms, bell pepper, spinach, grated potatoes, broccoli and summer squash)|16 ounce carton (453g)(2 cups) Egg Substitute or Egg Whites|1/4 cup (29g) grated low fat cheese",
    methods:
      "Preheat oven to 350°F (176°C).|Sauté vegetables in a nonstick pan prepared with non stick cooking spray. Cook until tender. Salt and pepper to taste. |Prepare a 12 muffin tin with non stick cooking spray.  Fill each with a small amount of the veggie mixture, dividing evenly among the 12 muffin cups. |Pour egg substitute over the veggies, dividing evenly between the 12 muffin cups. |Sprinkle a small amount of cheese on top of the veggie/egg substitute mixture.| Bake for 20-25 minutes or until set. Remove from muffin tins and serve.|Serving Suggestion (not included in nutritional analysis) May be into an egg sandwich with an English muffin or toast. May be served with salsa.  ",
    serves: 6,
    time: 35,
    fat: 0.3,
    calories: 36,
    cholesterol: 1,
    carbohydrate: 3,
    saturated: 0,
    sodium: 110,
    fiber: 1,
    difficulty: "easy",
    category: "breakfast|vegetarian",
  },
  {
    title: "Mexican Picadillo",
    slug: "mexican-picadillo",
    img: "mexican-picadillo",
    hasImage: true,
    description:
      "This traditional Mexican dish can be made many different ways. Feel free to change up the  vegetables. It is very flavorful and low fat!",
    ingredients:
      "1 pound ground beef 95%/5% or leaner|2 cups (450g) diced potatoes (smaller and thinner will cook faster)|1½ cups (225g) frozen mixed vegetables|1/2 cup (88g) diced tomatoes|1/2 cup (26g) diced onion|1/2 cup (90g) diced green pepper|1/2 teaspoon chopped serrano pepper (optional)|1 clove of garlic, crushed|1/2 teaspoon oregano|1/2 teaspoon ground cumin|1 teaspoon salt |1 cup (225ml) of water",
    methods:
      "Brown the ground beef in a large skillet at medium heat turning occasionally.|Once beef is browned add in onion, green pepper, serrano pepper, tomatoes, garlic, oregano, cumin and salt. Stir and cook for 5 minutes. |Add in potatoes and water. Simmer for 15 minutes or until potatoes are soft.",
    serves: 4,
    time: 40,
    fat: 6.1,
    calories: 281,
    cholesterol: 70,
    carbohydrate: 27,
    saturated: 2.7,
    sodium: 874,
    fiber: 5,
    difficulty: "easy",
    category: "dinner",
  },
  {
    title: "Mini Cucumber Sammy’s",
    slug: "mini-cucumber-sammys",
    img: "mini-cucumber-sammys",
    hasImage: true,
    description: "This is a great recipe to bring to a BBQ.",
    ingredients:
      "1 loaf French bread (sliced into 12 slices)|1 cucumber (sliced into 12 discs), can leave peel on if desired,  make sure to wash ﬁrst|8 oz. (226g) brick of fat-free cream  cheese, softened|1/4 cup (70g) fat free Greek yogurt|1 package dry ranch dressing|Optional:salt, pepper, to taste.Dill for garnish",
    methods:
      "Combine cream cheese, fat-free Greek yogurt and dry package ranch dressing until creamy.|Spread approximately 1½-2 tbsp. cream cheese mixture on a slice of French bread, place 1 cucumber disc on top.|Refrigerate in covered container  at least one hour before serving.|Sprinkle with salt and pepper  as desired.|Garnish with dill as desired.",
    serves: 12,
    time: 70,
    fat: 1,
    calories: 97,
    cholesterol: 3,
    carbohydrate: 18,
    saturated: 0.3,
    sodium: 362,
    fiber: 1,
    difficulty: "easy",
    category: "dinner|vegetarian",
  },
  {
    title: "Mockamole",
    slug: "mockamole",
    img: "mockamole",
    hasImage: true,
    description:
      "If you miss your favorite high fat avocado dip, this one is a great second without the fat.",
    ingredients:
      "1- 15 ounce (425g) can of Lima beans, with liquid|Juice of 3 limes|1/2 cup (25g) of fresh coriander/cilantro|3 green onions/spring onions, chopped|3/4 teaspoon of salt|1 serrano pepper, without seeds, chopped (optional)",
    methods:
      "Add all ingredients to a blender or food processor and blend to a desired consistency of chunky to smooth.",
    serves: 8,
    time: 10,
    fat: 0.1,
    calories: 41,
    cholesterol: 0,
    carbohydrate: 8,
    saturated: 0,
    sodium: 373,
    fiber: 2,
    difficulty: "easy",
    category: "snack|vegetarian|quick and easy",
  },
  {
    title: "Morning Shake",
    slug: "morning-shake",
    img: "morning-shake",
    hasImage: true,
    description:
      "This is great when you are in a hurry. Easy to take along with you!",
    ingredients:
      "1 cup (225ml) Fat-Free Milk |1/2 medium Banana |1/2 cup (50g) Blueberries |1 teaspoon Vanilla Extract |1 packet artificial sweetener |1 cup (150g) ice",
    methods: "Place all ingredients into blender and blend until smooth ",
    serves: 1,
    time: 5,
    fat: 0.6,
    calories: 193,
    cholesterol: 5,
    carbohydrate: 38,
    saturated: 0.2,
    sodium: 105,
    fiber: 3,
    difficulty: "easy",
    category: "breakfast|quick and easy",
  },
  {
    title: "Mozzarella Cheese Sticks",
    slug: "mozzarella-cheese-sticks",
    img: "mozzarella-cheese-sticks",
    hasImage: true,
    description:
      "A cheesy appetizer, great for hosting or for bringing to an event!",
    ingredients:
      "1½ cups (338g) of Healthy Choice Pasta Sauce, Garlic & Herbs |6 Sargento string cheese, light |12 wonton wrappers |1/4 cup (2 large eggs) egg whites ",
    methods:
      "Cut each of the mozzarella cheese sticks in half. |Lay the cut mozzarella cheese stick on a wonton and roll it up, folding the sides so the cheese doesn’t pop out while baking. |Lay them all out on a pan, evenly distributed. |Spread egg whites over the wonton wrappers to create a nice browning effect during the baking process and to prevent the cheese sticks from drying out.  |Bake at 350°F (176°C) for 10-15 minutes or until the cheese is melted to your preference. |Serve warm, and dip into the pasta sauce for a delicious treat! ",
    serves: 6,
    time: 25,
    fat: 2.7,
    calories: 130,
    cholesterol: 11,
    carbohydrate: 16,
    saturated: 1.5,
    sodium: 449,
    fiber: 2,
    difficulty: "easy",
    category: "snack|appetizers|family favorites|quick and easy",
  },
  {
    title: "No-Crust Apple Pie",
    slug: "no-crust-apple-pie",
    img: "no-crust-apple-pie",
    hasImage: true,
    ingredients:
      "6-7 apples (5-6 cups), peeled, cored and sliced|1 tbsp. sugar|1 tsp. cinnamon|1/3 cup (75g) light margarine, melted|3/4 cup (169g) sugar |1 cup (140g) whole wheat (or white) ﬂour|1/2 cup or 4 egg whites",
    methods:
      "Place sliced apples into pie plate. |Mix sugar and cinnamon in small bowl. |Sprinkle this mixture over apples. |In a medium bowl, mix remaining ingredients. |Pour this thick and creamy mixture over apples as evenly as possible. |Bake at 350°F (176°C) for 1 hour or until golden brown.",
    serves: 8,
    time: 75,
    fat: 4,
    calories: 208,
    cholesterol: 0,
    carbohydrate: 42,
    saturated: 1,
    sodium: 86,
    fiber: 3,
    difficulty: "easy",
    category: "dessert|family favorites",
  },
  {
    title: "Noodle Kugel",
    slug: "noodle-kugel",
    img: "noodle-kugel",
    hasImage: true,
    description: "This holiday dish can be served warm or cold.",
    ingredients:
      "16 oz (453g) egg substitute|1/2 cup (113g) sugar|16 oz (453g) fat free sour cream/fat free greek yogurt|16 oz (453g) nonfat cottage cheese|2 – 12 ounce (340g) cans evaporated skim milk|2 tablespoons vanilla|3 teaspoons cinnamon, divided|12 ounce (340g) no-yolk egg noodle, cooked",
    methods:
      "Preheat oven to 350°F (176°C) degrees and spray 9x13 pan with nonstick cooking spray.|Combine first 6 ingredients in a large bowl.|Mix in only 1½ teaspoons of cinnamon.|Add noodles and stir all ingredients together.|Spread into pan evenly, cover and place in refrigerator overnight.|Next day, sprinkle remaining 1½ teaspoon of cinnamon on top of kugel. |Bake for approximately 1 hour, 40 minutes.",
    serves: 15,
    time: 120,
    fat: 1.7,
    calories: 218,
    cholesterol: 7,
    carbohydrate: 36,
    saturated: 0.3,
    sodium: 247,
    fiber: 1,
    difficulty: "easy",
    category: "dinner|family favorites",
  },
  {
    title: "Old Fashioned Potato Salad",
    slug: "old-fashioned-potato-salad",
    img: "old-fashioned-potato-salad",
    hasImage: true,
    description:
      "Make some easy substitutions to make this picnic favorite low fat!",
    ingredients:
      "4 medium sized russet potatoes|48 ounce (1.4L) container of fat free, low sodium chicken broth |1/4 cup (60g) sweet pickled relish|1/4 cup (40g) chopped onion|1/2 cup (115g) chopped celery|1/2 teaspoon salt|1/2 teaspoon pepper|4 eggs|1 tablespoon mustard|1/4 cup (58g) Hellmann’s Low Fat Mayonnaise",
    methods:
      "Wash potatoes and leave the skin on. In a large pot add the chicken broth and potatoes (if potatoes not fully covered then add water so they are completely covered) and bring to a boil. |Turn down the heat to medium and cover the pot. Cook until potatoes are tender, about 30 minutes. |Once done, strain off the liquid and while under cold running water, peel off the skin. |While the potatoes are cooking, place eggs in a saucepan and cover with cold water. Bring water to a boil; cover and remove from heat. Let eggs stand in hot water for 12-14 minutes. |Remove from the water, cool and peel. Remove egg yolk and chop egg whites.|Once done, place the potatoes in a large bowl and mash the potatoes with a fork leaving some small chunks. |Add in the relish, onion, celery, egg whites, salt, pepper, mustard and mayonnaise. |Mix together well and refrigerate until chilled. ",
    serves: 6,
    time: 50,
    fat: 1,
    calories: 136,
    cholesterol: 0,
    carbohydrate: 28,
    saturated: 0,
    sodium: 439,
    fiber: 2,
    difficulty: "easy",
    category: "lunch|family favorites",
  },
  {
    title: "Orange Cake",
    slug: "orange-cake",
    img: "orange-cake",
    hasImage: true,
    description:
      "This delicious cake is very moist and tastes great slightly chilled.",
    ingredients:
      "1 box orange cake mix|1 can peach pie ﬁlling|1/2 cup eggbeaters (or 4 egg whites) |1/2 cup (123g) fat-free sour cream/fat free greek yogurt |8 oz. (226g) fat-free cream cheese|1 small box fat-free, sugar-free vanilla pudding|20 oz. (566g) can crushed pineapple (undrained)|8 oz. (226g) fat-free Cool Whip",
    methods:
      "Preheat oven to 350°F (176°C). |Mix cake ingredients well and  pour into cake pan sprayed with cooking spray.  |You can use a tube pan or 13”x9”.|Bake for 40-45 minutes or until inserted toothpick comes out clean.|Let cool completely.|Mix ﬁrst 3 frosting ingredients well and then fold in fat-free Cool Whip. |Frost cooled cake.",
    serves: 12,
    time: 60,
    fat: 4,
    calories: 326,
    cholesterol: 3,
    carbohydrate: 65,
    saturated: 1,
    sodium: 534,
    difficulty: "easy",
    category: "dessert|family favorites",
  },
  {
    title: "Oven Fried Green Tomatoes",
    slug: "oven-fried-green-tomatoes",
    img: "oven-fried-green-tomatoes",
    hasImage: true,
    description: "Another amazing southern favorite made low-fat!",
    ingredients:
      "3 green tomatoes cut into 1/4 inch slices |1 cup (125g) panko bread crumbs |1/2 cup (113ml) low-fat buttermilk |1 large egg white |Non-stick cooking spray |1/2 teaspoon hot sauce |1 teaspoon salt |3/4 teaspoon pepper",
    methods:
      "Preheat oven to 450°F (232°C). Place baking sheet in oven. |Combine panko, salt, pepper, and hot sauce in a shallow dish and set aside.  |Whisk together buttermilk and egg in a large bowl.  |Place tomato slices into bowl with buttermilk mixture.  |Using a slotted spoon, remove tomatoes from mixture and add to the panko.  |Make sure tomato slices are evenly coated with panko mixture.  |Remove hot baking pan from oven and spray with cooking spray. Place coated tomato slices on heated pan and lightly coat them with cooking spray.  |Bake for 15 minutes.  |Turn slices over, spray tomato slices with cooking spray and place back in the oven for 10 additional minutes. |Tomatoes should be crispy and golden brown. Season as needed. Serve immediately. Pair with your favorite fat free dipping sauce or chutney!",
    serves: 6,
    time: 45,
    fat: 0.5,
    calories: 60,
    cholesterol: 0.8,
    carbohydrate: 11,
    saturated: 0.1,
    sodium: 476,
    fiber: 1,
    difficulty: "easy",
    category: "appetizers|family favorites",
  },
  {
    title: "Oven Fried Okra",
    slug: "oven-fried-okra",
    img: "oven-fried-okra",
    hasImage: true,
    description: "A classic southern favorite made low-fat!",
    ingredients:
      "1 pound fresh okra pods, stemmed and cut into 3/4 inch slices |1 cup (120g) stone ground yellow cornmeal |1/2 cup (113ml) low-fat buttermilk |1 large egg, lightly beaten |Non-stick cooking spray |1/4 teaspoon cayenne pepper |1/2 teaspoon salt  |1/2 teaspoon pepper",
    methods:
      "Placed rimmed baking sheet in the oven. Preheat oven to 450°F (232°C). |Combine cornmeal and cayenne pepper in a shallow dish and set aside.  |Whisk together buttermilk and egg in a large bowl. Add okra, salt, and pepper |Stir to combine and let sit for about 3 minutes to coat okra.  |Using a slotted spoon, remove okra from mixture and add to the cornmeal.  |Dredge okra in cornmeal.  |Remove hot baking pan from oven and spray with cooking spray. Place coated okra on heated pan and lightly coat the okra with cooking spray.  |Bake for 25 minutes, stirring once.  |Stir and spray with cooking spray again. Then bake for an additional 10 minutes. |Okra should be crispy and golden brown. Season as needed. Serve immediately. ",
    serves: 6,
    time: 50,
    fat: 1.9,
    calories: 119,
    cholesterol: 32,
    carbohydrate: 22,
    saturated: 0.5,
    sodium: 257,
    fiber: 4,
    difficulty: "easy",
    category: "dinner|vegetarian",
  },
  {
    title: "Oven-Fried Chicken with Skinny French Fries",
    slug: "oven-fried-chicken-with-skinny-french-fries",
    img: "oven-fried-chicken-with-skinny-french-fries",
    hasImage: true,
    description:
      "Craving fried food? You will feel satisﬁed with this “un-fried” meal.",
    ingredients:
      "1-1½ lbs (16-24oz). boneless, skinless chicken breast|1/3 cup (75g) fat-free ranch dressing|1 pkg. (or 3/4 cup (94g) seasoned Panko bread crumbs)|Skinny fries: 4 White Rose potatoes|paprika and other seasonings, as desired",
    methods:
      "Chicken: Preheat oven to 400°F (204°C). |Squirt ranch dressing into a large plastic bag. Add chicken and blend. |Dip chicken in the coating mixture  and bake on a nonstick aluminum  foil-covered baking sheet. |Bake for 25-30 minutes, turning once. |Skinny fries: Preheat oven to 425°F (218°C). |Cut potatoes into thick cut “French fry” size.|Spray a foil-lined baking sheet with nonstick cooking spray.|Spread french fries on pan and add seasoning.|Bake for about 30 minutes, turning frequently.",
    note: "May replace chicken with pork tenderloins or whiteﬁsh, like cod",
    serves: 4,
    time: 80,
    fat: 2.5,
    calories: 318,
    cholesterol: 68,
    carbohydrate: 43,
    saturated: 0.4,
    sodium: 607,
    difficulty: "easy",
    category: "dinner|family favorites",
  },
  {
    title: "Pineapple Bread Pudding",
    slug: "pineapple-bread-pudding",
    img: "pineapple-bread-pudding",
    hasImage: true,
    description:
      "This one bowl dish is fast and easy to prepare. It can be eaten as  a dessert, a sweet breakfast, or served for brunch. Enjoy!",
    ingredients:
      "3-4 slices whole-wheat bread (broken into small pieces by hand)|20 oz. (566g) can crushed pineapple, in its own juice (drain slightly)|2 tbsp. light margarine (to make fat-free, use butter spray)|1/2 cup (85g) brown sugar artificial sweetener or 1/2 cup (85g) brown sugar|1 cup (240g) egg substitute|1/2 cup (113ml) skim or 1% milk",
    methods:
      "Preheat oven to 350°F (176°C). |Melt margarine. Place in large bowl with milk ﬁrst, then add all ingredients except bread. Mix, by hand, until well blended. |Add bread and stir. |Pour into an 8”x8” or 9”x9” baking dish prepared with nonstick cooking spray. |Bake for 35-45 minutes or until it appears set and knife comes out clean.",
    serves: 9,
    time: 55,
    fat: 2.5,
    calories: 133,
    cholesterol: 3,
    carbohydrate: 23,
    saturated: 1.2,
    sodium: 102,
    difficulty: "easy",
    category: "dessert|family favorites",
  },
  // {
  //   title: "Pizza Quick® Lunch",
  //   slug: "pizza-quick-lunch",
  // img: "pizza-quick-lunch",
  //   hasImage: true,
  //   description:
  //     "Keep these ingredients on hand and satisfy your pizza  craving. Serve with a big bowl of soup.",
  //   ingredients:
  //     "Boboli® 8-inch mini-pizza crust |1/4 cup (56g) Pizza Quick® sauce|1 green pepper, thinly sliced|1 cup (75g) fresh mushrooms, sliced|2 red onion slices|2 tbsp. freshly grated |Parmesan cheese",
  //   methods:
  //     "Preheat oven to 400˚F (204°C). |Spread sauce on pizza crust. |Top with remaining ingredients (add other veggies, if desired). |Bake pizza for 8 minutes and enjoy.",
  //   serves: 2,
  //   time: 20,
  //   fat: 6,
  //   calories: 272,
  //   cholesterol: 4,
  //   carbohydrate: 44,
  //   saturated: 2,
  //   sodium: 657,
  //   difficulty: "easy",
  //   category: "dinner|family favorites|quick and easy|vegetarian",
  // },
  {
    title: "Potato Broccoli Bake",
    slug: "potato-broccoli-bake",
    img: "potato-broccoli-bake",
    hasImage: true,
    description:
      "You can make this dish from scratch or use leftover non-fat mashed potatoes. Double the portion for a more ﬁlling lunch.",
    ingredients:
      "3 Russet potatoes, washed,  peeled, and diced|1 tbsp. light trans fat-free  margarine|1 tsp. mustard|2 tbsp. skim milk|salt and pepper, to taste (if desired)|3 cups (525g) frozen chopped broccoli |1/4 cup (29g) low-fat grated cheddar cheese",
    methods:
      "Preheat oven to 350°F (176°C). |Cook, drain, and mash potatoes with margarine, mustard, milk, salt, and pepper. |Cook broccoli per package  directions, drain well, and fold into potatoes. |Place in an 8”x8” baking dish prepared with nonstick cooking spray. |Sprinkle with cheese and bake for 15 minutes.",
    serves: 4,
    time: 25,
    fat: 2.5,
    calories: 158,
    cholesterol: 5,
    carbohydrate: 28,
    saturated: 1,
    sodium: 113,
    difficulty: "easy",
    category: "dinner|quick and easy|vegetarian",
  },
  {
    title: "Pulled Chicken in a Crock Pot",
    slug: "pulled-chicken-in-a-crock-pot",
    img: "pulled-chicken-in-a-crock-pot",
    hasImage: true,
    description: "Don’t tell anyone how easy this recipe is.",
    ingredients:
      "4-6 boneless, skinless chicken breasts|8-12 oz. (226-340g) of your favorite barbeque sauce",
    methods:
      "In a crock pot prepared with nonstick cooking spray, place chicken with sauce and cover. |Cook on low for 6 hours or on high for 3 hours. |Before serving, pull chicken breasts apart with a fork for an  easy pulled chicken meal. |Serving suggestions*: Serve with a side dish and salad  or serve on a whole-wheat bun with a salad.|*Optional toppings not included in nutrition analysis",
    serves: 4,
    fat: 1.5,
    calories: 251,
    cholesterol: 68,
    carbohydrate: 32,
    saturated: 0.4,
    sodium: 757,
    difficulty: "easy",
    category: "dinner|family favorites",
  },
  {
    title: "Pumpkin Chocolate Chip Brownies",
    slug: "pumpkin-chocolate-chip-brownies",
    img: "pumpkin-chocolate-chip-brownies",
    hasImage: true,
    description:
      "A sweet and chocolate-y treat, perfect for any time of year, not just fall!",
    ingredients:
      "1/2 cup (115g) pumpkin puree |1 whole egg |1/2 cup egg whites (2 egg whites) |3 tablespoons fat-free vanilla Greek yogurt |1 cup (140g) flour |1 teaspoon baking powder |1 teaspoon unsweetened cocoa powder |1/2 teaspoon ground cinnamon |1/2 teaspoon ground allspice |1/4 teaspoon ground nutmeg |1/4 teaspoon salt |2/3 cup (113g) brown sugar, packed |1/2 cup (90g) semisweet chocolate chips ",
    methods:
      "Preheat oven to 350°F (176°C). Line an 11 x 7 inch pan with parchment paper. |In a large bowl, combine pumpkin puree, eggs, and yogurt until smooth. Set aside. |In a separate medium bowl, mix together the flour, baking powder, spices, salt, and brown sugar. Add to the wet ingredients and mix until thoroughly incorporated. Stir in the chocolate chips. |Pour into prepared pan and spread evenly. Bake for 15-20 minutes until you can put a toothpick in it and the toothpick comes out clean.  |Allow it to cool completely before cutting. ",
    serves: 24,
    time: 45,
    fat: 1.4,
    calories: 63,
    cholesterol: 8,
    carbohydrate: 12,
    saturated: 0.7,
    sodium: 37,
    difficulty: "easy",
    category: "dessert|family favorites",
  },
  {
    title: "Pumpkin Pancakes",
    slug: "pumpkin-pancakes",
    img: "pumpkin-pancakes",
    hasImage: true,
    description:
      "Delicious pancakes made more nutritious with the addition of beta carotene-rich pumpkin. Serve with 100% real maple syrup.",
    ingredients:
      "2 tsp. baking powder |1/2 cup (115g) canned pumpkin |1/2 cup (120g) egg substitute |1¼ cups (175g) all-purpose ﬂour |1 cup (225ml) skim milk |2¼ tsp. vegetable oil|3/4 tsp. pumpkin pie spice |1/4 cup (50g) sugar",
    methods:
      "Spray griddle with cooking spray and heat over medium heat.|Sift dry ingredients together in a bowl.|Whisk milk, pumpkin, egg substitute, and oil together in a bowl.|Pour wet mixture into dry mixture and stir until just moistened, but lumpy. Do not over mix.|Using a small ladle or spoon, pour about ½ a cup of batter onto the griddle and cook until bubbles form around the edges and cooked side is lightly browned, about  1 to 3 minutes. Flip and cook until browned on the other side, about 1 minute more.|Repeat with remaining batter. |Makes 8 pancakes.",
    serves: 4,
    time: 15,
    fat: 2.8,
    calories: 278,
    cholesterol: 1,
    carbohydrate: 58,
    saturated: 0.6,
    sodium: 355,
    difficulty: "easy",
    category: "breakfast|family favorites|quick and easy",
  },
  {
    title: "Pumpkin Roll",
    slug: "pumpkin-roll",
    img: "pumpkin-roll",
    hasImage: true,
    description:
      "This is a delicious, moist, festive dessert that not only tastes great, but also makes an impressive entrance to any holiday feast.",
    ingredients:
      "2/3 cup (150g) canned pumpkin |3/4 cup (150g) sugar |3/4 cup (105g) flour |1 teaspoon baking soda |1/2 teaspoon cinnamon |1/4 teaspoon nutmeg |3 eggs  |4-oz (113g) fat free cream cheese|4-oz (113g) Neufchatel cheese|1 cup (60g) fat free Cool Whip|1 cup (200g) confectioner’s sugar|1/2 teaspoon vanilla",
    methods:
      "Preheat oven to 375°F (190°C). |Spray jelly roll pan with Pam. |Line the bottom of the pan with wax paper and spray with Pam again. |Hand mix batter ingredients. |Pour batter onto wax paper; spread evenly. |Bake 10-12 minutes. |Turn out the baked cake onto a cotton dish towel and remove wax paper. |Tightly roll the cake in the towel and let cool completely. |Mix together ingredients for the filling while cake is cooling; then chill. |Unroll the cake and spread filling; re-roll and chill. ",
    serves: 10,
    time: 25,
    fat: 4.3,
    calories: 210,
    cholesterol: 66,
    carbohydrate: 37,
    saturated: 2.0,
    sodium: 270,
    difficulty: "easy",
    category: "dessert|family favorites",
  },
  {
    title: "Quick and Easy Chocolate Cake",
    slug: "quick-and-easy-chocolate-cake",
    img: "quick-and-easy-chocolate-cake",
    hasImage: true,
    description:
      "Easy - this is just 2 ingredients. It’s easy to double the recipe for a larger group.",
    ingredients:
      "1 box of super moist devil's food cake mix (15.25 oz.)(432g)|1 can of pumpkin puree |1/4 cup (60g) of powdered low-fat peanut butter (optional but included in nutrient analysis)|1/4 cup (56ml) of water and 2 tbsp. of water (use only to mix with peanut butter)",
    methods:
      "Preheat oven to 400˚F (204°C). |Prepare in an 8”x8” pan with  nonstick cooking spray.|Mix the cake mix and canned pumpkin really well in a large bowl.|Spread the mix evenly in the prepared pan.|In a separate bowl, mix the peanut butter and water and swirl it on  top of cake mix.|Bake 25-30 minutes or until toothpick comes out clean.",
    serves: 12,
    time: 45,
    fat: 2,
    calories: 163,
    cholesterol: 0,
    carbohydrate: 33.72,
    saturated: 0,
    sodium: 333,
    difficulty: "easy",
    category: "dessert|family favorites",
  },
  {
    title: "Quick Burrito Lunch",
    slug: "quick-burrito-lunch",
    img: "quick-burrito-lunch",
    hasImage: true,
    description:
      "Keep these ingredients on hand for a quick and easy lunch. Non-fat refried beans are available as pinto or black beans.",
    ingredients:
      "1/2 cup (100g) nonfat refried beans, spicy or regular (warmed in the microwave)|2 tbsp. salsa|1/4-1/2 (50-100g) cup chopped fresh tomato|1/4 cup (25g) nonfat or 2% shredded  Mexican-ﬂavor or cheddar cheese|1 fat-free or low-fat ﬂour tortilla",
    methods:
      "Place tortilla on a microwave-safe plate. |Spread tortilla with warm beans and salsa and top with tomato and cheese. |Microwave on high to heat. |Fold over and enjoy!",
    serves: 1,
    time: 10,
    fat: 1.5,
    calories: 251,
    cholesterol: 3,
    carbohydrate: 39,
    saturated: 0,
    sodium: 870,
    difficulty: "easy",
    category: "lunch|family favorites|quick and easy",
  },
  {
    title: "Quinoa Veggie Bake",
    slug: "quinoa-veggie-bake",
    img: "quinoa-veggie-bake",
    hasImage: true,
    description:
      "Easy and delicious, this dish is a great addition to any meal!",
    ingredients:
      "1 cup (180g) quinoa|2 cups (450ml) water|2 cups (200g) nonfat shredded cheddar cheese|2 cups (350g) broccoli, chopped|1 cup (50g) carrots, shredded|1 cup (8 eggs) egg whites|1 teaspoon pepper",
    methods:
      "Cook quinoa according to box using 1 cup (180g) quinoa and 2 cups (450ml) water.|Once quinoa is cooked and drained, put quinoa into mixing bowl, add cheese, and stir.|Add broccoli, carrots, egg whites, and pepper to quinoa and cheese mixture. Stir well.|Spread mixture into 9x13 baking pan. Cook for 25-30 minutes at 425˚F (218°C).",
    serves: 6,
    time: 40,
    fat: 0.8,
    calories: 137,
    cholesterol: 7,
    carbohydrate: 14,
    saturated: 0.1,
    sodium: 474,
    fiber: 2,
    difficulty: "easy",
    category: "lunch|family favorites|vegetarian",
  },
  {
    title: "Ranch Dressing",
    slug: "ranch-dressing",
    img: "ranch-dressing",
    hasImage: true,
    description:
      "This dressing can be made whenever you need it using pantry items.",
    ingredients:
      "1/4 cup (58g) fat-free mayonnaise|1/4 cup (56ml) fat-free skim milk |1/4 cup (70g) fat-free plain Greek yogurt |1 tablespoon apple cider vinegar |1 teaspoon dry parsley |1/2 teaspoon dry chives |1/4 teaspoon onion powder |1/4 teaspoon dry dill weed |1/8 teaspoon garlic powder |1/8 teaspoon black pepper ",
    methods: "Combine the ingredients in a bowl and whisk until combined.",
    serves: 5,
    time: 10,
    fat: 0.4,
    calories: 21,
    cholesterol: 2,
    carbohydrate: 3,
    saturated: 0.1,
    sodium: 164,
    fiber: 0,
    difficulty: "easy",
    category: "family favorites|vegetarian|quick and easy",
  },
  {
    title: "Ranch Mushroom Appetizer",
    slug: "ranch-mushroom-appetizer",
    img: "ranch-mushroom-appetizer",
    hasImage: true,
    description: "Great appetizer or side dish!",
    ingredients:
      "1 pound fresh mushrooms|1 teaspoon butter-flavored sprinkles (i.e. Butter Buds or Molly McButter)|1 packet ranch salad dressing mix (1 ounce)(28g)|Water, as needed ",
    methods:
      "Put mushrooms in a slow cooker.|Mix the butter buds with the ranch dressing mix. Add just enough water to make it possible to pour the mixture over the mushrooms.|Pour the mixture over the mushrooms, stir and cook on low 3 to 4 hours.|The mushrooms eventually give off some water and that helps make the sauce.|If the mushrooms ever look dry, add a small amount of water and stir to make the sauce again. ",
    serves: 6,
    fat: 0.3,
    calories: 17,
    cholesterol: 0,
    carbohydrate: 5,
    saturated: 0.04,
    sodium: 394,
    fiber: 0.75,
    difficulty: "easy",
    category: "vegetarian|appetizer",
  },
  {
    title: "Randy’s Seafood Gumbo",
    slug: "randys-seafood-gumbo",
    img: "randys-seafood-gumbo",
    hasImage: true,
    description:
      "Here is a favorite gumbo recipe from a Louisiana native. Randy likes to make a large pot and freeze leftovers for a busy day.",
    ingredients:
      "3 cups (450g) each chopped onions (Vidalia or other) and chopped celery|6 cloves garlic, minced or 3 tsp minced garlic|1 cup (130g) Instant Roux Mix|8 cups (1.8L) water|4-8 oz. (133-226g) tomato sauce (if desired)|12 oz. (340g) package frozen chopped okra (about 3 cups)|1 tsp Creole Seasoning |1 lb. (16oz) peeled shrimp (may use frozen, cooked, tail off)|1 lb. (16oz) crabmeat (may use canned, pasteurized lump, special or claw meat)|1 tsp. gumbo ﬁle seasoning (if desired but recommended by Randy)|2 green onions/spring onions, minced|2 tbsp. chopped fresh Italian parsley|Louisiana Hot Sauce (Crystal brand) or Tabasco (to taste)|5 cups (1250g) cooked rice",
    methods:
      "In a large stockpot, prepared with nonstick cooking spray, sauté garlic, onions and celery until onions are translucent.|In a separate pot, whisk together 1 cup (130g) of the dry roux mix with 2 cups (450ml) of cool water and cook over low/medium heat. |As it begins to thicken, add the roux mixture to the stockpot, along with 6 cups (1.35L) of water.|Add tomato sauce, creole seasoning and okra and cook over low heat, stirring often.|Add shrimp and crab meat and continue cooking on low for 15 more minutes.|Add parsley, green onion/spring onion and file seasoning and simmer for 30 minutes before serving. Serve over rice, adding Tabasco per individual taste. ",
    serves: 10,
    time: 60,
    fat: 1.0,
    calories: 262,
    cholesterol: 126,
    carbohydrate: 40,
    saturated: 0,
    sodium: 976,
    fiber: 3,
    difficulty: "medium",
    category: "dinner|hearty meal",
  },
  {
    title: "Ratatouille",
    slug: "ratatouille",
    img: "ratatouille",
    hasImage: true,
    description:
      "In early fall, we have so many wonderful vegetables available. Make delicious use of them by cooking up this vegetarian dish.  This recipe can be served as a side dish or over your favorite pasta or brown rice for a meal.  ",
    ingredients:
      "1 large onion, chopped|2 cloves garlic, minced |1/2 Tablespoon olive oil |1 red pepper, chopped|1 green pepper, chopped |1 large eggplant/aubergine, peeled and cubed |3 medium tomatoes, chopped |2 medium zucchinis/courgettes, chopped",
    methods:
      "In a large stock pot, sauté onions and garlic in olive oil for 2-3 minutes. |Add peppers and sauté for another 2 minutes. |Add remaining ingredients and cover. |Simmer for 45 minutes - 1 hour or until vegetables are soft. ",
    serves: 6,
    time: 80,
    fat: 1.8,
    calories: 88,
    cholesterol: 0,
    carbohydrate: 17,
    saturated: 0.3,
    sodium: 14,
    fiber: 6,
    difficulty: "easy",
    category: "dinner|hearty meal",
  },
  {
    title: "Scallop Vegetable Sauté",
    slug: "scallop-vegetable-saute",
    img: "scallop-vegetable-saute",
    hasImage: true,
    description:
      "You will enjoy the ease of preparing this light dish. Enjoy it with the shellﬁsh of your choice, as most shellﬁsh are practically fat-free.",
    ingredients:
      "1½ lbs. (24oz) of scallops|1/4 cup (60ml) white wine or broth |2 cups (150g) mushrooms, sliced |2 tomatoes, chopped|1 yellow pepper, chopped |2 tbsp. green onions/spring onions, minced |2 garlic cloves, minced|1 tsp. dry dill weed|juice from 1 lemon|black pepper, to taste|4 cups (900g) rice, couscous, or quinoa",
    methods:
      "Sauté mushrooms, pepper and tomatoes in a sauté pan  prepared with nonstick cooking spray for 3-5 minutes.|Add wine or broth and scallops  and continue cooking for 5-8 minutes.|Add dill and green onions/spring onions and ﬁnish cooking for 2 minutes until scallops are no longer translucent. |Squeeze on lemon and pepper, to taste, and serve over rice, couscous or quinoa.",
    serves: 4,
    time: 25,
    fat: 1.5,
    calories: 339,
    cholesterol: 60,
    carbohydrate: 49,
    saturated: 0.2,
    sodium: 305,
    protein: 32,
    difficulty: "easy",
    category: "dinner|fish",
  },
  {
    title: "Scalloped Potatoes",
    slug: "scalloped-potatoes",
    img: "scalloped-potatoes",
    hasImage: true,
    description:
      "A creamy and cheesy casserole dish you can bring to any event! A crowd pleaser full of flavor, no one will know its low-fat!",
    ingredients:
      "1/2 cup (70g) unbleached flour |4 teaspoons dried parsley |1/4 teaspoon ground black pepper |2½ pounds (8oz) baking potatoes (about 4 medium) |2 small yellow onions, thinly sliced and separated into rings |1 garlic clove |1/2 cup (50g) grated Parmesan cheese |1½ cup (340ml) skim milk ",
    methods:
      "Combine the flour, parsley and pepper in a small bowl. Mix together and set aside.|Scrub the potatoes before slicing them thinly (about less than 1/4 inch).|Spray nonstick cooking spray onto the bottom and sides of an 8x12-inch baking dish.|Line the bottom of the pan with a layer of the sliced potatoes, overlapping the slices slightly. Then add a layer of the onion rings over the potatoes (half of the sliced onion rings) along with 1/2 of the garlic clove sprinkled on top. Sprinkle this layer with half of the flour mixture. Then evenly sprinkle 2 tablespoons of the Parmesan cheese over it. Repeat this step to add one more layer of potatoes, onions, garlic, flour, cheese.|Pour the milk over the potatoes and sprinkle with the remaining Parmesan.|Cover the dish with aluminum foil and bake at 350°F (176°C) for 45 minutes. Remove the foil and bake for an additional 30 minutes or until the potatoes are tender and the top is a nice golden brown color. Let sit for 5 minutes before serving.",
    serves: 10,
    time: 60,
    fat: 1.3,
    calories: 191,
    cholesterol: 4,
    carbohydrate: 27,
    saturated: 0.8,
    sodium: 105,
    protein: 6,
    difficulty: "easy",
    category: "dinner|vegetarian|family favorites",
  },
  {
    title: "Shrimp and Artichokes over Linguine",
    slug: "shrimp-and-artichokes-over-linguine",
    img: "shrimp-and-artichokes-over-linguine",
    hasImage: true,
    ingredients:
      "1 Onion, large, chopped |2 Garlic cloves, minced |1/2 tablespoon olive oil |3 Tomatoes, medium, chopped |1 - 14 ounce (396g) can Artichoke hearts quartered, in water or brine (no oil) |3/4 cup (169ml) white wine |1 pound large shrimp, peeled and deveined |1 pound whole grain linguine, cooked ",
    methods:
      "Sauté onion and garlic in olive oil in non-stick skillet for a few minutes.  |Add tomatoes, artichoke hearts (drained) and white wine.  Simmer for 10 min.  |Add shrimp and cook until shrimp are pink and cooked through.  Toss with linguine and enjoy. ",
    serves: 6,
    time: 25,
    fat: 4.2,
    calories: 400,
    cholesterol: 95,
    carbohydrate: 66,
    saturated: 0.41,
    sodium: 459,
    protein: 22,
    difficulty: "easy",
    category: "dinner|quick and easy",
  },
  {
    title: "Shrimp and Grits",
    slug: "shrimp-and-grits",
    img: "shrimp-and-grits",
    hasImage: true,
    description: "A wonderful, easy to prepare, southern classic made low fat!",
    ingredients:
      "3/4 pound peeled raw medium shrimp, thawed if frozen |Non-stick cooking spray |2 tablespoons water |1 teaspoon seafood seasoning blend (Tony Chacheres or similar) (use 1½ teaspoon if you like spicier foods) |1 teaspoon canola oil |1/2 cup (90g) red bell pepper, finely chopped |1/2 cup (90g) green bell pepper finely chopped |1/2 cup (75g) white onion, chopped |2 cups (450ml) Fat free milk |1/2 cup (130g) uncooked quick-cooking grits |1/4 teaspoon salt |1/2 cup (50g) shredded reduced fat sharp cheddar cheese |1 teaspoon Worcestershire sauce ",
    methods:
      "Heat a nonstick skillet over medium heat. |Spray skillet with non-stick cooking spray to coat bottom of pan. |Add the shrimp, water, and 1/2 teaspoon seafood seasoning. |Cook, stirring frequently, for 5 minutes, or until the shrimp turn pink on the outside and are opaque in the center. |Pour into a bowl. |Using the same skillet (on medium heat), heat the oil, swirling to coat the bottom. |Cook the bell peppers and onions for 5 minutes, or until tender, stirring frequently.  |Stir in the milk, grits, and salt. |Increase the heat to medium high and bring to a boil. |Reduce the heat and simmer, covered, for 5 minutes, or until thickened, stirring occasionally. Remove from heat. |Stir in Cheddar cheese, Worcestershire sauce, remaining 1/2 teaspoon of seafood seasoning blend (or 1 teaspoon if you like spicy foods), and shrimp with any accumulated liquid.  Cover and let stand for 5 minutes before serving. ",
    serves: 5,
    time: 35,
    fat: 4.1,
    calories: 204,
    cholesterol: 119,
    carbohydrate: 21,
    saturated: 1.8,
    sodium: 603,
    difficulty: "medium",
    category: "dinner|family favorites",
  },
  {
    title: "Shrimp and Pasta Salad",
    slug: "shrimp-and-pasta-salad",
    img: "shrimp-and-pasta-salad",
    hasImage: true,
    description:
      "This dish is great to bring to a summer cookout or prepare for a cool summer’s night dinner.",
    ingredients:
      "1 pound rotini pasta, cooked |1 pound cooked shrimp, 31-35 count |1 – 10 ounce (283g) package frozen peas, thawed and drained |1 large tomato, seeded and chopped |1 cup (225g) celery, chopped |1/3 cup (50g) green onions/spring onions, chopped |1 cup (250g) fat free Ranch dressing |1 teaspoon prepared horseradish |1/2 teaspoon Mrs. Dash garlic and herb seasoning (or your favorite seasoned salt) |1 cup (75g) lettuce, torn into pieces",
    methods:
      "In a large bowl, combine rotini, shrimp, peas, tomato, celery and green onions/spring onions. |In a small bowl, blend salad dressing, horseradish, and Mrs. Dash garlic and herb seasoning. |Add dressing mixture to salad; toss to coat. |Cover and chill thoroughly. |Add lettuce before serving. ",
    serves: 8,
    time: 15,
    fat: 1.7,
    calories: 335,
    cholesterol: 109,
    carbohydrate: 56,
    saturated: 0.3,
    sodium: 358,
    protein: 23,
    difficulty: "easy",
    category: "lunch|family favorites|quick and easy",
  },
  {
    title: "Shrimp Casserole",
    slug: "shrimp-casserole",
    img: "shrimp-casserole",
    hasImage: true,
    description:
      "Also known as Shrimp Etouﬀée, this is an easy,  almost no-fat version of a Cajun favorite. Spice it up  or down to your taste.",
    ingredients:
      "1 cup (150g) onion, chopped|1 cup (225g) celery, chopped|1 cup (175g) green bell pepper, chopped|1 tsp. garlic powder|3 tbsp. tomato paste|1 can of low-fat cream of chicken soup 1 bag (12 oz.) frozen cooked shrimp, medium size|1 tsp. Cajun seasoning|1/2 tsp. hot pepper sauce",
    methods:
      "In a 2-quart microwave safe dish, spray with  nonstick spray.|Add the onion, celery, green pepper and garlic. |Spray the top of vegetables with nonstick spray.|Heat in microwave 8 minutes.|Take out and stir, then add the tomato paste,  can of soup, shrimp, Cajun seasoning and hot pepper sauce.|Put back in microwave and cook another 5 minutes until mixture thickens.|Optional: Serve over plain white or brown rice, which is almost fat-free per 1/2 – 1 cup (115-225g) cooked.",
    serves: 4,
    time: 25,
    fat: 2.5,
    calories: 150,
    cholesterol: 118,
    carbohydrate: 14,
    saturated: 1,
    sodium: 567,
    protein: 18,
    difficulty: "easy",
    category: "dinner|quick and easy",
  },
  {
    title: "Shrimp Creole",
    slug: "shrimp-creole",
    img: "shrimp-creole",
    hasImage: true,
    description: "Enjoy a little seafood prepared southern style.",
    ingredients:
      "1 lb. (16oz) frozen shrimp, cooked, peeled and deveined|1 large onion, minced|2 celery stalks, chopped|1 green pepper, chopped|2 cloves garlic or 1/2 tsp. garlic powder|14 oz. (396g) can diced, no-salt-added tomatoes|8 oz. (226g) can no-salt-added tomato sauce|2 tsp. Worcestershire sauce|1/4 tsp. ground thyme|1/2 tsp. paprika|1-3 tbsp. Creole seasoning or more to taste |Tabasco to taste (if you want more spice)|2 tsp. sugar (or 1 packet of artificial sweetener)|1 tbsp. chopped fresh parsley",
    methods:
      "Sauté onions, celery, peppers, and garlic in a large sauté pan prepared with nonstick cooking spray for 4-5 minutes. |Add rest of ingredients except shrimp and simmer to blend ﬂavors. |Add thawed, cooked shrimp and heat thoroughly (although do not overcook shrimp). |Serve over steamed or brown rice.",
    serves: 4,
    time: 20,
    fat: 2.5,
    calories: 186,
    cholesterol: 212,
    carbohydrate: 13,
    saturated: 0,
    sodium: 479,
    protein: 28,
    difficulty: "easy",
    category: "dinner|quick and easy",
  },
  {
    title: "Skinny Mashed Potatoes",
    slug: "skinny-mashed-potatoes",
    img: "skinny-mashed-potatoes",
    hasImage: true,
    description: "A healthful way to make everyone’s favorite comfort food.",
    ingredients:
      "3 large russet potatoes |32 oz. (907g) fat free, less sodium chicken broth |2 cups (450ml) of water |1/2 cup (113ml) skim milk |8 wedges of The Laughing Cow Spreadable Wedges, Light, Garlic & Herb Cheese or any flavor of preference |Salt and pepper to taste",
    methods:
      "Wash, peel and cut potatoes into small chunks.|In a large pot add chicken broth and potatoes. Use the water to make sure the potatoes are submerged.|Boil potatoes until tender then drain liquid in a colander.|In a mixing bowl add the potatoes, skim milk and beat with a mixer on medium speed for 1 minute. |Add half of the cheese and beat on medium speed until blended then add the other half.|Add salt and pepper to taste. ",
    serves: 5,
    time: 15,
    fat: 2.5,
    calories: 186,
    cholesterol: 4.5,
    carbohydrate: 29,
    saturated: 1.6,
    sodium: 400,
    protein: 9,
    difficulty: "easy",
    category: "dinner|quick and easy|family favorites",
  },
  {
    title: "Slow Cooker Brown Sugar Pork Tenderloin",
    slug: "slow-cooker-brown-sugar-pork-tenderloin",
    img: "slow-cooker-brown-sugar-pork-tenderloin",
    hasImage: true,
    description: "A sweet and easy dish, this meal is sure to please!",
    ingredients:
      "1.25 pounds pork tenderloin, fat trimmed |1/4 cup (43g) brown sugar |1/4 cup (88g) maple syrup |1¼ cup (188g) sugar snap peas |1.5 pounds (24) baby red potatoes |1 cup (225ml) water ",
    methods:
      "Place pork tenderloin in crock pot.|Mix syrup and brown sugar together in mixing bowl. |Rub syrup and brown sugar over pork tenderloin, covering the entire piece. Leave a bit of the mixture in the bowl. |Add vegetables to the crock pot.|Add 1 cup (225ml) water to the crock pot. |Pour the remaining syrup and brown sugar mixture over the vegetables.|Put lid on crock pot and cook for 7.5 hours on low. ",
    serves: 4,
    fat: 3.4,
    calories: 367,
    cholesterol: 92,
    carbohydrate: 51,
    saturated: 1.1,
    sodium: 113,
    protein: 34,
    difficulty: "medium",
    category: "dinner|family favorites|hearty meal",
  },
  {
    title: "South of the Border Taco Soup",
    slug: "south-of-the-border-taco-soup",
    img: "south-of-the-border-taco-soup",
    hasImage: true,
    description:
      "This is an easy one-dish meal for a cool evening. It can be made with “extra-lean” ground beef or ground turkey. Serve with a salad and fruit.",
    ingredients:
      "1 lb. (16oz) 95% fat-free ground beef or ground turkey|1 onion, minced|1 green pepper, chopped (optional)|15 oz. (425g) can “no salt added” corn or 1½ cups frozen corn|15 oz. (425g) can creamed corn (does not actually contain cream!)|1 can diced tomatoes, no added salt, or ﬁre-roasted, if desired|15 oz. (425g) can pinto beans (rinsed)|1/4 cup (55g) salsa|2 cups (450ml) chicken broth (lower sodium)|1/4 cup (62g) fat-free ranch dressing|1/2 pkg. taco seasoning mix|Optional topping*: Baked, lower fat corn chips and fat-free or low-fat shredded cheddar cheese",
    methods:
      "In a stock pot prepared with nonstick cooking spray, sauté onions for a few minutes, add beef or turkey, and brown until cooked through. |Add rest of ingredients and heat until warm. |Serve in bowls and top with crumbled chips and some shredded cheese, if desired.|*Optional toppings not included in nutrition analysis.",
    serves: 5,
    fat: 8,
    calories: 274,
    cholesterol: 55,
    carbohydrate: 36,
    saturated: 0,
    sodium: 765,
    protein: 28,
    difficulty: "easy",
    category: "dinner|hearty meal",
  },
  {
    title: "Spicy Chicken with Sesame Noodles",
    slug: "spicy-chicken-with-sesame-noodles",
    img: "spicy-chicken-with-sesame-noodles",
    hasImage: true,
    description:
      "A quick and easy dish that is very filling and quite tasty. Best served at room temperature or even cold as leftovers. ",
    ingredients:
      "1 1/2 pounds (24oz) boneless, skinless chicken breast|12 oz (340g) linguine noodles|2 colored bell peppers (red, yellow or orange), cut into thin strips|2 cups (350g) broccoli crowns, chopped|4 green onions/spring onions, cut stems into 1/2” pieces, dice green tops|1-2 tablespoons Cajun spice mix|1 tablespoons onion powder|1 tablespoons garlic powder|1-2 teaspoons salt|1 tablespoons black pepper|1/2 cup (115g) sweet chili sauce|2 tablespoons sesame oil|Cooking spray",
    methods:
      "Heat water for pasta, cook as directed, drain and toss with sesame oil while noodles are hot. Let cool while cooking the chicken. |Cut chicken into ¾–1” cubes. |Add Cajun spice, onion & garlic powder, salt and pepper to zip top plastic bag. |Add chicken to bag, shake well to coat. |Spray a wok or large frying pan with pan spray, put on medium high heat and cook chicken until done. |Remove from pan and spray pan again. |Add peppers, sauté for 5 minutes, then add broccoli and green onion/spring onion stems. Sauté until broccoli is tender. |Toss vegetables and chicken with the noodles, mixing well. |Add the sweet chili sauce and green onion/spring onion tops, toss again. Serve. ",
    note:
      "To lower fat to 7.4 grams per serving, use only 1 tablespoon sesame oil.| For bigger appetites (and fat allowances) divide recipe by 4 providing 680 calories with 14.6g fat per serving.",
    serves: 6,
    time: 40,
    fat: 9.3,
    calories: 467,
    cholesterol: 83,
    carbohydrate: 57,
    saturated: 1.3,
    sodium: 654,
    protein: 34,
    fiber: 9,
    difficulty: "easy",
    category: "dinner|family favorites|quick and easy",
  },
  {
    title: "Spinach Cheese Bake",
    slug: "spinach-cheese-bake",
    img: "spinach-cheese-bake",
    hasImage: true,
    description:
      "Easy to prepare. Always a favorite. Serve as a dinner, lunch, or brunch entrée with fresh fruit and salad.",
    ingredients:
      "10-12 oz. (283-340g) pkg. frozen chopped spinach, thaw and drain well|4 egg whites|16 oz. (450g) nonfat cottage cheese|16 oz. (450g) 2% low-fat cheddar cheese or mozzarella cheese, shredded*|3 tbsp. ﬂour|1/4 tsp. garlic powder|black pepper, to taste",
    methods:
      "Preheat oven to 350°F (176°C). |Mix all ingredients together and place in an 8”x8” baking pan prepared with nonstick cooking spray. |Bake for 35-45 minutes (until knife comes out clean). |Cool 10 minutes.",
    note:
      "*To lower fat to 1 g/serving, use nonfat shredded cheese. To add 2 grams of fat/serving, sprinkle with 2 tbsp. of slivered almonds.",
    serves: 4,
    time: 55,
    fat: 7.5,
    calories: 240,
    cholesterol: 10,
    carbohydrate: 14,
    saturated: 4.5,
    sodium: 790,
    protein: 29,
    difficulty: "easy",
    category: "dinner|lunch|family favorites",
  },
  {
    title: "Spinach Cheese Balls",
    slug: "spinach-cheese-balls",
    img: "spinach-cheese-balls",
    hasImage: true,
    description:
      "Serve this low fat appetizer for company, especially during the  holidays.  Prepare a day ahead and bake fresh for the occasion.  Leftovers can be microwaved.",
    ingredients:
      "10 oz. (283g) package frozen, chopped spinach, thaw and drain well|1/2 cup egg substitute or 3 egg whites|3/4 cup (70g) bread crumbs|1/3 cup (35g) Parmesan cheese|1/4 cup (40g) onion, finely minced|1/2 tsp. poultry seasoning |1/4 tsp. garlic powder|1/8 tsp. pepper",
    methods:
      "Preheat oven to 350°F (176°C). |Combine all ingredients and stir to mix.|Shape into 18 one inch balls. Place on non-greased cookie sheet.|Bake for 15-20 minutes.|Serve warm with honey mustard or another favorite dipping sauce (not included in analysis).",
    serves: 4,
    time: 30,
    fat: 2,
    calories: 96,
    cholesterol: 4,
    carbohydrate: 13,
    saturated: 1,
    sodium: 300,
    protein: 7,
    difficulty: "easy",
    category: "appetizer|family favorites|quick and easy|vegetarian",
  },
  {
    title: "Springtime Shrimp Flatbread",
    slug: "springtime-shrimp-flatbread",
    img: "springtime-shrimp-flatbread",
    hasImage: true,
    ingredients:
      "4 jumbo shrimp (16/20 per pound) |2 Roma tomatoes|1/2 cup (10g) fresh basil|1 tablespoon minced fresh garlic|Salt and pepper|1 cup (225g) fresh baby spinach|1/4 cup (25g) nonfat mozzarella|1 whole wheat pita",
    methods:
      "Preheat oven to 275°F (135°C). |Peel and devein shrimp and place them on a grill or hot skillet. Cook until halfway done. |Remove them from the heat and cut into 1/4 inch pieces.|Cut the tomatoes into quarters and toss with minced garlic and half of the basil. Add pinch of salt and pepper. Cook in the oven for 20 minutes.|After removing the tomatoes, turn the oven up to 400°F (204°C). |Place the pita on a baking sheet. Top with shrimp tomato mixture, spinach and mozzarella. |Bake until crispy. Take out and sprinkle with remaining basil. ",
    serves: 1,
    fat: 4.2,
    calories: 385,
    cholesterol: 188,
    carbohydrate: 44,
    sodium: 660,
    protein: 41,
    difficulty: "medium",
    category: "lunch",
  },
  {
    title: "Stacee’s PB Chocolate Shake",
    slug: "stacees-pb2-shake",
    img: "stacees-pb2-shake",
    hasImage: true,
    description:
      "Great idea for a shake that combines chocolate and peanut butter!",
    ingredients:
      "3/4 cup (169ml) fat-free milk |2 tablespoons Peanut butter powder|2 tablespoons chocolate syrup |6-8 ice cubes",
    methods: "Put all ingredients in a blender and blend until frothy.",
    serves: 1,
    time: 10,
    fat: 2.1,
    calories: 337,
    cholesterol: 7,
    carbohydrate: 66,
    sodium: 286,
    protein: 17,
    fiber: 3,
    difficulty: "easy",
    category: "snack|quick and easy|family favorites",
  },
  {
    title: "Strawberry Spinach Salad",
    slug: "strawberry-spinach-salad",
    img: "strawberry-spinach-salad",
    hasImage: true,
    description: "A light and tasty salad, perfect addition to any meal!",
    ingredients:
      "3 cups (675g) packed of fresh baby spinach |2 cups (400g) of fresh strawberries, sliced|3 tablespoons of shredded Parmesan cheese|1/2 cup (113ml) Balsamic vinegar|1/4 cup (88g) of honey|Dash of salt and pepper",
    methods:
      "Mix and toss spinach, strawberries and cheese.|Mix dressing ingredients in a medium bowl add to salad and toss.",
    serves: 3,
    time: 10,
    fat: 1.7,
    calories: 178,
    cholesterol: 4,
    carbohydrate: 39,
    sodium: 113,
    protein: 3,
    fiber: 2,
    difficulty: "easy",
    category: "lunch|quick and easy|vegetarian",
  },
  {
    title: "Stuffed Peppers",
    slug: "stuffed-peppers",
    img: "stuffed-peppers",
    hasImage: true,
    ingredients:
      "4 large green bell peppers|1/2 pound 96% lean ground beef|1/4 cup (40g) chopped onion|1/2 teaspoon chili powder|1/4 teaspoon cayenne pepper (optional)|1 garlic clove, minced|1/4 teaspoon pepper|1/2 teaspoon salt|1/2 tablespoon Worcestershire sauce|1½ cups (375g) cooked brown rice|1/2 cup (50g) shredded non-fat cheddar cheese, divided|8 ounce (226g) can tomato sauce",
    methods:
      "Preheat oven to 350°F (176°C)|Place meat in a large skillet and cook over medium heat, stirring to crumble until the meat is no longer pink. Stir in the onion, chili powder, garlic, pepper, salt and Worcestershire sauce and continue to cook until the onion is tender. |Remove skillet from the heat and stir in the rice, tomato sauce and 1/4 cup (25g) of the cheese.|Cut the tops off the peppers and remove the membranes and seeds. Fill the peppers with the meat mixture. |Place the peppers upright in a casserole dish. Cover with aluminum foil and bake at 350°F (176°C) for 55 minutes then take peppers out and sprinkle the remaining cheddar cheese evenly on each pepper. Return to oven and cook for 5 more minutes without the foil. Serve hot.",
    serves: 4,
    fat: 3.4,
    calories: 233,
    cholesterol: 31,
    carbohydrate: 34,
    sodium: 766,
    protein: 18,
    fiber: 4,
    difficulty: "easy",
    category: "lunch|vegetarian",
  },
  {
    title: "Summer Veggie Gazpacho",
    slug: "summer-veggie-gazpacho",
    img: "summer-veggie-gazpacho",
    hasImage: true,
    description: "This is an enjoyable way to eat your veggies.",
    ingredients:
      "2 cups (450ml) tomato or vegetable juice, low sodium, if desired|3 tomatoes, quartered|1 green pepper, quartered|1 cucumber, peeled and seeded |1/2 onion|juice from 1/2 lemon|juice from 1 lime|1 tbsp. red wine vinegar|3/4 tsp. each of tarragon and basil ¼ cup fresh parsley|dash or 2 hot pepper sauce |dash or 2 ground cumin|black pepper, to taste",
    methods:
      "Combine all ingredients in a food processor and pulse until desired consistency. |Chill for at least 2 hours. |Garnish with yogurt or nonfat sour cream/greek yogurt and serve.|Makes 4 cups.",
    serves: 4,
    fat: 0,
    calories: 56,
    cholesterol: 0,
    carbohydrate: 11,
    sodium: 300,
    protein: 2,
    fiber: 2,
    difficulty: "easy",
    category: "appetizer|vegetarian",
  },
  {
    title: "Super-Easy Chili",
    slug: "super-easy-chili",
    img: "super-easy-chili",
    hasImage: true,
    description: "A heart-warming meal for a chilly night.",
    ingredients:
      "1 lb. (16oz) extra-lean ground turkey or ground beef (at least 95% fat-free) |14 oz. (396g) can diced tomatoes|6 oz. (170g) can tomato paste|15 oz. (425g) can pinto or kidney beans, rinsed and drained|1 onion, chopped|1-2 tbsp. chili powder|1/2 tsp. garlic powder",
    methods:
      "Brown turkey and onions in a large, nonstick skillet. |Add rest of ingredients and 3/4 cup (169ml) water, then simmer.",
    serves: 4,
    fat: 6,
    calories: 358,
    cholesterol: 80,
    carbohydrate: 45,
    sodium: 468,
    protein: 31,
    difficulty: "easy",
    category: "dinner|hearty meal|family favorites",
  },
  {
    title: "Sweet Potato Casserole",
    slug: "sweet-potato-casserole",
    img: "sweet-potato-casserole",
    hasImage: true,
    description: "A family favorite!",
    ingredients:
      "6 cups (1950g) cooked, mashed sweet potatoes, from fresh, boiled  and with skin removed|1/2 cup (113ml) skim milk|1 cup (200g) artificial sweetener or other non-calorie sugar substitute |1/4 cup (2 eggs) egg whites|1 tbsp. vanilla|3 optional toppings (not included in analysis):| 1/2 cup (100g) brown sugar and 1/2 cup (100g) artificial sweetener|2 cups (100g) mini marshmallows|Cinnamon and nutmeg",
    methods:
      "Preheat oven to 350°F (176°C)|Boil sweet potatoes until done, let cool and remove skin.|Mash and whip with all the rest of the liquid ingredients.|Pour into a non-stick sprayed, prepared 9x13 inch casserole dish.|Bake for 30 minutes. |If an optional topping is used, add the topping in the last 10 minutes of cooking.",
    serves: 16,
    time: 60,
    fat: 0.2,
    calories: 100,
    cholesterol: 0,
    carbohydrate: 24,
    sodium: 43,
    protein: 2,
    fiber: 3,
    difficulty: "easy",
    category: "dinner|family favorites",
  },
  {
    title: "Texas Caviar",
    slug: "texas-caviar",
    img: "texas-caviar",
    hasImage: true,
    description:
      "Also known as Southern Caviar or Cowboy Caviar, this is an easy appetizer to throw together in minutes the night before you are having guests over! ",
    ingredients:
      "1 yellow bell pepper|1 orange bell pepper|1 red bell pepper|2 jalapeno peppers|1 (15 ounce)(425g) can black-eyed peas|1 (15 ounce)(425g) can black beans|1 (15 ounce)(425g) can white sweet corn|1 (16 ounce)(453g) bottle Fat Free Italian dressing|4 roma tomatoes|1 bunch coriander/cilantro",
    methods:
      "Remove membranes and seeds from peppers.|Dice yellow, orange, and red peppers and place in a large plastic bowl|Finely chop jalapenos and place into the same bowl|Drain and rinse the black beans, black-eye peas, and corn and add to peppers.|Add Fat Free Italian dressing and stir well.|Cover and marinate in refrigerator for at least 8 hours.|Remove from fridge and drain excess liquid from mixture.|Dice tomatoes and coriander/cilantro and stir into mixture.",
    serves: 8,
    fat: 1.9,
    calories: 201,
    cholesterol: 1.1,
    carbohydrate: 39,
    sodium: 806,
    protein: 10,
    fiber: 9,
    difficulty: "easy",
    category: "appetizer|family favorites|vegetarian",
  },
  {
    title: "Three Bean Salad",
    slug: "three-bean-salad",
    img: "three-bean-salad",
    hasImage: true,
    description:
      "Serve up this sweet and tangy salad at your next picnic or family gathering.",
    ingredients:
      "15 ounce (425g) can cut green beans|15 ounce (425g) can cut wax beans|15 ounce (425g) can dark red kidney beans|4 ounce (113g) jar of chopped pimientos|1 green bell pepper, chopped|1 small sweet onion, chopped|2 celery ribs, chopped|1/2 cup (100g) sugar|2/3 cup (150ml) vinegar|1½ tablespoons canola oil|1 teaspoon salt|1/2 teaspoon ground black pepper",
    methods:
      "Add first 7 ingredients to a large bowl and mix.|Whisk together sugar, vinegar, canola oil, salt and pepper.|Pour over bean mixture and chill before serving. ",
    serves: 8,
    time: 15,
    fat: 3.3,
    calories: 189,
    cholesterol: 0,
    carbohydrate: 35,
    saturated: 0.3,
    sodium: 539,
    protein: 6,
    fiber: 5,
    difficulty: "easy",
    category: "lunch|family favorites|quick and easy|vegetarian",
  },
  {
    title: "Top Round Steak for Grill",
    slug: "top-round-steak-for-grill",
    img: "top-round-steak-for-grill",
    hasImage: true,
    description:
      "If you are a beef lover, try this recipe with a 4 oz. cooked portion containing 5g of total fat. Just remember to watch your portion to stay within your  max daily fat budget!",
    ingredients:
      "1 lb. (16oz) Top round beef,  trimmed of all visible fat |1/4 cup (56ml) Balsamic vinegar|2 tsp. Worcestershire sauce |2 tsp. Dijon mustard|3 cloves garlic minced|1/8 cup (28ml) Orange juice|1/4 cup (56ml) Water|Pepper to taste",
    methods:
      "Whisk ingredients together.|Tenderize meat by piercing with a fork several times.|Pour marinade over meat and  let sit for 4 hours or overnight.|Cook meat to desired doneness using meat thermometer.|As a suggestion, use cooking spray to saute sliced onions and sliced roasted red peppers (in jar in brine no oil). Spoon over sliced steak  when serving.",
    serves: 4,
    fat: 5.2,
    calories: 235,
    cholesterol: 95,
    carbohydrate: 10,
    saturated: 2.2,
    sodium: 178,
    protein: 34,
    fiber: 0,
    difficulty: "easy",
    category: "dinner",
  },
  {
    title: "Tuna Pasta Un-Casserole",
    slug: "tuna-pasta-un-casserole",
    img: "tuna-pasta-un-casserole",
    hasImage: true,
    description:
      "This is a low-fat, convenient twist to your homestyle tuna noodle  casserole. You can make this from foods you have in your pantry… easy to keep on hand.",
    ingredients:
      "12 oz. (340g) can tuna in water|10 oz. (283g) can Cream of Mushroom Soup| 1/2 cup (113ml) skim milk|1/2 cup (75g) frozen peas|8 oz. (226g) can sliced mushrooms|small jar pimentos|1/2 tsp. garlic powder|white or black pepper, to taste|Fat-free butter spray|3 tbsp. Parmesan cheese as a topping (if desired)|4 cups (800g) of cooked pasta of your choice",
    methods:
      "In a microwave-safe bowl, combine mushrooms, soup and milk and  blend thoroughly. Add tuna, peas, pimentos and spices.|Spray about 15 times with butter spray, if desired, and stir well. |Heat for 4 minutes on high.|Serve over 1 cup of cooked pasta.|Top each portion with 2 tsp.  Parmesan cheese, if desired. ",
    serves: 4,
    time: 25,
    fat: 3,
    calories: 331,
    cholesterol: 35,
    carbohydrate: 48,
    saturated: 0.5,
    sodium: 510,
    protein: 28,
    difficulty: "easy",
    category: "dinner|family favorites|quick and easy",
  },
  {
    title: "Crock Pot Turkey Chili",
    slug: "turkey-chili-in-a-crock-pot",
    img: "turkey-chili-in-a-crock-pot",
    hasImage: true,
    description:
      "This is an easy recipe to make in the morning, leave in crock pot during the day, so you have dinner ready when you come home.",
    ingredients:
      "2 tsp. Canola Oil|1 medium sweet onion, chopped|2 garlic cloves, minced|1 lb. (16oz) extra lean ground turkey breast (99% fat-free)|1 can (15 oz.)(425g) no salt added corn (or use frozen equivalent)|1 can (15 oz.)(425g) no salt added diced tomatoes|1 can (15 oz.)(425g) diced tomatoes with green chilies|1 can (15 oz.)(425g) low sodium Goya dark kidney beans|1/2 packet reduced sodium chili seasoning mix|1/2 tbsp. chili powder|1/2 tbsp. salt-free Fiesta Lime seasoning",
    methods:
      "Sautee onion and garlic in canola oil. |Add turkey meat until browned and cooked through. |Add mixture to crock pot. |Stir in remaining ingredients and simmer in crock pot 4-6 hours.|Garnish with fat-free sour cream/fat free greek yogurt.",
    serves: 6,
    fat: 3,
    calories: 248,
    cholesterol: 47,
    carbohydrate: 29,
    saturated: 0.1,
    sodium: 410,
    protein: 26,
    difficulty: "easy",
    category: "dinner|family favorites|hearty meal",
  },
  {
    title: "Turkey Tamale Pie",
    slug: "turkey-tamale-pie",
    img: "turkey-tamale-pie",
    hasImage: true,
    description: "A warm and ﬁlling meal.",
    ingredients:
      "1 onion, minced|1 green pepper, chopped|3/4 to 1 lb. (12-16oz) ground turkey (extra-lean 99% fat-free)|1 cup (175g) frozen corn|16 oz. (453g) can pinto beans, rinsed |4 oz. (113g) can ﬁre-roasted green chiles (mild)|8 oz. (226g) can tomato sauce (no added salt if desired)|1/4 cup (55g) salsa|1-2 tbsp. chili powder|3-4 tbsp. water|1 cup (120g) cornmeal|2½ cups (560ml) water|1/4 tsp salt, if desired",
    methods:
      "Preheat oven to 375°F (190°C). |Sauté onions and peppers in a pan prepared with nonstick cooking spray. |Add ground turkey and sauté until  no longer pink. |Add corn, beans, chiles, tomato sauce, salsa, chili powder, and water and stir. |Pour ﬁlling into an 8”x8” or 9”x9” baking dish sprayed with nonstick cooking spray.|In a small saucepan, combine water, cornmeal and salt.|Bring to a boil then reduce heat and stir for 1 minute, until topping thickens slightly. Spread over ﬁlling.|Bake for 30 minutes.",
    serves: 4 - 6,
    fat: 2,
    calories: 270,
    cholesterol: 33,
    carbohydrate: 39,
    saturated: 0,
    sodium: 389,
    protein: 24,
    difficulty: "easy",
    category: "dinner|family favorites|hearty meal",
  },
  {
    title: "Turkey Tetrazzini",
    slug: "turkey-tetrazzini",
    img: "turkey-tetrazzini",
    hasImage: true,
    description:
      "Great comfort food to make a head and refrigerate; bake just before you are ready to serve it. ",
    ingredients:
      "10 oz (283g) uncooked spaghetti noodles |1 pound turkey breast cutlets |1/2 teaspoon salt, divided |1/2 teaspoon black pepper, divided |2 tablespoons dry sherry, optional |16 ounce (453g) package pre-sliced mushrooms |1/4 cup (40g) chopped onions |3/4 cup (115g) frozen peas, thawed |3/4 cup (169ml) fat free milk |2/3 cup (163g) fat-free sour cream/fat free greek yogurt |1/3 cup (35g) grated Parmesan cheese |1 can reduced-fat cream of chicken soup (Campbell’s Healthy Request) |Cooking spray |1/3 cup (30g) dry breadcrumbs ",
    methods:
      "Preheat oven to 450°F (232°C). |Cook pasta according to package directions, omitting salt and fat. Drain |Cut turkey breasts into bite sized pieces and sprinkle with ¼ teaspoon salt and ¼ teaspoon pepper. Spray skillet with cooking spray and heat on medium-high. Add turkey to pan and cook until no longer pink. Remove turkey from pan.  |Add the onion, mushrooms and sherry to the pan. Cover and cook 4 minutes or until tender. |Combine peas, milk, sour cream/greek yogurt, cheese and soup in a large bowl. Add remainder salt and pepper along with turkey, pasta and mushroom mixture to the soup mixture. Toss gently to combine. Spoon mixture into a 13x9 inch baking dish coated with cooking spray.|Sprinkle bread crumbs over pasta mixture. Bake at 450°F (232°C) for 12 minutes or until bubbly and thoroughly heated.  ",
    serves: 6,
    fat: 5.3,
    calories: 408,
    cholesterol: 49,
    carbohydrate: 58,
    saturated: 1.5,
    sodium: 650,
    protein: 32,
    fiber: 5,
    difficulty: "medium",
    category: "dinner|family favorites|hearty meal",
  },
  {
    title: "Vegetable Cream Cheese Roll-ups",
    slug: "vegetable-cream-cheese-roll-ups",
    img: "vegetable-cream-cheese-roll-ups",
    hasImage: true,
    description:
      "Whip up a batch of these pinwheels for a delicious change of pace.",
    ingredients:
      "8 oz. (226g) fat-free cream cheese|1 cucumber, peeled, seeded and minced|1 red pepper, minced|1 Roma tomato, minced 1/4 tsp. garlic powder|2 tbsp. dried chives|3 low-fat ﬂour tortillas (soft taco size)",
    methods:
      "Mix all ingredients (except tortillas) and let sit in refrigerator for 2 hours. |Spread evenly on 2-3 ﬂour tortillas. |Roll tightly and cut into pinwheels (about ½-inch thick).|Serve on a tray.",
    serves: 3,
    fat: 3,
    calories: 235,
    cholesterol: 20,
    carbohydrate: 30,
    saturated: 1,
    sodium: 813,
    protein: 22,
    difficulty: "easy",
    category: "dinner|family favorites|vegetarian",
  },
  {
    title: "Vegetarian Chili",
    slug: "vegetarian-chili",
    img: "vegetarian-chili",
    hasImage: true,
    description:
      "Want something warm and delicious for dinner tonight?  Try this vegetarian chili recipe with soy crumbles. You’ll never miss the ground beef. ",
    ingredients:
      "1 tablespoon canola oil |1 large onion, chopped |1-2 cloves garlic, minced|1/2 red bell pepper, chopped|1/2 green bell pepper, chopped|1 Jalapeno pepper, diced|2, 28 ounce (793g) cans of whole peeled tomatoes|3 tablespoons chili powder|1/2 teaspoon cumin|1/2 teaspoon black pepper|1/2 teaspoon oregano|1/2 teaspoon favorite hot sauce (optional)|1/2 teaspoon cayenne pepper (optional)|2, 15.5 ounce (439g) cans low sodium kidney beans, drained",
    methods:
      "Saute onion, garlic, and peppers in canola oil until soft. |In large stock pot, crush tomatoes by hand and add onion pepper mixture. |Add chili powder, cumin, black pepper, oregano, and optional hot sauce and cayenne pepper depending on preference. |Simmer 45 minutes. |Add kidney beans then simmer for additional 15 minutes.|Garnish with fat free sour cream/fat free greek yogurt if you like. ",
    serves: 8,
    time: 75,
    fat: 4.3,
    calories: 231,
    cholesterol: 0,
    carbohydrate: 35,
    saturated: 0,
    sodium: 618,
    protein: 16,
    difficulty: "easy",
    category: "dinner|family favorites|vegetarian",
  },
  {
    title: "Veggie Taco Salad",
    slug: "veggie-taco-salad",
    img: "veggie-taco-salad",
    hasImage: true,
    description:
      "A taco salad made with “veggie” ground beef….you won’t even miss ",
    ingredients:
      "4 cups (300g) Mixed Greens|8-10 cherry tomatoes, sliced in half |1/4 red onion, minced|1/2 green pepper, chopped|1 yellow or red pepper, chopped |1/2 cup (75g) frozen corn, thawed|1, 16 oz. (453g) can less salt black beans, rinse and drain|4 tbsp. sliced black olives (or about 4 large black olives, sliced)|1 cup (1/2, 12 oz. package) veggie “meat crumbles”, taco ﬂavor|1/4 cup (55g) salsa|1 serving baked low fat corn chips (1 oz. or about 18 chips)",
    methods:
      "Sauté veggie ground round for 5-10 minutes to heat thoroughly.|Place other ingredients except salsa and chips in large serving bowl.|Top with warmed ground round, salsa and “crunched” corn chips.",
    serves: 2,
    time: 15,
    fat: 3.5,
    calories: 408,
    cholesterol: 0,
    carbohydrate: 71,
    saturated: 0,
    sodium: 648,
    protein: 30,
    difficulty: "easy",
    category: "lunch|snack|vegetarian|quick and easy",
  },
  {
    title: "Waldorf Salad",
    slug: "waldorf-salad",
    img: "waldorf-salad",
    hasImage: true,
    description: "An Easy Side dish you can make in minutes! ",
    ingredients:
      "2 large apples (red or green)(diced)|1 cup (100g) grapes (halved)|2 cups (450g) chopped celery |1/2 cup (125g) Fat Free Yogurt|1 tablespoon lemon juice|2 tablespoon apple cider vinegar|1 tsp dried tarragon",
    methods:
      "Dice apples.|Combine apples, grapes, and celery in a large bowl|Add rest of ingredients to bowl and mix.|Serve immediately or cover and chill and serve later. ",
    serves: 7,
    time: 15,
    fat: 0.2,
    calories: 59,
    cholesterol: 0.8,
    carbohydrate: 12,
    saturated: 0.05,
    sodium: 25,
    protein: 2,
    fiber: 2,
    difficulty: "easy",
    category: "lunch|vegetarian|quick and easy",
  },
  {
    title: "Warm Artichoke Dip",
    slug: "warm-artichoke-dip",
    img: "warm-artichoke-dip",
    hasImage: true,
    description:
      "Keep these ingredients in your kitchen and you will always be ready for company!",
    ingredients:
      "2, 15 oz (425g) can of artichoke hearts in water, drained well |1/2 cup (50g) grated parmesan cheese |1/2 cup (115g) fat-free mayonnaise (Kraft makes one) |1/2 tsp garlic powder |Nonstick cooking spray |24 Melba Toasts ",
    methods:
      "Preheat oven to 350°F (176°C).| Place all ingredients in a blender or food processor and puree until somewhat smooth but with slight texture. | Place in small baking dish (that you will also use to serve it) prepared with nonstick cooking spray.|  Bake for 25-30 minutes until warm and brown around the edges. | Serve with plain low fat crackers like Melba Classic Toasts (these are fat free) or use as a dip for carrots.",
    serves: 8,
    time: 40,
    fat: 1.8,
    calories: 111,
    cholesterol: 3,
    carbohydrate: 20,
    saturated: 1,
    sodium: 357,
    protein: 4,
    difficulty: "easy",
    category: "snack|vegetarian|quick and easy",
  },
  {
    title: "Weekend Breakfast Sausage",
    slug: "weekend-breakfast-sausage",
    img: "weekend-breakfast-sausage",
    hasImage: true,
    description:
      "These sausages have such a great ﬂavor. No one will guess that they are made from turkey breast.",
    ingredients:
      "1 lb. (16oz) extra-lean ground turkey breast (99% fat-free)|1 slice whole-wheat bread,  processed into crumbs|1 egg white|2 cups (150g) portabella mushrooms,  ground in a food processor|2 tsp. ground sage|1/2 tsp. ground thyme|1/2 tsp. cayenne pepper (or a little less for milder taste) |1/4 tsp. garlic powder|1/2 tsp. salt|black pepper, to taste|4 bay leaves",
    methods:
      "In a large bowl, mix all ingredients (except bay leaves) very well.|Form into 12 patties. “Dry fry” the patties on a nonstick pan prepared with nonstick cooking spray. |Toss in bay leaves for extra ﬂavor. |Turn and cook until done.",
    serves: 4,
    time: 25,
    fat: 1.5,
    calories: 146,
    cholesterol: 55,
    carbohydrate: 5,
    saturated: 0.5,
    sodium: 350,
    protein: 28,
    difficulty: "easy",
    category: "breakfast|quick and easy",
  },
  {
    title: "White Bean Dip",
    slug: "white-bean-dip",
    img: "white-bean-dip",
    hasImage: true,
    description: "This is a heart heal",
    ingredients:
      "2, 15 ounce (425g) cans of white northern or cannellini beans, drained|1 tablespoon of oregano or Italian dried herbs|1/2 teaspoon of garlic powder |1/4 cup (56ml) of water",
    methods:
      "Add all ingredients in a blender or food processor|Blend until smooth |Serve with cut vegetables or low fat crackers",
    serves: 8,
    time: 10,
    fat: 0.4,
    calories: 85,
    cholesterol: 0,
    carbohydrate: 14,
    saturated: 0,
    sodium: 226,
    protein: 5,
    fiber: 5,
    difficulty: "easy",
    category: "snack|quick and easy",
  },
  {
    title: "Whole-Wheat Couscous Pilaf",
    slug: "whole-wheat-couscous-pilaf",
    img: "whole-wheat-couscous-pilaf",
    hasImage: true,
    description:
      "Couscous is a grain made of semolina or other ground  cracked wheat. In this dish, couscous is mixed with chopped vegetables and garbanzo beans.",
    ingredients:
      "1 cup (225ml) fat-free vegetable broth |3/4 cup (124g) whole-wheat couscous |1 cup (175g) or more broccoli ﬂorets |1-2 carrots, shredded|8 mushrooms or more, sliced |2 green onions/spring onions, sliced|1/2 cup (75g) peas, frozen|1 cup garbanzo beans, less salt, rinsed|1 clove garlic, minced|1 tsp. canola or olive oil",
    methods:
      "Bring broth to a boil.|Add couscous. Remove from heat and let stand for 5 minutes, covered. |In a sauté pan, sauté garlic with  oil, then add vegetables and beans.|Stir-fry for about 5-8 minutes. |Combine veggie mixture into couscous and serve.",
    serves: 4,
    time: 25,
    fat: 1.5,
    calories: 233,
    cholesterol: 0,
    carbohydrate: 45,
    saturated: 0,
    sodium: 155,
    protein: 11,
    difficulty: "easy",
    category: "lunch|quick and easy|vegetarian",
  },
  {
    title: "Low Fat Zucchini/Courgette Bread",
    slug: "low-fat-zucchini-bread",
    img: "low-fat-zucchini-bread",
    hasImage: true,
    description:
      "Don’t know what to do with all that zucchini/courgette from your garden?  Try this easy recipe for a yummy sweet, healthy treat.",
    ingredients:
      "3½ cups (490g) flour |1 teaspoon baking powder |1 teaspoon baking soda |1/2 teaspoon salt |2 teaspoon cinnamon |1/2 teaspoon nutmeg |3/4 cup (180g) egg substitute |1¾ cups (350g) sugar |3/4 cup (190g) nonfat plain yogurt |1½ teaspoon vanilla extract|2 cups (300g) lightly packed, coarsely grated zucchini/courgette |1 cup (150g) raisins (optional) ",
    methods:
      "Preheat oven to 350°F (176°C). |Prepare two 9x5-inch loaf pans with cooking spray. |Sift dry ingredients together in large bowl. |In another large bowl, beat egg substitute, sugar, yogurt, and vanilla. |Gradually add dry ingredients to wet ingredients. |Stir in zucchini/courgette and optional raisins.   |Divide between 2 loaf pans. |Bake for 50 minutes (or until toothpick in center comes out clean).",
    serves: 24,
    time: 70,
    fat: 0.5,
    calories: 135,
    cholesterol: 0,
    carbohydrate: 29,
    saturated: 0,
    sodium: 137,
    protein: 3,
    fiber: 1,
    difficulty: "medium",
    category: "snack|vegetarian",
  },
  {
    title: "Spicy Chicken Pitta Pockets",
    slug: "spicy-chicken-pitta-pockets",
    img: "spicy-chicken-pitta-pockets",
    hasImage: true,
    description:
      "Make these easy harissa-spiced chicken pittas for lunch or a quick dinner.",
    ingredients:
      "2 tbsp Greek Style low-fat yogurt |2 tsp harissa paste |1 tbsp olive oil  |1 small onion |1 large chicken breast fillet, skin removed and cut into slices |1 red pepper, de-seeded and sliced|100g (1 cup) mushrooms, sliced |2 wholemeal/whole grain pitta breads |Salad leaves, washed and dried |Two lemon quarters – optional ",
    methods:
      "Toast the pitta breads, when ready they should puff up. Slice along the top edge to form a pocket which you can fill later with the chicken mixture and salad. |Meanwhile, cook the onion gently in the oil for a few minutes until softened.|Add the sliced chicken and cook until brown on all sides|Add the mushrooms and peppers and continue to cook for another 5-10 minutes until the mushrooms are soft and the peppers are softening but still have some crunch.  Stir in the harissa paste, making sure that all the chicken and vegetables are coated. Allow to cool a little.|Now you need to assemble the contents.  Start but putting a layer of salad into each pitta followed by the chicken mixture. Finnish with a layer of salad leaves and a drizzle of yogurt.|Serve immediately with lemon quarters| Recipe Tip: You can use the same combination in sandwiches or wraps. Choose wholemeal/whole grain varieties. ",
    serves: 2,
    time: 25,
    fat: 9,
    calories: 440,
    saturated: 1.5,
    sodium: 1.3,
    difficulty: "easy",
    heartuk: true,
    category: "snack|dinner|family favorites|quick and easy",
  },
  {
    title: "Spicy Burgers",
    slug: "spicy-burgers",
    img: "spicy-burgers",
    hasImage: true,
    description:
      "These crowd-pleasing paprika-scented burgers are light but flavoursome and a doddle to make.",
    ingredients:
      "450g (1lb) minced/ground chicken |1 onion, grated |2 garlic cloves, crushed |1 teaspoon paprika ",
    methods:
      "Preheat the grill to medium. |Mix all the ingredients together. |Shape the mixture into 8 burgers. |Grill under medium heat for 4-5 minutes each side or until the meat is completely cooked through. |Serve with salad or in wholemeal rolls. ",
    serves: 4,
    time: 15,
    fat: 1.4,
    calories: 110,
    saturated: 0.4,
    sodium: 0.17,
    difficulty: "easy",
    heartuk: true,
    category: "dinner|family favorites|quick and easy",
  },
  {
    title: "Herb and Garlic Baked Chicken",
    slug: "herb-and-garlic-baked-chicken",
    img: "herb-and-garlic-baked-chicken",
    hasImage: true,
    description:
      "The combination of herbs and garlic mean you don’t need to add salt for flavourful, perfectly cooked chicken.",
    ingredients:
      "4 skinless chicken breasts, trimmed of all fat|A handful of chopped herbs such as tarragon, sage, parsley|3 garlic cloves crushed|Oil and water spray|600-650g (4 - 5 cups) stir fried vegetables (choose from onions, peppers, mushrooms, shredded cabbage, lightly boiled vegetable batons, canned kidney beans)",
    methods:
      "Combine the garlic and herbs together.|Make 3-4 shallow cuts in the chicken breasts. Stuff with the garlic and herb mixture.|Spray a baking tray with the oil and water spray. Add the chicken and spray again.|Lightly cover with foil to stop it burning or drying out in the oven.|Cook for 30 minutes at 200C/400F/Gas 6 or until the juices run clear when a skewer is poked into the chicken.|Lightly coat a frying pan with oil and water spray.  Heat the pan, add the vegetables and cook for a few minutes until tender.|Serve with the chicken.",
    serves: 4,
    time: 45,
    fat: 2.7,
    calories: 186,
    saturated: 0.6,
    sodium: 1,
    difficulty: "easy",
    heartuk: true,
    category: "dinner|lunch|family favorites",
  },
  {
    title: "Spicy Pasta",
    slug: "spicy-pasta",
    img: "spicy-pasta",
    hasImage: true,
    description:
      "A low fat, fail-safe pasta dish with rich, bold flavours that you can cook in less than half an hour.",
    ingredients:
      "450g (3½ cups) wholewheat penne or other pasta shape |1 tablespoon olive oil |1 teaspoon cumin seeds |1 teaspoon mustard seeds |1 large onion, chopped |1 teaspoon garlic, chopped |2 green chillies, finely chopped |225g (2 cups) button mushrooms |400ml (14oz can) of tomatoes, liquidised |1 teaspoon garam masala |1 teaspoon chopped coriander/cilantro",
    methods:
      "Cook the pasta as directed on the packet, preferably leaving it slightly undercooked. Drain well. |Heat a large, non-stick pan. Add the oil followed by the cumin seeds, mustard seeds and onions. Cook until onions light brown. Add the garlic, chillies and mushrooms and continue to cook for another 2 minutes. |Add the tomatoes, bring to the boil. |Add the garam masala and reduce the heat and simmer for 5 minutes.|Stir in the pasta. Garnish with coriander/cilantro and serve hot. ",
    serves: 6,
    time: 28,
    fat: 4.7,
    calories: 339,
    saturated: 0.7,
    difficulty: "easy",
    heartuk: true,
    category: "dinner|family favorites|quick and easy|vegetarian",
  },
  {
    title: "Chicken Casserole",
    slug: "chicken-casserole",
    img: "chicken-casserole",
    hasImage: true,
    description:
      "Treat your family to this comforting chicken casserole that delivers on taste whilst being low-fat.",
    ingredients:
      "1kg (2lbs) chicken pieces |1 large onion, diced |6 garlic cloves |1 tablespoon rapeseed oil |1 x 400g (14oz) can chopped tomatoes |1/2 teaspoon dried herbs such as tarragon, coriander/cilantro or oregano |75ml (2.5 oz) red wine vinegar|75ml (2.5 oz) white wine vinegar  |150ml (5 oz) low-salt chicken stock |pepper to season",
    methods:
      "Preheat oven to 150C/300F or Gas Mark 3 |Skin the chicken pieces and remove all signs of visible fat.|In a large pan or hob proof casserole dish cook the onion and whole cloves in the oil without colour for 5 minutes.|Add the canned tomatoes,dried herbs, vinegars and chicken stock and bring to a simmer.|Allow to reduce a little.|Add the chicken pieces to sauce and cook for 5 minutes.|Transfer to a casserole dish, cover and place in the preheated oven for 50 minutes.|Serve with potatoes or chunks of wholegrain bread and green beans.",
    serves: 4,
    time: 80,
    fat: 6.7,
    calories: 255,
    saturated: 1.2,
    sodium: 0.43,
    difficulty: "easy",
    heartuk: true,
    category: "dinner|family favorites|hearty meal",
  },
  {
    title: "Cottage Pie",
    slug: "cottage-pie",
    img: "cottage-pie",
    hasImage: true,
    description:
      "A traditional family favorite made-over; a low-fat beef mince gratin with a fluffy mash topping.",
    ingredients:
      "400g (1lb) lean minced/ground beef |1 tablespoon vegetable oil |1 large onion, chopped |1 garlic clove, crushed or chopped  |1 tablespoon Worcestershire sauce (optional) |1 large carrot, diced |1-2 tablespoons tomato puree |50g (1/4 cup) frozen peas |1 low-salt beef stock cube |1 400g (14oz) can chopped tomatoes |500g (17oz) peeled potatoes |2-3 tablespoons skimmed milk |1 tablespoon vegetable spread (such as olive or sunflower) |Pepper to season",
    methods:
      "Preheat oven to 200C/400F or gas mark 6. |For the filling: |Warm the oil in a large saucepan or frying pan.  Add the chopped onion and garlic and cook for 5 minutes without colouring.|Add the minced beef, and continue to cook until browned.|Add the stock cube, canned tomatoes, tomato puree, Worcestershire sauce  and carrot pieces.|Bring to the boil and simmer for 10-15 minutes.  The sauce should thicken nicely.|Add frozen peas and continue to simmer for 5 minutes, season with pepper.|For the topping:|Peel the potatoes and cut into egg sized pieces. Boil for about 20 minutes or until tender. |Drain the potatoes, add the vegetable spread and milk and mash until smooth and creamy.|Making the pie: |Put the meat filling into a casserole or baking dish, and top with the mashed potato|Bake for 45 minutes (30 minutes from hot), until golden brown on top|Serve on its own or with seasonal vegetables|Recipe Tip: Try adding canned beans to the meat mixture instead of peas, adding chopped mushrooms or for a smoother sauce puree the chopped tomatoes before adding to the pan. ",
    serves: 4,
    time: 50,
    fat: 12,
    calories: 322,
    saturated: 3.1,
    sodium: 1.3,
    difficulty: "medium",
    heartuk: true,
    category: "dinner|family favorites|hearty meal",
  },
  {
    title: "Butternut Squash Risotto",
    slug: "butternut-squash-risotto",
    img: "butternut-squash-risotto",
    hasImage: true,
    description:
      "Sweet from the butternut squash with the hint of salt from the bacon, this will become a family favorite.",
    ingredients:
      "4 pieces of bacon, trimmed of fat and chopped into pieces  |1 tablespoon rapeseed oil |1 large onion finely chopped |1 small butternut squash, peeled and chopped into bite-sized pieces |300g (2 1/2 cups) risotto rice (Arborio) |1 litre (4 cups) stock made from 2 low-salt vegetable or chicken stock cubes |pepper to season ",
    methods:
      "Cook the bacon and butternut squash in a large non-stick frying pan with the oil for 10 minutes |Add the onion and continue to cook for about 5 minutes.  The squash and onion should both have softened |Stir in the rice, then add the hot stock, stir again and bring to a simmer |Cook for 15-20 minutes, stirring regularly to make sure the rice does not stick  |When almost all the stock has been absorbed and the rice is tender the dish is ready. Season and serve| Recipe Tip: To reduce the salt further, replace the bacon with 1 small chicken breast, cut into strips. ",
    serves: 4,
    time: 50,
    fat: 9.1,
    calories: 447,
    saturated: 2.4,
    sodium: 1.2,
    difficulty: "easy",
    heartuk: true,
    category: "dinner|family favorites|vegetarian",
  },
  {
    title: "Mediterranean Vegetable Lasagne",
    slug: "mediterranean-vegetable-lasagne",
    img: "mediterranean-vegetable-lasagne",
    hasImage: true,
    description:
      "This layered pasta dish is a heart-healthy alternative to the traditional version with delicious roasted Mediterranean veggies replacing the meat ragu and combined with a lighter but still creamy sauce - Buon Appetito!",
    ingredients:
      "2 aubergines/eggplants, cut into chunks |2 red onions, cut into wedges |2 red peppers, cut into strips |4 garlic cloves, finely chopped |3 tablespoons olive oil |450g (4 cups) courgettes, sliced |225g (8oz) lasagne sheets |30g (2 tbsp) parmesan cheese|900ml (30 fl oz) skimmed milk |60g (2oz) olive spread |70g (2.5oz) plain flour |125g (1/3 cup) reduced fat hard cheese (we used Edam) ",
    methods:
      "Preheat the oven to 220°C/fan200°C/gas 7/428°F. Mix the aubergine, onion, pepper, garlic and half the oil in a bowl. Transfer to a large roasting tin and cook for 30 minutes or until soft. |Meanwhile, heat the rest of the oil in a frying pan. Fry the courgettes for 4 minutes until browned. |Remove the roasted veg from the oven and stir in the courgettes. Lower the oven to 200°C/fan180°C/gas 6/390°F.|For the sauce, bring the milk to the boil in a pan and set aside. Melt the olive spread in a pan, add the flour and cook for 1 minute then remove from the heat. Gradually stir in the milk then bring to the boil whilst stirring and then simmer gently for 10 minutes stirring occasionally. |Cook the lasagne according to the packet instructions.|Add the cheese to the sauce and season with pepper. Spoon a thin layer over the base of a lightly-oiled ovenproof dish, cover with 4 lasagne sheets, overlapping them slightly. Top with half the vegetables, then one-third of the remaining sauce and another 4 sheets of lasagne. Repeat this process once more and then spread over the remaining sauce and sprinkle with Parmesan.  Bake for 40 minutes, until golden. ",
    serves: 8,
    time: 105,
    fat: 13,
    calories: 282,
    saturated: 3.6,
    sodium: 0.29,
    difficulty: "medium",
    heartuk: true,
    category: "dinner|family favorites|vegetarian",
  },
  {
    title: "Apple and Blackberry Oat Crumble",
    slug: "apple-and-blackberry-oat-crumble",
    img: "apple-and-blackberry-oat-crumble",
    hasImage: true,
    description: "A warming winter favorite which adds to your five a day.",
    ingredients:
      "7 Braeburn apples, chopped|4 teaspoons sugar or your favorite sweetener|1 teaspoon cinnamon|100g (3/4 cup) blackberries|50ml (1.5 fl oz) water|60g (2oz) oats/porridge oats|120g (4oz) plain wholemeal/whole grain flour|40g (1/3 cup) brown sugar|1 teaspoon cinnamon|75g (2.5oz) light non-hydrogenated margarine (ex. Becel Light)",
    methods:
      "Preheat the oven to 200°C/180°C fan/392°F.|Peel the apples and chop them into 2cm chunks. Sprinkle 4 teaspoons of sweetener and 1 teaspoon of cinnamon, then cover and stew in 50ml water on a medium heat until the apples soften. Check and stir every few minutes.  Once they become soft enough to crush easily with a spoon, turn the heat off and leave to cool. |Whilst the apples are being stewed, prepare the crumble. Combine the oats and plain flour together in a large bowl. Add the sweetener, cinnamon and muscovado brown sugar and mix thoroughly using a spoon.  Using your fingertips, add the spread and rub into the flour and oat mixture to form a crumbly, moist texture until it resembles breadcrumbs.|Pour the stewed apples into an ovenproof dish, stir in the blackberries and sprinkle the crumble topping over it, then bake for 25 minutes until golden and biscuit-like on top.  If preferred, dust with a pinch of icing sugar and drizzle a tablespoon of low-fat custard to serve. ",
    serves: 8,
    time: 45,
    fat: 5.1,
    calories: 230,
    saturated: 1.1,
    sodium: 0.12,
    difficulty: "easy",
    heartuk: true,
    category: "dessert|snack|family favorites|vegetarian",
  },
  {
    title: "Tropical Pavlova",
    slug: "tropical-pavlova",
    img: "tropical-pavlova",
    hasImage: true,
    description:
      "Pavlova is a much-loved dessert and here it’s given a tropical make-over with mango, pineapple and passion fruit.",
    ingredients:
      "3 egg whites|175g (1 cup) caster sugar|1 teaspoon strong black coffee|250g (3/4 cup) Natural fromage frais/low fat cottage cheese blended with a little skim milk, until smooth. |125g (1 cup) mango, diced|125g (1 cup) fresh pineapple, cut into chunks|1-2 passion fruits",
    methods:
      "Whisk the egg whites in a bowl until they are stiff. Fold in 1 tablespoon of the sugar, then gradually whisk in the remainder. The meringue must be glossy and form peaks when spoonfuls are dropped into the bowl. Fold in the black coffee.|Spread the meringue mixture over a large sheet of baking paper to form a 20cm (8 inch) diameter round. Make a slight hollow in the centre of the meringue and cook in a preheated oven, 120 C (250 F), Gas Mark 1/2, for 1 hour until the meringue is crisp. Remove from the oven and leave to cool on the paper for about 10 minutes before peeling off.|When the meringue is cold, fill the hollow in the top with fromage frais. Arrange the mango and pineapple on top, then drizzle the passion fruit seeds and juice over the fruit.| Or you could try these other combinations of fruit to fill the pavlova: Strawberries and mango, Raspberries and blueberries, Cherries and nectarines, Pineapple and papaya",
    serves: 4,
    time: 80,
    fat: 0.5,
    calories: 269,
    saturated: 0.1,
    sodium: 0.18,
    difficulty: "easy",
    heartuk: true,
    category: "dessert|family favorites",
  },
  {
    title: "Fudgy Buttermilk Brownies",
    slug: "fudgy-buttermilk-brownies",
    img: "fudgy-buttermilk-brownies",
    hasImage: true,
    description:
      "We all love to indulge in chocolate but unlike most chocolate recipes this one is made with low fat buttermilk.  So whether you decide to ice or not you are sure to enjoy tucking into these.",
    ingredients:
      "1 teaspoon vegetable oil |100g (3/4 cup) white flour |50g (1/3 cup) wholemeal/whole grain flour |175g (3/4 cup) soft brown sugar |50g (1/3 cup) cocoa powder (for brownies) |1/2 teaspoon baking soda |1 large egg |1 medium cooking apple, sliced, gently cooked in a little water and mashed |120ml (4 fl oz) low fat buttermilk |2 teaspoons vanilla essence |150g (1 cup) icing sugar sifted |50g (1/3 cup) cocoa powder (for the icing) |1-3 tablespoons skimmed milk",
    methods:
      "Preheat the oven to 180°C (360°F).|Lightly grease a 9 inch square baking tray with vegetable oil.  |Stir together both flours, sugar, cocoa powder and baking powder. |Separately in another bowl whisk the egg.  Add the apple, buttermilk and vanilla extract. |Add to the flour mixture and mix until well blended. |Pour into the baking tray. |Bake for 30 minutes.  |If making the icing stir together the icing sugar and cocoa powder  |Gradually add the milk until you reach a spreading consistency.  Spread over the cooled brownies.  When set cut into 16 squares and carefully ease out of the tray. ",
    serves: 16,
    time: 45,
    fat: 2.3,
    calories: 155,
    saturated: 1,
    sodium: 0.22,
    difficulty: "easy",
    heartuk: true,
    category: "dessert|family favorites",
  },
  {
    title: "Pure Fruit Cake",
    slug: "pure-fruit-cake",
    img: "pure-fruit-cake",
    hasImage: true,
    description:
      "The puréed dates act as a fat substitute in this recipe and bind all the fruit together to produce a cake that is rich enough for a Christmas cake. All it needs is Santa!",
    ingredients:
      "Cooking spray for greasing|250g (2 cups) stoned/pitted dates|300ml (10 fl oz) water|175g (1 1/2 cups) seedless/dark raisins|125g (1 cup) sultanas/golden raisins|125g (1 cup) currants|50g (1/3 cup) Candies/candied fruit mixed peel, chopped|175g (1 1/2 cup) wholemeal/whole grain plain flour|3 teaspoons baking powder|1 teaspoon mixed spice/pumpkin pie spice|grated rind and juice of 1 orange or lemon|25g (0.8oz) ground almonds|Can be decorated with a few walnuts and glace cherries (optional)",
    methods:
      "Grease a 1kg (35oz) loaf tin. Place the dates in a saucepan with the measured water and heat gently until they are soft. Remove from the heat and mash with a fork until puréed.|Place the date purée in a bowl with all the remaining ingredients, except the flaked almonds, and 4 tablespoons of water. Mix together well. Spoon the mixture into the prepared tin and level the top. Sprinkle with flaked almonds.|Bake in a preheated oven, 160°C (325°F), Gas Mark 3, for 1½ hours until a skewer inserted into the middle comes out clean. Towards the end of the cooking you may need to protect the top of the cake with foil.|Allow the cake to cool a little in the tin, then turn out and finish cooling on a wire rack.",
    serves: 12,
    time: 105,
    fat: 1.9,
    calories: 250,
    saturated: 0.2,
    sodium: 0.37,
    difficulty: "easy",
    heartuk: true,
    category: "dessert|family favorites",
  },
];
