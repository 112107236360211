export default [
    {
      title: "Apples, applesauce",
      serves: "1 small",
      fat: 0,
      category: "fruit"
    },
    {
      title: "Bananas",
      serves: "1 small",
      fat: 0,
      category: "fruit"
    },
    {
      title: "Berries",
      serves: "1 cup (128g)",
      fat: 0,
      category: "fruit"
    },
    {
      title: "Grapes",
      serves: "1 cup (128g)",
      fat: 0,
      category: "fruit"
    },
    {
      title: "Kiwi",
      serves: "1 medium",
      fat: 0,
      category: "fruit"
    },
    {
      title: "Oranges, tangerines",
      serves: "1 small",
      fat: 0,
      category: "fruit"
    },
    {
      title: "Peaches, nectarines",
      serves: "1 small",
      fat: 0,
      category: "fruit"
    },
    {
      title: "Fruit cocktail and other canned fruit (except grapefruit)",
      serves: "1/2 cup (64g)",
      fat: 0,
      category: "fruit"
    },
    {
      title: "Raisins, other dried fruit",
      serves: "1 ounce (28g)",
      fat: 0,
      category: "fruit"
    },
    {
      title: "banana chips",
      serves: "1 ounce (28g)",
      fat: 9,
      category: "fruit"
    },
    {
      title: "Broccoli",
      serves: "1 cup (128g)",
      fat: 0,
      category: "vegetables"
    },
    {
      title: "Carrots",
      serves: "1 cup (128g)",
      fat: 0,
      category: "vegetables"
    },
    {
      title: "Cauliflower",
      serves: "1 cup (128g)",
      fat: 0,
      category: "vegetables"
    },
    {
      title: "Green beans",
      serves: "1 cup (128g)",
      fat: 0,
      category: "vegetables"
    },
    {
      title: "Salad greens, spinach",
      serves: "1 cup (128g)",
      fat: 0,
      category: "vegetables"
    },
    {
      title: "Tomatoes, canned tomato products",
      serves: "1 large",
      fat: 0,
      category: "vegetables"
    },
    {
      title: "Onions",
      serves: "1 small",
      fat: 0,
      category: "vegetables"
    },
    {
      title: "Potatoes, yams",
      serves: "1 medium ",
      fat: 0,
      category: "vegetables"
    },
    {
      title: "Zucchini",
      serves: "1 medium",
      fat: 0,
      category: "vegetables"
    },
    {
      title: "Frozen vegetables without added fat",
      serves: "1 cup (128g)",
      fat: 0,
      category: "vegetables"
    },
    {
      title: "Canned beans, fat-free refried beans, vegetarian baked beans",
      serves: "1/2cup (64g)",
      fat: 1,
      category: "vegetables"
    },
    {
      title: "Egg whites, egg substitute",
      serves: "1 1/4 cup (425g)",
      fat: 0,
      category: "meat"
    },
    {
      title: "99% fat-free ground turkey",
      serves: "4 ounces",
      fat: 1,
      category: "meat"
    },
    {
      title: "Turkey breast, turkey deli slices",
      serves: "1 ounce (28g)",
      fat: 1,
      category: "meat"
    },
    {
      title: "Low-fat hot dogs like Ball Park®",
      serves: "1 frank",
      fat: 1,
      category: "meat"
    },
    {
      title: "Tuna in water",
      serves: "2 ounces (56g)",
      fat: 1.5,
      category: "meat"
    },
    {
      title: "Lean white fish like sole and tilapia",
      serves: "4 ounces (114g)",
      fat: 2,
      category: "meat"
    },
    {
      title: "Shrimp, scallops, other shellfish",
      serves: "3 ounces (85g)",
      fat: 0.5,
      category: "meat"
    },
    {
      title: "Skim milk",
      serves: "8 ounces (236ml)",
      fat: 0,
      category: "dairy"
    },
    {
      title: "Fat-free cottage or ricotta cheese",
      serves: "1/2 cup (170g)",
      fat: 0,
      category: "dairy"
    },
    {
      title: "Fat-free yogurt or Greek yogurt",
      serves: "8 ounces (236ml)",
      fat: 0,
      category: "dairy"
    },
    {
      title: "Fat-free cheese, cream cheese, sour cream",
      serves: "1 slice, 1 ounce (30mg)",
      fat: 0,
      category: "dairy"
    },
    {
      title: "Dairy Fat-free half and half",
      serves: "1 ounce (30mg)",
      fat: 0,
      category: "dairy"
    },
    {
      title: "Sliced bread",
      serves: "1 slice",
      fat: 0.5,
      category: "bread"
    },
    {
      title: "Dinner roll",
      serves: "1 small",
      fat: 1.5,
      category: "bread"
    },
    {
      title: "bagel",
      serves: "1 small, 2 ounces (56g)",
      fat: 1,
      category: "bread"
    },
    {
      title: "pita bread",
      serves: "1 small",
      fat: 0,
      category: "bread"
    },
    {
      title: "English muffin",
      serves: "2 ounces (56g)",
      fat: 1.5,
      category: "bread"
    },
    {
      title: "Corn tortillas",
      serves: "1 small",
      fat: 1,
      category: "bread"
    },
    {
      title: "fat-free flour tortillas",
      serves: "1 small",
      fat: 0,
      category: "bread"
    },
    {
      title: "Cereals with <1.5 g fat per serving like CheeriosTM, Wheaties®, or Rice ChexTM",
      serves: "3/4 cup (63g)",
      fat: 1.5,
      category: "bread"
    },
    {
      title: "Hot cereal like Cream of WheatTM",
      serves: "1 ounce (30ml)",
      fat: 0,
      category: "bread"
    },
    {
      title: "Plain Pasta, rice",
      serves: "1/2 cup (64g)",
      fat: 0,
      category: "bread"
    },
    {
      title: "Fat-free saltine crackers",
      serves: "5 crackers",
      fat: 0,
      category: "bread"
    },
    {
      title: "matzo",
      serves: "1 ounce (30g)",
      fat: 1,
      category: "bread"
    },
    {
      title: "rice cakes unflavored",
      serves: "1 reg size",
      fat: 0.5,
      category: "bread"
    },
    {
      title: "Pretzel (Dried, salted snack)",
      serves: "1 ounce (30g)",
      fat: 1,
      category: "bread"
    },
    {
      title: "Nonstick cooking spray",
      serves: "1/4 second",
      fat: 0,
      category: "baking"
    },
    {
      title: "Fat-free butter flavored spray",
      serves: "1/4 second",
      fat: 0,
      category: "baking"
    },
    {
      title: "Jelly, jam, fruit spread",
      serves: "2 tbsp",
      fat: 0,
      category: "baking"
    },
    {
      title: "Fat-free salad dressing",
      serves: "2 tbsp",
      fat: 0,
      category: "baking"
    },
    {
      title: "Fresh lemon, lime, vinegars",
      serves: "1 portion",
      fat: 0,
      category: "baking"
    },
    {
      title: "Fat-free mayonnaise",
      serves: "2 tbsp",
      fat: 0,
      category: "baking"
    },
    {
      title: "Condiments like ketchup, mustard, barbeque sauce, teriyaki sauce, soy sauce, salsa, hot sauce, Tabasco®,relish, pickles",
      serves: "per serving on bottle",
      fat: 0,
      category: "baking"
    },
    {
      title: "Boca® original vegan veggie burger (1 g fat per patty)",
      serves: "1 patty",
      fat: 1,
      category: "other"
    },
    {
      title: "CedarLane® low-fat beans, rice and cheese-style burrito (frozen, 1 g fat per burrito)",
      serves: "1 burrito",
      fat: 1,
      category: "other"
    },
    {
      title: "Low-fat peanut butter like PB2® and chocolate PB2® (powdered) peanut butter (1-1.5 g fat/2 tbsp.)",
      serves: "2 tbsp",
      fat: 1.5,
      category: "other"
    },
    {
      title: "Cedar’s® fat-free hummus",
      serves: "2 tbsp",
      fat: 0,
      category: "other"
    },
    {
      title: "Soups (up to 1.5 g fat per cup)",
      serves: "per serving on can",
      fat: 1.5,
      category: "other"
    },
    {
      title: "Fat-free tomato, spaghetti or pizza sauce",
      serves: "1/4 cup (85g)",
      fat: 0,
      category: "other"
    }
   ]