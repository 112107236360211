export default [
    {
        title: "Muffins di banana e crusca",
        slug: "muffins-di-banana-e-crusca",
        img: "banana-bran-muffins",
        hasImage: true,
        description: "Questa ricetta è utile per recuperare le banane troppo mature o che hanno preso colpi e che quindi hanno qualche \"macchia nera\". Ottimi per colazione o come dolcetto.",
        ingredients: "2 banane mature | 250 g di fiocchi di crusca | 225 ml di latte scremato | 2 cucchiai da minestra di succo di mela | 2 albumi d'uovo | 1 cucchiaino da caffè di estratto di vaniglia | 140 g di farina | 75 gdi zucchero | 1 cucchiaio da minestra di lievito in polvere | 1 cucchiaino da caffè di  cannella | 45 g di uvetta, se si desidera ",
        methods: "Preriscaldare il forno a 190 ° C. | In una scodella grande, dopo aver chiacciato le banane con una forchetta, aggiungere tutti gli ingredienti seguendo l'ordine proposto nella lista  e mescolare bene. | Preparare uno stampo per muffin utilizzando di pirottini di carta al fine di evitare di ungere la teglia o utilizzarne una teglia antiaderente . |L'impasto è sufficiente per 12 pirottini. | Cuocere per 20-30 minuti e verificare la cottura con uno stuzzicadente inserendolo nel centro dell'impasto. Il muffin è cotto se lo stuzzicadente esce asciutto. | Raffreddare e disporre su un piatto: buon appetito. I muffin possono essere conservati in frigorifero.",
        notes: "Esiste in commercio uno spray per la cottura senza grassi antiaderente. Può essere utilizzato al posto della carta da forno (rif spray antiaderente zero calorie)",
        time: 40,
        serves: 12,
        fat: 1,
        saturated: 0,
        calories: 141,
        cholesterol: 0,
        carbohydrate: 30,
        protein: 3,
        sodium: 203,
        fiber: 3,
        difficulty: "facile",
        category: "light snacks|breakfast|family favorites",
    },
    {
      title: "Porridge cremoso con frutti di bosco",
      slug: "porridge-cremoso-con-frutti-di-bosco",
      img: "creamy-porridge-with-summer-berries",
      hasImage: true,
      description: "Inizia la giornata con questa colazione nutriente ma deliziosa. L'avena è un'ottima fonte di fibre solubili e i frutti di bosco aiutano l'obiettivo dei  5 pasti al giorno. La frutta può essere fresca, congelata, in scatola o secca .",
      ingredients: "600 ml di acqua | 125 g di porridge di avena | 150 ml di latte scremato | 1 manciata di frutti di bosco freschi, congelati e scongelati, in scatola, cotti o esiccati. La frutta può essere mista o di una sola qualità tra le seguenti: lamponi, fragole, more e mirtilli rossi",
      methods: "Mettere l'acqua e l'avena in un pentolino e portare a ebollizione. Cuocere a fuoco lento per 10-20 minuti, mescolando di tanto in tanto. | Aggiungere il latte scremato, mescolare e cuocere a fuoco lento per qualche altro minuto. | Servire guarnito con i frutti di bosco scelti.",
      notes: "",
      time: 5,
      serves: 2,
      fat: 5.4,
      saturated: 0.9,
      sodium: 90,
      calories: 296,
      difficulty: "facile",
      heartuk: true,
      category:
        "breakfast|family favorites|light snacks|quick and easy|vegetarian",
    },
    {
      title: "Sorpresa croccante",
      slug: "sorpresa-croccante",
      img: "berry-crunch-treat",
      hasImage: true,
      description: "Meravigliosa per colazione, pranzo o spuntino!",
      ingredients: "100 g di frutti di bosco o altri piccoli frutti freschi o congelati | 2 cucchiai di marmellata alla frutta senza zuccheri aggiunti (qualsiasi gusto) | 20 g di muesli |125 g di yogurt greco senza grassi",
      methods: "Mescola tutti insieme e divertiti!",
      notes: "",
      time: 5,
      serves: 1,
      fat: 0.7,
      saturated: 0.18,
      calories: 205,
      cholesterol: 6,
      carbohydrate: 37,
      protein: 13,
      sodium: 63,
      fiber: 5,
      difficulty: "facile",
      category: "quick and easy|breakfast|snack",
    },
    {
      title: "Frappè mattutino",
      slug: "Frappe-mattutino",
      img: "morning-shake",
      hasImage: true,
      description: "Questo spuntino è fantastico quando sei di fretta. Facile da portare con te!",
      ingredients: "225 ml di latte senza grassi | 1/2 banana media | 50 g di mirtilli | 1 cucchiaino di estratto di vaniglia | 1 confezione di dolcificante artificiale | 150 g di ghiaccio",
      methods: "Mettere tutti gli ingredienti nel frullatore e frullare fino a ottenere un composto omogeneo",
      notes: "",
      serves: 1,
      time: 5,
      fat: 0.6,
      calories: 193,
      cholesterol: 5,
      carbohydrate: 38,
      saturated: 0.2,
      sodium: 105,
      fiber: 3,
      difficulty: "facile",
      category: "breakfast|quick and easy"
    },
    {
      title: "Pancakes a basso contenuto di grassi",
      slug: "pancakes-a-basso-contenuto-di-grassi",
      img: "light-and-low-pancakes",
      hasImage: true,
      description: "Questi pancake a basso contenuto di grassi sono deliziosamente versatili e si sposano bene  sia con condimenti salati che dolci.",
      ingredients: "125 g  di farina integrale o farina di grano saraceno| 1 uovo | 300 ml  di latte scremato (se usi farina integrale , ne avrai bisogno un po 'di più) | 1 cucchiaino di olio vegetale",
      methods: "Setacciare la farina in una scodella. Se si utilizza farina integrale, aggiungere anche della crusca . | Sbattere insieme l'uovo, il latte e l'olio, quindi unire lentamente la farina. Mescolare il composto fino a formare una pastella liscia. | Lasciare riposare per circa 20 minuti, quindi rimescolare. | Scaldare un filo d'olio in una padella antiaderente o spruzzare con acqua nebulizzata. Quando l'olio è caldo, aggiungere 2 cucchiai di composto per pancake e roteare la padella in modo che si stenda su tutta la superficie. | Cuocere il pancake per 2 minuti fino a quando la parte inferiore è leggermente dorata, quindi capovolgere e cuocere l'altro lato per un minuto. | Per mantenere caldi i puncakes si possono impilare nel forno. La ricetta dovrebbe essere sufficiente per 8 pancakes. Servire con il condimento che hai scelto dolce o salato.",
      notes: "Esiste in commercio uno spray per la cottura senza grassi antiaderente. Può essere utilizzato per urgere la padella (rif spray antiaderente zero calorie)",
      time: 30,
      serves: 4,
      fat: 3.1,
      saturated: 0.6,
      calories: 162,
      sodium: 140,
      difficulty: "facile",
      heartuk: true,
      category:
        "breakfast|family favorites|light snacks|quick and easy|vegetarian"
    },
    {
      title: "Zuppa di zucca cremosa",
      slug: "zuppa-di-zucca-cremosa",
      img: "creamy-butternut-squash-soup",
      hasImage: true,
      description: "Questa zuppa può essere preparata come semplice zuppa di zucca dolce o, aggiungendo  un po 'di curry in polvere, renderla un poco più piccante. La zuppa può essere servita con pane croccante.",
      ingredients: "1 cipolla tritata | 2 carote a rondelle o tagliate a cubetti | 2 gambi di sedano a cubetti | 340 g di zucca gialla \"pronta all'uso\" già mondata e tagliata | 1 patata a dadini | 2 spicchi d'aglio tritati | 1150 ml   di brodo di pollo senza grassi e a ridotto contenuto di sodio | 1-2 cucchiai da cucina di zucchero o miele (o se preferite dolcificante artificiale) | Pepe qb | 2-3 cucchiaini da caffè di curry in polvere se lo si desidera",
      methods: "In una pentola o in una padella larga antiaderente rosolare le cipolle, le carote, il sedano e l'aglio fino a quando diventano teneri e croccanti. | Mescolare gli ingredienti rimanenti, tranne il curry in polvere, e portare a ebollizione. Quindi ridurre la fiamma e cuocere a fuoco lento per circa 30 minuti fino a quando le verdure sono cotte e molto tenere, pronte per fare purea. | Con un robot da cucina frullare  il composto fino a renderlo liscio. | Se a tutti piace il curry, aggiungere 2-3 cucchiaini di curry in polvere. Altrimenti, aggiungere 1/2 cucchiaino o poco di più per ogni singola porzione.",
      notes: "Esiste in commercio uno spray per la cottura senza grassi antiaderente. Può essere utilizzato per urgere la padella (rif spray antiaderente zero calorie)",
      time: 40,
      serves: 4,
      fat: 0,
      saturated: 0,
      calories: 104,
      cholesterol: 0,
      carbohydrate: 23,
      protein: 3,
      sodium: 600,
      difficulty: "medio",
      category: "family favorites|lunch|dinner|vegetarian|fat free",
    },
    {
      title: "Zuppa di fagioli e pasta",
      slug: "zuppa-di-fagioli-e-pasta",
      img: "bean-and-macaroni-soup",
      hasImage: true,
      description: "Questa zuppa sostanziosa e densa è un pasto abbondante buono per il pranzo o per la cena.",
      ingredients: "400 g (una lattina) di fagioli borlotti o canellini, se precotti sciacquati e scolati | 400 g (una lattina) di pomodori a cubetti | 1 cipolla tritata | 4 carote affettate a rondelle | 1/2 cucchiaino di aglio in polvere | 1/4 di dato per brodo | 80 g di pasta di tipo \"Dadini\"  |700 gr  di acqua (aggiungerne se di desidera una zuppa più liquida)",
      methods: "In una padella antiaderente far rolosare la cipolla e le carote  per 5 minuti. | Aggiungere i pomodori, il dado e l'acqua. Portare a ebollizione | Aggiungere i maccheroncini  e i fagioli e far bollire per 10 minuti mantenendo la pasta al dente. Mescolare spesso per evitare che si attacchi sul fondo. ",
      notes: "Esiste in commercio uno spray per la cottura senza grassi antiaderente. Può essere utilizzato per urgere la padella (rif spray antiaderente zero calorie)",
      time: 20,
      serves: 4,
      fat: 1,
      saturated: 0,
      calories: 197,
      cholesterol: 0,
      carbohydrate: 39,
      protein: 7.5,
      sodium: 358,
      difficulty: "facile",
      category: "quick and easy|vegetarian|lunch|hearty meal",
    },
    {
      title: "Insalata fredda di pasta e gamberi",
      slug: "insalata-fredda-di-pasta-e-gamberi",
      img: "shrimp-and-pasta-salad",
      hasImage: true,
      description: "Questo piatto è ottimo come contorno per una grigliata o da preparare per una fresca cena estiva.",
      ingredients: "450 g di pasta tipo farfalla preventivamente cotta (pesata quindi già cotta)  | 450 g di gamberetti cotti, circa 31-35 pezzi|280 g di piselli surgelati o già cotti e scolati | 1 pomodoro grande, senza semi  finemente  tritato | 225 g di sedano tritato | 50 g di scalogno tritato | 250 g di salsa per condimenti a base di yogurt light | 1 cucchiaino di rafano in polvere | 1/2 cucchiaino di mix in polvere di  aglio ed erbe aromatichr | 75 g insalata tipo lattuga tagliata a pezzi",
      methods: "In una insalatiera capiente unire le farfalle cotte, i gamberi, piselli, il trito di pomodoro, di sedano e lo scalogno. | In un secondo contenitore più piccolo, mescolare il condimento per l'insalata a base di yougurt, le polveri di  rafano, di aglio e mix di erbe aromatiche. | Aggiungere il condimento cosi preparato all'insalata di pasta. | Copri l'insalatiera e lascia raffreddare completamente. | Prima di servire aggiungere la lattuga a listarelle.",
      notes: "",
      serves: 8,
      time: 15,
      fat: 1.7,
      calories: 335,
      cholesterol: 109,
      carbohydrate: 56,
      saturated: 0.3,
      sodium: 358,
      protein: 23,
      difficulty: "facile",
      category: "lunch|family favorites|quick and easy",
    },
    {
      title: "Pilaf di cous cous integrale",
      slug: "pilaf-di-cous-cous-integrale",
      img: "whole-wheat-couscous-pilaf",
      hasImage: true,
      description: "Questo piatto è a base di Cous cous verdure tritate e ceci",
      ingredients: "225 ml di brodo vegetale senza grassi | 124 g di cous cous integrale | 175 g di broccoli mondati e leggermente bolliti| 1-2 carote sminuzzate finemente a dadini| 8 funghi tagliati a fette | 2 scalogni affettati | 75 g di piselli congelati | 1 tazza di ceci  pre-cotti senza sale e sciacquati | 1 spicchio d'aglio tritato | 1 cucchiaino di olio d'oliva",
      methods: "Portare ad ebollizione il brodo | Aggiungere il Cous cous. Togliete dal fuoco e lasciate riposare per 5 minuti coperto (il Cous Cous continua a coucere lentamente e a gonfiarsi). | In una padella soffriggere l'aglio con olio, quindi aggiungere le verdure tagliate, i piselli e i ceci. | Soffriggere per circa 5-8 minuti. | Unire la miscela di verdure al Cous cous e servire.",
      notes: "",
      serves: 4,
      time: 25,
      fat: 1.5,
      calories: 233,
      cholesterol: 0,
      carbohydrate: 45,
      saturated: 0,
      sodium: 155,
      protein: 11,
      difficulty: "facile",
      category: "lunch|quick and easy|vegetarian",
    },
    {
      title: "Bruschetta",
      slug: "italian-bruschetta",
      img: "bruschetta",
      hasImage: true,
      description: "Un semplice aperitivo gustoso e facile da preparare",
      ingredients: "4 pomodori medi, privati dai semi e tagliati a cubetti| 1 spicchio d'aglio tritato finemente |5 g di basilico fresco tritato | 1/2 cucchiaio di olio d'oliva | 1/4 cucchiaino di sale | 1/4 cucchiaino di pepe | 8-10 fette pane fresco ",
      methods: "In un recipiente amalgamare i pomodori, l'aglio, il basilico, l'olio d'oliva, il sale e il pepe. Lasciar riposare per 10 minuti. | Tostare le fette di pane sulla griglia o nel tostapane. | Versare il composto di pomodoro sulle fette di pane e servire.",
      notes: "",
      time: 15,
      serves: 8,
      fat: 2.0,
      saturated: 0.4,
      calories: 101,
      cholesterol: 0,
      carbohydrate: 18,
      protein: 3,
      sodium: 261,
      fiber: 2,
      difficulty: "facile",
      category: "appetizer|lunch|family favorites|quick and easy",
    },
    {
      title: "Caesar salad al pollo",
      slug: "caesar-salad-al-pollo",
      img: "chicken-caesar-salad",
      hasImage: true,
      description: "Una versione più leggera di un piatto normalmente ad alto contenuto di grassi. Per un tocco di colore aggiungi dei pomodorini tipo pachino/ciliegina.",
      ingredients: "600 g di insalata tipo lattuga romana lavata e tagliata a pezzi| 450 g di petti di pollo disossati e senza pelle precotti e affettati a cubetti| 115 g di crostini senza grassi | 30 g parmigiano reggiano grattugiato | 175 g salsa Caesar senza grassi | 1/2 cucchiaino di pepe nero appena macinato | 1 limone fresco",
      methods: "In un recipiente capiente mescolare lattuga e i crostini, aggiungere il condimento pronto e mescolare bene. | Spremere il limone sull'insalata e aggiungere il formaggio; mescolare di nuovo. | Dividere il composto in 4 piatti da portata. | Disporre il pollo cotto tagliato a fettine sottili o tagliato a dadini dividendolo equamente sopra ogni porzione.",
      notes: "",
      time: 10,
      serves: 4,
      fat: 4,
      saturated: 2,
      calories: 214,
      cholesterol: 92,
      carbohydrate: 22,
      protein: 19,
      sodium: 484,
      difficulty: "facile",
      category: "lunch|quick and easy|dinner",
    },
    {
      title: "Tortino di spinaci al formaggio",
      slug: "tortino-di-spinaci-al-formaggio",
      img: "spinach-cheese-bake",
      hasImage: true,
      description: "Facile da preparare un contorno gustoso e versatile. Può essere utilizzato come come antipasto, come piatto sia per il pranzo che per la cena unito a frutta fresca e insalata.",
      ingredients: "340 g di spinaci tritati surgelati, cotti e scolati| 4 albumi | 450 g ricotta senza grassi | 450 g di formaggio magro o mozzarella  2% di grassi tagliata finemente | 3 cucchiai di farina | 1/4 cucchiaino di aglio in polvere | pepe nero quanto basta",
      methods: "Preriscaldare il forno a 175 ° C. | Mescolare tutti gli ingredienti e metterli in una teglia da 20 x 20 cm antiaderente o ricoperta da carta da forno. | Cuocere per 35-45 minuti (come prova inserire uno stuzzicandete nell'impasto e questo deve uscire pulito). | Raffreddare circa 10 minuti e servire.",
      notes: "Per ridurre il grasso a 1 g a porzione, utilizzare formaggio grattugiato senza grassi. Se è necessario aggiungere 2 g di grasso a  porzione spolverare il tortino prima di infornarlo con 2 cucchiai da cucina di mandorle a scaglie.             Esiste in commercio uno spray per la cottura senza grassi antiaderente. Può essere utilizzato al posto della carta da forno (rif spray antiaderente zero calorie)",
      serves: 4,
    time: 55,
    fat: 7.5,
    calories: 240,
    cholesterol: 10,
    carbohydrate: 14,
    saturated: 4.5,
    sodium: 790,
    protein: 29,
    difficulty: "facile",
    category: "dinner|lunch|family favorites",
    },
    {
      title: "Peperoni stufati",
      slug: "peperoni-stufati",
      img: "stuffed-peppers",
      hasImage: true,
      description: "Un buon piatto gustoso e completo ottimo sia come pranzo che come cena",
      ingredients: "4 peperoni verdi grandi |225 gr di carne macinata molto magra (meno del 5% di grassi)  | 40 g di scalogno tritato | 1/2 cucchiaino da caffè di peperoncino in polvere | 1/4 cucchiaino da caffè di pepe di Caienna (facoltativo) | 1 spicchio d'aglio tritato | un pizzico di pepe | 1/2 cucchiaino da caffè di sale | 1/2 cucchiaio da cucina di salsa Worcestershire | 375 g di riso integrale cotto | 50 g di formaggio tipo cheddar o erdamer senza grassi sminuzzato | 225g di salsa di pomodoro ",
      methods: "Preriscaldare il forno a 175 ° C | Cuorere  a fuoco medio la carne in un'ampia padella, mescolando di tanto in tanto per renderla fine. Incorporare lo scalogno, il peperoncino in polvere, l'aglio, il pepe, il sale e la salsa Worcestershire e continuare a cuocere finché la cipolla non è tenera. | Togliere la padella dal fuoco e aggiungere il riso già cotto, la salsa di pomodoro e 25 g di formaggio. | Tagliare i peperoni e rimuovere le membrane ed i semi. Farcire i peperoni con il composto di carne. | Posizionare i peperoni in posizione verticale in una pirofila. Coprire  la pirofila con un foglio di alluminio e infornare a 175 ° C per 55 minuti. Estrarre la teglia e cospargere uniformemente il formaggio cheddar rimasto su ogni peperone. Reinserire la teglia in forno e cuocere per altri 5 minuti senza copertura. Servire caldo.",
      notes: "Esiste in commercio uno spray per la cottura senza grassi antiaderente. Può essere utilizzato per urgere la padella (rif spray antiaderente zero calorie)",
      serves: 4,
      fat: 3.4,
      calories: 233,
      cholesterol: 31,
      carbohydrate: 34,
      sodium: 766,
      protein: 18,
      fiber: 4,
      difficulty: "facile",
      category: "lunch|vegetarian",
    },
    {
      title: "Parmigiana di melanzane",
      slug: "parmigiana-di-melanzane",
      img: "eggplant-parmesan",
      hasImage: true,
      description: "Gusto autentico senza grumi di grasso. La grande differenza  rispetto alla ricetta originale è che le melanzane vengono impanate e poi cotte al forno, non fritte.",
      ingredients: "1 melanzana  grande, sbucciata e fatta a fette di circa 1 cm | 2 zucchine di media grandezza tritate   | 225g di funghi a fette | 700 g salsa di pomodoro in barattolo (controllare il contenuto di grassi più basso) | 120 g di mozzarella senza grassi o magra sminuzzata | 4 cucchiai da cucina di Parmigiano | 2 albumi | 50 g di pangrattato",
      methods: "Preriscaldare il forno a 190 ° C. | Emulsionare gli albumi in una scodella, immergere le fette di melanzane, quindi impanarle con pangrattato preventivamente preparato in un piatto fondo. |Rivestire una teglia da forno da 20X30 cm con carta da forno. Inserirvi le melanzane | Cuocere per 30 minuti. Sfornare. | Versare la salsa di pomodoro sulle melanzane, cospargere di verdure e formaggio e riprendere la cottura per 20-25 minuti fino a quando bolle.",
      notes: "Analisi basata sull'utilizzo di mozzarella senza grassi. Se si utilizza formaggio a basso contenuto di grassi, il grasso aumenterà fino a 7 g di grassi per porzione. Esiste in commercio uno spray per la cottura senza grassi antiaderente. Può essere utilizzato al posto della carta da forno (rif spray antiaderente zero calorie)",
      ast: "",
      time: 65,
      serves: 4,
      fat: 3,
      saturated: 2,
      calories: 279,
      cholesterol: 10,
      carbohydrate: 42,
      protein: 21,
      sodium: 479,
      difficulty: "medio",
      category: "dinner|vegetarian",
    },
    {
      title: "Petto di tacchino morbido",
      slug: "petto-di-tacchino-morbido",
      img: "company-turkey-breast",
      hasImage: true,
      description: "Prova questo gratin di pane per mantenere umido e saporito il petto di tacchino",
      ingredients: "450 g di petto di tacchino disossato e senza pelle |260 g di funghi tritati | 3 scalogni di media grandezza tritati | 1 fetta di pane integrale sbriciolato| 75 ml di sherry o marsala | 1 cucchiaino da caffè di basilico essiccato",
      methods: "Preriscaldare il forno a 160 ° C. | In una padella media antiaderente rosolare i funghi, lo scalogno, lo sherry e il basilico per 3-4 minuti. | Aggiungere il pangrattato e togliere dal fuoco. | Disporre il petto di tacchino in una teglia antiaderente | Distribuire o tamponare il composto precedentemente preparato sul petto di  tacchino. | Coprire la teglia e cuocere per circa 2 ore (meno se i petti sono piccoli). Per gratinare eliminare la copertura e re-infornare per 30 minuti| Lasciate riposare 10 minuti prima di affettare.",
      notes: "Esiste in commercio uno spray per la cottura senza grassi antiaderente. Può essere utilizzato al posto della carta da forno (rif spray antiaderente zero calorie)",
      time: 150,
      serves: 4,
      fat: 2,
      saturated: 0.5,
      calories: 162,
      cholesterol: 86,
      carbohydrate: 6,
      protein: 30,
      sodium: 118,
      difficulty: "medio",
      category: "dinner|family favorites",
    },
    {
      title: "Pesce impanato",
      slug: "pesce-impanato",
      img: "fish-made-simple",
      hasImage: true,
      description: "Goditi il sapore del pesce con questa semplice preparazione.",
      ingredients: "350-450 g pesce magro come sogliola, eglefino, merluzzo o halibut | 2 albumi | 60 g di pangrattato | 1 limone fresco",
      methods: "Preriscaldare il forno a 220 ° C. | Lavare e asciugare i filetti di pesce. | Emulsionare l'albume in una scodella ed immergere il pesce. | Mettere il pangrattato in un altro contenitore e impanare il pesce. | Disporre i filetti ricoperti su un foglio di alluminio o carta da forno steso su un teglia. | Tagliare il limone in fette sottili e ricoprire i filetti di pesce. | Cuocere per 20 minuti (dipende dallo spessore) o fino a quando il pesce risulta morbido al taglio. | Servire con limone fresco.",
      notes: "Se si preferisce un gusto più burroso senza l'impanatura, spruzzare i filetti con uno spray al burro, condire a piacere, guarnire con fettine di limone sottili e infornare come sopra. I condimenti alternativi possono includere salsa di frutta (ad esempio mango), salsa di pomodoro, marinata allo zenzero senza grassi o salsa teriyaki.",
      time: 30,
      serves: 2,
      fat: 3,
      saturated: 0,
      calories: 223,
      cholesterol: 83,
      carbohydrate: 10,
      protein: 37,
      sodium: 395,
      difficulty: "facile",
      category: "dinner|fish|quick and easy",
    },
    {
      title: "Polpette",
      slug: "polpette",
      img: "italian-meatballs",
      hasImage: true,
      description: "Aggiungi questa ricetta alla tua cena settimanale e diventerà sicuramente la preferita della famiglia!",
      ingredients: "450 g di carne macinata \"extra magra\" (meno del 5% di grassi) | 60 g di pangrattato | 55 ml di latte  scremato | 2 albumi d'uovo | 40 g cipolla tritata | 1 spicchio d'aglio tritato | 1/2 cucchiaino da caffè di origano | un pizzico di pepe | 900 g di salsa di pomodoro senza grassi",
      methods: "Preriscaldare il forno a 175 ° C. | In un recipiente capiente mescolare tutti gli ingredienti escludendo  la salsa di pomodoro (servirà dopo). | Preparare piccole palline di impasto grandi come il palmo di una mano  e disporle su una teglia rivestita con carta stagnola o carta da forno antiaderente. | Cuocere per 20-25 minuti,  girare le polpette a metà cottura. | In una casseruola grande, aggiungere la salsa di pomodoro e portare a ebollizione. | Aggiungere le polpette e cuocere a fuoco lento per 15-20 minuti. | Impasto per 24 polpette.",
      notes: "",
      time: 50,
      serves: 8,
      fat: 3.3,
      calories: 155,
      cholesterol: 35,
      carbohydrate: 14,
      saturated: 1.4,
      sodium: 630,
      fiber: 2,
      difficulty: "facile",
      category: "dinner|family favorites",
    },
    {
      title: "Toast di tonno",
      slug: "toast-di-tonno",
      img: "tasty-tuna-sandwiches",
      hasImage: true,
      description: "Questa versione più leggera della classica \"maionese al tonno\" sarà un successo per uno spuntino veloce all'ora di pranzo.",
      ingredients: "1 scatola di tonno al naturale sgocciolato | 2 cucchiai da cucina di yogurt greco magro | 1 cucchiaio da cucina di coriandolo fresco tritato o esiccato | un pizzico di pepe nero macinato | 4 fette di pane integrale  o 2 panini | ¼ di cetriolo a dadini | ¼ di peperone rosso, a dadini | una manciata di foglie di insalata fresca | maionese a basso contenuto di grassi",
      methods: "Mescolare con lo yogurt con il coriandolo e unire un pizzico di pepe nero. Sbriciolare il tonno ed aggiungerlo allo yogurt. Incorporare all'impasto il cetriolo a dadini e il peperoncino. | Spalmare le 2 fette di pane con un velo di maionese magra, aggiungere il tonno condito sulle fette. Farcire con alcune foglie di insalata e sovrapporre le  restanti fette di pane. Servire.",
      notes: "",
      time: 5,
      serves: 2,
      fat: 4.4,
      saturated: 0.7,
      sodium: 98,
      calories: 282,
      difficulty: "facile",
      heartuk: true,
      category: "lunch|light snacks",
    },
    {
      title: "Torta di cioccolato senza uova",
      slug: "torta-di-cioccolato-senza-uova",
      img: "easy-eggless-cocoa-cake",
      hasImage: true,
      description: "Dolce perfetto per una colazione o merenda pomeridiana",
      ingredients: "210 g di farina | 200 g di zucchero | 30 g di cacao | 1 cucchiaino di lievito in polvere | un pizzico di sale | 225 ml di acqua | 2 cucchiai da cucina di olio | 2 cucchiai da cucina di yogurt magro | 1 cucchiaio da cucina di aceto | 1 cucchiaino da caffé di estratto di vaniglia",
      methods: "Preriscaldare il forno a 175 ° C. | In una scodella mettere tutti gli ingredienti secchi e mescolarli. Aggiungere gli ingredienti liquidi e amalgamare il composto fino a renderlo liscio. Versare l'impasto in una teglia da forno di 20 cm preventivamente ricoperta con carta da forno. | Cuocere per 30-35 minuti o finché il coltello non sarà pulito. | Spolverare con zucchero a velo e spolverare con gocce di cioccolato.",
      notes: "Esiste in commercio uno spray per la cottura senza grassi antiaderente. Può essere utilizzato al posto della carta da forno (rif spray antiaderente zero calorie)",
      time: 45,
      serves: 9,
      fat: 3.6,
      saturated: 0.6,
      calories: 199,
      cholesterol: 0,
      carbohydrate: 40,
      protein: 3,
      sodium: 122,
      difficulty: "facile",
      category: "family favorites|dessert",
    },
    {
      title: "Cheesecake senza grassi",
      slug: "cheesecake-senza-grassi",
      img: "easy-fat-free-cheesecake",
      hasImage: true,
      description: "Cheesecake cremosa con il sapore della versione originale. Si può ridurre lo zucchero e i carboidrati utilizzando lo zucchero con un  dolcificante non calorico.",
      ingredients: "450 g di crema di formaggio senza grassi a  temperatura ambiente |110 g di zucchero bianco semolato (altro dolcificante) | 190 g di yogurt greco senza grassi a temperatura ambiente | 1 cucchiaino da caffè di vaniglia in polvere | 4 albumi  | 2 cucchiai da cucina di succo di limone | 2 cucchiai da cucina di farina| Guarnizione opzionale:  amarene cotte o frutti di bosco freschi",
      methods: "Preriscaldare il forno a 175 ° C. | Prendere una teglia tonda con diametro 20 cm e riverstirla di carta da forno. | In un contenitore grande mescolare la crema di formaggio, lo zucchero (o dolcificante non calorico) e la vaniglia. Aiutarsi con un mixer elettrico per rendere il composto liscio. | Aggiungere lo yogurt, gli albumi, il succo di limone e la farina e continuare a mescolare delicatamente. | Versare uniformemente nella teglia preventivamente preparata. | Cuocere per 30-40 minuti. La torta è pronta quando l'impasto si è solidificato.|  Lasciar raffreddare completamente. | Se lo si deridera aggiungere le guarnizioni.",
      notes: "Esiste in commercio uno spray per la cottura senza grassi antiaderente. Può essere utilizzato al posto della carta da forno (rif spray antiaderente zero calorie)",
      time: 50,
      serves: 8,
      fat: 1,
      saturated: 19,
      calories: 142,
      cholesterol: 6,
      carbohydrate: 19,
      protein: 14,
      sodium: 418,
      fiber: 1,
      difficulty: "facile",
      category: "family favorites|dessert",
    },
    {
      title: "Granita di mele verdi e sedano",
      slug: "granita-di-mele-verdi-e-sedano",
      img: "green-apple-and-celery-granita",
      hasImage: true,
      description: "La granita è una valida alternativa dal punto di vista nutrizionale al gelato. ",
      ingredients: "200g di centrifuga di mela verde | 5g di fruttosio | 4g di gelatina animale ",
      methods: "Scaldare in un pentolino parte della centrifuga con il fruttosio.  | Aggiungere la gelatina, precedentemente idratata con acqua fredda, e la rimanente parte di centrifuga di mela.  | Mettere in congelatore per 6 ore. Al momento dell'impiattamento, tritare con il mixer.  ",
      notes: "by Heinz Beck. Edizioni Internazionali srl / Divisione EDIMES",
      time: 50,
      serves: 1,
      fat: 1.6,
      saturated: 0.9,
      calories: 91,
      carbohydrate: 19.8,
      protein: 0.65,
      sodium: 7,
      fiber: 1.65,
      difficulty: "facile",
      category: "quick and easy|vegetarian|gluten free|dessert|light snacks",
    },
    {
      title: "Tortiglioni con melanzane, pinoli e uvetta ",
      slug: "tortiglioni-con-melanzane",
      img: "trivelli-Pasta-with-eggplant",
      hasImage: true,
      description: "Un piatto di pasta gustoso e completo",
      ingredients: "200g di melanzane | olio extra vergine di oliva | sale | 500g di pomodorini | 1 spicchio di aglio | peperoncino | 30g di pinoli | 30g di uvetta | 320g di pasta tipo tortiglioni | 60g di ricotta salata | 6 foglie di menta | 6 foglie di basilico ",
      methods: "In una pentola capiente portare ad ebollizione l'acqua salata e cuorere al dente la pasta.  | Nel frattempo pelare le melanzane, tagliarle a dadini e salare leggermente. Lasciare riposare per mezz'ora.  | Pennellare una padella antiaderente calda con dell'olio extra vergine di oliva e rosolare le melanzane  fino ad ottenere un colore ben dorato. | In una seconda padella antiaderente soffriggere l'aglio e il peperoncino con olio extra vergine di oliva, aggiungere i pomodorini tagliati in quarti e lasciare bollire per 10 minuti.  Unire le melanzane, l'uvetta tritata e i pinoli precedentemente tostati, lasciandoli cuocere per altri 5 minuti. |   Una volta scolata la pasta, mantecarla nella salsa di ricotta salata, con un po' di menta e di basilico tritato.  | Infine aggiungere il sugo di melanzane e pomodori preparatato a parte. Servire",
      notes: "by Heinz Beck. Edizioni Internazionali srl / Divisione EDIMES",
      time: 50,
      serves: 1,
      fat: 12.1,
      saturated: 2.6,
      calories: 448,
      // cholesterol: 6,
      carbohydrate: 75.2,
      protein: 14.4,
      sodium: 40,
      fiber: 6.7,
      difficulty: "medio",
      category: "lunch|dinner|family favourites|gluten free",
    },
    {
      title: "Merluzzo su fagioli borlotti e neve ghiacciata di prezzemolo",
      slug: "merluzzo-su-fagioli",
      img: "cod-on-pinto-beans",
      hasImage: true,
      description: "Un piatto gourmet a base di pesce gustoso anche alla vista",
      ingredients: "125g pomodorini | olio extra vergine di oliva | sale e timo | 300g di fagioli borlotti | 1 carota | 1 cipolla | prezzemolo 1 mazzo | rosmarino | 2 foglie di alloro | salsa di soya | brodo vegetale | 400g di merluzzo",
      methods: "Lavare i pomodorini ed incidere la buccia con un piccolo taglio a croce; immergere in acqua bollente per alcuni secondi, estrarre con una schiumarola, raffreddare in acqua ghiacciata, togliere la buccia, dividere in quarti e privarli  dei semi.  | Mettere i pomodorini in una ciotola e condire con l'olio, il sale e il timo tritato. Lasciare riposare mezz'ora quindi trasferire gli spicchi di pomodoro su una teglia rivestita di carta da forno e fare essicare in forno caldo a 80°C per circa 4 ore. | Sgranare i fagioli fresci e cuocerli in acqua salata con la carota, la cipolla e le erbe. Rosolare i fagioli in padella con i pomodorini tagliati a listarelle, poco brodo vegetale e sfumare con la salsa di soya. | Lavare il merluzzo, eviscerarlo e tagliare la polpa in 4 pezzi da circa 100g ciascuno.  | Mettere i filetti in un sacchetto per sottovuoto con l'olio e poco sale. Cuocere in forno a vapore a 65°C per 5 minuti. | Pulire le foglie di prezzemolo eliminando il gambo e sbollentare in acqua per 1 minuto. Asciugare, stendere su una placca e congelare. Frullare finemente e conservare a meno 24°C. |  Distribuire i fagioli cotti insieme ai pomodorini confit in un piatto fondo ed adagiarvi sopra il merluzzo.  | Guarnire con la neve ghiacciata di prezzemolo. ",
      notes: "by Heinz Beck. Edizioni Internazionali srl / Divisione EDIMES",
      time: 50,
      serves: 1,
      fat: 7.7,
      saturated: 0.8,
      calories: 353,
      carbohydrate: 40,
      protein:  33.1,
      sodium: 86,
      fiber: 14.1,
      difficulty: "facile",
      category: "family favorites|dessert",
    }
    // {
    //   title: "Granita di mele verdi e sedano",
    //   description: "La granita è una valida alternativa dal punto di vista nutrizionale al gelato. ",
    //   ingredients: "200g di centrifuga di mela verde | 5g di fruttosio | 4g di gelatina animale ",
    //   methods: "Scaldare in un pentolino parte della centrifuga con il fruttosio.  | Aggiungere la gelatina, precedentemente idratata con acqua fredda, e la rimanente parte di centrifuga di mela.  | Mettere in congelatore per 6 ore. Al momento dell'impiattamento, tritare con il mixer.  ",
    //   notes: "by Heinz Beck.    Edizioni Internazionali srl / Divisione EDIMES",
    // },
    // {
    //   title: "Trivelli con melanzane, pinoli e uvetta ",
    //   description: "Un piatto di pasta gustoso e completo",
    //   ingredients: "200g di melanzane | olio extra vergine di oliva | sale | 500g di pomodorini | 1 spicchio di aglio | peperoncino | 30g di pinoli | 30g di uvetta | 320g di pasta tipo trivelli | 60g di ricotta salata | 6 foglie di menta | 6 foglie di basilico ",
    //   methods: "In una pentola capiente portare ad ebollizione l'acqua salata e cuorere al dente la pasta.  | Nel frattempo pelare le melanzane, tagliarle a dadini e salare leggermente. Lasciare riposare per mezz'ora.  | Pennellare una padella antiaderente calda con dell'olio extra vergine di oliva e rosolare le melanzane  fino ad ottenere un colore ben dorato. | In una seconda padella antiaderente soffriggere l'aglio e il peperoncino con olio extra vergine di oliva, aggiungere i pomodorini tagliati in quarti e lasciare bollire per 10 minuti.  Unire le melanzane, l'uvetta tritata e i pinoli precedentemente tostati, lasciandoli cuocere per altri 5 minuti. |   Una volta scolata la pasta, mantecarla nella salsa di ricotta salata, con un po' di menta e di basilico tritato.  | Infine aggiungere il sugo di melanzane e pomodori preparatato a parte. Servire",
    //   notes: "by Heinz Beck.    Edizioni Internazionali srl / Divisione EDIMES",
    // },
    // {
    //   title: "Merluzzo su fagioli borlotti e neve ghiacciata di prezzemolo ",
    //   description: "Un piatto gourmet a base di pesce gustoso anche alla vista",
    //   ingredients: "125g pomodorini | olio extra vergine di oliva | sale e timo | 300g di fagioli borlotti | 1 carota | 1 cipolla | prezzemolo 1 mazzo | rosmarino | 2 foglie di alloro | salsa di soya | brodo vegetale | 400g di merluzzo",
    //   methods: "Lavare i pomodorini ed incidere la buccia con un piccolo taglio a croce; immergere in acqua bollente per alcuni secondi, estrarre con una schiumarola, raffreddare in acqua ghiacciata, Togliere la buccia, dividere in quarti e privarli  dei semi.  | Mettere i pomodorini in una ciotola e condire con l'olio, il sale e il timo tritato. Lasciare riposare mezz'ora quindi trasferire gli spicchi di pomodoro su una teglia rivestita di carta da forno e fare essicare in forno caldo a 80°C per circa 4 ore. | Sgranare i fagioli fresci e cuocerli in acqua salata con la carota, la cipolla e le erbe. Rosolare i fagioli in padella con i pomodorini tagliati a listarelle, poco brodo vegetale e sfumare con la salsa di soya. | Lavare il merluzzo, eviscerarlo e tagliare la polpa in 4 pezzi da circa 100g ciascuno.  | Mettere i filetti in un sacchetto per sottovuoto con l'olio e poco sale. Cuocere in forno a vapore a 65°C per 5 minuti. | Pulire le foglie di prezzemolo eliminando il gambo e sbollentare in acqua per 1 minuto. Asciugare, stendere su una placca e congelare. Frullare finemente e conservare a meno 24°C. |  Distribuire i fagioli cotti insieme ai pomodorini confit in un piatto fondo ed adagiarvi sopra il merluzzo.  | Guarnire con la neve ghiacciata di prezzemolo. ",
    //   notes: "by Heinz Beck.    Edizioni Internazionali srl / Divisione EDIMES",
    // }
   ]