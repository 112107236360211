<template>
<div>
    <div class="home container">
        <!--  Intro  -->
        <div class="row">
            <img class="img-01" src="@/assets/img/img-01.png" />
            <div class="col s12 m12 l10">
                <h1 class="hero-heading">HoFH recettes</h1>
                <p class="hero-p">
                    <b>Nous avons rassemblé une variété de recettes délicieuses, faibles en gras et faciles à faire pour les personnes atteintes d’hypercholestérolémie familiale homozygote (HoFH), une maladie génétique héréditaire rare qui se caractérise par la présence de taux élevés de cholestérol dans le sang. Ces recettes permettent aux personnes atteintes d’HoFH, dont le prestataire de soins de santé a conseillé de suivre un régime alimentaire très faible en gras, de diversifier leur régime alimentaire.</b>
                </p>

                <p class="hero-p">
                    <b>Ces recettes ne sont pas destinées à remplacer les médicaments hypolipidémiants et ne suffisent pas à elles seules à réduire les niveaux de cholestérol élevés chez les personnes atteintes d’HoFH. Les patients doivent continuer à prendre leurs médicaments tels qu’ils leur ont été prescrits et toujours suivre les conseils de leur prestataire de soins de santé.</b>
                </p>

                <p class="hero-p">
                    <b>Veuillez tenir compte de toute allergie alimentaire que vous pourriez avoir lorsque vous choisissez une recette.</b>
                </p>
            </div>
        </div>

        <!--  Search bar  -->
        <div class="row">
            <div class="col m12 s12">
                <Autocomplete placeholder="Chercher une recette ou un ingrédient" :items="recipes" title @selected="recipeSelected" />
            </div>
        </div>

        <!--  Quick tags  -->
        <div class="row">
            <div class="col s12">
                <ul class="hints">
                    <router-link :to="'/french/categories/petit déjeuner'">
                        <li>Petit Déjeuner</li>
                    </router-link>
                    <router-link :to="'/french/categories/déjeuner'">
                        <li>Déjeuner</li>
                    </router-link>
                    <router-link :to="'/french/categories/dîner'">
                        <li>Dîner</li>
                    </router-link>
                    <router-link :to="'/french/categories/poisson'">
                        <li>Poisson</li>
                    </router-link>
                    <router-link :to="'/french/categories/dessert'">
                        <li>Dessert</li>
                    </router-link>
                    <router-link :to="'/french/categories/végétarien'">
                        <li>Végétarien</li>
                    </router-link>
                    <router-link :to="'/french/categories/collations légères'">
                        <li>Collations</li>
                    </router-link>
                    <router-link :to="'/french/all-recipes/'">
                        <li>Tout voir</li>
                    </router-link>
                    <!-- <a class='dropdown-trigger' href='#' data-target='more-categories'><li class="valign-wrapper">More <i class="tiny material-icons">arrow_drop_down</i></li></a> -->
                </ul>
            </div>

            <!-- Dropdown Structure -->
            <!-- <ul id='more-categories' class='dropdown-content'>
          <li><router-link :to="'/categories/dessert'"><li>Dessert </li></router-link></li>
          <li><router-link :to="'/categories/fish'"><li>Fish </li></router-link></li>
        </ul>-->
        </div>

        <!--  Recently Viewed  -->
        <div class="row" v-if="recentlyViewedList.length">
            <h2>
                Vu Récemment
                <span class="red-text darken-1 clearRecent" @click="clearRecent">(effacer tout)</span>
            </h2>

            <VueSlickCarousel v-bind="slickOptions">
                <div class="col s6 m3" v-for="(data, index) in recentlyViewedList.slice().reverse()" :key="index">
                    <div class="card hoverable small recentlyViewed">
                        <a class="btn-floating halfway-fab waves-effect waves-light red deleteRecent" @click="deleteRecent(data.slug, index)">&minus;</a>

                        <router-link :to="'/french/recipe/' + data.slug">
                            <div class="card-image">
                                <div v-if="data.hasImage">
                                    <img :src="require(`@/assets/img/recipes/${data.img}.jpg`)" />
                                </div>
                                <div v-else>
                                    <img src="@/assets/img/default-recipe.jpg" />
                                </div>
                                <span class="difficulty" :class="data.difficulty">{{data.difficulty}}</span>
                                <div class="blob">
                                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M55.4,-66.5C68,-55.5,71.7,-34.4,75.3,-13.4C78.8,7.6,82.3,28.6,74.6,44.3C67,60,48.2,70.6,28.9,76.2C9.6,81.8,-10.3,82.5,-26.7,75.6C-43.1,68.7,-56,54.2,-67.2,37.5C-78.4,20.8,-87.8,1.8,-86.8,-17.2C-85.7,-36.3,-74.1,-55.4,-57.9,-65.8C-41.7,-76.2,-20.8,-78,0.3,-78.3C21.4,-78.7,42.8,-77.6,55.4,-66.5Z" transform="translate(100 100)" />
                                    </svg>
                                    <div class="fatBlobText">
                                        <p>Gras:</p>
                                        <p>{{data.fat}}g</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card-content">
                                <h5 class="card-header">{{data.title}}</h5>
                                <p v-if="data.serves">
                                    <strong>Pour:</strong>
                                    {{data.serves}}
                                </p>
                                <p>
                                    <strong>Calories:</strong>
                                    {{data.calories}}kcals
                                </p>
                            </div>
                        </router-link>
                    </div>
                </div>
            </VueSlickCarousel>
        </div>

        <!--  Veggie friendly  -->
        <div class="row">
            <h2>
                Végétarien
                <span class="orange-text darken-1 float-right">
                    <small>
                        <router-link :to="'/french/categories/végétarien'">Voir tout</router-link>
                    </small>
                </span>
            </h2>

            <VueSlickCarousel v-bind="slickOptions">
                <div class="col s6 m3" v-for="(data, index) in vegRecipes.slice(0, 7)" :key="index">
                    <div class="card hoverable small">
                        <router-link :to="'/french/recipe/' + data.slug">
                            <div class="card-image">
                                <div v-if="data.hasImage">
                                    <img :src="require(`@/assets/img/recipes/${data.img}.jpg`)" />
                                </div>
                                <div v-else>
                                    <img src="@/assets/img/default-recipe.jpg" />
                                </div>
                                <span class="difficulty" :class="data.difficulty">{{data.difficulty}}</span>
                                <div class="blob">
                                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M55.4,-66.5C68,-55.5,71.7,-34.4,75.3,-13.4C78.8,7.6,82.3,28.6,74.6,44.3C67,60,48.2,70.6,28.9,76.2C9.6,81.8,-10.3,82.5,-26.7,75.6C-43.1,68.7,-56,54.2,-67.2,37.5C-78.4,20.8,-87.8,1.8,-86.8,-17.2C-85.7,-36.3,-74.1,-55.4,-57.9,-65.8C-41.7,-76.2,-20.8,-78,0.3,-78.3C21.4,-78.7,42.8,-77.6,55.4,-66.5Z" transform="translate(100 100)" />
                                    </svg>
                                    <div class="fatBlobText">
                                        <p>Gras:</p>
                                        <p>{{data.fat}}g</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card-content">
                                <h5 class="card-header">{{data.title}}</h5>
                                <p v-if="data.serves">
                                    <strong>Pour:</strong>
                                    {{data.serves}}
                                </p>
                                <p>
                                    <strong>Calories:</strong>
                                    {{data.calories}}kcals
                                </p>
                            </div>
                        </router-link>
                    </div>
                </div>

                <!--  See all  -->
                <div class="col s6 m3">
                    <router-link :to="'/french/categories/végétarien'">
                        <div class="card hoverable small see-more valign-wrapper">
                            <h3>
                                Voir tout
                                <i class="small material-icons">trending_flat</i>
                            </h3>
                        </div>
                    </router-link>
                </div>
            </VueSlickCarousel>
        </div>

        <!--  Quick and Easy  -->
        <div class="row">
            <h2>
                Rapides et Faciles à Préparer
                <span class="orange-text darken-1 float-right">
                    <small>
                        <router-link :to="'/french/categories/rapides et faciles'">Voir tout</router-link>
                    </small>
                </span>
            </h2>

            <VueSlickCarousel v-bind="slickOptions">
                <div class="col s6 m3" v-for="(data, index) in quickRecipes.slice(0, 7)" :key="index">
                    <div class="card hoverable small">
                        <router-link :to="'/french/recipe/' + data.slug">
                            <div class="card-image">
                                <div v-if="data.hasImage">
                                    <img :src="require(`@/assets/img/recipes/${data.img}.jpg`)" />
                                </div>
                                <div v-else>
                                    <img src="@/assets/img/default-recipe.jpg" />
                                </div>
                                <span class="difficulty" :class="data.difficulty">{{data.difficulty}}</span>
                                <div class="blob">
                                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M55.4,-66.5C68,-55.5,71.7,-34.4,75.3,-13.4C78.8,7.6,82.3,28.6,74.6,44.3C67,60,48.2,70.6,28.9,76.2C9.6,81.8,-10.3,82.5,-26.7,75.6C-43.1,68.7,-56,54.2,-67.2,37.5C-78.4,20.8,-87.8,1.8,-86.8,-17.2C-85.7,-36.3,-74.1,-55.4,-57.9,-65.8C-41.7,-76.2,-20.8,-78,0.3,-78.3C21.4,-78.7,42.8,-77.6,55.4,-66.5Z" transform="translate(100 100)" />
                                    </svg>
                                    <div class="fatBlobText">
                                        <p>Gras:</p>
                                        <p>{{data.fat}}g</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card-content">
                                <h5 class="card-header">{{data.title}}</h5>
                                <p v-if="data.serves">
                                    <strong>Pour:</strong>
                                    {{data.serves}}
                                </p>
                                <p>
                                    <strong>Calories:</strong>
                                    {{data.calories}}kcals
                                </p>
                            </div>
                        </router-link>
                    </div>
                </div>

                <!--  Voir tout  -->
                <div class="col s6 m3">
                    <div class="card hoverable small see-more valign-wrapper">
                        <router-link :to="'/french/categories/rapides et faciles'">
                            <h3>
                                Voir tout
                                <i class="small material-icons">trending_flat</i>
                            </h3>
                        </router-link>
                    </div>
                </div>
            </VueSlickCarousel>
        </div>
    </div>
    <div class="wide snacks">
        <div class="container">
            <!--  Low fat snacks  -->
            <div class="row">
                <div class="col s12 m5">
                    <h2>Aliments sans gras / faible en gras</h2>
                    <p class="left-align">
                        <strong>
                            Voici une sélection d’aliments contenant 1,5 g de matières grasses par portion ou moins. N’oubliez pas, bien sûr, que, consommés en grande 
                            quantité, les aliments contenant 1,5 g de gras par portion peuvent mener à un excès de matières grasses.
                        </strong>
                    </p>
                    <p class="left-align">Si vous avez des restrictions alimentaires liées à d’autres problèmes de santé, consultez votre médecin pour savoir si ces aliments vous conviennent.</p>
                </div>
                <div class="col s12 m5">
                    <div class="collection-container">
                        <ul class="collection">
                            <li class="collection-item left-align" v-for="(snack, index) in snacks" :key="index">
                                <strong>{{snack.title}}</strong>
                                &#x000B7; Portion: {{snack.serves}} &#x000B7; Gras: {{snack.fat}}g
                            </li>
                        </ul>
                        <div class="gradient"></div>
                    </div>
                </div>
                <div class="col m1">
                    <img class="responsive-img hide-on-small-only" src="@/assets/img/home-snacks.png" />
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <!--  Family Favourites  -->
        <div class="row">
            <h2>
                Mets Préférés de la Famille
                <span class="orange-text darken-1 float-right">
                    <small>
                        <router-link :to="'/french/categories/mets préférés de la famille'">Voir tout</router-link>
                    </small>
                </span>
            </h2>

            <VueSlickCarousel v-bind="slickOptions">
                <div class="col s6 m3" v-for="(data, index) in familyFavs.slice(0, 7)" :key="index">
                    <div class="card hoverable small">
                        <router-link :to="'/french/recipe/' + data.slug">
                            <div class="card-image">
                                <div v-if="data.hasImage">
                                    <img :src="require(`@/assets/img/recipes/${data.img}.jpg`)" />
                                </div>
                                <div v-else>
                                    <img src="@/assets/img/default-recipe.jpg" />
                                </div>
                                <span class="difficulty" :class="data.difficulty">{{data.difficulty}}</span>
                                <div class="blob">
                                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M55.4,-66.5C68,-55.5,71.7,-34.4,75.3,-13.4C78.8,7.6,82.3,28.6,74.6,44.3C67,60,48.2,70.6,28.9,76.2C9.6,81.8,-10.3,82.5,-26.7,75.6C-43.1,68.7,-56,54.2,-67.2,37.5C-78.4,20.8,-87.8,1.8,-86.8,-17.2C-85.7,-36.3,-74.1,-55.4,-57.9,-65.8C-41.7,-76.2,-20.8,-78,0.3,-78.3C21.4,-78.7,42.8,-77.6,55.4,-66.5Z" transform="translate(100 100)" />
                                    </svg>
                                    <div class="fatBlobText">
                                        <p>Gras:</p>
                                        <p>{{data.fat}}g</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card-content">
                                <h5 class="card-header">{{data.title}}</h5>
                                <p v-if="data.serves">
                                    <strong>Pour:</strong>
                                    {{data.serves}}
                                </p>
                                <p>
                                    <strong>Calories:</strong>
                                    {{data.calories}}kcals
                                </p>
                            </div>
                        </router-link>
                    </div>
                </div>

                <!--  See all  -->
                <div class="col s6 m3">
                    <router-link :to="'/french/categories/mets préférés de la famille'">
                        <div class="card hoverable small see-more valign-wrapper">
                            <h3>
                                Voir tout
                                <i class="small material-icons">trending_flat</i>
                            </h3>
                        </div>
                    </router-link>
                </div>
            </VueSlickCarousel>
        </div>
        <!-- <div id="dim"></div> -->
    </div>

    <div class="container">
        <div class="row small card-panel yellow">
            <div class="col s12 m1">
                <img class="responsive-img info-img" src="@/assets/img/info-img.png" alt />
            </div>
            <div class="col s12 m11">
                <p class="hero-p">
                    <i>
                        <strong>Remarque:</strong> Consultez votre médecin ou un autre prestataire 
                        de soins de santé avant d’apporter des changements à votre régime alimentaire, 
                        en particulier si vous avez des restrictions alimentaires liées à des affections 
                        telles que le diabète ou une maladie rénale.
                        Consultez votre médecin pour savoir si ces conseils vous conviennent. 
                        <strong>
                            Au début de votre traitement, n’apportez pas de changements majeurs à votre 
                            alimentation, si ce n’est qu’en réduisant votre apport lipidique.
                        </strong>
                        Par exemple, l’augmentation soudaine de l’apport en fibres ou la consommation d’une 
                        grande quantité d’aliments provoquant des gaz peut avoir un effet sur les problèmes d’estomac.
                    </i>
                </p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
// @ is an alias to /src
// import firebase from 'firebase'
import getRecipes from "@/assets/data/recipes-french-01";
import getSnacks from "@/assets/data/snacks-french-01";
import Autocomplete from "@/components/Autocomplete";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
// import '@/assets/js/materialize.min.js';
// import Autocomplete from 'vue2-autocomplete-js'

export default {
    name: "home",
    components: {
        Autocomplete,
        VueSlickCarousel,
    },
    data() {
        return {
            recipes: [],
            recentlyViewed: [],
            recentlyViewedList: [],
            vegRecipes: [],
            heartyMeals: [],
            quickRecipes: [],
            snacks: [],
            familyFavs: [],
            slickOptions: {
                dots: true,
                arrows: true,
                focusOnSelect: true,
                infinite: false,
                speed: 500,
                slidesToShow: 4,
                slidesToScroll: 1,
                responsive: [{
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 0,
                    },
                }, ],
            },
        };
    },
    metaInfo() {
        return {
            title: 'HoFH Recipes',
            meta: [{
                name: 'description',
                content: 'A collection of recipes for people with homozygous familial hypercholesterolemia (HoFH)'
            }, {
                name: 'keywords',
                content: 'HOFH, Hypercholesterolaemia, HOFH recipes, Hypercholesterolaemia Diet, HOFH meal plan, homozygous familial hypercholesterolemia, low fat recipes, low fat meals, ldl, ldl tracker, fat free meals'
            }],
        }
    },
    computed:{

    },
    created() {
        /* eslint-disable */
        this.recipes = getRecipes;
        this.snacks = getSnacks;

        if (localStorage.recentlyViewed) {
            this.recentlyViewed = JSON.parse(localStorage.recentlyViewed);
            // console.log(this.recentlyViewed)
        }

        // console.log(firebase.auth().currentUser.emailVerified)

        for (var i = 0; i < this.recipes.length; i++) {
            var obj = this.recipes[i];

            if (obj.category.includes("végétarien")) {
                this.vegRecipes.push(this.recipes[i]);
            }
            if (obj.category.includes("rapides et faciles")) {
                this.quickRecipes.push(this.recipes[i]);
            }
            if (obj.category.includes("mets préférés de la famille")) {
                this.familyFavs.push(this.recipes[i]);
            }

            // for(var j = 0; j < 8; j++){
            //     if(this.recentlyViewed[j] == obj.slug){

            //       this.recentlyViewedList.push(this.recipes[i])

            //   }
            // }
        }

        for (var j = 0; j < 8; j++) {
            for (var i = 0; i < this.recipes.length; i++) {
                var obj = this.recipes[i];

                if (this.recentlyViewed[j] == obj.slug) {
                    this.recentlyViewedList.push(this.recipes[i]);
                }
            }
        }

        // console.log(JSON.stringify(this.recentlyViewed[0]))
    },
    mounted() {
        document.body.classList.remove("sub-page");
    },
    methods: {
        recipeSelected(recipe) {
            // console.log(`recipe Selected:\ntitle: ${recipe.title}\ningredients: ${recipe.ingredients}`);
            this.$router.push({
                path: `/french/recipe/${recipe.slug}`
            });
        },

        clearRecent() {
            this.recentlyViewedList = [];
            localStorage.recentlyViewed = [];
        },

        deleteRecent(title, index) {
            // event.preventDefault();
            // var title = localStorage.recentlyViewed.titleOf(recipe);
            this.recentlyViewedList.splice(
                this.recentlyViewedList.length - index - 1,
                1
            );
            // console.log(localStorage.recentlyViewed)

            var json = JSON.parse(localStorage["recentlyViewed"]);

            // console.log(title)
            // console.log(json)

            for (var i = 0; i < json.length; i++) {
                if (json[i] == title) {
                    json.splice(i, 1);
                    localStorage["recentlyViewed"] = JSON.stringify(json);
                }
            }

            console.log(json);
        },
    },
};
</script>

<style>
body {
    background-image: url(~@/assets/img/bg-2.png);
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 650px;
    -webkit-transition: all 500ms ease-in-out;
    -moz-transition: all 500ms ease-in-out;
    -o-transition: all 500ms ease-in-out;
    transition: all 500ms ease-in-out;
}

.sub-page .hero-heading {
    color: #1a727e;
}

strong {
    font-weight: 700;
}

.home {
    margin-top: 60px !important;
}

.yellow {
    background-color: #faab17 !important;
}

.yellow .hero-p {
    color: #583b04 !important;
}

.info-img {
    /* max-width: 76% !important; */
}

.hero-heading {
    font-family: "Baloo Thambi", cursive;
    color: #fff;
    font-size: 2.75em !important;
    margin-top: 0px !important;
    text-align: left !important;
}

.hero-p {
    font-size: 1em;
    color: #fff;
    text-align: left;
}

.sub-page .hero-p {
    color: #1a727e;
    margin-bottom: 45px;
}

.img-01 {
    width: 370px;
    position: absolute;
    top: 120px;
    right: 40px;
    z-index: -1;
}

.hints {
    margin: 20px 0 10px !important;
}

.float-right {
    float: right;
}

.card.small {
    height: 275px;
    box-shadow: 0 2px 2px 0 rgba(100, 100, 100, 0.14),
        0 3px 1px -2px rgba(100, 100, 100, 0.12),
        0 1px 5px 0 rgba(100, 100, 100, 0.2);
    border-radius: 6px;
    cursor: pointer;
}

.hoverable:hover {
    -webkit-box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.2),
        0 3px 10px 0 rgba(0, 0, 0, 0.19);
    box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}

.card .difficulty {
    position: absolute;
    bottom: 7%;
    left: 2%;
    background: #fff;
    border-radius: 100px;
    padding: 2px 13px;
}

.card .difficulty.facile {
    color: #97b804;
}

.card .difficulty.moyen {
    color: #e3990f;
}

.card .difficulty.difficile {
    color: #f26a53;
}

.card h5 {
    font-size: 1em;
    padding: 0;
    margin: 10px 0;
    text-align: left;
    line-height: 1.3em;
    color: #1a727d;
    font-weight: 800;
    /* font-family: 'Baloo Thambi', cursive; */
}

.card .card-content {
    padding: 7px 16px;
}

.card .card-content p {
    text-align: left;
    color: #68a1a8;
    font-size: 0.9em;
}

.card .card-image .blob {
    /* background-image: url(/img/blob-1.4887153e.svg); */
    /*background-size: 97px 59px; */
    width: 55px;
    height: 55px;
    /*background-position: center; */
    overflow: visible;
    position: absolute;
    bottom: 7px;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card .card-image .blob .fatBlobText {
    padding-top: 6px;
}

.card .card-image .blob p {
    text-align: center;
    margin: 0;
    padding: 0;
    margin-top: -6px;
    font-size: 1em;
    font-family: "Baloo Thambi", cursive;
    color: #fff;
    position: relative;
}

.card .card-image .blob svg {
    fill: #26bad4;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.card .card-image img {
    border-radius: 6px 6px 0 0;
}

.snacks .collection {
    margin: 2.3rem 0 1rem 2rem;
    max-height: 300px;
    overflow: scroll;
    border: none;
    padding-bottom: 100px;
}

.snacks img {
    position: absolute;
    bottom: 0;
    max-width: 100px;
}

.collection-container {
    position: relative;
}

.collection-container .gradient {
    pointer-events: none;
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 0;
    left: 0;
    height: 150px;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjcwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
    background: -moz-linear-gradient(top,
            rgba(255, 255, 255, 0) 0%,
            rgba(239 247 249) 70%);
    background: -webkit-gradient(linear,
            left top,
            left bottom,
            color-stop(0%, rgba(255, 255, 255, 0)),
            color-stop(70%, rgba(239 247 249)));
    background: -webkit-linear-gradient(top,
            rgba(255, 255, 255, 0) 0%,
            rgba(239 247 249) 70%);
    background: -o-linear-gradient(top,
            rgba(255, 255, 255, 0) 0%,
            rgba(239 247 249) 70%);
    background: -ms-linear-gradient(top,
            rgba(255, 255, 255, 0) 0%,
            rgba(239 247 249) 70%);
    background: linear-gradient(to bottom,
            rgba(255, 255, 255, 0) 0%,
            rgba(239 247 249) 70%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
}

.recentlyViewed .deleteRecent {
    position: absolute;
    color: #fff;
    /* padding: 0; */
    line-height: 35px;
    font-size: 21px;
    font-weight: 900;
    right: 4px;
    /* bottom: 10px; */
    background-color: #f16a54;
    top: 12px;
    width: 35px;
    height: 35px;
}

.recentlyViewed .deleteRecent:hover {
    cursor: pointer;
    background-color: #ce4530 !important;
}

.slick-slider {
    margin-bottom: 1.5rem;
}

.hints {
    margin: 0;
    text-align: left;
    margin: 0 0 10px !important;
}

.hints li {
    display: inline-block;
    background-color: #feb813;
    margin: 0px 5px 10px;
    padding: 5px 21px;
    border-radius: 50px;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}

.hints li:hover {
    background-color: #e2a20a;
}

.hints a {
    color: #875f00;
}

.slick-prev:before,
.slick-next:before {
    font-family: "slick";
    font-size: 35px;
    line-height: 1;
    opacity: 1;
    color: #feb912;
}

.slick-prev {
    left: -50px;
}

.slick-next {
    right: -50px;
}

.clearRecent {
    font-size: 0.75em;
    cursor: pointer;
}

.see-more {
    background: linear-gradient(319deg,
            rgba(0, 161, 201, 1) 0%,
            rgba(2, 178, 209, 1) 100%);
}

.see-more h3 {
    position: absolute;
    bottom: 0;
    color: #fff;
    margin: 0;
    padding: 0;
    font-size: 1.6rem;
    line-height: 2rem;
    margin: 30px;
    display: block;
    text-align: left;
}

#more-categories li {
    list-style-type: none;
    background-color: transparent;
    padding: 0px;
    margin: 0;
    border-radius: 0;
}

#more-categories li:hover {
    background: #eee;
}

@media screen and (min-device-width: 1500px) {
    /* body{
      background-size: 100% 730px;
    } */
}

@media screen and (min-device-width: 2200px) {
    /* body{
      background-position: 0 0;
    } */
}

@media only screen and (min-width: 993px) {
    .container {
        width: 80% !important;
    }
}

@media only screen and (max-width: 1200px) {
    html {
        font-size: 13px;
    }

    /* body{
        background-size: 100% 730px;
    } */
    /* body.sub-page {

    } */

    .img-01,
    .img-02,
    .img-03 {
        max-width: 200px;
        top: 14% !important;
    }

    .img-01 {
        max-width: 290px;
    }
}

@media screen and (max-device-width: 768px) {
    /* body{
      background-size: 100% 530px;
    } */
    /* body.sub-page {
      background-position: 0 -190px;
      background-size: 140% 36%;
  } */

    .container {
        width: 90%;
    }

    nav ul a{
        padding: 0 6px;
    }

    nav .brand-logo i.material-icons{
        margin: 0 10px;
    }

    .img-01,
    .img-02,
    .img-03 {
        max-width: 160px;
        top: 11% !important;
    }

    .img-01 {
        max-width: 290px;
    }

    .autocomplete {
        width: 100%;
    }

    .card .card-content {
        padding: 12px !important;
    }

    .card.small {
        height: 245px;
    }

    .card.small .card-image+.card-content,
    .card.medium .card-image+.card-content,
    .card.large .card-image+.card-content {
        max-height: 100% !important;
    }

    .hints li {
        margin: 0 5px 8px;
        text-align: center;
        padding: 5px 17px;
    }

    .home h2 {
        margin: 1.5em 0 0.5em !important;
    }
}

/* Portrait */
@media only screen and (min-device-width: 420px) and (max-device-width: 992px) {
    .img-01 {
        display: none;
    }
}

@media screen and (max-device-width: 420px) {
    body {
        background-size: 1000px 800px;
        background-position: -350px 0px;
    }

    body.sub-page {
        background-size: 100% 60px;
        background-position: 0px 0px;
    }

    nav {
        display: none !important;
    }

    h2 {
        font-size: 20px !important;
    }

    .img-01,
    .img-02,
    .img-03 {
        max-width: 70px;
        top: 2% !important;
    }

    .img-01 {
        max-width: 125px;
        top: 1% !important;
        right: 6%;
    }

    .hero-heading {
        font-size: 2em !important;
    }

    .shelf-nav {
        display: block !important;
    }

    .snacks .collection {
        margin: 2.3rem 0 1rem;
    }

    .recentlyViewed .deleteRecent {
        line-height: 25px;
        right: 4px;
        width: 25px;
        height: 25px;
    }

    .sidenav-trigger {
        display: inline-block !important;
    }

    .autocomplete input[type=text]:not(.browser-default){
        padding: 0px 15px;
    }

    .search-icon{
        right: 15px;
    }

    .hints li {
        width: 30%;
        text-align: center;
    }

    .categories,
    .favourites {
        margin-top: 70px !important;
    }

    .recipe-btn i {}

    .recipe-img {
        /* margin: 40px auto 0 !important; */
    }

    .mtb {
        margin: 1em 0 !important;
    }

    .weightsList ul,
    .ldlList ul {
        margin: 0;
    }

    .weightsList li,
    .ldlList li {
        padding: 10px 0px;
    }

    .checklist,
    .methods {
        padding: 0 2em;
    }

    .info-img {
        max-width: 20% !important;
    }
}

@media screen and (max-device-width: 320px) {
    .container {
        width: 100%;
    }
}
</style>
