<template>
<div class="container lifestyle">
    <div class="row small">
        <img class="img-03" src="@/assets/img/img-budget.png">
        <div class="col s12 m8">
            <h1 class="hero-heading">Low-Fat Eating on a Budget</h1>
            <p class="hero-p"><strong>Here are some tips for combining budget conscious shopping with the low-fat eating plan to keep your options low cost, tasty, and low fat.</strong></p>
            <p class="hero-p">Keep in mind your Max Daily Fat, which was provided to you during your nutrition consultation. Remember that all fats count!</p>
        </div>
    </div>

    <div class="row small">
        <div class="col s12">
            <h3>Bringing it Home Under Budget</h3>
        </div>
        <div class="col s12 m12">
            <div class="card-panel">
                <p class="hero-p two-col">
                <ul>
                    <li> <strong>Plan Ahead –</strong> Create a list of meals that can be made with
                        what you already have on hand. “Shopping” in your kitchen
                        minimizes the number of items you need to spend money on. </li>

                    <li> <strong>Use It All Up –</strong> Think of additional meals you can cook
                        with each ingredient.
                        Bag of potatoes – a potato can be baked, made into
                        low-fat potato soup or a mashed potato side dish</li>

                    <li> <strong>Try Meatless Meals –</strong> Meat, poultry, and seafood will
                        often be the most expensive items. Meatless meals that
                        use other protein sources, like beans, can save money.</li>

                    <li> <strong>Lower Convenience Costs –</strong> Reduce the number of
                        pre-cooked items on the shopping list which are usually
                        more expensive.
                        Bouillon powder is usually cheaper than buying broth.
                        Dry beans are usually less costly than pre-cooked,
                        canned beans</li>

                    <li> <strong>Stock Up –</strong> Take advantage of sales to stock up on non-perishable
                        items: dry milk powder, pasta, rice, beans, cereal,
                        canned goods, frozen fruits and veggies. You can also stock
                        up on fresh items that can be frozen for later: veggies, fruits,
                        bread, bagels, tortillas, milk (works for cooking and baking,
                        but not drinking) and low-fat cheese.</li>

                    <li> <strong>Shop on Wednesday Nights –</strong> Lifehacker.com states that
                        the best time to shop at a grocery store is usually Wednesday
                        night when the new circular ads come out, but the store might
                        still honor the previous week’s sales, allowing you to shop the
                        sales from current and previous week’s circulars. Call the store
                        first to ask what their policy is.</li>

                    <li> <strong>Try Low-Cost Grocery Chains –</strong> Deep-discount grocery
                        chains can offer plenty of healthy options. Just be sure to
                        check the store’s payment policies, as they may differ from
                        traditional grocery chains.</li>

                    <li> <strong>Shop the Store Brand –</strong> The quality may be the same
                        at a lower price.</li>

                    <li> <strong>Look for Coupons/Vouchers –</strong> A great way to use a coupon is on
                        an item that is already on sale. Double coupons and online
                        coupons are also ways to save.
                    </li>
                    <li> <strong>Think about Drinks –</strong> Items that are in single serving
                        bottles and cans are going to be more expensive. Cut out
                        sodas/fizzy drinks, sports drinks, fruit drinks, teas and bottled water
                        from your shopping list or look for ways to make drinks
                        at home for less money.</li>
                </ul>
                </p>
            </div>
        </div>
    </div>

    <div class="row small">
        <div class="col s12 m6">
            <h3>Making it Last</h3>
            <div class="card-panel">
                <p class="hero-p">
                <ul>
                    <li> <strong>Nature Made –</strong> Plan meals using produce that keeps
                        well naturally.
                        Onions, potatoes, carrots and apples will
                        last a long time if stored properly</li>
                    <li> <strong>Store it Right –</strong> Storing food properly allows you to
                        make it last as long as possible. Consider freezing items
                        for longer storage times.</li>
                    <li> <strong>Use Portions –</strong> This allows you to stretch your foods as
                        far as they were planned to go. Portion out foods according
                        to the recipe serving size. Break out a big bag of snacks into individual bags.</li>
                    <li> <strong>Keep a Snack Stash –</strong> Set aside planned foods that
                        everyone understands are the “go-to” snack foods.</li>
                </ul>
                </p>
            </div>
        </div>
        <div class="col s12 m6">
            <h3>Making it Fast</h3>
            <div class="card-panel">
                <p class="hero-p">
                <ul>
                    <li> <strong>Make Extra and Use Leftovers –</strong> Double a recipe and
                        save the extras. Freeze leftovers, then pull out another day
                        for a fast meal – just heat and eat!</li>
                    <li> <strong>Cook the Whole Package –</strong> When making a meal that
                        uses rice, beans or pasta, cook the whole package, then
                        freeze the rest to use as an ingredient in another meal.
                        Pasta needs to be slightly undercooked to be frozen
                        and reheated for another use.</li>
                    <li> <strong>Have a Cooking Day –</strong> Keep one day a week where
                        as much prep work and cooking is done so meals come
                        together fast during the rest of the days.</li>
                </ul>
                </p>
            </div>
        </div>
    </div>

    <div class="row small">
        <div class="col s12">
            <h3>Making it Tasty</h3>
        </div>
        <div class="col s12 m6">
            <div class="card-panel">
                <p class="hero-p">
                <ul>
                    <li> <strong>Check for Favorites –</strong> When planning meals, ask everyone
                        in the household, “What are some meals you would like this
                        week?” Not all requests will be met at every meal, but there
                        will be something for everybody throughout the course of
                        the week.</li>
                    <li> <strong>Open Up to New Recipes –</strong> A quick search online for
                        “budget meals” will expand the meal options. Local libraries
                        will likely have budget-minded cookbooks as well.</li>
                    <li> <strong>Use Seasonings –</strong> Many dry herbs and spices can be bought
                        inexpensively. Some ethnic grocers may
                        offer the spices of their cuisine at cheaper prices.</li>
                </ul>
                </p>
            </div>
        </div>
        <div class="col s12 m6">
            <div class="hero-p card-panel blue budget-panel">
                <img class="budget-bubble" src="@/assets/img/budget-topten.png" alt="">
                <h3>TOP 10 BUDGET CONSCIOUS LOW-FAT FOODS</h3>
                <ol>
                    <li>Plain Oatmeal</li>
                    <li>Generic Dry Breakfast Cereal</li>
                    <li>Canned Tuna in Water</li>
                    <li>Sliced Bread</li>
                    <li>Frozen Vegetables</li>
                    <li>Potatoes</li>
                    <li>Apples</li>
                    <li>Rice</li>
                    <li>Skimmed Milk</li>
                    <li>Dry Beans<sup>&ast;</sup></li>
                </ol>
                <small><sup>&ast;</sup> Use sparingly if you are sensitive to the gas forming effects of beans</small>
            </div>
        </div>
    </div>

</div>
</template>

<script>
export default {
    name: 'EatingOnABudget',
    data() {
        return {}
    },
    mounted() {
        document.body.classList.add("sub-page");
    },
    methods: {},
    computed: {}
}
</script>

<style>
.budget-panel {
    position: relative;
}

.budget-bubble {
    position: absolute;
    top: 35%;
    right: -40px;
    max-width: 140px;
}

@media screen and (max-device-width: 420px) {
    .budget-bubble {
        position: absolute;
        top: 37%;
        right: -20px;
        max-width: 130px;
    }
}
</style>
