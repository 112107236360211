<template>
<div class="login container card-panel">
    <h1 class="center">Login</h1>

    <button class="btn white googleLogin" @click="socialLogin">
        <svg viewBox="0 0 366 372" xmlns="http://www.w3.org/2000/svg">
            <path d="M125.9 10.2c40.2-13.9 85.3-13.6 125.3 1.1 22.2 8.2 42.5 21 59.9 37.1-5.8 6.3-12.1 12.2-18.1 18.3l-34.2 34.2c-11.3-10.8-25.1-19-40.1-23.6-17.6-5.3-36.6-6.1-54.6-2.2-21 4.5-40.5 15.5-55.6 30.9-12.2 12.3-21.4 27.5-27 43.9-20.3-15.8-40.6-31.5-61-47.3 21.5-43 60.1-76.9 105.4-92.4z" id="Shape" fill="#EA4335" />
            <path d="M20.6 102.4c20.3 15.8 40.6 31.5 61 47.3-8 23.3-8 49.2 0 72.4-20.3 15.8-40.6 31.6-60.9 47.3C1.9 232.7-3.8 189.6 4.4 149.2c3.3-16.2 8.7-32 16.2-46.8z" id="Shape" fill="#FBBC05" />
            <path d="M361.7 151.1c5.8 32.7 4.5 66.8-4.7 98.8-8.5 29.3-24.6 56.5-47.1 77.2l-59.1-45.9c19.5-13.1 33.3-34.3 37.2-57.5H186.6c.1-24.2.1-48.4.1-72.6h175z" id="Shape" fill="#4285F4" />
            <path d="M81.4 222.2c7.8 22.9 22.8 43.2 42.6 57.1 12.4 8.7 26.6 14.9 41.4 17.9 14.6 3 29.7 2.6 44.4.1 14.6-2.6 28.7-7.9 41-16.2l59.1 45.9c-21.3 19.7-48 33.1-76.2 39.6-31.2 7.1-64.2 7.3-95.2-1-24.6-6.5-47.7-18.2-67.6-34.1-20.9-16.6-38.3-38-50.4-62 20.3-15.7 40.6-31.5 60.9-47.3z" fill="#34A853" />
        </svg>
        Login with Google
    </button>

    <div class="alternative-payment-separator" data-alternative-payment-separator>
        <span class="alternative-payment-separator__content">OR</span>
    </div>

    <form @submit.prevent="login">
        <div class="input-field">
            <label for="email">Email:</label>
            <input type="email" name="email" v-model="email" />
        </div>
        <div class="input-field mt20">
            <label for="password">Password:</label>
            <input type="password" name="password" v-model="password" />
        </div>
        <div class="forgot-password">
            <span>
                <router-link to="/forgot">Forgot password?</router-link>
            </span>
        </div>
        <p class="red-text center" v-if="feedback">{{feedback}}</p>
        <div class="field center">
            <button class="btn btn-large mt30">Login</button>
        </div>
        <p class="mt">
            Don't have an account? Sign up
            <span>
                <router-link to="/signup">
                    <strong>here</strong>
                </router-link>
            </span>
        </p>
    </form>
</div>
</template>

<script>
import db from "@/firebase/init";
import firebase from "firebase";
import request from 'request';
export default {
    name: "login",
    data() {
        return {
            email: null,
            password: null,
            feedback: null,
        };
    },
    methods: {
        login() {
            /* eslint-disable */
            // console.log(this.email, this.password)

            if (this.email && this.password) {
                firebase
                    .auth()
                    .signInWithEmailAndPassword(this.email, this.password)
                    .then((cred) => {
                        db.collection("users").add({
                            user_id: cred.user.uid,
                            email: this.email,
                        });

                        /* eslint-disable */
                        var toastHTML = "<span>Welcome!</span>";
                        M.toast({
                            html: toastHTML
                        });
                        this.$router.push({
                            name: "home"
                        });

                        const url = 'https://hooks.slack.com/services/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';
                        const text = 'New signup, Email/Pass, ' + firebase.auth().currentUser.email;

                        request.post({
                                headers: {
                                    'Content-type': 'application/json'
                                },
                                url,
                                form: JSON.stringify({
                                    text
                                }),
                            },
                            (error, res, body) => console.log(error, body, res.statusCode)
                        );
                    })
                    .catch((err) => {
                        this.feedback = err.message;
                    });

                this.feedback = null;
            } else {
                this.feedback = "Please fill in both fields";
            }
        },
        socialLogin() {
            const provider = new firebase.auth.GoogleAuthProvider();

            firebase
                .auth()
                .signInWithPopup(provider)
                .then((result) => {
                    var token = result.credential.accessToken;
                    // var user = result.user;

                    //this is what you need
                    var isNewUser = result.additionalUserInfo.isNewUser;
                    if (isNewUser) {
                        //delete the created user
                        // console.log("NEW USER");
                        db.collection("users").add({
                            user_id: result.user.uid,
                            email: result.user.email,
                        });

                        const url = 'https://hooks.slack.com/services/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';
                        const text = 'New signup, with Google, ' + result.user.email;

                        request.post({
                                headers: {
                                    'Content-type': 'application/json'
                                },
                                url,
                                form: JSON.stringify({
                                    text
                                }),
                            },
                            (error, res, body) => console.log(error, body, res.statusCode)
                        );

                    } else {
                        console.log("ALREADY A USER");
                    }

                    this.$router.push({
                        name: "home"
                    });
                })
                .catch((err) => {
                    this.feedback = err.message;
                });
        },
    },
};
</script>

<style>
.login {
    max-width: 400px !important;
    margin: 50px auto !important;
}

.signup-left .btn {
    background: #fff !important;
}

.signup-left .field .btn,
.login .field .btn {
    background: #01b1d0 !important;
}

.login .googleLogin {
    color: #2c3e50;
    width: 90%;
    padding: 7px 30px;
    height: auto;
}

.login button svg {
    max-width: 20px;
    margin-top: 8px;
    float: left;
}

.login h1 {
    font-size: 2em;
    margin: 1em 0 1.5em;
}

.login .field label {
    float: left;
}

.forgot-password span {
    width: 100%;
    text-align: right;
    display: block;
    font-size: 0.85em;
    color: #01b1d0;
    font-weight: 700;
}

.mt {
    margin-top: 2em;
}
</style>
