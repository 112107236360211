<template>
<div class="container shoppingList">
    <div class="row small">
        <img class="img-02" src="@/assets/img/img-shopping.png" />
        <h1 class="hero-heading">Your shopping list</h1>
    </div>

    <div class="row">
        <div class="col s12 m7">
            <ul>
                <li>
                    <div class="input-field">
                        <i class="material-icons prefix">add</i>
                        <input id="icon_prefix" type="text" class="validate addItem" v-model="manualShoppingItem" />
                        <label for="icon_prefix" class="addItemLabel">ADD ITEM</label>
                    </div>

                    <a class="btn-floating btn-medium waves-effect waves-light addItemButton" v-on:click="addToList()">
                        <i class="material-icons">chevron_right</i>
                    </a>
                </li>
            </ul>
            <ul v-if="this.shoppingList.length">
                <li v-for="(data, index) in shoppingList.slice().reverse()" :key="index">
                    <input type="checkbox" />
                    <p>
                        <span>{{data}}</span>
                        <a class="btn-floating waves-effect waves-light deleteShoppingItem" @click="deleteShoppingItem(data)">
                            <i class="material-icons">delete</i>
                        </a>
                    </p>
                </li>
            </ul>

            <!-- <button v-if="user" class="recipe-btn left" id="addToShopping">add to shopping list <i class='material-icons right'>shopping_basket</i></button> -->
        </div>

        <div class="left-align col s12 m7" v-if="!this.shoppingList.length">
            <h5>Your shopping list is empty</h5>
            <p>Add something above or add ingredients from one of our recipe pages.</p>
        </div>
    </div>
</div>
</template>

<script>
import recipes from "@/assets/data/recipes-v03";
import firebase from "firebase";
import db from "@/firebase/init";
export default {
    //custom option named recipes
    data() {
        return {
            recipes: recipes,
            user: firebase.auth().currentUser,
            shoppingList: [],
            manualShoppingItem: null,
        };
    },
    created() {
        db.collection("users")
            .where("user_id", "==", this.user.uid)
            .onSnapshot((snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    this.shoppingList = change.doc.data().shoppingList;
                });
            });
    },
    mounted() {
        this.user = firebase.auth().currentUser;
        document.body.classList.add("sub-page");

        /* eslint-disable */
        console.log(this.displayRecipes);
        // retrieve weight list
    },
    methods: {
        getImgUrl() {
            let randomNum = Math.floor(Math.random() * (1 - 230 + 1)) + 1;
            let imgSrc = "https://source.unsplash.com/700x500/?food?sig=" + randomNum;
            return imgSrc;
        },
        addToList(index) {
            console.log(this.manualShoppingItem);

            if (this.manualShoppingItem) {
                db.collection("users")
                    .where("user_id", "==", this.user.uid)
                    .get()
                    .then((snapshot) => {
                        snapshot.forEach((doc) => {
                            db.collection("users")
                                .doc(doc.id)
                                .update({
                                    shoppingList: firebase.firestore.FieldValue.arrayUnion(
                                        this.manualShoppingItem
                                    ),
                                });
                        });
                    })
                    .then(() => {
                        var toastHTML = "<span> Added to shopping list</span>";
                        M.toast({
                            html: toastHTML
                        });

                        document.querySelector(".prefix").classList.remove("active");
                        document.querySelector(".addItemLabel").classList.remove("active");
                        this.manualShoppingItem = null;
                    })
                    .catch((err) => {
                        /* eslint-disable */
                        alert(err);
                    });
            }
        },
        deleteShoppingItem(shoppingItem) {
            console.log(shoppingItem);
            db.collection("users")
                .where("user_id", "==", this.user.uid)
                .get()
                .then((snapshot) => {
                    snapshot.forEach((doc) => {
                        db.collection("users")
                            .doc(doc.id)
                            .update({
                                shoppingList: firebase.firestore.FieldValue.arrayRemove(
                                    shoppingItem
                                ),
                            });
                    });
                })
                .then(() => {
                    var toastHTML = "<span>Item Removed</span>";
                    M.toast({
                        html: toastHTML
                    });
                });
        },
    },
};
</script>

<style>
.shoppingList .img-02 {
    width: 420px;
    position: absolute;
    top: 43px;
    right: 10%;
    z-index: 0;
}

.shoppingList {
    margin-top: 100px !important;
}

.shoppingList h1 {
    text-align: left !important;
    margin: 0 0 0.5em !important;
    padding: 0 !important;
}

.shoppingList h5 {
    font-family: "Baloo Thambi", cursive;
    color: #1a727e;
}

.shoppingList .card-panel {
    padding: 14px 10px 10px;
}

.shoppingList .btn-floating.addItemButton {
    background-color: #fbab18 !important;
    float: right;
}

.shoppingList p,
.shoppingList h2 {
    text-align: left;
}

.shoppingList .recipe div {
    max-width: 600px;
    background: #eee;
    margin: 20px;
    padding: 20px;
    float: left;
}

.shoppingList h3 {
    margin: 40px 0 0;
}

.shoppingList ul {
    text-align: left;
}

.shoppingList ol {
    text-align: left;
}

.shoppingList li {
    border-bottom: 1px solid #feb813;
    text-align: left;
    padding: 10px 20px;
}

.shoppingList a {
    color: #42b983;
}

.shoppingList .addItem {
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
    height: auto !important;
    margin-top: 10px !important;
}

.shoppingList .input-field {
    padding: 5px 0;
    width: 80%;
    display: inline-block;
    margin-top: 0;
}

.shoppingList .input-field .prefix~label {
    font-weight: bold;
    color: #c4cbd3;
}

.shoppingList .input-field .prefix.active {
    color: #26bad4;
}

.shoppingList input[type="text"]:focus:not([readonly]) {
    margin-left: 3em !important;
    border: none !important;
}

.shoppingList input[type="text"]:not(.browser-default):focus:not([readonly])+label {
    color: #26bad4;
}

.shoppingList input.valid[type="text"] {
    margin-left: 3em !important;
    border: none !important;
    box-shadow: none;
}

.shoppingList .input-field .prefix {
    margin-left: -6px;
    color: #c4cbd3;
}

.shoppingList .deleteFavourite {
    position: absolute;
    right: 4px;
    /* bottom: 10px; */
    background-color: #f16a54;
    top: 12px;
    width: 35px;
    height: 35px;
}

.shoppingList .deleteFavourite:hover {
    cursor: pointer;
    background-color: #00a1c9 !important;
}

.shoppingList .deleteFavourite.btn-floating i {
    line-height: 35px;
    font-size: 1.4em;
}

.deleteShoppingItem {
    width: 30px;
    height: 30px;
    line-height: 30px;
    float: right;
    background-color: #f16a54 !important;
}

.deleteShoppingItem i {
    font-size: 1rem;
    line-height: 30px;
}

.nofavs {
    margin-top: 3em !important;
    padding: 20px;
}

.nofavs h4 {
    color: #f16a54;
    font-size: 2em;
}

@media screen and (max-device-width: 1024px) {
    .shoppingList .img-02 {
        width: 360px;
        top: 53px;
    }
}

@media screen and (max-device-width: 768px) {
    .shoppingList .img-02 {
        display: block;
        width: 280px;
        top: 133px;
        right: 6%;
    }
}

@media screen and (max-device-width: 600px) {
    .shoppingList .img-02 {
        display: none;
    }
}

@media screen and (max-device-width: 420px) {
    .row.small {
        margin-bottom: 0px;
    }
}
</style>
