<template>
  <div>
    <div class="home">
      <div class="container">
        <!--  Intro  -->
        <div class="row">
          <img class="img-01" src="@/assets/img/img-01.png" />
          <div class="col s12 m12 l9">
            <h1 class="hero-heading">وصفات HoFH</h1>
            <p class="hero-p">
              لقد جمعنا مجموعة من الوصفات اللذيذة قليلة الدسم والبسيطة للأشخاص
              المصابين بفرط كوليسترول الدم العائلي متماثل الزيجوت (HoFH)، وهي
              حالة وراثية نادرة تؤدي إلى ارتفاع مستويات الكوليسترول في الدم.
              توفر هذه الوصفات تنوعًا إضافيًا في خطة النظام الغذائي لمرضى HoFH
              الذين نصحهم مقدم خدمة الرعاية الصحية المتابع لحالاتهم باتباع خطة
              تناول وجبات منخفضة جدًا للدهون. هذه الوصفات ليست مُعدّة لتكون
              بديلًا عن الأدوية الخافضة للدهون وليست كافية بمفردها لتقليل
              مستويات الكوليسترول العالية لدى الأشخاص المصابين بمرض HoFH. يجب أن
              يستمر المرضى في تناول أدويتهم على النحو الموصوف في الوصفة الطبية
              وأن يتبعوا دائمًا نصيحة مقدم الرعاية الصحية. يُرجى الانتباه لأي
              حساسية تجاه الأطعمة قد تكون لديك عند اختيار الوصفات المرغوبة.
            </p>

            <router-link  :to="'/arabic/all-recipes/'">
              <button class="btn see-all">شاهد كل الوصفات</button>
            </router-link>

            <!-- <p class="hero-p">
                        <b>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad vel fugit, asperiores nobis, beatae illum aperiam, perferendis quos unde officiis assumenda amet necessitatibus culpa sapiente molestias nemo exercitationem obcaecati ea.</b>
                    </p> -->
          </div>
        </div>

        <!--  Search bar  -->
        <div class="row">
          <div class="col m12 s12">
            <Autocomplete
              placeholder="البحث عن وصفة أو مكون"
              :items="recipes"
              title
              @selected="recipeSelected"
            />
          </div>
        </div>
      </div>

      <div class="container">

        <!--  Veggie friendly  -->
        <div class="row">
            <h2>نباتي</h2>

            <VueSlickCarousel v-bind="slickOptions">
                <div class="col s6 m3" v-for="(data, index) in vegRecipes.slice(0, 12)" :key="index">
                    <div class="card hoverable small">
                        <router-link :to="'/arabic/recipe/' + data.slug">
                        <div class="card-image">
                        <div v-if="data.hasImage">
                            <img
                            :src="require(`@/assets/img/recipes/${data.img}.jpg`)"
                            />
                        </div>
                        <div v-else>
                            <img src="@/assets/img/default-recipe.jpg" />
                        </div>
                        <span class="difficulty" :class="data.difficulty">{{
                            data.difficulty
                        }}</span>
                        <div class="blob">
                            <svg
                            viewBox="0 0 200 200"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="M55.4,-66.5C68,-55.5,71.7,-34.4,75.3,-13.4C78.8,7.6,82.3,28.6,74.6,44.3C67,60,48.2,70.6,28.9,76.2C9.6,81.8,-10.3,82.5,-26.7,75.6C-43.1,68.7,-56,54.2,-67.2,37.5C-78.4,20.8,-87.8,1.8,-86.8,-17.2C-85.7,-36.3,-74.1,-55.4,-57.9,-65.8C-41.7,-76.2,-20.8,-78,0.3,-78.3C21.4,-78.7,42.8,-77.6,55.4,-66.5Z"
                                transform="translate(100 100)"
                            />
                            </svg>
                            <div class="fatBlobText">
                            <p>الدهون :</p>
                            <p>{{ data.fat }}g</p>
                            </div>
                        </div>
                        </div>
                        <div class="card-content">
                        <h5 class="card-header">{{ data.title }}</h5>
                        <p v-if="data.serves">
                            <strong>عدد الحصص:</strong> {{ data.serves }}
                        </p>
                        <p><strong>السعرات الحرارية:</strong> {{ data.calories }}سعر حراري</p>
                        </div>
                    </router-link>
                    </div>
                </div>

                <!--  See all  -->
                <!-- <div class="col s6 m3">
                    <router-link :to="'/categories/vegetarian'">
                        <div class="card hoverable small see-more valign-wrapper">
                            <h3>
                                See all
                                <i class="small material-icons">trending_flat</i>
                            </h3>
                        </div>
                    </router-link>
                </div> -->
            </VueSlickCarousel>
        </div>

        <!--  Quick and easy  -->
        <div class="row">
            <h2>سريع وسهل</h2>

            <VueSlickCarousel v-bind="slickOptions">
                <div class="col s6 m3" v-for="(data, index) in quickRecipes.slice(0, 12)" :key="index">
                    <div class="card hoverable small">
                        <router-link :to="'/arabic/recipe/' + data.slug">
                            <div class="card-image">
                            <div v-if="data.hasImage">
                                <img
                                :src="require(`@/assets/img/recipes/${data.img}.jpg`)"
                                />
                            </div>
                            <div v-else>
                                <img src="@/assets/img/default-recipe.jpg" />
                            </div>
                            <span class="difficulty" :class="data.difficulty">{{
                                data.difficulty
                            }}</span>
                            <div class="blob">
                                <svg
                                viewBox="0 0 200 200"
                                xmlns="http://www.w3.org/2000/svg"
                                >
                                <path
                                    d="M55.4,-66.5C68,-55.5,71.7,-34.4,75.3,-13.4C78.8,7.6,82.3,28.6,74.6,44.3C67,60,48.2,70.6,28.9,76.2C9.6,81.8,-10.3,82.5,-26.7,75.6C-43.1,68.7,-56,54.2,-67.2,37.5C-78.4,20.8,-87.8,1.8,-86.8,-17.2C-85.7,-36.3,-74.1,-55.4,-57.9,-65.8C-41.7,-76.2,-20.8,-78,0.3,-78.3C21.4,-78.7,42.8,-77.6,55.4,-66.5Z"
                                    transform="translate(100 100)"
                                />
                                </svg>
                                <div class="fatBlobText">
                                <p>الدهون :</p>
                                <p>{{ data.fat }}g</p>
                                </div>
                            </div>
                            </div>
                            <div class="card-content">
                            <h5 class="card-header">{{ data.title }}</h5>
                            <p v-if="data.serves">
                                <strong>عدد الحصص:</strong> {{ data.serves }}
                            </p>
                            <p><strong>السعرات الحرارية:</strong> {{ data.calories }}سعر حراري</p>
                            </div>
                        </router-link>
                    </div>
                </div>
            </VueSlickCarousel>
        </div>

<!--  Family favourites -->
        <div class="row">
            <h2>التفضيلات العائلية</h2>

            <VueSlickCarousel v-bind="slickOptions">
                <div class="col s6 m3" v-for="(data, index) in familyFavs.slice(0, 12)" :key="index">
                    <div class="card hoverable small">
                        <router-link :to="'/arabic/recipe/' + data.slug">
                            <div class="card-image">
                            <div v-if="data.hasImage">
                                <img
                                :src="require(`@/assets/img/recipes/${data.img}.jpg`)"
                                />
                            </div>
                            <div v-else>
                                <img src="@/assets/img/default-recipe.jpg" />
                            </div>
                            <span class="difficulty" :class="data.difficulty">{{
                                data.difficulty
                            }}</span>
                            <div class="blob">
                                <svg
                                viewBox="0 0 200 200"
                                xmlns="http://www.w3.org/2000/svg"
                                >
                                <path
                                    d="M55.4,-66.5C68,-55.5,71.7,-34.4,75.3,-13.4C78.8,7.6,82.3,28.6,74.6,44.3C67,60,48.2,70.6,28.9,76.2C9.6,81.8,-10.3,82.5,-26.7,75.6C-43.1,68.7,-56,54.2,-67.2,37.5C-78.4,20.8,-87.8,1.8,-86.8,-17.2C-85.7,-36.3,-74.1,-55.4,-57.9,-65.8C-41.7,-76.2,-20.8,-78,0.3,-78.3C21.4,-78.7,42.8,-77.6,55.4,-66.5Z"
                                    transform="translate(100 100)"
                                />
                                </svg>
                                <div class="fatBlobText">
                                <p>الدهون :</p>
                                <p>{{ data.fat }}g</p>
                                </div>
                            </div>
                            </div>
                            <div class="card-content">
                            <h5 class="card-header">{{ data.title }}</h5>
                            <p v-if="data.serves">
                                <strong>عدد الحصص:</strong> {{ data.serves }}
                            </p>
                            <p><strong>السعرات الحرارية:</strong> {{ data.calories }}سعر حراري</p>
                            </div>
                        </router-link>
                    </div>
                </div>
            </VueSlickCarousel>
        </div>


        <!-- <div class="row">
          <div class="col s6 m3" v-for="(data, index) in recipes" :key="index">
            <div class="card hoverable small">
              <router-link :to="'/arabic/recipe/' + data.slug">
                <div class="card-image">
                  <div v-if="data.hasImage">
                    <img
                      :src="require(`@/assets/img/recipes/${data.img}.jpg`)"
                    />
                  </div>
                  <div v-else>
                    <img src="@/assets/img/default-recipe.jpg" />
                  </div>
                  <span class="difficulty" :class="data.difficulty">{{
                    data.difficulty
                  }}</span>
                  <div class="blob">
                    <svg
                      viewBox="0 0 200 200"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M55.4,-66.5C68,-55.5,71.7,-34.4,75.3,-13.4C78.8,7.6,82.3,28.6,74.6,44.3C67,60,48.2,70.6,28.9,76.2C9.6,81.8,-10.3,82.5,-26.7,75.6C-43.1,68.7,-56,54.2,-67.2,37.5C-78.4,20.8,-87.8,1.8,-86.8,-17.2C-85.7,-36.3,-74.1,-55.4,-57.9,-65.8C-41.7,-76.2,-20.8,-78,0.3,-78.3C21.4,-78.7,42.8,-77.6,55.4,-66.5Z"
                        transform="translate(100 100)"
                      />
                    </svg>
                    <div class="fatBlobText">
                      <p>الدهون :</p>
                      <p>{{ data.fat }}g</p>
                    </div>
                  </div>
                </div>
                <div class="card-content">
                  <h5 class="card-header">{{ data.title }}</h5>
                  <p v-if="data.serves">
                    <strong>عدد الحصص:</strong> {{ data.serves }}
                  </p>
                  <p><strong>السعرات الحرارية:</strong> {{ data.calories }}kcals</p>
                </div>
              </router-link>
            </div>
          </div>
        </div> -->

      </div>

      <div class="wide snacks">
        <div class="container">
          <!--  Low fat snacks  -->
          <div class="row">
            <div class="col s12 m5">
              <h2>الأطعمة الخالية من الدهون/ قليلة الدسم جدًا</h2>
              <p>
                <strong>
                  فيما يلي مجموعة مختارة من الأطعمة التي تحتوي على 1.5 جم من الدهون لكل وجبة أو أقل. تذكر بالطبع، أن الأطعمة التي تحتوي على 1.5 جم من الدهون لكل وجبة يتم تناولها بكميات كبيرة يمكن أن تؤدي إلى زيادة الدهون بنسبة كبيرة جدًا.
                </strong>
              </p>

              <p>إذا كانت لديك قيود غذائية لحالات طبية إضافية، فعليك استشارة مقدم خدمة الرعاية الصحية لمعرفة ما إذا كانت هذه الأطعمة مناسبة لك.</p>
            </div>
            <div class="col s12 m5">
              <div class="collection-container">
                <ul class="collection">
                    <li class="collection-item left-align">التفاح، تفاح مهروس - عدد الحصص: 1 صغير - الدهون: 0 جم</li>
                    <li class="collection-item left-align">الموز - عدد الحصص: 1 صغير - الدهون: 0 جم</li>
                    <li class="collection-item left-align">التوت - عدد الحصص: 1 كوب (128 جم) - الدهون: 0 جم</li>
                    <li class="collection-item left-align">العنب - عدد الحصص: 1 كوب (128 جم) - الدهون: 0 جم</li>
                    <li class="collection-item left-align">الكيوي - عدد الحصص: 1 متوسط - الدهون: 0 جم</li>
                    <li class="collection-item left-align">البرتقال واليوسفي - عدد الحصص: 1 صغير - الدهون: 0 جم</li>
                    <li class="collection-item left-align">الخوخ والنكتارين - عدد الحصص: 1 صغير - الدهون: 0 جم</li>
                    <li class="collection-item left-align">كوكتيل فواكه وفاكهة أخرى معلبة (ما عدا الجريب فروت) - عدد الحصص: 1/2 كوب (64 جم) - الدهون: 0 جم</li>
                    <li class="collection-item left-align">الزبيب والفواكه المجففة الأخرى - عدد الحصص: 1 أوقية (28 جم) - الدهون: 0 جم</li>
                    <li class="collection-item left-align">رقائق الموز - عدد الحصص: 1 أوقية (28 جم) - الدهون: 9 جم</li>
                    <li class="collection-item left-align">البروكلي - عدد الحصص: 1 كوب (128 جم) - الدهون: 0 جم</li>
                    <li class="collection-item left-align">الجزر - عدد الحصص: 1 كوب (128 جم) - الدهون: 0 جم</li>
                    <li class="collection-item left-align">القَرنَبِيط - عدد الحصص: 1 كوب (128 جم) - الدهون: 0 جم</li>
                    <li class="collection-item left-align">الفاصوليا خضراء - عدد الحصص: 1 كوب (128 جم) - الدهون: 0 جم</li>
                    <li class="collection-item left-align">سلطة الخضار والسبانخ - عدد الحصص: 1 كوب (128 جم) - الدهون: 0 جم</li>
                    <li class="collection-item left-align">الطماطم ومنتجات الطماطم المعلبة - عدد الحصص: 1 كبير - الدهون: 0 جم</li>
                    <li class="collection-item left-align">البصل - عدد الحصص: 1 صغيرة - الدهون: 0 جم</li>
                    <li class="collection-item left-align">البطاطس والبطاطا - عدد الحصص: 1 متوسطة - الدهون: 0 جم</li>
                    <li class="collection-item left-align">الكوسة - عدد الحصص: 1 متوسطة - الدهون: 0 جم</li>
                    <li class="collection-item left-align">خضروات مجمدة بدون إضافة دهون - عدد الحصص: 1 كوب (128 جم) - الدهون: 0 جم</li>
                    <li class="collection-item left-align">الفاصوليا المعلبة، والفاصوليا المجففة الخالية من الدسم، والفاصوليا النباتية المطبوخة - عدد الحصص: 1/2 كوب (64 جم) - الدهون: 1 جم</li>
                    <li class="collection-item left-align">بياض البيض، وبديل البيض - عدد الحصص: 1‏ ‏1/4‏ كوب (425 جم) - الدهون: 0 جم</li>
                    <li class="collection-item left-align">رومي مفروم خالٍ من الدهون 99% - عدد الحصص: 4 أوقيات - الدهون: 1 جم</li>
                    <li class="collection-item left-align">صدور رومي وشرائح رومي باردة - عدد الحصص: 1 أوقية (28 جم) - دهون: 1 جم</li>
                    <li class="collection-item left-align">نقانق قليلة الدسم مثل Ball Park® - عدد الحصص: 1 فرانكفورت - الدهون: 1 جم</li>
                    <li class="collection-item left-align">تونة في الماء - عدد الحصص: 2 أوقية (56 جم) - الدهون: 1.5 جم</li>
                    <li class="collection-item left-align">السمك الأبيض الخالي من الدهون مثل سمك موسى والبلطي - عدد الحصص: 4 أوقيات (114 جم) - الدهون: 2 جم</li>
                    <li class="collection-item left-align">الروبيان والمحار الصدفي والمحاريات الأخرى - عدد الحصص: 3 أوقيات (85 جم) - الدهون: 0.5 جم</li>
                    <li class="collection-item left-align">حليب خالي الدسم - عدد الحصص: 8 أوقيات (236 مل) - الدهون: 0 جم</li>
                    <li class="collection-item left-align">جبن قريش أو جبن ريكوتا خالية من الدسم - عدد الحصص: 1/2 كوب (170 جم) - الدهون: 0 جم</li>
                    <li class="collection-item left-align">زبادي خالي الدسم أو زبادي يوناني - عدد الحصص: 8 أوقيات (236 مل) - الدهون: 0 جم</li>
                    <li class="collection-item left-align">الجبن خالي الدسم، والجبن الكريمي، والقشدة الرائبة - عدد الحصص: 1 شريحة، 1 أوقية (30 مجم) - الدهون: 0 جم</li>
                    <li class="collection-item left-align">منتجات الألبان متوسطة الدسم - عدد الحصص: 1 أوقية (30 مجم) - الدهون: 0 جم</li>
                    <li class="collection-item left-align">شرائح الخبز  - عدد الحصص: 1 شريحة - الدهون: 0.5 جم</li>
                    <li class="collection-item left-align">خبز خلية النحل - عدد الحصص 1 صغير - دهون: 1.5 جم</li>
                    <li class="collection-item left-align">خبز البيجل - عدد الحصص: 1 صغير، 2 أوقية (56 جم) - الدهون: 1 جم</li>
                    <li class="collection-item left-align">الخبز العربي  - عدد الحصص: 1 صغير - دهون: 0 جم</li>
                    <li class="collection-item left-align">خبز المافن الإنجليزي- عدد الحصص: 2 أوقية (56 جم) - الدهون: 1.5 جم</li>
                    <li class="collection-item left-align">خبز التورتيلا - عدد الحصص: 1 صغير - دهون: 1 جم</li>
                    <li class="collection-item left-align">خبز تورتيلا بدقيق خالي الدسم - الدهون: 1 صغير - دهون: 0 جم</li>
                    <li class="collection-item left-align">الحبوب الأقل من 1.5 جم دهون لكل وجبة مثل CheeriosTM أو Wheaties‏® أو Rice ChexTM - عدد الحصص: 3/4 كوب (63 جم) - الدهون: 1.5 جم</li>
                    <li class="collection-item left-align">الحبوب الساخنة مثل Cream of WheatTM - عدد الحصص: 1 أوقية (30 مل) - الدهون: 0 جم</li>
                    <li class="collection-item left-align">المعكرونة والأرز السادة - عدد الحصص: 1/2 كوب (64 جم) - الدهون: 0 جم</li>
                    <li class="collection-item left-align">مقرمشات مملحة خالية من الدهون - عدد الحصص:  5 مقرمشات - الدهون: 0 جم</li>
                    <li class="collection-item left-align">خبز ماتزو - عدد الحصص: 1 أوقية (30 جم) - الدهون: 1 جم</li>
                    <li class="collection-item left-align">كعك الأرز بدون نكهة - عدد الحصص: 1 حجم متوسط - الدهون: 0.5 جم</li>
                    <li class="collection-item left-align">البريتزل (وجبة خفيفة مجففة ومملحة) - عدد الحصص: 1 أوقية (30 جم) - الدهون: 1 جم</li>
                    <li class="collection-item left-align">بخاخة زيت طبخ غير لاصق - عدد الحصص: رشة 1/4 ثانية - الدهون: 0 جم</li>
                    <li class="collection-item left-align">بخاخ نكهة الزبدة الخالية من الدسم - عدد الحصص: رشة 1/4 ثانية - الدهون: 0 جم</li>
                    <li class="collection-item left-align">الجيلي، المربى، مهروس الفاكهة - عدد الحصص: 2 ملعقة كبيرة - الدهون: 0 جم</li>
                    <li class="collection-item left-align">تتبيلة السلطة الخالية من الدهون - عدد الحصص: 2 ملعقة كبيرة - الدهون: 0 جم</li>
                    <li class="collection-item left-align">الليمون الطازج وحامض الليمون والخل - عدد الحصص: 1 قطعة - الدهون: 0 جم</li>
                    <li class="collection-item left-align">مايونيز خالي من الدسم  - عدد الحصص: 2 ملعقة كبيرة - الدهون: 0 جم</li>
                    <li class="collection-item left-align">البهارات مثل الكاتشب والخردل (المسطردة) وصوص الباربيكيو وصوص الترياكي وصوص الصويا والصلصا والصوص الحار وصوص Tabasco® والمقبّلات والمخللات - عدد الحصص: لكل وجبة على الزجاجة - الدهون: 0 جم</li>
                    <li class="collection-item left-align">برجر خضروات نباتي أصلي Boca® (كل قرص 1 جم دهون) - عدد الحصص: 1 قرص - الدهون: 1 جم</li>
                    <li class="collection-item left-align">حبوب CedarLane® قليلة الدسم، بوريتو بطريقة الجبن والأرز (المجمدة، 1 جم من الدهون لكل قطعة بوريتو) - عدد الحصص: 1 قطعة بوريتو - الدهون: 1 جم</li>
                    <li class="collection-item left-align">حمص Cedar’s® خالي من الدهون - عدد الحصص: 2 ملعقة كبيرة - الدهون: 0 جم</li>
                    <li class="collection-item left-align">الحساء (حتى 1.5 جم من الدهون لكل كوب) - عدد الحصص: لكل وجبة على العلبة - الدهون: 1.5 جم</li>
                    <li class="collection-item left-align">طماطم خالية من الدهون، صوص المعكرونة أو البيتزا - عدد الحصص: 1/4 كوب (85 جم) - الدهون: 0 جم</li>
                </ul>
                <div class="gradient"></div>
              </div>
            </div>
            <div class="col m1">
              <img
                class="responsive-img hide-on-small-only"
                src="@/assets/img/home-snacks.png"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row small card-panel yellow">
          <div class="col s12 m1 hide-on-small-only">
            <img
              class="responsive-img info-img"
              src="@/assets/img/info-img.png"
              alt
            />
          </div>
          <div class="col s12 m11">
            <p class="hero-p">
              <i>
                تنبيه: تحدث مع طبيبك أو غيره من مقدمي خدمات الرعاية الصحية قبل إجراء أي تغييرات في نظامك الغذائي، خاصة إذا كانت لديك قيود غذائية لحالات مثل مرض السكري أو أمراض الكلى. استشر طبيبك لمعرفة ما إذا كانت هذه النصائح ستناسب حالتك. عند بدء العلاج، لا تجر تغييرات كبيرة على نظامك الغذائي، بخلاف تقليل تناول الدهون. على سبيل المثال، قد يكون لزيادة تناول الألياف فجأة أو تناول الكثير من الأطعمة المكونة للغازات تأثير على أمراض المعدة.
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import firebase from 'firebase'
import getRecipes from "@/assets/data/recipes-arabic-01";
import getSnacks from "@/assets/data/snacks-italiano";
import Autocomplete from "@/components/Autocomplete";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
// import '@/assets/js/materialize.min.js';
// import Autocomplete from 'vue2-autocomplete-js'

export default {
  name: "home",
  components: {
    Autocomplete,
    VueSlickCarousel,
  },
  data() {
    return {
      recipes: [],
      recentlyViewed: [],
      recentlyViewedList: [],
      vegRecipes: [],
      heartyMeals: [],
      quickRecipes: [],
      snacks: [],
      familyFavs: [],
      slickOptions: {
        dots: true,
        arrows: true,
        // focusOnSelect: true,
        // infinite: false,
        // rtl: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 600,
            settings: {
                dots: false,
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 0,
            },
          },
        ],
      },
    };
  },
  metaInfo() {
    return {
      title: "HoFH Recipes",
      meta: [
        {
          name: "description",
          content:
            "A collection of recipes for people with homozygous familial hypercholesterolemia (HoFH)",
        },
        {
          name: "keywords",
          content:
            "HOFH, Hypercholesterolaemia, HOFH recipes, Hypercholesterolaemia Diet, HOFH meal plan, homozygous familial hypercholesterolemia, low fat recipes, low fat meals, ldl, ldl tracker, fat free meals",
        },
      ],
    };
  },
  computed: {},
  created() {
    /* eslint-disable */
    this.recipes = getRecipes;
    this.snacks = getSnacks;

    if (localStorage.recentlyViewed) {
      this.recentlyViewed = JSON.parse(localStorage.recentlyViewed);
      // console.log(this.recentlyViewed)
    }

    // console.log(firebase.auth().currentUser.emailVerified)

    for (var i = 0; i < this.recipes.length; i++) {
      var obj = this.recipes[i];

      if (obj.category.includes("vegetarian")) {
        this.vegRecipes.push(this.recipes[i]);
      }
      if (obj.category.includes("quick and easy")) {
        this.quickRecipes.push(this.recipes[i]);
      }
      if (obj.category.includes("family favorites")) {
        this.familyFavs.push(this.recipes[i]);
      }

      // for(var j = 0; j < 8; j++){
      //     if(this.recentlyViewed[j] == obj.slug){

      //       this.recentlyViewedList.push(this.recipes[i])

      //   }
      // }
    }

    for (var j = 0; j < 8; j++) {
      for (var i = 0; i < this.recipes.length; i++) {
        var obj = this.recipes[i];

        if (this.recentlyViewed[j] == obj.slug) {
          this.recentlyViewedList.push(this.recipes[i]);
        }
      }
    }

    // console.log(JSON.stringify(this.recentlyViewed[0]))
  },
  mounted() {
    document.body.classList.remove("sub-page");
  },
  methods: {
    recipeSelected(recipe) {
      // console.log(`recipe Selected:\ntitle: ${recipe.title}\ningredients: ${recipe.ingredients}`);
      this.$router.push({
        path: `/arabic/recipe/${recipe.slug}`,
      });
    },

    clearRecent() {
      this.recentlyViewedList = [];
      localStorage.recentlyViewed = [];
    },

    deleteRecent(title, index) {
      // event.preventDefault();
      // var title = localStorage.recentlyViewed.titleOf(recipe);
      this.recentlyViewedList.splice(
        this.recentlyViewedList.length - index - 1,
        1
      );
      // console.log(localStorage.recentlyViewed)

      var json = JSON.parse(localStorage["recentlyViewed"]);

      // console.log(title)
      // console.log(json)

      for (var i = 0; i < json.length; i++) {
        if (json[i] == title) {
          json.splice(i, 1);
          localStorage["recentlyViewed"] = JSON.stringify(json);
        }
      }

      console.log(json);
    },
  },
};
</script>

<style scoped>
/* * {
  direction: rtl;
} */

.row .col {
  float: right;
}

.right {
  float: left !important;
}

.left {
  float: right !important;
}

h2,
.left-align,
.hero-p,
.card h5,
.card .card-content p,
.methods li,
.methods h4,
.checklist h4,
ul:not(.browser-default),
.recipe .hero-heading,
.hero-heading {
  text-align: right !important
}

.see-all{
    background-color: #feb813 !important;
    color: #124b52;
    border-radius: 30px;
    height: auto;
    line-height: inherit;
    font-family: 'Baloo Thambi', cursive;
    padding: 10px 50px;
    float: right;
}

ul:not(.browser-default) {
  padding-right: 0;
}

.img-01 {
  width: 370px;
  position: absolute;
  top: 120px;
  left: 40px;
  right: unset;
  z-index: -1;
}

nav ul li {
  float: right;
}

.card-panel.yellow{
    display: flex;
    align-items: center;
}
</style>
