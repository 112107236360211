<template>
<div class="recipe container">

    <div class="row">
        <div class="col s12 m6">
            <div class="">
                <ul>
                    <li v-if="currentRecipe" class="left-align">
                         <span class="chip back-btn"> <a @click="$router.go(-1)">رجوع</a> <i class='material-icons'>arrow_left</i></span>
                        <h1 class="hero-heading">{{currentRecipe.title}}</h1>
                        <ul>
                            <!-- <li>Prep time: {{currentRecipe.time}}</li>
                                <li>Serves: {{currentRecipe.serves}}</li> -->
                            <div class="description">
                                <span v-if="currentRecipe.time"><strong>وقت التحضير:</strong> {{currentRecipe.time}}دقيقة &#x000B7; </span>
                                <span v-if="currentRecipe.difficulty"> <strong>الصعوبة:</strong> {{currentRecipe.difficulty}} &#x000B7; </span>
                                <span v-if="currentRecipe.serves"><strong> عدد الحصص:</strong> {{currentRecipe.serves}} </span>
                                <p>{{currentRecipe.description}}</p>
                                <!-- <p v-if="currentRecipe.notes"><strong>Nota: {{currentRecipe.notes}}</strong></p> -->
                                <!-- <p v-if="currentRecipe.heartuk"><strong>This recipe is sponsored from <a href="https://www.heartuk.org.uk/healthy-living/" target="_blank">heartuk.org.uk</a></strong></p> -->
                            </div>
                            <!-- <div class="tag">
                                <p>
                                    <span><strong>Categorie: </strong></span>
                                    <span class="chip" v-for="(category, index) in tagList" :key="index">
                                        <router-link :to="`/categories/${category}`">
                                            {{category}}
                                            <span v-if="index != Object.keys(tagList).length - 1"></span>
                                        </router-link>
                                    </span>
                                </p>
                            </div> -->
                            <div class="recipe-btns">
                                <!-- <button v-if="user" class="recipe-btn" id="addToFavs" @click="addToFavs">add to favorites <i class='material-icons right'>favorite</i></button> -->
                                <!-- <button v-if="user" class="recipe-btn" @click="printRecipe();">Print recipe <i class='material-icons right'>print</i></button> -->
                            </div>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col s12 m6">
            <div class="recipe-img-container" v-if="currentRecipe.hasImage">
                <img class="responsive-img recipe-img" :src="require(`@/assets/img/recipes/${currentRecipe.img}.jpg`)">
                <!-- <a v-if="currentRecipe.heartuk" href="https://www.heartuk.org.uk/healthy-living/" target="_blank"><img class="responsive-img heartukLogo" src="@/assets/img/heart-uk-logo-white.svg"></a> -->
            </div>
            <div v-else>
                <img class="responsive-img" src="@/assets/img/img-01.png">
            </div>
        </div>
    </div>
    <div id="printThis">
        <h3 class="printHeader">{{currentRecipe.title}} <span>By hofhrecipes.com</span></h3>

        <div class="row">
            <h4>المعلومات الغذائية لكل حصة <span class="yellow-text">(1/{{currentRecipe.serves}} الوجبة) </span></h4>
            <span v-if="currentRecipe.fat != null">
                <div class="nValue">
                    <div class="blob valign-wrapper fat">
                        <div class="blob-details">
                            <p><strong>الدهون:</strong></p>
                            <p>{{currentRecipe.fat}}g</p>
                        </div>
                    </div>
                </div>
            </span>
            <span v-if="currentRecipe.saturated != null">
                <div class="nValue">
                    <div class="blob valign-wrapper saturated">
                        <div class="blob-details">
                            <p><strong>الدهون المشبعة:</strong></p>
                            <p>{{currentRecipe.saturated}}g</p>
                        </div>
                    </div>
                </div>
            </span>
            <span v-if="currentRecipe.carbohydrate != null">
                <div class="nValue">
                    <div class="blob valign-wrapper carbohydrate">
                        <div class="blob-details">
                            <p><strong>الكربوهيدرات:</strong></p>
                            <p>{{currentRecipe.carbohydrate}}g</p>
                        </div>
                    </div>
                </div>
            </span>
            <span v-if="currentRecipe.protein != null">
                <div class="nValue">
                    <div class="blob valign-wrapper protein">
                        <div class="blob-details">
                            <p><strong>بروتين:</strong></p>
                            <p>{{currentRecipe.protein}}g</p>
                        </div>
                    </div>
                </div>
            </span>
            <span v-if="currentRecipe.calories != null">
                <div class="nValue">
                    <div class="blob valign-wrapper sodium">
                        <div class="blob-details">
                            <p><strong>السعرات الحرارية:</strong></p>
                            <p>{{currentRecipe.calories}}</p>
                        </div>
                    </div>
                </div>
            </span>
        </div>
        <div class="row">
            <div class="col s12 m6">
                <div class="left-align checklist">
                    <h4>المكونات</h4>
                    <ul>
                        <li v-for="(ingredient, index) in ingredientsList" :key="index"><input type="checkbox" />
                            <form action="#">
                                <p>
                                    <label>
                                        <input type="checkbox" :value="ingredient" v-model="selectedIngredients" />
                                        <span>{{ingredient}}</span>
                                    </label>
                                </p>
                            </form>
                        </li>
                    </ul>

                    <!-- <button v-if="user" class="recipe-btn" id="addToShopping" @click="addToShopping">add to shopping list <i class='material-icons right'>shopping_basket</i></button> -->
                </div>
            </div>
            <div class="col s12 m6">
                <div class="left-align methods">
                    <h4>الطريقة</h4>
                    <ol>
                        <li v-for="(method, index) in methodsList" :key="index">{{method}}</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import recipes from '@/assets/data/recipes-arabic-01.js'
import firebase from 'firebase'
import db from '@/firebase/init'
import request from 'request';
// import axios from 'axios'

export default {
    name: 'RecipeItaliano',
    data() {
        return {
            user: firebase.auth().currentUser,
            favRecipes: null,
            currentRecipe: null,
            activeRecipe: null,
            recipes: [],
            ingredientsList: null,
            tagList: null,
            methodsList: null,
            recentlyViewed: [],
            selectedIngredients: []
        }
    },
    metaInfo() {
        return {
            title: this.currentRecipe.title,
            meta: [{
                name: 'Description',
                content: this.currentRecipe.title + ' - ' + this.currentRecipe.description
            }, {
                name: 'keywords',
                content: this.currentRecipe.title + ', HOFH, HOFH recipes, HOFH meal plan, homozygous familial hypercholesterolemia, low fat recipes, low fat meals, ldl, fat free meals'
            }],
        }
    },
    mounted() {
        this.recipes = recipes;
        this.user = firebase.auth().currentUser
        document.body.classList.add("sub-page");

        if (localStorage.recentlyViewed) {
            this.recentlyViewed = JSON.parse(localStorage.recentlyViewed);
        }

        /* eslint-disable */
        if (this.recentlyViewed.includes(this.currentRecipe.slug)) {
            this.recentlyViewed.splice(this.recentlyViewed.indexOf(this.currentRecipe.slug), 1);
        }

        if (this.recentlyViewed.length == 8) {
            console.log(this.recentlyViewed.length)
            this.recentlyViewed = this.recentlyViewed.slice(1);
            this.recentlyViewed.push(this.currentRecipe.slug);
        } else {
            this.recentlyViewed.push(this.currentRecipe.slug);
        }

    },
    watch: {
        recentlyViewed(newRecipe) {
            localStorage.recentlyViewed = JSON.stringify(newRecipe);
        }
    },
    created() {
        let activeRecipe = this.$route.params.id;
        // this.currentRecipe = recipes[0]
        /* eslint-disable */
        // console.log(this.$route.params.id)

        // this.currentRecipe = recipes.filter(obj => {
        //     return obj.slug === this.$route.params.id;
        // })

        this.currentRecipe = recipes.find(function (obj) {
            return obj.slug === activeRecipe;
        });

        this.tagList = this.currentRecipe.category.split("|");
        this.ingredientsList = this.currentRecipe.ingredients.split("|");
        this.methodsList = this.currentRecipe.method.split("|");
        /* eslint-disable */
        // console.log(this.tagList)

        if (this.user) {
            db.collection('users').where('user_id', '==', this.user.uid)
                .onSnapshot((snapshot) => {
                    snapshot.docChanges().forEach(change => {
                        if (change.type == 'added') {
                            this.favRecipes = change.doc.data().favRecipes

                            // console.log(this.favRecipes)
                            // console.log(this.currentRecipe.slug)

                            if (this.favRecipes) {
                                for (var i = 0; i < this.favRecipes.length; i++) {
                                    var obj = this.favRecipes[i];
                                    // console.log(obj + this.currentRecipe.slug)
                                    if (obj == this.currentRecipe.slug) {
                                        document.getElementById("addToFavs").style.backgroundColor = "#f16a54";
                                        document.getElementById("addToFavs").style.color = "#ffffff";
                                        document.getElementById("addToFavs").innerHTML = "Remove from favorites <i class='material-icons right'>delete</i>";
                                        document.getElementById("addToFavs").classList.add("active")
                                    }
                                }
                            }
                        }
                    })
                })
        }
    },
    methods: {
        printRecipe() {
            try {
                document.execCommand('print', false, null)
            } catch {
                window.print();
                return false;
            }

            // this.$htmlToPaper('printThis');
        },
        // getImgUrl(){
        //     let randomNum = Math.floor(Math.random() * (1 - 230 + 1)) + 1;
        //     let imgSrc = "https://source.unsplash.com/700x500/?food?sig=" + randomNum;
        //     return imgSrc
        // },

        addToFavs() {
            console.log(this.currentRecipe.slug)

            /* eslint-disable */
            //update users weight record
            //   var currUser = db.collection("users").where('user_id', '==', this.user.uid);

            if (document.getElementById("addToFavs").classList.contains("active")) {
                db.collection('users').where('user_id', '==', this.user.uid).get()
                    .then(snapshot => {
                        snapshot.forEach((doc) => {
                            db.collection('users').doc(doc.id).update({
                                favRecipes: firebase.firestore.FieldValue.arrayRemove(this.currentRecipe.slug)
                            })
                        });
                    }).then(() => {
                        document.getElementById("addToFavs").style.cssText = "background-color: #e2f3f5; color: #1a727e";
                        document.getElementById("addToFavs").innerHTML = "<i class='material-icons right'>favorite</i> Add to favorites";
                        document.getElementById("addToFavs").classList.remove("active")
                        var toastHTML = '<span> Removed from favorites</span>';
                        M.toast({
                            html: toastHTML
                        });

                        const url = 'https://hooks.slack.com/services/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';
                        const text = this.currentRecipe.slug + ' removed from favourites by ' + firebase.auth().currentUser.email;

                        request.post({
                                headers: {
                                    'Content-type': 'application/json'
                                },
                                url,
                                form: JSON.stringify({
                                    text
                                }),
                            },
                            (error, res, body) => console.log(error, body, res.statusCode)
                        );
                    }).catch(err => {
                        /* eslint-disable */
                        alert(err);
                    })
            } else {
                db.collection('users').where('user_id', '==', this.user.uid).get()
                    .then(snapshot => {
                        snapshot.forEach((doc) => {
                            db.collection('users').doc(doc.id).update({
                                favRecipes: firebase.firestore.FieldValue.arrayUnion(this.currentRecipe.slug)
                            })
                        });
                    }).then(() => {
                        document.getElementById("addToFavs").style.cssText = "background-color: #f16a54; color: #fff";
                        document.getElementById("addToFavs").innerHTML = "<i class='material-icons right'>delete</i> Remove from favorites";
                        document.getElementById("addToFavs").classList.add("active")
                        var toastHTML = '<span> Added to favorites</span>';
                        M.toast({
                            html: toastHTML
                        });

                        const url = 'https://hooks.slack.com/services/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';
                        const text = this.currentRecipe.slug + ' added to favourites by ' + firebase.auth().currentUser.email;

                        request.post({
                                headers: {
                                    'Content-type': 'application/json'
                                },
                                url,
                                form: JSON.stringify({
                                    text
                                }),
                            },
                            (error, res, body) => console.log(error, body, res.statusCode)
                        );
                    }).catch(err => {
                        /* eslint-disable */
                        alert(err);
                    })
            }
        },

        addToShopping() {

            // for(var i = 0; i < this.selectedIngredients.length; i++) {
            //     var obj = this.selectedIngredients[i];
            //     console.log(obj)
            // }

            db.collection('users').where('user_id', '==', this.user.uid).get()
                .then(snapshot => {
                    for (var i = 0; i < this.selectedIngredients.length; i++) {
                        var obj = this.selectedIngredients[i];
                        snapshot.forEach((doc) => {
                            db.collection('users').doc(doc.id).update({
                                shoppingList: firebase.firestore.FieldValue.arrayUnion(obj)
                            })
                        });
                    }
                }).then(() => {
                    document.getElementById("addToShopping").style.cssText = "background-color: #e2f3f5; color: #eca500";
                    document.getElementById("addToShopping").innerHTML = "<i class='material-icons right'>check</i> Done!";
                    document.getElementById("addToShopping").classList.remove("active")
                    var toastHTML = '<span> Added to shopping list</span>';
                    M.toast({
                        html: toastHTML
                    });

                    const url = 'https://hooks.slack.com/services/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';
                    const text = firebase.auth().currentUser.email + ' added stuff to their shopping list';

                    request.post({
                            headers: {
                                'Content-type': 'application/json'
                            },
                            url,
                            form: JSON.stringify({
                                text
                            }),
                        },
                        (error, res, body) => console.log(error, body, res.statusCode)
                    );
                }).catch(err => {
                    /* eslint-disable */
                    alert(err);
                })
        }
    }
}
</script>

<style scoped>
* {
  direction: rtl;
}

.row .col {
  float: right;
}

.right {
  float: left !important;
}

.left {
  float: right !important;
}

h2,
.left-align,
.hero-p,
.card h5,
.card .card-content p,
.methods li,
.methods h4,
.checklist h4,
ul:not(.browser-default),
.recipe .hero-heading,
.hero-heading {
  text-align: initial !important;
}

ul:not(.browser-default) {
  padding-right: 0;
}
</style>
