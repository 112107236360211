import firebase from 'firebase'
// import firestore from 'firebase/firestore'

var firebaseConfig = {
    apiKey: "AIzaSyAc3wRE90wqmsD1abmgkZWUEg8R_vgPS8Y",
    authDomain: "hofhrecipes.firebaseapp.com",
    databaseURL: "https://hofhrecipes.firebaseio.com",
    projectId: "hofhrecipes",
    storageBucket: "hofhrecipes.appspot.com",
    messagingSenderId: "614044645099",
    appId: "1:614044645099:web:243df6accd263a4190f0ae"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export default firebaseApp.firestore();